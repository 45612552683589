import React, { FC as FC_, memo } from 'react';
import { useSelector } from 'react-redux';
import GeneralGuarantorsInformation from '../../../../ReviewApplication/Guarantors/GeneralGuarantorsInformation/GeneralGuarantorsInformation';
import ReviewApplicationGeneralSection from '../../../../ReviewApplication/ReviewApplicationGeneralSection';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../../../../ReviewApplication/ReviewApplication';
import { AppState as AppState_ } from '../../../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../../../data/models/QDApplicationHolder';
import Borrower_ from '../../../../../../data/models/Borrower';
import QDCoBorrowerApplicationHolder_ from '../../../../../../data/models/QDCoBorrowerApplicationHolder';

type GuarantorsProps = ReviewApplicationSectionProps_;

const Guarantors: FC_<GuarantorsProps> = ({
  page, sectionTitle, expandState, setExpandState,
}: GuarantorsProps) => {
  const { appHolder, holder, } = useSelector<
    AppState_,
    {
      appHolder: QdApplicationHolder_;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    appHolder: state.holderState.Holder,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const coBorrower = holder.Personal.CoBorrower ?? holder.Business.CoBorrower;
  const guarantorsForReview = appHolder?.Guarantor?.Guarantors?.filter((guarantor: Borrower_) => guarantor.Id !== coBorrower.Id) || [];

  if (guarantorsForReview.length === 0) {
    return null;
  }

  return (
    <ReviewApplicationGeneralSection page={null} sectionTitle={sectionTitle} plainContainer>
      <>
        {guarantorsForReview.map((guarantor: Borrower_) => {
          return (
            <GeneralGuarantorsInformation
              key={`${guarantor.Id}`}
              hasFullAccess={false}
              isCoBorrower
              page={page}
              guarantor={guarantor}
              expandState={expandState}
              setExpandState={setExpandState}
            />
          );
        })}
      </>
    </ReviewApplicationGeneralSection>
  );
};

export default memo(Guarantors);
