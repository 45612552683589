/* eslint-disable no-undef */
import React, { FC as FC_ } from 'react';
import { useSelector } from 'react-redux';
import { IconsLight } from '@jkhy/vsg-design-system';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../utils/Types';
import HorizontalInfoCardGenerator from '../HorizontalInfoCardGenerator/HorizontalInfoCardGenerator';
import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import Debts_ from '../../../data/models/BusinessObligations';
import QdDebt_ from '../../../data/models/QDDebt';
import DebtsSettings from './DebtsSettings';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';

export interface DebtProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, QdDebt_>[],
    holder: QdApplicationHolder_,
    section: Debts_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const BusinessObligations: FC_<DebtProps> = (props: DebtProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const { IdQDApplication, Debts: DebtsModel, } = holder;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.BusinessObligations);
  const pageSettings = new DebtsSettings([pageFieldHolder?.BusinessObligations.General], [section?.SubSections[0]]);

  const newDebt = () => {
    const newData: QdDebt_ = {
      CurrentBalance: null,
      GUID: '',
      IdCompany: BigInt(0),
      IdUserLastModified: BigInt(0),
      NullFields: [],
      TreatAllNulls: false,
      Id: BigInt(0),
      IdQDApplication,
      DebtType: null,
      DebtTypeEnumStr: '',
      InterestRate: null,
      MaturityDate: null,
      CollateralDescription: '',
      Creditor: '',
      MonthlyPayment: null,
    };
    return newData;
  };

  const onSubmit = async (invalidPageFields, data) => {
    const debtsModelModified = { ...DebtsModel, Debts: data, };
    await props.onSubmit(invalidPageFields, holder, debtsModelModified, ExternalPages.BusinessObligations, false);
  };

  const onDebtSave = async (debt: QdDebt_) => QdServiceApi.saveQDDebt(debt);
  const onDebtRemove = async (id: BigInt) => QdServiceApi.removeQDDebt(id);
  const getSaveHolderAction = (debts: QdDebt_[]): ISaveHolder_ => {
    const debtsModelModified = {
      ...DebtsModel,
      Debts: debts,
    };
    return {
      holderState: {
        Holder: { ...holder, Debts: debtsModelModified, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };
  };

  return (
    <>
      {!loading && (
        <HorizontalInfoCardGenerator
          formIdentifier={`form-${ExternalPages.BusinessObligations}`}
          onSubmit={onSubmit}
          onSave={onDebtSave}
          onRemove={onDebtRemove}
          getSaveHolderAction={getSaveHolderAction}
          pageSettings={pageSettings}
          holder={holder}
          subHolder={DebtsModel}
          title="DebtTypeEnumStr"
          icon={IconsLight.faMoneyBillAlt}
          newObject={newDebt()}
          list={DebtsModel.Debts}
        />
      )}
    </>
  );
};

export default BusinessObligations;
