import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

const settings = {
  client_id: (window as any).CONFIG.Oidc.client_id,
  redirect_uri: `${(window as any).BASE_URL}QuickDecisionV2/token-callback`,
  post_logout_redirect_uri: `${(window as any).BASE_URL}QuickDecisionV2/logout-callback`,
  response_type: (window as any).CONFIG.Oidc.response_type,
  scope: (window as any).CONFIG.Oidc.scope,
  authority: (window as any).CONFIG.Oidc.authority,
  automaticSilentRenew: 'true',
  filterProtocolClaims: 'true',
  loadUserInfo: 'true',
  userStore: new WebStorageStateStore({ store: sessionStorage, }),
};

const userManager = createUserManager(settings as any);

export default userManager;
