import React, { FC as FC_, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Radio, Toast } from '@jkhy/vsg-design-system';
import IdentityVerificationAPI, { PreciseIDSubmitPars as PreciseIDSubmitPars_ } from '../../../../data/api/IdentityVerificationApi';
import Borrower_ from '../../../../data/models/Borrower';
import PreciseIDQuestion_ from '../../../../data/models/PreciseIDQuestion';
import { useFormData, CustomFormData as CustomFormData_ } from '../../../../hooks/useFormData';
import { IdentityState as IdentityState_ } from '../../../../redux/actions/Identity';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../redux/actions/CoBorrowerIdentity';
import { setLoader } from '../../../../redux/actions/Loading';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { PreciseIDDecisions, ValueConstants } from '../../../../utils/Enums';
import Messages from '../../../../utils/Messages';
import QDPartyDetails_ from '../../../../data/models/QDPartyDetails';

type PreciseIDProps = {
  borrower: Borrower_;
  formId: string;
  completeIdentityVerificationProcess: (
    identityCheckType: typeof ValueConstants.PreciseID | typeof ValueConstants.Idology,
    responseStatus?: typeof PreciseIDDecisions[keyof typeof PreciseIDDecisions],
    partyDetails?: QDPartyDetails_
  ) => void;
};

type FieldTypes = string;

const PreciseID: FC_<PreciseIDProps> = ({ borrower, formId, completeIdentityVerificationProcess, }: PreciseIDProps) => {
  const { identityState, coBorrowerIdentityState, } = useSelector<
    AppState_,
    {
      identityState: IdentityState_;
      coBorrowerIdentityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    identityState: state.identityState,
    coBorrowerIdentityState: state.coBorrowerIdentityState,
  }));

  const isPrimaryBorrower = borrower.IsPrimaryBorrower;
  const sessionId = isPrimaryBorrower ? identityState.IdentityHolder.TransactionID : coBorrowerIdentityState.IdentityHolder.TransactionID;

  const [questions, setQuestions] = useState<PreciseIDQuestion_[]>([]);

  const dispatch = useDispatch();
  const {
    formData, setFormData, onFormValuesChange, validate,
  } = useFormData<FieldTypes>();

  useEffect(() => {
    const loadQuestions = async () => {
      dispatch(setLoader(true));

      const { Success, Result, } = await IdentityVerificationAPI.getPreciseIDQuestions(borrower.GUID, sessionId);
      if (Success) {
        const { Questions, } = Result;

        if (Questions?.length === 0) {
          dispatch(setLoader(false));
          completeIdentityVerificationProcess(ValueConstants.PreciseID);
          return;
        }

        setQuestions(Questions);
      }

      dispatch(setLoader(false));
    };

    if (questions.length === 0 && borrower.GUID) {
      loadQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [borrower.GUID, questions.length, setFormData]);

  useEffect(() => {
    if (questions.length > 0) {
      const emptyFormData: CustomFormData_<FieldTypes> = {};
      questions.forEach(({ Question: questionName, }: PreciseIDQuestion_) => {
        emptyFormData[questionName] = {
          value: null,
          isValid: true,
        };
      });

      setFormData(emptyFormData);
    }
  }, [questions, setFormData]);

  const generateSubmitParameters = (): PreciseIDSubmitPars_ => {
    const answers = Object.values(formData).map(customField => customField.value);
    const idParty = borrower?.IdParty;

    return {
      SessionID: sessionId,
      IdQDParty: borrower.Id,
      ...(idParty ? { IdParty: idParty, } : null),
      Answers: ['', ...answers],
    };
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = validate();

    if (!isValid) {
      return;
    }

    dispatch(setLoader(true));

    const submitParameters = generateSubmitParameters();

    const { Success, Result, } = await IdentityVerificationAPI.submitPreciseIDQuestions(submitParameters);
    // The loader need to be stop here, because completeIdologyProcess trigger redux action that could Re-Inquire Holder
    // (Re-Inquire Holder functionality has own loading functionality)
    dispatch(setLoader(false));

    if (Success) {
      const {
        ShowError, Error, Decision, QDPartyDetails,
      } = Result;

      if (ShowError) {
        Toast.danger(Error);
        return;
      }

      completeIdentityVerificationProcess(ValueConstants.PreciseID, Decision, QDPartyDetails);
    }
  };

  return (
    <form id={formId} data-ui="PreciseID" onSubmit={handleSubmit}>
      {formData
        && questions.map(({ Question: questionName, Answers: answers, }: PreciseIDQuestion_) => {
          // The formData is formed after setting the questions in the state (UseEffect)
          if (!formData || !formData[questionName]) {
            return null;
          }

          return (
            <FormGroup
              key={questionName}
              className="mb-2"
              checkboxOrRadio
              dataUI={questionName}
              label={questionName}
              isRequired
              isValid={formData[questionName].isValid}
              validationMessage={Messages.REQUIRED_FIELD}
            >
              {answers.map((answer: string, answerIdx: number) => {
                const key = `${questionName}-${answer}`;
                const answerValue = `${answerIdx + 1}`;

                return (
                  <Radio
                    key={key}
                    dataUI={key}
                    className="mb-05"
                    name={questionName}
                    htmlFor={key}
                    id={key}
                    value={answerValue}
                    checked={formData[questionName].value === answerValue}
                    onChange={() => onFormValuesChange(questionName, answerValue)}
                  >
                    {answer}
                  </Radio>
                );
              })}
            </FormGroup>
          );
        })}
    </form>
  );
};

export default PreciseID;
