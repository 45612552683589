import BaseAPI from './BaseApi';
import QDPrequalification_ from '../models/Prequalification/QDPrequalification';
import NameValueItem_ from '../models/NameValueItem';
import QdPrequalCompletePars_ from '../models/Prequalification/QDPrequalCompletePars';
import LoanOption_ from '../models/QDLoanOption';

export default class QdPrequalificationApi extends BaseAPI {
  static inquire = (GUIDQDPrequalification, Email, QDApplicationModelCode) => BaseAPI.performAjax<QDPrequalification_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionPrequalification/PrequalificationAppInquire`,
    data: { GUIDQDPrequalification, Email, QDApplicationModelCode, },
  });

  static inquireLoanOptions = (GUIDQDPrequalification: string) => BaseAPI.performAjax<LoanOption_[]>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionPrequalification/InquireLoanOptions?guid=${GUIDQDPrequalification}`,
  });

  static creditScoreOptionsInquire = () => BaseAPI.performAjax<NameValueItem_[]>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionPrequalification/CreditScoreOptionsInquire`,
  });

  static continueWithApplication = (GUIDQDPrequalification: string) => BaseAPI.performAjax<string>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionPrequalification/ContinueWithApplication`,
    data: GUIDQDPrequalification,
  });
}
