import React from 'react';
import { connect } from 'react-redux';

import { bindActionCreators as bindActionCreators_ } from 'redux';

import { AppState as AppState_ } from '../../redux/AppState';
import QuestionModel_ from '../../data/models/Question';
import { updateRenewalHolder } from '../../redux/actions/Renewal';
import QuestionTemplate from './QuestionTemplate';

export interface QuestionProps {
  question: QuestionModel_;
  renewal: any;
  idQDFacility: any;
  updateRenewalHolder: any;
}

export interface QuestionState {
  question: QuestionModel_;
}

class Question extends React.Component<QuestionProps, QuestionState, any> {
  constructor(props) {
    super(props);

    this.state = {
      question: { ...props.question, },
    };
  }

  onChange = (value: string | number | boolean, prop: string) => {
    const { question, } = this.state;

    question[prop] = value;

    this.setState({ question, });

    const { renewal, idQDFacility, } = this.props;

    const mapped = renewal?.ModelHolder?.Renewal?.Application?.Facilities.map(qdf_ => {
      const qdf = qdf_;
      if (qdf.Id === idQDFacility) {
        const questions = qdf.Questions.map(q_ => {
          const q = q_;
          if (q.Id === question.Id) {
            q[prop] = value;
          }
          return q;
        });

        qdf.Questions = questions;
      }

      return qdf;
    });

    const mappedOtherChangesQuestions = renewal?.ModelHolder?.Renewal?.Application?.Questions.map(q_ => {
      const q = q_;
      if (q.Id === question.Id) {
        q[prop] = value;
      }

      return q;
    });

    renewal.ModelHolder.Renewal.Application.Facilities = mapped;
    renewal.ModelHolder.Renewal.Application.Questions = mappedOtherChangesQuestions;
    const { updateRenewalHolder: updateRenewalHolder_, } = this.props;
    updateRenewalHolder_({ ...renewal?.ModelHolder, });
  };

  render = () => {
    const { question, } = this.state;

    return <QuestionTemplate question={question} onChange={this.onChange} />;
  };
}

export default connect(
  (state: AppState_) => ({ ...state, }),
  dispatch => bindActionCreators_({ updateRenewalHolder, }, dispatch)
)(Question) as any;
