import { Action as Action_ } from 'redux';
import PageField_ from '../../data/models/PageField';
import QdApplicationSection_ from '../../data/models/QDApplicationSection';
import SubSection_ from '../../data/models/Subsection';
import QdApplicationModel_ from '../../data/models/QDApplicationModel';
import PageFieldHolder_ from '../../data/models/PageFields/PageFieldHolder';

export const SET_PREQUALIFICATION_MODEL_STATE = 'SET_PREQUALIFICATION_MODEL_STATE';

export type PrequalificationModelHolder = {
  Model: QdApplicationModel_;
  Sections: QdApplicationSection_[];
  SubSections: SubSection_[];
  PageFields: PageField_[];
  ApplicationPageFields: PageFieldHolder_;
};

export type PrequalificationModelState = {
  ModelHolder: PrequalificationModelHolder;
  IsModelHolderFetched: boolean;
};
export interface SetPrequalificationModelStateAction extends Action_<typeof SET_PREQUALIFICATION_MODEL_STATE> {
  payload: PrequalificationModelState;
}

export const setPrequalificationModelState = (modelState: PrequalificationModelState) => ({
  type: SET_PREQUALIFICATION_MODEL_STATE,
  payload: modelState,
});
