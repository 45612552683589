import PageSettings from '../External/Page/PageHelpers/PageSettings';
import PageField_ from '../../data/models/PageField';
import PageFieldExtended_ from '../External/Page/PageHelpers/PageFieldExtended';
import { RenewalType } from '../../utils/Enums';

export default class RenewalSettings<T, T1> extends PageSettings<T, T1, any> {
  // eslint-disable-next-line class-methods-use-this
  pageFieldsMapper(pageField: PageField_): PageFieldExtended_<T, T1> {
    switch (pageField.FieldName) {
      case 'RenewalChoice':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, facility, pf) => {
            if (facility.RenewalChoice === RenewalType.RenewalSameAmount) return true;

            return pf.Required && facility.RenewalChoice !== null && !!facility.RenewalAmount;
          },
        });
      case 'ESignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
      case 'RegOQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'RegOResponse',
        });
      case 'OfficerQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'OfficerQuestionCode',
        });
      case 'MarketingQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'MarketingQuestionCode',
        });
      case 'SpecificReason':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, facility, pf) => {
            if (facility.RenewalChoice === RenewalType.RenewalSameAmount) return true;

            return pf.Required && !!facility.SpecificReason;
          },
        });
      case 'IfYesMailingAddressChangedAnswer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, app, pf) => pf.Required && app.IfYesMailingAddressChangedAnswer !== null,
        });
      case 'PhoneNumberChangedAnswer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, app, pf) => pf.Required && app.PhoneNumberChangedAnswer !== null,
        });

      case 'IfYesPhoneNumberChangedAnswer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, app, pf) => {
            if (!app.PhoneNumberChangedAnswer) return true;
            return pf.Required && !!app.IfYesPhoneNumberChangedAnswer;
          },
        });

      default:
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
    }
  }

  /* eslint no-unused-vars: off */
  static additionalRenewalPageFields = () => {
    return [
      PageFieldExtended_.createInstance({
        FieldName: 'MailingAddress',
        ObjectProperty: 'MailingAddress',
        Required: true,
        IsValid: (appHolder, coBorrower, pf) => {
          if (!appHolder.MailingAddressChangedAnswer) return true;
          if (!pf.Required || pf.IsHidden) return true;

          if (coBorrower.MailingAddress) return true;
          return false;
        },
      }),
      PageFieldExtended_.createInstance({
        FieldName: 'MailingCity',
        ObjectProperty: 'MailingCity',
        Required: true,
        IsValid: (appHolder, coBorrower, pf) => {
          if (!appHolder.MailingAddressChangedAnswer) return true;
          if (!pf.Required || pf.IsHidden) return true;

          if (coBorrower.MailingCity) return true;
          return false;
        },
      }),
      PageFieldExtended_.createInstance({
        FieldName: 'MailingState',
        ObjectProperty: 'MailingState',
        Required: true,
        IsValid: (appHolder, coBorrower, pf) => {
          if (!appHolder.MailingAddressChangedAnswer) return true;
          if (!pf.Required || pf.IsHidden) return true;

          if (coBorrower.MailingState) return true;
          return false;
        },
      }),
      PageFieldExtended_.createInstance({
        FieldName: 'MailingZip',
        ObjectProperty: 'MailingZip',
        Required: true,
        IsValid: (appHolder, coBorrower, pf) => {
          if (!appHolder.MailingAddressChangedAnswer) return true;
          if (!pf.Required || pf.IsHidden) return true;

          if (coBorrower.MailingZip) return true;
          return false;
        },
      }),
      PageFieldExtended_.createInstance({
        FieldName: 'IfYesPhoneNumberChangedAnswer',
        ObjectProperty: 'IfYesPhoneNumberChangedAnswer',
        Required: true,
        IsValid: (appHolder, coBorrower, pf) => {
          if (!appHolder.PhoneNumberChangedAnswer) return true;
          if (!pf.Required || pf.IsHidden) return true;

          if (appHolder.IfYesPhoneNumberChangedAnswer) return true;
          return false;
        },
      })
    ];
  };
}
