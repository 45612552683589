import { Action as Action_ } from 'redux';
import QDCoBorrowerApplicationHolder_ from '../../data/models/QDCoBorrowerApplicationHolder';
import { GuarantorPages } from '../../utils/Enums';

export type CoBorrowerHolderState = {
  IsCoBorrowerHolderFetched: boolean;
  CoBorrowerHolder: QDCoBorrowerApplicationHolder_;
};

export interface IGotCoBorrowerHolder extends Action_<'GotCoBorrowerHolder'> {
  coBorrowerHolderState: CoBorrowerHolderState;
}

export interface ICoBorrowerSaveHolder extends Action_<'SaveCoBorrowerHolder'> {
  coBorrowerHolderState: CoBorrowerHolderState;
}

export type IgnoreChangeHolder = Action_<'IgnoreChange'>;

export type CoBorrowerHolderActions = IGotCoBorrowerHolder | ICoBorrowerSaveHolder | IgnoreChangeHolder;

export const updateCoBorrowerHolder = (result: QDCoBorrowerApplicationHolder_): IGotCoBorrowerHolder => ({
  type: 'GotCoBorrowerHolder',
  coBorrowerHolderState: {
    CoBorrowerHolder: result,
    IsCoBorrowerHolderFetched: true,
  },
});

export const updateCoBorrowerHolderFromSaveResponse = (
  Sequence: number | string,
  holder: QDCoBorrowerApplicationHolder_,
  responseResult
): ICoBorrowerSaveHolder | IgnoreChangeHolder => {
  const resetNullFields = list => (list || []).map(l => {
    const item = l;
    item.NullFields.length = 0;
    return item;
  });

  let action: ICoBorrowerSaveHolder | IgnoreChangeHolder;

  switch (`${Sequence}`) {
    case GuarantorPages.PersonalAddress:
      {
        const personalAddressPage = {
          ...holder.AddressesP,
          RemovedPersonalAdddresses: [],
          PersonalAddresses: resetNullFields(responseResult?.CoBorrowerAddresses),
        };

        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: { ...holder, AddressesP: personalAddressPage, },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.BusinessAddresses:
      {
        const businessAddressPage = {
          ...holder.AddressesB,
          BusinessAddresses: resetNullFields(responseResult?.CoBorrowerAddresses),
        };

        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: { ...holder, AddressesB: businessAddressPage, },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.PersonalPartyMatch:
      {
        const coBorrower = responseResult?.CoBorrower;
        if (coBorrower) {
          coBorrower.NullFields.length = 0;
        }
        const personalPartyMatch = { ...holder?.PersonalMatchCheck, CoBorrower: coBorrower, };
        const personalData = { ...holder?.Personal, CoBorrower: coBorrower, };
        const personalAddressPage = { ...holder.AddressesP, PersonalAddresses: resetNullFields(responseResult?.CoBorrowerAddresses), };
        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: {
              ...holder,
              PersonalMatchCheck: personalPartyMatch,
              Personal: personalData,
              AddressesP: personalAddressPage,
            },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.BusinessPartyMatch:
      {
        const coBorrower = responseResult?.CoBorrower;
        if (coBorrower) {
          coBorrower.NullFields.length = 0;
        }
        const businessPartyMatch = { ...holder.BusinessMatchCheck, CoBorrower: coBorrower, };
        const businessData = { ...holder.Business, CoBorrower: coBorrower, };
        const businessAddress = { ...holder.AddressesB, BusinessAddresses: resetNullFields(responseResult?.CoBorrowerAddresses), };
        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: {
              ...holder,
              BusinessMatchCheck: businessPartyMatch,
              Business: businessData,
              AddressesB: businessAddress,
            },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.BusinessOtherInformation:
    case GuarantorPages.BusinessInformation:
      {
        const coBorrower = responseResult?.CoBorrower;

        if (coBorrower) {
          coBorrower.NullFields.length = 0;
        }

        const businessData = { ...holder.Business, CoBorrower: coBorrower, };

        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: {
              ...holder,
              Business: businessData,
            },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages['Income&Expenses']:
      {
        const employment = responseResult?.Employment;
        if (employment) {
          employment.NullFields.length = 0;
        }

        const incomeExpensesPage = { ...holder.IncomeExpenses, Employment: employment, };
        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: {
              ...holder,
              IncomeExpenses: incomeExpensesPage,
            },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.Information:
      {
        const coBorrower = responseResult?.CoBorrower;
        if (coBorrower) {
          coBorrower.NullFields.length = 0;
        }

        const informationPage = { ...holder.Information, ESignConsent: coBorrower?.ESignConsent, Questions: responseResult?.Questions, };

        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: { ...holder, Information: informationPage, },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    case GuarantorPages.Declarations:
      {
        const coBorrower = responseResult?.CoBorrower;
        if (coBorrower) {
          coBorrower.NullFields.length = 0;
        }

        const declarationsPage = {
          ...holder.Declarations,
          ESignConsent: coBorrower?.ESignConsent,
          RegOResponse: coBorrower?.RegOResponse,
          RegOResponseMemo: coBorrower?.RegOResponseMemo,
          Questions: responseResult?.Questions,
        };

        action = {
          coBorrowerHolderState: {
            CoBorrowerHolder: { ...holder, Declarations: declarationsPage, },
            IsCoBorrowerHolderFetched: true,
          },
          type: 'SaveCoBorrowerHolder',
        };
      }
      break;
    default:
      action = { type: 'IgnoreChange', };
      break;
  }

  return action;
};
