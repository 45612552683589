import { ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import _ from 'lodash';
import {
  ExternalPages, ValueConstants, SaveProcessStage, GuarantorPageSectionName_, GuarantorPages
} from '../../utils/Enums';
import GeneratorHost, { IGeneratorParameters as GeneratorParameters_ } from '../../utils/GeneratorHost';
import { LoadingAction as LoadingAction_, setLoader } from './Loading';
import QdServiceApi_ from '../../data/api/QDServiceApi';
import { updateVisibleSections } from './VisibleSections';
import Section_ from '../../data/models/Section';
import QdApplicationHolder_ from '../../data/models/QDApplicationHolder';
import QDCoBorrowerApplicationHolder_ from '../../data/models/QDCoBorrowerApplicationHolder';
import {
  filterSections, sortVisibleSections, checkIsReviewPageVisible, shouldUseHolderForEval
} from '../../utils/Helper';
import { InputSaveParameters as InputSaveParameters_ } from '../../utils/Types';

import { setIdentityResult, setIdentityState as setIdentityStateAction } from './Identity';
import { setCoborrowerIdentityResult, setCoBorrowerIdentityState as setCoBorrowerIdentityAction } from './CoBorrowerIdentity';
import { updateHolderFromSaveResponse } from './Holder';
import { updateCoBorrowerHolderFromSaveResponse } from './CoBorrowerHolder';
import { QDApplicationTypeAnswer } from '../../data/models/Information';

const shouldEvalIdentity = (isIdentityFetched: boolean, page: string, updateObject) => {
  if (isIdentityFetched) return false;
  const { FromGuarantor, } = updateObject;

  if (!FromGuarantor && parseInt(page, 10) === parseInt(ExternalPages.PersonalPartyMatch, 10)) {
    const {
      OwnerCheck: {
        Personal: { PartyMatchDone, IdParty, },
      },
    } = updateObject;
    return PartyMatchDone && IdParty;
  }

  if (FromGuarantor && parseInt(page, 10) === parseInt(GuarantorPages.PersonalPartyMatch, 10)) {
    const {
      PersonalMatchCheck: {
        CoBorrower: { PartyMatchDone, IdParty, },
      },
    } = updateObject;
    return PartyMatchDone && IdParty;
  }

  return FromGuarantor
    ? parseFloat(page) === parseInt(GuarantorPages.PersonalAddress, 10)
    : parseFloat(page) === parseInt(ExternalPages.PersonalAddresses, 10);
};
// eslint-disable-next-line func-names
export const evalProcessGenerator = function* (pars: InputSaveParameters_) {
  const {
    updateObject, allSections, identityCheckPars, holder,
  } = pars;

  const {
    FromGuarantor,
    IdQDApplication,
    GUIDQDApplication,
    IdQDModel,
    SectionName,
    Sequence,
    identityState: { IsIdentityFetched, },
    PartyGUID,
  } = updateObject;

  if (!FromGuarantor) {
    yield QdServiceApi_.saveSection(updateObject);
  } else {
    yield QdServiceApi_.saveCoBorrowerSection({ ...updateObject, GUIDQDParty: PartyGUID, });
  }

  const applicationTypeAnswer = holder?.Information?.ApplicationTypeAnswer ?? QDApplicationTypeAnswer.Business;
  const requestHolder = shouldUseHolderForEval(applicationTypeAnswer, FromGuarantor, PartyGUID) ? holder : null;

  const evalResult = yield QdServiceApi_.evaluate(IdQDApplication, SectionName, requestHolder, PartyGUID);
  if (!evalResult?.ShouldStopApplication) {
    const visibleSections = filterSections('IdQDSectionDefinition', allSections, evalResult?.VisibleSections);
    const nextPage = visibleSections[visibleSections.length - 1];

    yield QdServiceApi_.evalSectionSubSections({
      FromGuarantor,
      GUIDQDApplication,
      IdQDApplication,
      IdQDApplicationModel: IdQDModel,
      SectionName: nextPage.SectionName,
      GUIDQDParty: PartyGUID,
    } as Section_);

    const evalIdentityCheck = shouldEvalIdentity(IsIdentityFetched, Sequence, updateObject);
    if (evalIdentityCheck) {
      yield QdServiceApi_.identityCheckEval(identityCheckPars);
    }
  }
};

export const evalProcessActionCreator: ActionCreator_<ThunkAction_<Promise<number>, boolean, InputSaveParameters_, LoadingAction_>> = (
  pars: InputSaveParameters_,
  onFail: () => void
) => {
  return async (dispatch: Dispatch_) => {
    let visibleSections = [];
    // eslint-disable-next-line no-unused-vars
    let evaluatedSubSections;
    let identityResponse;
    let nextStep = 0;

    const { allSections, updateObject, holder, } = pars;
    const {
      Sequence,
      identityState: { IsIdentityFetched, IdentityVisited, IdentityHolder, },
    } = updateObject;

    const params: GeneratorParameters_<any> = {
      dispatch,
      onEachSuccess: (ajaxReqSequence, response) => {
        switch (ajaxReqSequence) {
          case SaveProcessStage.SaveSection: {
            const saveHolderAction = updateHolderFromSaveResponse(Sequence, holder as QdApplicationHolder_, response);
            if (saveHolderAction?.type === 'SaveHolder') {
              dispatch(saveHolderAction);
            }
            break;
          }
          case SaveProcessStage.EvalSection:
            if (response.ShouldStopApplication) {
              nextStep = ValueConstants.ShouldStopApplication;
            }

            visibleSections = filterSections('IdQDSectionDefinition', allSections, response?.VisibleSections);
            break;
          case SaveProcessStage.EvalSubSection:
            evaluatedSubSections = response;
            break;
          case SaveProcessStage.EvalIdentitySection: {
            const evalIdentityCheck = shouldEvalIdentity(IsIdentityFetched, Sequence, updateObject);
            if (evalIdentityCheck) {
              identityResponse = response;
              dispatch(setIdentityResult(identityResponse));
            }
            break;
          }
          default:
            break;
        }
      },
      onFail: errors => {
        dispatch(setLoader(false));

        const [error] = errors;
        if (error?.Code === 401) {
          onFail();
        }
      },
    };

    dispatch(setLoader(true));

    await new GeneratorHost(params).run(evalProcessGenerator.bind(null, pars));

    const isReviewPageVisible = checkIsReviewPageVisible(visibleSections, ExternalPages.Review);
    const visibleSorted = sortVisibleSections(visibleSections);
    dispatch(updateVisibleSections(visibleSorted, isReviewPageVisible));

    //  Determine next page
    if (nextStep === ValueConstants.ShouldStopApplication) {
      dispatch(setLoader(false));
      return nextStep;
    }

    const nextPage = visibleSections[visibleSections.length - 1];
    if (nextPage) nextPage.SubSections = filterSections('Id', nextPage.SubSections, evaluatedSubSections);

    const nextVisibleSection = visibleSorted[visibleSorted.length - 1];
    nextStep = parseFloat(nextVisibleSection?.Sequence);

    if (
      !IdentityVisited
      && (IdentityHolder?.HasQuestions || identityResponse?.HasQuestions)
      && (parseInt(Sequence, 10) === parseInt(ExternalPages.PersonalPartyMatch, 10)
        || parseFloat(Sequence) === parseInt(ExternalPages.PersonalAddresses, 10))
    ) {
      nextStep = parseFloat(ExternalPages.IdentityCheck);
    }
    // END Determine next page

    //  Handle: Client has reached Identiti Verification questions, navigate and save previous page.
    //  Assure correct menu items, associated with identityVerificationStep method
    if (
      IsIdentityFetched
      && !IdentityVisited
      && parseInt(Sequence, 10) !== parseInt(ExternalPages.PersonalPartyMatch, 10)
      && parseFloat(Sequence) !== parseInt(ExternalPages.PersonalAddresses, 10)
    ) {
      // Update identity visited
      dispatch(
        setIdentityStateAction({
          ...updateObject?.identityState,
          IdentityVisited: true,
        })
      );
    }

    dispatch(setLoader(false));

    return nextStep;
  };
};

export default {};

export const evalCoBorrowerProcessActionCreator: ActionCreator_<ThunkAction_<Promise<number>, boolean, InputSaveParameters_, LoadingAction_>> = (
  pars: InputSaveParameters_,
  onFail: () => void
) => {
  return async (dispatch: Dispatch_) => {
    let visibleSections = [];
    // eslint-disable-next-line no-unused-vars
    let evaluatedSubSections;
    let identityResponse;
    let nextStep = 0;

    const { allSections, updateObject, holder, } = pars;
    const {
      Sequence,
      identityState: { IsIdentityFetched, IdentityVisited, IdentityHolder, },
    } = updateObject;

    const params: GeneratorParameters_<any> = {
      dispatch,
      onEachSuccess: (evaluatedSection, response) => {
        switch (evaluatedSection) {
          case SaveProcessStage.SaveSection: {
            const saveHolderAction = updateCoBorrowerHolderFromSaveResponse(Sequence, holder as QDCoBorrowerApplicationHolder_, response);
            if (saveHolderAction?.type === 'SaveCoBorrowerHolder') {
              dispatch(saveHolderAction);
            }
            break;
          }
          case SaveProcessStage.EvalSection:
            if (response.ShouldStopApplication) {
              nextStep = ValueConstants.ShouldStopApplication;
            }

            visibleSections = filterSections('IdQDSectionDefinition', allSections, response?.VisibleSections);
            break;
          case SaveProcessStage.EvalSubSection:
            evaluatedSubSections = response;
            break;
          case SaveProcessStage.EvalIdentitySection:
            {
              const evalIdentityCheck = shouldEvalIdentity(IsIdentityFetched, Sequence, updateObject);
              if (evalIdentityCheck) {
                identityResponse = response;
                dispatch(setCoborrowerIdentityResult(identityResponse));
              }
            }
            break;
          default:
            break;
        }
      },
      onFail: errors => {
        dispatch(setLoader(false));
        const [error] = errors;
        if (error?.Code === 401) {
          onFail();
        }
      },
    };

    dispatch(setLoader(true));

    await new GeneratorHost(params).run(evalProcessGenerator.bind(null, pars));

    const isReviewPageVisible = checkIsReviewPageVisible(visibleSections, GuarantorPages.Review);
    const visibleSorted = sortVisibleSections(visibleSections);
    dispatch(updateVisibleSections(visibleSorted, isReviewPageVisible));

    if (nextStep === ValueConstants.ShouldStopApplication) {
      dispatch(setLoader(false));
      return nextStep;
    }

    const nextPage = visibleSections[visibleSections.length - 1];
    if (nextPage) nextPage.SubSections = filterSections('Id', nextPage.SubSections, evaluatedSubSections);

    const nextVisibleSection = visibleSorted[visibleSorted.length - 1];
    nextStep = parseFloat(nextVisibleSection?.Sequence);

    if (
      !IdentityVisited
      && (IdentityHolder?.HasQuestions || identityResponse?.HasQuestions)
      && (parseInt(Sequence, 10) === parseInt(GuarantorPages.PersonalPartyMatch, 10)
        || parseFloat(Sequence) === parseInt(GuarantorPages.PersonalAddress, 10))
    ) {
      nextStep = parseFloat(GuarantorPages.IdentityCheck);
    }

    //  Handle: Client has reached Identiti Verification questions, navigate and save previous page.
    //  Assure correct menu items, associated with coBorrowerIdentityVerificationStep method
    if (
      IsIdentityFetched
      && !IdentityVisited
      && parseInt(Sequence, 10) !== parseInt(GuarantorPages.PersonalPartyMatch, 10)
      && parseFloat(Sequence) !== parseInt(GuarantorPages.PersonalAddress, 10)
    ) {
      // Update identity visited
      dispatch(
        setCoBorrowerIdentityAction({
          ...updateObject?.identityState,
          IdentityVisited: true,
        })
      );
    }

    dispatch(setLoader(false));

    return nextStep;
  };
};
