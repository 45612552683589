import React, {
  FC as FC_, useMemo, Fragment, memo, useEffect, useCallback
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { Collapse, DataPreviewText } from '@jkhy/vsg-design-system';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import { CustomCollapse as CustomCollapse_, ReviewApplicationData as ReviewApplicationData_ } from './ReviewApplicationGeneralSection';
import { getSubSectionPageHeader, isNullOrUndefined } from '../../../utils/Helper';
import { ExternalPages, ExternalPageSectionName, GuarantorPages } from '../../../utils/Enums';
import { ExpandState as ExpandState_ } from './ReviewApplication';
import Section_ from '../../../data/models/Section';
import { getGuarantorGuid } from '../../../utils/LocalStorageManager';
import ReviewApplicationLinkForwardStep from './LinkForwardStep/ReviewApplicationLinkForwardStep';
import { RoutingProps as RoutingProps_ } from '../../../utils/Types';

type ReviewApplicationCustomCollapseProps = {
  collapse: CustomCollapse_;
  page: typeof ExternalPages[keyof typeof ExternalPages] | typeof GuarantorPages[keyof typeof GuarantorPages];
  expandState: ExpandState_;
  setExpandState: React.Dispatch<React.SetStateAction<ExpandState_>>;
};

const ReviewApplicationCustomCollapse: FC_<ReviewApplicationCustomCollapseProps> = ({
  collapse,
  page,
  expandState,
  setExpandState,
}: ReviewApplicationCustomCollapseProps) => {
  const {
    id, title, subTitle, fields, link, pageFields,
  } = collapse;

  const expanded = !!expandState && !!expandState[id];

  const { model, } = useParams<RoutingProps_>();

  const { holder, sections, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      sections: Section_[];
    }
  >(state => ({
    holder: state.holderState?.Holder,
    sections: state.sectionsState.VisibleSections,
  }));

  const applicationUID = holder?.UID;

  const location = useLocation();

  const toggleCollapse = useCallback(
    (isExpanded: boolean) => {
      const currentExpandState = { ...expandState, [id]: isExpanded, };
      setExpandState(prevState => ({ ...prevState, [page]: currentExpandState, }));
    },
    [expandState, id, page, setExpandState]
  );

  useEffect(() => {
    // Register collapse in global state
    if (expandState && isNullOrUndefined(expandState[id])) {
      toggleCollapse(true);
    }
  }, [expandState, expanded, id, setExpandState, toggleCollapse]);

  const linkPath = useMemo(() => {
    const guarantorGuid = getGuarantorGuid();
    if (!isNullOrUndefined(guarantorGuid)) {
      const { search = '', } = location;

      return link && model ? `/guarantor/${model}/${link.page}/${guarantorGuid}/${search}` : '';
    }

    return link && model && applicationUID ? `/section/${model}/${link.page}/${applicationUID}` : '';
  }, [applicationUID, link, location, model]);

  const coBorrowerTitle = useMemo(() => {
    return page === ExternalPages.CoBorrower
      ? getSubSectionPageHeader(sections, ExternalPageSectionName.Guarantor, 'Co-Borrower/Guarantor Information')
      : '';
  }, [page, sections]);

  return (
    <Collapse key={id} dataUI={id} title={title} subTitle={subTitle} expanded={expanded} onClick={() => toggleCollapse(!expanded)}>
      {coBorrowerTitle && <h6 className="mb-2">{coBorrowerTitle}</h6>}

      {fields.map((field: ReviewApplicationData_) => {
        const [fieldName] = Object.keys(field);
        const fieldValues = field[fieldName];

        const {
          dataUI: fieldDataUI, className = 'mb-2', bold = 'label', value, customLabel = '', pageField = {}, customComponent,
        } = fieldValues;

        // Default page field is object key, but you can add different. Some fields like addresses use one page field for many fields.
        const currentPageField = pageFields[fieldName] || pageField;

        const label = customLabel || currentPageField?.CustomLabel || currentPageField?.Label;

        if (customComponent) {
          return <Fragment key={fieldDataUI}>{customComponent}</Fragment>;
        }

        return <DataPreviewText key={fieldDataUI} dataUI={fieldDataUI} className={className} bold={bold} label={label} text={value} />;
      })}

      {link && <ReviewApplicationLinkForwardStep path={linkPath} text={link.text} />}
    </Collapse>
  );
};

export default memo(ReviewApplicationCustomCollapse);
