import { Reducer as Reducer_ } from 'redux';
import { HolderActions as HolderActions_, HolderState as HolderState_ } from '../actions/Holder';

const holderReducer: Reducer_<HolderState_, HolderActions_> = (state = { IsHolderFetched: false, Holder: null, }, action) => {
  switch (action.type) {
    case 'GotHolder': {
      return { ...action.holderState, };
    }
    case 'SaveHolder': {
      return { ...action.holderState, };
    }
    case 'IgnoreChange':
    default: {
      return state;
    }
  }
};

export default holderReducer;
