import BaseAPI from './BaseApi';
import RenewalHolder_ from '../models/RenewalHolder';
import ResponseBase_ from '../models/ResponseBase';

export default class RenewalAPI extends BaseAPI {
  static getRenewal = id => BaseAPI.performAjax<RenewalHolder_>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionRenewalApplication/InquireRenewal?id=${id}`,
  });

  static facilityUpdate = item => BaseAPI.performAjax<ResponseBase_<any>>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionRenewalApplication/QDFacilityUpdate`,
    data: item,
  });
}
