import React, { FC as FC_, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { ExternalPages, IdentityCheckStatus } from '../../../../utils/Enums';
import {
  booleanStringComparison, currencyFormat, dateFormat, grossIncomeText, isNullOrUndefined, yesNoOrEmptyAnswer
} from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { AppState as AppState_ } from '../../../../redux/AppState';
import Employment_ from '../../../../data/models/Employment';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { QDApplicationTypeAnswer } from '../../../../data/models/Information';

type EmploymentProps = {
  guarantorFullName?: string;
  employment: Employment_;
} & ReviewApplicationSectionProps_;

const Employment: FC_<EmploymentProps> = ({
  isCoBorrower,
  guarantorFullName = '',
  page,
  employment,
  sectionTitle,
  expandState,
  setExpandState,
}: EmploymentProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
    holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder:QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const pageFields = isCoBorrower ? CoBorrowerPageField?.IncomeandExpenses?.General : ApplicationPageField?.IncomeandExpenses;

  const showDBAField = () => {
    const {
      Information: { ApplicationTypeAnswer, },
      BorrowerP: { Personal: { IdParty, PartyMatchConfirmedByUser, IdentityCheck, }, },
    } = holder;

    return ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString()
           && IdParty
           && PartyMatchConfirmedByUser
           && IdentityCheck === IdentityCheckStatus.Validated;
  };

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !employment) return [];

    const {
      Id: employmentId,
      EmploymentStartDate,
      EmployerName,
      EmploymentTypeCodeStr,
      GrossMonthlyBonus,
      GrossAnnuallyBonus,
      GrossBonusPeriodEnumStr,
      GrossMonthlyEmploymentIncome,
      GrossAnnuallyEmploymentIncome,
      GrossEmploymentIncomePeriodEnumStr,
      JobTitle,
      OtherMonthlyPayments,
      MonthlyHousingExpense,
      OtherMonthlyIncome,
      OtherAnnuallyIncome,
      OtherIncomePeriodEnumStr,
      OperatesAsDBA,
      IdPartyDBA,
      PersonalDBA,
    } = employment || {};

    const grossBonus = grossIncomeText(GrossMonthlyBonus, GrossAnnuallyBonus, GrossBonusPeriodEnumStr);
    const grossIncome = grossIncomeText(GrossMonthlyEmploymentIncome, GrossAnnuallyEmploymentIncome, GrossEmploymentIncomePeriodEnumStr);
    const otherIncome = grossIncomeText(OtherMonthlyIncome, OtherAnnuallyIncome, OtherIncomePeriodEnumStr);

    const isGeneralExternalGuarantorPage = isCoBorrower && page === ExternalPages.CoBorrower;

    const title = isGeneralExternalGuarantorPage ? 'Name:' : 'Employer:';
    const subTitle = isGeneralExternalGuarantorPage ? guarantorFullName : EmployerName;

    const linkText = isGeneralExternalGuarantorPage ? 'Go to Co-borrower step to edit' : 'Go to employment step to edit';

    return [
      {
        id: `review-employment-${employmentId}-collapse`,
        title,
        subTitle,
        pageFields,
        link: { page, text: linkText, },
        data: {
          Employer: {
            dataUI: `income-expenses-${employmentId}-employer-name-field`,
            value: EmployerName,
          },
          EmploymentStartDate: {
            dataUI: `income-expenses-${employmentId}-employment-start-date-field`,
            value: dateFormat(EmploymentStartDate),
          },
          EmploymentType: {
            dataUI: `income-expenses-${employmentId}-employment-type-field`,
            value: EmploymentTypeCodeStr,
          },
          GrossBonus: {
            dataUI: `income-expenses-${employmentId}-gross-bonus-field`,
            value: grossBonus,
          },
          GrossEmploymentIncome: {
            dataUI: `income-expenses-${employmentId}-gross-income-field`,
            value: grossIncome,
          },
          JobTitle: {
            dataUI: `income-expenses-${employmentId}-job-title-field`,
            value: JobTitle,
          },
          OtherMonthlyPayments: {
            dataUI: `income-expenses-${employmentId}-monthly-alimony-payments-field`,
            value: currencyFormat(OtherMonthlyPayments),
          },
          MonthlyHousingExpenses: {
            dataUI: `income-expenses-${employmentId}-monthly-housing-field`,
            value: currencyFormat(MonthlyHousingExpense),
          },
          OtherIncome: {
            dataUI: `income-expenses-${employmentId}-alimony-field`,
            value: otherIncome,
          },
          OperatesAsDBA: {
            dataUI: `income-expenses-${employmentId}-operates-as-dba-field`,
            value: yesNoOrEmptyAnswer(OperatesAsDBA),
            customLabel: 'Are you operating as a DBA?',
            isVisible: !isCoBorrower && holder?.Information?.ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString(),
          },
          ApplicableDBA: {
            dataUI: `income-expenses-${employmentId}-applicable-dba-field`,
            value: PersonalDBA,
            customLabel: 'Which DBA is applicable for this request?',
            isVisible: !isCoBorrower && showDBAField() && IdPartyDBA && booleanStringComparison(OperatesAsDBA),
          },
          PersonalDBA: {
            dataUI: `income-expenses-${employmentId}-custom-dba-field`,
            customLabel: 'DBA Name',
            value: PersonalDBA,
            isVisible: !isCoBorrower && booleanStringComparison(OperatesAsDBA) && (isNullOrUndefined(IdPartyDBA) || `${IdPartyDBA}` === '0'),
          },
        },
      }
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFields, employment, isCoBorrower, page, guarantorFullName]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

Employment.defaultProps = {
  guarantorFullName: '',
};

export default memo(Employment);
