import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import QdPrequalificationApi from '../../data/api/QDPrequalificationApi';
import ModelAPI from '../../data/api/ModelApi';
import { ApplicableFor } from '../../utils/Enums';
import { setPageFieldObj } from '../../utils/Helper';
import { setPrequalificationModelState, PrequalificationModelState as PrequalificationModelState_ } from './PrequalificationModel';
import GeneratorHost, { IGeneratorParameters as GeneratorParameters_ } from '../../utils/GeneratorHost';
import { QuickDecisionPageTypes } from '../../data/models/PageField';
import QdApplicationSection_ from '../../data/models/QDApplicationSection';
import SubSection_ from '../../data/models/Subsection';
import { LoadingAction as LoadingAction_, setLoader } from './Loading';
import { setPrequalificationHolder } from './PrequalificationHolder';
import { setGuid } from '../../utils/LocalStorageManager';
import QDPrequalification_ from '../../data/models/Prequalification/QDPrequalification';

type PrequalificationGeneratorParams = {
  dispatch: Dispatch_;
  onFail: (errors) => void;
};

export type PrequalificationDataParameters = {
  guid: string;
  email: string;
  model: string;
};

const getPrequalificationDataGenerator = (params: PrequalificationDataParameters, dispatch: Dispatch_) => {
  // eslint-disable-next-line func-names
  return function* () {
    dispatch(setLoader(true));

    const { model: modelCode, guid, email, } = params;
    const model = yield ModelAPI.getApplicationModel(modelCode);
    const sections = yield ModelAPI.getApplicationSections(modelCode);
    const subSections = yield ModelAPI.getApplicationSubSections(modelCode);

    const formattedSections = sections
      .filter((section: QdApplicationSection_) => section.ApplicableFor === ApplicableFor.Prequalification)
      .sort((a, b) => parseInt(a.Sequence, 10) - parseInt(b.Sequence, 10))
      .map((section: QdApplicationSection_) => {
        const currentSubSections = subSections.filter((subSection: SubSection_) => subSection.IdQDApplicationSection === section.Id);

        return {
          ...section,
          SubSections: currentSubSections,
        };
      });

    const pageFields = yield ModelAPI.getApplicationPageFields(model.IdPrequalificationPageFieldTemplate, QuickDecisionPageTypes.QDPrequalification);
    const formattedPageFields = setPageFieldObj(pageFields);

    const holder: QDPrequalification_ = yield QdPrequalificationApi.inquire(guid, email, modelCode);
    setGuid(holder.GUID);
    dispatch(setPrequalificationHolder(holder));

    dispatch(setLoader(false));

    const modelState: PrequalificationModelState_ = {
      ModelHolder: {
        Model: model,
        Sections: formattedSections,
        SubSections: subSections,
        PageFields: pageFields,
        ApplicationPageFields: formattedPageFields,
      },
      IsModelHolderFetched: true,
    };

    dispatch(setPrequalificationModelState(modelState));
  };
};

export const getPrequalificationDataActionCreator: ActionCreator_<ThunkAction_<
  Promise<void>,
  boolean,
  PrequalificationDataParameters,
  LoadingAction_
>> = (pars: PrequalificationDataParameters, unauthorized: () => void, onFail: () => void) => {
  return async (dispatch: Dispatch_) => {
    const generatorParams: GeneratorParameters_<PrequalificationGeneratorParams> = {
      dispatch,
      onFail: errors => {
        const [error] = errors;
        switch (error?.Code) {
          case 403: {
            unauthorized();
            break;
          }
          case 401: {
            onFail();
            break;
          }
          default:
            break;
        }
      },
    };

    const generator = getPrequalificationDataGenerator(pars, dispatch);
    await new GeneratorHost(generatorParams).run(generator);
  };
};
