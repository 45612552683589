import React, {
  FC as FC_, useState, useEffect, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../redux/AppState';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AddressDetails as AddressDetails_, ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../utils/Types';
import { AddressType, ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import { booleanStringComparison } from '../../../utils/Helper';
import { getGoogleApiAddressAsString } from '../../../utils/Address';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import Page from '../Page/Page';
import BusinessAddressModel_ from '../../../data/models/BusinessAddress';
import BusinessAddressSettings from './BusinessAddressSettings';
import QDAddress from '../../../data/models/QDAddress';
import PageField from '../../../data/models/PageField';
import BusinessAddress_ from '../../../data/models/PageFields/BusinessAddress/BusinessAddress';

export interface BusinessAddressProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[],
    holder: QdApplicationHolder_,
    section: any,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const BusinessAddress: FC_<BusinessAddressProps> = (props: BusinessAddressProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const {
    BorrowerB: {
      Business: { Id: IdQDParty, IdQDApplication, },
    },
  } = holder;
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.BusinessAddresses);
  const bAddressPF = modelState?.ModelHolder?.ApplicationPageField?.BusinessAddress;

  const businessAddressPageFields: BusinessAddress_ = useMemo(() => {
    const businessAddressGeneralPageFields = bAddressPF?.General;
    const { SortOrder: googleAddressFieldSortOrder, ...googleAddressPageField } = businessAddressGeneralPageFields?.CurrentAddress;
    const { SortOrder: mailingAddressFieldSortOrder, } = businessAddressGeneralPageFields?.MailingAddress;

    return {
      ...bAddressPF?.General,
      StreetAddress: { ...googleAddressPageField, FieldName: 'StreetAddress', SortOrder: googleAddressFieldSortOrder + 1, },
      City: { ...googleAddressPageField, FieldName: 'City', SortOrder: googleAddressFieldSortOrder + 2, },
      State: { ...googleAddressPageField, FieldName: 'State', SortOrder: googleAddressFieldSortOrder + 3, },
      ZipCode: { ...googleAddressPageField, FieldName: 'ZipCode', SortOrder: googleAddressFieldSortOrder + 4, },
      DifferentMailingAddress: { ...new PageField(), FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressFieldSortOrder - 1, },
      MailingZip: { ...businessAddressGeneralPageFields.ZipCode, FieldName: 'MailingZip', SortOrder: mailingAddressFieldSortOrder + 1, },
      MailingCity: { ...businessAddressGeneralPageFields.City, FieldName: 'MailingCity', SortOrder: mailingAddressFieldSortOrder + 2, },
      MailingState: { ...businessAddressGeneralPageFields.State, FieldName: 'MailingState', SortOrder: mailingAddressFieldSortOrder + 3, },
    };
  }, [bAddressPF]);

  const pageSettings = useMemo(() => {
    return new BusinessAddressSettings([businessAddressPageFields], [section?.SubSections[0]]);
  }, [businessAddressPageFields, section]);

  const [businessAddress, setBusinessAddress] = useState(holder.AddressesB);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[]) => {
    const modifiedBusinessAddress = businessAddress.BusinessAddresses.map((ba: QDAddress) => {
      const editItem = { ...ba, };
      editItem.AddressType = AddressType.Current;
      editItem.IdQDParty = IdQDParty;
      editItem.IdQDApplication = IdQDApplication;
      editItem.IsSavedByUser = true;

      // Reset mailing fields
      if (!booleanStringComparison(editItem.DifferentMailingAddress)) {
        editItem.MailingAddress = '';
        editItem.MailingCity = '';
        editItem.MailingState = '';
        editItem.MailingZip = '';
      }

      return editItem;
    });

    const newBusinessAddresses = {
      ...businessAddress,
      BusinessAddresses: modifiedBusinessAddress,
    };

    setBusinessAddress(newBusinessAddresses);
    props.onSubmit(invalidPageFields, holder, newBusinessAddresses, ExternalPages.BusinessAddresses, false);
  };

  const onChange = (
    pageField: PageFieldExtended_<QdApplicationHolder_, BusinessAddressModel_>,
    value: string,
    e: React.ChangeEvent,
    text: string
  ) => {
    const { ObjectProperty, ObjectIndex, ObjectPropertyStr, } = pageField;

    businessAddress.BusinessAddresses[ObjectIndex][ObjectProperty] = value;
    if (ObjectPropertyStr) {
      businessAddress.BusinessAddresses[ObjectIndex][ObjectPropertyStr] = text;
    }

    setBusinessAddress({ ...businessAddress, });
  };

  const onAddressSelect = (pageField: PageFieldExtended_<QdApplicationHolder_, BusinessAddressModel_>, addressDetails: AddressDetails_) => {
    const { ObjectProperty, ObjectIndex, } = pageField;

    if (addressDetails) {
      const changedAddressData = { ...businessAddress, };
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;

      changedAddressData.BusinessAddresses[ObjectIndex] = {
        ...changedAddressData.BusinessAddresses[ObjectIndex],
        [ObjectProperty]: fullAddress,
        Address: streetAddress,
        City: city,
        State: state,
        Zip: zipCode,
      };

      setBusinessAddress({ ...changedAddressData, });
    }
  };

  useEffect(() => {
    const existingAddress = businessAddress?.BusinessAddresses?.find(a => a?.AddressType === AddressType.Current);

    if (existingAddress) {
      existingAddress.GoogleAPIAddress = getGoogleApiAddressAsString(existingAddress);
    }

    const newAddress = existingAddress || new QDAddress();

    setBusinessAddress({ ...businessAddress, BusinessAddresses: [newAddress], });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={onChange}
          onAddressSelect={onAddressSelect}
          holder={holder}
          subHolder={businessAddress}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.BusinessAddresses}`}
        />
      )}
    </>
  );
};

export default BusinessAddress;
