import { IconsSolid } from '@jkhy/vsg-design-system';
import moment from 'moment';
import { validateDateRanges } from '../../../../utils/Validator';
import PageSettings from '../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../data/models/PageField';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import BorrowerPHolder_ from '../../../../data/models/BorrowerPHolder';
import { ValidationRegexConstants } from '../../../../utils/Enums';
import InputMasksConstants from '../../../../utils/InputMasksConstants';
import {
  PersonalBorrowerAdditionalSettings as PersonalBorrowerAdditionalSettings_,
  ValidateFormatResult as ValidateFormatResult_
} from '../../../../utils/Types';
import { joinValidElementsInString, pascalCaseToWord } from '../../../../utils/Helper';
import Messages from '../../../../utils/Messages';

// Database field size/length(40), it consider formatting spaces(2) in concatenated (First, Middle and Last Names) result
export const CONTACT_NAME_LENGTH_LIMITATION = 38;

export default class PersonalBorrowerSettings extends PageSettings<QdApplicationHolder_, BorrowerPHolder_, PersonalBorrowerAdditionalSettings_> {
  pageFieldsMapper(pageField: PageField_, settings: PersonalBorrowerAdditionalSettings_): PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_> {
    if (!pageField) {
      return null;
    }

    const { borrower, hasIDVerificationShowed, isPartyMatched, } = settings;
    const getContactNameInvalidMessage = (invalidLabels: string[]) => {
      let concatenatedFields = '';
      if (invalidLabels.length === 0) return concatenatedFields;

      if (invalidLabels.length > 2) {
        concatenatedFields = joinValidElementsInString(invalidLabels, ', ');
        const lastCommaIdx = concatenatedFields.lastIndexOf(',');
        // eslint-disable-next-line max-len
        concatenatedFields = `${concatenatedFields.substring(0, lastCommaIdx)} and ${concatenatedFields.substring(lastCommaIdx + 1, concatenatedFields.length)}`;
      } else if (invalidLabels.length === 2) {
        concatenatedFields = joinValidElementsInString(invalidLabels, ' and ');
      } else {
        concatenatedFields = invalidLabels.pop() || '';
      }

      return `The entered value on the ${concatenatedFields} fields cannot exceed ${CONTACT_NAME_LENGTH_LIMITATION} characters.`;
    };

    // TODO: Remove check, once ContactName length of Party table is alter to same length as FullName in QDParty table
    const checkContactNameLimitation = (subHolder: BorrowerPHolder_, pageF:any):ValidateFormatResult_ => {
      const {
        FirstName,
        MiddleName,
        LastName,
      } = subHolder.Personal;

      const firstNameLength = (FirstName ?? '').length;
      const middleNameLength = (MiddleName ?? '').length;
      const lastNameLength = (LastName ?? '').length;

      const totalLength = firstNameLength + middleNameLength + lastNameLength;
      const withinLimitation = totalLength <= CONTACT_NAME_LENGTH_LIMITATION;

      const result:ValidateFormatResult_ = {
        IsValid: withinLimitation,
        InvalidMessage: '',
        InvalidFieldNames: [],
        RelatedFieldNames: ['FirstName', 'MiddleName', 'LastName'],
      };

      if (result.IsValid) return result;

      switch (pageF.FieldName) {
        case 'FirstName':
          if (firstNameLength > 0) result.InvalidFieldNames.push(pageF.FieldName);
          if (middleNameLength > 0) result.InvalidFieldNames.push('MiddleName');
          if (lastNameLength > 0) result.InvalidFieldNames.push('LastName');
          break;
        case 'MiddleName':
          if (firstNameLength > 0) result.InvalidFieldNames.push('FirstName');
          if (middleNameLength > 0) result.InvalidFieldNames.push(pageF.FieldName);
          if (lastNameLength > 0) result.InvalidFieldNames.push('LastName');
          break;
        case 'LastName':
          if (firstNameLength > 0) result.InvalidFieldNames.push('FirstName');
          if (middleNameLength > 0) result.InvalidFieldNames.push('MiddleName');
          if (lastNameLength > 0) result.InvalidFieldNames.push(pageF.FieldName);
          break;
        default: break;
      }
      const invalidLabels = result.InvalidFieldNames.map(name => pascalCaseToWord(name));
      result.InvalidMessage = getContactNameInvalidMessage(invalidLabels);

      return result;
    };

    const IsNameValid = (_appHolder: any, subHolder:BorrowerPHolder_, pf: any) => {
      if (pf.IsHidden) return true;

      const value = subHolder.Personal[pf.ObjectProperty];
      let isValid = !!value;

      // check for required content
      pf.ValidationMessage = '';
      if (isValid === false) {
        if (pf.Required) { 
          pf.ValidationMessage = Messages.REQUIRED_FIELD; 
          return false;
        }
        else return true;
      }

      // check content limitation
      const res  = checkContactNameLimitation(subHolder, pf); 
      isValid &&= res.IsValid;
      if (!isValid) {
        pf.ValidationMessage = res.InvalidMessage;
      }
      
      return isValid;
    };

    switch (pageField.FieldName) {
      case 'FirstName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-first-name-field',
          ObjectType: 'Personal',
          ObjectProperty: 'FirstName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 33,
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
          onBlurCustomValidation: checkContactNameLimitation,
          IsValid: IsNameValid
        });
      case 'MiddleName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-middle-name-field',
          ObjectType: 'Personal',
          ObjectProperty: 'MiddleName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 32,
          },
          IsReadOnly: isPartyMatched && borrower?.MiddleName?.length > 0,
          onBlurCustomValidation: checkContactNameLimitation,
          IsValid: IsNameValid
        });
      case 'LastName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-last-name-field',
          ObjectType: 'Personal',
          ObjectProperty: 'LastName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 33,
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
          onBlurCustomValidation: checkContactNameLimitation,
          IsValid: IsNameValid
        });
      case 'SSN':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-SSN-field',
          ObjectType: 'Personal',
          ObjectProperty: 'TIN',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.SSN_TAX_ID,
            inputType: 'password',
            regexStr: ValidationRegexConstants.TIN_SSN,
            errorMessage: `${pageField?.Label || 'SSN'} must be in format: 000000000`,
            className: 'text-left',
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });
      case 'MobilePhone':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-telephone-field',
          ObjectType: 'Personal',
          ObjectProperty: 'PreferredPhone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'phone',
            icon: IconsSolid.faPhone,
          },
        });
      case 'HomePhone':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-home-phone-field',
          ObjectType: 'Personal',
          ObjectProperty: 'HomePhone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Home #'} is incorrect`,
            inputType: 'phone',
            icon: IconsSolid.faPhone,
          },
        });
      case 'EmailAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-email-address-field',
          ObjectType: 'Personal',
          ObjectProperty: 'Email',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            icon: IconsSolid.faEnvelope,
            inputType: 'email',
            maxLength: 100,
          },
          IsReadOnly: true,
        });
      case 'DateOfBirth':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-date-of-birth-field',
          ObjectType: 'Personal',
          ObjectProperty: 'DOB',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, app, pf) => {
            const result = app.Personal.DOB;
            if (moment().diff(result, 'year') >= 18) return true;

            const pageF = pf;
            pageF.ValidationMessage = 'Invalid birth date. The applicant must be 18 years or older.';
            return false;
          },
          Component: {
            type: 'datepicker',
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });
      case 'USCitizen':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-US-citizen-field',
          ObjectType: 'Personal',
          ObjectProperty: 'USCitizenFlag',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });
      case 'IDType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-ID-type-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IDTypeCode',
          ObjectPropertyStr: 'IDTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'IdType',
              ListType: 'LtItems',
            },
          },
        });
      case 'IDNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-ID-number-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IDNumber',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 20,
          },
        });
      case 'IDIssueDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-issue-date-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IDIssueDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (
            appHolder: QdApplicationHolder_,
            subHolder: BorrowerPHolder_,
            pageF: PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_>
          ) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(appHolder, subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'IDIssuer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-issuer-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IDIssuer',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });
      case 'IDExpirationDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-expiration-date-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IDExpirationDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeStart: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (
            appHolder: QdApplicationHolder_,
            subHolder: BorrowerPHolder_,
            pageF: PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_>
          ) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(appHolder, subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'PercentOwnership':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-percent-ownership-field',
          ObjectType: 'Personal',
          ObjectProperty: 'PercentOwnership',
          ObjectPropertyType: 'number',
          IsValid: (appHolder, app, pf) => {
            if (pageField.IsHidden || !pageField.Required) return true;

            const result = app.Personal.PercentOwnership;
            if (result > 0 && result <= 1) return true;

            const pageF = pf;
            pageF.ValidationMessage = 'The percentage should be between 0 and 100';
            return false;
          },
          Component: {
            type: 'maskedinput',
            inputType: 'percent',
            icon: IconsSolid.faPercent,
          },
        });
      case 'ServeInMilitary':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-serve-in-military-field',
          ObjectType: 'Personal',
          ObjectProperty: 'IsMilitaryService',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });
      case 'MilitaryServiceStatus':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'personal-borrower-military-service-status-field',
          ObjectType: 'Personal',
          ObjectProperty: 'MilitaryServiceStatusCode',
          ObjectPropertyStr: 'MilitaryServiceStatusCodeStr',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: (appHolder_, owner) => {
            const {
              Personal: { IsMilitaryService, },
            } = owner;
            return typeof IsMilitaryService === 'boolean' ? !IsMilitaryService : `${IsMilitaryService}` !== 'true';
          },
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'MilitaryServiceStatus',
              ListType: 'LtItems',
            },
          },
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
