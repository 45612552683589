/* eslint-disable import/prefer-default-export */
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from './Types';
import { ExternalPages, GuarantorPages } from './Enums';
import QDPartyDisclosure_ from '../data/models/QDPartyDisclosure';

export type DisclosuresPartyStatuses = {
  // Example -> { guid: true }
  [key: string]: boolean;
};

type DisclosureDocumentData = {
  isOpened: boolean;
  documentBytes: string;
  name: string;
};

export type DisclosuresDocuments = {
  // Example -> { guid: url }
  [key: string]: DisclosureDocumentData;
};

export const checkIsDisclosuresHasEmailTemplate = (modelState: ModelState_<ExternalModelHolder_>, page: ExternalPages | GuarantorPages) => {
  const {
    ModelHolder: {
      Model: { IdEmailTemplateInformationPageDisclosures, IdEmailTemplateDisclosures, },
    },
  } = modelState;

  if (page === ExternalPages.InformationMembership || page === GuarantorPages.Information) {
    return !!IdEmailTemplateInformationPageDisclosures;
  }

  if (page === ExternalPages.Disclosures || page === GuarantorPages.Disclosures) {
    return !!IdEmailTemplateDisclosures;
  }

  return false;
};

export const checkHasPartyAgreedEarlier = (guid: string, parties: QDPartyDisclosure_[]): boolean => {
  return !!parties.find(partyFromServer => partyFromServer.AgreedEarlier && partyFromServer.PartyGuid === guid);
};

export const checkAreAllPartiesAgreed = (statuses: DisclosuresPartyStatuses): boolean => {
  return Object.values(statuses).every(isAgree => isAgree === true);
};

export const checkAreAllDocumentsOpened = (disclosureDocuments: DisclosuresDocuments): boolean => {
  return Object.values(disclosureDocuments).every(({ isOpened, }) => isOpened === true);
};

export const checkHasDisclosures = (concatenatedDisclosures: string, disclosureDocuments: DisclosuresDocuments): boolean => {
  return !!concatenatedDisclosures || Object.keys(disclosureDocuments).length > 0;
};
