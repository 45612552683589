import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../../redux/actions/CoBorrowerIdentity';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { isNullOrUndefined, booleanStringComparison, checkIsPartyMatched } from '../../../../../utils/Helper';
import { checkHasIDVerificationShowed } from '../../../../../utils/IdentityVerification';
import Page from '../../../Page/Page';
import { GuarantorPageSectionName_, GuarantorPages, PartyType } from '../../../../../utils/Enums';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import Borrower_ from '../../../../../data/models/Borrower';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationPersonal as QDCoBorrowerApplicationPersonal_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import CoBorrowerPersonalSettings_ from './CoBorrowerPersonalSettings_';

type CoBorrowerPersonalInformationProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationPersonal_,
    sequence: string
  ) => void;
};

const CoBorrowerPersonalInformation_ = (props: CoBorrowerPersonalInformationProps_) => {
  const {
    modelState,
    sectionsState: { VisibleSections: visibleSections, },
    loading,
    holder,
    coBorrowerIdentityState,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
      coBorrowerIdentityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    coBorrowerIdentityState: state.coBorrowerIdentityState,
  }));

  const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;
  const [borrower, setBorrower] = useState(coBorrower);
  const isPersonal = borrower.PartyType === PartyType.Personal;
  const section = visibleSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.PersonalInformation);

  useEffect(() => {
    if (coBorrowerIdentityState?.IdentityVisited && !isNullOrUndefined(`${coBorrower?.IdentityCheck}`)) {
      setBorrower(coBorrower);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coBorrowerIdentityState?.IdentityVisited, coBorrower?.IdentityCheck]);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[]) => {
    props.onSubmit(invalidPageFields, holder, holder.Personal, GuarantorPages.PersonalInformation);
  };

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;

  const updatePersonalBorrower = (pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    borrower[ObjectProperty] = value;
    if (ObjectPropertyStr) borrower[ObjectPropertyStr] = text;

    // Reset MilitaryServiceStatusCodeStr
    if (ObjectProperty === 'IsMilitaryService' && !booleanStringComparison(value)) {
      borrower.MilitaryServiceStatusCode = '';
      borrower.MilitaryServiceStatusCodeStr = '';
    }

    const changed = { ...borrower, };

    setBorrower(changed);

    if (isPersonal) {
      holder.Personal.CoBorrower = changed;
    } else {
      holder.Business.CoBorrower = changed;
    }
  };

  const pageSettings = useMemo(() => {
    const hasIDVerificationShowed = checkHasIDVerificationShowed(borrower, coBorrowerIdentityState);
    const isPartyMatched = checkIsPartyMatched(borrower);

    const additionalSettings = {
      hasIDVerificationShowed,
      isPartyMatched,
      borrower,
    };

    return new CoBorrowerPersonalSettings_([pageFieldHolder?.PersonalInformation?.General], section?.SubSections, additionalSettings);
  }, [borrower, pageFieldHolder, section, coBorrowerIdentityState]);

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updatePersonalBorrower}
          holder={holder}
          subHolder={borrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${isPersonal ? GuarantorPages.PersonalInformation : GuarantorPages.BusinessInformation}`}
        />
      )}
    </>
  );
};

export default CoBorrowerPersonalInformation_;
