/* eslint no-unused-vars: "off" */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators as bindActionCreators_ } from 'redux';
import { connect } from 'react-redux';
import { flatten, partition } from 'lodash';
import { ReactAux, Modal, TextEditorContentWrapper } from '@jkhy/vsg-design-system';
import Base from '../Layout/BaseComponent';
import Loan from './Loan/Loan';
import Information from './Information/Information';
import Declarations from './Declarations/Declarations';
import { rawHtmlParser, validateAddressBlock, validatePhoneNumber } from '../../utils/Helper';
import { AppState as AppState_ } from '../../redux/AppState';
import { setLoader } from '../../redux/actions/Loading';
import PageMissing from '../PageMissing/PageMissing';
import QuestionModel_ from '../../data/models/Question';
import Facility_ from '../../data/models/Facility';
import { updateRenewalHolder } from '../../redux/actions/Renewal';
import QDApplicationBase_ from '../../data/models/QDApplicationBase';
import RenewalSettings from './RenewalSettings';
import Disclosure_ from '../../data/models/Disclosure';
import Submitted from './Submitted/Submitted';
import { QdApplication as QdApplication_ } from '../../data/models/QDApplication';
import { validateQuestionAndSetValidationMessage } from '../../components/Question/QuestionValidate';
import {
  RenewalPropsExtended, RenewalState, InvalidFields, RenewalModelHolder
} from '../../utils/Types';
import { RenewalPages, RenewalPageSectionName } from '../../utils/Enums';
import QdApplicationSection from '../../data/models/QDApplicationSection';
import { setGuid, getGuid } from '../../utils/LocalStorageManager';

class Renewal extends Base<RenewalPropsExtended, RenewalState> {
  constructor(props) {
    super(props);
    const { renewalSequence, } = this.props.match.params;
    const invalidFields = this.constructInvalidState();
    this.state = {
      selected: parseInt(renewalSequence, 0),
      steps: [],
      dataLoaded: false,
      visibleWindow: false,
      windowContent: null,
      invalidFields,
    };
  }

  toggleWindow = () => {
    this.setState(prevState => ({
      visibleWindow: !prevState.visibleWindow,
    }));
  };

  constructInvalidState = (): InvalidFields => {
    const { renewal, } = this.props;
    const additionalFields = RenewalSettings.additionalRenewalPageFields();
    const renewalSettings = new RenewalSettings<RenewalModelHolder, QDApplicationBase_>(
      [renewal.ModelHolder?.ApplicationPageField?.Information],
      null,
      null
    );
    const declarationSettings = new RenewalSettings<Disclosure_, Disclosure_>([renewal.ModelHolder?.ApplicationPageField?.Declarations], null, null);

    return {
      Facilities: [],
      Information: {
        AdditionalFields: additionalFields,
        AppFields: renewalSettings?.PageFields,
      },
      Declarations: declarationSettings?.PageFields,
    };
  };

  getInvalidFieldsForInformationTab = (app: QdApplication_) => {
    const { renewal, } = this.props;
    const [invalidRenewalForBorrowerFields] = partition(
      this.state.invalidFields?.Information?.AdditionalFields,
      pf => !pf.IsValid(app, renewal.ModelHolder?.Renewal?.Application?.Business, pf)
    );

    const [invalidFieldsForApp] = partition(
      this.state.invalidFields?.Information?.AppFields,
      pf => !pf.IsValid(app, renewal.ModelHolder?.Renewal?.Application, pf)
    );

    const InformationQuestionValidation = renewal.ModelHolder.Renewal.Application.Questions.map((q: QuestionModel_) => {
      const { IsValid, ValidationMessage, } = validateQuestionAndSetValidationMessage(q);

      const qTemp = { ...q, IsValid, ValidationMessage, };

      return qTemp;
    });

    const invalidApplicationQuestionValidation = InformationQuestionValidation.filter(r => r.IsValid === false);

    renewal.ModelHolder.Renewal.Application.Questions = InformationQuestionValidation;
    const { updateRenewalHolder: updateRenewalHolder_, } = this.props;
    updateRenewalHolder_({ ...renewal?.ModelHolder, });

    return [...invalidRenewalForBorrowerFields, ...invalidFieldsForApp, ...invalidApplicationQuestionValidation];
  };

  validateAllFields = () => {
    const {
      renewal,
      renewal: {
        ModelHolder: {
          Renewal: { Application: app, },
        },
      },
    } = this.props;
    const { Facilities: facilities = [], } = app;
    const informationFields = this.getInvalidFieldsForInformationTab(app);

    const facilityInvalidField = flatten(
      facilities.map((f: Facility_) => {
        const facilitySettings = new RenewalSettings<QDApplicationBase_, Facility_>([renewal.ModelHolder?.ApplicationPageField?.Renewal], null, null);
        const [invalidFacilitySpecificFields] = partition(facilitySettings.PageFields, pf => !pf.IsValid(null, f, pf));

        return invalidFacilitySpecificFields;
      })
    );

    const invalidDeclarationFields = this.getInvalidFieldsForDeclarationTab();
    return [...facilityInvalidField, ...informationFields, ...invalidDeclarationFields];
  };

  getInvalidFieldsForDeclarationTab = () => {
    const { renewal, } = this.props;
    const declSettings = new RenewalSettings<Disclosure_, QdApplication_>([renewal.ModelHolder?.ApplicationPageField?.Declarations], null, null);
    const [invalidSetOfFields] = partition(declSettings.PageFields, pf => !pf.IsValid(null, renewal.ModelHolder?.Renewal?.Application, pf));
    return [...invalidSetOfFields];
  };

  validate = () => {
    const validFields = this.validateAllFields();
    const {
      renewal: {
        ModelHolder: {
          Renewal: { Application: app, },
        },
      },
    } = this.props;
    const borrower = this.props.renewal?.ModelHolder?.Renewal?.Application?.Business;

    return [
      validFields,
      validFields.length === 0
        && app.ESignConsent === true
        && (app.MailingAddressChangedAnswer ? validateAddressBlock('MailingZip', borrower) : true)
        && (app.MailingAddressChangedAnswer ? validateAddressBlock('MailingAddress', borrower) : true)
        && (app.PhoneNumberChangedAnswer ? validatePhoneNumber(app) : true)
    ];
  };

  componentDidUpdate() {
    const { renewal, } = this.props;

    if (renewal?.IsModelHolderFetched && !this.state.dataLoaded) {
      const guidSessionKey = getGuid();
      const {
        ModelHolder: {
          Renewal: { Application, },
        },
      } = renewal;
      if (!!Application?.GUID && (!guidSessionKey || guidSessionKey === 'undefined')) {
        setGuid(Application?.GUID);
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true, });

      if (Application?.CompleteFlag) this.goToRenewalPage(RenewalPages.Submitted);
    }
  }

  goToNexTab = (renewalSequence: RenewalPages, guid: string = '') => {
    this.goToRenewalPage(renewalSequence, guid);
  };

  getSection = (sectionName: string): QdApplicationSection => {
    const section = this.props.renewal?.ModelHolder?.ApplicationSections.find(s => s.SectionName === sectionName);
    if (section) {
      section.SectionHeaderHTML = rawHtmlParser(section.SectionHeaderHTML);
      section.SectionFooterHTML = rawHtmlParser(section.SectionFooterHTML);
      section.SubSections.map(subSection => {
        const ss = subSection;
        ss.SubSectionName = rawHtmlParser(ss.SubSectionName);
        ss.SubSectionHeaderText = rawHtmlParser(ss.SubSectionHeaderText);
        ss.SubSectionFooterText = rawHtmlParser(ss.SubSectionFooterText);
        return ss;
      });
    }
    return section;
  };

  renderSectionPage = () => {
    const {
      renewal,
      match: { params: routingParams, },
    } = this.props;

    switch (routingParams?.renewalSequence) {
      case RenewalPages.RenewalIdentity: {
        const app = renewal?.ModelHolder?.Renewal?.Application;
        const { Facilities: facilities = [], CompleteFlag, } = app;

        if (CompleteFlag) return <Submitted />;

        const [firstFacility] = facilities;
        return <Loan section={this.getSection(RenewalPageSectionName.Renewal)} qdFacility={firstFacility} goToNextPage={this.goToNexTab} />;
      }
      case RenewalPages.Renewal: {
        const app = renewal?.ModelHolder?.Renewal?.Application;
        const { Facilities: facilities = [], } = app;

        const selectedFacility = facilities.find(f => f.GUID === routingParams?.guid);
        return <Loan section={this.getSection('Renewal')} qdFacility={selectedFacility} goToNextPage={this.goToNexTab} />;
      }
      case RenewalPages.Information:
        return (
          <Information
            section={this.getSection(RenewalPageSectionName.Information)}
            invalidFields={this.state.invalidFields?.Information}
            getInvalidFieldsForInformationTab={this.getInvalidFieldsForInformationTab}
            goToNexTab={this.goToNexTab}
          />
        );
      case RenewalPages.RenewalDeclarations:
        return (
          <Declarations
            section={this.getSection(RenewalPageSectionName.RenewalDeclarations)}
            validate={this.validate}
            getInvalidFieldsForDeclarationTab={this.getInvalidFieldsForDeclarationTab}
            goToRenewalPage={this.goToRenewalPage}
          />
        );
      case RenewalPages.Submitted:
        return <Submitted />;
      default:
        return <PageMissing />;
    }
  };

  content = () => {
    const { loading, } = this.props;
    return (
      <ReactAux>
        {!loading ? this.renderSectionPage() : <div style={{ height: 500, }} />}

        <Modal title="FAQ" closeBtnName="Close" isVisible={this.state.visibleWindow} onClose={this.toggleWindow} dataUI="renewal-faq-modal">
          <TextEditorContentWrapper>{this.state.windowContent}</TextEditorContentWrapper>
        </Modal>
      </ReactAux>
    );
  };
}

export default withRouter(
  connect(
    (state: AppState_) => ({ ...state, }),
    dispatch => bindActionCreators_({ updateRenewalHolder, setLoader, }, dispatch)
  )(Renewal)
);
