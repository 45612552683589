import React, { FC as FC_, useCallback } from 'react';
import { PricingCard, PricingCardsWrapper, ReactAux } from '@jkhy/vsg-design-system';
import LoanOption_ from '../../data/models/QDLoanOption';
import LoanFee_ from '../../data/models/LoanFee';
import { currencyFormat, isNullOrUndefined, percentageFormat } from '../../utils/Helper';

type LoanOptionsProps = {
  loanOptions: LoanOption_[];
  onSelect?: (id: bigint) => void;
};

export const LOAN_OPTIONS_CARDS_CONTAINER = 'loan-options-container';

const LoanOptions: FC_<LoanOptionsProps> = ({ loanOptions, onSelect, }: LoanOptionsProps) => {
  const getFeesData = useCallback((lo: LoanOption_) => {
    if ((lo?.LoanFeeList || []).length > 0) {
      return lo?.LoanFeeList.map(({ FeeName, FeeAmount, FeePercent, }: LoanFee_) => {
        const amount = isNullOrUndefined(FeePercent) ? currencyFormat(FeeAmount) : percentageFormat(FeePercent, 3);

        return {
          title: FeeName,
          amount,
        };
      });
    }

    const result = [];
    if (lo?.FeeRule?.ClosingFeeName) {
      result.push({ title: lo?.FeeRule?.ClosingFeeName, amount: currencyFormat(lo?.FeeRule?.OriginationFee), });
    }
    if (lo?.FeeRule?.RecurringFeeName) {
      result.push({ title: lo?.FeeRule?.RecurringFeeName, amount: currencyFormat(lo?.FeeRule?.RecurringFee), });
    }

    return result;
  }, []);

  const getConditionsData = useCallback((lo: LoanOption_) => {
    const result = [
      {
        title: `${lo?.PaymentFrequencyCodeStr} Payment`,
        amount: lo?.ShowPaymentAmount ? currencyFormat(lo?.PaymentFrequencyAmount, 2) : 'Payment Amount Varies',
        description: [lo?.PaymentTypeEnumStr],
      }
    ];

    const interestRate = {
      title: 'Interest Rate',
      amount: percentageFormat(lo?.Rate + lo?.RateAdd, 3),
      description: [],
    };

    if (lo?.FixedVarCodeStr === 'Fixed') {
      interestRate.amount = percentageFormat(lo?.InterestRateMatrixCalculatedRate, 3);
    }

    if (!lo?.InterestRateDescription) {
      const variableRate = lo.IdInterestRateStr ? `${lo.IdInterestRateStr} + ` : '';
      // eslint-disable-next-line max-len
      const description = lo?.FixedVarCodeStr === 'Fixed' ? 'Fixed Rate' : `${lo.FixedVarCodeStr} Rate: ${variableRate}${percentageFormat(lo?.RateAdd, 3)}`;
      interestRate.description.push(description);
    } else {
      interestRate.description.push(<strong>{`(${lo?.InterestRateDescription})`}</strong>);
    }

    if (lo?.CeilingValue) {
      interestRate.description.push(`${percentageFormat(lo?.CeilingValue, 3)} (Ceiling)`);
    }

    if (lo?.FloorValue) {
      interestRate.description.push(`${percentageFormat(lo?.FloorValue, 3)} (Floor)`);
    }

    result.push(interestRate);

    const apr = {
      title: 'Annual Percentage Rate',
      amount: percentageFormat(lo?.APR, 3),
      description: [],
    };

    result.push(apr);

    return result;
  }, []);

  return (
    <PricingCardsWrapper dataUI={LOAN_OPTIONS_CARDS_CONTAINER} className="mb-3">
      {(loanOptions || []).map((lo: LoanOption_, index: number) => {
        const {
          Id, AmountOffered, Description, Selected,
        } = lo;

        const amount = currencyFormat(AmountOffered, 2);
        const feesData = getFeesData(lo);
        const conditionsData = getConditionsData(lo);

        return (
          <ReactAux key={Id}>
            <PricingCard
              className={index !== loanOptions.length - 1 ? 'mb-2' : ''}
              dataUI={`complete-loan-option-card-${index}`}
              viewMode={!onSelect}
              onSelect={() => onSelect(Id)}
              title={Description}
              amount={amount}
              feesData={feesData}
              conditionsData={conditionsData}
              selected={Selected}
            />
          </ReactAux>
        );
      })}
    </PricingCardsWrapper>
  );
};

LoanOptions.defaultProps = {
  onSelect: null,
};

export default LoanOptions;
