/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC as FC_, useState, useEffect, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { AppState as AppState_ } from '../../../redux/AppState';
import Page from '../Page/Page';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PurposeProductSettings from './PurposeProductSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PurposeProduct_ from '../../../data/models/PurposeProduct';
import ListAPI from '../../../data/api/ListApi';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import Section_ from '../../../data/models/Section';
import ListItem from '../../../data/models/ListItem';
import { deepCopy } from '../../../utils/Helper';

export interface PurposeProductProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, PurposeProduct_>[],
    holder: QdApplicationHolder_,
    section: PurposeProduct_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const PurposeProduct: FC_<PurposeProductProps> = (props: PurposeProductProps) => {
  const {
    holder, loading, modelState, sections,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      sections: Section_[];
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
    sections: state?.sectionsState.VisibleSections,
  }));

  const [purposeProduct, setPurposeProduct] = useState<PurposeProduct_>({} as PurposeProduct_);

  const section = sections.find(s => s.SectionName === ExternalPageSectionName.PurposeProduct);
  const [subSection] = section?.SubSections;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const purposeAndProductPageFields = pageFieldHolder?.PurposeProduct;

  const { PurposePickList: purposePickList, CurrentYear: currentYear, } = holder;
  const model = modelState?.ModelHolder?.Model;
  const maximumLoanAmount = model?.MaximumLoanAmount;
  const minimumLoanAmount = model?.MinimumLoanAmount;

  const additionalData = {
    purposePickList,
    currentYear,
    maximumLoanAmount,
    minimumLoanAmount,
  };

  const pageSettings = useMemo(() => {
    return new PurposeProductSettings([purposeAndProductPageFields], [subSection], additionalData);
  }, [purposeAndProductPageFields, subSection, additionalData]);

  const [qdProducts, setQDProduct] = useState<ListItem[]>([]);

  useEffect(() => {
    const fetchQDProduct = async () => {
      const { Result: result, } = await ListAPI.getLtItemsSearch({ listName: 'QDProduct', });
      setQDProduct(result);
    };

    if (qdProducts.length === 0) {
      fetchQDProduct();
    }
  }, []);

  useEffect(() => {
    const purposeProductDataFromHolder = deepCopy(holder?.PurposeProduct);
    setPurposeProduct(purposeProductDataFromHolder);
  }, [holder?.PurposeProduct]);

  const updatePurposeProduct = (pageField: PageFieldExtended_<QdApplicationHolder_, PurposeProduct_>, value: string, e, text: string) => {
    const {
      ObjectType, ObjectIndex, ObjectProperty, ObjectPropertyStr,
    } = pageField;

    if (ObjectType) {
      if (ObjectIndex) {
        purposeProduct[ObjectType][ObjectIndex][ObjectProperty] = value;
        if (ObjectPropertyStr) purposeProduct[ObjectType][ObjectIndex][ObjectPropertyStr] = text;
      } else {
        purposeProduct[ObjectType][ObjectProperty] = value;
        if (ObjectPropertyStr) purposeProduct[ObjectType][ObjectPropertyStr] = text;
      }
    } else {
      purposeProduct[ObjectProperty] = value;
      if (ObjectPropertyStr) purposeProduct[ObjectPropertyStr] = text;
    }

    if (ObjectProperty === 'ProductTypeCode') {
      const qdProductSC = qdProducts.find(item => item.Code === value);
      purposeProduct.ProductTypeSecondCode = qdProductSC?.SecondCode;
    }

    setPurposeProduct({ ...purposeProduct, });
  };

  const modifiedHolder = () => {
    const business = {
      ...holder.BorrowerB.Business,
    };
    const borrowerB = { ...holder.BorrowerB, Business: business, };
    return { ...holder, PurposeProduct: purposeProduct, BorrowerB: borrowerB, };
  };

  const dispatch = useDispatch();
  const updateHolder = () => {
    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: modifiedHolder(),
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, PurposeProduct_>[]) => {
    updateHolder();
    await props.onSubmit(invalidPageFields, modifiedHolder(), purposeProduct, ExternalPages.PurposeProduct, false);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updatePurposeProduct}
          holder={holder}
          subHolder={purposeProduct}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.PurposeProduct}`}
        />
      )}
    </>
  );
};

export default PurposeProduct;
