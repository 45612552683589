import { changeDocumentTitle } from '../../utils/Helper';
import userManager from '../../utils/UserManager';

const Logout = props => {
  const {
    match: {
      params: { autologout, },
    },
  } = props;

  const pars = !autologout ? {} : { AutoLogout: autologout, };

  userManager.getUser().then(user => {
    if (user && !user.expired) {
      changeDocumentTitle('Logout', null);
      userManager.signoutRedirect({ id_token_hint: user.id_token, extraQueryParams: pars, });
    }
  });
  userManager.removeUser();

  return null;
};

export default Logout;
