import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

const NotImplementedPage = props => {
  const {
    guid, model, sequence, hash,
  } = useParams();
  const { text, } = props;

  return (
    <div>
      <div style={{ textAlign: 'center', fontWeight: 'bold', }}>{text}</div>
      {guid ? (
        <div style={{ textAlign: 'center', fontWeight: 'bold', }}>
          guid param:
          {guid}
        </div>
      ) : (
        ''
      )}
      {model ? (
        <div style={{ textAlign: 'center', fontWeight: 'bold', }}>
          model param:
          {model}
        </div>
      ) : (
        ''
      )}
      {sequence ? (
        <div style={{ textAlign: 'center', fontWeight: 'bold', }}>
          sequence param:
          {sequence}
        </div>
      ) : (
        ''
      )}
      {hash ? (
        <div style={{ textAlign: 'center', fontWeight: 'bold', }}>
          hash param:
          {hash}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

NotImplementedPage.propTypes = {
  text: PropTypes.string.isRequired,
};

export default NotImplementedPage;
