import IdologyQuestion_ from '../models/IdologyQuestion';
import IdologyResult_ from '../models/IdologyResult';
import BaseAPI from './BaseApi';
import PreciseIDResult_ from '../models/PreciseIDResult';

export type IdologySubmitPars = {
  TransactionID: string;
  DifferentiatingQuestions: IdologyQuestion_[];
  Questions: IdologyQuestion_[];
  AdditionalQuestions: IdologyQuestion_[];
  idParty: bigint;
};

export type PreciseIDSubmitPars = {
  IdQDParty: bigint;
  IdParty?: bigint;
  SessionID: string;
  Answers: string[];
};

export default class IdentityVerificationAPI extends BaseAPI {
  // Idology
  static getIdologyQuestions = (qdPartyGuid: string, transactionId: string) => BaseAPI.performAjax<IdologyResult_>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/InquireIdologyQuestions?qdPartyGuid=${qdPartyGuid}&transactionId=${transactionId}`,
  });

  static submitIdologyQuestions = (data: IdologySubmitPars) => BaseAPI.performAjax<IdologyResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/SubmitIdologyQuestions`,
    data,
  });

  static submitIdologyAdditionalQuestions = (data: IdologySubmitPars) => BaseAPI.performAjax<IdologyResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/SubmitIdologyAdditionalQuestions`,
    data,
  });

  static submitIdologyDifferentiatingQuestions = (data: IdologySubmitPars) => BaseAPI.performAjax<IdologyResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/SubmiIdologytDifferentiatingQuestions`,
    data,
  });

  // PreciseID
  static getPreciseIDQuestions = (qdPartyGuid: string, sessionId: string) => BaseAPI.performAjax<PreciseIDResult_>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/InquirePreciseIdQuestions?qdPartyGuid=${qdPartyGuid}&sessionId=${sessionId}`,
  });

  static submitPreciseIDQuestions = (data: PreciseIDSubmitPars) => BaseAPI.performAjax<PreciseIDResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/IdentityVerification/SubmitPreciseIDQuestions`,
    data,
  });
}
