import { Action as Action_ } from 'redux';

export const setUnauthorizedActionCreator = (unauthorized: boolean): UnauthorizedAction => {
  return {
    unauthorized,
    type: 'Set',
  };
};

export interface UnauthorizedAction extends Action_<'Set'> {
  unauthorized: boolean;
}

export default {};
