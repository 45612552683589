import PageSettings from '../../../Page/PageHelpers/PageSettings';
import { validateESignConsent } from '../../../../../utils/Validator';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationDeclarations as QDCoBorrowerApplicationDeclarations_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';

class CoBorrowerDeclarationsSettings extends PageSettings<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationDeclarations_, boolean> {
  pageFieldsMapper(
    pageField: PageField_,
    isBusinessBorrower: boolean
  ): PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationDeclarations_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'ESignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-declarations-esign-consent-field',
          ObjectProperty: 'ESignConsent',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'guarantor-declarations-esign-consent-radio-yes', value: 'true', label: 'Yes', },
              { dataUI: 'guarantor-declarations-esign-consent-radio-no', value: 'false', label: 'No', }
            ],
          },
          IsValid: (coBorrowerHolder, subHolder, pageF) => {
            const { IsValid, InvalidMessage, } = validateESignConsent(subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'RegOQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-declarations-reg-o-question-field',
          ObjectProperty: 'RegOResponse',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'guarantor-declarations-req-o-question-radio-yes', value: 'true', label: 'Yes', },
              { dataUI: 'guarantor-declarations-req-o-question-radio-no', value: 'false', label: 'No', }
            ],
          },
          IsHiddenCalculated: () => isBusinessBorrower,
        });
      case 'RegOResponseMemo':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-declarations-reg-o-response-memo-field',
          ObjectProperty: 'RegOResponseMemo',
          Component: {
            type: 'textarea',
            dataUI: 'guarantor-declarations-reg-o-response-memo-textarea',
          },
          IsHiddenCalculated: () => isBusinessBorrower,
        });

      default:
        return this.PageFields.find(field => field.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}

export default CoBorrowerDeclarationsSettings;
