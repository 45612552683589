import { ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { first } from 'lodash';
import ModelAPI from '../../data/api/ModelApi';
import { setPageFieldObj } from '../../utils/Helper';
import PageFieldHolder_ from '../../data/models/PageFields/PageFieldHolder';
import { IGotRenewalModelHolder as IGotRenewalModelHolder_ } from './Model';
import { LoadingAction as LoadingAction_, setLoader } from './Loading';
import GeneratorHost, { IGeneratorParameters as GeneratorParameters_ } from '../../utils/GeneratorHost';
import PageFieldAPI from '../../data/api/PageFieldApi';
import RenewalAPI from '../../data/api/RenewalApi';
import { RenewalModelHolder as RenewalModelHolder_ } from '../../utils/Types';

// eslint-disable-next-line func-names
export const renewalModelHolderGenerator = function* (id: number, dispatch: Dispatch_) {
  const resultRenewalModel = yield ModelAPI.getModel(id);
  const renewal = yield RenewalAPI.getRenewal(id);
  const sections = yield ModelAPI.getSections(resultRenewalModel.Code);
  const pageFieldsResult = yield PageFieldAPI.getPageFields(resultRenewalModel.IdPageFieldTemplate);

  const pageFieldHolder = setPageFieldObj(pageFieldsResult) as PageFieldHolder_;
  const res: RenewalModelHolder_ = {
    Model: resultRenewalModel,
    Renewal: renewal,
    ApplicationSections: sections,
    ApplicationPageField: pageFieldHolder,
    PageFields: pageFieldsResult,
  };

  const gotRenewalModelHolderAction: IGotRenewalModelHolder_ = {
    renewalModelState: {
      ModelHolder: res,
      IsModelHolderFetched: true,
    },
    type: 'GotRenewalModelHolder',
  };
  dispatch(gotRenewalModelHolderAction);
};

export const getRenewalModelHolderActionCreator: ActionCreator_<ThunkAction_<Promise<LoadingAction_>, boolean, number, LoadingAction_>> = (
  id: number,
  Unauthorized: () => void,
  onFail: () => void
) => {
  return async (dispatch: Dispatch_) => {
    const params: GeneratorParameters_<any> = {
      dispatch,
      onFail: errors => {
        const err = first(errors);
        switch (err?.Code) {
          case 403:
            Unauthorized();
            break;
          case 401:
            onFail();
            break; // perform silent login
          default:
        }
      },
    };
    dispatch(setLoader(true));
    await new GeneratorHost(params).run(renewalModelHolderGenerator.bind(null, id, dispatch));
    return dispatch(setLoader(false));
  };
};

export const updateRenewalHolder = (result: RenewalModelHolder_): IGotRenewalModelHolder_ => ({
  type: 'GotRenewalModelHolder',
  renewalModelState: {
    IsModelHolderFetched: true,
    ModelHolder: result,
  },
});
