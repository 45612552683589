export interface Error {
  Code: number;
  message: string;
}

class ResponseBase<T> {
  Success: boolean;

  Exceptions: Error[];

  Result: T;
}

export default ResponseBase;
