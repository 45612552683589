import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Section } from '@jkhy/vsg-design-system';
import IdologyPage from '../IdentityVerification/Idology/Idology';
import PreciseIDPage from '../IdentityVerification/PreciseID/PreciseID';
import { AppState as AppState_ } from '../../../redux/AppState';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { ExternalPages, IdentityVerificationSystem, ValueConstants } from '../../../utils/Enums';
import { EvalSectionState as EvalSectionState_ } from '../../../redux/actions/VisibleSections';
import {
  ExternalModelHolder as ExternalModelHolder_,
  IdentityVerificationCompleteStatus as IdentityVerificationCompleteStatus_,
  ModelState as ModelState_
} from '../../../utils/Types';
import { IdentityState as IdentityState_, setIdentityState } from '../../../redux/actions/Identity';
import QDPartyDetails_ from '../../../data/models/QDPartyDetails';
import { updateExternalHolder } from '../../../redux/actions/Holder';
import PartyCheck_ from '../../../data/models/PartyCheck';
import PersonalAddress_ from '../../../data/models/PersonalAddress';
import BorrowerPersonalHolder_ from '../../../data/models/BorrowerPHolder';
import { isVerified } from '../../../utils/IdentityVerification';

export const IDENTITY_VERIFICATION_SECTION = 'identity-verification-section';

export default () => {
  const {
    holder, identityState, sectionsState, modelState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      identityState: IdentityState_;
      sectionsState: EvalSectionState_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    identityState: state.identityState,
    sectionsState: state?.sectionsState,
    modelState: state.modelState,
  }));

  const history = useHistory();

  const { IdentityVisited, } = identityState;
  const {
    ModelHolder: { Model: model, IdentitySection: identitySection, },
  } = modelState;

  const [identitySubSection] = identitySection.SubSections;

  useEffect(() => {
    const visibleSorted = sectionsState.VisibleSections;
    const nextVisibleSection = visibleSorted[visibleSorted.length - 1];
    const returnUrl = `/section/${model.Code}/${nextVisibleSection?.Sequence}/${holder.GUIDQDApplication}`;

    if (IdentityVisited) {
      history.push(returnUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdentityVisited]);

  const useIdology = model?.IdentityVerificationSystem === IdentityVerificationSystem.IDology;

  const dispatch = useDispatch();

  const updateIdentityState = () => {
    dispatch(
      setIdentityState({
        ...identityState,
        IsIdentityFetched: true,
        IdentityVisited: true,
      })
    );
  };

  const updateHolder = ({ QDParty, Addresses, }: QDPartyDetails_) => {
    const ownerCheck: PartyCheck_ = { ...holder.OwnerCheck, Personal: QDParty, };
    const personalBorrower: BorrowerPersonalHolder_ = { ...holder.BorrowerP, Personal: QDParty, };
    const personalAddresses: PersonalAddress_ = { ...holder.AddressesP, PersonalAddresses: Addresses, };

    const newHolder: QdApplicationHolder_ = {
      ...holder,
      OwnerCheck: ownerCheck,
      BorrowerP: personalBorrower,
      AddressesP: personalAddresses,
    };

    dispatch(updateExternalHolder(newHolder));
  };

  const completeIdentityVerificationProcess = async (
    identityCheckType: typeof ValueConstants.PreciseID | typeof ValueConstants.Idology,
    verificationStatus?: IdentityVerificationCompleteStatus_,
    partyDetails?: QDPartyDetails_
  ) => {
    updateIdentityState();

    const isPartyVerified = isVerified(identityCheckType, verificationStatus);

    if (isPartyVerified && partyDetails) {
      updateHolder(partyDetails);
    }
  };

  return (
    <Section
      key="identity-verification"
      dataUI={IDENTITY_VERIFICATION_SECTION}
      title={identitySubSection?.SubSectionName}
      headerText={identitySubSection?.SubSectionHeaderText || identitySection?.SectionHeaderHTML}
      footerText=""
    >
      {useIdology ? (
        <IdologyPage
          borrower={holder.BorrowerP.Personal}
          formId={`form-${ExternalPages.IdentityCheck}`}
          completeIdentityVerificationProcess={completeIdentityVerificationProcess}
        />
      ) : (
        <PreciseIDPage
          borrower={holder.BorrowerP.Personal}
          formId={`form-${ExternalPages.IdentityCheck}`}
          completeIdentityVerificationProcess={completeIdentityVerificationProcess}
        />
      )}
    </Section>
  );
};
