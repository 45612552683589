import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';
import QdServiceApi_ from '../../data/api/QDServiceApi';
import Party_ from '../../data/models/Borrower';

export type QDPartyState = {
  QDParty: Party_;
  IsQDPartyFetching: boolean;
  IsQDPartyFetched: boolean;
};

export interface IGotQDParty extends Action_<'GetQDParty'> {
  QDPartyState: QDPartyState;
}

const getQdPartyActionCreator: ActionCreator_<ThunkAction_<Promise<IGotQDParty>, QDPartyState, string, IGotQDParty>> = (guid: string) => {
  return async (dispatch: Dispatch_) => {
    const fetchingParty: IGotQDParty = {
      QDPartyState: {
        QDParty: new Party_(),
        IsQDPartyFetching: true,
        IsQDPartyFetched: false,
      },
      type: 'GetQDParty',
    };
    dispatch(fetchingParty);

    dispatch(setLoader(true));

    const { Result: party, } = await QdServiceApi_.inquireQdParty(guid);

    dispatch(setLoader(false));

    const fetchedParty: IGotQDParty = {
      QDPartyState: {
        QDParty: party,
        IsQDPartyFetching: false,
        IsQDPartyFetched: true,
      },
      type: 'GetQDParty',
    };

    return dispatch(fetchedParty);
  };
};

export default getQdPartyActionCreator;

export const updateGuarantor = (party: Party_): IGotQDParty => ({
  type: 'GetQDParty',
  QDPartyState: {
    QDParty: party,
    IsQDPartyFetching: false,
    IsQDPartyFetched: true,
  },
});
