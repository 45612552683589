import { Reducer as Reducer_ } from 'redux';
import { DisclosuresActions as DisclosuresActions_, DisclosuresState as DisclosuresState_ } from '../actions/Disclosures';

const disclosuresReducer: Reducer_<DisclosuresState_, DisclosuresActions_> = (
  state = {
    informationDisclosures: { hasDisclosures: false, isFetched: false, },
    mainDisclosures: { hasDisclosures: false, isFetched: false, },
  },
  action
) => {
  const { payload, type, } = action;

  switch (type) {
    case 'InformationDisclosures': {
      return {
        ...state,
        informationDisclosures: payload,
      };
    }
    case 'MainDisclosures': {
      return {
        ...state,
        mainDisclosures: payload,
      };
    }
    default:
      return state;
  }
};

export default disclosuresReducer;
