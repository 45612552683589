import { IdentityState as IdentityState_ } from '../redux/actions/Identity';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../redux/actions/CoBorrowerIdentity';
import { PrequalificationModelHolder as PrequalificationModelHolder_ } from '../redux/actions/PrequalificationModel';
import { ExternalModelHolder as ExternalModelHolder_, RenewalModelHolder as RenewalModelHolder_, RoutingProps as RoutingProps_ } from './Types';
import {
  CompletePages, ExternalPages, ExternalPageSectionName, GuarantorPages, RenewalPages
} from './Enums';
import Section_ from '../data/models/Section';
import { joinValidElementsInString, deepComparison } from './Helper';
import QdApplicationSection_ from '../data/models/QDApplicationSection';

export type CoBorrowerAddNowStep = {
  text: string;
  sequence: typeof GuarantorPages[keyof typeof GuarantorPages];
  path: string;
  isActive: boolean;
};

export type NavigationStep = {
  text: string;
  path: string;
  isActive: boolean;
  subSteps: NavigationStep[];
};

type ShouldChangeBrowserTitleProps = {
  currentModelHolder: ExternalModelHolder_ | PrequalificationModelHolder_ | RenewalModelHolder_;
  previousModelHolder: ExternalModelHolder_ | PrequalificationModelHolder_ | RenewalModelHolder_;
  currentSections: QdApplicationSection_[];
  previousSections: QdApplicationSection_[];
  currentPathname: string;
  previousPathname: string;
};

type GetCompleteStepsProps = {
  completePage: typeof CompletePages[keyof typeof CompletePages];
  model: string;
  guid: string;
  modelHolder: ExternalModelHolder_;
  sections: Section_[];
  isDocumentsPageVisible?: boolean;
  isStatusPageVisible?: boolean;
  statusSection?: Section_;
};

export const getCompleteSteps = ({
  completePage,
  model,
  guid,
  modelHolder,
  sections,
  isDocumentsPageVisible = true,
  isStatusPageVisible = true,
}: GetCompleteStepsProps) => {
  let statusSection = sections?.find(s => (s.SectionName || '') === ExternalPageSectionName.Review);
  if (!statusSection) statusSection = modelHolder?.ApplicationSections?.find(s => (s.SectionName || '') === ExternalPageSectionName.Review);

  const steps = [
    {
      text: 'Results',
      path: `/complete/${model}/${guid}/`,
      isActive: completePage === CompletePages.Complete,
    }
  ];

  if (isDocumentsPageVisible) {
    steps.push({
      text: modelHolder?.Model?.DocumentsTabName || 'Documents',
      path: `/documents/${model}/${guid}/`,
      isActive: completePage === CompletePages.Documents,
    });
  }

  if (isStatusPageVisible && statusSection) {
    steps.push({
      text: statusSection.TabName,
      path: `/status/${model}/${guid}/`,
      isActive: completePage === ExternalPageSectionName.Review.toLowerCase(),
    });
  }

  return steps;
};

export const getExternalSectionTabName = (
  matchParams: RoutingProps_,
  modelHolder: ExternalModelHolder_,
  coBorrowerIdentityState: CoBorrowerIdentityState_,
  sections: Section_[],
  identityState?: IdentityState_
) => {
  const {
    sequence, coBorrSequence, page: addNowPage, completePage, guid, model,
  } = matchParams;

  const foundSection = sections.find(section => section.Sequence === sequence || section.Sequence === coBorrSequence);
  let currentSectionTabName = '';

  const isBorrowerApplication = !!sequence;

  if (foundSection) {
    const reviewPageSequence = isBorrowerApplication ? ExternalPages.Review : GuarantorPages.Review;
    currentSectionTabName = foundSection.Sequence === reviewPageSequence ? 'Review' : foundSection.TabName;
  }

  // Identity Check page
  const currentIdentityState = isBorrowerApplication ? identityState : coBorrowerIdentityState;
  const { IsIdentityFetched, IdentityVisited, IdentityHolder, } = currentIdentityState || {};
  const identityPageConditions = [IsIdentityFetched, !IdentityVisited, IdentityHolder?.HasQuestions];
  const isIdentityPageActive = identityPageConditions.every(condition => condition);

  if (isIdentityPageActive) {
    currentSectionTabName = modelHolder.IdentitySection?.TabName || '';
  }

  // Add now pages
  if (addNowPage) {
    // eslint-disable-next-line max-len
    const { IsIdentityFetched: coBorrIsIdentityFetched, IdentityVisited: coBorrIsIdentityVisited, IdentityHolder: coBorrIdentityHolder, } = coBorrowerIdentityState || {};

    let stepTitle = sections.find(section => section.Sequence === addNowPage)?.TabName;

    const coBorrIdentityPageConditions = [coBorrIsIdentityFetched, !coBorrIsIdentityVisited, coBorrIdentityHolder?.HasQuestions];
    const isCoborrIdentityPageActive = coBorrIdentityPageConditions.every(condition => condition);
    if (isCoborrIdentityPageActive) {
      stepTitle = 'Identity';
    }

    currentSectionTabName = joinValidElementsInString([currentSectionTabName, stepTitle], ' - ');
    // Complete pages
  } else if (completePage) {
    const completeSteps = getCompleteSteps({
      completePage,
      model,
      guid,
      modelHolder,
      sections,
    });

    currentSectionTabName = completeSteps.find(step => step.path.includes(completePage))?.text;
  }

  return currentSectionTabName;
};

export const getRenewalSectionTabName = (
  renewalSequence: typeof RenewalPages[keyof typeof RenewalPages],
  renewalModelHolder: RenewalModelHolder_
) => {
  const sections = renewalModelHolder?.ApplicationSections || [];

  const foundSection = sections.find(section => section.Sequence === renewalSequence);
  return foundSection?.TabName || '';
};

export const shouldChangeBrowserTitle = ({
  currentModelHolder,
  previousModelHolder,
  currentSections,
  previousSections,
  currentPathname,
  previousPathname,
}: ShouldChangeBrowserTitleProps) => {
  const currentBrowserTitle = currentModelHolder?.Model?.BrowserTabTitle;
  const previousBrowserTitle = previousModelHolder?.Model?.BrowserTabTitle;

  const areVisibleSectionsEqual = deepComparison(currentSections, previousSections);
  return (
    (currentBrowserTitle !== previousBrowserTitle || currentPathname !== previousPathname || !areVisibleSectionsEqual) && currentSections.length > 0
  );
};

export const shouldChangeFavicon = (
  currentModelHolder: ExternalModelHolder_ | PrequalificationModelHolder_ | RenewalModelHolder_,
  previousModelHolder: ExternalModelHolder_ | PrequalificationModelHolder_ | RenewalModelHolder_
): boolean => {
  const currentFavicon = currentModelHolder?.Model?.FaviconUrl;
  const previousFavicon = previousModelHolder?.Model?.FaviconUrl;
  return currentFavicon !== previousFavicon;
};

export const redirectToCP = () => {
  window.location.href = window.CUSTOMER_PORTAL_URL;
};
