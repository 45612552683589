import { Action as Action_ } from 'redux';
import QDPrequalification_ from '../../data/models/Prequalification/QDPrequalification';

export const GET_PREQUALIFICATION_HOLDER = 'GET_PREQUALIFICATION_HOLDER';

export interface GetPrequalificationHolderAction extends Action_<typeof GET_PREQUALIFICATION_HOLDER> {
  payload: QDPrequalification_;
}

export const setPrequalificationHolder = (holder: QDPrequalification_) => ({ type: GET_PREQUALIFICATION_HOLDER, payload: holder, });
