import React, { FC as FC_ } from 'react';
import Status from '../Status/Status';
import NotImplementedPage from '../../NotImplement/NotImplement';
import Complete from '../Complete/Complete';
import Document from '../Document/Document';
import Expired from '../Expired/Expired';
import { CompletePages } from '../../../utils/Enums';

export interface IExternalCompleteProps {
  completePage: CompletePages;
}

// eslint-disable-next-line react/prop-types
const ExternalComplete: FC_<IExternalCompleteProps> = ({ completePage, }) => {
  const renderCompletePages = (page: CompletePages) => {
    switch (page) {
      case CompletePages.Status:
        return <Status />;
      case CompletePages.Complete:
        return <Complete />;
      case CompletePages.Documents:
        return <Document />;
      case CompletePages.Expired:
        return <Expired />;
      default:
        return <NotImplementedPage text="Completed application" />;
    }
  };

  return <>{renderCompletePages(completePage)}</>;
};

export default ExternalComplete;
