import PageSettings from '../../../Page/PageHelpers/PageSettings';
import { validateESignConsent } from '../../../../../utils/Validator';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationInformation as QDCoBorrowerApplicationInformation_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';

class CoBorrowerInformationSettings extends PageSettings<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationInformation_, boolean> {
  pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationInformation_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'ESignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-information-esign-consent-field',
          ObjectProperty: 'ESignConsent',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'guarantor-information-esign-consent-radio-yes', value: 'true', label: 'Yes', },
              { dataUI: 'guarantor-information-esign-consent-radio-no', value: 'false', label: 'No', }
            ],
          },
          IsValid: (coBorrowerHolder, subHolder, pageF) => {
            const { IsValid, InvalidMessage, } = validateESignConsent(subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });

      default:
        return this.PageFields.find(field => field.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}

export default CoBorrowerInformationSettings;
