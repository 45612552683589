import { Reducer as Reducer_ } from 'redux';
import { UnauthorizedAction as UnautorizedAction_ } from '../actions/Unauthorized';

const unauthorizedReducer: Reducer_<boolean, UnautorizedAction_> = (state = false, action) => {
  switch (action.type) {
    case 'Set': {
      return action.unauthorized;
    }
    default:
      return state;
  }
};

export default unauthorizedReducer;
