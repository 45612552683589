/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: off */
import React, { FC as FC_, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Button,
  IconsSolid,
  Section,
  FormGroup,
  Input,
  DataPreviewText,
  Alert,
  InfoCard,
  FormSection,
  Loader,
  ActionsWrapper
} from '@jkhy/vsg-design-system';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import CoBorrower_ from '../../../data/models/CoBorrower';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { dateFormat, deepCopy } from '../../../utils/Helper';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import {
  ExternalPageSectionName, QDPartyStatus, PartyType, CoBorrowerAddType, QDPartyStatusName, AlertTypes
} from '../../../utils/Enums';
import Borrower_ from '../../../data/models/Borrower';

// TODO change input to support Email mask validation.
const enum GuarantorEmailStatus {
  Success = 'success',
  Loading = 'loading',
  Error = 'error',
  Default = 'default',
}

type GuarantorResendEmailStatus = GuarantorEmailStatus.Success | GuarantorEmailStatus.Error | GuarantorEmailStatus.Default;
type GuarantorEditEmailStatus = GuarantorEmailStatus.Loading | GuarantorEmailStatus.Error | GuarantorEmailStatus.Default;

let originalGuarantor: CoBorrower_;

const Status: FC_ = () => {
  const { holder, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    modelState: state.modelState,
  }));

  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => (s.SectionName || '') === ExternalPageSectionName.Review);
  const [editModeArray, setEditModeArray] = useState([false]);
  const [guarantor, setGuarantor] = useState(holder?.Guarantor);

  const [guarantorResendEmailStatus, setGuarantorResendEmailStatus] = useState<GuarantorResendEmailStatus[]>(
    Array(guarantor?.Guarantors?.length).fill('default')
  );

  const [guarantorEditEmailStatus, setGuarantorEditEmailStatus] = useState<GuarantorEditEmailStatus[]>(
    Array(guarantor?.Guarantors?.length).fill('default')
  );

  const getStatusAndAlertType = (status: number) => {
    switch (status) {
      case QDPartyStatus.Pending:
        return {
          status: QDPartyStatusName.InvitationSent,
          alertType: AlertTypes.Info,
        };
      case QDPartyStatus.Stopped:
        return {
          status: QDPartyStatusName.InvitationRejected,
          alertType: AlertTypes.Danger,
        };
      case QDPartyStatus.Accepted:
      case QDPartyStatus.DataGathered:
      case QDPartyStatus.Authenticated:
      case QDPartyStatus.DeclarationsGathered:
        return {
          status: QDPartyStatusName.ApplicationStarted,
          alertType: AlertTypes.Success,
        };
      case QDPartyStatus.FailedAuthentication:
        return {
          status: QDPartyStatusName.IdentityUnverified,
          alertType: AlertTypes.Danger,
        };
      case QDPartyStatus.Submitted:
        return {
          status: QDPartyStatusName.Complete,
          alertType: AlertTypes.Success,
        };
      case QDPartyStatus.Declined:
        return {
          status: QDPartyStatusName.Withdrawn,
          alertType: AlertTypes.Danger,
        };
      case QDPartyStatus.NonCompliant:
        return {
          status: QDPartyStatusName.ApplicantStopped,
          alertType: AlertTypes.Danger,
        };
      default:
        return {
          status: QDPartyStatusName.InvitationSent,
          alertType: AlertTypes.Info,
        };
    }
  };

  useEffect(() => {
    originalGuarantor = deepCopy(guarantor);
  }, [holder?.Guarantor]);

  // Reset resend email success status
  useEffect(() => {
    const currentResendEmailStatus = [...guarantorResendEmailStatus];

    if (currentResendEmailStatus.includes(GuarantorEmailStatus.Success)) {
      const newStatus = currentResendEmailStatus.map(status => {
        return status === GuarantorEmailStatus.Success ? GuarantorEmailStatus.Default : status;
      });

      setGuarantorResendEmailStatus(newStatus);
    }
  }, [guarantorResendEmailStatus]);

  const changeEditMode = (status: boolean, index: number) => {
    editModeArray[index] = status;
    setEditModeArray([...editModeArray]);
  };

  const changeEditEmailStatus = (status: GuarantorEditEmailStatus, index: number) => {
    setGuarantorEditEmailStatus(oldProps => {
      const newStatus = [...oldProps];
      newStatus[index] = status;

      return newStatus;
    });
  };

  const changeResendEmailStatus = (status: GuarantorResendEmailStatus, index: number) => {
    setGuarantorResendEmailStatus(oldProps => {
      const newStatus = [...oldProps];
      newStatus[index] = status;

      return newStatus;
    });
  };

  const changeEmailAddress = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const {
      target: { value, },
    } = e;

    guarantor.Guarantors[index].Email = value;
    setGuarantor({ ...guarantor, });
  };

  const cancelChanges = (index: number) => {
    guarantor.Guarantors[index].Email = originalGuarantor.Guarantors[index].Email;
    changeEditMode(false, index);
    changeEditEmailStatus(GuarantorEmailStatus.Default, index);
  };

  const saveChanges = async (index: number) => {
    changeEditEmailStatus(GuarantorEmailStatus.Loading, index);

    const res = await QdServiceApi.setQDPartyEmail(guarantor.Guarantors[index]);

    if (res.Success) {
      originalGuarantor = deepCopy(guarantor);
      changeEditEmailStatus(GuarantorEmailStatus.Default, index);
      changeEditMode(false, index);
    } else {
      changeEditEmailStatus(GuarantorEmailStatus.Error, index);
    }
  };

  const resendEmail = async index => {
    const res = await QdServiceApi.sendQDPartyEmail(guarantor.Guarantors[index]);

    // Set success or error status
    const status = res?.Success ? GuarantorEmailStatus.Success : GuarantorEmailStatus.Error;
    changeResendEmailStatus(status, index);
  };

  const getDateContent = (date: string) => (!date ? 'N/A' : dateFormat(date));

  return (
    <Section
      dataUI="status-section"
      title={section?.SubSections[0]?.SubSectionName}
      className="mb-3"
      headerText={section?.SubSections[0]?.SubSectionHeaderText}
      footerText={section?.SubSections[0]?.SubSectionFooterText}
    >
      {guarantor.Guarantors.map((g: Borrower_, index: number) => {
        if (g?.CoBorrowerAddType === CoBorrowerAddType.AddNow) {
          return null;
        }

        const { status, alertType, } = getStatusAndAlertType(g.Status);
        const isStatusComplete = status === QDPartyStatusName.Complete;
        const email = g?.Email;

        // Resend email status
        const resendEmailStatus = guarantorResendEmailStatus[index];
        const hasResendEmailError = resendEmailStatus === GuarantorEmailStatus.Error;
        const showResendEmailSuccessMessage = resendEmailStatus === GuarantorEmailStatus.Success;

        // Edit email status
        const editEmailStatus = guarantorEditEmailStatus[index];
        const hasEditEmailError = editEmailStatus === GuarantorEmailStatus.Error;
        const isEditEmailLoading = editEmailStatus === GuarantorEmailStatus.Loading;

        const isInEditMode = editModeArray[index];

        const resendEmailButton = {
          dataUI: 'status-resend-email-button',
          label: 'Resend E-mail',
          icon: IconsSolid.faRedo,
          onClicked: () => resendEmail(index),
          disabled: isInEditMode,
        };

        const saveOrEditButton = isInEditMode
          ? {
            dataUI: 'status-save-changes-button',
            type: 'primary',
            text: 'Save',
            icon: IconsSolid.faSave,
            onClicked: () => saveChanges(index),
            className: 'action-spacer',
          }
          : {
            dataUI: 'status-edit-email-button',
            type: 'secondary',
            text: 'Edit E-mail',
            icon: IconsSolid.faEdit,
            onClicked: () => changeEditMode(true, index),
            className: '',
          };

        return (
          <InfoCard
            key={`${g?.Id}-${g?.GUID}`}
            dataUI={`status-guarantor-info-card-${index}`}
            className="mb-3"
            button={!isStatusComplete && resendEmailButton}
            successState={{
              show: showResendEmailSuccessMessage,
              content: {
                description: `E-mail ${email ? `to ${email}` : ''} was successfully sent!`,
                icon: IconsSolid.faPaperPlane,
                title: 'Success',
              },
              isError: hasResendEmailError,
            }}
          >
            <DataPreviewText
              dataUI="status-name"
              className="mb-1"
              isInline
              label="Name:"
              text={g?.PartyType === PartyType.Business ? g?.FullName : `${g?.FirstName || ''} ${g?.LastName || ''}`}
            />
            <DataPreviewText dataUI="status-request-date" className="mb-1" isInline label="Request Date:" text={getDateContent(g?.RequestDate)} />
            <DataPreviewText dataUI="status-response-date" className="mb-2" isInline label="Response Date:" text={getDateContent(g?.ResponseDate)} />

            {!isStatusComplete && (
              <FormSection dataUI="status-form-section" className="mb-2">
                {!isInEditMode && <DataPreviewText dataUI="status-change-email-label" className="mb-1" isInline label="Email:" text={email} />}

                {isInEditMode && (
                  <FormGroup dataUI="status-change-email-form-group" className="mb-2" htmlFor="email" isRequired label="E-mail Address">
                    <Input
                      dataUI={`status-change-email-input-${index}`}
                      type="email"
                      name="email"
                      id="email"
                      value={g?.Email}
                      onChange={e => changeEmailAddress(e, index)}
                    />
                  </FormGroup>
                )}

                {isInEditMode && hasEditEmailError && (
                  <Alert dataUI="status-change-email-error-message" className="mb-2" type="danger" text="Failed to change email address." />
                )}

                <ActionsWrapper
                  dataUI="status-edit-email-actions-wrapper"
                  alignCenter={isEditEmailLoading}
                  alignLeft={!isEditEmailLoading}
                  removePadding
                  removeBg
                >
                  {!isEditEmailLoading && (
                    <Button
                      dataUI={saveOrEditButton.dataUI}
                      btnType={saveOrEditButton.type}
                      iconLeft={saveOrEditButton.icon}
                      onClick={saveOrEditButton.onClicked}
                      className={saveOrEditButton.className}
                      type="button"
                    >
                      {saveOrEditButton.text}
                    </Button>
                  )}

                  {isInEditMode && !isEditEmailLoading && (
                    <Button
                      dataUI="status-cancel-changes-button"
                      btnType="secondary"
                      iconLeft={IconsSolid.faTimesCircle}
                      onClick={() => cancelChanges(index)}
                    >
                      Cancel
                    </Button>
                  )}

                  {isEditEmailLoading && <Loader dataUI="status-change-email-loader" isSmall />}
                </ActionsWrapper>
              </FormSection>
            )}

            <Alert dataUI={`status-type-${alertType}`} className="mb-2" type={alertType} text={status} />

            {hasResendEmailError && (
              <Alert dataUI="status-resend-email-error-message" className="mb-2" type="danger" text="Failed to send email address." />
            )}
          </InfoCard>
        );
      })}
    </Section>
  );
};

export default Status;
