class ListItem {
  Code: string;

  IdTeam?: bigint;

  Description: string;

  PermissionCode: string;

  ListItemHasPermission?: boolean;

  IdInstitution?: bigint;

  BankSpecificListTableFlag?: boolean;

  FourthDescription: string;

  FourthCode: string;

  LinkedLtName: string;

  IdPermission?: bigint;

  IdBank?: bigint;

  ThirdDescription: string;

  ThirdCode: string;

  IsHidden: boolean;

  SecondDescription: string;

  SecondCode: string;

  Disabled: boolean;

  IsReserved: boolean;

  IsDefault: boolean;

  SmallDescrip: string;

  Descrip: string;

  BankCode: string;

  LtName: string;
}

export default ListItem;
