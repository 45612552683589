import Disclosure_ from './Disclosure';
import Question_ from './Question';
import Naics_ from './Naics';

export default class Borrower {
  IdQDApplication: bigint;

  GUIDQDApplication: string;

  UID: string;

  Auth: string;

  BorrowerType: number;

  BorrowerTypeStr: string;

  PartyType: number;

  CoBorrowerAddType: number;

  SameAddressType: number;

  FirstName: string;

  MiddleName: string;

  LastName: string;

  FullName: string;

  GBusinessName?: string;

  DBA: string;

  Title: string;

  PreferredPhone: string;

  PhoneType: number;

  HomePhone: string;

  Street: string; // TODO: check needed for Gurantor, else obsolete and remove

  City: string; // TODO: check needed for Gurantor, else obsolete and remove

  State: string; // TODO: check needed for Gurantor, else obsolete and remove

  Zip: string; // TODO: check needed for Gurantor, else obsolete and remove

  Email: string;

  DOB: string;

  TIN: string;

  WebSite: string;

  AnnualIncome: number; // TODO: check needed for Gurantor, else obsolete and remove

  OrganizationTypeCode: string;

  OrganizationTypeCodeStr: string;

  TaxEntityTypeCode: string;

  TaxEntityTypeCodeStr: string;

  CompanyTraits: string;

  DateFormed: string;

  DateFormedMonth: number;

  DateFormedYear: number;

  NumberOfEmployees: number;

  StateFormedCode: string;

  StateFormedCodeStr: string;

  AGI: number;

  AGIYear: number;

  MailingAddress: string;

  MailingCity: string;

  MailingState: string;

  MailingZip: string;

  ZipBusinessLocated: string;

  MostRecentYearEndDate: string;

  AnnualSales: number;

  AverageDeposits: number;

  AverageDepositsActual: number;

  TimeinBusinessYears: number;

  TimeinBusinessMonths: number;

  IsExistingCustomerFlag: boolean;

  CheckingAccountNumber: string;

  StateBusinessLocated: string;

  StateBusinessLocatedStr: string;

  PercentOwnership: number;

  SubmittedFlag: boolean;

  Status: number;

  DateLastUpdated: string;

  ESignConsent: boolean;

  EsignConsentDate: string;

  CreditConsent: boolean;

  CreditConsentDate: string;

  PreciseIdScore: number;

  RequestDate: string;

  ResponseDate: string;

  IdParty: bigint;

  IsPrimaryBorrower: boolean;

  ContactName: string;

  Questions: Question_[];

  Disclosures: Disclosure_[];

  NaicsList: Naics_[];

  IdentityCheck: number;

  ManualVerificationFlag: boolean;

  IdentityCheckNote: string;

  IdentityCheckScore: number;

  LastReminderDate: string;

  RegOResponse: boolean;

  RegOResponseMemo: string;

  SuccessfulNAICSCheckFlag: boolean;

  VerificationEmail: string;

  IdCompany: bigint;

  GUID: string;

  Id: bigint;

  TreatAllNulls: boolean;

  NullFields: string[];

  IdUserLastModified: bigint;

  PartyMatchDone: boolean;

  PartyMatchConfirmedByUser?: boolean = false;

  IsMilitaryService: boolean;

  MilitaryServiceStatusCode: string;

  MilitaryServiceStatusCodeStr: string;

  USCitizenFlag: boolean;

  CitizenshipClassCode: string;

  CitizenshipClassCodeStr: string;

  IDTypeCode: string;

  IDTypeCodeStr: string;

  IDNumber: string;

  IDIssuer: string;

  IDIssueDate: string;

  IDExpirationDate: string;

  Saved: boolean;
}
