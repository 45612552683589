import React, { FC as FC_ } from 'react';
import { useHistory } from 'react-router-dom';
import { IconsSolid, LinkWithIcon } from '@jkhy/vsg-design-system';

interface ReviewApplicationLinkForwardStepProps {
  path: string;
  text: string;
}

const ReviewApplicationLinkForwardStep: FC_<ReviewApplicationLinkForwardStepProps> = ({ path, text, }: ReviewApplicationLinkForwardStepProps) => {
  const history = useHistory();

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    history.push(path);
  };

  return (
    <LinkWithIcon icon={IconsSolid.faEdit}>
      <a onClick={handleOnClick} href={path}>
        {text}
      </a>
    </LinkWithIcon>
  );
};

export default ReviewApplicationLinkForwardStep;
