import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import LayoutTemplate, { LayoutTemplateProps as LayoutTemplateProps_ } from '../Layout/LayoutTemplate';
import Base from '../Layout/BaseComponent';
import { attachBrandingIcon, attachCustomCss, changeDocumentTitle } from '../../utils/Helper';
import { getModel } from '../../utils/LocalStorageManager';
import { BaseType as BaseType_ } from '../../utils/Types';
import { AppState as AppState_ } from '../../redux/AppState';

interface LogoutCallbackProps extends BaseType_ {
  browserTabTitle?: string;
  faviconUrl?: string;
}
class LogoutCallback extends Base<LogoutCallbackProps, null> {
  componentDidMount() {
    const modelSessionKey = getModel();

    const {
      match: {
        params: { autologout, },
      },
    } = this.props;
    const { browserTabTitle: currentBrowserTabTitle, faviconUrl, } = this.props;
    changeDocumentTitle(currentBrowserTabTitle, 'Logout Callback');

    attachCustomCss(modelSessionKey);
    attachBrandingIcon(modelSessionKey, faviconUrl);
    setTimeout(() => {
      window.location.href = window.CUSTOMER_PORTAL_URL + (autologout ? '/?autologout=1' : '');
    }, 5000);
  }

  render() {
    return (
      <LayoutTemplate>
        <div id="logout-section" className="text-center">
          <p>Your application is saved, and you are signed out.</p>
          <p>
            You are being redirected to the
            {' '}
            <a href={window.CUSTOMER_PORTAL_URL}>sign-in</a>
            {' '}
            screen.
          </p>
        </div>
      </LayoutTemplate>
    );
  }
}

const mapStateToProps = (state: AppState_) => {
  return {
    browserTabTitle: state.modelState?.ModelHolder?.Model?.BrowserTabTitle,
    faviconUrl: state.modelState?.ModelHolder?.Model?.FaviconUrl,
  };
};

export default withRouter(connect(mapStateToProps)(LogoutCallback));
