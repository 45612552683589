import React from 'react';
import { useSelector } from 'react-redux';
import { Section, TextEditorContentWrapper } from '@jkhy/vsg-design-system';
import { htmlParser } from '../../../../utils/Helper';
import { PrequalificationModelState as PrequalificationModelState_ } from '../../../../redux/actions/PrequalificationModel';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { PrequalificationPages } from '../../../../utils/prequalification/Enums';
import QdApplicationSection_ from '../../../../data/models/QDApplicationSection';

export const PREQUALIFICATION_NOT_QUALIFIED_SECTION_DATA_UI = 'prequalification-not-qualified-section';

const PrequalificationResultsNotQualified = () => {
  const {
    isLoading,
    prequalificationModelState: { ModelHolder, },
  } = useSelector<
    AppState_,
    {
      isLoading: boolean;
      prequalificationModelState: PrequalificationModelState_;
    }
  >(state => ({
    isLoading: state.loading,
    prequalificationModelState: state.prequalificationModelState,
  }));

  const model = ModelHolder.Model;
  const section = ModelHolder.Sections.find(({ Sequence, }: QdApplicationSection_) => Sequence === PrequalificationPages.NotQualified);
  const [subSection] = section?.SubSections || [];

  const content = htmlParser(model.NotQualifiedContentHTML);

  if (isLoading) {
    return null;
  }

  return (
    <form id={`form-${PrequalificationPages.NotQualified}`}>
      <Section dataUI={PREQUALIFICATION_NOT_QUALIFIED_SECTION_DATA_UI} className="mb-3" title={subSection?.SubSectionName}>
        <TextEditorContentWrapper>{content}</TextEditorContentWrapper>
      </Section>
    </form>
  );
};

export default PrequalificationResultsNotQualified;
