import React, {
  FC as FC_, useState, useEffect, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, FormGroup, Radio, Textarea, FormSection
} from '@jkhy/vsg-design-system';
import { partition } from 'lodash';
import { AppState as AppState_ } from '../../../redux/AppState';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import Section_ from '../../../data/models/Section';
import SubSection_ from '../../../data/models/Subsection';
import DeclarationModel_ from '../../../data/models/Declaration';
import MarketingQuestion from '../../../components/MarketingQuestion/MarketingQuestion';
import QdServiceApi from '../../../data/api/QDServiceApi';
import Question_ from '../../../data/models/Question';
import QuestionTemplate from '../../../components/Question/QuestionTemplate';
import DeclarationSettings from './DeclarationSettings';
import {
  booleanStringComparison,
  errorFoundMessage,
  getFullName,
  getHoverHelpPageFieldValue,
  isFieldValid,
  isLtItemSecondCodeVisible,
  scrollToError
} from '../../../utils/Helper';
import { getGuid } from '../../../utils/LocalStorageManager';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import {
  ValueConstants, ExternalPages, ExternalPageSectionName, ListItemNames, CoBorrowerAddType
} from '../../../utils/Enums';
import PageField_ from '../../../data/models/PageField';
import ListItem_ from '../../../data/models/ListItem';
import ListAPI from '../../../data/api/ListApi';
import Borrower_ from '../../../data/models/Borrower';
import { validateQuestionAndSetValidationMessage } from '../../../components/Question/QuestionValidate';
import { setLoader as setLoaderAction } from '../../../redux/actions/Loading';
import { isBusinessParty } from '../../../utils/Borrower';

export interface DeclarationProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, DeclarationModel_>[],
    holder: QdApplicationHolder_,
    section: DeclarationModel_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

export type PrimaryQuestions = {
  IdQDParty: BigInt;
  ESignConsent: boolean;
  RegOResponse: boolean;
  RegOResponseMemo: string;
  MarketingQuestionCode?: string;
  MarketingQuestionCodeStr?: string;
  MarketingQuestionMemo?: string;
};

type CustomInvalidSetOfPageFields = {
  [partyId: string]: PageFieldExtended_<any, any>[];
};

const Declaration: FC_<DeclarationProps> = (props: DeclarationProps) => {
  const {
    holder, loading, modelState, sections,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      sections: Section_[];
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
    sections: state?.sectionsState.VisibleSections,
  }));

  const primaryBorrowerPartyId = holder.BorrowerP.Personal.Id;

  const [invalidSetOfPageFields, setInvalidSetOfPageFields] = useState<CustomInvalidSetOfPageFields>({});
  const [ltItems, setLtItems] = useState<ListItem_[]>([]);
  const [data, setData] = useState({ ...holder, });

  const { ApplicationPageField: pageFieldHolder, } = modelState?.ModelHolder;

  const pageFields = {
    ...pageFieldHolder?.Declarations,
    RegOResponseMemo: { ...new PageField_(), FieldName: 'RegOResponseMemo', Label: 'If yes, please explain: ', },
    MarketingQuestionMemo: { ...new PageField_(), FieldName: 'MarketingQuestionMemo', },
  };

  const section = sections.find(s => s.SectionName === ExternalPageSectionName.Declarations);
  const subSection = section?.SubSections?.find((ss: SubSection_) => ss.Code === ValueConstants.DeclarationsSubSection);

  const pageSettings = useMemo(() => {
    return new DeclarationSettings([pageFields], [subSection], primaryBorrowerPartyId);
  }, [pageFields, subSection, primaryBorrowerPartyId]);

  const ESignConsentPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === pageFields.ESignConsent.FieldName);
  const RegOQuestionPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === pageFields.RegOQuestion.FieldName);

  const RegOResponseMemoPageFieldExtended = pageSettings?.PageFields?.find(
    pageField => pageField.FieldName === pageFields.RegOResponseMemo.FieldName
  );

  const MarketingQuestionPageFieldExtended = pageSettings?.PageFields?.find(
    pageField => pageField.FieldName === pageFields.MarketingQuestion.FieldName
  );

  const MarketingQuestionMemoPageFieldExtended = pageSettings?.PageFields?.find(
    pageField => pageField.FieldName === pageFields.MarketingQuestionMemo.FieldName
  );

  const dispatch = useDispatch();

  const updateHolder = (newHolderData: QdApplicationHolder_) => {
    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: newHolderData,
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const checkIsPrimaryBorrower = (partyId: BigInt) => partyId === primaryBorrowerPartyId;

  const validate = (pageFieldQuestion: PrimaryQuestions[]) => {
    let isDeclarationQuestionsValid = true;
    let isPageFieldsValid = true;

    const allInvalidSetOfPageFields = {};

    pageFieldQuestion.forEach((primaryQuestions: PrimaryQuestions) => {
      const [invalidPageFields] = partition(pageSettings.PageFields, pf => !pf.IsValid(holder, primaryQuestions, pf) && pf.Component !== null);

      if (invalidPageFields.length > 0) {
        const partyIdAsString = `${primaryQuestions.IdQDParty}`;
        allInvalidSetOfPageFields[partyIdAsString] = invalidPageFields;
      }
    });

    setInvalidSetOfPageFields(allInvalidSetOfPageFields);
    isPageFieldsValid = Object.keys(allInvalidSetOfPageFields)?.length === 0;

    // Custom Questions
    if (data?.Declarations.Questions?.length > 0) {
      const validatedDeclarationQuestions = data?.Declarations.Questions?.map((question: Question_) => {
        const { IsValid, ValidationMessage: validationMessage, } = validateQuestionAndSetValidationMessage(question);

        return {
          ...question,
          IsValid,
          ValidationMessage: validationMessage,
        };
      });

      setData({ ...data, Declarations: { ...data.Declarations, Questions: validatedDeclarationQuestions, }, });

      isDeclarationQuestionsValid = validatedDeclarationQuestions.every((question: Question_) => question.IsValid);
    }

    return {
      isValid: isDeclarationQuestionsValid && isPageFieldsValid,
      invalidPageFields: allInvalidSetOfPageFields,
    };
  };

  const prepareData = (newHolderData: QdApplicationHolder_) => {
    const {
      ESignConsent, RegOResponse, RegOResponseMemo, MarketingQuestionCode, MarketingQuestionCodeStr,
    } = newHolderData.Declarations;

    const primaryBorrowerQuestions: PrimaryQuestions = {
      IdQDParty: primaryBorrowerPartyId,
      ESignConsent,
      RegOResponse,
      RegOResponseMemo,
      MarketingQuestionCode,
      MarketingQuestionCodeStr,
    };

    const guarantorQuestions: PrimaryQuestions[] = [];

    newHolderData.Guarantor.Guarantors
      // Business parties have only custom questions
      .filter(guarantor => guarantor.CoBorrowerAddType === CoBorrowerAddType.AddNow && !isBusinessParty(guarantor))
      .forEach(guarantor => {
        const {
          Id: guarantorId,
          ESignConsent: guarantorESignConsent,
          RegOResponse: guarantorRegOResponse,
          RegOResponseMemo: guarantorRegOResponseMemo,
        } = guarantor;

        guarantorQuestions.push({
          IdQDParty: guarantorId,
          ESignConsent: guarantorESignConsent,
          RegOResponse: guarantorRegOResponse,
          RegOResponseMemo: guarantorRegOResponseMemo,
        });
      });

    return {
      primaryBorrowerQuestions,
      guarantorQuestions,
    };
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newData = {
      ...data,
    };

    const { primaryBorrowerQuestions, guarantorQuestions, } = prepareData(newData);

    const pageFieldQuestions = [primaryBorrowerQuestions, ...guarantorQuestions];

    const { isValid, } = validate(pageFieldQuestions);

    if (!isValid) {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);

      return;
    }

    // Reset response memo
    pageFieldQuestions.forEach(primaryQuestions => {
      if (!booleanStringComparison(primaryQuestions?.RegOResponse) && primaryQuestions?.RegOResponseMemo) {
        if (primaryQuestions.IdQDParty === primaryBorrowerPartyId) {
          newData.Declarations.RegOResponseMemo = null;
        } else {
          newData.Guarantor.Guarantors = newData.Guarantor.Guarantors.map(guarantor => {
            if (guarantor.Id === primaryQuestions.IdQDParty) {
              return {
                ...guarantor,
                RegOResponseMemo: null,
              };
            }

            return guarantor;
          });
        }
      }
    });

    // Reset marketing question memo
    if (!isLtItemSecondCodeVisible(newData?.Declarations?.MarketingQuestionCode, ltItems)) {
      newData.Declarations.MarketingQuestionMemo = null;
    }

    const { guarantorQuestions: changedGuarantorQuestions, } = prepareData(newData);

    // Data for server
    const dataForServer = {
      ...data.Declarations,
      GuarantorQuestions: changedGuarantorQuestions,
    };

    setData(newData);
    updateHolder(newData);
    props.onSubmit([], holder, dataForServer, ExternalPages.Declaration, false);
  };

  const addNowGuarantors = useMemo(() => {
    return holder.Guarantor.Guarantors.filter((guarantor: Borrower_) => guarantor.CoBorrowerAddType === CoBorrowerAddType.AddNow);
  }, [holder.Guarantor.Guarantors]);

  const prepareCustomQuestions = async () => {
    const {
      BorrowerP: {
        Personal: { GUID: personalGuid, },
      },
      BorrowerB: {
        Business: { GUID: businessGuid, TIN: businessTIN, },
      },
    } = holder;
    const guidKey = getGuid();

    const allCustomQuestions = [];

    // Borrower Custom Questions
    const { Result, } = await QdServiceApi.qdApplicationEvaluatedQuestions(guidKey, '', personalGuid, businessTIN ? businessGuid : '');
    const borrowerCustomQuestions = Result?.filter(
      (q: Question_) => q.ApplicableForBorrower && !q.IsHidden && q.SectionName === section.SectionName
    )?.map((q: Question_) => ({ ...q, NullFields: [], }));

    allCustomQuestions.push(...borrowerCustomQuestions);

    // Guarantors Custom Questions
    if (addNowGuarantors.length > 0) {
      const promises = addNowGuarantors.map(async guarantor => {
        const { GUIDQDApplication, GUID, } = guarantor;

        const { Result: GuarantorResult, } = await QdServiceApi.qdApplicationEvaluatedQuestions(GUIDQDApplication, GUID);
        const guarantorCustomQuestions = GuarantorResult?.filter(
          (q: Question_) => q.ApplicableForGuarantor && !q.IsHidden && q.SectionName === section.SectionName
        )?.map((q: Question_) => ({ ...q, NullFields: [], }));

        allCustomQuestions.push(...guarantorCustomQuestions);
      });

      await Promise.all(promises);
    }

    setData(oldHolderData => ({
      ...oldHolderData,
      Declarations: {
        ...oldHolderData.Declarations,
        Questions: allCustomQuestions,
      },
    }));
  };

  const fetchAndSaveReferralSourceLtItems = async () => {
    const pars = { listName: ListItemNames.ReferralSource, };

    const { Result: allReferralSourceLtItems, } = await ListAPI.getLtItemsSearch(pars);
    const allQDReferralSourceLtItems = allReferralSourceLtItems.filter(ltItem => ltItem.ThirdCode === 'QD');
    setLtItems(allQDReferralSourceLtItems);
  };

  useEffect(() => {
    const initData = async () => {
      dispatch(setLoaderAction(true));

      await prepareCustomQuestions();
      await fetchAndSaveReferralSourceLtItems();

      dispatch(setLoaderAction(false));
    };

    initData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangePageFieldQuestions = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pageField: PageFieldExtended_<QdApplicationHolder_, PrimaryQuestions>,
    party: Borrower_,
    text?: string
  ) => {
    const { value, } = e.target;
    const { ObjectProperty, ObjectPropertyStr, ObjectPropertyType, } = pageField;

    const newValue = ObjectPropertyType === 'boolean' ? booleanStringComparison(value) : value;
    const isNeedToChangeObjectPropertyString = ObjectPropertyStr || ObjectProperty === MarketingQuestionPageFieldExtended.ObjectProperty;

    const partyId = party.Id;
    const isPrimaryBorrower = checkIsPrimaryBorrower(partyId);

    const newData = {
      ...data,
    };

    if (isPrimaryBorrower) {
      newData.Declarations[ObjectProperty] = newValue;

      if (isNeedToChangeObjectPropertyString) {
        newData.Declarations[ObjectPropertyStr] = text;
      }
    } else {
      newData.Guarantor.Guarantors = newData.Guarantor.Guarantors.map((guarantor: Borrower_) => {
        if (guarantor.Id === partyId) {
          return {
            ...guarantor,
            [ObjectProperty]: newValue,
            ...(isNeedToChangeObjectPropertyString ? { [ObjectPropertyStr]: text, } : {}),
          };
        }

        return guarantor;
      });
    }

    setData(newData);
  };

  const onChangeCustomQuestion = (value: string | number | boolean, prop: string, id: bigint) => {
    const { Questions: CustomQuestions, } = data.Declarations;

    const mappedCustomQuestions = (CustomQuestions || []).map(question => {
      if (question.Id === id) {
        return {
          ...question,
          [prop]: value,
        };
      }

      return question;
    });

    setData(oldData => ({
      ...oldData,
      Declarations: {
        ...oldData.Declarations,
        Questions: mappedCustomQuestions,
      },
    }));
  };

  return (
    <>
      {!loading && (
        <form id={`form-${ExternalPages.Declaration}`} onSubmit={onSubmit} noValidate>
          <Section
            className="mb-3"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
            dataUI={`declaration-${subSection?.Code}-section`}
          >
            {[holder.BorrowerP.Personal, holder.BorrowerB.Business, ...addNowGuarantors].map((party: Borrower_) => {
              const partyId = party.Id;
              const partyIdAsString = `${partyId}`;
              const formSectionKey = `declaration-form-section-${partyId}`;

              const isPrimaryBorrower = checkIsPrimaryBorrower(partyId);

              const hasCoborrowerVisiblePF = !RegOQuestionPageFieldExtended?.IsHidden || !ESignConsentPageFieldExtended?.IsHidden;
              let currentPageFieldQuestions;
              if (isPrimaryBorrower) currentPageFieldQuestions = data.Declarations;
              else if (hasCoborrowerVisiblePF) {
                currentPageFieldQuestions = data.Guarantor.Guarantors.find(guarantor => guarantor.Id === party.Id);
              }

              const currentCustomQuestions = data?.Declarations?.Questions.filter(question => question.IdQDParty === partyId);

              const currentInvalidPageFields = invalidSetOfPageFields[partyIdAsString] || [];

              const noDataToDisplay = !isPrimaryBorrower && !currentPageFieldQuestions && currentCustomQuestions.length === 0;
              if (noDataToDisplay) return null;

              return (
                <FormSection className="mb-3" key={formSectionKey} dataUI={formSectionKey}>
                  <h3 className="mb-2">{getFullName(party)}</h3>

                  {currentCustomQuestions?.length > 0
                    && currentCustomQuestions.map((question: Question_) => {
                      return <QuestionTemplate key={`${question.Id}`} question={question} onChange={onChangeCustomQuestion} />;
                    })}

                  <FormGroup
                    className="mb-2"
                    checkboxOrRadio
                    dataUI={RegOQuestionPageFieldExtended?.dataUI}
                    label={RegOQuestionPageFieldExtended?.Label}
                    isRequired={RegOQuestionPageFieldExtended?.Required}
                    isBold={RegOQuestionPageFieldExtended?.IsBold}
                    isHidden={RegOQuestionPageFieldExtended?.IsHidden || isBusinessParty(party)}
                    hoverHelp={getHoverHelpPageFieldValue(RegOQuestionPageFieldExtended)}
                    isValid={isFieldValid(RegOQuestionPageFieldExtended?.FieldName, currentInvalidPageFields)}
                    validationMessage={RegOQuestionPageFieldExtended?.ValidationMessage}
                  >
                    {RegOQuestionPageFieldExtended?.Component?.options?.map(option => {
                      const { dataUI, value, label, } = option;
                      const key = `${RegOQuestionPageFieldExtended?.FieldName}-${value}-${party.GUID}`;

                      return (
                        <Radio
                          key={key}
                          dataUI={dataUI}
                          className="d-inline-block"
                          name={key}
                          htmlFor={key}
                          id={key}
                          value={value}
                          checked={currentPageFieldQuestions?.RegOResponse === booleanStringComparison(value)}
                          onChange={e => onChangePageFieldQuestions(e, RegOQuestionPageFieldExtended, party)}
                        >
                          {label}
                        </Radio>
                      );
                    })}
                  </FormGroup>

                  {booleanStringComparison(currentPageFieldQuestions?.RegOResponse) && (
                    <FormGroup className="mb-2" label={RegOResponseMemoPageFieldExtended.Label} dataUI={RegOResponseMemoPageFieldExtended.dataUI}>
                      <Textarea
                        value={currentPageFieldQuestions.RegOResponseMemo}
                        rows={4}
                        onChange={e => onChangePageFieldQuestions(e, RegOResponseMemoPageFieldExtended, party)}
                        dataUI={RegOResponseMemoPageFieldExtended?.Component?.dataUI}
                      />
                    </FormGroup>
                  )}

                  <FormGroup
                    className="mb-2"
                    checkboxOrRadio
                    dataUI={ESignConsentPageFieldExtended?.dataUI}
                    label={ESignConsentPageFieldExtended?.Label}
                    isRequired={ESignConsentPageFieldExtended?.Required}
                    isBold={ESignConsentPageFieldExtended?.IsBold}
                    isHidden={ESignConsentPageFieldExtended?.IsHidden || isBusinessParty(party)}
                    hoverHelp={getHoverHelpPageFieldValue(ESignConsentPageFieldExtended)}
                    isValid={isFieldValid(ESignConsentPageFieldExtended?.FieldName, currentInvalidPageFields)}
                    validationMessage={errorFoundMessage(ESignConsentPageFieldExtended?.FieldName, currentInvalidPageFields)}
                  >
                    {ESignConsentPageFieldExtended?.Component?.options?.map(option => {
                      const { dataUI, value, label, } = option;
                      const key = `${ESignConsentPageFieldExtended?.FieldName}-${value}-${party.GUID}`;

                      return (
                        <Radio
                          key={key}
                          dataUI={dataUI}
                          className="d-inline-block"
                          name={key}
                          htmlFor={key}
                          id={key}
                          value={value}
                          checked={currentPageFieldQuestions?.ESignConsent === booleanStringComparison(value)}
                          onChange={e => onChangePageFieldQuestions(e, ESignConsentPageFieldExtended, party)}
                        >
                          {label}
                        </Radio>
                      );
                    })}
                  </FormGroup>

                  {isPrimaryBorrower && (
                    <MarketingQuestion
                      value={data?.Declarations?.MarketingQuestionCode}
                      marketingQuestionText={pageFields?.MarketingQuestion?.Label}
                      isValid={isFieldValid('MarketingQuestion', currentInvalidPageFields)}
                      pageField={pageFields?.MarketingQuestion}
                      inputValue={data?.Declarations?.MarketingQuestionMemo}
                      onInputChange={e => onChangePageFieldQuestions(e, MarketingQuestionMemoPageFieldExtended, party)}
                      onChangeRadio={(e, selected) => onChangePageFieldQuestions(e, MarketingQuestionPageFieldExtended, party, selected?.text)}
                    />
                  )}
                </FormSection>
              );
            })}
          </Section>
        </form>
      )}
    </>
  );
};

export default Declaration;
