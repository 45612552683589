import React from 'react';

export interface CallbackProps {
  children: any;
  userManager: any;
  successCallback: any;
  errorCallback: any;
}

class Callback extends React.Component<CallbackProps, any, any> {
  componentDidMount() {
    const { userManager, } = this.props;
    userManager
      .signinRedirectCallback()
      .then(this.onRedirectSuccess)
      .catch(this.onRedirectError);
  }

  onRedirectSuccess = user => {
    const { successCallback, } = this.props;
    successCallback(user);
  };

  onRedirectError = error => {
    const { errorCallback, } = this.props;
    if (errorCallback) {
      errorCallback(error);
    } else {
      throw new Error(`Error handling redirect callback: ${error.message}`);
    }
  };

  render() {
    const { children, } = this.props;
    return React.Children.only(children);
  }
}

export default Callback;
