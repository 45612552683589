/* eslint-disable quotes */
/* eslint-disable import/prefer-default-export */
import QdApplicationSection_ from '../../data/models/QDApplicationSection';
import { LINKS } from '../../pages/Layout/BaseComponent';
import { PrequalificationPages } from './Enums';
import { isNullOrUndefined } from '../Helper';

export const getPrequalificationUrl = (
  modelCode: string,
  sequence: typeof PrequalificationPages[keyof typeof PrequalificationPages],
  guid?: string
): string => {
  if (!modelCode) {
    return LINKS.PAGE_MISSING;
  }

  const url = `/prequalification/${modelCode}/`;

  const isExistingApp = sequence && guid && !isNullOrUndefined(guid);
  return isExistingApp ? `${url}${sequence}/${guid}/` : url;
};

export const getPrequalificatonSectionTabName = (
  sequence: typeof PrequalificationPages[keyof typeof PrequalificationPages],
  sections: QdApplicationSection_[]
) => {
  const foundSection = sections.find(section => section.Sequence === sequence);
  return foundSection?.TabName || '';
};
