import React from 'react';
import { useSelector } from 'react-redux';

import {
  Button, Section, ActionsWrapper, FontAwesomeIcon, IconsSolid, TextEditorContentWrapper, ReactAux
} from '@jkhy/vsg-design-system';

import { htmlParser } from '../../../utils/Helper';
import { AppState as AppState_ } from '../../../redux/AppState';

import { ApplicationStatus } from '../../../utils/Enums';
import { RenewalModelHolder as RenewalModelHolder_ } from '../../../utils/Types';

const Submitted = () => {
  const renewal = useSelector<AppState_, RenewalModelHolder_>(state => state.renewal?.ModelHolder);
  const app = renewal?.Renewal?.Application;
  const model = renewal?.Model;
  let content;
  let title;
  let icon;
  let iconClassName = 'mb-2';

  switch (app.ApplicationStatus) {
    case ApplicationStatus.Approved:
      content = htmlParser(model.ApprovedHTML);
      title = 'Approved!';
      icon = IconsSolid.faHandshake;
      iconClassName = 'mb-2 text-success';
      break;
    case ApplicationStatus.Denied:
      content = htmlParser(model.DeniedHTML);
      title = 'Denied';
      icon = IconsSolid.faTimesCircle;
      break;
    case ApplicationStatus.OnHold:
      content = htmlParser(model.OnHoldHTML);
      title = 'On Hold!';
      icon = IconsSolid.faHandPaper;
      break;
    case ApplicationStatus.WaitingOnCoApplicant:
      content = htmlParser(model.ConsentHTML);
      title = 'Pending!';
      icon = IconsSolid.faClock;
      break;
    case ApplicationStatus.ClosedExpired:
    case ApplicationStatus.ApprovedExpired:
    case ApplicationStatus.ClosedWithdrawn:
      content = htmlParser(model.ClosedApplicationHTML);
      title = 'Closed!';
      icon = IconsSolid.faTimes;
      break;
    default:
      //  Submitted
      content = htmlParser(model.ThankYouHTML);
      title = 'Thank You!';
      icon = IconsSolid.faThumbsUp;
      iconClassName = 'mb-2 text-success';
  }

  return (
    <ReactAux>
      <Section dataUI="renewal-submitted-content">
        <div className="text-center">
          <FontAwesomeIcon icon={icon} className={iconClassName} size="l" />
          <h1>{title}</h1>
        </div>

        <TextEditorContentWrapper>{content}</TextEditorContentWrapper>
      </Section>

      <ActionsWrapper dataUI="submitted-action-wrapper" className="mt-2">
        <Button
          dataUI="submitted-back-to-portal"
          style={{ textDecoration: 'none', }}
          onClick={() => window.location.replace(`${window.CUSTOMER_PORTAL_URL}/renewals`)}
        >
          Back to Portal
        </Button>
      </ActionsWrapper>
    </ReactAux>
  );
};

export default Submitted;
