import { Action as Action_ } from 'redux';

export const setLoader = (loading: boolean): LoadingAction => {
  return {
    loading,
    type: 'Fetching',
  };
};

export const setProgress = (progress: boolean): ProgressingAction => {
  return {
    progress,
    type: 'Progressing',
  };
};

export interface LoadingAction extends Action_<'Fetching'> {
  loading: boolean;
}

export interface ProgressingAction extends Action_<'Progressing'> {
  progress: boolean;
}

export default {};
