/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {
  FC as FC_, useState, useEffect, Fragment
} from 'react';
import { FormGroup, Radio, Input } from '@jkhy/vsg-design-system';

import ListItem_ from '../../data/models/ListItem';
import ListApi from '../../data/api/ListApi';
import PageField_ from '../../data/models/PageField';
import Messages from '../../utils/Messages';
import { ListItemNames } from '../../utils/Enums';

type MarketingQuestionProps = {
  pageField: PageField_;
  onChangeRadio: (e: React.ChangeEvent<HTMLInputElement>, selected: { value: string; text: string }) => void;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  marketingQuestionText: string;
  value: string;
  inputValue: string;
  isValid: boolean;
};

const MarketingQuestion: FC_<MarketingQuestionProps> = props => {
  const [ltItems, setLtItems] = useState<ListItem_[]>([]);
  const [selectedListItem, setSelectedListItem] = useState<ListItem_>(new ListItem_());

  async function fetchReferralSource() {
    const pars = {
      listName: ListItemNames.ReferralSource,
    };
    const { Result: result, } = await ListApi.getLtItemsSearch(pars);
    const res = result.filter(li => li.ThirdCode === 'QD');

    const foundLi = res.find(li => li.Code === props.value) || new ListItem_();
    setSelectedListItem(foundLi);

    setLtItems(res);
  }

  useEffect(() => {
    fetchReferralSource();
  }, []);

  const {
    pageField, marketingQuestionText, isValid, inputValue,
  } = props;
  return (
    <>
      <FormGroup
        className="mb-2"
        isBold={pageField?.IsBold}
        isHidden={pageField?.IsHidden}
        hoverHelp={pageField?.HoverHelp}
        label={marketingQuestionText}
        isValid={isValid}
        validationMessage={Messages.REQUIRED_FIELD}
        isRequired={pageField?.Required}
        dataUI="marketing-questions-form-group"
        htmlFor={`marketing-questions-${selectedListItem.Code}`}
        checkboxOrRadio
      >
        {ltItems.map(li => {
          return (
            <Radio
              key={`${li?.Code}-${li?.SecondCode}`}
              name="MarketingQuestion"
              value={li.Code}
              checked={props.value === li.Code}
              dataUI={`marketing-question-radio-${li.Code}`}
              id={`marketing-questions-${selectedListItem.Code}`}
              onChange={e => {
                const foundLi = ltItems.find(listItem => listItem.Code === e.target.value);
                setSelectedListItem(foundLi);

                if (typeof props.onChangeRadio === 'function') {
                  props.onChangeRadio(e, { value: foundLi.Code, text: foundLi.Descrip, });
                }
              }}
            >
              {li.Descrip}
            </Radio>
          );
        })}
      </FormGroup>
      {ltItems.map(li => {
        return (
          <Fragment key={`${li?.Code}-${li?.SecondCode}`}>
            {selectedListItem.SecondCode && selectedListItem.Code === li.Code && (
              <FormGroup
                className="mb-2"
                label={selectedListItem.Descrip}
                dataUI="marketing-question-input-form-group"
                htmlFor={`marketing-questions-input-${selectedListItem.Code}`}
              >
                <Input
                  type="text"
                  value={inputValue}
                  dataUI={`marketing-question-input-${li.Code}`}
                  id={`marketing-questions-input-${selectedListItem.Code}`}
                  onChange={e => {
                    if (typeof props.onInputChange === 'function') {
                      props.onInputChange(e);
                    }
                  }}
                  maxLength={970}
                />
              </FormGroup>
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default MarketingQuestion;
