/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */

export const enum PrequalificationPages {
  Prequalification = '1',
  Qualifies = '2',
  NotQualified = '3',
  QualifiedExpired = '4',
}

export const enum PrequalificationSectionName {
  Prequalification = 'Prequalification',
  Qualifies = 'PrequalificationQualifies',
  NotQualified = 'PrequalificationNotQualified',
}

export const enum PrequalificationStatus {
  Started = 0,
  Qualified = 1,
  NotQualified = 2,
  Complete = 3,
  Canceled = 4,
  QualifiedExpired = 5,
}
