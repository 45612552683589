import React, { FC as FC_ } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, IconsSolid } from '@jkhy/vsg-design-system';
import BaseAPI from '../../data/api/BaseApi';
import { removeUser } from '../../utils/LocalStorageManager';

type UnauthorizedProps = {
  isVisible: boolean;
};

const UnauthorizedModal: FC_<UnauthorizedProps> = props => {
  const history = useHistory();

  const onTryAgain = async () => {
    removeUser();
    await BaseAPI.signinRedirect();
  };

  const { isVisible, } = props;
  return (
    <Modal
      title="Incorrect User"
      isVisible={isVisible}
      size="S"
      dataUI="unauthorized-modal"
      onSubmit={onTryAgain}
      actionBtnName="Try Again"
      actionBtnIcon={IconsSolid.faRedo}
      isClosable
      onClose={() => {
        history.push('/logout');
      }}
      closeBtnName="Sign Out"
      closeBtnIcon={IconsSolid.faSignOutAlt}
    >
      <p>
        The email address used to sign in does not match the expected user for this website. Please sign out and try again. If the issue continues,
        please contact your institution for assistance.
      </p>
    </Modal>
  );
};

UnauthorizedModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};
export default UnauthorizedModal;
