import BaseAPI from './BaseApi';
import PageField_ from '../models/PageField';

export default class PageFieldAPI extends BaseAPI {
  static getPageFields = (idPageFieldTemplate, pageType = 18) => BaseAPI.performAjax<PageField_[]>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionRenewalApplication/InquirePageFields`,
    data: { idPageFieldTemplate, pageType, },
  });
}
