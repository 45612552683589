import React, {
  useEffect, useState, useMemo, useCallback
} from 'react';
import { Section, IconsSolid, LinkWithIcon } from '@jkhy/vsg-design-system';
import { useSelector } from 'react-redux';
import PurposeProductSection from './PurposeProduct/PurposeProduct';
import AutoDetailsSection from './AutoDetails/AutoDetails';
import BusinessReferences from './BusinessReferences/BusinessReferences';
import BusinessObligations from './BusinessObligations/BusinessObligations';
import BusinessDeposits from './BusinessDeposits/BusinessDeposits';
import Collaterals from './Collaterals/Collaterals';
import Disclosures from './Disclosures/Disclosures';
import Information from './Information/Information';
import Declarations from './Declarations/Declarations';
import Employment from './Employment/Employment';
import PersonalBorrower from './PersonalBorrower/PersonalBorrower';
import BusinessBorrower from './BusinessBorrower/BusinessBorrower';
import BusinessOtherInformation from './BusinessOtherInformation/BusinessOtherInformation';
import BusinessAddresses from './Addresses/BusinessAddresses/BusinessAddresses';
import Guarantors from './Guarantors/Guarantors';
import PersonalAddresses from './Addresses/PersonalAddresses/PersonalAddresses';
import { ExternalPages, ExternalPageSectionName, GuarantorPages } from '../../../utils/Enums';
import {
  getSectionTabName, isSectionVisibleBySequence, areSectionsExpanded, toggleAllSections
} from '../../../utils/ReviewApplication';
import { AppState as AppState_ } from '../../../redux/AppState';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import Section_ from '../../../data/models/Section';

export interface ReviewApplicationProps {
  onSubmit: () => void;
}

export type ReviewSection = {
  [id: string]: boolean;
};

export type ExpandState = {
  [key in ExternalPages | GuarantorPages]: ReviewSection;
};

export type ReviewApplicationSectionProps = {
  sectionTitle?: string;
  expandState: ReviewSection;
  setExpandState: React.Dispatch<React.SetStateAction<ExpandState>>;
  page: typeof ExternalPages[keyof typeof ExternalPages] | typeof GuarantorPages[keyof typeof GuarantorPages];
  isCoBorrower: boolean;
};

const ReviewApplication = (props: ReviewApplicationProps) => {
  const { onSubmit, } = props;

  const { holder, sections, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      sections: Section_[];
    }
  >(state => ({
    holder: state.holderState?.Holder,
    sections: state?.sectionsState.VisibleSections,
  }));

  const [expandState, setExpandState] = useState<ExpandState>(null);

  const allSectionsExpanded = useMemo(() => {
    return areSectionsExpanded(expandState);
  }, [expandState]);

  const isSectionVisible = useCallback(
    sequence => {
      return isSectionVisibleBySequence(sections, sequence);
    },
    [sections]
  );

  const getTabName = useCallback(
    (sectionName: typeof ExternalPageSectionName[keyof typeof ExternalPageSectionName], defaultTabName: string) => {
      return getSectionTabName(sections, sectionName, defaultTabName);
    },
    [sections]
  );

  useEffect(() => {
    const allSections = sections
      .filter((s: Section_) => Number(s.Sequence) <= Number(ExternalPages.Disclosures) && s.SectionName !== ExternalPageSectionName.Welcome)
      .map(({ Sequence, }: Section_) => Sequence as keyof typeof ExternalPages)
      .reduce((acc, sequence) => ({ ...acc, [sequence]: {}, }), {} as ExpandState);

    setExpandState(allSections);
  }, [sections]);

  const toggleSections = (expand: boolean) => {
    const modifiedExpandedState: ExpandState = toggleAllSections(expand, expandState);
    setExpandState(modifiedExpandedState);
  };

  return (
    <div id="review-application-page">
      {expandState && (
        <form
          className="ml-3"
          id={`form-${ExternalPages.Review}`}
          onSubmit={e => {
            e.preventDefault();
            onSubmit();
          }}
        >
          <Section className="mb-4" title="Review Application" dataUI="review-application-section">
            <LinkWithIcon icon={allSectionsExpanded ? IconsSolid.faCompressAlt : IconsSolid.faExpandAlt}>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  toggleSections(!allSectionsExpanded);
                }}
              >
                {allSectionsExpanded ? 'Collapse all sections' : 'Expand all sections'}
              </a>
            </LinkWithIcon>

            {isSectionVisible(ExternalPages.InformationMembership) && (
              <Information
                isCoBorrower={false}
                page={ExternalPages.InformationMembership}
                sectionTitle={getTabName(ExternalPageSectionName.InformationMembership, 'Information')}
                expandState={expandState[ExternalPages.InformationMembership]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.PurposeProduct) && (
              <PurposeProductSection
                isCoBorrower={false}
                hasFullAccess
                page={ExternalPages.PurposeProduct}
                sectionTitle={getTabName(ExternalPageSectionName.PurposeProduct, 'Purpose / Product')}
                expandState={expandState[ExternalPages.PurposeProduct]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.AutoDetails) && (
              <AutoDetailsSection
                isCoBorrower={false}
                hasFullAccess
                page={ExternalPages.AutoDetails}
                sectionTitle={getTabName(ExternalPageSectionName.AutoDetails, 'Auto Details')}
                expandState={expandState[ExternalPages.AutoDetails]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.PersonalBorrower) && (
              <PersonalBorrower
                isCoBorrower={false}
                hasFullAccess
                page={ExternalPages.PersonalBorrower}
                personalBorrower={holder.BorrowerP.Personal}
                sectionTitle={getTabName(ExternalPageSectionName.YourInformation, 'Owner Information')}
                expandState={expandState[ExternalPages.PersonalBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.PersonalAddresses) && (
              <PersonalAddresses
                isCoBorrower={false}
                page={ExternalPages.PersonalAddresses}
                personalAddresses={holder.AddressesP.PersonalAddresses}
                sectionTitle={getTabName(ExternalPageSectionName.PersonalAddresses, 'Personal Address')}
                expandState={expandState[ExternalPages.PersonalAddresses]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.Employment) && (
              <Employment
                isCoBorrower={false}
                page={ExternalPages.Employment}
                employment={holder?.IncomeExpences?.Employment}
                sectionTitle={getTabName(ExternalPageSectionName.Employment, 'Employment')}
                expandState={expandState[ExternalPages.Employment]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessBorrower) && (
              <BusinessBorrower
                isCoBorrower={false}
                hasFullAccess
                page={ExternalPages.BusinessBorrower}
                businessBorrower={holder.BorrowerB.Business}
                sectionTitle={getTabName(ExternalPageSectionName.BusinessInformation, 'Business Information')}
                expandState={expandState[ExternalPages.BusinessBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessBorrowerOtherInformation) && (
              <BusinessOtherInformation
                isCoBorrower={false}
                page={ExternalPages.BusinessBorrowerOtherInformation}
                businessBorrower={holder.BorrowerB.Business}
                sectionTitle={getTabName(ExternalPageSectionName.BusinessBorrowerOtherInformation, 'Business Other Information')}
                expandState={expandState[ExternalPages.BusinessBorrowerOtherInformation]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessAddresses) && (
              <BusinessAddresses
                isCoBorrower={false}
                page={ExternalPages.BusinessAddresses}
                businessAddresses={holder.AddressesB.BusinessAddresses}
                sectionTitle={getTabName(ExternalPageSectionName.BusinessAddresses, 'Business Address')}
                expandState={expandState[ExternalPages.BusinessAddresses]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessReference) && (
              <BusinessReferences
                isCoBorrower={false}
                page={ExternalPages.BusinessReference}
                sectionTitle={getTabName(ExternalPageSectionName.References, 'Business References')}
                expandState={expandState[ExternalPages.BusinessReference]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessObligations) && (
              <BusinessObligations
                isCoBorrower={false}
                page={ExternalPages.BusinessObligations}
                sectionTitle={getTabName(ExternalPageSectionName.BusinessObligations, 'Business Debts')}
                expandState={expandState[ExternalPages.BusinessObligations]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.BusinessDeposits) && (
              <BusinessDeposits
                isCoBorrower={false}
                page={ExternalPages.BusinessDeposits}
                sectionTitle={getTabName(ExternalPageSectionName.BusinessDeposits, 'Business Deposits')}
                expandState={expandState[ExternalPages.BusinessDeposits]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.CoBorrower) && (
              <Guarantors
                isCoBorrower
                page={ExternalPages.CoBorrower}
                expandState={expandState[ExternalPages.CoBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.Collateral) && (
              <Collaterals
                isCoBorrower={false}
                page={ExternalPages.Collateral}
                sectionTitle={getTabName(ExternalPageSectionName.Collateral, 'Collateral')}
                expandState={expandState[ExternalPages.Collateral]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.Declaration) && (
              <Declarations
                isCoBorrower={false}
                page={ExternalPages.Declaration}
                sectionTitle={getTabName(ExternalPageSectionName.Declarations, 'Declarations')}
                expandState={expandState[ExternalPages.Declaration]}
                setExpandState={setExpandState}
              />
            )}

            {isSectionVisible(ExternalPages.Disclosures) && (
              <Disclosures
                isCoBorrower={false}
                page={ExternalPages.Disclosures}
                sectionTitle={getTabName(ExternalPageSectionName.Disclosures, 'Disclosures')}
                expandState={expandState[ExternalPages.Disclosures]}
                setExpandState={setExpandState}
              />
            )}
          </Section>
        </form>
      )}
    </div>
  );
};

export default ReviewApplication;
