import React, { FC as FC_, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  InfoCard,
  ActionsWrapper,
  Button,
  Checkbox,
  Modal,
  Alert,
  TextEditorContentWrapper,
  FontAwesomeIcon,
  IconsSolid
} from '@jkhy/vsg-design-system';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import { currencyFormat, htmlParser } from '../../../utils/Helper';

import QdServiceApi_ from '../../../data/api/QDServiceApi';
import QDLoanOptionSelectPars_ from '../../../data/models/QDLoanOptionSelectPars';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ApplicationStatus } from '../../../utils/Enums';
import { setLoader } from '../../../redux/actions/Loading';
import LoanOptions from '../../../components/LoanOptions/LoanOptions';

const Complete: FC_<any> = () => {
  const { holder, modelState, loading, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    modelState: state.modelState,
    loading: state.loading,
  }));

  const dispatch = useDispatch();

  const { LoanOptions: loanOptionsFromHolder, } = holder;
  const {
    ModelHolder: { Model: model, },
  } = modelState;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [interestedInCreditLife, setInterestedInCreditLife] = useState(false);
  const [loanOptions, setLoanOptions] = useState(loanOptionsFromHolder);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const onSelect = (idLoanOption: bigint) => {
    const loanOptionsMapped = (loanOptions || []).map(lo => {
      return {
        ...lo,
        Selected: lo.Id === idLoanOption,
      };
    });

    setLoanOptions(loanOptionsMapped);
  };

  useEffect(() => {
    setLoanOptions(loanOptionsFromHolder);

    if (loanOptionsFromHolder?.length === 1) {
      const [firstLoanOption] = loanOptionsFromHolder;
      onSelect(firstLoanOption?.Id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanOptionsFromHolder]);
  // eslint-disable-next-line max-len
  const isStatusTabVisible = ApplicationStatus.WaitingOnCoApplicant === holder.ApplicationStatus && holder?.CompleteFlag && holder?.Guarantor?.Guarantors.length > 0;
  const selectedLoanOption = (loanOptions || []).find(lo => lo.Selected);
  // eslint-disable-next-line max-len
  const isLoanOptionVisible = !holder.Expired && (holder.ApplicationStatus === ApplicationStatus.Approved || holder.ApplicationStatus === ApplicationStatus.Started);

  const CompleteText = () => {
    switch (holder.ApplicationStatus) {
      case ApplicationStatus.Approved:
        return htmlParser(model.ApprovedHTML);
      case ApplicationStatus.Denied:
        return htmlParser(model.DeniedHTML);
      case ApplicationStatus.OnHold:
      case ApplicationStatus.HoldRemoved:
        return htmlParser(model.OnHoldHTML);
      case ApplicationStatus.WaitingOnCoApplicant:
        return htmlParser(model.ConsentHTML);
      case ApplicationStatus.ClosedExpired:
      case ApplicationStatus.ApprovedExpired:
      case ApplicationStatus.ClosedWithdrawn:
        return htmlParser(model.ClosedApplicationHTML);
      case ApplicationStatus.ApprovedWithdrawn:
        return htmlParser(model.WithdrawApplicationHTML);
      default:
        return htmlParser(model.ThankYouHTML);
    }
  };

  const updateHolder = (applicationStatus: number) => {
    holder.ApplicationStatus = applicationStatus;

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: holder,
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (selectedLoanOption) {
      dispatch(setLoader(true));

      const pars: QDLoanOptionSelectPars_ = {
        LoanOptionId: selectedLoanOption.Id,
        GUID: selectedLoanOption.GUID,
        GUIDQDApplication: holder.GUIDQDApplication,
        SelectedByOfficer: false,
        InterestedInCreditLife: interestedInCreditLife,
      };

      const res = await QdServiceApi_.loanOptionSelect(pars);

      if (res?.Success) {
        updateHolder(ApplicationStatus.Accepted);
      }

      dispatch(setLoader(false));
    }
  };

  const onWithdraw = async (idQdApplication: BigInt) => {
    dispatch(setLoader(true));
    const result = await QdServiceApi_.loanOptionWithdraw(idQdApplication);
    if (result.Success) {
      updateHolder(ApplicationStatus.ApprovedWithdrawn);
    }
    setShowWithdrawModal(false);
    dispatch(setLoader(false));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      currentTarget: { checked, },
    } = e;
    setTermsAccepted(checked);
  };

  const showActionButtonsWrapper = isStatusTabVisible || isLoanOptionVisible;
  const paymentFrequencyAmount = currencyFormat(selectedLoanOption?.PaymentFrequencyAmount, 2);

  return (
    <>
      <form id="form-complete" onSubmit={onSubmit}>
        {!loading && (
          <>
            <div className="mb-3">
              {holder.ApplicationStatus === ApplicationStatus.Approved && (
                <div className="text-center">
                  <FontAwesomeIcon className="text-success mb-2" icon={IconsSolid.faThumbsUp} size="l" />
                </div>
              )}
              <TextEditorContentWrapper>{CompleteText()}</TextEditorContentWrapper>
            </div>

            {isLoanOptionVisible && <LoanOptions loanOptions={loanOptions} onSelect={onSelect} />}

            {isLoanOptionVisible && selectedLoanOption && (
              <>
                <Checkbox
                  id="chkTermsAccepted"
                  dataUI="complete-section-terms-accepted-checkbox"
                  className={`mb-1 ${holder.IsCounterOffer ? '' : 'mb-3'}`}
                  name="terms-accepted"
                  checked={termsAccepted}
                  onChange={onChange}
                  htmlFor="chkTermsAccepted"
                >
                  {`${
                    holder.IsCounterOffer ? model?.CounterOfferAcceptedText ?? '' : model?.TermsAcceptedText ?? ''
                  } ${selectedLoanOption?.RepaymentTerms?.replace('{PmtAmount}', paymentFrequencyAmount) ?? ''}`}
                </Checkbox>

                {selectedLoanOption?.IncludeCreditLife && (
                  <Checkbox
                    id="chkInterestedInCreditLife"
                    dataUI="complete-section-interested-in-credit-life-checkbox"
                    className="mb-3"
                    name="interested-in-credit-life"
                    checked={interestedInCreditLife}
                    onChange={({ currentTarget: { checked, }, }) => setInterestedInCreditLife(checked)}
                    htmlFor="chkInterestedInCreditLife"
                  >
                    {selectedLoanOption?.CreditLifeTerms}
                  </Checkbox>
                )}

                {holder.IsCounterOffer && (
                  <p className="mb-3">
                    <TextEditorContentWrapper>{htmlParser(model.CounterOfferTermsHTML)}</TextEditorContentWrapper>
                  </p>
                )}
              </>
            )}

            {isLoanOptionVisible && (
              <p className="mb-3">
                <TextEditorContentWrapper>{htmlParser(model.WaitDeclineHTML)}</TextEditorContentWrapper>
              </p>
            )}

            {showActionButtonsWrapper && (
              <>
                {holder.ApplicationStatus === ApplicationStatus.Approved && (
                  <InfoCard dataUI="withdraw-container" className="mb-3">
                    <Alert
                      dataUI="withdraw-info"
                      className="mb-2"
                      type="info"
                      text="Select the Withdraw button if you want to withdraw your loan application."
                    />
                    <Button
                      dataUI="withdraw-button"
                      className="mb-2"
                      btnType="secondary"
                      onClick={e => {
                        e.preventDefault();
                        setShowWithdrawModal(true);
                      }}
                    >
                      Withdraw
                    </Button>
                  </InfoCard>
                )}

                {isLoanOptionVisible && selectedLoanOption && (
                  <Alert
                    className="mb-3"
                    dataUI="bottom-info-selected-loan-option"
                    type="success"
                    text={`SELECTED: ${selectedLoanOption.Description} - ${currencyFormat(selectedLoanOption.AmountOffered)}`}
                  />
                )}

                <ActionsWrapper dataUI="complete-actions-wrapper" className="mb-1 mr-1">
                  {isStatusTabVisible && (
                    <Button
                      dataUI="complete-submit-completed-button"
                      style={{ textDecoration: 'none', }}
                      link={{ path: `/status/${model.Code}/${holder.GUIDQDApplication}`, title: 'Continue', }}
                    >
                      Continue
                    </Button>
                  )}

                  {isLoanOptionVisible && (
                    <Button type="submit" disabled={!termsAccepted} form="form-complete" dataUI="complete-continue-submit-button">
                      Submit
                    </Button>
                  )}
                </ActionsWrapper>
              </>
            )}
          </>
        )}
      </form>
      <Modal
        isVisible={showWithdrawModal}
        onClose={() => {
          setShowWithdrawModal(false);
        }}
        title="Withdraw"
        onSubmit={() => onWithdraw(holder.IdQDApplication)}
        isClosable
        closeBtnName="No"
        actionBtnName="Yes"
        dataUI="withdraw-modal"
      >
        <p>
          Are you sure you want to withdraw your application?
          <br />
          <br />
          By selecting Yes, this confirms that your application will be withdrawn and your application will be considered complete. By selecting No,
          you can return to the offers presented and select one or contact your institution if there are any questions.
        </p>
      </Modal>
    </>
  );
};

export default Complete;
