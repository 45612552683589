import React, {
  FC as FC_, memo, useCallback, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection from '../ReviewApplicationGeneralSection';
import { ExternalPageSectionName } from '../../../../utils/Enums';
import {
  booleanStringComparison, currencyFormat, getSubSectionPageHeader, isNullOrUndefined
} from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../../redux/AppState';
import Section_ from '../../../../data/models/Section';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';

type AutoDetailsProps = {
  hasFullAccess: boolean;
} & ReviewApplicationSectionProps_;

const AutoDetails: FC_<AutoDetailsProps> = ({
  hasFullAccess, page, sectionTitle, expandState, setExpandState,
}: AutoDetailsProps) => {
  const { modelState, holder, sections, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
      sections: Section_[];
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
    sections: state?.sectionsState.VisibleSections,
  }));

  const autoDetailsPageFields = modelState?.ModelHolder?.ApplicationPageField?.AutoDetails?.General;
  const autoDetailsData = holder?.AutoDetails?.AutoDetails;

  const newOrUsedAutoStringRepresentation = useCallback((newFlag: boolean | string) => {
    if (isNullOrUndefined(`${newFlag}`)) return '';
    return booleanStringComparison(newFlag) ? 'New' : 'Used';
  }, []);

  const rows = useMemo(() => {
    const title = getSubSectionPageHeader(sections, ExternalPageSectionName.AutoDetails, 'Auto Details');

    return [
      {
        id: 'review-auto-details-section',
        title,
        pageFields: autoDetailsPageFields,
        ...(hasFullAccess ? { link: { page, text: 'Go to auto details step to edit', }, } : {}),
        data: {
          NewOrUsed: {
            dataUI: 'auto-details-new-or-used-field',
            value: newOrUsedAutoStringRepresentation(autoDetailsData?.NewFlag),
          },
          PurchasePrice: {
            dataUI: 'auto-details-purchase-price-field',
            value: currencyFormat(parseInt(autoDetailsData?.PurchasePrice, 10)),
          },
          Year: {
            dataUI: 'auto-details-year-field',
            value: `${autoDetailsData?.Year || ''}`,
          },
          Make: {
            dataUI: 'auto-details-make-field',
            value: autoDetailsData?.Make,
          },
          Model: {
            dataUI: 'auto-details-model-field',
            value: autoDetailsData?.Model,
          },
          VIN: {
            dataUI: 'auto-details-vin-field',
            value: autoDetailsData?.VIN,
          },
          Mileage: {
            dataUI: 'auto-details-mileage-field',
            value: `${autoDetailsData?.Mileage || ''}`,
          },
        },
      }
    ];
  }, [sections, autoDetailsPageFields, hasFullAccess, page, newOrUsedAutoStringRepresentation, autoDetailsData]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(AutoDetails);
