import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import {
  AddressDetails as AddressDetails_,
  ExternalModelHolder as ExternalModelHolder_,
  ModelState as ModelState_
} from '../../../../../utils/Types';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import { AddressType, GuarantorPages, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import BusinessAddress_ from '../../../../../data/models/PageFields/BusinessAddress/BusinessAddress';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import PageField from '../../../../../data/models/PageField';
import Page from '../../../Page/Page';
import CoBorrowerAddNowBusinessAddressSettings from './BusinessAddressSettings';
import QDAddress from '../../../../../data/models/QDAddress';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import { booleanStringComparison } from '../../../../../utils/Helper';

import { getGoogleApiAddressAsString } from '../../../../../utils/Address';

export interface BusinessAddressProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

export type CoBorrowerAddNowBusinessAdresses = {
  BusinessAddresses: QDAddress[];
};

const CoBorrowerAddNowBusinessAddress: FC_<BusinessAddressProps> = (props: BusinessAddressProps) => {
  const { addNowHolder, modelState, loading, } = useSelector<
    AppState_,
    {
      addNowHolder: CoBorrowerAddNowHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
    }
  >(state => ({
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    modelState: state.modelState,
    loading: state.loading,
  }));

  const [addresses, setAddresses] = useState<CoBorrowerAddNowBusinessAdresses>({ BusinessAddresses: addNowHolder?.Addresses, });

  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.BusinessAddress);
  const [subSection] = section?.SubSections;

  const businessAddressGeneralPageFields = modelState?.ModelHolder?.CoBorrowerPageField?.BusinessAddress?.General;
  const businessAdressPageFields: BusinessAddress_ = useMemo(() => {
    const { SortOrder: currentAddressSortOrder, ...currentAddress } = businessAddressGeneralPageFields.CurrentAddress;
    const { SortOrder: mailingAddressSortOrder, ...mailingAddress } = businessAddressGeneralPageFields.MailingAddress;

    return {
      ...businessAddressGeneralPageFields,
      // Custom fields, which use main page field settings
      StreetAddress: { ...currentAddress, FieldName: 'StreetAddress', SortOrder: currentAddressSortOrder + 1, },
      City: { ...currentAddress, FieldName: 'City', SortOrder: currentAddressSortOrder + 2, },
      State: { ...currentAddress, FieldName: 'State', SortOrder: currentAddressSortOrder + 3, },
      ZipCode: { ...currentAddress, FieldName: 'Zip', SortOrder: currentAddressSortOrder + 4, },
      DifferentMailingAddress: { ...new PageField(), FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressSortOrder - 1, },
      MailingZipCode: { ...mailingAddress, FieldName: 'MailingZip', SortOrder: mailingAddressSortOrder + 1, },
      MailingCity: { ...mailingAddress, FieldName: 'MailingCity', SortOrder: mailingAddressSortOrder + 2, },
      MailingState: { ...mailingAddress, FieldName: 'MailingState', SortOrder: mailingAddressSortOrder + 3, },
    };
  }, [businessAddressGeneralPageFields]);

  const pageSettings = useMemo(() => {
    return new CoBorrowerAddNowBusinessAddressSettings([businessAdressPageFields], [subSection]);
  }, [businessAdressPageFields, subSection]);

  const onSubmit = async (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, CoBorrowerAddNowBusinessAdresses>[]) => {
    if (invalidPageFields.length === 0) {
      const {
        CoBorrower: { IdQDApplication, Id: idQDParty, },
      } = addNowHolder;

      const promises = addresses?.BusinessAddresses?.map(async (address: QDAddress) => {
        const changedAddress = {
          ...address,
          AddressType: AddressType.Current,
        };

        if (!changedAddress.IdQDParty) {
          changedAddress.IdQDParty = idQDParty;
        }

        if (!changedAddress.IdQDApplication) {
          changedAddress.IdQDApplication = IdQDApplication;
        }

        // Reset mailing fields
        if (!booleanStringComparison(changedAddress.DifferentMailingAddress)) {
          changedAddress.MailingAddress = '';
          changedAddress.MailingCity = '';
          changedAddress.MailingState = '';
          changedAddress.MailingZip = '';
        }
        changedAddress.IsSavedByUser = true;

        const { Result: saved, } = await QdServiceApi.saveQDAddress(changedAddress);

        if (!changedAddress.Id) {
          changedAddress.Id = saved.Id;
        }

        return changedAddress;
      });

      const businessAddresses = await Promise.all(promises);

      setAddresses({ BusinessAddresses: businessAddresses, });

      const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, Addresses: businessAddresses, };
      await props.onSave(coBorrwerAddNowHolder, GuarantorPageSectionName_.BusinessAddress);
    }
  };

  const onChange = (pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, CoBorrowerAddNowBusinessAdresses>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, ObjectIndex, } = pageField;
    const changedAddressData = { ...addresses, };

    changedAddressData.BusinessAddresses[ObjectIndex][ObjectProperty] = value;
    if (ObjectPropertyStr) changedAddressData.BusinessAddresses[ObjectIndex][ObjectPropertyStr] = text;

    setAddresses({ ...changedAddressData, });
  };

  const onAddressSelect = (
    pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, CoBorrowerAddNowBusinessAdresses>,
    addressDetails: AddressDetails_
  ) => {
    const { ObjectProperty, ObjectIndex, } = pageField;

    if (addressDetails) {
      const changedAddressData = { ...addresses, };
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;

      changedAddressData.BusinessAddresses[ObjectIndex] = {
        ...changedAddressData.BusinessAddresses[ObjectIndex],
        [ObjectProperty]: fullAddress,
        Address: streetAddress,
        City: city,
        State: state,
        Zip: zipCode,
      };

      setAddresses({ ...changedAddressData, });
    }
  };

  useEffect(() => {
    let existingAddress = addNowHolder?.Addresses.find(a => a?.AddressType === AddressType.Current);

    if (existingAddress) {
      existingAddress.GoogleAPIAddress = getGoogleApiAddressAsString(existingAddress);
    } else {
      existingAddress = new QDAddress();
      existingAddress.AddressType = AddressType.Current;
    }

    setAddresses({ BusinessAddresses: [existingAddress], });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={onChange}
          onAddressSelect={onAddressSelect}
          holder={addNowHolder}
          subHolder={addresses}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessAddresses}`}
        />
      )}
    </>
  );
};

export default CoBorrowerAddNowBusinessAddress;
