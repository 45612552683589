import React, { FC as FC_, useMemo, memo } from 'react';
import { ExternalPages, GuarantorPages } from '../../../utils/Enums';
import PageField_ from '../../../data/models/PageField';
import ReviewApplicationCustomCollapse from './ReviewApplicationCustomCollapse';
import { ExpandState as ExpandState_ } from './ReviewApplication';

export type LinkOptions = {
  page: typeof ExternalPages[keyof typeof ExternalPages] | typeof GuarantorPages[keyof typeof GuarantorPages];
  text: string;
};

export interface ReviewApplicationData {
  [key: string]: {
    dataUI: string;
    value: string;
    customLabel?: string;
    pageField?: PageField_;
    isVisible?: boolean;
    hasAccess?: boolean;
    className?: string;
    bold?: 'label' | 'text';
    customComponent?: React.ReactNode;
  };
}

interface CommonGeneralSectionTypes {
  id: string;
  title: string;
  subTitle?: string;
  link?: LinkOptions;
  pageFields: any;
}

export interface CustomCollapse extends CommonGeneralSectionTypes {
  fields: ReviewApplicationData[];
}

export interface CustomRow extends CommonGeneralSectionTypes {
  data: ReviewApplicationData;
}

type ReviewApplicationGeneralSectionProps = {
  page: typeof ExternalPages[keyof typeof ExternalPages] | typeof GuarantorPages[keyof typeof GuarantorPages];
  sectionTitle?: string;
  plainContainer?: boolean;
  children?: React.ReactElement;
  rows?: CustomRow[];
  expandState?: any;
  setExpandState?: React.Dispatch<React.SetStateAction<ExpandState_>>;
};

const ReviewApplicationGeneralSection: FC_<ReviewApplicationGeneralSectionProps> = ({
  page,
  sectionTitle,
  plainContainer,
  rows = [],
  expandState,
  setExpandState,
  children,
}: ReviewApplicationGeneralSectionProps) => {
  // @ts-ignore
  const customCollapses: CustomCollapse[] = useMemo(() => {
    return (rows || [])
      .map(({ pageFields = {}, data: rowData, ...otherFields }) => {
        const fields = Object.entries(rowData)
          .filter(([fieldName, { isVisible = true, hasAccess = true, }]) => {
            const currentPageField = pageFields[fieldName];

            return hasAccess && isVisible && !currentPageField?.IsHidden;
          })
          .map(([fieldName, fieldValues]) => ({ [fieldName]: fieldValues, }));

        return {
          ...otherFields,
          pageFields,
          fields,
        };
      })
      .filter(row => row.fields.length > 0);
  }, [rows]);

  if (plainContainer) {
    return (
      <>
        {sectionTitle && <h3 className="mt-2">{sectionTitle}</h3>}

        {children}
      </>
    );
  }

  return (
    <>
      {page && customCollapses.length > 0 && (
        <>
          {sectionTitle && <h3 className="mt-2">{sectionTitle}</h3>}

          {customCollapses.map((collapse: CustomCollapse) => (
            <ReviewApplicationCustomCollapse
              key={collapse.id}
              collapse={collapse}
              page={page}
              expandState={expandState}
              setExpandState={setExpandState}
            />
          ))}
        </>
      )}
    </>
  );
};

ReviewApplicationGeneralSection.defaultProps = {
  sectionTitle: '',
  plainContainer: false,
  children: null,
  rows: [],
  expandState: null,
  setExpandState: () => {},
};

export default memo(ReviewApplicationGeneralSection);
