import React, {
  FC as FC_, useMemo, useCallback, useEffect, useState, memo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import {
  CoBorrowerAddType,
  ExternalPageSectionName,
  GuarantorPages,
  GuarantorPageSectionName_,
  ListItemNames,
  PartyType
} from '../../../../utils/Enums';
import {
  booleanStringComparison, getFullName, isLtItemSecondCodeVisible, yesNoOrEmptyAnswer
} from '../../../../utils/Helper';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import Borrower_ from '../../../../data/models/Borrower';
import { Declaration as CustomDeclaration_, ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import Declaration_ from '../../../../data/models/Declaration';
import { setLoader } from '../../../../redux/actions/Loading';
import ListAPI from '../../../../data/api/ListApi';
import ListItem_ from '../../../../data/models/ListItem';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationDeclarations as QDCoBorrowerApplicationDeclarations_
} from '../../../../data/models/QDCoBorrowerApplicationHolder';
import { filterQuestion } from '../../../../utils/Declarations';
import { transformCustomQuestions } from '../QuestionPresentation/QuestionPresentation';

type DeclarationsProps = ReviewApplicationSectionProps_;

const Declarations: FC_<DeclarationsProps> = ({
  page, isCoBorrower, sectionTitle, expandState, setExpandState,
}: DeclarationsProps) => {
  const [referralSources, setReferralSources] = useState<ListItem_[]>([]);

  const {
    holder,
    coBorrowerHolder,
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      coBorrowerHolder: QDCoBorrowerApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    coBorrowerHolder: state.coBorrowerHolderState.CoBorrowerHolder,
    modelState: state.modelState,
  }));

  const dispatch = useDispatch();

  const pageFields = isCoBorrower ? CoBorrowerPageField?.Declarations : ApplicationPageField?.Declarations;
  const declarations = isCoBorrower ? coBorrowerHolder.Declarations : holder.Declarations;

  const coBorrower = isCoBorrower ? coBorrowerHolder.Personal.CoBorrower ?? coBorrowerHolder.Business.CoBorrower : null;
  const primaryBorrower = isCoBorrower ? coBorrower : holder.BorrowerP.Personal;

  const guarantors = holder.Guarantor.Guarantors;
  const businessBorrowerFromExternalApp = holder.BorrowerB.Business;

  useEffect(() => {
    const loadData = async () => {
      dispatch(setLoader(true));

      const pars = { listName: ListItemNames.ReferralSource, };

      const { Result: allReferralSourceLtItems, } = await ListAPI.getLtItemsSearch(pars);
      const allQDReferralSourceLtItems = allReferralSourceLtItems.filter(ltItem => ltItem.ThirdCode === 'QD');
      setReferralSources(allQDReferralSourceLtItems);

      dispatch(setLoader(false));
    };

    if (referralSources.length === 0) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transformDeclaration = useCallback(
    (primaryBorrowerId: bigint, party: Borrower_, data: Declaration_ | QDCoBorrowerApplicationDeclarations_): CustomDeclaration_ => {
      const partyId = party.Id;
      const isPrimaryBorrower = partyId === primaryBorrowerId;
      const isPersonalParty = party.PartyType === PartyType.Personal;
      const partyFullName = getFullName(party);

      const {
        MarketingQuestionCode, MarketingQuestionCodeStr, MarketingQuestionMemo, Questions,
      } = data;
      const { ESignConsent, RegOResponse, RegOResponseMemo, } = isPrimaryBorrower ? data : party;

      const sectionName = isCoBorrower ? GuarantorPageSectionName_.Declarations : ExternalPageSectionName.Declarations;
      // eslint-disable-next-line max-len
      const isGuarantor = isCoBorrower && page === GuarantorPages.Declarations ? true : party.CoBorrowerAddType === CoBorrowerAddType.AddNow; // External Declaration page show add now guarantors

      const questions = Questions.filter(question => filterQuestion(question, sectionName, isGuarantor, partyId));

      return {
        Id: `${partyId}-${partyFullName}`,
        isPersonalParty,
        isPrimaryBorrower,
        partyFullName,
        questions,
        eSignConsent: ESignConsent,
        regOResponse: RegOResponse,
        regOResponseMemo: RegOResponseMemo,
        marketingQuestionCode: isPrimaryBorrower ? MarketingQuestionCode : '',
        marketingQuestionCodeStr: isPrimaryBorrower ? MarketingQuestionCodeStr : '',
        marketingQuestionMemo: isPrimaryBorrower ? MarketingQuestionMemo : '',
      };
    },
    [isCoBorrower, page]
  );

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !declarations || !primaryBorrower) return [];

    const currentParties = [primaryBorrower];

    if (!isCoBorrower) {
      // Don't include primary business without TIN (Consumer application)
      if (businessBorrowerFromExternalApp.TIN) {
        currentParties.push(businessBorrowerFromExternalApp);
      }

      const addNowGuarantors = guarantors?.filter((g: Borrower_) => g.CoBorrowerAddType === CoBorrowerAddType.AddNow);
      currentParties.push(...addNowGuarantors);
    }

    return currentParties.map((party: Borrower_) => {
      const generatedDeclaration = transformDeclaration(primaryBorrower.Id, party, declarations);

      const {
        Id: declarationId,
        partyFullName,
        eSignConsent,
        regOResponse,
        regOResponseMemo,
        marketingQuestionCode,
        marketingQuestionCodeStr,
        marketingQuestionMemo,
        questions,
        isPrimaryBorrower,
        isPersonalParty,
      } = generatedDeclaration;

      const transformedCustomQuestions = transformCustomQuestions(questions);

      let externalDeclarationsPageData = {};
      if (!isCoBorrower) {
        externalDeclarationsPageData = {
          MarketingQuestion: {
            dataUI: `declaration-marketing-question-text-${declarationId}-field`,
            value: marketingQuestionCodeStr,
            isVisible: !pageFields.MarketingQuestion.IsHidden && marketingQuestionCode && isPrimaryBorrower,
          },
          MarketingQuestionMemo: {
            dataUI: `declaration-marketing-question-memo-${declarationId}-field`,
            value: marketingQuestionMemo,
            customLabel: '',
            isVisible:
              !pageFields.MarketingQuestion.IsHidden
              && marketingQuestionCode
              && isPrimaryBorrower
              && isLtItemSecondCodeVisible(marketingQuestionCode, referralSources),
          },
        };
      }

      return {
        id: `review-declarations-${declarationId}-collapse`,
        title: isCoBorrower ? 'Declarations' : partyFullName,
        pageFields,
        link: { page, text: 'Go to declaration step to edit', },
        data: {
          ...transformedCustomQuestions,
          ESignConsent: {
            dataUI: `declaration-e-sign-consent-${declarationId}-field`,
            value: yesNoOrEmptyAnswer(eSignConsent),
            // Visible for External Commercial Apps - Owners(personal borrower/party) or Email invited Co-Borrowers
            isVisible: !pageFields.ESignConsent.IsHidden && (isCoBorrower || isPersonalParty),
          },
          RegOQuestion: {
            dataUI: `declaration-reg-o-question-${declarationId}-field`,
            value: yesNoOrEmptyAnswer(regOResponse),
            isVisible: !pageFields.RegOQuestion.IsHidden && isPersonalParty,
          },
          RegOResponseMemo: {
            dataUI: `declaration-reg-o-response-${declarationId}-field`,
            value: regOResponseMemo,
            customLabel: 'If yes, please explain:',
            isVisible: !pageFields.RegOQuestion.IsHidden && booleanStringComparison(regOResponse),
          },
          ...externalDeclarationsPageData,
        },
      };
    });
  }, [
    pageFields,
    declarations,
    primaryBorrower,
    isCoBorrower,
    businessBorrowerFromExternalApp,
    guarantors,
    transformDeclaration,
    page,
    referralSources
  ]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(Declarations);
