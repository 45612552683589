import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import Page from '../../Page/Page';
import OtherInformationSettings from './OtherInformationSettings';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import BorrowerBHolder_ from '../../../../data/models/BorrowerHolder';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../../utils/Types';
import { ExternalPages, ExternalPageSectionName } from '../../../../utils/Enums';
import { ISaveHolder as ISaveHolder_ } from '../../../../redux/actions/Holder';
import { EvalSectionState as EvalSectionState_ } from '../../../../redux/actions/VisibleSections';

export interface BusinessBorrowerOtherInformationProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerBHolder_>[],
    holder: QdApplicationHolder_,
    borrower: BorrowerBHolder_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const OtherInformation: FC_<BusinessBorrowerOtherInformationProps> = (props: BusinessBorrowerOtherInformationProps) => {
  const {
    holder, loading, modelState, sectionsState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
    sectionsState: state?.sectionsState,
  }));
  const [borrower, setBorrower] = useState(holder?.BorrowerB);
  const { VisibleSections, } = sectionsState;
  const section = VisibleSections?.find(s => s?.SectionName === ExternalPageSectionName.BusinessBorrowerOtherInformation);
  const [subSection] = section.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  let otherInformationPageFieldHolder = pageFieldHolder?.BusinessInformation?.OtherInformation;
  const { CheckingAccountNumber, } = otherInformationPageFieldHolder;
  const ReCheckingAccountNumber = {
    ...CheckingAccountNumber,
    FieldName: 'ReCheckingAccountNumber',
    SortOrder: CheckingAccountNumber.SortOrder + 1,
  };
  otherInformationPageFieldHolder = { ...otherInformationPageFieldHolder, ReCheckingAccountNumber, };
  const pageSettings = useMemo(() => {
    return new OtherInformationSettings([otherInformationPageFieldHolder], [subSection], null);
  }, [otherInformationPageFieldHolder, subSection]);

  useEffect(() => {
    setBorrower(holder?.BorrowerB);
  }, [holder]);

  const dispatch = useDispatch();
  const updateHolder = () => {
    const { Business, ReCheckingAccountNum, } = borrower;
    const updateBorrower = { ...holder.BorrowerB, Business, ReCheckingAccountNum, };

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: { ...holder, BorrowerB: updateBorrower, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const updateBusinessBorrower = (pageField: PageFieldExtended_<QdApplicationHolder_, BorrowerBHolder_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    if (pageField.FieldName === 'ReCheckingAccountNumber') borrower[ObjectProperty] = value;
    else borrower.Business[ObjectProperty] = value;

    if (ObjectPropertyStr) borrower.Business[ObjectPropertyStr] = text;

    setBorrower({ ...borrower, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerBHolder_>[]) => {
    updateHolder();
    await props.onSubmit(invalidPageFields, holder, borrower, ExternalPages.BusinessBorrowerOtherInformation, false);
  };

  return (
    <>
      {!loading && (
        <>
          <Page
            pageSettings={pageSettings}
            onChange={updateBusinessBorrower}
            holder={holder}
            subHolder={borrower}
            onSubmit={onSubmit}
            formIdentifier={`form-${ExternalPages.BusinessBorrowerOtherInformation}`}
          />
        </>
      )}
    </>
  );
};
export default OtherInformation;
