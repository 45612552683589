/* eslint-disable max-len */
export default {
  GENERAL_ERROR: 'Oops, something unexpected happened.',
  CREATE_APPLICATION_REPORT: 'Generating Application Report...',
  CREATE_PRIMARY_BORROWER: 'Creating Primary Borrower...',
  CREATE_LEAD: 'Creating Lead...',
  EVALUATE_AUTO_CREATE_LR: 'Evaluating auto create loan request',
  CREATE_RELATIONSHIP_FILE: 'Creating Relationship File...',
  CREATE_LR_FILE: 'Creating Loan Request...',
  EVALUATE_CREDIT_SCORE: 'Evaluating credit score...',
  EVALUATE_SCORE_CARD: 'Evaluating score card...',
  EVALUATE_LOAN_OPTIONS: 'Evaluating loan options...',
  EVALUATE_REQUEST: 'Evaluating Request...',
  INVALID_SECURITY_ANSWER: 'You have entered an invalid security question answer',
  GUARANTOR_EMAIL_SENT: 'Email @email sent',
  MINIMUM_LOAN_AMOUNT_ERROR: 'Sorry, the minimum loan amount you can apply for is @amount.',
  MAXIMUM_LOAN_AMOUNT_ERROR: 'Sorry, the maximum loan amount you can apply for is @amount.',
  INVALID_OWNERSHIP_EXPOSURE: 'Please review % Ownership fields as the combined total must equal 100%.',
  INVALID_SECTION: 'Please enter required information above.',
  INVALID_AGE: 'Invalid birthdate. The applicant must be 18 years or older.',
  INVALID_EMAIL: 'Please add correct e-mail address.',
  INVALID_SAME_EMAIL_AS_MAIN_APPLICANT: 'Email Address entered must be different from the main applicant.',
  INVALID_STREET_ADDRESS: 'Enter Physical Address. No PO Boxes.',
  CONTENT_LENGTH_ERROR: 'Please correct content(s) above.',
  INVALID_DATE: 'Entered date is not valid',
  INVALID_DATE_FORMAT: 'must be in correct format (061216 = 06/12/2016 or 06122016 = 06/12/2016)',
  INVALID_DATE_RANGE: 'outside of the valid range',
  INVALID_DISCLOSURES: 'All disclosures must be read and acknowledged.',
  INVALID_NUMBER: 'Enter numbers only, field will auto format.',
  INVALID_TIN_FORMAT: 'Business TIN must be in format: 000000000',
  INVALID_SSN_FORMAT: 'SSN must be in format: 000000000',
  INVALID_ZIP_FORMAT: 'Incorrect ZIP code format',
  INVALID_PHONE_FORMAT: 'Incorrect phone format',
  INVALID_URL: 'Invalid or untrusted URL for redirection',
  UNSUPPORTED_OPERATION: 'This operations is not supported',
  REQUIRED_FIELD: 'Required Field',
  E_SIGN_CONSENT_REQUIRED: 'In order to process your application you must consent to receive communication electronically.',
  INVALID_WRAP_UP_TERMS: 'Please read all terms and conditions, and select the check box to acknowledge agreement!',
  AGREEMENT_NOTIFICATION_DISCLOSURES: 'After you agree with the disclosures each applicant will receive a PDF by e-mail.',
  DISCLOSURES_TOOLTIP_TITLE: 'Click all disclosure links to enable this check box.',
  DISCLOSURES_CHECKBOX_LABEL: 'Please read all disclosures and select the check box to acknowledge agreement.',
  WRAP_UP_CHECKBOX_LABEL: 'Check here to indicate that you have read and agree to the above terms.',
  CO_BORROWER_DISCLOSURES_CHECKBOX_LABEL: 'I have read and agree to the disclosures and terms above.',
  CO_BORROWER_DISCLOSURES_AGREEMENT_NOTIFICATION: 'After you agree with the disclosures you will receive a PDF copy by e-mail.',
  MISSING_INFORMATION: 'Missing information',
  BUSINESS_TIN_SAME_AS_MAIN_APPLICANT:
    'The Business Tax ID entered and Owner Social Security Number entered are the same.  Since the Ownership % entered is less than 100%, please note additional co-applicants/owners added cannot have the same TIN/SSN entered here.',
  BUSINESS_TIN_SAME: 'The Business Tax ID entered is same with other participants.',
  PERSONAL_SSN_SAME: 'The SSN entered is same with other participants.',
};
