/* eslint-disable no-undef */
import React, { FC as FC_ } from 'react';
import { IconsLight } from '@jkhy/vsg-design-system';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../redux/AppState';
import QdApplicationSection_ from '../../../data/models/QDApplicationSection';
import PageFieldHolder_ from '../../../data/models/PageFields/PageFieldHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import Reference_ from '../../../data/models/Reference';
import ReferenceHolder_ from '../../../data/models/ReferenceHolder';
import BusinessReferenceSettings from './BusinessReferenceSettings';
import { ExternalPages, ExternalPageSectionName, ReferenceType } from '../../../utils/Enums';
import HorizontalInfoCardGenerator from '../HorizontalInfoCardGenerator/HorizontalInfoCardGenerator';
import { EvalSectionState as EvalSectionState_ } from '../../../redux/actions/VisibleSections';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';

export interface BusinessReferenceProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, Reference_>[],
    holder: QdApplicationHolder_,
    reference: ReferenceHolder_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const BusinessReference: FC_<BusinessReferenceProps> = (props: BusinessReferenceProps) => {
  const { holder, sectionsState, pageFieldHolder, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      sectionsState: EvalSectionState_;
      pageFieldHolder: PageFieldHolder_;
    }
  >(state => ({
    holder: state.holderState.Holder,
    sectionsState: state?.sectionsState,
    pageFieldHolder: state?.modelState?.ModelHolder?.ApplicationPageField,
  }));

  const { IdQDApplication, References: ReferencesModel, } = holder;
  const { VisibleSections, } = sectionsState;
  const section = VisibleSections?.find(s => s.SectionName === ExternalPageSectionName.References);
  const pageSettings = new BusinessReferenceSettings([pageFieldHolder?.BusinessReferences?.General], [section.SubSections[0]]);

  const onSubmit = async (invalidPageFields, data) => {
    const referencesModelModified = { ...ReferencesModel, References: data, };
    await props.onSubmit(invalidPageFields, holder, referencesModelModified, ExternalPages.BusinessReference, false);
  };

  const onReferenceSave = async (reference: Reference_) => QdServiceApi.saveQDBusinessReference(reference);
  const onReferenceRemove = async (id: BigInt) => QdServiceApi.removeQDBusinessReference(id);
  const getSaveHolderAction = (references): ISaveHolder_ => {
    const referencesModelModified = {
      ...ReferencesModel,
      References: references,
    };
    return {
      holderState: {
        Holder: { ...holder, References: referencesModelModified, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };
  };

  const newReference = () => {
    const newData: Reference_ = {
      GUID: '',
      IdCompany: BigInt(0),
      IdUserLastModified: BigInt(0),
      NullFields: [],
      TreatAllNulls: false,
      Id: BigInt(0),
      IdQDApplication,
      ReferenceType: null,
      ReferenceTypeEnumStr: '',
      Name: '',
      Street: '',
      City: '',
      State: '',
      Zip: '',
      Phone: '',
      Email: '',
    };
    return newData;
  };

  const setIcon = (ref: Reference_) => {
    const type = parseInt(`${ref.ReferenceType}`, 10);
    switch (type) {
      case ReferenceType.Accountant:
        return IconsLight.faCalculator;
      case ReferenceType.InsuranceAgent:
        return IconsLight.faHandsUsd;
      case ReferenceType.Attorney:
      default:
        return IconsLight.faGavel;
    }
  };

  const setTitle = (ref: Reference_) => {
    const type = parseInt(`${ref.ReferenceType}`, 10);
    let typeStr = '';
    switch (type) {
      case ReferenceType.Accountant:
        typeStr = 'Accountant';
        break;
      case ReferenceType.InsuranceAgent:
        typeStr = 'Insurance Agent';
        break;
      case ReferenceType.Attorney:
        typeStr = 'Attorney';
        break;
      default:
        return '';
    }

    return `${typeStr} - ${ref.Name}`;
  };

  return (
    <>
      <HorizontalInfoCardGenerator
        formIdentifier={`form-${ExternalPages.BusinessReference}`}
        onSubmit={onSubmit}
        onSave={onReferenceSave}
        onRemove={onReferenceRemove}
        getSaveHolderAction={getSaveHolderAction}
        pageSettings={pageSettings}
        holder={holder}
        subHolder={ReferencesModel}
        setItemTitle={setTitle}
        addButtonLabel="Add Reference"
        setItemIcon={setIcon}
        newObject={newReference()}
        list={[...ReferencesModel.References]}
      />
    </>
  );
};
export default BusinessReference;
