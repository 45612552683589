import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, TextEditorContentWrapper } from '@jkhy/vsg-design-system';
import ActionLog_ from '../../../../../data/models/QDActionLog';
import {
  GuarantorPageSectionName_, GuarantorPages, QDPartyStatus, PartyType, ContextParams
} from '../../../../../utils/Enums';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { htmlParser } from '../../../../../utils/Helper';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { updateCoBorrowerHolder } from '../../../../../redux/actions/CoBorrowerHolder';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { setLoader } from '../../../../../redux/actions/Loading';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import { getLocalStorageItem, localStorageKeys, removeLocalStorageItem } from '../../../../../utils/LocalStorageManager';

type GuarantorWelcomeProps = {
  logApplication: (log: ActionLog_) => void;
  onSubmit: (invalidPageFields: PageFieldExtended_<any, any>[], holder: QDCoBorrowerApplicationHolder_, section: any, sequence: string) => void;
};

const CoBorrowerWelcome_ = (props: GuarantorWelcomeProps) => {
  const {
    holder, modelState, sectionsState, loading,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const dispatch = useDispatch();
  const context = getLocalStorageItem(localStorageKeys.CONTEXT);

  const model = modelState?.ModelHolder?.Model;
  const welcomeUpHTML = htmlParser(model.GuarantorFirstScreenHTML);
  const welcome = sectionsState?.VisibleSections?.find(s => s.SectionName === GuarantorPageSectionName_.Welcome);

  const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;

  const updateStatus = async () => {
    const res = await QdServiceApi.updateQDPartyStatus(coBorrower.GUID, QDPartyStatus.Accepted);
    if (res.Success) {
      const newHolderData = { ...holder, };

      if (coBorrower.PartyType === PartyType.Personal) {
        newHolderData.Personal.CoBorrower.Status = QDPartyStatus.Accepted;
      } else {
        newHolderData.Business.CoBorrower.Status = QDPartyStatus.Accepted;
      }

      dispatch(updateCoBorrowerHolder(newHolderData));
    }
  };

  useEffect(() => {
    (async function init() {
      if (context === ContextParams.CurrentRequests) {
        if (coBorrower.Status === QDPartyStatus.Pending) {
          await updateStatus();
        }

        removeLocalStorageItem(localStorageKeys.CONTEXT);
      }
    }());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context, coBorrower.Status]);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(setLoader(true));
    const newCoBorrowerStatus = QDPartyStatus.Accepted;

    const welcomeHolderData = {
      ...holder.Welcome,
      CoBorrowerStatus: newCoBorrowerStatus,
    };

    const logParams = new ActionLog_();

    const pars: ActionLog_ = {
      ...logParams,
      IdQDApplicationModel: model.Id,
      ModelName: model?.Code,
      IDQDParty: coBorrower?.Id,
      PartyName: coBorrower?.FullName,
      Details: 'Co-borrower/Guarantor: Application Started',
      Category: 'Navigation',
      Action: 'Navigate',
      ActionStatus: 'Success',
    };

    await props.logApplication(pars);
    await props.onSubmit([], holder, welcomeHolderData, GuarantorPages.Welcome);

    const newCoBorrower = {
      ...coBorrower,
      Status: newCoBorrowerStatus,
    };

    const newCoBorrowerData = coBorrower?.PartyType === PartyType.Personal
      ? { Personal: { ...holder.Personal, CoBorrower: newCoBorrower, }, }
      : { Business: { ...holder.Business, CoBorrower: newCoBorrower, }, };

    dispatch(updateCoBorrowerHolder({ ...holder, ...newCoBorrowerData, }));
  };

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.Welcome}`} onSubmit={onSubmit}>
          <Section title={welcome.SubSections[0]?.SubSectionName} className="mb-3" dataUI="welcome-coborrower-up-section">
            <p>
              <TextEditorContentWrapper dataUI="welcome-coborrower-section-content">{welcomeUpHTML}</TextEditorContentWrapper>
            </p>
          </Section>
        </form>
      )}
    </>
  );
};

export default CoBorrowerWelcome_;
