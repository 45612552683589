import React, { FC as FC_, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Radio } from '@jkhy/vsg-design-system';
import IdologyQuestion_ from '../../../../data/models/IdologyQuestion';
import IdologyAnswer_ from '../../../../data/models/IdologyAnswer';
import IdentityVerificationAPI, { IdologySubmitPars as IdologySubmitPars_ } from '../../../../data/api/IdentityVerificationApi';
import Borrower_ from '../../../../data/models/Borrower';
import { IdologyStages, IdologyVerificationStatuses, ValueConstants } from '../../../../utils/Enums';
import Messages from '../../../../utils/Messages';
import { useFormData, CustomFormData as CustomFormData_ } from '../../../../hooks/useFormData';
import { IdentityState as IdentityState_ } from '../../../../redux/actions/Identity';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { setLoader } from '../../../../redux/actions/Loading';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../redux/actions/CoBorrowerIdentity';
import QDPartyDetails_ from '../../../../data/models/QDPartyDetails';

type IdologyProps = {
  borrower: Borrower_;
  formId: string;
  completeIdentityVerificationProcess: (
    identityCheckType: typeof ValueConstants.PreciseID | typeof ValueConstants.Idology,
    verificationStatus: typeof IdologyVerificationStatuses[keyof typeof IdologyVerificationStatuses],
    partyDetails: QDPartyDetails_
  ) => void;
};

type FieldTypes = string;

const Idology: FC_<IdologyProps> = ({ borrower, completeIdentityVerificationProcess, formId, }: IdologyProps) => {
  const { identityState, coBorrowerIdentityState, } = useSelector<
    AppState_,
    {
      identityState: IdentityState_;
      coBorrowerIdentityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    identityState: state.identityState,
    coBorrowerIdentityState: state.coBorrowerIdentityState,
  }));

  const isPrimaryBorrower = borrower.IsPrimaryBorrower;
  const transactionId = isPrimaryBorrower ? identityState.IdentityHolder.TransactionID : coBorrowerIdentityState.IdentityHolder.TransactionID;

  const [questions, setQuestions] = useState<IdologyQuestion_[]>([]);
  const [stage, setStage] = useState<IdologyStages>(null);

  const dispatch = useDispatch();
  const {
    formData, setFormData, onFormValuesChange, validate,
  } = useFormData<FieldTypes>();

  const completeIdologyProcess = (
    verificationStatus?: typeof IdologyVerificationStatuses[keyof typeof IdologyVerificationStatuses],
    partyDetails?: QDPartyDetails_
  ) => {
    setStage(IdologyStages.Done);
    completeIdentityVerificationProcess(ValueConstants.Idology, verificationStatus, partyDetails);
  };

  useEffect(() => {
    const loadQuestions = async () => {
      dispatch(setLoader(true));

      const { Success, Result, } = await IdentityVerificationAPI.getIdologyQuestions(borrower.GUID, transactionId);
      if (Success) {
        const { DifferentiatingQuestions, Questions, } = Result;

        const hasDifferentiatingQuestions = DifferentiatingQuestions?.length > 0;
        const hasQuestions = Questions?.length > 0;

        if (!hasDifferentiatingQuestions && !hasQuestions) {
          dispatch(setLoader(false));
          completeIdologyProcess();
          return;
        }

        const newQuestions = hasDifferentiatingQuestions ? DifferentiatingQuestions : Questions;
        const newStage = hasDifferentiatingQuestions ? IdologyStages.Differential : IdologyStages.Questions;

        setQuestions(newQuestions);
        setStage(newStage);
      }

      dispatch(setLoader(false));
    };

    if (questions.length === 0 && borrower.GUID) {
      loadQuestions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (questions.length > 0) {
      const emptyFormData: CustomFormData_<FieldTypes> = {};
      questions.forEach(({ Question: questionName, }: IdologyQuestion_) => {
        emptyFormData[questionName] = {
          value: null,
          isValid: true,
        };
      });

      setFormData(emptyFormData);
    }
  }, [questions, setFormData]);

  const generateSubmitParameters = (): IdologySubmitPars_ => {
    const generatedQuestions = questions.map((question: IdologyQuestion_) => {
      const { Question: questionName, } = question;
      const selectedAnswer = formData[questionName].value;

      return {
        ...question,
        Answers: [{ Text: selectedAnswer, }],
      };
    });

    return {
      TransactionID: transactionId,
      // To Do: Rename parameter to IdQDParty when the server change it
      idParty: borrower.Id,
      // Тhe оbject of questions may contain the  Primary and Differentiating types of questions.
      DifferentiatingQuestions: stage === IdologyStages.Differential ? generatedQuestions : null,
      Questions: stage === IdologyStages.Questions ? generatedQuestions : null,
      AdditionalQuestions: stage === IdologyStages.Additional ? generatedQuestions : null,
    };
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const isValid = validate();

    if (!isValid) {
      return;
    }

    dispatch(setLoader(true));
    const data = generateSubmitParameters();

    switch (stage) {
      case IdologyStages.Questions: {
        const { Success, Result, } = await IdentityVerificationAPI.submitIdologyQuestions(data);
        // The loader need to be stop here, because completeIdologyProcess trigger redux action that could Re-Inquire Holder
        // (Re-Inquire Holder functionality has own loading functionality)
        dispatch(setLoader(false));

        if (Success) {
          const { Questions, VerificationStatus, QDPartyDetails, } = Result;

          if (Questions.length > 0) {
            setQuestions(Questions);
            setStage(IdologyStages.Additional);
            break;
          }

          completeIdologyProcess(VerificationStatus, QDPartyDetails);
        }

        break;
      }
      case IdologyStages.Additional: {
        const { Success, Result, } = await IdentityVerificationAPI.submitIdologyAdditionalQuestions(data);
        dispatch(setLoader(false));

        if (Success) {
          const { VerificationStatus, QDPartyDetails, } = Result;
          completeIdologyProcess(VerificationStatus, QDPartyDetails);
        }

        break;
      }
      case IdologyStages.Differential: {
        const { Success, Result, } = await IdentityVerificationAPI.submitIdologyDifferentiatingQuestions(data);
        dispatch(setLoader(false));

        if (Success) {
          const { Questions, } = Result;

          if (Questions.length > 0) {
            setQuestions(Questions);
            setStage(IdologyStages.Questions);
            break;
          }

          completeIdologyProcess();
        }

        break;
      }
      default:
        break;
    }
  };

  return (
    <form id={formId} data-ui="Idology" onSubmit={handleSubmit}>
      {questions.map(({ Question: questionName, Answers: answers, }: IdologyQuestion_) => {
        // The formData is formed after setting the questions in the state (UseEffect)
        if (!formData || !formData[questionName]) {
          return null;
        }

        return (
          <FormGroup
            key={questionName}
            className="mb-2"
            checkboxOrRadio
            dataUI={questionName}
            label={questionName}
            isRequired
            isValid={formData[questionName].isValid}
            validationMessage={Messages.REQUIRED_FIELD}
          >
            {answers.map(({ Text: answerText, }: IdologyAnswer_) => {
              const key = `${questionName}-${answerText}`;

              return (
                <Radio
                  key={key}
                  dataUI={key}
                  className="mb-05"
                  name={questionName}
                  htmlFor={key}
                  id={key}
                  value={answerText}
                  checked={formData[questionName].value === answerText}
                  onChange={() => onFormValuesChange(questionName, answerText)}
                >
                  {answerText}
                </Radio>
              );
            })}
          </FormGroup>
        );
      })}
    </form>
  );
};

export default Idology;
