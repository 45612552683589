import { Reducer as Reducer_ } from 'redux';
import { CoBorrowerHolderActions as CoBorrowerHolderActions_, CoBorrowerHolderState as CoBorrowerHolderState_ } from '../actions/CoBorrowerHolder';

const initialState: CoBorrowerHolderState_ = {
  CoBorrowerHolder: null,
  IsCoBorrowerHolderFetched: false,
};

const coBorrowerHolderReducer: Reducer_<CoBorrowerHolderState_, CoBorrowerHolderActions_> = (state = initialState, action) => {
  switch (action.type) {
    case 'GotCoBorrowerHolder':
    case 'SaveCoBorrowerHolder': {
      return { ...action.coBorrowerHolderState, };
    }
    default:
      return state;
  }
};
export default coBorrowerHolderReducer;
