import React, { FC as FC_ } from 'react';
import { useSelector } from 'react-redux';
import { ReactAux, Section, TextEditorContentWrapper } from '@jkhy/vsg-design-system';
import _ from 'lodash';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../redux/AppState';
import { htmlParser } from '../../../utils/Helper';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';

export interface WelcomeProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[],
    holder: QdApplicationHolder_,
    section: {},
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const Welcome: FC_<WelcomeProps> = (props: WelcomeProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));
  const model = modelState?.ModelHolder?.Model;
  const welcomeUpHTML = htmlParser(model.WelcomeApplicationHTML);
  const welcome = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.Welcome);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onSubmit([], holder, welcome, ExternalPages.Welcome, false);
  };

  return (
    <ReactAux>
      {!loading && (
        <form id={`form-${ExternalPages.Welcome}`} onSubmit={onSubmit}>
          <Section title={welcome.SubSections[0]?.SubSectionName} className="mb-3" dataUI="welcome-up-section">
            <p>
              <TextEditorContentWrapper dataUI="welcome-section-content">{welcomeUpHTML}</TextEditorContentWrapper>
            </p>
          </Section>
        </form>
      )}
    </ReactAux>
  );
};

export default Welcome;
