import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Page from '../../../Page/Page';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { CoBorrowerBusinessAddressSettings_ } from '../../AddNow/Address/BusinessAddressSettings';
import { GuarantorPageSectionName_, GuarantorPages, AddressType } from '../../../../../utils/Enums';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import {
  AddressDetails as AddressDetails_,
  ExternalModelHolder as ExternalModelHolder_,
  ModelState as ModelState_
} from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationBusinessAddresses as QDCoBorrowerApplicationBusinessAddresses_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import QDAddress_ from '../../../../../data/models/QDAddress';
import PageField_ from '../../../../../data/models/PageField';
import { booleanStringComparison } from '../../../../../utils/Helper';
import { getGoogleApiAddressAsString } from '../../../../../utils/Address';

type CoBorrowerBusinessAddressProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationBusinessAddresses_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationBusinessAddresses_,
    sequence: string
  ) => void;
};

const CoBorrowerBusinessAddress = (props: CoBorrowerBusinessAddressProps_) => {
  const {
    modelState, sectionsState, loading, holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const section = sectionsState?.VisibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.BusinessAddress);
  const [addresses, setAddresses] = useState(holder?.AddressesB);

  const businessAddressGeneralPageFields = modelState?.ModelHolder?.CoBorrowerPageField?.BusinessAddress?.General;
  const businessAdressPageFields = useMemo(() => {
    const { SortOrder: currentAddressSortOrder, ...currentAddress } = businessAddressGeneralPageFields.CurrentAddress;
    const { SortOrder: mailingAddressSortOrder, ...mailingAddress } = businessAddressGeneralPageFields.MailingAddress;

    return {
      ...businessAddressGeneralPageFields,
      StreetAddress: { ...currentAddress, FieldName: 'StreetAddress', SortOrder: currentAddressSortOrder + 1, },
      City: { ...currentAddress, FieldName: 'City', SortOrder: currentAddressSortOrder + 2, },
      State: { ...currentAddress, FieldName: 'State', SortOrder: currentAddressSortOrder + 3, },
      Zip: { ...currentAddress, FieldName: 'Zip', SortOrder: currentAddressSortOrder + 4, },
      DifferentMailingAddress: { ...new PageField_(), FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressSortOrder - 1, },
      MailingZip: { ...mailingAddress, FieldName: 'MailingZip', SortOrder: mailingAddressSortOrder + 1, },
      MailingCity: { ...mailingAddress, FieldName: 'MailingCity', SortOrder: mailingAddressSortOrder + 2, },
      MailingState: { ...mailingAddress, FieldName: 'MailingState', SortOrder: mailingAddressSortOrder + 3, },
    };
  }, [businessAddressGeneralPageFields]);

  const updateBusinessAddress = (
    pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationBusinessAddresses_>,
    value: string,
    e,
    text: string
  ) => {
    const { ObjectProperty, ObjectPropertyStr, ObjectIndex, } = pageField;

    const changedAddressData = { ...addresses, };

    if (changedAddressData.BusinessAddresses?.length === 0) {
      const newAddress = new QDAddress_();
      changedAddressData.BusinessAddresses.push(newAddress);
    }

    changedAddressData.BusinessAddresses[ObjectIndex][ObjectProperty] = value;
    if (ObjectPropertyStr) changedAddressData.BusinessAddresses[ObjectIndex][ObjectPropertyStr] = text;

    setAddresses(changedAddressData);
  };

  const pageSettings = useMemo(() => {
    return new CoBorrowerBusinessAddressSettings_([businessAdressPageFields], section?.SubSections);
  }, [businessAdressPageFields, section]);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationBusinessAddresses_>[]) => {
    const {
      Business: {
        CoBorrower: { Id, IdQDApplication, },
      },
    } = holder;

    const modifiedBusinessAddresses = addresses.BusinessAddresses.map(address => {
      const changedAddress = {
        ...address,
        AddressType: AddressType.Current,
        IdQDParty: Id,
        IdQDApplication,
      };

      // Reset mailing fields
      if (!booleanStringComparison(changedAddress.DifferentMailingAddress)) {
        changedAddress.MailingAddress = '';
        changedAddress.MailingCity = '';
        changedAddress.MailingState = '';
        changedAddress.MailingZip = '';
      }

      return changedAddress;
    });

    const changedAddressData = {
      ...addresses,
      BusinessAddresses: modifiedBusinessAddresses,
    };

    setAddresses(changedAddressData);

    props.onSubmit(invalidPageFields, holder, changedAddressData, GuarantorPages.BusinessAddresses);
  };

  const onAddressSelect = (
    pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationBusinessAddresses_>,
    addressDetails: AddressDetails_
  ) => {
    const { ObjectProperty, ObjectIndex, } = pageField;

    if (addressDetails) {
      const changedAddressData = { ...addresses, };
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;

      changedAddressData.BusinessAddresses[ObjectIndex] = {
        ...changedAddressData.BusinessAddresses[ObjectIndex],
        [ObjectProperty]: fullAddress,
        Address: streetAddress,
        City: city,
        State: state,
        Zip: zipCode,
      };

      setAddresses({ ...changedAddressData, });
    }
  };

  useEffect(() => {
    let existingAddress = holder?.AddressesB?.BusinessAddresses?.find(a => a?.AddressType === AddressType.Current);

    if (existingAddress) {
      existingAddress.GoogleAPIAddress = getGoogleApiAddressAsString(existingAddress);
    } else {
      existingAddress = new QDAddress_();
      existingAddress.AddressType = AddressType.Current;
    }

    setAddresses({ ...addresses, BusinessAddresses: [existingAddress], });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateBusinessAddress}
          onAddressSelect={onAddressSelect}
          holder={holder}
          subHolder={addresses}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessAddresses}`}
        />
      )}
    </>
  );
};

export default CoBorrowerBusinessAddress;
