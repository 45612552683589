import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PageField_ from '../../../data/models/PageField';
import { ValidationRegexConstants } from '../../../utils/Enums';
import InputMasksConstants from '../../../utils/InputMasksConstants';

export default class InformationSettings<T, T1> extends PageSettings<T, T1, any> {
  // eslint-disable-next-line class-methods-use-this
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<T, T1> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'ESignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
      case 'OfficerQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'OfficerQuestionCode',
        });
      case 'ZipCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'ZipBusinessLocated',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
          IsValid: (holder, app, pf) => {
            if (!pf.Required || pf.IsHidden) return true;
            return !!app.ZipBusinessLocated;
          },
        });
      case 'BranchQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'BranchQuestionCode',
        });
      case 'CountyCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              stateLess: true,
            },
          },
        });
      case 'LocationOfBusiness':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'StateBusinessLocated',
        });
      case 'ApplicationTypeQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'ApplicationTypeAnswer',
        });

      default:
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
    }
  }
}
