import React, { FC as FC_, memo } from 'react';
import { useSelector } from 'react-redux';
import Disclosures from '../Disclosures/Disclosures';
import {
  DisplayOptions, ExternalPageSectionName, GuarantorPageSectionName_, ValueConstants
} from '../../../../utils/Enums';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import { AppState as AppState_ } from '../../../../redux/AppState';
import Section_ from '../../../../data/models/Section';
import { DisclosuresState as DisclosuresState_ } from '../../../../redux/actions/Disclosures';
import ReviewApplicationGeneralSection from '../ReviewApplicationGeneralSection';
import InformationDeclarations from './InformationDeclarations/InformationDeclarations';

type InformationProps = ReviewApplicationSectionProps_;

const Information: FC_<InformationProps> = ({
  isCoBorrower, page, sectionTitle, expandState, setExpandState,
}: InformationProps) => {
  const { sections, disclosuresState, } = useSelector<
    AppState_,
    {
      sections: Section_[];
      disclosuresState: DisclosuresState_;
    }
  >(state => ({
    sections: state.sectionsState.VisibleSections,
    disclosuresState: state.disclosuresState,
  }));

  const { informationDisclosures, } = disclosuresState || {};
  const sectionName = isCoBorrower ? GuarantorPageSectionName_.Information : ExternalPageSectionName.InformationMembership;
  const informationSection = sections.find(s => s.SectionName === sectionName);

  const declarationsSubCode = isCoBorrower
    ? ValueConstants.CoBorrowerInformationDeclarationsSubSection
    : ValueConstants.InformationDeclarationsSubSection;
  const informationDeclarationsSubSection = informationSection?.SubSections.find(el => el.Code === declarationsSubCode);

  const disclosuresSubCode = isCoBorrower
    ? ValueConstants.CoBorrowerInformationDisclosuresSubSection
    : ValueConstants.InformationDisclosureSubSection;
  const informationDisclosuresSubSection = informationSection?.SubSections.find(el => el.Code === disclosuresSubCode);

  if (
    informationDeclarationsSubSection?.DisplayOption !== DisplayOptions.Always
    && informationDisclosuresSubSection?.DisplayOption !== DisplayOptions.Always
    && !informationDisclosures?.hasDisclosures
  ) {
    return null;
  }

  return (
    <ReviewApplicationGeneralSection page={null} sectionTitle={sectionTitle} plainContainer>
      <>
        {informationDeclarationsSubSection?.DisplayOption === DisplayOptions.Always && (
          <InformationDeclarations isCoBorrower={isCoBorrower} page={page} expandState={expandState} setExpandState={setExpandState} />
        )}

        {informationDisclosuresSubSection?.DisplayOption === DisplayOptions.Always && informationDisclosures?.hasDisclosures && (
          <Disclosures isCoBorrower={isCoBorrower} page={page} expandState={expandState} setExpandState={setExpandState} />
        )}
      </>
    </ReviewApplicationGeneralSection>
  );
};

export default memo(Information);
