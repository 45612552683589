import { AddressType as AddressType_ } from '../../utils/Enums';

export default class QDAddress {
  Id: bigint;

  IdQDParty: bigint;

  IdQDApplication: bigint;

  GUID: string;

  AddressType: AddressType_;

  Address: string;

  Zip: string;

  City: string;

  State: string;

  AddressStartDate: string;

  AddressEndDate: string;

  CurrentHousingStatusCode: string;

  CurrentHousingStatusCodeStr: string;

  DifferentMailingAddress: boolean;

  MailingAddress: string;

  MailingZip: string;

  MailingCity: string;

  MailingState: string;

  // FE purpose, no DataBase representation
  GoogleAPIAddress: string;

  NullFields?: string[];

  IsSavedByUser: boolean;
}
