import { Reducer as Reducer_ } from 'redux';
import QDPrequalification_ from '../../data/models/Prequalification/QDPrequalification';
import { GetPrequalificationHolderAction as GetPrequalificationHolderAction_, GET_PREQUALIFICATION_HOLDER } from '../actions/PrequalificationHolder';

const prequalificationHolderReducer: Reducer_<QDPrequalification_, GetPrequalificationHolderAction_> = (state = null, action) => {
  const { type, payload, } = action;

  switch (type) {
    case GET_PREQUALIFICATION_HOLDER: {
      return { ...payload, };
    }
    default:
      return state;
  }
};

export default prequalificationHolderReducer;
