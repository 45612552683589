export default class ActionLog {
  IPAddress: string;

  ModelName: string;

  PartyName: string;

  IdQDApplicationModel: bigint;

  IDQDApplication: bigint;

  IDQDParty: bigint;

  GUIDQDApplication: string;

  GUIDQDParty: string;

  GUIDQDPrequalification: string;

  TimeStamp: string;

  Action: string;

  ActionStatus: string;

  Details: string;

  Category: string;

  IdCompany: bigint;

  GUID: string;

  Id: bigint;

  TreatAllNulls: boolean;

  IdUserLastModified: bigint;
}
