import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import AutoDetailsModel_ from '../../../data/models/AutoDetails';
import Messages from '../../../utils/Messages';

export const NEW_OR_USED_FIELD_DATA_UI = 'auto-details-new-or-used-flag-field';
export const PURCHASE_PRICE_FIELD_DATA_UI = 'auto-details-purchase-price-field';
export const YEAR_FIELD_DATA_UI = 'auto-details-year-field';
export const MAKE_FIELD_DATA_UI = 'auto-details-make-field';
export const MODEL_FIELD_DATA_UI = 'auto-details-model-field';
export const VIN_FIELD_DATA_UI = 'auto-details-vin-field';
export const MILEAGE_FIELD_DATA_UI = 'auto-details-mileage-field';

export default class AutoDetailsSettings extends PageSettings<QdApplicationHolder_, AutoDetailsModel_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, AutoDetailsModel_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'NewOrUsed':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'NewFlag',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'New', },
              { value: 'false', label: 'Used', }
            ],
          },
          dataUI: NEW_OR_USED_FIELD_DATA_UI,
        });

      case 'PurchasePrice':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'PurchasePrice',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: PURCHASE_PRICE_FIELD_DATA_UI,
        });

      case 'Year':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'Year',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            inputType: 'number',
            maxLength: 4,
            maxValue: 2099,
            minValue: 1800,
          },
          IsValid: (appHolder, app, pf) => {
            const result = app.AutoDetails.Year;

            if (pageField.IsHidden) return true;

            if (!pageField.Required && !result) return true;

            if (!result) {
              const pageF = pf;
              pageF.ValidationMessage = Messages.REQUIRED_FIELD;
              return false;
            }

            if (result <= 2099 && result >= 1800) {
              return true;
            }

            const pageF = pf;
            pageF.ValidationMessage = 'Year should be between 1800 and 2099';
            return false;
          },
          dataUI: YEAR_FIELD_DATA_UI,
        });

      case 'Make':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'Make',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 30,
          },
          dataUI: MAKE_FIELD_DATA_UI,
        });

      case 'Model':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'Model',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 30,
          },
          dataUI: MODEL_FIELD_DATA_UI,
        });

      case 'VIN':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'VIN',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 30,
          },
          dataUI: VIN_FIELD_DATA_UI,
        });

      case 'Mileage':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'AutoDetails',
          ObjectProperty: 'Mileage',
          ObjectPropertyType: 'number',
          Component: {
            type: 'input',
            inputType: 'number',
            maxLength: 11,
            maxValue: 999999999,
          },
          IsValid: (appHolder, app, pf) => {
            const result = app.AutoDetails.Mileage;

            if (pageField.IsHidden) return true;

            if (!pageField.Required && !result) return true;

            if (!result) {
              const pageF = pf;
              pageF.ValidationMessage = Messages.REQUIRED_FIELD;
              return false;
            }

            if (result <= pf.Component.maxValue && result >= 0) {
              return true;
            }

            const pageF = pf;
            pageF.ValidationMessage = 'Mileage should be between 0 and 999 999 999';
            return false;
          },
          dataUI: MILEAGE_FIELD_DATA_UI,
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
