export default class YourInformation {
  FirstName: string;

  LastName: string;

  DateOfBirth: string;

  TIN: string;

  IdPartyMatch: bigint;

  Address: string;

  AddressConfirmed: boolean;

  HasDoneBusinessPreviously: boolean;
}
