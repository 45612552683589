import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { GuarantorPages, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import { IncomeExpensesSettings_ } from '../../AddNow/IncomeExpenses/IncomeExpensesSettings';
import Page from '../../../Page/Page';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import Employment_ from '../../../../../data/models/Employment';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationEmployment as QDCoBorrowerApplicationEmployment_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';

type IncomeExpensesProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Employment_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationEmployment_,
    sequence: string
  ) => void;
};

const IncomeExpenses_ = (props: IncomeExpensesProps_) => {
  const { holder, modelState, loading, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    loading: state.loading,
    holder: state.coBorrowerHolderState?.CoBorrowerHolder,
  }));

  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.Employment);

  const [incomeExpenses, setIncomeExpenses] = useState(holder?.IncomeExpenses?.Employment ?? new Employment_());
  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const pageSettings = useMemo(() => {
    return new IncomeExpensesSettings_([pageFieldHolder?.IncomeandExpenses?.General], section?.SubSections);
  }, [pageFieldHolder, section]);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Employment_>[]) => {
    if (invalidPageFields.length === 0) {
      if (!incomeExpenses?.IdQDParty) {
        const {
          CoBorrower: { Id, IdQDApplication, },
        } = holder?.Personal;
        incomeExpenses.IdQDParty = Id;
        incomeExpenses.IdQDApplication = IdQDApplication;
      }
      const updatedHolder: QDCoBorrowerApplicationEmployment_ = { ...holder.IncomeExpenses, Employment: incomeExpenses, };
      props.onSubmit(invalidPageFields, holder, updatedHolder, GuarantorPages['Income&Expenses']);
    }
  };

  const updateIncomeExpenses = (pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Employment_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    incomeExpenses[ObjectProperty] = value;
    if (ObjectPropertyStr) incomeExpenses[ObjectPropertyStr] = text;

    setIncomeExpenses({ ...incomeExpenses, });
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateIncomeExpenses}
          holder={holder}
          subHolder={incomeExpenses}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages['Income&Expenses']}`}
        />
      )}
    </>
  );
};

export default IncomeExpenses_;
