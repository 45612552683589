/* eslint no-unused-vars: off */

export const enum ApplicationTypes {
  External = 'External',
  Prequalification = 'Prequalification',
  Renewal = 'Renewal',
}

export const enum GuarantorPagesAddNow {
  Authentication = 'guarantor-authentication',
  Start = 'start',
  Information = 'my-information',
  Declarations = 'declarations',
  Review = 'review',
  Complete = 'complete',
  Disabled = 'application-disabled',
  IdentityCheck = 'identity',
  Employment = 'employment',
}

export enum GuarantorPages {
  Authentication = 'guarantor-authentication',
  Welcome = '1',
  Information = '2',
  PersonalPartyMatch = '3',
  PersonalInformation = '4',
  IdentityCheck = '4.2',
  PersonalAddress = '5',
  'Income&Expenses' = '6',
  BusinessPartyMatch = '7',
  BusinessInformation = '8',
  BusinessOtherInformation = '9',
  BusinessAddresses = '10',
  Complete = '11',
  // Sequence of items below, should be same as External Pages
  // As this sections are re-used from Borrower External App
  Declarations = '18',
  Disclosures = '19',
  Terms = '20',
  Review = '21',
}

export const enum GuarantorPageSectionName_ {
  Welcome = 'CoBorrowerWelcome',
  Information = 'CoBorrowerInformation',
  PersonalPartyMatch = 'CoBorrowerPersonalPartyMatch',
  PersonalInformation = 'CoBorrowerPersonalInformation',
  BusinessPartyMatch = 'CoBorrowerBusinessPartyMatch',
  BusinessInformation = 'CoBorrowerBusinessInformation',
  BusinessOtherInformation = 'CoBorrowerBusinessOtherInformation',
  PersonalAddress = 'CoBorrowerPersonalAddress',
  BusinessAddress = 'CoBorrowerBusinessAddress',
  Employment = 'CoBorrowerEmployment',
  Identity = 'Identity',
  Declarations = 'Declarations',
  Disclosures = 'Disclosures',
  ReviewApplication = 'Review Application',
  ThankYou = 'CoBorrowerThankYou',
  Review = 'Status',
}

export const enum ExternalPages {
  Welcome = '1',
  InformationMembership = '2',
  PurposeProduct = '3',
  AutoDetails = '4',
  PersonalPartyMatch = '5',
  PersonalBorrower = '6',
  PersonalAddresses = '7',
  IdentityCheck = '7.1',
  Employment = '8',
  BusinessPartyMatch = '9',
  BusinessBorrower = '10',
  BusinessBorrowerOtherInformation = '11',
  BusinessAddresses = '12',
  BusinessReference = '13',
  BusinessObligations = '14',
  BusinessDeposits = '15',
  CoBorrower = '16',
  Collateral = '17',
  Declaration = '18',
  Disclosures = '19',
  WrapUp = '20',
  Review = '21',
}

export const enum CompletePages {
  Complete = 'complete',
  Documents = 'documents',
  Status = 'status',
  Expired = 'expired',
}

export const enum IdentityCheckStatus {
  ManualCheckRequired = 0,
  Validated = 1,
  NotValidated = 2,
  ManuallyValidated = 3,
}

export enum ExternalPageSectionName {
  IdentityCheck = 'Identity',
  Welcome = 'Welcome',
  InformationMembership = 'InformationMembership',
  PersonalPartyMatch = 'PersonalPartyMatch',
  PurposeProduct = 'PurposeProduct',
  AutoDetails = 'AutoDetails',
  YourInformation = 'YourInformation',
  PersonalAddresses = 'PersonalAddresses',
  Employment = 'Employment',
  BusinessPartyMatch = 'BusinessPartyMatch',
  BusinessInformation = 'BusinessInformation',
  BusinessBorrowerOtherInformation = 'BusinessBorrowerOtherInformation',
  BusinessAddresses = 'BusinessAddresses',
  References = 'References',
  BusinessObligations = 'BusinessObligations',
  BusinessDeposits = 'BusinessDeposits',
  Guarantor = 'Guarantor',
  Collateral = 'Collateral',
  Declarations = 'Declarations',
  Disclosures = 'Disclosures',
  TermsAndCondition = 'RequiredDocs',
  Review = 'Status',
}

export enum CoBorrowerAddNowPages {
  PersonalMatch = '1',
  PersonalInformation = '2',
  PersonalAddress = '3',
  IdentityCheck = '4',
  Employment = '5',
  //  important are type of indexes: 1st Personal, then Business
  BusinessMatch = '6',
  BusinessInformation = '7',
  OtherInformation = '8',
  BusinessAddress = '9',
}

export const enum AnswerType {
  AnswerString = 0,
  AnswerBoolean = 1,
  AnswerInteger = 2,
  AnswerDecimal = 3,
  AnswerDate = 4,
  AnswerMemo = 5,
  AnswerYear = 6,
}

export const enum RenewalPages {
  RenewalIdentity = '0',
  Renewal = '1',
  Information = '2',
  RenewalDeclarations = '3',
  WrapUp = '4',
  RenewalStatus = '5',
  Submitted = '6',
}

export const enum RenewalPageSectionName {
  RenewalIdentity = 'RenewalIdentity',
  Renewal = 'Renewal',
  Information = 'Information',
  RenewalDeclarations = 'RenewalDeclarations',
  RenewalStatus = 'RenewalStatus',
}

export const enum RenewalType {
  RenewalSameAmount = 0,
  RenewalIncreaseAmount = 1,
  RenewalDecreaseAmount = 2,
}

export const enum QDPartyStatus {
  Pending = 0,
  Stopped = 1,
  Accepted = 2,
  DataGathered = 3,
  Authenticated = 4,
  FailedAuthentication = 5,
  Submitted = 6,
  Declined = 7,
  DeclarationsGathered = 8,
  NonCompliant = 9,
}

export const enum QDPartyStatusName {
  InvitationSent = 'Invitation Sent',
  InvitationRejected = 'Invitation Rejected',
  ApplicationStarted = 'Application Started',
  IdentityUnverified = 'Identity Unverified',
  Complete = 'Complete',
  Withdrawn = 'Withdrawn',
  ApplicantStopped = 'Applicant Stopped',
}

export const enum ApplicationStatus {
  Started = 0,
  Approved = 1,
  Denied = 2,
  OnHold = 3,
  Accepted = 4,
  WaitingOnCoApplicant = 5,
  ClosedExpired = 6,
  ApprovedExpired = 7,
  ClosedAnnul = 8,
  Submitted = 9,
  HoldRemoved = 10,
  ApprovedWithdrawn = 11,
  ClosedWithdrawn = 12,
}

export enum ClientContext {
  Owner = 1,
  Guarantor = 2,
}

export enum IdentityVerificationSystem {
  IDology = 0,
  PreciseID = 1,
}

export enum ValueConstants {
  MaxMoney = 922337203685477.5807,
  MinBusinessFormedYear = 1753,
  ShouldStopApplication = -1,
  BorrowerBusinessSubSectionCode = 'BusInfo',
  CoBorrowerInformationDeclarationsSubSection = 'CoAppInfDecl',
  CoBorrowerInformationDisclosuresSubSection = 'CoAppInfDisc',
  DeclarationsSubSection = 'Declarations',
  InformationDeclarationsSubSection = 'InfoDecl',
  InformationDisclosureSubSection = 'InfoDiscl',
  TersmAndConditionSubSection = 'ReqDocs',
  Idology = 'Idology',
  PreciseID = 'PreciseID',
  DropDownDefaultValue = 'Select...',
}

export enum BorrowerType {
  Borrower = 0,
  CoBorrower = 1,
  Guarantor = 2,
}

export enum DebtDepositsPageEnum {
  Debts = 'Debts',
  Deposits = 'Deposits',
}

export enum SubmitTotalStepsConstants {
  Default = 7,
  HasEmailInvitedCoBorrowers = 2,
}

export enum SaveProcessStage {
  SaveSection = 2,
  EvalSection = 3,
  EvalSubSection = 4,
  EvalIdentitySection = 5,
}

export enum PartyType {
  Personal = 0,
  Business = 1,
}

export enum CoBorrowerAddType {
  EmailInvitation = 0,
  AddNow = 1,
}

export enum SameAddressType {
  No = 0,
  Yes = 1,
}

export enum AddressType {
  Business = 0,
  Mailing = 1,
  Home = 2,
  Seasonal = 3,
  BillingAddress = 4,
  Previous = 5,
  Current = 6,
}

export enum ReferenceType {
  Accountant = 0,
  Attorney = 1,
  InsuranceAgent = 2,
}

export enum DisplayOptions {
  Always = 0,
  Never = 1,
  Conditional = 2,
}

export enum ValidationRegexConstants {
  ZipCode = '([0-9]){5}',
  TIN_SSN = '([0-9]){9}',
  Phone = '\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}',
  WebSite = '[w]{3}[.]{1}[a-z0-9]{3,}[.]{1}[a-zA-Z]{2,}',
  Email = '[A-Za-z0-9._%+-]{3,}@[a-zA-Z]{3,}([.]{1}[a-zA-Z]{2,}|[.]{1}[a-zA-Z]{2,}[.]{1}[a-zA-Z]{2,})',
}

export const URLValidationConstants = {
  PrequalificationResultQualifies : /^[\w\/]+\/section\/[a-zA-Z0-9._~()!@,;-]+\/1\/[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\/$/i
};

export enum AddressPersonalPageSteps {
  CurrentAddress = 1,
  HorizontalCardOverview = 2,
  PreviousAddress = 3,
}

export enum ListItemNames {
  ReferralSource = 'ReferralSource',
}

export const REGEX = {
  PO_BOX: /^((?!p\.?o\.? box).)*$/gi,
};

export const enum AlertTypes {
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger',
  Info = 'info',
}

export enum GrossIncomePeriods {
  Monthly = 'Monthly',
  Annually = 'Annually',
}

export enum ApplicableFor {
  PreSubmit = 0,
  PostSubmit = 1,
  CoAppPreSubmit = 2,
  CoAppPostSubmit = 3,
  PreSubmitCommercial = 4,
  Prequalification = 5,
  CoAppPreSubmitCom = 6,
}

export enum CustomerType {
  Commercial = 0,
  Consumer = 1,
}

export const enum DateFormats {
  MonthDayYear = 'MM/DD/YYYY',
  DayMonthYear = 'DD/MM/YYYY',
  YearMonthDay = 'YYYY/MM/DD',
}

export const enum WorkerActions {
  CounterStart = 'counter-start',
  CounterEnd = 'counter-end',
  CounterReset = 'counter-reset',
  CounterResetWithLogicStart = 'counter-reset-with-logic-start',
  CounterResetWithLogicEnd = 'counter-reset-with-logic-end',
  TimeoutComponentCountdown = 'timeout-component-countdown',
  TimeoutComponentIntervalValue = 'timeout-component-interval-value',
  TimeoutComponentCountdownIntervalClear = 'timeout-component-countdown-interval-clear',
}

export const enum ContextParams {
  CurrentRequests = 'current-requests',
}

export const enum QueryParams {
  Context = 'context',
}

export const enum IdologyResponseStatuses {
  FAIL = 0,
  PASS = 1,
  ERROR = 2,
  FAILED = 3,
  PARTIAL = 4,
}

export const enum IdologyVerificationStatuses {
  MISSING = 0,
  FAIL = 1,
  PASS = 2,
  MANUAL = 3,
}

export enum PreciseIDDecisions {
  ManualCheckRequired = 0,
  Validated = 1,
  NotValidated = 2,
  ManuallyValidated = 3,
}

export const enum IdologyStages {
  Questions = 1,
  Differential = 2,
  Additional = 3,
  Done = 4,
}
