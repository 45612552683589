/* eslint-disable no-unused-vars */
/* eslint no-console: "off" */
import toastr from 'toastr';
import moment from 'moment';
import _ from 'lodash';
import { v4 as guid } from 'uuid';
import parse from 'html-react-parser';
import { ValidationMessage, isGoogleApiUnavailable } from '@jkhy/vsg-design-system';
import React from 'react';
import DOMPurify from 'dompurify';
import PageField_ from '../data/models/PageField';
import Section_ from '../data/models/Section';
import PageFieldHolder_ from '../data/models/PageFields/PageFieldHolder';
import PageFieldExtended_ from '../pages/External/Page/PageHelpers/PageFieldExtended';
import Messages from './Messages';
import Borrower_ from '../data/models/Borrower';
import QDApplicationBase_ from '../data/models/QDApplicationBase';
import {
  CompletePages,
  ExternalPages,
  ExternalPageSectionName,
  GrossIncomePeriods,
  GuarantorPages,
  PartyType,
  REGEX,
  ApplicableFor,
  IdentityCheckStatus,
  ApplicationTypes,
  GuarantorPageSectionName_
} from './Enums';
import ListItem_ from '../data/models/ListItem';
import ActionLog_ from '../data/models/QDActionLog';
import ResponseBase_ from '../data/models/ResponseBase';
import QdServiceApi from '../data/api/QDServiceApi';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_, RenewalModelHolder as RenewalModelHolder_ } from './Types';
import { HolderState as HolderState_ } from '../redux/actions/Holder';
import { CoBorrowerHolderState as CoBorrowerHolderState_ } from '../redux/actions/CoBorrowerHolder';
import { googleAPIDisabledFromBrowser } from './LocalStorageManager';
import { PrequalificationModelHolder as PrequalificationModelHolder_ } from '../redux/actions/PrequalificationModel';
import { QDApplicationTypeAnswer } from '../data/models/Information';

toastr.options = {
  tapToDismiss: true,
  toastClass: 'toast',
  containerId: 'toast-container',
  debug: false,
  extendedTimeOut: 0,
  iconClass: 'toast-info',
  positionClass: 'toast-bottom-right',
  timeOut: 1000,
  titleClass: 'toast-title',
  messageClass: 'toast-message',
};

type DateFormat = number | string | Date;
export type FormatOverload = {
  (value: number, type: 'p' | 'c', displayFormat?: number): string;
  (value: DateFormat, type: 'd', displayFormat?: string): string;
};

export const informationMsg = (warning, timeout: number) => {
  const toastrEl = toastr.warning('', warning.Title, { timeOut: timeout || 5000, });
  return toastrEl;
};

export const errorMsg = errors_ => {
  const MAX_LENGTH = 40;
  let errors = errors_;
  if (errors && errors.Message) {
    if (errors.ExceptionType === 5) {
      return informationMsg({ Title: errors.Description, }, null);
    }
    errors.Description = errors.Description || errors.Message;
    // eslint-disable-next-line max-len
    errors.ShortMessage = errors.ShortMessage || (errors.Description.length > MAX_LENGTH ? `${errors.Description.substr(0, MAX_LENGTH)}...` : errors.Description);
    errors.InnerException = errors.InnerException || {};
    if ((errors.InnerException.Message || '').trim().toLowerCase() === (errors.Message || '').trim().toLowerCase()) {
      errors.InnerException.Message = null;
    }
  } else if (errors && errors.xhr && errors.xhr.status === 500) {
    errors = {
      Title: errors.xhr.statusText,
      Message: errors.xhr.statusText,
      StackTrace: errors.xhr.responseText,
    };
  }
  const error = {
    err: errors,
    title: errors.Title || Messages.GENERAL_ERROR,
  };
  const toastrOptions = { timeOut: 5000, };
  toastr.error('', error.title, toastrOptions);

  return null;
};

export const scrollToError = (element: HTMLElement) => {
  if (element) element.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center', });
};

export const scrollToTopOfThePage = () => {
  if (window.pageYOffset !== 0) {
    const appContainer = document.getElementById('app');
    appContainer.scrollIntoView({ behavior: 'smooth', inline: 'start', block: 'start', });
  }
};

export const isNullOrUndefined = value => {
  return `${value}` === 'null' || `${value}` === 'undefined';
};

export const successMsg = (timeOut_: number, msg_: string) => {
  const timeOut = timeOut_ || 3000;
  const msg = msg_ || 'Changes saved successfully.';
  const success = {
    Title: '',
    Message: msg,
  };
  const toastSuccess = toastr.success(success.Message, success.Title, { timeOut, });
  return toastSuccess;
};

export const getInternetExplorerVersion = () => {
  let rv = -1;
  const { appName, userAgent, } = navigator;
  let regExpression;
  try {
    if (appName === 'Microsoft Internet Explorer') {
      regExpression = new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})');
      if (regExpression.exec(userAgent)) {
        rv = parseFloat(RegExp.$1);
      }
    } else if (appName === 'Netscape') {
      regExpression = new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})');
      if (regExpression.exec(userAgent) != null) {
        rv = parseFloat(RegExp.$1);
      }
    }
    return rv;
  } catch (ex) {
    rv = -1;
  }
  return rv;
};

export const rawHtmlParser = (encodedStr: string):any => {
  if (!encodedStr) {
    return '';
  }

  const domParser = new DOMParser();
  const dom = domParser.parseFromString(`<!doctype html><body>${encodedStr}</body>`, 'text/html');

  const parsedHtml = dom.body.textContent;
  return parse(parsedHtml);
};

export const htmlParser = (encodedStr: string):any => (typeof encodedStr === 'string' && encodedStr.length > 0 ? parse(encodedStr) : '');

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

export const setPageFieldObj = (pageFields: PageField_[]): PageFieldHolder_ => {
  const pageFieldResult = {};

  _.each(pageFields, pf => {
    const groupSplit = pf.GroupName.split('/');
    const property = groupSplit[0].replace(/[- ]*/g, '');
    const subProperty = (groupSplit[1] || '').replace(/[- ]*/g, '');

    function setPageField(pageFieldsParam: PageField_[], groupName: string) {
      const pfResult = {};
      const fields = _.filter(pageFieldsParam, pff => pff.GroupName === groupName);

      _.each(fields, f => {
        pfResult[f.FieldName] = f;
      });

      return pfResult;
    }

    pageFieldResult[property] = pageFieldResult[property] || {};

    if (groupSplit.length === 1) {
      pageFieldResult[property] = setPageField(pageFields, pf.GroupName);
    }

    if (groupSplit.length === 2) {
      pageFieldResult[property][subProperty] = pageFieldResult[property][subProperty] || {};
      pageFieldResult[property][subProperty] = setPageField(pageFields, pf.GroupName);
    }
  });

  return pageFieldResult as PageFieldHolder_;
};

export const invalidFieldLookUp = <T, T2, T3, T1 extends PageFieldExtended_<T2, T3>>(prop: T, invalidFields: T1[]): T1 => {
  const invalidField = invalidFields.find((iv: T1) => iv.FieldName === prop.toString());
  const iv = invalidField as T1;
  return iv;
};

export const isFieldValid = <T, T2, T3, T1 extends PageFieldExtended_<T2, T3>>(prop: T, invalidFields: T1[]): boolean => {
  return !invalidFields.find((iv: T1) => iv.FieldName === prop.toString());
};

export const errorFound = <T, T2, T3, T1 extends PageFieldExtended_<T2, T3>>(prop: T, invalidFields: T1[]): JSX.Element => {
  const iv = invalidFieldLookUp<T, T2, T3, T1>(prop, invalidFields);
  return iv ? <ValidationMessage message={iv?.ValidationMessage || Messages.REQUIRED_FIELD} /> : <></>;
};

export const errorFoundMessage = <T, T2, T3, T1 extends PageFieldExtended_<T2, T3>>(prop: T, invalidFields: T1[]): string => {
  const iv = invalidFieldLookUp<T, T2, T3, T1>(prop, invalidFields);
  return iv ? iv?.ValidationMessage : '';
};

type QDApplicationBaseKeys = keyof Borrower_;

export const validateAddressBlock = (prop: QDApplicationBaseKeys, borrower: Borrower_) => {
  const { MailingZip, MailingAddress, } = borrower;
  if (prop === 'MailingZip') {
    const regexExpZipCode = RegExp('^[0-9]{5}([0-9]{4})?$');
    return regexExpZipCode.test(MailingZip);
  }

  const regexExpNoPoBox = new RegExp(REGEX.PO_BOX);
  return regexExpNoPoBox.test(MailingAddress);
};

export const validatePhoneNumber = (app: QDApplicationBase_) => {
  const regExPhoneNumber = RegExp(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im);
  return regExPhoneNumber.test(app.IfYesPhoneNumberChangedAnswer);
};

export const validateAddressNoPoBox = (address: string) => {
  const regexExpNoPoBox = new RegExp(REGEX.PO_BOX);
  return regexExpNoPoBox.test(address);
};

export const currencyFormat = (value: number, digits: number = 0) => {
  let num = value;
  if (!!num && typeof num === 'string') {
    num = parseFloat(`${num}`.replace(/,| /g, ''));
  }
  return num ? `$ ${num.toFixed(digits).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}` : '';
};

export const percentageFormat = (num: number, fractionPartSymbolsCount = 0) => (num ? `${(num * 100).toFixed(fractionPartSymbolsCount)}%` : '');

export const dateFormat = (date: DateFormat, dFormat: string = 'MM/DD/YYYY'): string => {
  return date === null || date === undefined || date === '' ? '' : moment(date).format(dFormat);
};

export const format: FormatOverload = (value, type, displayFormat) => {
  switch (type) {
    case 'p':
      return percentageFormat(value, displayFormat);
    case 'c':
      return currencyFormat(value, displayFormat);
    case 'd':
      return dateFormat(value, displayFormat);
    default:
      return '';
  }
};

export const attachCustomCss = (model: string) => {
  const [head] = document.getElementsByTagName('head');
  const { Registrar: { RegistrarURL, }, } = (window as any).CONFIG;
  const cssLink = document.createElement('link');
  const sanitizedURL = DOMPurify.sanitize(`${RegistrarURL}/api/branding/${window.INSTANCE_ID}/${window.LV_API_NAME}/${model}/css`);
  cssLink.href = sanitizedURL;
  cssLink.media = 'screen';
  cssLink.type = 'text/css';
  cssLink.rel = 'stylesheet';

  head.appendChild(cssLink);
};

export const attachBrandingIcon = (model: string, faviconUrl: string = '') => {
  const linkNode = document.createElement('link');
  const cdnUrl = (window as any).CONFIG.Registrar.RegistrarURL;

  let faviconHref = faviconUrl || `/api/branding/${window.INSTANCE_ID}/${window.LV_API_NAME}/${model}/favicon`;
  // Check url has domain
  if (faviconHref.indexOf('/api') === 0) faviconHref = `${cdnUrl}${faviconHref}`;
  const sanitizedURL = DOMPurify.sanitize(`${faviconHref}?nocache=${guid()}`);
  linkNode.href = sanitizedURL;
  linkNode.rel = 'shortcut icon';
  linkNode.type = 'image/x-icon';
  document.head.appendChild(linkNode);
};

export const getAge = dateOfBirth => {
  const dob = new Date(dateOfBirth);
  const currentDate = new Date();

  if (!dob) return 0;
  if (currentDate < dob) return 0;

  const ageDiffMs = currentDate.getTime() - dob.getTime();
  const ageDate = new Date(ageDiffMs);
  let age = Math.abs(ageDate.getUTCFullYear() - 1970);

  const yearDiff = currentDate.getUTCFullYear() - dob.getUTCFullYear();
  if (age === 18 && yearDiff === 18) {
    const monthDiff = currentDate.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && dob.getDate() > currentDate.getDate())) {
      age = 17;
    }
  }

  return age;
};

export const getUniqueKey = (): string => guid();

export const getHoverHelpPageFieldValue = (pf: PageField_): string => {
  return pf?.HoverHelpEnabledFlag ? pf.HoverHelp : null;
};

export const filterSections = (prop: string, allItems: Section_[], sourceItems: bigint[]): any => allItems.filter(item => {
  const sn = (sourceItems || []).find(idSource => item[prop] === idSource);
  return _.isNumber(sn);
});

export const isLtItemSecondCodeVisible = (marketingQuestionCode: string, ltItems: ListItem_[]): boolean => {
  const foundListItem = ltItems.find(ltItem => ltItem?.Code === marketingQuestionCode);

  return !!foundListItem?.SecondCode;
};

export const booleanStringComparison = (val: string | boolean): boolean => `${val}` === 'true';

export const formComponentsVisibility = (components, period: string, monthlyPropertyName: string, annuallyPropertyName: string) => {
  switch (period) {
    case GrossIncomePeriods.Monthly:
      return components.filter(c => c.ObjectProperty !== annuallyPropertyName);
    case GrossIncomePeriods.Annually:
      return components.filter(c => c.ObjectProperty !== monthlyPropertyName);
    default:
      return components;
  }
};

export const grossIncomeText = (monthly: number, annually, period: string) => {
  const value = period === GrossIncomePeriods.Monthly ? monthly : annually;
  return value ? `${currencyFormat(value)} / ${period}` : '';
};

export const joinValidElementsInString = (elements: any[] = [], separator: string = '') => elements.filter(element => element).join(separator);

export const getFullName = (borrower: Borrower_): string => {
  if (!borrower) {
    return '';
  }

  const {
    FirstName, MiddleName, LastName, FullName,
  } = borrower;

  if (borrower.PartyType === PartyType.Personal) {
    return joinValidElementsInString([FirstName, MiddleName, LastName], ' ');
  }

  if (borrower.PartyType === PartyType.Business) {
    return FullName;
  }

  return '';
};

export const maskText = (value: string | number, maskSymbol: string, maskSymbolLength: number | 'all-symbols') => {
  if (isNullOrUndefined(`${value}`)) return '';

  if (maskSymbolLength === 'all-symbols') return `${maskSymbol.repeat(7)}`;

  const maskedValue = `${value}`.substring(0, maskSymbolLength).replace(/./g, maskSymbol);
  const nonMaskedValue = `${value}`.substring(maskSymbolLength, `${value}`.length);

  return `${maskedValue}${nonMaskedValue}`;
};

export const maskLoanNumber = (value: string) => {
  if (!value) return '';

  let maskIdx = value.length - 4;
  if (maskIdx < 0) maskIdx = 0;
  return `*****${value.substring(maskIdx)}`;
};

export const isDateBefore = (date: DateFormat, rangeEnd: DateFormat) => {
  const endDate = moment(rangeEnd)
    .endOf('day')
    .toDate();

  return moment(date).isSameOrBefore(endDate);
};

export const isDateSame = (firstDate: DateFormat, secondDate: DateFormat) => {
  const formattedFirstDate = moment(firstDate)
    .startOf('day')
    .toDate();
  const formattedSecondDate = moment(secondDate)
    .startOf('day')
    .toDate();

  return moment(formattedFirstDate).isSame(formattedSecondDate);
};

export const isDateAfter = (date: DateFormat, rangeStart: DateFormat) => {
  const startDate = moment(rangeStart)
    .startOf('day')
    .toDate();

  return moment(date).isSameOrAfter(startDate);
};

export const getSubSectionPageHeader = (
  sections: Section_[],
  sectionName: ExternalPageSectionName | GuarantorPageSectionName_,
  defaultPageHeader: string
) => {
  const foundSection = sections.find(s => s.SectionName === sectionName);
  const firstSubSection = foundSection?.SubSections?.length > 0 ? foundSection?.SubSections[0] : null;

  return firstSubSection?.SubSectionName || defaultPageHeader;
};

export const getEnumKeyByEnumValue = (myEnum: Object, enumValue: string | number) => {
  if (!myEnum || Object.keys(myEnum)?.length === 0 || !enumValue) return null;

  return Object.keys(myEnum).find(key => myEnum[key] === enumValue);
};

export const capitalizeFirstLetter = (text: string) => (text ? text.charAt(0).toUpperCase() + text.slice(1) : '');

export const logApplication = (data: ActionLog_): Promise<ResponseBase_<boolean>> => QdServiceApi.storeApplicationLog(data);

export const prepareActionLogSectionData = (
  sequence: ExternalPages | GuarantorPages,
  modelState: ModelState_<ExternalModelHolder_>,
  holderState: HolderState_ | CoBorrowerHolderState_,
  isEmailInvitedGuarantor: boolean,
  coBorrowerAddNowPage?: GuarantorPages
) => {
  const logPars = new ActionLog_();

  const model = modelState?.ModelHolder?.Model;
  const sections = isEmailInvitedGuarantor
    ? modelState?.ModelHolder?.CoBorrowerSections
    : modelState?.ModelHolder?.ApplicationSections.filter(
        s => [ApplicableFor.PreSubmit, ApplicableFor.PostSubmit, ApplicableFor.PreSubmitCommercial].includes(s.ApplicableFor)
        // To Do -> Use visible sections when Eval functionality is fixed
      );

  let sectionName = sequence === ExternalPages.Review ? 'Review' : sections?.find((s: Section_) => s.Sequence === sequence)?.SectionName;

  let borrower: Borrower_;

  if (isEmailInvitedGuarantor) {
    const { CoBorrowerHolder: holder, } = holderState as CoBorrowerHolderState_;
    borrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;
    sectionName = `Invited Co-Applicant: ${sectionName}`;
  } else {
    const { Holder: holder, } = holderState as HolderState_;
    borrower = holder?.BorrowerP?.Personal ?? holder?.BorrowerB?.Business;
  }

  if (coBorrowerAddNowPage) {
    const addNowPageName = getEnumKeyByEnumValue(GuarantorPages, coBorrowerAddNowPage);
    sectionName = `Add Now Co-Applicant: ${addNowPageName || ''}`;
  }

  return {
    ...logPars,
    IdQDApplicationModel: model?.Id,
    ModelName: model?.Code,
    IDQDParty: borrower?.Id,
    PartyName: getFullName(borrower),
    Details: sectionName ? `Section: ${sectionName} Visited` : '',
    Category: 'Navigation',
    Action: 'Navigate',
    ActionStatus: 'Success',
  };
};

export const prepareActionLogCompleteSectionData = (
  completePage: CompletePages,
  modelState: ModelState_<ExternalModelHolder_>,
  holderState: HolderState_
) => {
  const { Holder: holder, } = holderState;

  const logPars = new ActionLog_();
  const sectionName = completePage === CompletePages.Complete ? 'Results' : capitalizeFirstLetter(completePage);
  const model = modelState?.ModelHolder?.Model;
  const borrower = holder?.BorrowerP?.Personal ?? holder?.BorrowerB?.Business;

  return {
    ...logPars,
    IdQDApplicationModel: model?.Id,
    ModelName: model?.Code,
    IDQDParty: borrower?.Id,
    PartyName: getFullName(borrower),
    Details: sectionName ? `Section: ${sectionName} Visited` : '',
    Category: 'Navigation',
    Action: 'Navigate',
    ActionStatus: 'Success',
  };
};

export const yesNoOrEmptyAnswer = (value: boolean): string => {
  if (!isNullOrUndefined(`${value}`)) {
    return booleanStringComparison(value) ? 'Yes' : 'No';
  }

  return '';
};

export const deepComparison = (firstItem, secondItem): boolean => {
  return firstItem && secondItem ? JSON.stringify(firstItem) === JSON.stringify(secondItem) : firstItem === secondItem;
};

export const changeDocumentTitle = (title: string, additionalText: string) => {
  if (title || additionalText) {
    document.title = joinValidElementsInString([title, additionalText], ' - ');
  }
};

export const getQueryParameter = (key: string, locationSearch: string) => {
  const queries = new URLSearchParams(locationSearch);
  return queries.get(key);
};

export const sortVisibleSections = (visibleSections: Section_[]): any => _.sortBy(visibleSections || [], s => parseFloat(s.Sequence));

export const checkIsReviewPageVisible = (visibleSections: Section_[], reviewPage: GuarantorPages.Review | ExternalPages.Review) => {
  return visibleSections.some(vs => vs.Sequence === reviewPage);
};

// TODO: Remove googleAPIDisabledFromBrowser, once delivered to COCC , version 4.3.74
export const isGoogleUnavailable = () => isGoogleApiUnavailable() || booleanStringComparison(googleAPIDisabledFromBrowser());

export const checkIsPartyMatched = (borrower: Borrower_): boolean => {
  if (!borrower) {
    return false;
  }

  const { PartyMatchDone, IdentityCheck, IdParty, } = borrower;

  return PartyMatchDone && IdentityCheck === IdentityCheckStatus.Validated && !!IdParty;
};

export const generateUrlFromBase64String = (base64String: string, documentType: string): string => {
  // Remove spaces from base64string for IE compatibility
  const stringWithoutSpaces = base64String.replace(/\s/g, '');
  const binary = atob(stringWithoutSpaces);
  const binaryLength = binary.length;
  const buffer = new ArrayBuffer(binaryLength);
  const view = new Uint8Array(buffer);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binaryLength; i++) {
    view[i] = binary.charCodeAt(i);
  }

  const blob = new Blob([view], { type: documentType, });
  return URL.createObjectURL(blob);
};

export const downloadFileFromDocumentBytes = (base64String: string, fileName: string = 'default', fileType = 'pdf') => {
  const a = document.createElement('a');
  // For Safari data type should be attachment/file
  const sanitizedURL = DOMPurify.sanitize(`data:attachment/file;base64,${base64String}`);
  a.href = sanitizedURL;
  const sanitizedFileName = DOMPurify.sanitize(`${fileName}.${fileType}`);
  a.download = sanitizedFileName;

  // for Firefox, the link must be added to the body
  document.body.appendChild(a);

  a.click();

  document.body.removeChild(a);
};

export const getModelHolderBasedOnApplicationType = (
  externalModelState: ModelState_<ExternalModelHolder_>,
  renewalModelState: ModelState_<RenewalModelHolder_>,
  prequalificationModelState: ModelState_<PrequalificationModelHolder_>,
  applicationType: typeof ApplicationTypes[keyof typeof ApplicationTypes]
) => {
  if (applicationType === ApplicationTypes.External) {
    return externalModelState?.ModelHolder;
  }

  if (applicationType === ApplicationTypes.Prequalification) {
    return prequalificationModelState?.ModelHolder;
  }

  return renewalModelState?.ModelHolder;
};

// eslint-disable-next-line max-len
export const shouldUseHolderForEval = (applicationTypeAnswer:QDApplicationTypeAnswer, isCoBorrower: boolean, partyGuid:string) => !isCoBorrower && !partyGuid && applicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString();

export const pascalCaseToWord = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/([a-z])(\d+)/g, '$1 $2').replaceAll(/id[^A-za-z]/gi, 'ID ');