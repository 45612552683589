import React, { FC as FC_, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../../ReviewApplicationGeneralSection';
import { percentageFormat, getFullName } from '../../../../../utils/Helper';
import { isBusinessParty } from '../../../../../utils/Borrower';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../../ReviewApplication';
import Borrower_ from '../../../../../data/models/Borrower';

type GeneralGuarantorsInformationProps = {
  hasFullAccess: boolean;
  guarantor: Borrower_;
} & ReviewApplicationSectionProps_;

const GeneralGuarantorsInformation: FC_<GeneralGuarantorsInformationProps> = ({
  hasFullAccess,
  page,
  guarantor,
  sectionTitle,
  expandState,
  setExpandState,
}: GeneralGuarantorsInformationProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField, },
    },
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    modelState: state.modelState,
  }));

  const pageFields = ApplicationPageField?.Guarantor?.General;

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !guarantor) return [];

    const {
      Id: guarantorId, FullName, FirstName, LastName, DBA, Email, PercentOwnership, BorrowerTypeStr,
    } = guarantor;

    const fullName = getFullName(guarantor);

    const isBusinessGuarantor = isBusinessParty(guarantor);

    return [
      {
        id: `review-guarantor-${guarantorId}-collapse`,
        title: fullName,
        pageFields,
        ...(hasFullAccess ? { link: { page, text: 'Go to Co-borrower step to edit', }, } : {}),
        data: {
          GBusinessName: {
            dataUI: `co-borrower-business-name-${guarantorId}-field`,
            value: FullName,
            isVisible:  isBusinessGuarantor && !pageFields.GBusinessName.IsHidden,
          },
          GDBA: {
            dataUI: `co-borrower-date-of-birth-${guarantorId}-field`,
            value: DBA,
            isVisible: isBusinessGuarantor && !pageFields.GDBA.IsHidden ,
          },
          FirstName: {
            dataUI: `co-borrower-first-name-${guarantorId}-field`,
            value: FirstName,
            isVisible: !isBusinessGuarantor && !pageFields.FirstName.IsHidden,
          },
          LastName: {
            dataUI: `co-borrower-last-name-${guarantorId}-field`,
            value: LastName,
            isVisible: !isBusinessGuarantor && !pageFields.LastName.IsHidden,
          },
          EmailAddress: {
            dataUI: `co-borrower-email-address-${guarantorId}-field`,
            value: Email,
          },
          PercentOwnershipGuarantor: {
            dataUI: `co-borrower-percent-ownership-guarantor-${guarantorId}-field`,
            value: percentageFormat(PercentOwnership, 3),
          },
          Relationship: {
            dataUI: `co-borrower-relationship-${guarantorId}-field`,
            value: BorrowerTypeStr,
          },
        },
      }
    ];
  }, [pageFields, guarantor, page, hasFullAccess]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(GeneralGuarantorsInformation);
