import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';
import Employment_ from '../../data/models/Employment';
import QdServiceApi from '../../data/api/QDServiceApi';

export type GuarantorEmploymentState = {
  GuarantorEmployment: Employment_;
  IsGuarantorEmploymentFetching: boolean;
  IsGuarantorEmploymentFetched: boolean;
};

export interface IGotGuarantorEmployment extends Action_<'GetGuarantorEmployment' | 'SaveGuarantorEmployment'> {
  GuarantorEmploymentState: GuarantorEmploymentState;
}

const getGuarantorEmploymentCreator: ActionCreator_<ThunkAction_<
  Promise<IGotGuarantorEmployment>,
  GuarantorEmploymentState,
  string,
  IGotGuarantorEmployment
>> = (guid: string) => {
  return async (dispatch: Dispatch_) => {
    const fetchingGuarantorEmployment: IGotGuarantorEmployment = {
      GuarantorEmploymentState: {
        GuarantorEmployment: null,
        IsGuarantorEmploymentFetching: true,
        IsGuarantorEmploymentFetched: false,
      },
      type: 'GetGuarantorEmployment',
    };

    dispatch(fetchingGuarantorEmployment);

    dispatch(setLoader(true));

    const { Result: guarantorEmployment, } = await QdServiceApi.inquireQdEmployment(guid);

    const fetchedGuarantorEmployment: IGotGuarantorEmployment = {
      GuarantorEmploymentState: {
        GuarantorEmployment: guarantorEmployment,
        IsGuarantorEmploymentFetching: false,
        IsGuarantorEmploymentFetched: true,
      },
      type: 'GetGuarantorEmployment',
    };

    dispatch(setLoader(false));

    return dispatch(fetchedGuarantorEmployment);
  };
};

export default getGuarantorEmploymentCreator;

export const updateGuarantorEmploymentAction = (guarantorEmployment: Employment_): IGotGuarantorEmployment => {
  return {
    GuarantorEmploymentState: {
      GuarantorEmployment: guarantorEmployment,
      IsGuarantorEmploymentFetching: false,
      IsGuarantorEmploymentFetched: true,
    },
    type: 'SaveGuarantorEmployment',
  };
};
