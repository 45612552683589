import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';

import QDServiceApi_ from '../../data/api/QDServiceApi';
import QDEvaluationIdentityResult_ from '../../data/models/QDEvaluationIdentityResult';
import IdentityCheckEvalParam_ from '../../data/models/IdentityCheckEvalParam';

export type IdentityState = {
  IdentityHolder: QDEvaluationIdentityResult_;
  IsIdentityFetched: boolean;
  IdentityVisited: boolean;
};

export interface IGotIdentity extends Action_<'GetIdentity'> {
  identityState: IdentityState;
}

export interface ISetIdentity extends Action_<'SetIdentity'> {
  identityState: IdentityState;
}

export type IdentityActions = IGotIdentity | ISetIdentity;

export const setIdentityState = (identityState: IdentityState) => ({
  type: 'SetIdentity',
  identityState,
});

export const setIdentityResult = (identityHolder: QDEvaluationIdentityResult_): ISetIdentity => ({
  type: 'SetIdentity',
  identityState: {
    IdentityHolder: identityHolder,
    IsIdentityFetched: true,
    IdentityVisited: false,
  },
});

const getIdentityActionCreator: ActionCreator_<ThunkAction_<Promise<ISetIdentity>, IdentityState, IdentityCheckEvalParam_, IGotIdentity>> = (
  pars: IdentityCheckEvalParam_
) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const { Result: identityResponse, } = await QDServiceApi_.identityCheckEval(pars);

    dispatch(setLoader(false));

    return dispatch(setIdentityResult(identityResponse));
  };
};

export default getIdentityActionCreator;
