/* eslint no-useless-escape: "off" */
/* eslint no-control-regex: "off" */
import { ValidateFormatOptions as ValidateFormatOptions_, ValidateFormatResult as ValidateFormatResult_ } from './Types';
import Messages from './Messages';
import QdApplicationHolder_ from '../data/models/QDApplicationHolder';
import Borrower_ from '../data/models/Borrower';
import PageFieldExtended_ from '../pages/External/Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../data/models/CoBorrowerAddNowHolder';
import QDAddress_ from '../data/models/QDAddress';
import {
  isNullOrUndefined, isDateBefore, isDateAfter, isDateSame
} from './Helper';
import { ValidationRegexConstants, PartyType, AlertTypes } from './Enums';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const validateOnBlur = (pars: ValidateFormatOptions_): ValidateFormatResult_ => {
  const {
    inputType, regexStr, value, errorMessage,
  } = pars;

  const result: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };
  let regexPattern;
  switch (inputType || '') {
    case 'email':
      regexPattern = new RegExp(EMAIL_REGEX);
      result.InvalidMessage = Messages.INVALID_EMAIL;
      break;
    default:
      regexPattern = regexStr ? new RegExp(regexStr) : '';
      if (regexStr) result.InvalidMessage = errorMessage;
  }

  if (!regexPattern || !value) return result;

  result.IsValid = regexPattern.test(value);
  if (result.IsValid) result.InvalidMessage = '';

  return result;
};

export default validateOnBlur;

const generateInvalidResult = (invalidMessage: string): ValidateFormatResult_ => {
  return {
    IsValid: false,
    InvalidMessage: invalidMessage,
  };
};

type GuarantorEmailValidationOverload = {
  (appHolder: QdApplicationHolder_, coBorrower: Borrower_, pageField: PageFieldExtended_<QdApplicationHolder_, Borrower_>): ValidateFormatResult_;

  (
    guarantorAddNowHolder: CoBorrowerAddNowHolder_,
    coBorrower: Borrower_,
    pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>,
    appHolder: QdApplicationHolder_
  ): ValidateFormatResult_;
};

export const validateGuarantorEmail: GuarantorEmailValidationOverload = (holder, coBorrower, pageField, appHolder = null) => {
  const validResult: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };

  if (pageField.IsHidden) return validResult;

  if (pageField.IsHiddenCalculated(holder, coBorrower, pageField)) return validResult;

  const emailValue = coBorrower[pageField.ObjectProperty];

  if (pageField.Required && !emailValue) {
    return {
      IsValid: false,
      InvalidMessage: Messages.REQUIRED_FIELD,
    };
  }

  if (emailValue) {
    const personalBorrowerEmail = appHolder ? appHolder?.BorrowerP?.Personal?.Email : holder?.BorrowerP?.Personal?.Email || '';

    if (personalBorrowerEmail && personalBorrowerEmail === emailValue) {
      return {
        IsValid: false,
        InvalidMessage: Messages.INVALID_SAME_EMAIL_AS_MAIN_APPLICANT,
      };
    }

    if (pageField.Component) {
      const {
        Component: { inputType, },
      } = pageField;

      return validateOnBlur({ inputType, value: emailValue, });
    }
  }

  return validResult;
};

type DifferentTINValidationOverload = {
  (
    holder: QdApplicationHolder_,
    borrower: Borrower_,
    pageField: PageFieldExtended_<QdApplicationHolder_, Borrower_>,
    appHolder: QdApplicationHolder_
  ): ValidateFormatResult_;

  (
    holder: CoBorrowerAddNowHolder_,
    borrower: Borrower_,
    pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>,
    appHolder: QdApplicationHolder_
  ): ValidateFormatResult_;
};

export const validateDifferentTIN: DifferentTINValidationOverload = (holder, borrower, pageField, appHolder): ValidateFormatResult_ => {
  const {
    Component, IsHidden, IsHiddenCalculated, Required, ObjectProperty, ObjectType, Label,
  } = pageField;

  const validResult: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };

  if (IsHidden) return validResult;
  if (IsHiddenCalculated(holder, borrower, pageField)) return validResult;

  const tinValue = borrower[ObjectProperty];

  if (Required && !tinValue) {
    return {
      IsValid: false,
      InvalidMessage: Messages.REQUIRED_FIELD,
    };
  }

  if (tinValue) {
    const personalBorrowerTIN = appHolder?.BorrowerP?.Personal?.TIN;
    const hasLessThen100Percent = (appHolder?.BorrowerP?.Personal?.PercentOwnership ?? 0) < 1;

    if (tinValue === personalBorrowerTIN && hasLessThen100Percent) {
      if (ObjectType === 'Business') {
        return {
          IsValid: true,
          InvalidMessage: '',
          WarningMessage: Messages.BUSINESS_TIN_SAME_AS_MAIN_APPLICANT,
          Type: AlertTypes.Warning,
        };
      }

      return {
        IsValid: false,
        InvalidMessage: `${Label} entered must be different from the main applicant.`,
      };
    }

    if (Component) return validateOnBlur({ ...Component, value: tinValue, });
  }

  return validResult;
};

export const validateESignConsent = (subHolder, pageField) => {
  const validResult: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };

  if (pageField?.IsHidden) return validResult;

  const value = subHolder[pageField.ObjectProperty];

  if (value === false) {
    return {
      InvalidMessage: Messages.E_SIGN_CONSENT_REQUIRED,
      IsValid: false,
    };
  }

  if (pageField?.Required && !value) {
    return {
      InvalidMessage: Messages.REQUIRED_FIELD,
      IsValid: false,
    };
  }

  return validResult;
};

export const validateAddressEndDate = <THolder, TSubHolder>(
  pageField: PageFieldExtended_<THolder, TSubHolder>,
  address: QDAddress_,
  startDateLabel: string = 'Address Start Date'
) => {
  const validResult: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };

  if (pageField.IsHidden) return validResult;

  const value = address[pageField.ObjectProperty];

  if (pageField.Required && !value) {
    return generateInvalidResult(Messages.REQUIRED_FIELD);
  }

  if (value) {
    if (isNullOrUndefined(address.AddressStartDate) || address.AddressStartDate === '') return validResult;
    if (isNullOrUndefined(address.AddressEndDate) || address.AddressEndDate === '') return validResult;

    const isValid = isDateSame(address.AddressEndDate, address.AddressStartDate) || isDateAfter(address.AddressEndDate, address.AddressStartDate);

    if (!isValid) {
      return generateInvalidResult(`Date entered cannot precede ${startDateLabel}.`);
    }
  }

  return validResult;
};

export const validateDateRanges = <THolder, TSubHolder>(
  holder: THolder,
  subHolder: TSubHolder,
  pageField: PageFieldExtended_<THolder, TSubHolder>
): ValidateFormatResult_ => {
  const validResult: ValidateFormatResult_ = { IsValid: true, InvalidMessage: '', };

  const {
    IsHiddenCalculated, Required, IsHidden, ObjectProperty, ObjectType, ObjectIndex, Component,
  } = pageField;

  const rangeStart = Component?.rangeStart;
  const rangeEnd = Component?.rangeEnd;

  if (IsHidden) return validResult;
  if (IsHiddenCalculated(holder, subHolder, pageField)) return validResult;

  let value = null;

  if (ObjectType) {
    value = ObjectIndex ? subHolder[ObjectType][ObjectIndex][ObjectProperty] : subHolder[ObjectType][ObjectProperty];
  } else {
    value = subHolder[ObjectProperty];
  }

  if (Required && !value) {
    return generateInvalidResult(Messages.REQUIRED_FIELD);
  }

  if (value) {
    if (rangeStart && rangeEnd) {
      const isValid = isDateAfter(value, rangeStart) && isDateBefore(value, rangeEnd);

      if (isValid) {
        return validResult;
      }

      return generateInvalidResult('Date entered does not correspond to the start and the end range.');
    }

    if (rangeStart) {
      const isValid = isDateAfter(value, rangeStart);

      if (isValid) {
        return validResult;
      }

      return generateInvalidResult('Date entered cannot be in the past.');
    }

    if (rangeEnd) {
      const isValid = isDateBefore(value, rangeEnd);

      if (isValid) {
        return validResult;
      }

      return generateInvalidResult('Date entered cannot be a future date.');
    }
  }

  return validResult;
};

export const validatePartyMatchTIN = (
  tin: string,
  partyType: PartyType,
  appHolder: QdApplicationHolder_,
  checkForDifferentTin: boolean
): ValidateFormatResult_ => {
  if (!tin) {
    return generateInvalidResult(Messages.REQUIRED_FIELD);
  }

  const isPersonalParty = partyType === PartyType.Personal;

  const { IsValid, InvalidMessage, } = validateOnBlur({
    regexStr: ValidationRegexConstants.TIN_SSN,
    value: tin,
    errorMessage: isPersonalParty ? Messages.INVALID_SSN_FORMAT : Messages.INVALID_TIN_FORMAT,
  });

  if (!IsValid) {
    return generateInvalidResult(InvalidMessage);
  }

  if (checkForDifferentTin && appHolder) {
    const participants = [...appHolder?.Guarantor?.Guarantors, appHolder?.BorrowerB?.Business, appHolder?.BorrowerP?.Personal];

    const hasSameTIN = participants.find(g => g?.TIN === tin);

    if (hasSameTIN) {
      return generateInvalidResult(isPersonalParty ? Messages.PERSONAL_SSN_SAME : Messages.BUSINESS_TIN_SAME);
    }
  }

  return { IsValid: true, InvalidMessage: '', };
};
