import { Reducer as Reducer_ } from 'redux';
import {
  GuarantorEmploymentState as GuarantorEmploymentState_,
  IGotGuarantorEmployment as IGotGuarantorEmployment_
} from '../actions/GuarantorEmployment';

const guarantorEmploymentReducer: Reducer_<GuarantorEmploymentState_, IGotGuarantorEmployment_> = (
  state = {
    GuarantorEmployment: null,
    IsGuarantorEmploymentFetching: false,
    IsGuarantorEmploymentFetched: false,
  },
  action
) => {
  switch (action.type) {
    case 'GetGuarantorEmployment':
    case 'SaveGuarantorEmployment': {
      return { ...action.GuarantorEmploymentState, };
    }
    default:
      return state;
  }
};

export default guarantorEmploymentReducer;
