import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import {
  ActionsWrapper, Button, Collapse, IconsSolid, Section, ProgressBar
} from '@jkhy/vsg-design-system';
import BaseComponent from '../Layout/BaseComponent';
import NotImplementedPage from '../NotImplement/NotImplement';
import { getPrequalificationUrl } from '../../utils/prequalification/Navigation';
import { PrequalificationPages, PrequalificationStatus, PrequalificationSectionName } from '../../utils/prequalification/Enums';
import { AppState as AppState_ } from '../../redux/AppState';
import { PrequalificationRoutingParameters as PrequalificationRoutingParameters_ } from '../../utils/prequalification/Types';
import { PrequalificationModelState as PrequalificationModelState_ } from '../../redux/actions/PrequalificationModel';
import QDPrequalification_ from '../../data/models/Prequalification/QDPrequalification';
import PrequalificationResultsQualifies from './Results/Qualifies/PrequalificationResultsQualifies';
import PrequalificationResultsNotQualified from './Results/NotQualified/PrequalificationResultsNotQualified';
import { isNullOrUndefined, htmlParser, logApplication } from '../../utils/Helper';
import { COUNT_OF_SUBMIT_PROCESS_REQUESTS, submitPrequalificationProcessGenerator } from '../../utils/SubmitProcess';
import { redirectToCP } from '../../utils/Navigation';
import Prequalification from './PrequalificationPage/Prequalification';
import QdPrequalCompletePars_ from '../../data/models/Prequalification/QDPrequalCompletePars';
import { setPrequalificationHolder } from '../../redux/actions/PrequalificationHolder';
import GeneratorHost, { IGeneratorParameters as IGeneratorParameters_ } from '../../utils/GeneratorHost';
import ActionLog_ from '../../data/models/QDActionLog';
import { updateStopApplicationCondition as updateStopApplicationConditionAction } from '../../redux/actions/AppSettings';

export const PREQUALIFICATION_SECTION_DATA_UI = 'prequalification-base-section';

const PrequalificationBase = props => {
  const { prequalificationSequence: sequence, } = useParams<PrequalificationRoutingParameters_>();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [prequalDisclosurCollapseExpanded, setPrequalDisclosureCollapseExpanded] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [showProgress, setShowProgress] = useState(false);

  const {
    isLoading, prequalificationModelState, prequalificationHolder, exception,
  } = useSelector<
    AppState_,
    {
      isLoading: boolean;
      prequalificationModelState: PrequalificationModelState_;
      prequalificationHolder: QDPrequalification_;
      exception;
    }
  >(state => ({
    isLoading: state.loading,
    prequalificationModelState: state.prequalificationModelState,
    prequalificationHolder: state.prequalificationHolder,
    exception: state.exception,
  }));

  const modelCode = prequalificationModelState.ModelHolder.Model?.Code;
  const {
    GUID, QDPrequalificationStatus, FirstName, LastName,
  } = prequalificationHolder;

  const getNavigationDataByStatus = (status: PrequalificationStatus): { prequalificationSequence: PrequalificationPages; sectionName: string } => {
    switch (status) {
      case PrequalificationStatus.Started:
        return { prequalificationSequence: PrequalificationPages.Prequalification, sectionName: PrequalificationSectionName.Prequalification, };
      case PrequalificationStatus.Qualified:
        return { prequalificationSequence: PrequalificationPages.Qualifies, sectionName: PrequalificationSectionName.Qualifies, };
      case PrequalificationStatus.NotQualified:
        return { prequalificationSequence: PrequalificationPages.NotQualified, sectionName: PrequalificationSectionName.NotQualified, };
      case PrequalificationStatus.QualifiedExpired:
        return { prequalificationSequence: PrequalificationPages.QualifiedExpired, sectionName: '', };
      default:
        return { prequalificationSequence: null, sectionName: '', };
    }
  };

  const submitPrequalification = useCallback(async (prequalification: QDPrequalification_) => {
    setShowProgress(true);

    const onEachSuccess = (newStep: number, res: QdPrequalCompletePars_) => {
      setStep(newStep);
      if (res?.CompleteFlag) {
        // changing QDPrequalificationStatus of prequalification in state, should trigger navigation to next page
        const modified = { ...prequalificationHolder, QDPrequalificationStatus: res?.QDPrequalificationStatus, };
        dispatch(setPrequalificationHolder(modified));
      }
      if (res?.ShouldStopApplication) {
        dispatch(updateStopApplicationConditionAction(true));
      }
    };

    const onFail = () => {
      setShowProgress(false);
    };

    const generatorParams: IGeneratorParameters_<QdPrequalCompletePars_> = {
      dispatch,
      onEachSuccess,
      onFail,
      step,
    };

    await new GeneratorHost(generatorParams).run(submitPrequalificationProcessGenerator.bind(null, prequalification));
    setShowProgress(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const urlBasedOnStatus: { newUrl: string; sectionName: string } = useMemo(() => {
    if (modelCode && GUID && !isNullOrUndefined(QDPrequalificationStatus)) {
      const { prequalificationSequence, sectionName, } = getNavigationDataByStatus(QDPrequalificationStatus);

      const newUrl = getPrequalificationUrl(modelCode, prequalificationSequence, GUID);
      return { newUrl, sectionName, };
    }
    return null;
  }, [modelCode, GUID, QDPrequalificationStatus]);

  useEffect(() => {
    if (urlBasedOnStatus?.newUrl && location.pathname !== urlBasedOnStatus?.newUrl) {
      history.push(urlBasedOnStatus?.newUrl);
    }
  }, [history, location.pathname, urlBasedOnStatus]);

  useEffect(() => {
    const actionLogSectionData: any = {
      ...new ActionLog_(),
      ModelName: modelCode,
      GUIDQDPrequalification: GUID,
      PartyName: FirstName && LastName ? `${FirstName} ${LastName}` : '',
      Details: urlBasedOnStatus?.sectionName ? `Section: ${urlBasedOnStatus?.sectionName} Visited` : '',
      Category: 'Navigation',
      Action: 'Navigate',
      ActionStatus: 'Success',
    };
    if (urlBasedOnStatus?.newUrl) {
      logApplication(actionLogSectionData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GUID, modelCode, urlBasedOnStatus]);

  const renderPrequalificationPage = useCallback(() => {
    switch (sequence) {
      case PrequalificationPages.Prequalification:
        return <Prequalification onSubmit={submitPrequalification} />;
      case PrequalificationPages.Qualifies:
        return <PrequalificationResultsQualifies />;
      case PrequalificationPages.NotQualified:
        return <PrequalificationResultsNotQualified />;
      case PrequalificationPages.QualifiedExpired:
        return <NotImplementedPage text="Not Implemented" />;
      default:
        return <NotImplementedPage text="Not Implemented" />;
    }
  }, [sequence, submitPrequalification]);

  const shouldShowPageContent = urlBasedOnStatus?.newUrl === location.pathname;

  let currentSectionName = '';
  let isPrequalificationPage = false;
  let isQualifiedPage = false;
  let isNotQualifiedPage = false;
  switch (sequence) {
    case PrequalificationPages.Prequalification:
      currentSectionName = PrequalificationSectionName.Prequalification;
      isPrequalificationPage = true;
      break;
    case PrequalificationPages.Qualifies:
      currentSectionName = PrequalificationSectionName.Qualifies;
      isQualifiedPage = true;
      break;
    case PrequalificationPages.NotQualified:
      isNotQualifiedPage = true;
      currentSectionName = PrequalificationSectionName.NotQualified;
      break;
    default:
      break;
  }

  const {
    ModelHolder: { Sections, },
  } = prequalificationModelState;
  const currentPage = Sections.find(s => s.SectionName === currentSectionName);
  const renderDisclosureFooter = (isPrequalificationPage || isQualifiedPage) && currentPage?.ShowFooterDisclosures;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <BaseComponent {...props} exception={exception} location={location} loading={isLoading}>
      {shouldShowPageContent && (
        <>
          {renderPrequalificationPage()}

          <Section dataUI={PREQUALIFICATION_SECTION_DATA_UI} className="mt-3">
            <ActionsWrapper dataUI={`${PREQUALIFICATION_SECTION_DATA_UI}-actions-wrapper`} className="mb-1">
              {(isQualifiedPage || isNotQualifiedPage) && (
                <Button
                  dataUI={`${PREQUALIFICATION_SECTION_DATA_UI}-cancel-button`}
                  btnType="secondary"
                  className="action-spacer"
                  iconLeft={IconsSolid.faTimesCircle}
                  onClick={redirectToCP}
                >
                  {isQualifiedPage ? 'Cancel' : 'Close'}
                </Button>
              )}

              {(isPrequalificationPage || isQualifiedPage) && (
                <Button
                  dataUI={`${PREQUALIFICATION_SECTION_DATA_UI}-continue-button`}
                  form={`form-${sequence}`}
                  type="submit"
                  iconRight={IconsSolid.faChevronRight}
                >
                  {isPrequalificationPage ? 'Continue' : 'Continue with Application'}
                </Button>
              )}
            </ActionsWrapper>

            {renderDisclosureFooter && (
              <Collapse
                title={currentPage?.FooterDisclosuresLabel}
                onClick={() => setPrequalDisclosureCollapseExpanded(prevState => !prevState)}
                dataUI={`${PREQUALIFICATION_SECTION_DATA_UI}-footer-disclosures`}
                simple
                smallText
                expanded={prequalDisclosurCollapseExpanded}
              >
                {htmlParser(currentPage?.FooterDisclosuresText)}
              </Collapse>
            )}
          </Section>

          <ProgressBar isVisible={showProgress} steps={COUNT_OF_SUBMIT_PROCESS_REQUESTS} currentStep={step} dataUI="external-section-progress-bar" />
        </>
      )}
    </BaseComponent>
  );
};

export default PrequalificationBase;
