import { UserState as UserState_ } from 'redux-oidc';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LayoutTemplate from './LayoutTemplate';
import { changeDocumentTitle, attachBrandingIcon, getInternetExplorerVersion } from '../../utils/Helper';
import { AppState as AppState_ } from '../../redux/AppState';
import {
  getModelHolderActionCreator,
  IGotModelHolder as IGotModelHolder_,
  IGotRenewalModelHolder as IGotRenewalModelHolder_
} from '../../redux/actions/Model';
import { getRenewalModelHolderActionCreator } from '../../redux/actions/Renewal';
import Base from './BaseComponent';
import getHolderActionCreator, {
  HolderState as HolderState_,
  IGotHolder as IGotHolder_,
  InputHolderParameters as InputHolderParameters_
} from '../../redux/actions/Holder';
import {
  setIdInvitation,
  setInvitationModel,
  setGuid,
  setGuarantorGuid,
  setModel,
  setApplicationType,
  getApplicationType
} from '../../utils/LocalStorageManager';
import { IdentityState as IdentityState_ } from '../../redux/actions/Identity';
import { CoBorrowerAddNowState as CoBorrowerAddNowState_ } from '../../redux/actions/CoBorrowerAddNow';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../redux/actions/CoBorrowerIdentity';
import {
  BaseType as BaseType_,
  ModelState as ModelState_,
  ExternalModelHolder as ExternalModelHolder_,
  RenewalModelHolder as RenewalModelHolder_,
  RoutingProps as RoutingProps_
} from '../../utils/Types';
import LoadingPanel from '../../components/Loading/Loading';
import UnauthorizedModal from '../../components/Unauthorized/UnauthorizedModal';
import { setUnauthorizedActionCreator, UnauthorizedAction as UnauthorizedAction_ } from '../../redux/actions/Unauthorized';
import {
  PrequalificationModelHolder as PrequalificationModelHolder_,
  PrequalificationModelState as PrequalificationModelState_,
  SetPrequalificationModelStateAction as SetPrequalificationModelStateAction_
} from '../../redux/actions/PrequalificationModel';
import {
  getPrequalificationDataActionCreator,
  PrequalificationDataParameters as PrequalificationDataParameters_
} from '../../redux/actions/PrequalificationData';
import { ApplicationTypes } from '../../utils/Enums';
import {
  getExternalSectionTabName, getRenewalSectionTabName, shouldChangeBrowserTitle, shouldChangeFavicon
} from '../../utils/Navigation';
import { getPrequalificatonSectionTabName } from '../../utils/prequalification/Navigation';
import QdApplicationSection_ from '../../data/models/QDApplicationSection';

interface IProps {
  modelState?: ModelState_<ExternalModelHolder_>;
  renewalModelState?: ModelState_<RenewalModelHolder_>;
  getModelHolder?: (pars: InputHolderParameters_, Unauthorized: () => void, onFail: () => void) => Promise<IGotModelHolder_>;
  getRenewalModelHolder?: (id: number, Unauthorized: () => void, onFail: () => void) => Promise<IGotRenewalModelHolder_>;
  holderState?: HolderState_;
  identityState?: IdentityState_;
  coBorrowerAddNowState?: CoBorrowerAddNowState_;
  coBorrowerIdentityState?: CoBorrowerIdentityState_;
  getHolder?: (params: InputHolderParameters_) => Promise<IGotHolder_>;
  setUnauthorized?: () => Promise<UnauthorizedAction_>;
  oidc?: UserState_;
  children: React.ReactNode;
  unauthorized: boolean;
  getPrequalificationData: (
    params: PrequalificationDataParameters_,
    unauthorized: () => void,
    onFail: () => void
  ) => Promise<SetPrequalificationModelStateAction_>;
  prequalificationModelState: PrequalificationModelState_;
}

type CustomApplicationData = {
  modelHolder: ExternalModelHolder_ | RenewalModelHolder_ | PrequalificationModelHolder_;
  sections: QdApplicationSection_[];
};

type LayoutBaseProps = IProps & BaseType_ & any; // TODO trying to avoid any at all cost

const isExternalModelHolder = (
  modelHolder: ExternalModelHolder_ | RenewalModelHolder_ | PrequalificationModelHolder_
): modelHolder is ExternalModelHolder_ => {
  return (modelHolder as ExternalModelHolder_)?.ApplicationSections !== undefined;
};

const isRenewalModelHolder = (
  modelHolder: ExternalModelHolder_ | RenewalModelHolder_ | PrequalificationModelHolder_
): modelHolder is RenewalModelHolder_ => {
  return (modelHolder as RenewalModelHolder_)?.Renewal !== undefined;
};

class Layout extends Base<LayoutBaseProps, {}> {
  constructor(props: LayoutBaseProps) {
    super(props);

    if (getInternetExplorerVersion() !== -1) {
      this.goToIENotSupported();
      return;
    }

    const { params, } = this.props.match;
    const {
      idInvitation, model, guid, partyGUID,
    } = params;
    const {
      history: { location, },
    } = this.props;

    setIdInvitation(idInvitation);
    setInvitationModel(model);
    setModel(model);

    setGuid(guid);
    setGuarantorGuid(partyGUID);

    const applicationType = this.getInitialApplicationType(location.pathname, params);
    setApplicationType(applicationType);

    if (applicationType === ApplicationTypes.Prequalification) {
      this.loadInitialPrequalificationData();
    } else if (applicationType === ApplicationTypes.External) {
      this.loadInitialExternalData();
    } else if (applicationType === ApplicationTypes.Renewal) {
      this.loadInitialRenewalData();
    }

    this.signinRedirect = this.signinRedirect.bind(this);
  }

  componentDidUpdate(prevProps: LayoutBaseProps) {
    const currentProps = this.props;
    const {
      location: { pathname: currentPathname, },
    } = currentProps;
    const {
      location: { pathname: previousPathname, },
    } = prevProps;

    const { modelHolder: currentModelHolder, sections: currentSections, } = this.getApplicationData(currentProps);
    const { modelHolder: previousModelHolder, sections: previousSections, } = this.getApplicationData(prevProps);

    const shouldChange = shouldChangeBrowserTitle({
      currentModelHolder,
      previousModelHolder,
      currentSections,
      previousSections,
      currentPathname,
      previousPathname,
    });

    if (shouldChange) {
      const {
        Model: { BrowserTabTitle: browserTabTitle, },
      } = currentModelHolder;
      const sectionTabName = this.getSectionTabName(currentModelHolder, currentSections);

      changeDocumentTitle(browserTabTitle, sectionTabName);
    }

    const changeFavicon = shouldChangeFavicon(currentModelHolder, previousModelHolder);
    if (changeFavicon) {
      const {
        Model: { Code, FaviconUrl, },
      } = currentModelHolder;
      attachBrandingIcon(Code, FaviconUrl);
    }
  }

  getInitialApplicationType = (locationPathname: string, routingProps: RoutingProps_) => {
    const { idInvitation, model, } = routingProps;

    if (locationPathname.includes('prequalification') && model) {
      return ApplicationTypes.Prequalification;
    }

    if (locationPathname.includes('renewal') && idInvitation) {
      return ApplicationTypes.Renewal;
    }

    return ApplicationTypes.External;
  };

  getApplicationData = (props: LayoutBaseProps): CustomApplicationData => {
    const {
      modelState: currentExternalModelState,
      prequalificationModelState: currentPrequalificationModelState,
      renewalModelState: currentRenewalModelState,
      sectionsState: { VisibleSections: externalVisibleSections, },
      match: {
        params: { coBorrSequence, },
      },
    } = props;

    const applicationType = getApplicationType();

    if (applicationType === ApplicationTypes.External) {
      return {
        modelHolder: currentExternalModelState?.ModelHolder,
        sections: coBorrSequence ? currentExternalModelState?.ModelHolder?.CoBorrowerSections : externalVisibleSections,
      };
    }

    if (applicationType === ApplicationTypes.Prequalification) {
      return {
        modelHolder: currentPrequalificationModelState?.ModelHolder,
        sections: currentPrequalificationModelState?.ModelHolder?.Sections,
      };
    }

    return {
      modelHolder: currentRenewalModelState?.ModelHolder,
      sections: currentRenewalModelState?.ModelHolder?.ApplicationSections,
    };
  };

  getSectionTabName = (modelHolder: ExternalModelHolder_ | RenewalModelHolder_ | PrequalificationModelHolder_, sections: QdApplicationSection_[]) => {
    const {
      match: { params: routingProps, },
      identityState,
      coBorrowerIdentityState,
    } = this.props;

    const { prequalificationSequence, renewalSequence, } = routingProps;

    const applicationType = getApplicationType();

    if (applicationType === ApplicationTypes.External && isExternalModelHolder(modelHolder)) {
      return getExternalSectionTabName(routingProps, modelHolder, coBorrowerIdentityState, sections, identityState);
    }

    if (applicationType === ApplicationTypes.Prequalification) {
      return getPrequalificatonSectionTabName(prequalificationSequence, sections);
    }

    if (applicationType === ApplicationTypes.Renewal && isRenewalModelHolder(modelHolder)) {
      return getRenewalSectionTabName(renewalSequence, modelHolder);
    }

    return '';
  };

  loadInitialRenewalData = async () => {
    const { idInvitation, } = this.props.match.params;
    const { getRenewalModelHolder, setUnauthorized, } = this.props;
    await getRenewalModelHolder(parseInt(idInvitation, 0), () => setUnauthorized(), this.signinRedirect);
  };

  loadInitialExternalData = async () => {
    const {
      getModelHolder,
      setUnauthorized,
      modelState,
      oidc,
      match: {
        params: { guid, model, partyGUID, },
      },
    } = this.props;

    const email = oidc?.user?.profile?.email;
    if (!modelState.IsModelHolderFetched) {
      const pars = {
        email,
        guid,
        model,
        partyGUID,
      };
      await getModelHolder(pars, () => setUnauthorized(), this.signinRedirect);
    }
  };

  loadInitialPrequalificationData = async () => {
    const { model, guid, } = this.props.match.params;
    const {
      setUnauthorized, getPrequalificationData, oidc, prequalificationModelState,
    } = this.props;
    const email = oidc?.user?.profile?.email;

    const parameters: PrequalificationDataParameters_ = {
      email,
      guid,
      model,
    };

    if (!prequalificationModelState.IsModelHolderFetched) {
      await getPrequalificationData(parameters, setUnauthorized, this.signinRedirect);
    }
  };

  shouldShowLayoutTemplate = (applicationType: typeof ApplicationTypes[keyof typeof ApplicationTypes]) => {
    const {
      renewalModelState, modelState, prequalificationModelState, prequalificationHolder,
    } = this.props;

    if (applicationType === ApplicationTypes.Prequalification) {
      return prequalificationModelState.IsModelHolderFetched && prequalificationHolder;
    }

    if (applicationType === ApplicationTypes.Renewal) {
      return renewalModelState.IsModelHolderFetched;
    }

    return modelState.IsModelHolderFetched;
  };

  render = () => {
    const {
      unauthorized,
      //  history, TODO: Check auto-logout, requires history param
    } = this.props;

    const applicationType = getApplicationType();
    const shouldShow = this.shouldShowLayoutTemplate(applicationType);

    if (unauthorized) {
      return (
        <LayoutTemplate>
          <UnauthorizedModal isVisible={unauthorized} />
        </LayoutTemplate>
      );
    }

    return shouldShow ? (
      <LayoutTemplate>
        <>{this.props.children}</>
      </LayoutTemplate>
    ) : (
      <LoadingPanel dataUI="app-component-loader" />
    );
  };
}

const mapStateToProps = (state: AppState_) => {
  return {
    modelState: state.modelState,
    renewalModelState: state.renewalModelState,
    oidc: state.oidc,
    loading: state.loading,
    holderState: state.holderState,
    identityState: state.identityState,
    coBorrowerAddNowState: state.coBorrowerAddNowState,
    coBorrowerIdentityState: state.coBorrowerIdentityState,
    unauthorized: state.unauthorized,
    sectionsState: state.sectionsState,
    prequalificationModelState: state.prequalificationModelState,
    prequalificationHolder: state.prequalificationHolder,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getHolder: (params: InputHolderParameters_) => dispatch(getHolderActionCreator(params)),
    // eslint-disable-next-line max-len
    getModelHolder: (params: InputHolderParameters_, Unauthorized: () => void, onFail: () => void) => dispatch(getModelHolderActionCreator(params, Unauthorized, onFail)),
    // eslint-disable-next-line max-len
    getRenewalModelHolder: (id: number, Unauthorized: () => void, onFail: () => void) => dispatch(getRenewalModelHolderActionCreator(id, Unauthorized, onFail)),
    // eslint-disable-next-line max-len
    getPrequalificationData: (params: PrequalificationDataParameters_, unauthorized: () => void, onFail: () => void) => dispatch(getPrequalificationDataActionCreator(params, unauthorized, onFail)),
    setUnauthorized: () => dispatch(setUnauthorizedActionCreator(true)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
