/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC as FC_, useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _, { partition } from 'lodash';

import {
  Section,
  FormGroup,
  MaskedInput,
  Radio,
  Input,
  IconsSolid,
  Button,
  HorizontalInfoCard,
  IconsLight,
  Modal,
  ActionsWrapper,
  Alert,
  ReactAux
} from '@jkhy/vsg-design-system';

import PickList from '../../../components/PickList/PickList';
import PageField, { QuickDecisionPageTypes } from '../../../data/models/PageField';
import CoBorrowerSettings from './CoBorrowerSettings';
import Section_ from '../../../data/models/Section';
import Borrower_ from '../../../data/models/Borrower';
import CoBorrowerHolder_ from '../../../data/models/CoBorrower';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldHolder_ from '../../../data/models/PageFields/PageFieldHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import { getCurrentAddress } from '../../../utils/Address';
import {
  filterSections,
  getHoverHelpPageFieldValue,
  invalidFieldLookUp,
  isNullOrUndefined,
  scrollToError,
  setPageFieldObj
} from '../../../utils/Helper';
import {
  BorrowerType,
  CoBorrowerAddType,
  ExternalPages,
  PartyType,
  CustomerType as CustomerTypeEnum,
  SameAddressType,
  ValueConstants,
  ExternalPageSectionName
} from '../../../utils/Enums';
import validateOnBlur from '../../../utils/Validator';
import { UpdateAction as UpdateAction_ } from '../../../redux/actions/SubmitButton';
import Messages from '../../../utils/Messages';
import { setCoBorrowerAddNowState } from '../../../redux/actions/CoBorrowerAddNow';
import CoBorrowerPars_ from '../../../data/models/CoBorrowerPars';
import { setLoader } from '../../../redux/actions/Loading';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../utils/Types';
import QdServiceApi from '../../../data/api/QDServiceApi';
import ModelApi from '../../../data/api/ModelApi';
import { IGotModelHolder as IGotModelHolder_ } from '../../../redux/actions/Model';
import { resetCoBorrowerIdentity } from '../../../redux/actions/CoBorrowerIdentity';
import { updateVisibleSections } from '../../../redux/actions/VisibleSections';
import QDAddress from '../../../data/models/QDAddress';
import CoBorrowerAddNowHolder_ from '../../../data/models/CoBorrowerAddNowHolder';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';

export interface CoBorrowerProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, Borrower_>[],
    holder: QdApplicationHolder_,
    guarantors: CoBorrowerHolder_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const enum CoBorrowerPageSteps {
  // eslint-disable-next-line no-unused-vars
  HorizontalCardOverview = 1,
  // eslint-disable-next-line no-unused-vars
  CoBorrowerMainPage = 2,
  // eslint-disable-next-line no-unused-vars
  CoBorrowerInformationPage = 3,
}

export const fetchCoBorrowerPageFields = async (modelState, dispatch) => {
  const { IdGuarantorPageFieldTemplate, } = modelState.ModelHolder.Model;
  const { Result, } = await ModelApi.getApplicationPageFields(IdGuarantorPageFieldTemplate, QuickDecisionPageTypes.QuickDecisionGuarantor);
  if ((Result || []).length > 0) {
    const modelHolder = {
      ...modelState.ModelHolder,
      CoBorrowerPageField: setPageFieldObj(Result),
    };
    const gotModelHolderAction: IGotModelHolder_ = {
      modelState: {
        ModelHolder: modelHolder,
        IsModelHolderFetched: true,
      },
      type: 'GotModelHolder',
    };
    dispatch(gotModelHolderAction);
  }
};

const formId = `form-${ExternalPages.CoBorrower}`;

const CoBorrower: FC_<CoBorrowerProps> = (props: CoBorrowerProps) => {
  const {
    holder, modelState, pageFieldHolder, sections, loading,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      pageFieldHolder: PageFieldHolder_;
      sections: Section_[];
      loading: boolean;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    modelState: state.modelState,
    pageFieldHolder: state?.modelState?.ModelHolder?.ApplicationPageField,
    sections: state?.sectionsState.VisibleSections,
    loading: state.loading,
  }));

  const section = sections?.find(s => s.SectionName === ExternalPageSectionName.Guarantor);

  const coBorrowerHolder = { ...holder?.Guarantor, };
  const [validatedAddNowCoBorrowers, setValidatedAddNowCoBorrowers] = useState({});
  const [isAllAddNowCoBorrowersValid, setIsAllAddNowCoBorrowersValid] = useState(true);
  const [invalidSetOfPageFields, setInvalidSetOfPageFields] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [step, setStep] = useState(CoBorrowerPageSteps.HorizontalCardOverview);
  const [addMode, setAddMode] = useState(false);
  const [tempCoBorrower, setTempCoBorrower] = useState(new Borrower_());
  const [removeIndex, setRemoveIndex] = useState(-1);

  const dispatch = useDispatch();
  const history = useHistory();
  const guarantorsRef = useRef([...holder.Guarantor.Guarantors]);

  const {
    ModelHolder: { CoBorrowerPageField, Model: {
      CustomerType
    }},
  } = modelState;
  const isConsumer = CustomerType === CustomerTypeEnum.Consumer;
  const pageFields: any = [pageFieldHolder?.Guarantor?.General];
  if (CoBorrowerPageField) {
    const {
      PersonalInformation: {
        General: { FirstName, LastName, EmailAddress, },
      },
    } = CoBorrowerPageField;
    pageFields.push({
      FirstName,
      LastName,
      EmailAddress,
    });
    if (!isConsumer) {
      const { BusinessInformation : {
        General: { BusinessName, }
      }} = CoBorrowerPageField;
      pageFields.push({
        BusinessName,
      });
    }
  }
  const coBorrowerSettings = new CoBorrowerSettings<QdApplicationHolder_, Borrower_>(pageFields, null, null);

  const getDefaultGuarantor = (): Borrower_ => {
    const guarantor = {
      ...new Borrower_(),
      PartyType: PartyType.Personal,
      CoBorrowerAddType: null,
      // TODO: Instead of enum use QDRelationship pick list to determine the Guarantor value
      BorrowerType: BorrowerType.Guarantor,
      BorrowerTypeStr: 'Guarantor',
      PercentOwnership: null,
      DBA: '',
      Email: '',
      FirstName: '',
      LastName: '',
      FullName: '',
      GUID: '',
      // eslint-disable-next-line no-undef
      Id: BigInt(0),
      Saved: false,
    };

    return guarantor;
  };

  const showSubmitButtonFlag = (show: boolean, disabled?: boolean) => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: false, show, disabled, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };

  const validateReservedRequiredFields = () => {
    const localValidatedAddNowCoBorrowers = {};

    coBorrowerHolder.Guarantors.filter((guarantor: Borrower_) => guarantor.CoBorrowerAddType === CoBorrowerAddType.AddNow).forEach(
      (guarantor: Borrower_) => {
        const fieldsToValidate = guarantor.PartyType === PartyType.Personal
          ? [guarantor.FirstName, guarantor.LastName, guarantor.TIN, guarantor.Email, guarantor.DOB]
          : [guarantor.FullName, guarantor.TIN];

        const isValid = fieldsToValidate.every(value => !isNullOrUndefined(value) && value !== '');

        localValidatedAddNowCoBorrowers[`${guarantor.Id}`] = isValid;
      }
    );

    setValidatedAddNowCoBorrowers(localValidatedAddNowCoBorrowers);

    const isAllAddNowGuarantorsValid = Object.values(localValidatedAddNowCoBorrowers).every(isValid => isValid);
    setIsAllAddNowCoBorrowersValid(isAllAddNowGuarantorsValid);
    showSubmitButtonFlag(true, !isAllAddNowGuarantorsValid);
  };

  const setCoBorrowers = (newCoBorrowerHolder: CoBorrowerHolder_) => {
    const newHolder: QdApplicationHolder_ = {
      ...holder,
      Guarantor: newCoBorrowerHolder,
    };

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: newHolder,
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  useEffect(() => {
    if (step === CoBorrowerPageSteps.HorizontalCardOverview) {
      validateReservedRequiredFields();
    }
  }, [step, coBorrowerHolder?.Guarantors?.length]);

  useEffect(() => {
    if (step === CoBorrowerPageSteps.HorizontalCardOverview) {
      showSubmitButtonFlag(true, !isAllAddNowCoBorrowersValid);
    } else {
      showSubmitButtonFlag(false, false);
    }
  }, [step, isAllAddNowCoBorrowersValid]);

  useEffect(() => {
    if (step === CoBorrowerPageSteps.HorizontalCardOverview) {
      setInvalidSetOfPageFields([]);
    } else if (step === CoBorrowerPageSteps.CoBorrowerInformationPage) {
      // Add Now Edit
      // eslint-disable-next-line no-use-before-define
      coBorrowerAddNowEdit();
    }
  }, [step]);

  useEffect(() => {
    const loadPageFields = async () => {
      dispatch(setLoader(true));
      await fetchCoBorrowerPageFields(modelState, dispatch);
      dispatch(setLoader(false));
    };

    dispatch(resetCoBorrowerIdentity());

    if (!modelState.ModelHolder.CoBorrowerPageField) loadPageFields();

    return () => {
      // aka as deprecated ComponentWillUnmount
      showSubmitButtonFlag(true, false);
    };
  }, []);

  useEffect(() => {
    guarantorsRef.current = [...holder.Guarantor.Guarantors];
  }, [holder.Guarantor.Guarantors]);

  useEffect(() => {
    return () => {
      // Due to the fact that this useEffect only runs on initial render, the variables it used from closure are all stale when the component unmounts
      // That's why we need to use Ref and to get data from guarantorsRef.

      // eslint-disable-next-line no-undef
      const unsavedRecord = guarantorsRef.current?.some(g => g.Id === BigInt(0) && g.Saved === false);
      if (unsavedRecord) {
        const newGuarantors = [...guarantorsRef.current];
        newGuarantors.splice(newGuarantors.length - 1, 1);
        setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });
      }
    };
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isAllAddNowCoBorrowersValid) {
      props.onSubmit([], holder, coBorrowerHolder, ExternalPages.CoBorrower, false);
    }
  };

  const addNewGuarantor = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const guarantor = getDefaultGuarantor();
    const newGuarantors = [...coBorrowerHolder.Guarantors, guarantor];

    setActiveIndex(newGuarantors.length - 1);
    setAddMode(true);
    setStep(CoBorrowerPageSteps.CoBorrowerMainPage);
    setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });
  };

  const removeGuarantor = async (): Promise<void> => {
    const guarantor = coBorrowerHolder.Guarantors[removeIndex];
    const gId = guarantor?.Id;
    if (!gId) return;

    await QdServiceApi.removeCoBorrower(gId, guarantor?.IdQDApplication, guarantor?.GUID);

    const newGuarantors = [...coBorrowerHolder.Guarantors];
    newGuarantors.splice(removeIndex, 1);

    const isAddNowGuarantor = guarantor.CoBorrowerAddType === CoBorrowerAddType.AddNow;
    const addNowAddresses = isAddNowGuarantor
      ? coBorrowerHolder.GuarantorsAddNowAddresses.filter(address => address.IdQDParty !== guarantor.Id)
      : [...coBorrowerHolder.GuarantorsAddNowAddresses];

    const addNowEmployments = isAddNowGuarantor
      ? coBorrowerHolder.GuarantorsAddNowEmployments.filter(address => address.IdQDParty !== guarantor.Id)
      : [...coBorrowerHolder.GuarantorsAddNowEmployments];

    setCoBorrowers({
      ...coBorrowerHolder,
      Guarantors: newGuarantors,
      GuarantorsAddNowAddresses: addNowAddresses,
      GuarantorsAddNowEmployments: addNowEmployments,
    });

    setRemoveIndex(-1);
  };

  const onCancel = () => {
    const newGuarantors = [...coBorrowerHolder.Guarantors];

    if (addMode) {
      newGuarantors.splice(newGuarantors.length - 1, 1);
      setAddMode(false);
    } else {
      newGuarantors[activeIndex] = tempCoBorrower;
    }

    setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });

    setStep(CoBorrowerPageSteps.HorizontalCardOverview);
  };

  const updateCoBorrowerAddress = async (coBorrowerAddNowHolder: CoBorrowerAddNowHolder_, address: QDAddress) => {
    // eslint-disable-next-line no-unused-vars
    const { Id, GUID, ...otherAddressData } = address;
    const { CoBorrower: coBorrower, } = coBorrowerAddNowHolder;

    const newCoBorrowerAddress = {
      ...new QDAddress(),
      ...otherAddressData,
      IsSavedByUser: false,
      IdQDParty: coBorrower.Id,
    };

    const { Success, Result: savedAddress, } = await QdServiceApi.saveQDAddress(newCoBorrowerAddress);
    if (Success) {
      const newCoBorrowerAddNowHolder = {
        ...coBorrowerAddNowHolder,
        Addresses: [savedAddress],
      };

      dispatch(setCoBorrowerAddNowState(newCoBorrowerAddNowHolder, true));
    }
  };

  const processCoBorrowerAddNow = async (guid: string, sameAddressType: number, nextPage) => {
    const {
      ModelHolder: { Model: model, },
    } = modelState;
    const pars: CoBorrowerPars_ = {
      QDPartyGuid: guid,
      GUIDQDApplication: holder.GUIDQDApplication,
    };
    const { Result: coBorrowerAddNowHolder, } = await QdServiceApi.inquireCoBorrower(pars);
    if (coBorrowerAddNowHolder) {
      coBorrowerAddNowHolder.CoBorrower.SameAddressType = sameAddressType;
    }
    dispatch(setCoBorrowerAddNowState(coBorrowerAddNowHolder, true));

    // Prefill co-borrower personal address state
    // in case user choose Same as Main Applicant
    const { CoBorrower: coBorrower, Addresses: addresses, } = coBorrowerAddNowHolder;
    if (!coBorrower.PartyMatchDone && coBorrower.PartyType === PartyType.Personal) {
      const coBorrowerCurrentAddress = getCurrentAddress(addresses);
      if (coBorrower.SameAddressType === SameAddressType.Yes && !coBorrowerCurrentAddress) {
        const personalBorrowerCurrentAddress = getCurrentAddress(holder.AddressesP.PersonalAddresses);
        if (personalBorrowerCurrentAddress) {
          await updateCoBorrowerAddress(coBorrowerAddNowHolder, personalBorrowerCurrentAddress);
        }
      }
    }

    //   Navigate
    const url = `/guarantor-add-now/${model.Code}/${ExternalPages.CoBorrower}/${nextPage.Sequence}/${holder.GUIDQDApplication}/`;
    history.push(url);

    dispatch(setLoader(false));
  };

  const coBorrowerAddNowEdit = async () => {
    const { IdQDApplication, } = holder;
    const newGuarantors = [...coBorrowerHolder.Guarantors];
    const { ModelHolder: modelHolder, } = modelState;

    // Eval Coborrower Add Now sections
    const { Result: evalResult, } = await QdServiceApi.evaluate(IdQDApplication, null, null, newGuarantors[activeIndex].GUID);
    const addNowVisibleSections = filterSections('IdQDSectionDefinition', modelHolder.ApplicationSections, evalResult?.VisibleSections);

    const nextPage = addNowVisibleSections[addNowVisibleSections.length - 1];
    await processCoBorrowerAddNow(newGuarantors[activeIndex].GUID, newGuarantors[activeIndex].SameAddressType, nextPage);

    // Hide all pages that are after the Guarantors page
    const currentSectionSequence = parseInt(section.Sequence, 10);
    let visibleSections = sections;
    if (sections.some((s: Section_) => parseInt(s.Sequence, 10) > currentSectionSequence)) {
      visibleSections = sections.filter((s: Section_) => parseInt(s.Sequence, 10) <= currentSectionSequence);
    }

    dispatch(updateVisibleSections(visibleSections, false, addNowVisibleSections));
  };

  const onSave = async () => {
    const newGuarantors = [...coBorrowerHolder.Guarantors];
    let invalidSetOfPF = [];

    newGuarantors.forEach((guarantor, index) => {
      const [invalidSetOfPFPerGuarantor] = partition(coBorrowerSettings.PageFields, pf => !pf.IsValid(holder, guarantor, pf));
      const cloneObj = _.cloneDeep(invalidSetOfPFPerGuarantor);
      const modifiedInvalidFields = cloneObj.map(pf => {
        const pageField = pf;
        pageField.IsInvalidResult = true;
        pageField.FieldName = pf.FieldName + index;
        return pf;
      });

      invalidSetOfPF = invalidSetOfPF.concat(modifiedInvalidFields);
    });

    setInvalidSetOfPageFields(invalidSetOfPF);

    if (invalidSetOfPF.length === 0) {
      const { IdQDApplication, } = holder;
      newGuarantors[activeIndex].IdQDApplication = IdQDApplication;

      const coBorrowerSave = {
        GUIDQDApplication: holder?.GUIDQDApplication,
        CoBorrower: newGuarantors[activeIndex],
        Employment: null,
        Addresses: null,
        CoBorrowerInfoPageSaved: false,
        RemovedAddresses: [],
      };

      const { Result, } = await QdServiceApi.saveCoBorrower(coBorrowerSave);
      newGuarantors[activeIndex].Id = Result?.CoBorrower?.Id;
      newGuarantors[activeIndex].GUID = Result?.CoBorrower?.GUID;
      newGuarantors[activeIndex].Saved = true;

      if (newGuarantors[activeIndex].CoBorrowerAddType === CoBorrowerAddType.AddNow) {
        await coBorrowerAddNowEdit();
      } else {
        setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });
        setAddMode(false);
        setStep(CoBorrowerPageSteps.HorizontalCardOverview);
      }

      return;
    }

    setTimeout(() => {
      scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
    }, 300);
  };

  const onChange = (e: any, fieldName: string) => {
    const {
      target: { value: val, selectedOptions, },
    } = e;
    const [selected] = selectedOptions || [];

    let pageField = new PageField();
    if (fieldName === 'PartyType' || fieldName === 'CoBorrowerAddType' || fieldName === 'SameAddressType') pageField.ObjectProperty = fieldName;
    else pageField = coBorrowerSettings.PageFields.find(p => p.FieldName === fieldName);

    if (pageField.ObjectProperty) {
      const newGuarantors = [...coBorrowerHolder.Guarantors];
      const value = fieldName === 'PartyType' || fieldName === 'CoBorrowerAddType' || fieldName === 'SameAddressType' ? parseInt(val, 10) : val;

      newGuarantors[activeIndex][pageField.ObjectProperty] = value;
      if (!!selected?.text && pageField.ObjectProperty === 'BorrowerType') {
        newGuarantors[activeIndex][`${pageField.ObjectProperty}EnumStr`] = selected?.text;
      }

      setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });
    }
  };

  const onChangeSelect = (e: any, fieldName: string) => {
    const { value, label, } = e;
    const pageField = coBorrowerSettings.PageFields.find(p => p.FieldName === fieldName);
    const field = pageField?.ObjectProperty;
    const newGuarantors = [...coBorrowerHolder.Guarantors];

    newGuarantors[activeIndex][field] = value;
    newGuarantors[activeIndex][pageField.ObjectPropertyStr] = label === ValueConstants.DropDownDefaultValue ? '' : label;
    setCoBorrowers({ ...coBorrowerHolder, Guarantors: newGuarantors, });
  };

  const onBlur = (e, pageField: PageField) => {
    const {
      target: { value, },
    } = e;
    const { PageFields, } = coBorrowerSettings;
    const { FieldName: fieldName, GroupName: groupName, } = pageField;

    let invalidSetOfPF = [...invalidSetOfPageFields];
    const { IsValid, InvalidMessage, } = validateOnBlur({ inputType: 'email', value, });
    const currentPf = [...PageFields].find(p => p.FieldName === fieldName && p.GroupName === groupName);
    if (currentPf) {
      const modifiedFieldName = fieldName + activeIndex;
      currentPf.FieldName = modifiedFieldName;
      currentPf.IsInvalidResult = !IsValid;
      currentPf.ValidationMessage = InvalidMessage;

      invalidSetOfPF = invalidSetOfPF.filter(p => p.FieldName !== modifiedFieldName);
      if (currentPf.IsInvalidResult) invalidSetOfPF = invalidSetOfPF.concat([currentPf]);
    }

    setInvalidSetOfPageFields(invalidSetOfPF);
  };

  const setIcon = (coBorrower: Borrower_) => {
    const type = parseInt(`${coBorrower.PartyType}`, 10);
    switch (type) {
      case PartyType.Business:
        return IconsLight.faIndustryAlt;
      case PartyType.Personal:
        return IconsLight.faUser;
      default:
        return IconsLight.faUser;
    }
  };

  const determinePageFields = guarantor => {
    const {
      General: {
        FirstName, LastName, PercentOwnershipGuarantor, Relationship, EmailAddress, GBusinessName, GDBA,
      },
    } = pageFieldHolder.Guarantor;

    const {
      PersonalInformation: {
        General: { FirstName: firstNameAddNow, LastName: lastNameAddNow, EmailAddress: personalEmail, },
      },
    } = CoBorrowerPageField;

    const BusinessName = CoBorrowerPageField?.BusinessInformation?.General?.BusinessName
    const { PartyType: partyType, CoBorrowerAddType: coBorrowerAddType, } = guarantor;

    const businessNamePageField = coBorrowerAddType === CoBorrowerAddType.AddNow ? BusinessName : GBusinessName;
    const firstNamePageField = partyType === PartyType.Personal && coBorrowerAddType === CoBorrowerAddType.AddNow ? firstNameAddNow : FirstName;
    const lastNamePageField = partyType === PartyType.Personal && coBorrowerAddType === CoBorrowerAddType.AddNow ? lastNameAddNow : LastName;
    const emailPageField = partyType === PartyType.Personal && coBorrowerAddType === CoBorrowerAddType.AddNow ? personalEmail : EmailAddress;

    return {
      PercentOwnershipGuarantor,
      GDBA,
      Relationship,
      BusinessName: businessNamePageField,
      FirstName: firstNamePageField,
      LastName: lastNamePageField,
      EmailAddress: emailPageField,
    };
  };

  const renderGuarantor = (guarantor: Borrower_, index) => {
    if (!guarantor) {
      return null;
    }

    const pageFieldsInUse = determinePageFields(guarantor);
    const {
      FirstName, LastName, PercentOwnershipGuarantor, Relationship, EmailAddress, BusinessName, GDBA,
    } = pageFieldsInUse;

    const emailValidationMessage = invalidSetOfPageFields?.find(pageField => pageField.ObjectProperty === 'Email')?.ValidationMessage;

    return (
      <div data-ui="co-borrower-form-container">
        <FormGroup className="mb-2" dataUI={`co-borrower-party-type-form-group-${index}`} label="Co-Applicant Type" isRequired checkboxOrRadio>
          <Radio
            name={`PartyType-${index}`}
            dataUI={`co-borrower-party-type-individual-radio-${index}`}
            htmlFor={`co-borrower-individual-answer-${index}`}
            id={`co-borrower-individual-answer-${index}`}
            className="d-inline-block"
            value={PartyType.Personal}
            checked={guarantor.PartyType === PartyType.Personal || isConsumer }
            onChange={e => onChange(e, 'PartyType')}
            disabled={!!guarantor.Id}
          >
            Individual
          </Radio>
          { !isConsumer && (
            <Radio
              name={`PartyType-${index}`}
              dataUI={`co-borrower-party-type-business-radio-${index}`}
              htmlFor={`co-borrower-business-answer-${index}`}
              id={`co-borrower-business-answer-${index}`}
              className="d-inline-block"
              value={PartyType.Business}
              checked={guarantor.PartyType === PartyType.Business}
              onChange={e => onChange(e, 'PartyType')}
              disabled={!!guarantor.Id}
            >
              Business
            </Radio>
          )}
        </FormGroup>
        <FormGroup
          className="mb-2"
          dataUI={`co-borrower-add-information-form-group-${index}`}
          label="Do you want to add an additional applicant now or invite them to fill out their portion of the application?"
          isRequired
          checkboxOrRadio
        >
          <Radio
            name={`CoBorrowerAddType-${index}`}
            dataUI={`co-borrower-add-type-email-radio-${index}`}
            htmlFor={`co-borrower-email-verification-answer-${index}`}
            id={`co-borrower-email-verification-answer-${index}`}
            className="d-inline-block"
            value={CoBorrowerAddType.EmailInvitation}
            checked={guarantor.CoBorrowerAddType === CoBorrowerAddType.EmailInvitation}
            onChange={e => onChange(e, 'CoBorrowerAddType')}
            disabled={!!guarantor.Id}
          >
            Email Invitation
          </Radio>
          <Radio
            name={`CoBorrowerAddType-${index}`}
            dataUI={`co-borrower-add-type-now-radio-${index}`}
            htmlFor={`co-borrower-add-now-answer-${index}`}
            id={`co-borrower-add-now-answer-${index}`}
            className="d-inline-block"
            value={CoBorrowerAddType.AddNow}
            checked={guarantor.CoBorrowerAddType === CoBorrowerAddType.AddNow}
            onChange={e => onChange(e, 'CoBorrowerAddType')}
            disabled={!!guarantor.Id}
          >
            Add Now
          </Radio>
        </FormGroup>
        {guarantor.CoBorrowerAddType !== null && (
          <ReactAux>
            {guarantor.PartyType === PartyType.Business && BusinessName &&(
              <>
                <FormGroup
                  className="mb-2"
                  label={BusinessName?.Label}
                  isRequired={BusinessName?.Required}
                  isBold={BusinessName?.IsBold}
                  isHidden={BusinessName?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(BusinessName)}
                  dataUI={`co-borrower-business-name-form-group-${index}`}
                  htmlFor={`co-borrower-business-name-${index}`}
                  isValid={!invalidFieldLookUp(`${BusinessName?.FieldName + index}`, invalidSetOfPageFields)}
                  validationMessage={Messages.REQUIRED_FIELD}
                >
                  <Input
                    type="text"
                    dataUI={`co-borrower-business-name-input-${index}`}
                    id={`co-borrower-business-name-${index}`}
                    value={guarantor.FullName}
                    onChange={e => onChange(e, BusinessName?.FieldName)}
                    maxLength={100}
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  label={GDBA?.Label}
                  isRequired={GDBA?.Required}
                  isBold={GDBA?.IsBold}
                  isHidden={GDBA?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(GDBA)}
                  dataUI={`co-borrower-DBA-form-group-${index}`}
                  htmlFor={`co-borrower-DBA-${index}`}
                  isValid={!invalidFieldLookUp(`${GDBA?.FieldName + index}`, invalidSetOfPageFields)}
                  validationMessage={Messages.REQUIRED_FIELD}
                >
                  <Input
                    type="text"
                    dataUI={`co-borrower-DBA-input-${index}`}
                    id={`co-borrower-DBA-${index}`}
                    value={guarantor.DBA}
                    onChange={e => onChange(e, GDBA?.FieldName)}
                    maxLength={190}
                  />
                </FormGroup>
              </>
            )}
            <FormGroup
              className="mb-2"
              label={FirstName?.Label}
              isRequired={FirstName?.Required}
              isBold={FirstName?.IsBold}
              isHidden={FirstName?.IsHidden}
              hoverHelp={getHoverHelpPageFieldValue(FirstName)}
              dataUI={`co-borrower-first-name-form-group-${index}`}
              htmlFor={`co-borrower-first-name-${index}`}
              isValid={!invalidFieldLookUp(`${FirstName?.FieldName + index}`, invalidSetOfPageFields)}
              validationMessage={Messages.REQUIRED_FIELD}
            >
              <Input
                type="text"
                dataUI={`co-borrower-first-name-input-${index}`}
                id={`co-borrower-first-name-${index}`}
                value={guarantor.FirstName}
                onChange={e => onChange(e, FirstName?.FieldName)}
                maxLength={33}
              />
            </FormGroup>
            <FormGroup
              className="mb-2"
              isRequired={LastName?.Required}
              label={LastName?.Label}
              isBold={LastName?.IsBold}
              isHidden={LastName?.IsHidden}
              hoverHelp={getHoverHelpPageFieldValue(LastName)}
              dataUI={`co-borrower-last-name-form-group-${index}`}
              htmlFor={`co-borrower-last-name-${index}`}
              isValid={!invalidFieldLookUp(`${LastName?.FieldName + index}`, invalidSetOfPageFields)}
              validationMessage={Messages.REQUIRED_FIELD}
            >
              <Input
                type="text"
                dataUI={`co-borrower-last-name-input-${index}`}
                id={`co-borrower-last-name-${index}`}
                value={guarantor.LastName}
                onChange={e => onChange(e, LastName?.FieldName)}
                maxLength={33}
              />
            </FormGroup>
            <FormGroup
              className="mb-2"
              label={Relationship?.Label}
              isRequired={Relationship?.Required}
              isBold={Relationship?.IsBold}
              isHidden={Relationship?.IsHidden}
              hoverHelp={getHoverHelpPageFieldValue(Relationship)}
              dataUI={`co-borrower-relationship-name-form-group-${index}`}
              htmlFor={`co-borrower-relationship-name-${index}`}
              isValid={!invalidFieldLookUp(`${Relationship?.FieldName + index}`, invalidSetOfPageFields)}
              validationMessage={Messages.REQUIRED_FIELD}
            >
              <PickList
                dsSelectWrapper
                id={`co-borrower-relationship-name-${index}`}
                stateLess={false}
                initialData={[]}
                value={guarantor?.BorrowerType?.toString()}
                disabled={false}
                onChange={e => onChangeSelect(e, Relationship?.FieldName)}
                ListName="QDRelationship"
                ListType="LtItems"
                pageField={Relationship}
                dataUI={`co-borrower-field-name-pick-list-${index}`}
              />
            </FormGroup>
            <FormGroup
              className="mb-2"
              label={PercentOwnershipGuarantor?.Label}
              isRequired={PercentOwnershipGuarantor?.Required}
              isBold={PercentOwnershipGuarantor?.IsBold}
              isHidden={PercentOwnershipGuarantor?.IsHidden || isConsumer}
              hoverHelp={getHoverHelpPageFieldValue(PercentOwnershipGuarantor)}
              dataUI={`co-borrower-percent-ownership-form-group-${index}`}
              htmlFor={`co-borrower-percent-ownership-${index}`}
              isValid={!invalidFieldLookUp(`${PercentOwnershipGuarantor?.FieldName + index}`, invalidSetOfPageFields)}
              validationMessage={Messages.REQUIRED_FIELD}
            >
              {/* ToDo
            New properties have been added with the latest version (minPercent and maxPercent)
            Only for "percent" type - If you want to use these new properties,
            please update the field value when using onBlur event (You can use functionality from onChange method) */}
              <MaskedInput
                type="percent"
                icon={IconsSolid.faPercent}
                dataUI={`co-borrower-percent-ownership-masked-input-${index}`}
                id={`co-borrower-percent-ownership-${index}`}
                value={guarantor.PercentOwnership}
                min={0}
                max={100}
                onBlur={e => onChange(e, PercentOwnershipGuarantor?.FieldName)}
                onChange={e => onChange(e, PercentOwnershipGuarantor?.FieldName)}
              />
            </FormGroup>
            <FormGroup
              className="mb-2"
              label={EmailAddress?.Label}
              isRequired={EmailAddress?.Required}
              isBold={EmailAddress?.IsBold}
              isHidden={EmailAddress?.IsHidden}
              hoverHelp={getHoverHelpPageFieldValue(EmailAddress)}
              dataUI={`co-borrower-email-form-group-${index}`}
              htmlFor={`co-borrower-email-${index}`}
              isValid={!invalidFieldLookUp(`${EmailAddress?.FieldName + index}`, invalidSetOfPageFields)}
              validationMessage={emailValidationMessage}
            >
              <Input
                type="text"
                dataUI={`co-borrower-email-input-${index}`}
                id={`co-borrower-email-${index}`}
                value={guarantor.Email}
                onChange={e => onChange(e, EmailAddress?.FieldName)}
                onBlur={e => onBlur(e, EmailAddress)}
                maxLength={100}
              />
            </FormGroup>
          </ReactAux>
        )}
        {guarantor?.CoBorrowerAddType === CoBorrowerAddType.AddNow && guarantor?.PartyType === PartyType.Personal && !guarantor?.PartyMatchDone && (
          <FormGroup
            className="mb-2"
            dataUI={`co-borrower-same-address-form-group-${index}`}
            label="Does this co-applicant live at the same address as the primary applicant?"
            checkboxOrRadio
          >
            <Radio
              name={`SameAddress-${index}`}
              dataUI={`co-borrower-same-address-yes-radio-${index}`}
              htmlFor={`co-borrower-same-address-yes-answer-${index}`}
              id={`co-borrower-same-address-yes-answer-${index}`}
              className="d-inline-block"
              value={SameAddressType.Yes}
              checked={guarantor.SameAddressType === SameAddressType.Yes}
              onChange={e => onChange(e, 'SameAddressType')}
            >
              Yes
            </Radio>

            <Radio
              name={`SameAddress-${index}`}
              dataUI={`co-borrower-same-address-no-radio-${index}`}
              htmlFor={`co-borrower-same-address-no-answer-${index}`}
              id={`co-borrower-same-address-no-answer-${index}`}
              className="d-inline-block"
              value={SameAddressType.No}
              checked={guarantor.SameAddressType === SameAddressType.No}
              onChange={e => onChange(e, 'SameAddressType')}
            >
              No
            </Radio>
          </FormGroup>
        )}
        {guarantor?.CoBorrowerAddType !== null && (
          <ActionsWrapper dataUI="add-co-borrower-actions-wrapper">
            <Button
              dataUI="cancel-co-borrower-button"
              btnType="secondary"
              className="action-spacer"
              iconLeft={IconsSolid.faTimesCircle}
              onClick={onCancel}
            >
              Cancel
            </Button>

            <Button dataUI="save-co-borrower-button" iconLeft={IconsSolid.faSave} onClick={onSave}>
              Next
            </Button>
          </ActionsWrapper>
        )}
      </div>
    );
  };

  if (loading) {
    return null;
  }

  return (
    <form id={formId} data-ui={formId} onSubmit={onSubmit}>
      <Section
        title={section?.SubSections[0]?.SubSectionName}
        headerText={section?.SubSections[0]?.SubSectionHeaderText}
        footerText={section?.SubSections[0]?.SubSectionFooterText}
        className="mb-2"
        dataUI="co-borrower-guarantors-section"
      >
        {step === CoBorrowerPageSteps.HorizontalCardOverview && (
          <>
            {coBorrowerHolder.Guarantors.map((g: Borrower_, index: number) => (
              <HorizontalInfoCard
                key={`${g?.Id}-${g?.GUID}-${g?.Email}`}
                title={g.PartyType === PartyType.Personal ? `${g.FirstName} ${g.LastName}` : g.FullName}
                icon={setIcon(g)}
                className="mb-2"
                dataUI={`co-borrower-horizontal-info-card-${index}`}
                onEdit={() => {
                  setTempCoBorrower({ ...g, });
                  setActiveIndex(index);

                  const nextState = g.CoBorrowerAddType === CoBorrowerAddType.AddNow
                    ? CoBorrowerPageSteps.CoBorrowerInformationPage
                    : CoBorrowerPageSteps.CoBorrowerMainPage;
                  setStep(nextState);
                }}
                onDelete={() => {
                  setRemoveIndex(index);
                }}
                isValid={validatedAddNowCoBorrowers[`${g.Id}`]}
                validationMessage={Messages.MISSING_INFORMATION}
              />
            ))}

            <Button dataUI="add-co-borrower-button" iconLeft={IconsSolid.faPlus} onClick={addNewGuarantor}>
              Add
            </Button>

            {!isAllAddNowCoBorrowersValid && (
              <Alert
                dataUI="invalid-co-borrower-alert"
                className="mt-3"
                type="danger"
                text="Please fill out the missing information in the card in order to continue."
              />
            )}
          </>
        )}

        {step === CoBorrowerPageSteps.CoBorrowerMainPage
          && coBorrowerHolder.Guarantors.length > 0
          && renderGuarantor(coBorrowerHolder.Guarantors[activeIndex], activeIndex)}
      </Section>
      <Modal
        dataUI="remove-guarantor-confiramtion-modal"
        title="Confirm to delete"
        isVisible={removeIndex > -1}
        isClosable
        closeBtnName="Cancel"
        closeBtnIcon={IconsSolid.faTimesCircle}
        onClose={() => setRemoveIndex(-1)}
        actionBtnName="YES, DELETE"
        actionBtnIcon={IconsSolid.faTrash}
        onSubmit={removeGuarantor}
        size="S"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </form>
  );
};

export default CoBorrower;
