import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';
import ModelApi_ from '../../data/api/ModelApi';
import QDDocumentsResult_ from '../../data/models/QDDocumentsResult';

export type DocumentsState = {
  DocumentHolder: QDDocumentsResult_;
  IsDocumentsFetching: boolean;
  IsDocumentsFetched: boolean;
};

export interface IGotDocuments extends Action_<'GetDocuments'> {
  DocumentsState: DocumentsState;
}

export const setDocuments = (documentsHolder: QDDocumentsResult_): IGotDocuments => ({
  type: 'GetDocuments',
  DocumentsState: {
    DocumentHolder: documentsHolder,
    IsDocumentsFetching: false,
    IsDocumentsFetched: true,
  },
});

const getDocumentsActionCreator: ActionCreator_<ThunkAction_<Promise<IGotDocuments>, DocumentsState, string, IGotDocuments>> = (guid: string) => {
  return async (dispatch: Dispatch_) => {
    const fetchingDocuments: IGotDocuments = {
      DocumentsState: {
        DocumentHolder: new QDDocumentsResult_(),
        IsDocumentsFetching: true,
        IsDocumentsFetched: false,
      },
      type: 'GetDocuments',
    };
    dispatch(fetchingDocuments);

    dispatch(setLoader(true));

    const { Result: documentsHolder, } = await ModelApi_.externalInquireDocumentData(guid);

    dispatch(setLoader(false));

    return dispatch(setDocuments(documentsHolder));
  };
};

export default getDocumentsActionCreator;
