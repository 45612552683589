import { IconsSolid, Toast } from '@jkhy/vsg-design-system';
import PageSettings from '../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../data/models/PageField';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import BorrowerHolder_ from '../../../../data/models/BorrowerHolder';
import Borrower_ from '../../../../data/models/Borrower';
import { ValidationRegexConstants } from '../../../../utils/Enums';
import InputMasksConstants from '../../../../utils/InputMasksConstants';
import { validateDifferentTIN } from '../../../../utils/Validator';

export default class BusinessBorrowerSettings extends PageSettings<QdApplicationHolder_, BorrowerHolder_, any> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, BorrowerHolder_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'BusinessName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'FullName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 40,
          },
          dataUI: 'business-borrower-name-field',
        });
      case 'DBA':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'DBA',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 50,
          },
          dataUI: 'business-borrower-DBA-field',
        });
      case 'CompanyTraits':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'CompanyTraits',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 1450,
          },
          dataUI: 'business-borrower-company-traits-field',
        });
      case 'StateFormed':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'StateFormedCode',
          ObjectPropertyStr: 'StateFormedCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
          dataUI: 'business-borrower-state-formed-field',
        });
      case 'PhoneNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'PreferredPhone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'phone',
            icon: IconsSolid.faPhone,
          },
          dataUI: 'business-borrower-telephone-field',
        });
      case 'OrganizationType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'OrganizationTypeCode',
          ObjectPropertyStr: 'OrganizationTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'OrganizationType',
              ListType: 'LtItems',
            },
          },
          dataUI: 'business-borrower-organization-type-field',
        });
      case 'TaxEntityType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'TaxEntityTypeCode',
          ObjectPropertyStr: 'TaxEntityTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'EntityTypes',
              ListType: 'LtItems',
            },
          },
          dataUI: 'business-borrower-tax-entity-type-field',
        });
      case 'TaxID':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'TIN',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.SSN_TAX_ID,
            regexStr: ValidationRegexConstants.TIN_SSN,
            errorMessage: `${pageField?.Label || 'Tax I.D. #'} must be in format: 000000000`,
            className: 'text-left',
          },
          dataUI: 'business-borrower-tax-ID-field',
          IsValid: (holder: QdApplicationHolder_, businessBorrower: BorrowerHolder_, pf: PageFieldExtended_<QdApplicationHolder_, Borrower_>) => {
            const pageFieldHelper = pf;
            const {
              IsValid, InvalidMessage, Type
            } = validateDifferentTIN(holder, businessBorrower.Business, pf, holder);

            if (!Type) {
              pageFieldHelper.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'WebSite':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'WebSite',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            regexStr: ValidationRegexConstants.WebSite,
            errorMessage: 'Must be in format: www.example.com',
            maxLength: 200,
          },
          dataUI: 'business-borrower-web-site-field',
        });
      case 'BusinessEmailAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'Email',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            icon: IconsSolid.faEnvelope,
            inputType: 'email',
            maxLength: 100,
          },
          dataUI: 'business-borrower-email-address-field',
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
