import { Reducer as Reducer_ } from 'redux';
import { CoBorrowerAddNowState as CoBorrowerAddNowState_, CoBorrowerAddNowActions as CoBorrowerAddNowActions_ } from '../actions/CoBorrowerAddNow';

const initialState: CoBorrowerAddNowState_ = {
  CoBorrowerAddNowHolder: {
    GUIDQDApplication: '',
    CoBorrower: null,
    CoBorrowerInfoPageSaved: false,
    Employment: null,
    Addresses: [],
  },
  IsCoBorrowerFetched: false,
  CoBorrowerAddNowActive: false,
};

const coBorrowerAddNowHolderReducer: Reducer_<CoBorrowerAddNowState_, CoBorrowerAddNowActions_> = (state = initialState, action) => {
  switch (action.type) {
    case 'GetCoBorrowerAddNow': {
      return { ...action.coBorrowerAddNowState, };
    }
    case 'SetCoBorrowerAddNow': {
      return { ...action.coBorrowerAddNowState, };
    }
    default:
      return state;
  }
};

export default coBorrowerAddNowHolderReducer;
