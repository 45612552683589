import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Page from '../../../Page/Page';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { CoBorrowerBusinessInformationSettings } from '../../AddNow/Information/BusinessSettings';
import { GuarantorPageSectionName_, GuarantorPages } from '../../../../../utils/Enums';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationBusiness as QDCoBorrowerApplicationBusiness_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import Borrower_ from '../../../../../data/models/Borrower';

type CoBorrowerBusinessInformationProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationBusiness_,
    sequence: string
  ) => void;
};

const CoBorrowerBusinessInformation = (props: CoBorrowerBusinessInformationProps_) => {
  const {
    modelState,
    sectionsState: { VisibleSections: visibleSections, },
    loading,
    holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const [businessCoBorrowerInformation, setBusinessCoBorrowerInformation] = useState({ ...holder?.Business.CoBorrower, });

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const section = visibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.BusinessInformation);

  const updateBusinessInformation = (pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;

    const changedBusinessCoBorrowerInformationData = { ...businessCoBorrowerInformation, };

    changedBusinessCoBorrowerInformationData[ObjectProperty] = value;
    if (ObjectPropertyStr) changedBusinessCoBorrowerInformationData[ObjectPropertyStr] = text;

    setBusinessCoBorrowerInformation(changedBusinessCoBorrowerInformationData);
  };

  const pageSettings = useMemo(() => {
    return new CoBorrowerBusinessInformationSettings([pageFieldHolder?.BusinessInformation?.General], section?.SubSections);
  }, [pageFieldHolder, section]);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[]) => {
    const businessInformation = {
      ...holder.Business,
      CoBorrower: businessCoBorrowerInformation,
    };

    props.onSubmit(invalidPageFields, holder, businessInformation, GuarantorPages.BusinessInformation);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateBusinessInformation}
          holder={holder}
          subHolder={businessCoBorrowerInformation}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessInformation}`}
        />
      )}
    </>
  );
};

export default CoBorrowerBusinessInformation;
