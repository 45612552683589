// eslint-disable-next-line max-classes-per-file
import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import QDAddress_ from '../../../../../data/models/QDAddress';
import { ValidationRegexConstants, AddressType, REGEX } from '../../../../../utils/Enums';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import Messages from '../../../../../utils/Messages';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { booleanStringComparison, isGoogleUnavailable } from '../../../../../utils/Helper';
import { validateAddressEndDate, validateDateRanges } from '../../../../../utils/Validator';

type CoBorrowerPersonalAddressAdditionalSettings = {
  startDateLabel: string;
  hasIDVerificationShowed: boolean;
  isCurrentAddress: boolean;
};

const pageFieldsMapper = <THolder, TSubHolder>(
  pageField: PageField_,
  getDefault: () => PageFieldExtended_<THolder, TSubHolder>,
  additionalSettings: CoBorrowerPersonalAddressAdditionalSettings
): PageFieldExtended_<THolder, TSubHolder> => {
  if (!pageField) {
    return null;
  }

  const { startDateLabel, hasIDVerificationShowed, isCurrentAddress, } = additionalSettings;

  const isMailingAddressHidden = (subHolder: QDAddress_) => !booleanStringComparison(subHolder?.DifferentMailingAddress);

  switch (pageField.FieldName) {
    case 'CurrentAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-current-address',
        ObjectProperty: 'GoogleAPIAddress',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: () => !isCurrentAddress || isGoogleUnavailable(),
        Component: {
          type: 'address',
          regexStr: REGEX.PO_BOX,
          errorMessage: Messages.INVALID_STREET_ADDRESS,
        },
        IsReadOnly: hasIDVerificationShowed,
      });
    case 'StreetAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-street-address',
        Label: 'Street Address', // Fields without DB representation should have Label
        ObjectProperty: 'Address',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
        },
        IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
      });
    case 'City':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-city-address',
        Label: 'City',
        ObjectProperty: 'City',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
        },
        IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
      });
    case 'State':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-state-address',
        Label: 'State', // Fields without DB representation should have Label
        ObjectProperty: 'State',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'States',
            ListType: 'LtItems',
          },
        },
        IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
      });
    case 'Zip':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-zip-address',
        Label: 'Zip', // Fields without DB representation should have Label
        ObjectProperty: 'Zip',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.ZIP_CODE,
          regexStr: ValidationRegexConstants.ZipCode,
          errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
          inputType: 'zipCode',
          icon: IconsSolid.faMapPin,
        },
        IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
      });
    case 'PreviousAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-previous-address',
        ObjectProperty: 'GoogleAPIAddress',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: () => isCurrentAddress || isGoogleUnavailable(),
        Component: {
          type: 'address',
          regexStr: REGEX.PO_BOX,
          errorMessage: Messages.INVALID_STREET_ADDRESS,
        },
      });
    case 'AddressStartDate':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-address-start-date',
        ObjectProperty: 'AddressStartDate',
        ObjectPropertyType: 'string',
        Component: {
          type: 'datepicker',
          rangeEnd: new Date(),
          notClearableOnInvalid: true,
        },
        IsValid: (holder: THolder, subHolder: QDAddress_, pageF: PageFieldExtended_<THolder, QDAddress_>) => {
          const { IsValid, InvalidMessage, } = validateDateRanges(holder, subHolder, pageF);
          const pf = pageF;

          if (InvalidMessage) {
            pf.ValidationMessage = InvalidMessage;
          }

          return IsValid;
        },
      });
    case 'AddressEndDate':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-address-end-date',
        ObjectProperty: 'AddressEndDate',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (coBorrowerHolder, subHolder) => subHolder.AddressType === AddressType.Current,
        Component: {
          type: 'datepicker',
          rangeEnd: new Date(),
          notClearableOnInvalid: true,
        },
        IsValid: (holder: THolder, subHolder: QDAddress_, pageF: PageFieldExtended_<THolder, QDAddress_>) => {
          const { IsValid: isValidDateRange, InvalidMessage: invalidDateRangeMessage, } = validateDateRanges(holder, subHolder, pageF);
          const pf = pageF;

          if (invalidDateRangeMessage) {
            pf.ValidationMessage = invalidDateRangeMessage;
            return isValidDateRange;
          }

          const { IsValid, InvalidMessage, } = validateAddressEndDate(pageF, subHolder, startDateLabel);

          if (InvalidMessage) {
            pf.ValidationMessage = InvalidMessage;
          }

          return IsValid;
        },
      });
    case 'CurrentHousingStatus':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-individual-current-housing-status',
        ObjectProperty: 'CurrentHousingStatusCode',
        ObjectPropertyStr: 'CurrentHousingStatusCodeStr',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: () => !isCurrentAddress,
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'CurrentHousingStatus',
            ListType: 'LtItems',
          },
        },
      });
    case 'DifferentMailingAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-different-mailing-address',
        Label: 'Is your mailing address different from your current address?',
        ObjectProperty: 'DifferentMailingAddress',
        ObjectPropertyType: 'boolean',
        IsHiddenCalculated: () => !isCurrentAddress,
        Component: {
          type: 'radio',
          options: [
            { value: 'true', label: 'Yes', },
            { value: 'false', label: 'No', }
          ],
        },
      });
    case 'MailingAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-mailing-address',
        Label: 'Mailing Address', // Fields without DB representation should have Label
        ObjectProperty: 'MailingAddress',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (coBorrowerHolder, subHolder) => isMailingAddressHidden(subHolder),
        Component: {
          type: 'input',
        },
      });
    case 'MailingCity':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-individual-mailing-city',
        Label: 'City', // Fields without DB representation should have Label
        ObjectProperty: 'MailingCity',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (coBorrowerHolder, subHolder) => isMailingAddressHidden(subHolder),
        Component: {
          type: 'input',
        },
      });
    case 'MailingState':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-individual-mailing-state',
        Label: 'State', // Fields without DB representation should have Label
        ObjectProperty: 'MailingState',
        IsHiddenCalculated: (coBorrowerHolder, subHolder) => isMailingAddressHidden(subHolder),
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'States',
            ListType: 'LtItems',
          },
        },
      });
    case 'MailingZip':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-individual-mailing-zip',
        Label: 'Zip Code', // Fields without DB representation should have Label
        ObjectProperty: 'MailingZip',
        ObjectPropertyType: 'string',
        IsHiddenCalculated: (coBorrowerHolder, subHolder) => isMailingAddressHidden(subHolder),
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.ZIP_CODE,
          regexStr: ValidationRegexConstants.ZipCode,
          errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
          inputType: 'zipCode',
          icon: IconsSolid.faMapPin,
        },
      });
    default:
      return getDefault();
  }
};

export default class PersonalAddressSettings extends PageSettings<CoBorrowerAddNowHolder_, QDAddress_, CoBorrowerPersonalAddressAdditionalSettings> {
  pageFieldsMapper(
    pageField: PageField_,
    additionalSettings: CoBorrowerPersonalAddressAdditionalSettings
  ): PageFieldExtended_<CoBorrowerAddNowHolder_, QDAddress_> {
    return pageFieldsMapper(
      pageField,
      () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField),
      additionalSettings
    );
  }
}

export class PersonalAddressSettings_ extends PageSettings<QDCoBorrowerApplicationHolder_, QDAddress_, CoBorrowerPersonalAddressAdditionalSettings> {
  protected pageFieldsMapper(
    pageField: PageField_,
    additionalSettings: CoBorrowerPersonalAddressAdditionalSettings
  ): PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDAddress_> {
    return pageFieldsMapper(
      pageField,
      () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField),
      additionalSettings
    );
  }
}
