/* eslint-disable no-undef */
import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionsWrapper, Button, HorizontalInfoCard, IconsSolid, IconsLight, Section, Modal
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../redux/AppState';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { EvalSectionState as EvalSectionState_ } from '../../../redux/actions/VisibleSections';
import Page from '../Page/Page';
import PersonalAddressSettings_ from './PersonalAddressSettings';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_, AddressDetails as AddressDetails_ } from '../../../utils/Types';
import { AddressType, ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { UpdateAction as UpdateAction_ } from '../../../redux/actions/SubmitButton';
import { booleanStringComparison } from '../../../utils/Helper';
import { checkHasIDVerificationShowed } from '../../../utils/IdentityVerification';
import { calculateDifferenceText, getGoogleApiAddressAsString } from '../../../utils/Address';
import PageField from '../../../data/models/PageField';
import PersonalAddress_ from '../../../data/models/PersonalAddress';
import QDAddress_ from '../../../data/models/QDAddress';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { updateExternalHolder } from '../../../redux/actions/Holder';
import { IdentityState as IdentityState_ } from '../../../redux/actions/Identity';

const enum AddressPersonalPageSteps {
  // eslint-disable-next-line no-unused-vars
  CurrentAddress = 1,
  // eslint-disable-next-line no-unused-vars
  HorizontalCardOverview = 2,
  // eslint-disable-next-line no-unused-vars
  PreviousAddress = 3,
}

export interface PersonalAddressProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[],
    holder: QdApplicationHolder_,
    section: any,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const PersonalAddress: FC_<PersonalAddressProps> = (props: PersonalAddressProps) => {
  const {
    holder, loading, modelState, sectionsState, identityState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      identityState: IdentityState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
    sectionsState: state?.sectionsState,
    identityState: state.identityState,
  }));

  const dispatch = useDispatch();

  const { VisibleSections, } = sectionsState;
  const section = VisibleSections?.find(s => s.SectionName === ExternalPageSectionName.PersonalAddresses);
  const [subSection] = section?.SubSections;

  const {
    BorrowerP: {
      Personal: { Id: IdQDParty, IdQDApplication, },
    },
  } = holder;

  const hasIDVerificationShowed = useMemo(() => {
    return checkHasIDVerificationShowed(holder.BorrowerP.Personal, identityState);
  }, [holder.BorrowerP.Personal, identityState]);

  const personalAddresses = holder?.AddressesP?.PersonalAddresses;

  const { initialAddresses, initialStep, initialActiveIndex, } = useMemo(() => {
    const modifiedPersonalAddresses = personalAddresses?.map((address: QDAddress_) => {
      return { ...address, GoogleAPIAddress: getGoogleApiAddressAsString(address), };
    });
    // eslint-disable-next-line max-len
    const newAddresses = modifiedPersonalAddresses?.length === 0 ? [{ ...new QDAddress_(), AddressType: AddressType.Current, }] : modifiedPersonalAddresses;

    const hasCurrentAddressSavedByUser = newAddresses?.some(a => a.IsSavedByUser && a.AddressType === AddressType.Current);

    const initIndex = hasCurrentAddressSavedByUser ? -1 : 0;
    const initStep = hasCurrentAddressSavedByUser ? AddressPersonalPageSteps.HorizontalCardOverview : AddressPersonalPageSteps.CurrentAddress;

    return {
      initialAddresses: newAddresses,
      initialStep: initStep,
      initialActiveIndex: initIndex,
    };
  }, [personalAddresses]);

  const [addresses, setAddresses] = useState<QDAddress_[]>(initialAddresses);
  const [addMode, setAddMode] = useState<boolean>(false);
  const [tempAddress, setTempAddress] = useState<QDAddress_>(new QDAddress_());
  const [step, setStep] = useState<number>(initialStep);
  const [activeAddressIndex, setActiveAddressIndex] = useState<number>(initialActiveIndex);
  const [removeId, setRemoveId] = useState<bigint>(BigInt(-1));

  const showSubmitButtonFlag = (show: boolean) => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: false, show, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };

  useEffect(() => {
    return () => {
      // aka as deprecated ComponentWillUnmount
      showSubmitButtonFlag(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStore = (modifiedAddresses: QDAddress_[]) => {
    const modifiedAddressP = { ...holder.AddressesP, PersonalAddresses: modifiedAddresses, };
    dispatch(updateExternalHolder({ ...holder, AddressesP: modifiedAddressP, }));
  };

  useEffect(() => {
    const showSubmit = step === AddressPersonalPageSteps.HorizontalCardOverview;
    showSubmitButtonFlag(showSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onRemove = async () => {
    if (removeId) {
      await QdServiceApi.removeQDAddress(removeId);
    }

    const editedData = addresses?.filter(address => address.Id !== removeId);
    setAddresses([...editedData]);
    updateStore([...editedData]);
    setRemoveId(BigInt(-1));
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const modifiedAddressP = { ...holder.AddressesP, PersonalAddresses: addresses, };
    await props.onSubmit([], holder, modifiedAddressP, ExternalPages.PersonalAddresses, false);
    showSubmitButtonFlag(true);
  };

  const navigateToStep = (navigateStep: AddressPersonalPageSteps) => {
    const showSubmit = navigateStep === AddressPersonalPageSteps.HorizontalCardOverview;
    showSubmitButtonFlag(showSubmit);
    setStep(navigateStep);
  };

  const onSave = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, PersonalAddress_>[]) => {
    if (invalidPageFields.length === 0) {
      const modifiedAddress = { ...addresses[activeAddressIndex], };
      const isNew = !modifiedAddress.Id;

      // Reset mailing fields
      if (!booleanStringComparison(modifiedAddress.DifferentMailingAddress)) {
        modifiedAddress.MailingAddress = '';
        modifiedAddress.MailingCity = '';
        modifiedAddress.MailingState = '';
        modifiedAddress.MailingZip = '';
      }

      if (!modifiedAddress?.IdQDParty) {
        modifiedAddress.IdQDParty = IdQDParty;
      }
      if (!modifiedAddress?.IdQDApplication) {
        modifiedAddress.IdQDApplication = IdQDApplication;
      }
      // In case client has NOT used Google proposed addresses
      // Use value entered by user in field
      if (modifiedAddress.GoogleAPIAddress && !modifiedAddress.Address) {
        modifiedAddress.Address = modifiedAddress.GoogleAPIAddress;
      }
      modifiedAddress.IsSavedByUser = true;

      const { Result: saved, } = await QdServiceApi.saveQDAddress(modifiedAddress);
      if (isNew) {
        modifiedAddress.Id = saved.Id;
        modifiedAddress.GUID = saved.GUID;
      }
      addresses[activeAddressIndex] = modifiedAddress;
      setAddresses([...addresses]);
      updateStore([...addresses]);
      setAddMode(false);
      navigateToStep(AddressPersonalPageSteps.HorizontalCardOverview);
      setActiveAddressIndex(-1);
    }
  };

  const onAdd = () => {
    const addressesSize = addresses?.length;

    const newPreviousAddress: QDAddress_ = {
      ...new QDAddress_(),
      AddressType: AddressType.Previous,
    };

    setAddresses([...addresses, newPreviousAddress]);
    setActiveAddressIndex(addressesSize);
    setAddMode(true);
    navigateToStep(AddressPersonalPageSteps.PreviousAddress);
  };

  const onEdit = (index: number) => {
    const address = { ...addresses[index], };
    setTempAddress(address);
    setActiveAddressIndex(index);
    // eslint-disable-next-line max-len
    const editStep = address?.AddressType === AddressType.Current ? AddressPersonalPageSteps.CurrentAddress : AddressPersonalPageSteps.PreviousAddress;
    navigateToStep(editStep);
  };

  const onCancel = () => {
    const newPersonalAddresses = [...addresses];

    // Add mode
    if (addMode) {
      newPersonalAddresses.pop();
      // Edit Mode
    } else {
      newPersonalAddresses[activeAddressIndex] = tempAddress;
    }

    setAddresses([...newPersonalAddresses]);
    navigateToStep(AddressPersonalPageSteps.HorizontalCardOverview);
  };

  const onChangeAddress = (
    pageField: PageFieldExtended_<QdApplicationHolder_, PersonalAddress_>,
    value: string,
    e: React.ChangeEvent,
    text: string
  ) => {
    const { ObjectProperty, ObjectIndex, ObjectPropertyStr, } = pageField;

    const changedAddresses = [...addresses];

    changedAddresses[ObjectIndex][ObjectProperty] = value;
    if (ObjectPropertyStr) {
      changedAddresses[ObjectIndex][ObjectPropertyStr] = text;
    }

    setAddresses(changedAddresses);
  };

  const onAddressSelect = (pageField: PageFieldExtended_<QdApplicationHolder_, PersonalAddress_>, addressDetails: AddressDetails_) => {
    const { ObjectProperty, ObjectIndex, } = pageField;

    if (addressDetails) {
      const changedAddresses = [...addresses];
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;
      changedAddresses[ObjectIndex] = {
        ...changedAddresses[ObjectIndex],
        [ObjectProperty]: fullAddress,
        Address: streetAddress,
        City: city,
        State: state,
        Zip: zipCode,
      };

      setAddresses([...changedAddresses]);
    }
  };

  const pageFieldsToUse = useMemo(() => {
    const { CurrentAddress, PreviousAddress, } = modelState?.ModelHolder?.ApplicationPageField?.PersonalAddress;

    const addressType = addresses[activeAddressIndex]?.AddressType;

    // Current Address Type
    if (addressType === AddressType.Current) {
      const { SortOrder: googleAddressFieldSortOrder, ...googleAddressPageField } = CurrentAddress.CurrentAddress;
      const { SortOrder: mailingAddressFieldSortOrder, } = CurrentAddress?.MailingAddress;

      return {
        ...CurrentAddress,
        StreetAddress: { ...googleAddressPageField, FieldName: 'StreetAddress', SortOrder: googleAddressFieldSortOrder + 1, },
        City: { ...googleAddressPageField, FieldName: 'City', SortOrder: googleAddressFieldSortOrder + 2, },
        State: { ...googleAddressPageField, FieldName: 'State', SortOrder: googleAddressFieldSortOrder + 3, },
        ZipCode: { ...googleAddressPageField, FieldName: 'ZipCode', SortOrder: googleAddressFieldSortOrder + 4, },
        DifferentMailingAddress: { ...new PageField(), FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressFieldSortOrder - 1, },
        MailingZip: { ...CurrentAddress.ZipCode, FieldName: 'MailingZip', SortOrder: mailingAddressFieldSortOrder + 1, },
        MailingCity: { ...CurrentAddress.City, FieldName: 'MailingCity', SortOrder: mailingAddressFieldSortOrder + 2, },
        MailingState: { ...CurrentAddress.State, FieldName: 'MailingState', SortOrder: mailingAddressFieldSortOrder + 3, },
      };
    }

    // Previous Address Type
    const { SortOrder: googleAddressFieldSortOrder, ...googleAddressPageField } = PreviousAddress.PreviousAddress;
    return {
      ...PreviousAddress,
      StreetAddress: { ...googleAddressPageField, FieldName: 'StreetAddress', SortOrder: googleAddressFieldSortOrder + 1, },
      City: { ...googleAddressPageField, FieldName: 'City', SortOrder: googleAddressFieldSortOrder + 2, },
      State: { ...googleAddressPageField, FieldName: 'State', SortOrder: googleAddressFieldSortOrder + 3, },
      ZipCode: { ...googleAddressPageField, FieldName: 'ZipCode', SortOrder: googleAddressFieldSortOrder + 4, },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAddressIndex]);

  const pageSettings = useMemo(() => {
    const addressType = addresses[activeAddressIndex]?.AddressType;

    const startDateLabel = pageFieldsToUse?.AddressStartDate?.Label;
    const additionalSettings = {
      startDateLabel,
      activeAddressIndex: `${activeAddressIndex}`,
      hasIDVerificationShowed,
      isCurrentAddress: addressType === AddressType.Current,
    };

    return new PersonalAddressSettings_([pageFieldsToUse], [subSection], additionalSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageFieldsToUse, subSection, activeAddressIndex, hasIDVerificationShowed]);

  return (
    <>
      {!loading && (
        <>
          {step !== AddressPersonalPageSteps.HorizontalCardOverview && (
            <>
              <Page
                pageSettings={pageSettings}
                onChange={onChangeAddress}
                onAddressSelect={onAddressSelect}
                holder={holder}
                subHolder={{ ...holder?.AddressesP, PersonalAddresses: addresses, }}
                onSubmit={onSave}
                formIdentifier="form-borrower-address"
              />

              <ActionsWrapper dataUI="current-personal-address-actions-wrapper">
                {/* Тhe address may have an id, but it may not be saved, because in a party match process, the address comes from LV party.
                    In this case, it has an id, but has never been saved in QD */}
                {((addresses[activeAddressIndex]?.Id && addresses[activeAddressIndex]?.IsSavedByUser)
                  || step === AddressPersonalPageSteps.PreviousAddress) && (
                  <Button
                    dataUI="cancel-previous-address-button"
                    btnType="secondary"
                    className="action-spacer"
                    iconLeft={IconsSolid.faTimesCircle}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  dataUI="current-personal-address-save-current-address-button"
                  iconLeft={IconsSolid.faSave}
                  form="form-borrower-address"
                  type="submit"
                >
                  Save
                </Button>
              </ActionsWrapper>
            </>
          )}

          <form id={`form-${ExternalPages.PersonalAddresses}`} onSubmit={onSubmit}>
            {step === AddressPersonalPageSteps.HorizontalCardOverview && (
              <>
                <Section
                  title={subSection.SubSectionName}
                  headerText={subSection.SubSectionHeaderText}
                  footerText={subSection.SubSectionFooterText}
                  dataUI="borrower-personal-addresses"
                >
                  {addresses?.map((address: QDAddress_, index: number) => {
                    const isCurrentAddress = address?.AddressType === AddressType.Current;

                    return (
                      <HorizontalInfoCard
                        key={`${address?.Id}-${address?.AddressType}`}
                        title={address?.Address}
                        icon={isCurrentAddress ? IconsLight.faHome : IconsLight.faBuilding}
                        description={calculateDifferenceText(address.AddressStartDate, address.AddressEndDate)}
                        className="mb-2"
                        dataUI={`previous-address-horizontal-info-card-${index}`}
                        onEdit={() => onEdit(index)}
                        onDelete={isCurrentAddress ? null : () => setRemoveId(address?.Id)}
                      />
                    );
                  })}

                  <Button dataUI="add-address-button" className="mt-2" onClick={onAdd}>
                    Add Address
                  </Button>
                </Section>
              </>
            )}
            <Modal
              dataUI="remove-confiramtion-modal"
              title="Confirm to delete"
              isVisible={removeId > -1}
              isClosable
              closeBtnName="Cancel"
              closeBtnIcon={IconsSolid.faTimesCircle}
              onClose={() => setRemoveId(BigInt(-1))}
              actionBtnName="YES, DELETE"
              actionBtnIcon={IconsSolid.faTrash}
              onSubmit={onRemove}
              size="S"
            >
              <p>Are you sure you want to delete?</p>
            </Modal>
          </form>
        </>
      )}
    </>
  );
};

export default PersonalAddress;
