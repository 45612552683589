import React, { FC as FC_, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, Checkbox, ScrollableContainer, Alert
} from '@jkhy/vsg-design-system';
import _ from 'lodash';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../redux/AppState';
import { htmlParser } from '../../../utils/Helper';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ExternalPages, ExternalPageSectionName, ValueConstants } from '../../../utils/Enums';
import Messages from '../../../utils/Messages';

export const WRAP_UP_SECTION = 'terms-and-conditions-section';
export const WRAP_UP_TEXT_CONTENT = 'terms-and-conditions-text-content';
export const WRAP_UP_VALIDATION_MESSAGE_CONTAINER = 'wrap-up-alert-agreed-terms-and-conditions';
export const WRAP_UP_CHECKBOX = 'terms-and-conditions-checkbox';

export interface WrapUpProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[],
    holder: QdApplicationHolder_,
    section: {},
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const WrapUp: FC_<WrapUpProps> = (props: WrapUpProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));
  const dispatch = useDispatch();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const agreedFlag = holder?.AgreedFlag;
  const { ApplicationSections: sections, Model: model, } = modelState?.ModelHolder;

  const section = sections?.find(s => s.SectionName === ExternalPageSectionName.TermsAndCondition);
  const subSection = section?.SubSections?.find(ss => ss.Code === ValueConstants.TersmAndConditionSubSection);

  const onAgreedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.currentTarget.checked;
    const holderModified = { ...holder, AgreedFlag: result, };

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: holderModified,
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!agreedFlag) {
      setShowWarningMessage(true);
      return;
    }

    if (showWarningMessage) setShowWarningMessage(false);

    props.onSubmit([], holder, holder.RequiredDocs, ExternalPages.WrapUp, false);
  };

  return (
    <>
      {!loading && (
        <form id={`form-${ExternalPages.WrapUp}`} onSubmit={onSubmit}>
          <Section
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
            dataUI={WRAP_UP_SECTION}
          >
            <ScrollableContainer dataUI={WRAP_UP_TEXT_CONTENT} className="mb-2">
              {htmlParser(model.WrapUpHTML)}
            </ScrollableContainer>

            <Checkbox
              checked={agreedFlag || false}
              onChange={onAgreedChange}
              dataUI={WRAP_UP_CHECKBOX}
              id={WRAP_UP_CHECKBOX}
              htmlFor={WRAP_UP_CHECKBOX}
              className="d-inline-block"
            >
              {Messages.WRAP_UP_CHECKBOX_LABEL}
            </Checkbox>

            {showWarningMessage && <Alert dataUI={WRAP_UP_VALIDATION_MESSAGE_CONTAINER} type="danger" text={Messages.INVALID_WRAP_UP_TERMS} />}
          </Section>
        </form>
      )}
    </>
  );
};

export default WrapUp;
