import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';

import CoBorrowerAddNowHolder_ from '../../data/models/CoBorrowerAddNowHolder';
import CoBorrowerPars_ from '../../data/models/CoBorrowerPars';
import QDServiceApi_ from '../../data/api/QDServiceApi';

export type CoBorrowerAddNowState = {
  CoBorrowerAddNowHolder: CoBorrowerAddNowHolder_;
  IsCoBorrowerFetched: boolean;
  CoBorrowerAddNowActive: boolean;
};
export interface IGotCoBorrowerAddNowState extends Action_<'GetCoBorrowerAddNow'> {
  coBorrowerAddNowState: CoBorrowerAddNowState;
}
export interface ISetCoBorrowerAddNowState extends Action_<'SetCoBorrowerAddNow'> {
  coBorrowerAddNowState: CoBorrowerAddNowState;
}

export type CoBorrowerAddNowActions = IGotCoBorrowerAddNowState | ISetCoBorrowerAddNowState;

export const setCoBorrowerAddNowState = (coBorrowerAddNowStateHolder: CoBorrowerAddNowHolder_, isActive: boolean): ISetCoBorrowerAddNowState => ({
  type: 'SetCoBorrowerAddNow',
  coBorrowerAddNowState: {
    CoBorrowerAddNowHolder: coBorrowerAddNowStateHolder,
    IsCoBorrowerFetched: true,
    CoBorrowerAddNowActive: isActive,
  },
});

const getCoBorrowerActionCreator: ActionCreator_<ThunkAction_<
  Promise<ISetCoBorrowerAddNowState>,
  CoBorrowerAddNowState,
  CoBorrowerPars_,
  IGotCoBorrowerAddNowState
>> = (pars: CoBorrowerPars_) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const { Result: coBorrowerHolder, } = await QDServiceApi_.inquireCoBorrower(pars);

    dispatch(setLoader(false));

    return dispatch(setCoBorrowerAddNowState(coBorrowerHolder, true));
  };
};

export default getCoBorrowerActionCreator;
