import React, { FC as FC_, useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState as AppState_ } from '../../../redux/AppState';
import Page from '../Page/Page';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { EvalSectionState as EvalSectionState_ } from '../../../redux/actions/VisibleSections';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';

import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import AutoDetailsModel_ from '../../../data/models/AutoDetails';
import AutoDetailsSettings from './AutoDetailsSettings';
import { deepCopy } from '../../../utils/Helper';
import { buildSaveHolderAction } from '../../../redux/actions/Holder';

export interface AutoDetailsProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, AutoDetailsModel_>[],
    holder: QdApplicationHolder_,
    section: AutoDetailsModel_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const AutoDetails: FC_<AutoDetailsProps> = (props: AutoDetailsProps) => {
  const {
    holder, loading, sectionsState, modelState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      sectionsState: EvalSectionState_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    sectionsState: state?.sectionsState,
    modelState: state.modelState,
  }));

  const { ApplicationPageField, } = modelState?.ModelHolder;

  const { VisibleSections, } = sectionsState;
  const section = VisibleSections?.find(s => s.SectionName === ExternalPageSectionName.AutoDetails);

  const autoDetailsGeneralPageFields = ApplicationPageField?.AutoDetails?.General;
  const [subSection] = section?.SubSections || [];
  const pageSettings = useMemo(() => {
    return new AutoDetailsSettings([autoDetailsGeneralPageFields], [subSection]);
  }, [autoDetailsGeneralPageFields, subSection]);

  const [autoDetails, setAutoDetails] = useState<AutoDetailsModel_>(deepCopy(holder?.AutoDetails));
  const [pageFieldSettings] = useState(pageSettings);
  const dispatch = useDispatch();

  const updateAutoDetails = (pageField: PageFieldExtended_<QdApplicationHolder_, AutoDetailsModel_>, value: string) => {
    const { ObjectType, ObjectProperty, } = pageField;
    autoDetails[ObjectType][ObjectProperty] = value;

    // This is because the back-end doesn't allow comma(,) so we need to remove it for this field
    if (ObjectProperty === 'PurchasePrice') {
      autoDetails.AutoDetails.PurchasePrice = autoDetails.AutoDetails.PurchasePrice.replace(',', '');
    }

    setAutoDetails({ ...autoDetails, });
  };

  useEffect(() => {
    setAutoDetails(deepCopy(holder?.AutoDetails))
  }, [])
  
  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, AutoDetailsModel_>[]) => {
    autoDetails.AutoDetails.IdQDApplication = holder.IdQDApplication;
    dispatch(buildSaveHolderAction({
      ...holder,
      AutoDetails: autoDetails
    }));

    await props.onSubmit(invalidPageFields, holder, autoDetails, ExternalPages.AutoDetails, false);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageFieldSettings}
          onChange={updateAutoDetails}
          holder={holder}
          subHolder={autoDetails}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.AutoDetails}`}
        />
      )}
    </>
  );
};

export default AutoDetails;
