import {
  Section,
  HorizontalInfoCard,
  FormGroup,
  Input,
  Button,
  Radio,
  MaskedInput,
  Textarea,
  Datepicker,
  IconsSolid,
  IconsLight as IconsLight_,
  ReactAux,
  ActionsWrapper,
  Modal,
  AddressAutocomplete
} from '@jkhy/vsg-design-system';
import React, { useEffect, useState, Fragment } from 'react';
import _, { partition } from 'lodash';
import { useDispatch } from 'react-redux';
import { dateFormat, getHoverHelpPageFieldValue, scrollToError } from '../../../utils/Helper';
import PageSettings_ from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import SubSection_ from '../../../data/models/Subsection';
import { ValueConstants } from '../../../utils/Enums';
import validateOnBlur from '../../../utils/Validator';
import PickList from '../../../components/PickList/PickList';
import { UpdateAction as UpdateAction_ } from '../../../redux/actions/SubmitButton';
import PageFieldComponent_ from '../../../data/models/Component';
import { AddressDetails as AddressDetails_ } from '../../../utils/Types';
import ResponseBase_ from '../../../data/models/ResponseBase';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';

export interface HorizontalInfoCardGeneratorProps<THolder, TSubHolder extends { Id?: BigInt }, TAdditionalData> {
  pageSettings: PageSettings_<THolder, TSubHolder, TAdditionalData>;
  holder: THolder;
  subHolder: any;
  formIdentifier: string;
  title?: string;
  addButtonLabel?: string;
  description?: string;
  icon?: any;
  newObject: TSubHolder;
  list: Array<TSubHolder>;
  onChange?: (entity: TSubHolder, e: React.ChangeEvent, pageFields: PageFieldExtended_<THolder, TSubHolder>) => void;
  onChangeSelect?: (entity: TSubHolder, e: React.ChangeEvent, pageFields: PageFieldExtended_<THolder, TSubHolder>) => void;
  onSelectAddress?: (entity: TSubHolder, addressDetails: AddressDetails_, pageField: PageFieldExtended_<THolder, TSubHolder>) => TSubHolder;
  // eslint-disable-next-line max-len
  onSubmit?: (invalidPageFields: PageFieldExtended_<THolder, TSubHolder>[], data: Array<TSubHolder>, e: React.FormEvent<HTMLFormElement>) => void;
  onSave?: (entity: TSubHolder) => Promise<ResponseBase_<TSubHolder>>;
  onRemove?: (id: BigInt) => Promise<ResponseBase_<any>>;
  getSaveHolderAction?: (data: Array<TSubHolder>) => ISaveHolder_;
  setItemIcon?: (entity: TSubHolder) => IconsLight_.IconDefinition;
  setItemTitle?: (entity: TSubHolder) => string;
  onEdit?: (entity: TSubHolder) => void;
}

// eslint-disable-next-line max-len
const HorizontalInfoCardGenerator = <THolder, TSubHolder extends { Id?: BigInt }, TAdditionalData>(
  props: HorizontalInfoCardGeneratorProps<THolder, TSubHolder, TAdditionalData>
) => {
  const {
    subHolder,
    holder,
    formIdentifier,
    title,
    description,
    icon,
    newObject,
    list,
    pageSettings,
    addButtonLabel,
    setItemIcon,
    setItemTitle,
    onEdit,
  } = props;

  const [data, setData] = useState([...list]);
  const [removedData, setRemovedData] = useState([]);
  const [showAddEditMode, setShowAddEditMode] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [addMode, setAddMode] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [tempData, setTempData] = useState({} as TSubHolder);

  const [pageFieldsLocals, setPageFieldsLocals] = useState<PageFieldExtended_<THolder, TSubHolder>[]>(pageSettings?.PageFields);

  let invalidPageFields = [];

  const dispatch = useDispatch();
  const updateSubmitButtonFlag = (show: boolean) => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: false, show, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };

  useEffect(() => {
    return () => {
      updateSubmitButtonFlag(true);
    }; //  ComponentWillUnmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    props.onSubmit(invalidPageFields, data, e);
  };

  const onChange = (e: any, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const {
      target: { value, selectedOptions, },
      label,
    } = e;
    const [selected] = selectedOptions || [];

    const field = component?.ObjectProperty ? component?.ObjectProperty : pageField?.ObjectProperty;

    if (value === 'true' || value === 'false') {
      data[activeIndex][field] = value === 'true';
    } else {
      data[activeIndex][field] = value;
      if (pageField?.ObjectPropertyStr) data[activeIndex][pageField.ObjectPropertyStr] = label;
      if (!!selected && pageField.Component.type === 'select') {
        data[activeIndex][pageField.ObjectPropertyStr] = selected.text === ValueConstants.DropDownDefaultValue ? '' : selected.text;
      }
    }

    if (props.onChange) props.onChange(data[activeIndex], e, pageField);
    setData([...data]);
  };

  const onChangeSelect = (e, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const { value, label, } = e;
    const field = component?.ObjectProperty ? component?.ObjectProperty : pageField?.ObjectProperty;
    const pf = pageField;
    if (component?.ObjectProperty) pf.ObjectProperty = component?.ObjectProperty;

    data[activeIndex][field] = value;
    data[activeIndex][pageField.ObjectPropertyStr] = label === ValueConstants.DropDownDefaultValue ? '' : label;

    if (props.onChangeSelect) props.onChangeSelect(data[activeIndex], e, pageField);
    setData([...data]);
  };

  const onChangeAddress = (value: string, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    onChange({ target: { value, }, }, pageField, component);
  };

  const onSelectAddress = (details: AddressDetails_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    onChange({ target: { value: details.fullAddress, }, }, pageField, pageField.Component);
    if (props.onSelectAddress) {
      const newData = [...data];
      const editedEntity = props.onSelectAddress(newData[activeIndex], details, pageField);
      newData[activeIndex] = { ...editedEntity, };
      setData(newData);
    }
  };

  const onBlur = (e, pageField: PageFieldExtended_<THolder, TSubHolder>, component?: PageFieldComponent_) => {
    const {
      target: { value, },
    } = e;
    const { FieldName, GroupName, Component, } = pageField;
    const { inputType, regexStr, errorMessage, } = Component;

    if (inputType === 'email' || !!regexStr) {
      const { IsValid, InvalidMessage, } = validateOnBlur({
        inputType,
        regexStr,
        value,
        errorMessage,
      });
      setPageFieldsLocals(
        pageFieldsLocals.map(p => {
          const currentPf = p;
          const invalidField = currentPf.FieldName === FieldName && currentPf.GroupName === GroupName;
          if (invalidField) {
            currentPf.IsInvalidResult = !IsValid;
            currentPf.ValidationMessage = InvalidMessage;
          }
          return currentPf;
        })
      );
    }

    if (inputType === 'percent' && component?.type === 'maskedinput') {
      onChange(e, pageField, component);
    }
  };

  const addData = (e: React.MouseEvent) => {
    e.preventDefault();

    data.push({ ...newObject, });
    setActiveIndex(data.length - 1);
    setAddMode(true);
    setData([...data]);
  };

  const removeData = async () => {
    const id = data[removeIndex]?.Id;
    if (id) {
      if (props.onRemove) {
        await props.onRemove(id);
      }
      setRemovedData([...removedData, id]);
    }
    const editedData = _.reject(data, (x, i) => {
      return i === removeIndex;
    });
    setData([...editedData]);
    if (props.getSaveHolderAction) {
      const action = props.getSaveHolderAction([...editedData]);
      dispatch(action);
    }
    setRemoveIndex(-1);
  };

  const onEntitySave = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const pageFields: PageFieldExtended_<THolder, TSubHolder>[] = [...pageSettings.PageFields];
    [invalidPageFields] = partition(pageFields, pf => !pf.IsValid(holder, data[activeIndex], pf) && pf.Component !== null);
    if (invalidPageFields.length > 0) {
      scrollToError(document.querySelector(`[data-ui="${invalidPageFields[0].dataUI}"]`));
      setPageFieldsLocals(
        pageFields.map(p => {
          const currentPf = { ...p, };
          const invalidField = invalidPageFields.find(invalidPf => invalidPf.FieldName === p.FieldName && invalidPf.GroupName === p.GroupName);
          if (!currentPf.IsMultipleForm) currentPf.IsInvalidResult = !!invalidField;
          return currentPf;
        })
      );
    } else {
      setPageFieldsLocals(
        pageFields.map(p => {
          const pTemp = { ...p, };
          pTemp.IsInvalidResult = false;
          return pTemp;
        })
      );

      if (props.onSave) {
        const { Result, } = await props.onSave(data[activeIndex]);
        if (!data[activeIndex].Id) data[activeIndex].Id = Result?.Id;
      }
      setData([...data]);
      if (props.getSaveHolderAction) {
        const action = props.getSaveHolderAction([...data]);
        dispatch(action);
      }
      setShowAddEditMode(false);
      updateSubmitButtonFlag(true);
    }
  };

  const getClassName = isInvalidResult => (isInvalidResult ? 'invalid' : '');

  const initValue = (pageField: PageFieldExtended_<THolder, TSubHolder>, component?: any) => {
    if (pageField.ObjectType) {
      return pageField.ObjectIndex
        ? subHolder[activeIndex][pageField.ObjectType][pageField.ObjectIndex][pageField.ObjectProperty]
        : subHolder[activeIndex][pageField.ObjectType][pageField.ObjectProperty];
    }

    return data[activeIndex][component?.ObjectProperty || pageField?.ObjectProperty];
  };

  const renderInput = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField, component);
    return (
      <Input
        id={pageField.FieldName}
        dataUI={pageField.dataUI}
        disabled={pageField.IsDisabled}
        onChange={e => onChange(e, pageField, component)}
        onBlur={e => onBlur(e, pageField)}
        value={value}
        className={getClassName(pageField.IsInvalidResult)}
        maxLength={component?.maxLength}
        min={component?.minValue}
        max={component?.maxValue}
        type={component?.inputType}
      />
    );
  };

  const renderSelect = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField, component);

    const selectOptions = component?.options?.initialData || [];
    return (
      <PickList
        dsSelectWrapper
        id={pageField.FieldName}
        stateLess={component?.options?.stateLess}
        hideSelectOption={component?.options?.hideSelectOption}
        value={value}
        initialData={selectOptions}
        disabled={pageField.IsDisabled}
        onChange={e => onChangeSelect(e, pageField, component)}
        ListName={component?.options?.ListName}
        ListType={component?.options?.ListType}
        pageField={pageField}
        className={getClassName(pageField.IsInvalidResult || component?.IsInvalidResult)}
        dataUI={pageField.dataUI}
      />
    );
  };

  const renderMaskedInput = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField, component);
    const emptyPattern = null;

    const invalidResultClassName = getClassName(pageField.IsInvalidResult || component?.IsInvalidResult);
    const className = component?.className ? `${component?.className} ${invalidResultClassName}` : invalidResultClassName;

    return (
      <MaskedInput
        id={pageField.FieldName}
        value={value}
        onChange={e => onChange(e, pageField, component)}
        onBlur={e => onBlur(e, pageField, component)}
        min={component?.minValue}
        max={component?.maxValue}
        maxLength={component?.maxLength}
        mask={component?.inputMask}
        disabled={pageField.IsDisabled}
        type={component?.inputType}
        icon={component?.icon}
        pattern={emptyPattern}
        placeholder={component?.placeholder}
        className={className}
        dataUI={pageField.dataUI}
        guide={component?.guide}
      />
    );
  };

  const renderRadio = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const { Component, }: PageFieldExtended_<THolder, TSubHolder> = pageField;
    const value = initValue(pageField);
    const className = Component.options.length > 2 ? '' : 'd-inline-block';
    return Component.options.map(option => (
      <Radio
        key={`${option.value}-${option.label}`}
        htmlFor={`${pageField.FieldName}-${option.label}`}
        id={`${pageField.FieldName}-${option.label}`}
        value={option.value}
        disabled={pageField.IsDisabled}
        name={pageField.FieldName}
        onChange={e => onChange({ ...e, label: option.label, }, pageField)}
        className={`${className} ${getClassName(pageField.IsInvalidResult)} ${component.className}`}
        checked={`${value}` === `${option.value}`}
        dataUI={pageField.dataUI}
      >
        {option.label}
      </Radio>
    ));
  };

  const renderTextarea = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <Textarea
        id={pageField.FieldName}
        disabled={pageField.IsDisabled}
        onChange={e => onChange(e, pageField)}
        value={value}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
        rows={component?.rows}
        maxLength={component?.maxLength}
      />
    );
  };

  const renderDatePicker = (pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField);
    return (
      <Datepicker
        id={pageField.FieldName}
        value={value}
        format="MM/DD/YYYY"
        disabled={pageField.IsDisabled}
        rangeStart={pageField?.Component?.rangeStart}
        rangeEnd={pageField?.Component?.rangeEnd}
        onChange={(e: any) => {
          const formattedDate = dateFormat(e?.target?.value);
          onChange({ target: { value: formattedDate, }, }, pageField);
        }}
        className={getClassName(pageField.IsInvalidResult)}
        dataUI={pageField.dataUI}
      />
    );
  };

  const renderAddressAutocomplete = (component: PageFieldComponent_, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    const value = initValue(pageField) || '';

    return (
      <AddressAutocomplete
        type="text"
        name="current-address"
        className={getClassName(pageField.IsInvalidResult)}
        id={pageField.FieldName}
        dataUI={pageField.dataUI}
        value={value}
        onBlur={e => onBlur(e, pageField)}
        onChange={(addressDetails: any) => onChangeAddress(addressDetails, pageField, component)}
        onAddressSelect={(addressDetails: any) => onSelectAddress(addressDetails, pageField)}
      />
    );
  };

  const renderComponent = (component: any, pageField: PageFieldExtended_<THolder, TSubHolder>) => {
    switch (component?.type) {
      case 'input':
        return renderInput(component, pageField);
      case 'select':
        return renderSelect(component, pageField);
      case 'radio':
        return renderRadio(component, pageField);
      case 'maskedinput':
        return renderMaskedInput(component, pageField);
      case 'textarea':
        return renderTextarea(component, pageField);
      case 'datepicker':
        return renderDatePicker(pageField);
      case 'address':
        return renderAddressAutocomplete(component, pageField);

      default:
        return renderInput(component, pageField);
    }
  };

  const renderSubSectionPageFields = (subSection: SubSection_) => {
    let pageFields: PageFieldExtended_<THolder, TSubHolder>[] = pageFieldsLocals;
    if (subSection.GroupName) {
      pageFields = _.filter(pageFields, p => p.GroupName === subSection.GroupName);
    }

    return pageFields.map(pf => {
      return (
        // eslint-disable-next-line react/jsx-fragments
        <Fragment key={`${pf?.Id}-${pf?.FieldName}`}>
          {!(pf.IsHidden || (pf.IsHiddenCalculated && pf.IsHiddenCalculated(props.holder, data[activeIndex], pf))) && (
            <FormGroup
              className="mb-2"
              label={pf?.Component?.type !== 'checkbox' ? pf.Label : ''}
              checkboxOrRadio={pf.Component.type === 'checkbox' || pf.Component.type === 'radio'}
              isBold={pf.IsBold}
              isRequired={pf.Required}
              hoverHelp={getHoverHelpPageFieldValue(pf)}
              isValid={!pf.IsInvalidResult}
              validationMessage={pf.ValidationMessage}
              dataUI={pf.dataUI}
              htmlFor={pf.FieldName}
            >
              {renderComponent(pf.Component, pf)}
            </FormGroup>
          )}
        </Fragment>
      );
    });
  };

  return (
    <form id={formIdentifier} onSubmit={onSubmit} noValidate>
      <Section
        dataUI="section-container"
        title={pageSettings.PageSection[0].SubSectionName}
        headerText={pageSettings.PageSection[0].SubSectionHeaderText}
        footerText={pageSettings.PageSection[0].SubSectionFooterText}
      >
        {!showAddEditMode
          && (data ?? []).map((obj, index) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <HorizontalInfoCard
                title={title ? obj[title] : setItemTitle(obj)}
                description={description ? obj[description] : null}
                icon={icon || setItemIcon(obj)}
                className="mb-2"
                dataUI={`horizontal-card-${index}`}
                onEdit={e => {
                  e.preventDefault();

                  setAddMode(false);
                  setTempData({ ...obj, });
                  setActiveIndex(index);
                  setShowAddEditMode(true);
                  updateSubmitButtonFlag(false);

                  if (onEdit) onEdit(obj);
                }}
                onDelete={e => {
                  e.preventDefault();
                  setRemoveIndex(index);
                }}
              />
            );
          })}
        {showAddEditMode && <ReactAux>{renderSubSectionPageFields(pageSettings.PageSection[0])}</ReactAux>}

        {!showAddEditMode && (
          <Button
            dataUI="add-button"
            iconLeft={IconsSolid.faPlus}
            onClick={e => {
              addData(e);
              setShowAddEditMode(true);
              updateSubmitButtonFlag(false);
            }}
          >
            {addButtonLabel || 'Add New'}
          </Button>
        )}
      </Section>

      {showAddEditMode && (
        <ActionsWrapper className="mt-2" dataUI="save-actions-container">
          <Button
            dataUI="cancel-button"
            btnType="secondary"
            className="action-spacer"
            iconLeft={IconsSolid.faTimesCircle}
            onClick={() => {
              if (addMode) {
                data.splice(activeIndex, 1);
              } else {
                data[activeIndex] = { ...tempData, };
              }
              setPageFieldsLocals(
                pageFieldsLocals.map(p => {
                  const pTemp = { ...p, };
                  pTemp.IsInvalidResult = false;
                  return pTemp;
                })
              );
              setShowAddEditMode(false);
              updateSubmitButtonFlag(true);
            }}
          >
            Cancel
          </Button>
          <Button iconLeft={IconsSolid.faSave} dataUI="save-button" onClick={onEntitySave}>
            Save
          </Button>
        </ActionsWrapper>
      )}

      <Modal
        dataUI="remove-confiramtion-modal"
        title="Confirm to delete"
        isVisible={removeIndex > -1}
        isClosable
        closeBtnName="Cancel"
        closeBtnIcon={IconsSolid.faTimesCircle}
        onClose={() => setRemoveIndex(-1)}
        actionBtnName="YES, DELETE"
        actionBtnIcon={IconsSolid.faTrash}
        onSubmit={removeData}
        size="S"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </form>
  );
};

export default HorizontalInfoCardGenerator;
