/* eslint no-unused-expressions: off */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators as bindActionCreators_ } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  ActionsWrapper, Button, Section, ReactAux, FormGroup, Radio, Input, Select, MaskedInput, IconsSolid
} from '@jkhy/vsg-design-system';
import Base from '../../Layout/BaseComponent';
import { updateRenewalHolder } from '../../../redux/actions/Renewal';
import Question from '../../../components/Question/Question';
import ListAPI from '../../../data/api/ListApi';
import { setLoader } from '../../../redux/actions/Loading';
import {
  errorMsg,
  getHoverHelpPageFieldValue,
  isFieldValid,
  errorFoundMessage,
  scrollToError,
  validateAddressBlock,
  validatePhoneNumber,
  isNullOrUndefined,
  booleanStringComparison
} from '../../../utils/Helper';
import Section_ from '../../../data/models/Section';
import { AppState as AppState_ } from '../../../redux/AppState';
import { QdApplication as QdApplication_ } from '../../../data/models/QDApplication';
import {
  InformationInvalidFields as InformationInvalidFields_,
  BaseType as BaseType_,
  RenewalModelHolder as RenewalModelHolder_
} from '../../../utils/Types';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import { ValueConstants, RenewalPages, RenewalPageSectionName } from '../../../utils/Enums';
import Messages from '../../../utils/Messages';

export interface InformationState {
  app: QdApplication_;
  stateListCodes: string[];
  statesCodesLoading: boolean;
  invalidFields: any[];
  zipCodeDirty: Boolean;
  phoneNumberDirty: Boolean;
}

type InformationProps = {
  section: Section_;
  goToNexTab: (renewalSequence: typeof RenewalPages[keyof typeof RenewalPages]) => void;
  updateRenewalHolder;
  getInvalidFieldsForInformationTab: any;
  invalidFields: InformationInvalidFields_;
} & BaseType_;

class Information extends Base<InformationProps, InformationState> {
  constructor(props) {
    super(props);

    this.state = {
      app: props?.renewal?.ModelHolder?.Renewal?.Application,
      invalidFields: [],
      stateListCodes: [],
      statesCodesLoading: true,
      zipCodeDirty: false,
      phoneNumberDirty: false,
    };
  }

  onChange = (e, prop) => {
    const { app, } = this.state;
    const value = e.value ? e.value : e.target.value;

    if (value === 'true' || value === 'false') {
      app[prop] = booleanStringComparison(value);
    } else {
      app[prop] = value;
    }

    this.setState({ app, });
  };

  componentDidMount() {
    const listStates = ListAPI.getLtItemsSearch({ listName: 'QDState', });

    listStates.then(result => {
      const stateList = result.Result;

      const stateListCodes = stateList.map(state => state.Code);
      this.setState({ stateListCodes, statesCodesLoading: false, });
    });
  }

  validate = () => {
    const { app, } = this.state;
    const merged = this.props.getInvalidFieldsForInformationTab(app);
    const borrower = this.state.app?.Business;

    this.setState({
      invalidFields: merged,
    });

    if (merged.length > 0) {
      return false;
    }

    return (
      (app.MailingAddressChangedAnswer ? validateAddressBlock('MailingZip', borrower) : true)
      && (app.MailingAddressChangedAnswer ? validateAddressBlock('MailingAddress', borrower) : true)
      && (app.PhoneNumberChangedAnswer ? validatePhoneNumber(app) : true)
    );
  };

  onContinue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (this.validate()) {
      const { app, } = this.state;
      const { setLoader: setLoader_, goToNexTab: goToNextPage_, } = this.props;

      this.updateStore();

      if (app.Business.MailingZip) {
        app.Business.MailingZip = app.Business.MailingZip.replace(/-/g, '');
      }

      setLoader_(true);
      const data = await this.save(app);
      const { Success, Exceptions, } = data;
      setLoader_(false);

      if (Success) goToNextPage_(RenewalPages.RenewalDeclarations);
      else errorMsg(Exceptions);
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  updateStore = () => {
    const { app, } = this.state;
    const newRenewal: RenewalModelHolder_ = {
      Renewal: {
        Invitation: this.props.renewal?.ModelHolder?.Renewal?.Invitation,
        Application: { ...app, },
      },
      ...this.props.renewal.ModelHolder,
    };

    newRenewal.Renewal.Application.PhoneNumberChangedAnswer = app.PhoneNumberChangedAnswer;
    newRenewal.Renewal.Application.IfYesPhoneNumberChangedAnswer = app.IfYesPhoneNumberChangedAnswer;
    this.props.updateRenewalHolder(newRenewal);
  };

  sortQuestionsBySequence = array => {
    array.sort((a, b) => {
      return a.Sequence - b.Sequence;
    });
  };

  filterQuestionsBySectionName = (array, filterProperty) => {
    return array?.filter(x => x.SectionName === filterProperty);
  };

  mailingZipValidationMessage = () => {
    const { app, invalidFields, zipCodeDirty, } = this.state;

    if (!isFieldValid('MailingZip', invalidFields)) return errorFoundMessage('MailingZip', invalidFields);
    if (zipCodeDirty && !validateAddressBlock('MailingZip', app?.Business)) return Messages.INVALID_ZIP_FORMAT;

    return '';
  };

  mailingAddressValidationMessage = () => {
    const { app, invalidFields, } = this.state;

    if (!isFieldValid('MailingAddress', invalidFields)) return errorFoundMessage('MailingAddress', invalidFields);
    if (!validateAddressBlock('MailingAddress', app?.Business)) return Messages.INVALID_STREET_ADDRESS;
    return '';
  };

  phoneNumberValidationMessage = () => {
    const { app, invalidFields, phoneNumberDirty, } = this.state;

    if (!isFieldValid('IfYesPhoneNumberChangedAnswer', invalidFields)) return errorFoundMessage('IfYesPhoneNumberChangedAnswer', invalidFields);
    if (phoneNumberDirty && !validatePhoneNumber(app)) return Messages.INVALID_PHONE_FORMAT;
    return '';
  };

  content = () => {
    const { app, invalidFields, statesCodesLoading, } = this.state;
    const {
      section,
      renewal: {
        ModelHolder: { ApplicationPageField: pageField, },
      },
    } = this.props;
    app.Questions = this.filterQuestionsBySectionName(app?.Questions, RenewalPageSectionName.Information);
    this.sortQuestionsBySequence(app.Questions);
    const usPhoneCountryCodeMask = ['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const [subSection] = section.SubSections;
    return (
      <ReactAux>
        <form>
          <Section
            title={subSection.SubSectionName}
            headerText={subSection.SubSectionHeaderText}
            footerText={subSection.SubSectionFooterText}
            dataUI={`${subSection.Code}-section`}
          >
            <FormGroup
              label={pageField?.Information?.MailingAddressChangedAnswer?.Label || 'Has your mailing address changed?'}
              isBold={pageField?.Information?.MailingAddressChangedAnswer.IsBold}
              isHidden={pageField?.Information?.MailingAddressChangedAnswer.IsHidden}
              isRequired={pageField?.Information?.MailingAddressChangedAnswer.Required}
              hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.MailingAddressChangedAnswer)}
              dataUI="information-mailing-address-changed-answer-form-group"
              checkboxOrRadio
              isValid={isFieldValid('MailingAddressChangedAnswer', invalidFields)}
              validationMessage={errorFoundMessage('MailingAddressChangedAnswer', invalidFields)}
              className="mb-2"
            >
              <Radio
                htmlFor="changeAdressYes"
                id="changeAdressYes"
                className="d-inline-block"
                name="question"
                checked={app.MailingAddressChangedAnswer === true}
                value="true"
                onChange={e => this.onChange.call(this, e, 'MailingAddressChangedAnswer')}
                dataUI="information-mailing-address-changed-answer-radio-yes"
              >
                Yes
              </Radio>
              <Radio
                htmlFor="changeAdressNo"
                id="changeAdressNo"
                className="d-inline-block"
                name="question"
                checked={app.MailingAddressChangedAnswer === false}
                value="false"
                onChange={e => {
                  app.IfYesMailingAddressChangedAnswer = '';
                  this.onChange.call(this, e, 'MailingAddressChangedAnswer');
                }}
                dataUI="information-mailing-address-changed-answer-radio-no"
              >
                No
              </Radio>
            </FormGroup>
            {app.MailingAddressChangedAnswer && (
              <>
                <FormGroup
                  isBold={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsBold}
                  isHidden={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsHidden}
                  isRequired={pageField?.Information?.IfYesMailingAddressChangedAnswer.Required}
                  hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.IfYesMailingAddressChangedAnswer)}
                  label="Address"
                  className="mb-2"
                  isValid={isFieldValid('MailingAddress', invalidFields) && validateAddressBlock('MailingAddress', app?.Business)}
                  validationMessage={this.mailingAddressValidationMessage()}
                  htmlFor="mailingAddress"
                  dataUI="information-mailing-address-changed-form-group"
                >
                  <Input
                    id="mailingAddress"
                    value={app.Business.MailingAddress}
                    maxLength={51}
                    onChange={e => {
                      const { value, } = e.target;
                      const { Business, } = app;
                      Business.MailingAddress = value;
                      const newApp = { ...app, Business, };
                      newApp.IfYesMailingAddressChangedAnswer = value;
                      this.setState({ app: newApp, });
                    }}
                    onBlur={this.updateStore}
                    dataUI="information-mailing-address-changed-input"
                  />
                </FormGroup>
                <FormGroup
                  isBold={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsBold}
                  isHidden={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsHidden}
                  isRequired={pageField?.Information?.IfYesMailingAddressChangedAnswer.Required}
                  hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.IfYesMailingAddressChangedAnswer)}
                  label="City"
                  className="mb-2"
                  isValid={isFieldValid('MailingCity', invalidFields)}
                  validationMessage={errorFoundMessage('MailingCity', invalidFields)}
                  htmlFor="mailingCity"
                  dataUI="information-mailing-address-city-changed-form-group"
                >
                  <Input
                    id="mailingCity"
                    maxLength={51}
                    value={app.Business.MailingCity || ''}
                    onChange={e => {
                      const { value, } = e.target;
                      const { Business, } = app;
                      Business.MailingCity = value;
                      const newApp = { ...app, Business, };
                      newApp.IfYesMailingAddressChangedAnswer = value;
                      this.setState({ app: newApp, });
                    }}
                    onBlur={this.updateStore}
                    dataUI="information-mailing-address-city-changed-input"
                  />
                </FormGroup>
                <FormGroup
                  isBold={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsBold}
                  isHidden={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsHidden}
                  isRequired={pageField?.Information?.IfYesMailingAddressChangedAnswer.Required}
                  hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.IfYesMailingAddressChangedAnswer)}
                  label="State code"
                  className="mb-2"
                  isValid={isFieldValid('MailingState', invalidFields)}
                  validationMessage={errorFoundMessage('MailingState', invalidFields)}
                  dataUI="information-mailing-address-state-code-changed-form-group"
                  htmlFor="mailingState"
                >
                  <Select
                    id="mailingState"
                    isLoading={statesCodesLoading}
                    placeholder={ValueConstants.DropDownDefaultValue.toString()}
                    options={this.state.stateListCodes.map(c => {
                      return { label: c, value: c, };
                    })}
                    value={app.Business.MailingState || ''}
                    onChange={e => {
                      const { Business, } = app;
                      Business.MailingState = e.target.value;
                      const newApp = { ...app, Business, };
                      newApp.IfYesMailingAddressChangedAnswer = e.target.value;
                      this.setState({ app: newApp, });
                    }}
                    onBlur={this.updateStore}
                    dataUI="information-mailing-address-state-code-changed-select"
                  />
                </FormGroup>
                <FormGroup
                  label="Zip Code"
                  isBold={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsBold}
                  isHidden={pageField?.Information?.IfYesMailingAddressChangedAnswer.IsHidden}
                  isRequired={pageField?.Information?.IfYesMailingAddressChangedAnswer.Required}
                  hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.IfYesMailingAddressChangedAnswer)}
                  isValid={this.state.zipCodeDirty ? validateAddressBlock('MailingZip', app?.Business) : isFieldValid('MailingZip', invalidFields)}
                  validationMessage={this.mailingZipValidationMessage()}
                  className="mb-2"
                  htmlFor="mailingZip"
                  dataUI="information-mailing-address-zip-code-changed-form-group"
                >
                  <MaskedInput
                    id="mailingZip"
                    value={app.Business.MailingZip || ''}
                    onChange={e => {
                      const { value, } = e.target;
                      const { Business, } = app;
                      Business.MailingZip = value;
                      const newApp = { ...app, Business, };
                      newApp.IfYesMailingAddressChangedAnswer = value;
                      this.setState({ app: newApp, });
                    }}
                    onBlur={() => {
                      this.updateStore();
                      this.setState({ zipCodeDirty: true, });
                    }}
                    mask={InputMasksConstants.ZIP_CODE}
                    type="zipCode"
                    dataUI="information-mailing-address-zip-code-changed"
                  />
                </FormGroup>
              </>
            )}
            <FormGroup
              label="Has your phone changed?"
              isBold={pageField?.Information?.PhoneNumberChangedAnswer.IsBold}
              isHidden={pageField?.Information?.PhoneNumberChangedAnswer.IsHidden}
              isRequired={pageField?.Information?.PhoneNumberChangedAnswer.Required}
              hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.PhoneNumberChangedAnswer)}
              dataUI="information-phone-changed-answer-form-group"
              isValid={isFieldValid('PhoneNumberChangedAnswer', invalidFields)}
              validationMessage={errorFoundMessage('PhoneNumberChangedAnswer', invalidFields)}
              checkboxOrRadio
              className="mb-2"
            >
              <Radio
                htmlFor="q3"
                className="d-inline-block"
                name="question-set2"
                checked={app.PhoneNumberChangedAnswer === true}
                value="true"
                onChange={e => this.onChange.call(this, e, 'PhoneNumberChangedAnswer')}
                id="q3"
                dataUI="information-phone-changed-radio-yes"
              >
                Yes
              </Radio>
              <Radio
                htmlFor="q4"
                className="d-inline-block"
                name="question-set2"
                checked={app.PhoneNumberChangedAnswer === false}
                value="false"
                onChange={e => this.onChange.call(this, e, 'PhoneNumberChangedAnswer')}
                id="q4"
                dataUI="information-phone-changed-radio-no"
              >
                No
              </Radio>
            </FormGroup>

            {app.PhoneNumberChangedAnswer && (
              <FormGroup
                label="Phone Number"
                isRequired={pageField?.Information?.IfYesPhoneNumberChangedAnswer.Required}
                isBold={pageField?.Information?.IfYesPhoneNumberChangedAnswer.IsBold}
                isHidden={pageField?.Information?.IfYesPhoneNumberChangedAnswer.IsHidden}
                hoverHelp={getHoverHelpPageFieldValue(pageField?.Information?.IfYesPhoneNumberChangedAnswer)}
                isValid={this.state.phoneNumberDirty ? validatePhoneNumber(app) : isFieldValid('IfYesPhoneNumberChangedAnswer', invalidFields)}
                validationMessage={this.phoneNumberValidationMessage()}
                dataUI="information-phone-number-changed-form-group"
                htmlFor="phoneNumber"
                className="mb-2"
              >
                <MaskedInput
                  id="phoneNumber"
                  type="phone"
                  mask={usPhoneCountryCodeMask}
                  icon={IconsSolid.faPhone}
                  value={app.IfYesPhoneNumberChangedAnswer}
                  onChange={e => {
                    this.onChange.call(this, e, 'IfYesPhoneNumberChangedAnswer');
                  }}
                  onBlur={() => {
                    this.updateStore();
                    this.setState({ phoneNumberDirty: true, });
                  }}
                  dataUI="information-phone-number-changed-input"
                />
              </FormGroup>
            )}

            {(app.Questions || []).map(question => {
              let key = `${question.Id}`;
              if (!isNullOrUndefined(question.IsValid)) {
                key = `${question.Id}-${question.IsValid ? 'valid' : 'not-valid'}`;
              }
              return <Question question={question} key={key} />;
            })}
          </Section>

          <ActionsWrapper dataUI="information-continue-button-wrapper" className="mt-2">
            <Button dataUI="information-continue-button" onClick={this.onContinue}>
              Continue
            </Button>
          </ActionsWrapper>
        </form>
      </ReactAux>
    );
  };
}

export default withRouter(
  connect(
    (state: AppState_) => ({ ...state, }),
    dispatch => bindActionCreators_({ updateRenewalHolder, setLoader, }, dispatch)
  )(Information)
);
