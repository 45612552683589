import React, {
  FC as FC_, useState, useEffect, useCallback
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, Checkbox, ScrollableContainer, FormSection, Alert, ReactAux, Tooltip
} from '@jkhy/vsg-design-system';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../redux/AppState';
import { setMainDisclosures } from '../../../redux/actions/Disclosures';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../utils/Types';
import { buildSaveHolderAction } from '../../../redux/actions/Holder';
import { ExternalPages, ExternalPageSectionName, CustomerType } from '../../../utils/Enums';
import { getGuid } from '../../../utils/LocalStorageManager';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { getFullName, htmlParser } from '../../../utils/Helper';
import QDPartyDisclosure_ from '../../../data/models/QDPartyDisclosure';
import Section_ from '../../../data/models/Section';
import Borrower_ from '../../../data/models/Borrower';
import Messages from '../../../utils/Messages';
import { setLoader as setLoaderAction } from '../../../redux/actions/Loading';
import {
  checkIsDisclosuresHasEmailTemplate,
  checkHasPartyAgreedEarlier,
  checkAreAllPartiesAgreed,
  checkAreAllDocumentsOpened,
  DisclosuresDocuments as DisclosuresDocuments_,
  DisclosuresPartyStatuses as DisclosuresPartyStatuses_,
  checkHasDisclosures
} from '../../../utils/Disclosures';
import DisclosuresDocuments from '../../../components/DisclosuresDocuments/DisclosuresDocuments';

export interface DisclosureProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, any>[],
    holder: QdApplicationHolder_,
    section: {},
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const Disclosures: FC_<DisclosureProps> = (props: DisclosureProps) => {
  const {
    holder, loading, sections, modelState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      sections: Section_[];
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    sections: state?.sectionsState.VisibleSections,
    modelState: state?.modelState,
  }));

  const {
    ModelHolder: { Model, },
  } = modelState;
  const {
    BorrowerP: { Personal, },
    BorrowerB: { Business, },
    Guarantor: { Guarantors, },
  } = holder;

  const section = sections?.find(s => s.SectionName === ExternalPageSectionName.Disclosures);
  const subSection = section.SubSections.find(sub => sub.Code === ExternalPageSectionName.Disclosures);
  const dispatch = useDispatch();

  const [partyStatuses, setPartyStatuses] = useState<DisclosuresPartyStatuses_>({});
  const [partiesFromServer, setPartiesFromServer] = useState<QDPartyDisclosure_[]>([]);
  const [concatDisclosures, setConcatDisclosures] = useState<string>('');
  const [disclosuresDocuments, setDisclosuresDocuments] = useState<DisclosuresDocuments_>({});
  const [showWarningMessage, setShowWarningMessage] = useState<boolean>(false);

  const hasDisclosures = checkHasDisclosures(concatDisclosures, disclosuresDocuments);

  const getPartyData = useCallback(
    (guid: string) => {
      return [Personal, Business, ...Guarantors].find((currentParty: Borrower_) => currentParty.GUID === guid);
    },
    [Business, Personal, Guarantors]
  );

  useEffect(() => {
    const getDisclosures = async () => {
      dispatch(setLoaderAction(true));

      const guidKey = getGuid();
      const {
        Result: { ConcatenatedDisclosures, Parties, PdfDisclosures, },
      } = await QdServiceApi.loadQDApplicationDisclosures(guidKey, section.Id);
      setConcatDisclosures(ConcatenatedDisclosures);

      const { CustomerType: customerType, } = Model;
      const filteredPartiesFromServer = customerType === CustomerType.Consumer || (customerType === CustomerType.Commercial && !Business.TIN)
        ? Parties.filter((disclosureParty: QDPartyDisclosure_) => disclosureParty.PartyGuid !== Business.GUID)
        : Parties;

      setPartiesFromServer(filteredPartiesFromServer);

      const newPartyStatuses: DisclosuresPartyStatuses_ = {};

      filteredPartiesFromServer.forEach((disclosureParty: QDPartyDisclosure_) => {
        const { PartyGuid, } = disclosureParty;
        const hasPartyAgreed = checkHasPartyAgreedEarlier(PartyGuid, Parties);

        newPartyStatuses[PartyGuid] = hasPartyAgreed;
      });

      setPartyStatuses(newPartyStatuses);

      const hasMainDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
      dispatch(setMainDisclosures({ hasDisclosures: hasMainDisclosures, isFetched: true, }));

      if (PdfDisclosures.length > 0) {
        const newDisclosuresDocuments: DisclosuresDocuments_ = {};
        const areAllPartiesAgreed = checkAreAllPartiesAgreed(newPartyStatuses);

        PdfDisclosures.forEach(disclosure => {
          const { GUID, Name, } = disclosure;

          newDisclosuresDocuments[GUID] = {
            isOpened: areAllPartiesAgreed,
            name: Name,
            documentBytes: '',
          };
        });

        setDisclosuresDocuments(newDisclosuresDocuments);
      }

      dispatch(setLoaderAction(false));
    };

    getDisclosures();

    // Reset guarantors in disclosures
    if (holder?.Disclosures?.QDPartyGuids?.length > 0) {
      const modifiedHolder = { ...holder, Disclosures: { ...holder.Disclosures, QDPartyGuids: [], }, };
      const saveHolderAction = buildSaveHolderAction(modifiedHolder);

      dispatch(saveHolderAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifyState = (guid, email) => {
    const disclosureModified = holder.Disclosures;
    if (!disclosureModified.QDPartyGuids?.find(d => d.PartyGuid === guid)) {
      // Add relation party guid for disclosure
      const partyDislcosure = new QDPartyDisclosure_();
      partyDislcosure.PartyGuid = guid;
      partyDislcosure.PartyEmail = email;
      partyDislcosure.AgreedEarlier = true;
      disclosureModified.QDPartyGuids.push(partyDislcosure);
    }

    // Update holder at AppState
    const holderModified = { ...holder, disclosureModified, };
    const saveHolderAction = buildSaveHolderAction(holderModified);
    dispatch(saveHolderAction);
  };

  const onDisclosureAgree = (e: React.ChangeEvent<HTMLInputElement>, borrower: Borrower_) => {
    const { checked, } = e.currentTarget;
    const { GUID, Email, } = borrower;
    modifyState(GUID, Email);
    setPartyStatuses(prevState => ({ ...prevState, [GUID]: checked, }));
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (hasDisclosures) {
      const isValid = checkAreAllPartiesAgreed(partyStatuses);

      if (!isValid) {
        setShowWarningMessage(true);
        return;
      }
    }

    const disclosures = {
      ...holder.Disclosures,
      DisclosuresAgreeFlag: true,
      DisclosuresSectionId: section.Id,
    };

    setShowWarningMessage(false);
    props.onSubmit([], holder, disclosures, ExternalPages.Disclosures, false);
  };

  const disclosureHTML = htmlParser(concatDisclosures);
  const hasDisclosuresDocuments = disclosuresDocuments && Object.keys(disclosuresDocuments).length > 0;

  return (
    <>
      {!loading && (
        <form className="ml-3" id={`form-${ExternalPages.Disclosures}`} onSubmit={onSubmit}>
          <Section
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
            className="mb-3"
            dataUI="disclosures-section"
          >
            <FormSection dataUI="disclosures-content-section" className="mb-3">
              {disclosureHTML && (
                <ScrollableContainer dataUI="concatenated-disclosures" className={`${hasDisclosuresDocuments ? 'mb-3' : ''}`}>
                  <p>{disclosureHTML}</p>
                </ScrollableContainer>
              )}

              {hasDisclosuresDocuments && (
                <DisclosuresDocuments disclosuresDocuments={disclosuresDocuments} setDisclosuresDocuments={setDisclosuresDocuments} />
              )}
            </FormSection>

            {hasDisclosures && (
              <ReactAux>
                {Object.entries(partyStatuses).map(([guid, isChecked], index: number) => {
                  const party = getPartyData(guid);

                  if (!party) {
                    return null;
                  }

                  const hasPartyAgreed = checkHasPartyAgreedEarlier(guid, partiesFromServer);
                  const areAllDocumentsOpened = checkAreAllDocumentsOpened(disclosuresDocuments);
                  const isDisabled = hasPartyAgreed || !areAllDocumentsOpened;

                  return (
                    <FormSection key={guid} className="mb-3 mt-3" dataUI={`dislosure-applicant-${index}-agree-form-section`}>
                      <h3 className="mb-2">{getFullName(party)}</h3>

                      {/* eslint-disable-next-line quick-decision/react-data-ui */}
                      <Tooltip title={areAllDocumentsOpened ? '' : Messages.DISCLOSURES_TOOLTIP_TITLE}>
                        <div>
                          <Checkbox
                            dataUI={`dislosure-applicant-${index}-agree-checkbox-field`}
                            htmlFor={`applicant-${index}-accept`}
                            id={`applicant-${index}-accept`}
                            name={`applicant-${index}-accept`}
                            checked={isChecked}
                            disabled={isDisabled}
                            onChange={e => onDisclosureAgree(e, party)}
                          >
                            {Messages.DISCLOSURES_CHECKBOX_LABEL}
                          </Checkbox>
                        </div>
                      </Tooltip>
                    </FormSection>
                  );
                })}

                {checkIsDisclosuresHasEmailTemplate(modelState, ExternalPages.Disclosures) && (
                  <Alert dataUI="dislosure-alert-email-notification" type="info" text={Messages.AGREEMENT_NOTIFICATION_DISCLOSURES} />
                )}
              </ReactAux>
            )}
          </Section>
          {showWarningMessage && <Alert dataUI="disclosure-alert-agreed-notification" type="danger" text={Messages.INVALID_DISCLOSURES} />}
        </form>
      )}
    </>
  );
};

export default Disclosures;
