import React, {
  FC as FC_, useCallback, useMemo, memo
} from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection from '../ReviewApplicationGeneralSection';
import { currencyFormat } from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import PurposeProduct_ from '../../../../data/models/PurposeProduct';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';

type PurposeProductProps = {
  hasFullAccess: boolean;
} & ReviewApplicationSectionProps_;

const PurposeProduct: FC_<PurposeProductProps> = ({
  hasFullAccess, page, sectionTitle, expandState, setExpandState,
}: PurposeProductProps) => {
  const { modelState, holder, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const purposeProductPageFields = modelState?.ModelHolder?.ApplicationPageField?.PurposeProduct;
  const purposeProductData = holder?.PurposeProduct;

  const getTimeInBusiness = useCallback((data: PurposeProduct_) => {
    const { DateFormedMonth, DateFormedYear, } = data || {};

    if (DateFormedMonth || DateFormedYear) {
      return `${DateFormedMonth || ''} / ${DateFormedYear || ''}`;
    }

    return '';
  }, []);

  const rows = useMemo(() => {
    return [
      {
        id: 'review-purpose-product-section',
        title: `${purposeProductPageFields?.Purpose?.Label}: `,
        subTitle: purposeProductData?.Facilities[0]?.PurposeCodeStr,
        pageFields: purposeProductPageFields,
        ...(hasFullAccess ? { link: { page, text: 'Go to purpose product step to edit', }, } : {}),
        data: {
          StatementOfIntent: {
            dataUI: 'purpose-product-statement-of-intent-field',
            value: purposeProductData?.Facilities[0]?.StatementOfIntentCodeStr,
          },
          Purpose: {
            dataUI: 'purpose-product-purpose-field',
            value: purposeProductData?.Facilities[0]?.PurposeCodeStr,
          },
          ProductType: {
            dataUI: 'purpose-product-product-type-field',
            value: purposeProductData?.ProductTypeCodeStr,
          },
          RequestedTermLength: {
            dataUI: 'purpose-product-requested-term-length-field',
            value: purposeProductData?.TermLengthCodeStr,
            isVisible: !purposeProductPageFields?.RequestedTermLength?.IsHidden && purposeProductData?.ProductTypeSecondCode === 'TL',
          },
          AmountRequested: {
            dataUI: 'purpose-product-amount-requested-field',
            value: currencyFormat(purposeProductData?.Facilities[0]?.AmountRequested),
          },
          SpecificReason: {
            dataUI: 'purpose-product-specific-reason-field',
            value: purposeProductData?.Facilities[0]?.SpecificReason,
          },
          TimeInBusiness: {
            dataUI: 'purpose-product-time-in-business-field',
            value: getTimeInBusiness(purposeProductData),
          },
        },
      }
    ];
  }, [getTimeInBusiness, hasFullAccess, page, purposeProductData, purposeProductPageFields]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(PurposeProduct);
