import { Reducer as Reducer_ } from 'redux';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_, ModelHolderActions as ModelHolderActions_ } from '../../utils/Types';

const modelHolderReducer: Reducer_<ModelState_<ExternalModelHolder_>, ModelHolderActions_> = (
  state = {
    ModelHolder: {
      IdentitySection: null,
      ApplicationPageField: null,
      PageFields: [],
      Model: null,
      ApplicationSections: [],
      CoBorrowerSections: [],
      SubSections: [],
    },
    IsModelHolderFetched: false,
  },
  action
) => {
  switch (action.type) {
    case 'GotModelHolder': {
      return { ...action.modelState, };
    }
    default:
      return state;
  }
};

export default modelHolderReducer;
