/* eslint-disable react-hooks/exhaustive-deps */

import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import Page from '../../Page/Page';
import BusinessBorrowerSettings from './BusinessBorrowerSettings';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import BorrowerHolder_ from '../../../../data/models/BorrowerHolder';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { ExternalPages, ExternalPageSectionName } from '../../../../utils/Enums';
import { ISaveHolder as ISaveHolder_ } from '../../../../redux/actions/Holder';

export interface BusinessBorrowerProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerHolder_>[],
    holder: QdApplicationHolder_,
    borrower: BorrowerHolder_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const BusinessBorrower: FC_<BusinessBorrowerProps> = (props: BusinessBorrowerProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const [borrower, setBorrower] = useState(holder?.BorrowerB);
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => (s.SectionName || '') === ExternalPageSectionName.BusinessInformation);
  const [subSection] = section?.SubSections;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const businessInformationGeneralPageFields = pageFieldHolder?.BusinessInformation?.General;

  /*  TODO: Related to LVD-1681:
  const ReCheckingAccountNumber = { ...pageFieldHolder?.BusinessInformation?.General?.CheckingAccountNumber, FieldName: 'ReCheckingAccountNumber', };
  const HasDifferentMailingAddress = { ...pageFieldHolder?.BusinessInformation?.General?.MailingAddress, FieldName: 'HasDifferentMailingAddress', };
  pageFieldHolder.BusinessInformation.General = {
    ...pageFieldHolder.BusinessInformation.General,
    ReCheckingAccountNumber,
    HasDifferentMailingAddress,
  };  */

  const pageSettings = useMemo(() => {
    return new BusinessBorrowerSettings([businessInformationGeneralPageFields], [subSection]);
  }, [businessInformationGeneralPageFields]);

  const dispatch = useDispatch();
  const updateHolder = () => {
    const { Business, ReCheckingAccountNum, } = borrower;
    const updateBorrower = { ...holder.BorrowerB, Business, ReCheckingAccountNum, };

    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: { ...holder, BorrowerB: updateBorrower, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  useEffect(() => {
    setBorrower(holder?.BorrowerB);
  }, [holder]);

  const updateBusinessBorrower = (pageField: PageFieldExtended_<QdApplicationHolder_, BorrowerHolder_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;

    if (ObjectProperty) borrower.Business[ObjectProperty] = value;
    if (ObjectPropertyStr) borrower.Business[ObjectPropertyStr] = text;

    setBorrower({ ...borrower, });
  };

  const onSubmit = (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerHolder_>[]) => {
    updateHolder();
    props.onSubmit(invalidPageFields, holder, borrower, ExternalPages.BusinessBorrower, false);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateBusinessBorrower}
          holder={holder}
          subHolder={borrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.BusinessBorrower}`}
        />
      )}
    </>
  );
};
export default BusinessBorrower;
