/* eslint func-names: off */
import BaseAPI from '../data/api/BaseApi';
import QdApplicationModel_ from '../data/models/QDApplicationModel';
import { ApplicationIdentifiers as ApplicationIdentifiers_ } from './Types';
import QdPrequalCompletePars_ from '../data/models/Prequalification/QDPrequalCompletePars';
import QDPrequalification_ from '../data/models/Prequalification/QDPrequalification';

const next = (url, pars) => BaseAPI.performAjax({
  url,
  method: 'POST',
  data: pars,
});

const submitProcessGenerator = function* (model: QdApplicationModel_, appIdentifiers: ApplicationIdentifiers_) {
  const url = `${BaseAPI.BASE_URL}api/QuickDecisionComplete/`;
  const pars = {
    IdQDApplication: appIdentifiers.IdApplication,
    GUIDQDApplication: appIdentifiers.GUID,
    Application: {
      Id: appIdentifiers.IdApplication,
      QDApplicationModelCode: model.Code,
      CustomerType: model.CustomerType,
      Source: 'Online', // TODO: fill value received via QDApplication holder / application state.
    },
  };

  const pars2 = yield next(`${url}CreatePrimaryBorrower`, pars);
  const pars3 = yield next(`${url}CreateLead`, pars2);
  if (!pars3.CompleteFlag) {
    const pars4 = yield next(`${url}CreateRelationshipFile`, pars3);
    if (pars4.IdMasterFile) {
      const pars5 = yield next(`${url}CreateLRFile`, pars4);
      const pars6 = yield next(`${url}EvaluateCreditScore`, pars5);
      const pars7 = model.CalculateScoreCard && model.IdScoreCard ? yield next(`${url}EvaluateScoreCard`, pars6) : pars6;

      return yield next(`${url}EvaluateLoanOptions`, pars7);
    }
  }
  return pars;
};

export default submitProcessGenerator;

export const COUNT_OF_SUBMIT_PROCESS_REQUESTS = 4; // Save, CreateProspect, CreateDecisionFile, EvaluateLoanOptions
export const submitPrequalificationProcessGenerator = function* (prequalification: QDPrequalification_) {
  const url = `${BaseAPI.BASE_URL}api/QuickDecisionPrequalification/`;

  const saveResult: QdPrequalCompletePars_ = yield next(`${url}SavePrequalification`, { prequalification, });

  if (!saveResult.ShouldStopApplication) {
    const qdPrequalCompletePars: QdPrequalCompletePars_ = {
      GUIDQDPrequalification: prequalification.GUID,
      Prequalification: saveResult.Prequalification,
    };

    const pars2: QdPrequalCompletePars_ = yield next(`${url}CreateProspectParty`, qdPrequalCompletePars);
    const pars3: QdPrequalCompletePars_ = yield next(`${url}CreateDecisionFile`, pars2);

    // TODO: Remove Prequalification from request params it's related to LV API mock logic
    return yield next(`${url}EvaluateLoanOptions`, { ...pars3, prequalification, });
  }

  return saveResult;
};
