import { IconsSolid } from '@jkhy/vsg-design-system';
import { validateDateRanges } from '../../../../../utils/Validator';
import PageSettings from '../../../Page/PageHelpers/PageSettings';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import { ValidationRegexConstants } from '../../../../../utils/Enums';
import Message from '../../../../../utils/Messages';
import { getAge, booleanStringComparison } from '../../../../../utils/Helper';
import { PersonalBorrowerAdditionalSettings as PersonalBorrowerAdditionalSettings_ } from '../../../../../utils/Types';

export default class CoBorrowerPersonalSettings_ extends PageSettings<
  QDCoBorrowerApplicationHolder_,
  Borrower_,
  PersonalBorrowerAdditionalSettings_
> {
  pageFieldsMapper(
    pageField: PageField_,
    settings: PersonalBorrowerAdditionalSettings_
  ): PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_> {
    if (!pageField) {
      return null;
    }

    const { borrower, hasIDVerificationShowed, isPartyMatched, } = settings;

    switch (pageField.FieldName) {
      case 'Relationship':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-relationship-field',
          ObjectProperty: 'BorrowerType',
          ObjectPropertyStr: 'BorrowerTypeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              hideSelectOption: true,
              initialData: [],
              ListName: 'QDRelationship',
              ListType: 'LtItems',
            },
          },
        });
      case 'FirstName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-first-name-field',
          ObjectProperty: 'FirstName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 33,
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });
      case 'MiddleName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-middle-name-field',
          ObjectProperty: 'MiddleName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 32,
          },
          IsReadOnly: isPartyMatched && borrower?.MiddleName?.length > 0,
        });
      case 'LastName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-last-name-field',
          ObjectProperty: 'LastName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 33,
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });

      case 'SSN':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-SSN-field',
          ObjectProperty: 'TIN',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'password',
            inputMask: InputMasksConstants.SSN_TAX_ID,
            regexStr: ValidationRegexConstants.TIN_SSN,
            errorMessage: `${pageField?.Label || 'SSN'} must be in format: 000000000`,
            className: 'text-left',
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });

      case 'MobilePhone':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-phone-number-field',
          ObjectProperty: 'PreferredPhone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'text',
            icon: IconsSolid.faPhone,
          },
        });

      case 'HomePhone':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-home-phone-number-field',
          ObjectProperty: 'HomePhone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'text',
            icon: IconsSolid.faPhone,
          },
        });

      case 'DateOfBirth':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-date-of-birth-field',
          ObjectProperty: 'DOB',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
          },
          IsValid: (holder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            const value = subHolder[pageF.ObjectProperty];
            const pf = pageF;
            pf.ValidationMessage = '';
            if (!!value === false) {
              if (pf.Required) {
                pf.ValidationMessage = Message.REQUIRED_FIELD;
                return false;
              }
              return true;
            }

            if (getAge(value) < 18) {
              pf.ValidationMessage = Message.INVALID_AGE;
              return false;
            }

            return !!value;
          },
          IsReadOnly: hasIDVerificationShowed || isPartyMatched,
        });

      case 'PercentOwnership':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-percent-ownership-guarantor-field',
          ObjectProperty: 'PercentOwnership',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'percent',
            icon: IconsSolid.faPercent,
          },
          IsReadOnly: true,
        });
      case 'USCitizen':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-US-citizen-field',
          ObjectProperty: 'USCitizenFlag',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });
      case 'IDType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-type-field',
          ObjectProperty: 'IDTypeCode',
          ObjectPropertyStr: 'IDTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'IdType',
              ListType: 'LtItems',
            },
          },
        });
      case 'IDExpirationDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-expiration-date-field',
          ObjectProperty: 'IDExpirationDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeStart: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (
            appHolder: QDCoBorrowerApplicationHolder_,
            subHolder: Borrower_,
            pageF: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>
          ) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(appHolder, subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'IDIssueDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-id-issuer-date-field',
          ObjectProperty: 'IDIssueDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (
            appHolder: QDCoBorrowerApplicationHolder_,
            subHolder: Borrower_,
            pageF: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>
          ) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(appHolder, subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });

      case 'IDNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-id-number',
          ObjectProperty: 'IDNumber',
          ObjectPropertyType: 'number',
          Component: {
            type: 'input',
            maxLength: 25,
          },
        });

      case 'IDIssuer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-id-issuer',
          ObjectProperty: 'IDIssuer',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });

      case 'EmailAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-email-address-field',
          ObjectProperty: 'Email',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: (holder, subHolder, pageF) => pageF.GroupName !== 'Personal Information/General',
          Component: {
            type: 'input',
            icon: IconsSolid.faEnvelope,
            inputType: 'email',
            maxLength: 100,
          },
          IsReadOnly: true,
        });

      case 'ServeInMilitary':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-military-service-status',
          ObjectProperty: 'IsMilitaryService',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });

      case 'MilitaryServiceStatus':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'guarantor-individual-military-service-status',
          ObjectProperty: 'MilitaryServiceStatusCode',
          ObjectPropertyStr: 'MilitaryServiceStatusCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'MilitaryServiceStatus',
              ListType: 'LtItems',
            },
          },
          IsHiddenCalculated: (holder, subHolder) => !booleanStringComparison(subHolder?.IsMilitaryService),
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
