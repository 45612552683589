/* eslint-disable no-unneeded-ternary */
import React from 'react';
import {
  FormGroup, Input, Radio, MaskedInput, Datepicker, Textarea, IntegerInput
} from '@jkhy/vsg-design-system';
import Question_ from '../../data/models/Question';
import { dateFormat } from '../../utils/Helper';
import Messages from '../../utils/Messages';
import { AnswerType } from '../../utils/Enums';

export interface QuestionTemplateProp {
  question: Question_;
  onChange: (value: string | number | boolean, property: string, id?: bigint) => void;
}

const QuestionTemplate: React.FC<QuestionTemplateProp> = (prop: QuestionTemplateProp) => {
  const { question, onChange, } = prop;
  const isTextAndYesEnabled = question.IfYesFlag && question.AnswerBoolean === true;

  return (
    <>
      <FormGroup
        isValid={question.IsValid}
        className="mb-2"
        label={question.QuestionText}
        isRequired={question.RequiredFlag}
        isHidden={question.IsHidden}
        validationMessage={!question.IsValid ? question.ValidationMessage : ''}
        dataUI={`question-template-question-form-group-${question.Code}`}
        htmlFor={`question-answer-${question.Id}`}
        checkboxOrRadio={question.AnswerType === 1 ? true : false}
      >
        {question.AnswerType === AnswerType.AnswerString && (
          <Input
            type="text"
            value={question.AnswerString || ''}
            onChange={e => onChange(e.target.value, 'AnswerString', question.Id)}
            maxLength={question.AnswerLength}
            dataUI={`question-template-answer-string-input-${question.Code}`}
            id={`question-answer-${question.Id}`}
          />
        )}

        {question.AnswerType === AnswerType.AnswerBoolean && (
          <>
            <Radio
              className="d-inline-block"
              name={`bool-${question.GUID}`}
              value="true"
              checked={question.AnswerBoolean === true}
              onChange={() => onChange(true, 'AnswerBoolean', question.Id)}
              dataUI={`question-template-answer-boolean-input-yes-${question.Code}`}
              htmlFor={`question-answer-yes-${question.Id}`}
              id={`question-answer-yes-${question.Id}`}
            >
              Yes
            </Radio>
            <Radio
              className="d-inline-block"
              name={`bool-${question.GUID}`}
              value="false"
              checked={question.AnswerBoolean === false}
              onChange={() => onChange(false, 'AnswerBoolean', question.Id)}
              dataUI={`question-template-answer-boolean-input-no-${question.Code}`}
              htmlFor={`question-answer-no-${question.Id}`}
              id={`question-answer-no-${question.Id}`}
            >
              No
            </Radio>
          </>
        )}

        {question.AnswerType === AnswerType.AnswerInteger && (
          <IntegerInput
            type="number"
            value={question.AnswerInteger ?? ''}
            min={question.AnswerMinValue}
            max={question.AnswerMaxValue}
            onChange={e => {
              let value = null;
              if (e.target.value) value = +e.target.value;

              onChange(value, 'AnswerInteger', question.Id);
            }}
            dataUI={`question-template-answer-integer-input-${question.Code}`}
            id={`question-answer-${question.Id}`}
          />
        )}

        {question.AnswerType === AnswerType.AnswerDecimal && (
          <MaskedInput
            value={question.AnswerDecimal ?? ''}
            min={question.AnswerMinValue}
            max={question.AnswerMaxValue}
            onChange={e => {
              let value = null;

              if (e.target.value) {
                const eventValue = e.target.value.split(',').join('');

                const valueAsArray = eventValue.split('');
                const lastSymbol = valueAsArray[valueAsArray.length - 1];
                value = lastSymbol !== '.' && lastSymbol !== '0' ? +eventValue : eventValue;
              }

              onChange(value, 'AnswerDecimal', question.Id);
            }}
            dataUI={`question-template-answer-decimal-input-${question.Code}`}
            id={`question-answer-${question.Id}`}
            className="text-left"
          />
        )}
        {question.AnswerType === AnswerType.AnswerDate && (
          <Datepicker
            value={question.AnswerDate ? new Date(question.AnswerDate) : null}
            format="MM/DD/YYYY"
            onChange={(e: any) => {
              const formattedDate = dateFormat(e.target.value);
              onChange(formattedDate, 'AnswerDate', question.Id);
            }}
            dataUI={`question-template-answer-date-date-picker-${question.Code}`}
            id={`question-answer-${question.Id}`}
          />
        )}

        {question.AnswerType === AnswerType.AnswerMemo && (
          <Textarea
            rows={5}
            value={question.AnswerMemo || ''}
            maxLength={question.AnswerLength}
            onChange={e => onChange(e.target.value, 'AnswerMemo', question.Id)}
            dataUI={`question-template-answer-memo-text-area-${question.Code}`}
            id={`question-answer-${question.Id}`}
          />
        )}

        {/**
         * TODO: Save it on Back-end
         */}

        {question.AnswerType === AnswerType.AnswerYear && (
          <IntegerInput
            id={`question-answer-${question.Id}`}
            value={question.AnswerYear || ''}
            onChange={e => {
              let value = null;
              if (e.target.value) value = +e.target.value;

              onChange(value, 'AnswerYear', question.Id);
            }}
            dataUI={`question-template-answer-year-input-${question.Code}`}
          />
        )}
      </FormGroup>
      {isTextAndYesEnabled && (
        <FormGroup
          className="mt-2 mb-2"
          label={question.IfYesQuestionText || 'Please explain reason'}
          dataUI={`question-template-if-yes-answer-form-group-${question.Code}`}
          htmlFor={`question-answer-is-yes-${question.Id}`}
          isRequired={question.RequiredFlag}
          isValid={!!question.IfYesAnswer}
          validationMessage={Messages.REQUIRED_FIELD}
        >
          <Input
            value={question.IfYesAnswer || ''}
            onChange={e => onChange(e.target.value, 'IfYesAnswer', question.Id)}
            dataUI={`question-template-if-yes-answer-input-${question.Code}`}
            id={`question-answer-is-yes-${question.Id}`}
          />
        </FormGroup>
      )}
    </>
  );
};

export default QuestionTemplate;
