import React, { FC as FC_, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../../ReviewApplicationGeneralSection';
import { ExternalPages } from '../../../../../utils/Enums';
import { dateFormat, yesNoOrEmptyAnswer } from '../../../../../utils/Helper';
import { getGoogleApiAddressAsString } from '../../../../../utils/Address';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../../ReviewApplication';
import QDAddress_ from '../../../../../data/models/QDAddress';

type BusinessAddressesProps = {
  businessAddresses: QDAddress_[];
} & ReviewApplicationSectionProps_;

const BusinessAddresses: FC_<BusinessAddressesProps> = ({
  isCoBorrower,
  page,
  businessAddresses,
  sectionTitle,
  expandState,
  setExpandState,
}: BusinessAddressesProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    modelState: state.modelState,
  }));

  const pageFields = isCoBorrower ? CoBorrowerPageField?.BusinessAddress?.General : ApplicationPageField?.BusinessAddress?.General;

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || businessAddresses?.length === 0) return [];

    return businessAddresses.map((address: QDAddress_) => {
      const {
        Id: addressId,
        Address,
        City,
        State,
        Zip,
        AddressStartDate,
        DifferentMailingAddress,
        MailingAddress,
        MailingCity,
        MailingState,
        MailingZip,
      } = address || {};

      const { CurrentAddress: currentAddressPageField, MailingAddress: mailingAddressPageField, } = pageFields || {};

      const linkText = isCoBorrower && page === ExternalPages.CoBorrower ? 'Go to Co-borrower step to edit' : 'Go to business address step to edit';

      const currentAddress = getGoogleApiAddressAsString(address);

      return {
        id: `review-business-address-${addressId}-collapse`,
        title: Address,
        pageFields,
        link: { page, text: linkText, },
        data: {
          CurrentAddress: {
            dataUI: `current-address-${addressId}-field`,
            value: currentAddress,
          },
          StreetAddress: {
            dataUI: `address-${addressId}-field`,
            value: Address,
            pageField: currentAddressPageField,
            customLabel: 'Address',
          },
          City: {
            dataUI: `address-city-${addressId}-field`,
            value: City,
            ...(isCoBorrower ? { pageField: currentAddressPageField, customLabel: 'City', } : {}),
          },
          State: {
            dataUI: `address-state-${addressId}-field`,
            value: State,
            ...(isCoBorrower ? { pageField: currentAddressPageField, customLabel: 'State', } : {}),
          },
          ZipCode: {
            dataUI: `address-zip-${addressId}-field`,
            value: Zip,
            ...(isCoBorrower ? { pageField: currentAddressPageField, customLabel: 'Zip', } : {}),
          },
          AddressStartDate: {
            dataUI: `address-start-date-${addressId}-field`,
            value: dateFormat(AddressStartDate),
          },
          DifferentMailingAddress: {
            dataUI: `address-different-mailing-address-${addressId}-field`,
            value: yesNoOrEmptyAnswer(DifferentMailingAddress),
            pageField: mailingAddressPageField,
            customLabel: 'Different Mailing Address',
          },
          MailingAddress: {
            dataUI: `mailing-address-${addressId}-field`,
            value: MailingAddress,
          },
          MailingCity: {
            dataUI: `mailing-address-city-${addressId}-field`,
            value: MailingCity,
            pageField: mailingAddressPageField,
            customLabel: 'Mailing City',
          },
          MailingState: {
            dataUI: `mailing-address-state-${addressId}-field`,
            value: MailingState,
            pageField: mailingAddressPageField,
            customLabel: 'Mailing State',
          },
          MailingZip: {
            dataUI: `mailing-address-zip-${addressId}-field`,
            value: MailingZip,
            pageField: mailingAddressPageField,
            customLabel: 'Mailing Zip',
          },
        },
      };
    });
  }, [pageFields, businessAddresses, isCoBorrower, page]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessAddresses);
