export default class QDPartyMatchPars {
  GUIDQDApplication: string;

  PartyType: number;

  FirstName?: string;

  LastName?: string;

  BirthDate: string;

  SSNLastDigits?: string;

  FullName?: string;

  TIN: string;

  Address?: string = '';

  Street?: string;

  Zip?: string;

  City?: string;

  State?: string;
}
