// eslint-disable-next-line max-classes-per-file
import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import { REGEX, ValidationRegexConstants } from '../../../../../utils/Enums';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import { validateDifferentTIN, validateGuarantorEmail } from '../../../../../utils/Validator';
import QdApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Messages from '../../../../../utils/Messages';
import { CoBorrowerAddNowAdditionalInformationSettings as AdditionalInformationSettings_ } from '../../../../../utils/Types';

const pageFieldsMapper = <THolder, TSubHolder>(
  pageField: PageField_,
  getDefault: () => PageFieldExtended_<THolder, TSubHolder>,
  applicationHolder?: QdApplicationHolder_
): PageFieldExtended_<THolder, TSubHolder> => {
  if (!pageField) {
    return null;
  }

  switch (pageField.FieldName) {
    case 'BusinessName':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-business-name-field',
        ObjectProperty: 'FullName',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 100,
        },
      });
    case 'DBA':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-DBA-field',
        ObjectProperty: 'DBA',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 190,
        },
      });
    case 'StreetAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-street-address-field',
        ObjectProperty: 'Street',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          regexStr: REGEX.PO_BOX,
          errorMessage: Messages.INVALID_STREET_ADDRESS,
          maxLength: 100,
        },
      });
    case 'City':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-city-field',
        ObjectProperty: 'City',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 50,
        },
      });
    case 'State':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-state-field',
        ObjectProperty: 'State',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'States',
            ListType: 'LtItems',
          },
        },
      });
    case 'ZIPCode':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-zip-code-field',
        ObjectProperty: 'Zip',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.ZIP_CODE,
          regexStr: ValidationRegexConstants.ZipCode,
          errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
          inputType: 'zipCode',
          icon: IconsSolid.faMapPin,
        },
      });
    case 'MailingAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-mailing-address-field',
        ObjectProperty: 'MailingAddress',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 100,
        },
      });
    case 'MailingCity':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-mailing-city-field',
        ObjectProperty: 'MailingCity',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 50,
        },
      });
    case 'MailingState':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-mailing-state-field',
        ObjectProperty: 'MailingState',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'States',
            ListType: 'LtItems',
          },
        },
      });
    case 'MailingZip':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-mailing-zip-field',
        ObjectProperty: 'MailingZip',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.ZIP_CODE,
          regexStr: ValidationRegexConstants.ZipCode,
          errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
          inputType: 'zipCode',
          icon: IconsSolid.faMapPin,
        },
      });
    case 'CompanyTraits':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-company-traits-field',
        ObjectProperty: 'CompanyTraits',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 1950,
        },
      });
    case 'DateFormed':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-date-formed-field',
        ObjectProperty: 'DateFormed',
        ObjectPropertyType: 'string',
        Component: {
          type: 'datepicker',
        },
        IsValid: (coBorrowerHolder, subHolder, pageF) => {
          if (pageF.IsHidden) return true;

          const value = subHolder[pageF.ObjectProperty];
          const pf = pageF;
          pf.ValidationMessage = '';
          if (!!value === false) {
            if (pf.Required) {
              pf.ValidationMessage = Messages.REQUIRED_FIELD;
              return false;
            }
            return true;
          }

          const currentDate = new Date();
          const selectedDate = new Date(value);
          if (currentDate < selectedDate) {
            pf.ValidationMessage = Messages.INVALID_DATE;
            return false;
          }

          return !!value;
        },
      });
    case 'StateFormed':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-state-formed-field',
        ObjectProperty: 'StateFormedCode',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'States',
            ListType: 'LtItems',
          },
        },
      });
    case 'NumberOfEmployees':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-number-of-employees-field',
        ObjectProperty: 'NumberOfEmployees',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 9,
          inputType: 'number',
          minValue: 0,
          maxValue: 9999999,
        },
      });
    case 'PercentOwnership':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-percent-ownership-guarantor-field',
        ObjectProperty: 'PercentOwnership',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'percent',
          icon: IconsSolid.faPercent,
        },
        IsReadOnly: !applicationHolder,
      });
    case 'TaxID':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-tax-ID-field',
        ObjectProperty: 'TIN',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.SSN_TAX_ID,
          regexStr: ValidationRegexConstants.TIN_SSN,
          errorMessage: `${pageField?.Label || 'Tax I.D. #'} must be in format: 000000000`,
          className: 'text-left',
        },
        IsValid: (
          guarantorAddNowHolder: CoBorrowerAddNowHolder_,
          coBorrower: Borrower_,
          pf: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>
        ) => {
          const pageFieldHelper = pf;
          const { IsValid, InvalidMessage, } = validateDifferentTIN(guarantorAddNowHolder, coBorrower, pf, applicationHolder);

          pageFieldHelper.ValidationMessage = InvalidMessage;

          return IsValid;
        },
      });
    case 'TaxEntityType':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-tax-entity-type-field',
        ObjectProperty: 'TaxEntityTypeCode',
        ObjectPropertyStr: 'TaxEntityTypeCodeStr',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'EntityTypes',
            ListType: 'LtItems',
          },
        },
      });
    case 'OrganizationType':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-organization-type-field',
        ObjectProperty: 'OrganizationTypeCode',
        ObjectPropertyStr: 'OrganizationTypeCodeStr',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'OrganizationType',
            ListType: 'LtItems',
          },
        },
      });
    case 'PhoneNumber':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-phone-number-field',
        ObjectProperty: 'PreferredPhone',
        ObjectPropertyType: 'string',
        Component: {
          type: 'maskedinput',
          inputMask: InputMasksConstants.PHONE,
          regexStr: ValidationRegexConstants.Phone,
          errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
          inputType: 'phone',
          icon: IconsSolid.faPhone,
        },
      });
    case 'Relationship':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-relationship-field',
        ObjectProperty: 'BorrowerType',
        ObjectPropertyStr: 'BorrowerTypeStr',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            hideSelectOption: true,
            initialData: [],
            ListName: 'QDRelationship',
            ListType: 'LtItems',
          },
        },
      });
    case 'WebSite': {
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-web-site-field',
        ObjectProperty: 'WebSite',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          regexStr: ValidationRegexConstants.WebSite,
          errorMessage: 'Must be in format: www.example.com',
          maxLength: 100,
        },
      });
    }
    case 'EmailAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-email-address-field',
        ObjectProperty: 'Email',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          icon: IconsSolid.faEnvelope,
          inputType: 'email',
          maxLength: 100,
        },
        IsValid: (
          guarantorAddNowHolder: CoBorrowerAddNowHolder_,
          coBorrower: Borrower_,
          pf: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>
        ) => {
          const pageFieldHelper = pf;
          const { IsValid, InvalidMessage, } = validateGuarantorEmail(guarantorAddNowHolder, coBorrower, pf, applicationHolder);

          pageFieldHelper.ValidationMessage = InvalidMessage;
          return IsValid;
        },
      });
    case 'BusinessEmailAddress':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'Email',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          icon: IconsSolid.faEnvelope,
          inputType: 'email',
          maxLength: 100,
        },
        dataUI: 'guarantor-business-borrower-email-address-field',
        // we passed in props applicationHolder only if we are in primary borrower application
        IsReadOnly: !applicationHolder,
      });
    case 'MostRecentYearEndDate':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-most-recent-year-end-date-field',
        ObjectProperty: 'MostRecentYearEndDate',
        ObjectPropertyType: 'string',
        Component: {
          type: 'datepicker',
        },
        IsValid: (coBorrowerHolder, subHolder, pageF) => {
          if (pageF.IsHidden) return true;

          const value = subHolder[pageF.ObjectProperty];
          const pf = pageF;
          pf.ValidationMessage = '';
          if (!!value === false) {
            if (pf.Required) {
              pf.ValidationMessage = Messages.REQUIRED_FIELD;
              return false;
            }
            return true;
          }

          const currentDate = new Date();
          const selectedDate = new Date(value);
          if (currentDate < selectedDate) {
            pf.ValidationMessage = Messages.INVALID_DATE;
            return false;
          }

          return !!value;
        },
      });
    case 'AnnualSales':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-annual-sales-field',
        ObjectProperty: 'AnnualSales',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
      });
    case 'AGI':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-AGI-field',
        ObjectProperty: 'AGI',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
      });
    case 'AverageDeposits':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'guarantor-business-average-deposits-field',
        ObjectProperty: 'AverageDeposits',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
      });

    default:
      return getDefault();
  }
};

export default class BusinessSettings extends PageSettings<CoBorrowerAddNowHolder_, Borrower_, AdditionalInformationSettings_> {
  protected pageFieldsMapper(pageField: PageField_, { holder, }): PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_> {
    const getDefaultPageField = () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);

    return pageFieldsMapper(pageField, getDefaultPageField, holder);
  }
}
export class CoBorrowerBusinessInformationSettings extends PageSettings<QDCoBorrowerApplicationHolder_, Borrower_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_> {
    const getDefaultPageField = () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);

    return pageFieldsMapper(pageField, getDefaultPageField);
  }
}
