import React, { FC as FC_, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import Page from '../../../Page/Page';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import IncomeExpensesSettings from './IncomeExpensesSettings';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import Employment_ from '../../../../../data/models/Employment';
import { GuarantorPages, GuarantorPageSectionName_ } from '../../../../../utils/Enums';

export interface IncomeExpensesProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

const CoBorrowerAddNowIncomeExpenses: FC_<IncomeExpensesProps> = (props: IncomeExpensesProps) => {
  const { addNowHolder, modelState, loading, } = useSelector<
    AppState_,
    {
      addNowHolder: CoBorrowerAddNowHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
    }
  >(state => ({
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    modelState: state.modelState,
    loading: state.loading,
  }));

  const [incomeExpenses, setIncomeExpenses] = useState(addNowHolder?.Employment ?? new Employment_());

  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.Employment);
  const [subSection] = section?.SubSections;

  const { CoBorrowerPageField, } = modelState?.ModelHolder;
  const incomeAndExpensesGeneralPageFields = CoBorrowerPageField?.IncomeandExpenses?.General;

  const pageSettings = useMemo(() => {
    return new IncomeExpensesSettings([incomeAndExpensesGeneralPageFields], [subSection]);
  }, [incomeAndExpensesGeneralPageFields, subSection]);

  const updateIncomeExpenses = (pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Employment_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;

    incomeExpenses[ObjectProperty] = value;
    if (ObjectPropertyStr) incomeExpenses[ObjectPropertyStr] = text;

    setIncomeExpenses({ ...incomeExpenses, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, Employment_>[]) => {
    if (invalidPageFields.length === 0) {
      if (!incomeExpenses?.IdQDParty) {
        const {
          CoBorrower: { Id, },
        } = addNowHolder;
        incomeExpenses.IdQDParty = Id;
      }

      const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = {
        ...addNowHolder,
        Employment: incomeExpenses,
      };
      await props.onSave(coBorrwerAddNowHolder, GuarantorPageSectionName_.Employment);
    }
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateIncomeExpenses}
          holder={addNowHolder}
          subHolder={incomeExpenses}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages['Income&Expenses']}`}
        />
      )}
    </>
  );
};

export default CoBorrowerAddNowIncomeExpenses;
