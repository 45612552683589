import { IconsSolid } from '@jkhy/vsg-design-system';
import { REGEX, ValidationRegexConstants } from '../../../utils/Enums';
/* eslint-disable max-classes-per-file */
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import BusinessAddressModel_ from '../../../data/models/BusinessAddress';

import { booleanStringComparison, isGoogleUnavailable } from '../../../utils/Helper';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import Messages from '../../../utils/Messages';
import { validateDateRanges } from '../../../utils/Validator';

export default class BusinessAddressSettings extends PageSettings<QdApplicationHolder_, BusinessAddressModel_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, BusinessAddressModel_> {
    if (!pageField) {
      return null;
    }

    const hideAdditionalMailingFields = (holder_: QdApplicationHolder_, subHolder: any) => {
      const dAddress = subHolder?.BusinessAddresses[0]?.DifferentMailingAddress;
      return !booleanStringComparison(dAddress);
    };

    switch (pageField.FieldName) {
      case 'CurrentAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-current-address-autocomplete-field',
          ObjectProperty: 'GoogleAPIAddress',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectPropertyType: 'string',
          Component: {
            type: 'address',
            regexStr: REGEX.PO_BOX,
            errorMessage: Messages.INVALID_STREET_ADDRESS,
          },
          IsHiddenCalculated: () => isGoogleUnavailable(),
        });
      case 'StreetAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-street-address-field',
          Label: 'Street Address',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectProperty: 'Address',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
          },
        });
      case 'City':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-city-address-field',
          Label: 'City',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectProperty: 'City',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
          },
        });
      case 'State':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-state-address-field',
          Label: 'State',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectProperty: 'State',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });
      case 'ZipCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-zip-address-field',
          Label: 'Zip',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectProperty: 'Zip',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
        });
      case 'AddressStartDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-address-start-date-field',
          ObjectProperty: 'AddressStartDate',
          ObjectIndex: '0',
          ObjectType: 'BusinessAddresses',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (
            holder: QdApplicationHolder_,
            subHolder: BusinessAddressModel_,
            pageF: PageFieldExtended_<QdApplicationHolder_, BusinessAddressModel_>
          ) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(holder, subHolder, pageF);
            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'DifferentMailingAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-different-address-field',
          ObjectType: 'BusinessAddresses',
          ObjectIndex: '0',
          ObjectProperty: 'DifferentMailingAddress',
          ObjectPropertyType: 'boolean',
          Label: 'Is your mailing address different from your current address?',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });
      case 'MailingAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-address-mailing-field',
          ObjectType: 'BusinessAddresses',
          ObjectIndex: '0',
          ObjectProperty: 'MailingAddress',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: hideAdditionalMailingFields,
          Component: {
            type: 'input',
          },
        });
      case 'MailingZip':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-address-zip-field',
          ObjectType: 'BusinessAddresses',
          ObjectIndex: '0',
          ObjectProperty: 'MailingZip',
          ObjectPropertyType: 'string',
          Label: 'Mailing Zip',
          IsHiddenCalculated: hideAdditionalMailingFields,
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
          },
        });
      case 'MailingCity':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-address-city-field',
          ObjectType: 'BusinessAddresses',
          ObjectIndex: '0',
          ObjectProperty: 'MailingCity',
          ObjectPropertyType: 'string',
          Label: 'Mailing City',
          IsHiddenCalculated: hideAdditionalMailingFields,
          Component: {
            type: 'input',
          },
        });
      case 'MailingState':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-address-state-field',
          ObjectType: 'BusinessAddresses',
          ObjectIndex: '0',
          ObjectProperty: 'MailingState',
          ObjectPropertyType: 'string',
          Label: 'Mailing State',
          IsHiddenCalculated: hideAdditionalMailingFields,
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });
      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
