import { Reducer as Reducer_ } from 'redux';
import { AppSettingsActions as AppSettingsActions_, AppSettingsState as AppSettingsState_ } from '../actions/AppSettings';

const appSettingsReducer: Reducer_<AppSettingsState_, AppSettingsActions_> = (state = { isApplicationStop: false, }, action) => {
  switch (action.type) {
    case 'UpdateStopApplication': {
      return {
        ...state,
        isApplicationStop: action.isApplicationStop,
      };
    }
    default:
      return state;
  }
};

export default appSettingsReducer;
