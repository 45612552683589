import { Reducer as Reducer_ } from 'redux';
import { UpdateAction as UpdateAction_, SubmitButtonState as SubmitButtonState_ } from '../actions/SubmitButton';
// eslint-disable-next-line max-len
const submitButtonReducer: Reducer_<SubmitButtonState_, UpdateAction_> = (state = { submitToReview: false, show: true, disabled: false, }, action) => {
  switch (action.type) {
    case 'UpdateSubmitButton': {
      return { ...action.state, };
    }
    default:
      return state;
  }
};

export default submitButtonReducer;
