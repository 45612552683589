import { Reducer as Reducer_ } from 'redux';
import { IGotException as IGotException_ } from '../actions/Exception';
import { Error as Error_ } from '../../data/models/ResponseBase';

const exceptionReducer: Reducer_<Error_[], IGotException_> = (state = [], action) => {
  switch (action.type) {
    case 'GotException': {
      return [...action.exceptions];
    }
    default:
      return state;
  }
};

export default exceptionReducer;
