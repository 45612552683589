import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators as bindActionCreators_ } from 'redux';
import { withRouter } from 'react-router-dom';
import { partition } from 'lodash';
import {
  ActionsWrapper, Button, Section, ReactAux, FormGroup, Radio, Textarea, MaskedInput, IconsSolid
} from '@jkhy/vsg-design-system';
import Base from '../../Layout/BaseComponent';
import { updateRenewalHolder } from '../../../redux/actions/Renewal';
import { setLoader } from '../../../redux/actions/Loading';
import {
  isFieldValid, errorFoundMessage, getHoverHelpPageFieldValue, maskLoanNumber
} from '../../../utils/Helper';
import RenewalSettings from '../RenewalSettings';
import QDApplicationBase_ from '../../../data/models/QDApplicationBase';
import Section_ from '../../../data/models/Section';
import Facility_ from '../../../data/models/Facility';
import Question_ from '../../../data/models/Question';
import { AppState as AppState_ } from '../../../redux/AppState';
import { QdApplication as QdApplication_ } from '../../../data/models/QDApplication';
import { BaseType as BaseType_, RenewalModelHolder as RenewalModelHolder_ } from '../../../utils/Types';
import { RenewalPages, RenewalType } from '../../../utils/Enums';
import QuestionTemplate from '../../../components/Question/QuestionTemplate';
import { validateQuestionAndSetValidationMessage } from '../../../components/Question/QuestionValidate';
import RenewalAPI from '../../../data/api/RenewalApi';

export interface LoanState {
  app: QdApplication_;
  facility: Facility_;
  invalidFields: any;
}

type FacilityValidation = {
  IsValid: boolean;
  QuestionsWithValidation: Question_[];
};

type LoanProps = {
  section: Section_;
  qdFacility: Facility_;
  goToNextPage: (renewalSequence: RenewalPages, guid?: string) => void;
  updateRenewalHolder: (holder: RenewalModelHolder_) => void;
} & BaseType_;

class Loan extends Base<LoanProps, LoanState> {
  constructor(props) {
    super(props);

    this.state = {
      facility: { ...props?.qdFacility, },
      app: props?.renewal?.ModelHolder?.Renewal?.Application,
      invalidFields: [],
    };
  }

  onChange = (value: string | number, prop: string | number) => {
    const { facility, } = this.state;

    facility[prop] = value;

    this.setState({ facility, });
  };

  onQuestionChange = (value: string | number | boolean, prop: string, questionId: bigint) => {
    const { facility, } = this.state;
    const modifiedQuestion = facility.Questions.map(q_ => {
      const q = q_;
      if (q.Id === questionId) {
        q[prop] = value;
      }
      return q;
    });

    facility.Questions = [...modifiedQuestion];
    this.setState({ facility, });
  };

  sortQuestionsBySequence = (array: Question_[]) => {
    array.sort((a: Question_, b: Question_) => a.Sequence - b.Sequence);
  };

  validate = (item: Facility_): FacilityValidation => {
    const { renewal, } = this.props;

    const facilitySettings = new RenewalSettings<QDApplicationBase_, Facility_>([renewal.ModelHolder?.ApplicationPageField?.Renewal], null, null);
    const [invalidFacilityFields] = partition(facilitySettings.PageFields, pf => !pf.IsValid(null, item, pf));

    const resultQuestionValidation = item.Questions.map((q: Question_) => {
      const { IsValid, ValidationMessage, } = validateQuestionAndSetValidationMessage(q);
      const qTemp = { ...q, IsValid, ValidationMessage, };
      return qTemp;
    });
    const facility = { ...item, Questions: resultQuestionValidation, };

    this.setState({
      invalidFields: invalidFacilityFields,
      facility,
    });

    const atLeastOneFailedQuestionValidation = resultQuestionValidation.find(q => !q.IsValid);
    return {
      IsValid: invalidFacilityFields.length === 0 && !atLeastOneFailedQuestionValidation,
      QuestionsWithValidation: resultQuestionValidation,
    };
  };

  onContinue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const { setLoader: setLoader_, renewal, goToNextPage, } = this.props;

    const { facility, } = this.state;

    setLoader_(true);
    const { IsValid, QuestionsWithValidation, } = this.validate(facility);
    if (!IsValid) {
      setLoader_(false);
      return;
    }
    facility.Questions = QuestionsWithValidation;

    const { Success, } = await RenewalAPI.facilityUpdate(facility);
    if (Success) {
      // update store
      const facilities = [...renewal.ModelHolder.Renewal.Application.Facilities];
      const indexOfItemToUpdate = facilities.map(s => s.Id).indexOf(facility.Id);
      facilities[indexOfItemToUpdate] = facility;
      renewal.ModelHolder.Renewal.Application.Facilities = facilities;
      updateRenewalHolder({ ...renewal.ModelHolder, });

      // determine next page in case more then 1 facility
      // navigate
      const nextFacility = facilities[indexOfItemToUpdate + 1];
      const nextPage = nextFacility?.GUID ? RenewalPages.Renewal : RenewalPages.Information;
      goToNextPage(nextPage, nextFacility?.GUID);
    }
    setLoader_(false);
  };

  content = () => {
    const {
      section,
      renewal: {
        ModelHolder: { ApplicationPageField: pageField, },
      },
    } = this.props;
    const { facility, invalidFields, } = this.state;
    const renewalChoice = facility.RenewalChoice;
    this.sortQuestionsBySequence(facility.Questions);

    const [subSection] = section?.SubSections;
    return (
      <ReactAux>
        <form>
          <Section
            dataUI="renewal-section"
            title={`Loan Number: ${maskLoanNumber(facility.LoanNumber)}`}
            headerText={subSection.SubSectionHeaderText}
            footerText={subSection.SubSectionFooterText}
          >
            <FormGroup
              className="mb-2"
              checkboxOrRadio
              label={pageField?.Renewal?.RenewalChoice.Label}
              isRequired={pageField?.Renewal?.RenewalChoice.Required}
              isHidden={pageField?.Renewal?.RenewalChoice.IsHidden}
              isBold={pageField?.Renewal?.RenewalChoice.IsBold}
              hoverHelp={getHoverHelpPageFieldValue(pageField?.Renewal?.RenewalChoice)}
              dataUI="renewal-renewal-choice-form-group"
            >
              <Radio
                htmlFor="renewChoiceSame"
                id="renewChoiceSame"
                dataUI="renewal-renewal-choice-renew-for-same-amount-radio"
                name={`question-${facility.GUID}`}
                value={0}
                checked={!renewalChoice || facility.RenewalChoice === RenewalType.RenewalSameAmount}
                onChange={e => {
                  let value = null;
                  if (e.target.value) value = +e.target.value;

                  this.onChange.call(this, value, 'RenewalChoice');
                }}
              >
                Renew for same amount
              </Radio>
              <Radio
                htmlFor="renewChoiceIncrease"
                id="renewChoiceIncrease"
                dataUI="renewal-renewal-choice-renew-with-increase-radio"
                name={`question-${facility.GUID}`}
                value={1}
                checked={renewalChoice === RenewalType.RenewalIncreaseAmount}
                onChange={e => {
                  let value = null;
                  if (e.target.value) value = +e.target.value;

                  this.onChange.call(this, value, 'RenewalChoice');
                }}
              >
                Renew with Increase
              </Radio>
              <Radio
                htmlFor="renewChoiceDecrese"
                id="renewChoiceDecrese"
                dataUI="renewal-renewal-choice-renew-with-decrease-radio"
                name={`question-${facility.GUID}`}
                value={2}
                checked={renewalChoice === RenewalType.RenewalDecreaseAmount}
                onChange={e => {
                  let value = null;
                  if (e.target.value) value = +e.target.value;

                  this.onChange.call(this, value, 'RenewalChoice');
                }}
              >
                Renew with Decrease
              </Radio>
            </FormGroup>

            {renewalChoice === RenewalType.RenewalIncreaseAmount && (
              <FormGroup
                className="mb-2"
                label="Enter amount to increase"
                isRequired
                htmlFor="amountIncrease"
                dataUI="renewal-amount-increase-form-group"
                isValid={isFieldValid('RenewalChoice', invalidFields)}
                validationMessage={errorFoundMessage('RenewalChoice', invalidFields)}
              >
                <MaskedInput
                  type="number"
                  id="amountIncrease"
                  name="amount-increase"
                  icon={IconsSolid.faDollarSign}
                  value={facility.RenewalAmount}
                  min={1}
                  max={922337203685477}
                  onChange={e => {
                    const split = e.target.value.split(',').join('');
                    let value = null;
                    if (split) value = +split;

                    this.onChange.call(this, value, 'RenewalAmount');
                  }}
                  dataUI="renewal-amount-increase-masked-input"
                />
              </FormGroup>
            )}

            {renewalChoice === RenewalType.RenewalDecreaseAmount && (
              <FormGroup
                className="mb-2"
                label="Enter amount to decrease"
                isRequired
                htmlFor="amountDecrease"
                dataUI="renewal-amount-decrease-form-group"
                isValid={isFieldValid('RenewalChoice', invalidFields)}
                validationMessage={errorFoundMessage('RenewalChoice', invalidFields)}
              >
                <MaskedInput
                  type="number"
                  id="amountDecrease"
                  name="amount-decrease"
                  icon={IconsSolid.faDollarSign}
                  value={facility.RenewalAmount}
                  min={1}
                  max={922337203685477}
                  onChange={e => {
                    const split = e.target.value.split(',').join('');
                    let value = null;
                    if (split) value = +split;

                    this.onChange.call(this, value, 'RenewalAmount');
                  }}
                  dataUI="renewal-amount-decrease-masked-input"
                />
              </FormGroup>
            )}

            {(renewalChoice === RenewalType.RenewalIncreaseAmount || renewalChoice === RenewalType.RenewalDecreaseAmount) && (
              <FormGroup
                className="mb-2"
                label={pageField?.Renewal?.SpecificReason.Label}
                isRequired={pageField?.Renewal?.SpecificReason.Required}
                isHidden={pageField?.Renewal?.SpecificReason.IsHidden}
                isBold={pageField?.Renewal?.SpecificReason.IsBold}
                hoverHelp={getHoverHelpPageFieldValue(pageField?.Renewal?.SpecificReason)}
                htmlFor="specificReason"
                dataUI="renewal-specific-reason-form-group"
                isValid={isFieldValid('SpecificReason', invalidFields)}
                validationMessage={errorFoundMessage('SpecificReason', invalidFields)}
              >
                <Textarea
                  id="specificReason"
                  name="specificReason"
                  rows={4}
                  value={facility.SpecificReason || ''}
                  onChange={e => this.onChange.call(this, e.target.value, 'SpecificReason')}
                  dataUI="renewal-tab-specific-reason-text-area"
                />
              </FormGroup>
            )}

            {(facility.Questions || []).map((question: Question_) => {
              return <QuestionTemplate key={`${facility.Id}-${question?.Id}`} question={question} onChange={this.onQuestionChange} />;
            })}
          </Section>

          <ActionsWrapper dataUI="renewal-actions-wrapper" className="mt-2">
            <Button dataUI="renewal-continue-button" type="button" onClick={this.onContinue}>
              Continue
            </Button>
          </ActionsWrapper>
        </form>
      </ReactAux>
    );
  };
}

export default withRouter(
  connect(
    (state: AppState_) => ({ ...state, }),
    dispatch => bindActionCreators_({ updateRenewalHolder, setLoader, }, dispatch)
  )(Loan)
);
