import * as React from 'react';
import { createRoot } from 'react-dom/client';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from '@jkhy/vsg-design-system';
import { createStore, applyMiddleware, Store as Store_ } from 'redux';
import thunk from 'redux-thunk';
import { OidcProvider, loadUser } from 'redux-oidc';
import reducers from './redux/reducers';
import Renewal from './pages/Renewal/Renewal';
import External from './pages/External/External';
import Layout from './pages/Layout/index';
import PageMissing from './pages/PageMissing/PageMissing';
import userManager from './utils/UserManager';
import TokenCallback from './pages/Authentication/TokenCallback';
import LogoutCallback from './pages/Authentication/LogoutCallback';
import Logout from './pages/Authentication/Logout';
import SystemApi from './data/api/SystemApi';
import { AppState as AppState_ } from './redux/AppState';
import '@jkhy/vsg-design-system/dist/styles.css';
import GuarantorBase from './pages/External/Guarantor/GuarantorBase';
import PrequalificationBase from './pages/Prequalification/PrequalificationBase';


SystemApi.attachErrorListener();

const store: Store_<AppState_> = createStore(reducers, undefined, applyMiddleware(thunk));

const userPromise = loadUser(store, userManager);

userPromise.then(() => {
  const App = () => (
    <Provider store={store}>
      <OidcProvider store={store} userManager={userManager}>
        <BrowserRouter basename={window.APP_NAME}>
          <Switch>
            {/* Renewal routes */}
            <Redirect exact from="/renewal/:model/:idInvitation/" to="/renewal/:model/:idInvitation/0" />
            <Route
              exact
              path="/renewal/:model/:idInvitation/:renewalSequence/:guid?/"
              render={() => (
                <Layout>
                  <Renewal />
                </Layout>
              )}
            />

            {/* Prequalification routes */}
            <Route
              exact
              path="/prequalification/:model/:prequalificationSequence?/:guid?/"
              render={() => (
                <Layout>
                  <PrequalificationBase />
                </Layout>
              )}
            />

            {/* Identity provider routes */}
            <Route path="/token-callback/" component={TokenCallback} />
            <Route exact path="/logout-callback/:autologout?/" component={LogoutCallback} />
            <Route exact path="/logout/:autologout?/" component={Logout} />

            {/* External application routes */}
            {/* Guarantor routes */}
            <Route
              exact
              path="/guarantor-authentication/:model/:partyGUID/"
              render={() => (
                <Layout>
                  <GuarantorBase />
                </Layout>
              )}
            />
            <Route
              exact
              path="/guarantor/:model/:coBorrSequence/:partyGUID?/"
              render={() => (
                <Layout>
                  <GuarantorBase />
                </Layout>
              )}
            />

            <Route
              exact
              path="/guarantor-add-now/:model/:sequence/:page/:guid?/"
              render={() => (
                <Layout>
                  <External />
                </Layout>
              )}
            />

            {/* Borrower routes */}
            <Route
              exact
              path="/section/:model/:sequence/:guid?/"
              render={() => (
                <Layout>
                  <External />
                </Layout>
              )}
            />

            <Route
              exact
              path="/app/:model/"
              render={() => (
                <Layout>
                  <External />
                </Layout>
              )}
            />

            <Route
              exact
              path="/resume/:model/:guid/"
              render={() => (
                <Layout>
                  <External />
                </Layout>
              )}
            />
            <Route
              exact
              path="/:completePage/:model/:guid/"
              render={() => (
                <Layout>
                  <External />
                </Layout>
              )}
            />

            <Route exact path="*" render={() => <PageMissing statusCode="404" errMsg="The page you are looking for was not found!" />} />
          </Switch>
        </BrowserRouter>
      </OidcProvider>
      <ToastContainer />
    </Provider>
  );

  createRoot(document.getElementById('app')!).render(<App />);
});
