/* eslint react/prop-types: off */
/* eslint react/destructuring-assignment: off */
import React, { FC as FC_ } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Section, ActionsWrapper, Button, ProgressBar, IconsSolid
} from '@jkhy/vsg-design-system';
import PersonalBorrower from '../Borrower/PersonalBorrower/PersonalBorrower';
import BusinessBorrower from '../Borrower/BusinessBorrower/BusinessBorrower';
import CoBorrower from '../CoBorrower/CoBorrower';
import Disclosures from '../Disclosures/Disclosures';
import Collateral from '../Collateral/Collateral';
import Declaration from '../Declaration/Declaration';
import OtherInformation from '../Borrower/BusinessBorrowerOtherInformation/OtherInformation';
import NotImplementedPage from '../../NotImplement/NotImplement';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { EvalSectionState as EvalSectionState_ } from '../../../redux/actions/VisibleSections';
import BusinessReference from '../BusinessReference/BusinessReference';
import WrapUp from '../WrapUp/WrapUp';
import ReviewApplication from '../ReviewApplication/ReviewApplication';
import { ExternalPages, SubmitTotalStepsConstants, GuarantorPages } from '../../../utils/Enums';
import { RoutingProps as RoutingProps_ } from '../../../utils/Types';
import { SubmitButtonState as SubmitButtonState_, UpdateAction as UpdateAction_ } from '../../../redux/actions/SubmitButton';
import BusinessDeposits from '../Deposit/Deposit';
import PurposeProduct from '../PurposeProduct/PurposeProduct';
import Welcome from '../Welcome/Welcome';
import IncomeExpenses from '../IncomeExpenses/IncomeExpenses';
import IdentityCheck from '../IdentityCheck/IdentityCheck';
import YourInformation from '../Borrower/PersonalBorrowerMatch/YourInformation';
import Information from '../Information/Information';
import { AppState as AppState_ } from '../../../redux/AppState';
import BusinessObligations from '../Debt/Debt';
import BusinessBorrowerMatch from '../Borrower/BusinessBorrowerMatch/BusinessBorrowerMatch';
import PersonalAddress from '../PersonalAddress/PersonalAddress';
import BusinessAddress from '../BusinessAddress/BusinessAddress';
import CoBorrowerAddNowHolder_ from '../../../data/models/CoBorrowerAddNowHolder';
import CoBorrowerPersonalMatch from '../CoBorrower/AddNow/PartyMatch/IndividualMatch';
import CoBorrowerBusinessMatch from '../CoBorrower/AddNow/PartyMatch/BusinessMatch';
import CoBorrowerInformation from '../CoBorrower/AddNow/Information/CoBorrowerInformation';
import CoBorrowerOtherInformation from '../CoBorrower/AddNow/Information/OtherInformation';
import CoBorrowerPersonalAddress from '../CoBorrower/AddNow/Address/IndividualAddress';
import CoBorrowerPersonalBusiness from '../CoBorrower/AddNow/Address/BusinessAddress';
import CoBorrowerPersonalEmployment from '../CoBorrower/AddNow/IncomeExpenses/IncomeExpenses';
import CoBorrowerIndetityCheck from '../CoBorrower/AddNow/IdentityCheck/IdentityCheck';
import AutoDetails from '../AutoDetails/AutoDetails';

export interface ExternalSectionProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<any, any>[],
    holder: QdApplicationHolder_,
    section: any,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
  sequence: string;
  onSubmitApplication: () => void;
  sectionsState: EvalSectionState_;
  agreedFlag: boolean;
  showProgress: boolean;
  step: number;
  locationParams: RoutingProps_;
  hasEmailInvitedGuarantors: boolean;
  coBorrowerAddNowSequence?: string;
  onCoBorroweerAddNowCancel?: () => void;
  onCoBorrowerAddNowSave?: (holder: CoBorrowerAddNowHolder_, currentSectionName: string, nextPage?: string) => void;
}

const ExternalSection: FC_<ExternalSectionProps> = (props: ExternalSectionProps) => {
  const { submitToReviewButtonState, } = useSelector<
    AppState_,
    {
      submitToReviewButtonState: SubmitButtonState_;
    }
  >(state => ({
    submitToReviewButtonState: state.submitToReviewButtonState,
  }));

  const dispatch = useDispatch();
  const updateSubmitButtonFlag = () => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: true, show: true, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };

  const renderSectionExternalPage = () => {
    const { sequence, coBorrowerAddNowSequence, } = props;
    switch (sequence) {
      case ExternalPages.IdentityCheck:
        return <IdentityCheck />;
      case ExternalPages.Welcome:
        return <Welcome onSubmit={props.onSubmit} />;
      case ExternalPages.InformationMembership:
        return <Information onSubmit={props.onSubmit} />;
      case ExternalPages.PersonalPartyMatch:
        return <YourInformation onSubmit={props.onSubmit} />;
      case ExternalPages.PurposeProduct:
        return <PurposeProduct onSubmit={props.onSubmit} />;
      case ExternalPages.AutoDetails:
        return <AutoDetails onSubmit={props.onSubmit} />;
      case ExternalPages.PersonalBorrower:
        return <PersonalBorrower onSubmit={props.onSubmit} />;
      case ExternalPages.PersonalAddresses:
        return <PersonalAddress onSubmit={props.onSubmit} />;
      case ExternalPages.Employment:
        return <IncomeExpenses onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessPartyMatch:
        return <BusinessBorrowerMatch onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessBorrower:
        return <BusinessBorrower onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessBorrowerOtherInformation:
        return <OtherInformation onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessAddresses:
        return <BusinessAddress onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessReference:
        return <BusinessReference onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessObligations:
        return <BusinessObligations onSubmit={props.onSubmit} />;
      case ExternalPages.BusinessDeposits:
        return <BusinessDeposits onSubmit={props.onSubmit} />;
      case ExternalPages.CoBorrower:
        switch (coBorrowerAddNowSequence) {
          //  Add Now Business CoBorrower
          case GuarantorPages.BusinessPartyMatch:
            return <CoBorrowerBusinessMatch onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.BusinessInformation:
            return <CoBorrowerInformation onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.BusinessOtherInformation:
            return <CoBorrowerOtherInformation onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.BusinessAddresses:
            return <CoBorrowerPersonalBusiness onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          //  Add Now Personal CoBorrower
          case GuarantorPages.PersonalPartyMatch:
            return <CoBorrowerPersonalMatch onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.PersonalInformation:
            return <CoBorrowerInformation onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.PersonalAddress:
            return <CoBorrowerPersonalAddress onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          case GuarantorPages.IdentityCheck:
            return <CoBorrowerIndetityCheck />;
          case GuarantorPages['Income&Expenses']:
            return <CoBorrowerPersonalEmployment onSave={props.onCoBorrowerAddNowSave} onCancel={props.onCoBorroweerAddNowCancel} />;
          default:
            //  Email Invitation CoBorrower
            return <CoBorrower onSubmit={props.onSubmit} />;
        }
      case ExternalPages.Collateral:
        return <Collateral onSubmit={props.onSubmit} />;
      case ExternalPages.Declaration:
        return <Declaration onSubmit={props.onSubmit} />;
      case ExternalPages.Disclosures:
        return <Disclosures onSubmit={props.onSubmit} />;
      case ExternalPages.WrapUp:
        return <WrapUp onSubmit={props.onSubmit} />;
      case ExternalPages.Review:
        return <ReviewApplication onSubmit={props.onSubmitApplication} />;
      default:
        return <NotImplementedPage text="Section page application" />;
    }
  };

  const {
    sequence,
    agreedFlag,
    sectionsState,
    locationParams,
    showProgress,
    step,
    coBorrowerAddNowSequence,
    hasEmailInvitedGuarantors,
    onCoBorroweerAddNowCancel,
  } = props;

  const { Default, HasEmailInvitedCoBorrowers, } = SubmitTotalStepsConstants;
  // eslint-disable-next-line max-len
  const showSaveAndReturn = sectionsState.IsReviewed && submitToReviewButtonState.show && locationParams.sequence !== ExternalPages.Review && !coBorrowerAddNowSequence;
  return (
    <>
      {renderSectionExternalPage()}

      <Section dataUI="external-section-element" className="mt-3">
        {sequence === ExternalPages.WrapUp ? (
          <ActionsWrapper dataUI="external-section-actions-wrapper" className="mb-1">
            <Button
              dataUI="external-section-review-button"
              disabled={!agreedFlag}
              form={`form-${sequence}`}
              type="submit"
              iconRight={IconsSolid.faChevronRight}
            >
              Review
            </Button>
          </ActionsWrapper>
        ) : (
          submitToReviewButtonState.show
          && !coBorrowerAddNowSequence && (
            <ActionsWrapper dataUI="external-section-actions-wrapper" className="mb-1">
              <Button
                dataUI="external-section-continue-button"
                form={`form-${sequence}`}
                type="submit"
                disabled={submitToReviewButtonState.disabled}
                iconRight={IconsSolid.faChevronRight}
              >
                {sequence !== ExternalPages.Review ? 'Continue' : 'Submit Application and Continue'}
              </Button>
            </ActionsWrapper>
          )
        )}
        {showSaveAndReturn && (
          <ActionsWrapper dataUI="external-section-actions-wrapper" className="mb-1">
            <Button
              type="submit"
              disabled={submitToReviewButtonState.disabled}
              dataUI="external-section-save-button"
              className="ml-3"
              form={`form-${sequence}`}
              iconLeft={IconsSolid.faSave}
              onClick={updateSubmitButtonFlag}
            >
              Save/Return to Review Application
            </Button>
          </ActionsWrapper>
        )}

        {locationParams.sequence === ExternalPages.CoBorrower && coBorrowerAddNowSequence && submitToReviewButtonState.show && (
          <ActionsWrapper dataUI="add-co-borrower-actions-wrapper">
            {coBorrowerAddNowSequence !== GuarantorPages.IdentityCheck && (
              <Button
                dataUI="cancel-co-borrower-button"
                btnType="secondary"
                className="action-spacer"
                iconLeft={IconsSolid.faTimesCircle}
                onClick={onCoBorroweerAddNowCancel}
              >
                Cancel
              </Button>
            )}

            <Button dataUI="next-co-borrower-button" iconRight={IconsSolid.faChevronRight} form={`form-${coBorrowerAddNowSequence}`} type="submit">
              Next
            </Button>
          </ActionsWrapper>
        )}
      </Section>

      <ProgressBar
        isVisible={showProgress}
        steps={hasEmailInvitedGuarantors ? HasEmailInvitedCoBorrowers : Default}
        currentStep={step}
        dataUI="external-section-progress-bar"
      />
    </>
  );
};

export default ExternalSection;
