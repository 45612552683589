import { ApplicationTypes, ContextParams } from './Enums';

export const localStorageKeys = Object.freeze({
  GUID: 'Guid',
  ID_INVITATION_KEYBOARD: 'QDRenewalApplicationID',
  RENEWAL_MODEL_CODE: 'RENEWAL_MODEL_CODE',
  MODEL: 'Model',
  QD: 'QD',
  GUARANTOR: 'Guarantor',
  OIDCKey: `oidc.user:${(window as any).CONFIG.Oidc.authority}:${(window as any).CONFIG.Oidc.client_id}`,
  CONTEXT: 'context',
  // TODO: Remove this logic once delivered to COCC , version 4.3.74
  DISABLE_GOOGLE_MAPS: 'google-maps-disabled',
  APPLICATION_TYPE: 'ApplicationType',
});

export const getUser = () => {
  const user = sessionStorage.getItem(localStorageKeys.OIDCKey);
  return user ? JSON.parse(user) : {};
};

export const setUser = user => sessionStorage.setItem(localStorageKeys.OIDCKey, JSON.stringify(user));

export const removeUser = () => sessionStorage.removeItem(localStorageKeys.OIDCKey);

export const getToken = () => getUser().access_token;

export const setIdInvitation = id => sessionStorage.setItem(localStorageKeys.ID_INVITATION_KEYBOARD, id);

export const removeIdInvitation = () => sessionStorage.removeItem(localStorageKeys.ID_INVITATION_KEYBOARD);

export const getIdInvitation = () => sessionStorage.getItem(localStorageKeys.ID_INVITATION_KEYBOARD);

export const setInvitationModel = (model: string) => sessionStorage.setItem(localStorageKeys.RENEWAL_MODEL_CODE, model);

export const getInvitationModel = () => sessionStorage.getItem(localStorageKeys.RENEWAL_MODEL_CODE);

export const setGuid = (guid: string) => sessionStorage.setItem(localStorageKeys.GUID, guid);

export const getGuid = () => sessionStorage.getItem(localStorageKeys.GUID);

export const setModel = (model: string) => sessionStorage.setItem(localStorageKeys.MODEL, model);

export const getModel = () => sessionStorage.getItem(localStorageKeys.MODEL);

export const setQD = (qd: string) => sessionStorage.setItem(localStorageKeys.QD, qd);

export const getQD = () => sessionStorage.getItem(localStorageKeys.QD);

export const setGuarantorGuid = (guid: string) => sessionStorage.setItem(localStorageKeys.GUARANTOR, guid);

export const getGuarantorGuid = () => sessionStorage.getItem(localStorageKeys.GUARANTOR);

export const setContext = (context: typeof ContextParams[keyof typeof ContextParams]) => localStorage.setItem(localStorageKeys.CONTEXT, context);

export const removeLocalStorageItem = (key: typeof localStorageKeys[keyof typeof localStorageKeys]) => localStorage.removeItem(key);

export const getLocalStorageItem = (key: typeof localStorageKeys[keyof typeof localStorageKeys]) => localStorage.getItem(key);
// TODO: Remove this logic once delivered to COCC , version 4.3.74
export const googleAPIDisabledFromBrowser = () => sessionStorage.getItem(localStorageKeys.DISABLE_GOOGLE_MAPS);

export const getApplicationType = () => sessionStorage.getItem(localStorageKeys.APPLICATION_TYPE) as ApplicationTypes;

export const setApplicationType = (applicationTypes: typeof ApplicationTypes[keyof typeof ApplicationTypes]) => {
  sessionStorage.setItem(localStorageKeys.APPLICATION_TYPE, applicationTypes);
};
