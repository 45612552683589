import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import { setLoader } from './Loading';

import QDServiceApi_ from '../../data/api/QDServiceApi';
import QDEvaluationIdentityResult_ from '../../data/models/QDEvaluationIdentityResult';
import IdentityCheckEvalParam_ from '../../data/models/IdentityCheckEvalParam';

export type CoBorrowerIdentityState = {
  IdentityHolder: QDEvaluationIdentityResult_;
  IsIdentityFetched: boolean;
  IdentityVisited: boolean;
};

export interface IGotCoBorrowerIdentity extends Action_<'GetCoBorrowerIdentity'> {
  coBorrowerIdentityState: CoBorrowerIdentityState;
}

export interface ISetCoBorrowerIdentity extends Action_<'SetCoBorrowerIdentity'> {
  coBorrowerIdentityState: CoBorrowerIdentityState;
}

export interface IResetCoBorrowerIdentity extends Action_<'ResetCoBorrowerIdentity'> {
  coBorrowerIdentityState: CoBorrowerIdentityState;
}

export type CoBorrowerIdentityActions = IGotCoBorrowerIdentity | ISetCoBorrowerIdentity | IResetCoBorrowerIdentity;

export const setCoBorrowerIdentityState = (coBorrowerIdentityState: CoBorrowerIdentityState): ISetCoBorrowerIdentity => ({
  type: 'SetCoBorrowerIdentity',
  coBorrowerIdentityState,
});

export const setCoborrowerIdentityResult = (identityHolder: QDEvaluationIdentityResult_): ISetCoBorrowerIdentity => ({
  type: 'SetCoBorrowerIdentity',
  coBorrowerIdentityState: {
    IdentityHolder: identityHolder,
    IsIdentityFetched: true,
    IdentityVisited: false,
  },
});

export const resetCoBorrowerIdentity = (): IResetCoBorrowerIdentity => ({
  type: 'ResetCoBorrowerIdentity',
  coBorrowerIdentityState: {
    IdentityHolder: new QDEvaluationIdentityResult_(),
    IsIdentityFetched: false,
    IdentityVisited: false,
  },
});

const getIdentityActionCreator: ActionCreator_<ThunkAction_<
  Promise<ISetCoBorrowerIdentity>,
  CoBorrowerIdentityState,
  IdentityCheckEvalParam_,
  IGotCoBorrowerIdentity
>> = (pars: IdentityCheckEvalParam_) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const { Result: identityResponse, } = await QDServiceApi_.identityCheckEval(pars);

    dispatch(setLoader(false));

    return dispatch(setCoborrowerIdentityResult(identityResponse));
  };
};

export default getIdentityActionCreator;
