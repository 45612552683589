import React, { useCallback } from 'react';
import { DataPreviewText } from '@jkhy/vsg-design-system';
import { format } from '../../../../utils/Helper';
import Question_ from '../../../../data/models/Question';

type QuestionPresentationProps = {
  question: Question_;
};

const QuestionPresentation = ({ question, }: QuestionPresentationProps) => {
  const getValue = useCallback((q: Question_) => {
    switch (q.AnswerType) {
      case 0:
        return q.AnswerString;
      case 1:
        return q.AnswerBoolean ? 'Yes' : 'No';
      case 2:
        return q.AnswerInteger?.toString();
      case 3:
        return format(q.AnswerDecimal, 'c', 2);
      case 4:
        return format(q.AnswerDate, 'd', 'MM/DD/YYYY');
      case 5:
        return q.AnswerMemo;
      case 6:
        return q.AnswerYear?.toString();
      default:
        return '';
    }
  }, []);

  const value = getValue(question);
  return (
    <>
      <DataPreviewText
        dataUI={`question-text-${question?.Id}-field`}
        className="mb-2"
        bold="label"
        text={value}
        label={question.QuestionText}
        isVisible
      />

      <DataPreviewText
        dataUI={`question-answer-${question?.Id}-field`}
        className="mb-2"
        bold="label"
        text={question.IfYesAnswer}
        label={question.IfYesQuestionText}
        isVisible={question.IfYesFlag && question.AnswerBoolean}
      />
    </>
  );
};

export const transformCustomQuestions = (customQuestions: Question_[]) => {
  const data = {};

  customQuestions.forEach((customQuestion: Question_) => {
    const questionId = customQuestion.Id;
    const customComponent = <QuestionPresentation key={`${questionId}`} question={customQuestion} />;

    data[`${questionId}`] = {
      dataUI: `custom-question-${customQuestion.GUID}`,
      customComponent,
    };
  });

  return data;
};

export default QuestionPresentation;
