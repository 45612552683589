/* eslint-disable no-param-reassign */
/* eslint no-undef: off */
import React, {
  FC as FC_, useState, useEffect, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { IconsLight } from '@jkhy/vsg-design-system';

import HorizontalInfoCardGenerator from '../HorizontalInfoCardGenerator/HorizontalInfoCardGenerator';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import QdApplicationSection_ from '../../../data/models/QDApplicationSection';
import PageFieldHolder_ from '../../../data/models/PageFields/PageFieldHolder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../redux/AppState';
import CollateralSettings from './CollateralSettings';
import ListAPI from '../../../data/api/ListApi';
import CollateralSectionModel_ from '../../../data/models/CollateralSectionModel';
import CollateralModel_ from '../../../data/models/Collateral';
import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import { AddressDetails as AddressDetails_ } from '../../../utils/Types';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import CollateralPageFields_ from '../../../data/models/PageFields/Collateral/Collateral';
import Borrower_ from '../../../data/models/Borrower';
import ListItem from '../../../data/models/ListItem';

export interface CollateralProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, CollateralModel_>[],
    holder: QdApplicationHolder_,
    section: CollateralSectionModel_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

export type AdditionalData_ = {
  owners: Borrower_[],
  CollateralCatPickList: string
}
type qdCollateralType = {
  value: string,
  label: string,
  secondCode: string
}
const Collateral: FC_<CollateralProps> = (props: CollateralProps) => {
  const { holder, sections, pageFieldHolder, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      sections: QdApplicationSection_[];
      pageFieldHolder: PageFieldHolder_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    sections: state.modelState?.ModelHolder?.ApplicationSections,
    pageFieldHolder: state?.modelState?.ModelHolder?.ApplicationPageField,
  }));

  const { IdQDApplication, Collateral: CollateralModel, } = holder;

  const initialCollateralPageFields = pageFieldHolder?.Collateral;

  const { SortOrder: googleAddressFieldSortOrder, ...googleAddressPageField } = initialCollateralPageFields?.CAddress;

  const collateralPageFields: CollateralPageFields_ = {
    ...initialCollateralPageFields,
    CStreetAddress: { ...googleAddressPageField, FieldName: 'CStreetAddress', SortOrder: googleAddressFieldSortOrder + 1, },
    CCity: { ...googleAddressPageField, FieldName: 'CCity', SortOrder: googleAddressFieldSortOrder + 2, },
    CState: { ...googleAddressPageField, FieldName: 'CState', SortOrder: googleAddressFieldSortOrder + 3, },
    CZipCode: { ...googleAddressPageField, FieldName: 'CZipCode', SortOrder: googleAddressFieldSortOrder + 4, },
  };

  const section = sections?.find(s => s.SectionName === ExternalPageSectionName.Collateral);
  const [subSection] = section?.SubSections;

  const collateralSettings = useMemo(() => {
    const {
      Guarantor: { Guarantors, },
      BorrowerP: { Personal, },
      BorrowerB: { Business, },
    } = holder;
    const owners = [...Guarantors, Personal, Business];
    const additionalData: AdditionalData_ = {
      owners,
      CollateralCatPickList: holder.CollateralCatPickList,
    };
    return new CollateralSettings([collateralPageFields], [subSection], additionalData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collateralPageFields, subSection, holder.Guarantor.Guarantors, holder.BorrowerP.Personal, holder.BorrowerB.Business]);

  const [pageFieldSettings, setPageFieldSettings] = useState(collateralSettings);

  const findCollateralCategoryByCode = (code: string) => {
    const qdCollateralCatListItems: ListItem[] = JSON.parse(sessionStorage.getItem(`LtItems-${holder.CollateralCatPickList}`) || '[]');
    return qdCollateralCatListItems.find(x => x.Code === code);
  };
  const qdCategoryTypesMap = (): qdCollateralType[] => {
    const allTypes: ListItem[] = JSON.parse(sessionStorage.getItem(`LtItems-${holder.CollateralTypePickList}`) || '[]');
    return allTypes.map(item => ({
      value: item.Code,
      label: item.Description,
      secondCode: item.SecondCode,
    }))
  };

  const setQDCollateralTypesData = (categoryCode?: string) => {
    const collateralCatObject = findCollateralCategoryByCode(categoryCode || ''); 
    const mappedTypes = qdCategoryTypesMap();
    pageFieldSettings.PageFields = pageFieldSettings.PageFields.map(pf => {
      const pageF = pf;
      if (pageF.FieldName === 'Type') {
        const filterResult = collateralCatObject ? mappedTypes?.filter(item => item.secondCode === collateralCatObject?.Code) : mappedTypes;
        pageF.Component.options.initialData = filterResult;
      }
      return pageF;
    });

    setPageFieldSettings(pageFieldSettings);
  };

  const fetchQDCollateralTypes = async () => {
    const { CollateralTypePickList } = holder;
    const pars = {
      listName: CollateralTypePickList,
      sortColumn: '',
      sortOrder: 'ASC',
    };
    await ListAPI.getLtItemsSearch(pars);
    setQDCollateralTypesData();
  };

  useEffect(() => {
      fetchQDCollateralTypes();
  }, []);

  const updateCollaterals = (entity: CollateralModel_, e: any, pageField: PageFieldExtended_<QdApplicationHolder_, CollateralModel_>) => {
    const { value, } = e;
    const { ObjectProperty, ObjectPropertyStr, } = pageField;

    if (ObjectProperty === 'CategoryCode') {
      const category = findCollateralCategoryByCode(value);
      entity[`${ObjectProperty}SecondCode`] = category?.SecondCode ?? '';
      entity[ObjectPropertyStr] = category?.Descrip;

      const selectedTypeCode = entity.TypeCode;
      if (selectedTypeCode) {
        const collateralType = qdCategoryTypesMap().find(item => item.value === selectedTypeCode);
        const resetTypeCode = value !== collateralType?.secondCode;
        if (resetTypeCode) {
          entity.TypeCode = '';
          entity.TypeCodeStr = '';
        }
      }

      // filter the CollateralTypes by the chosen CategoryCode
      setQDCollateralTypesData(entity.CategoryCode);
    }
  };

  const onEdit = (entity: CollateralModel_) => {
    if (entity.CategoryCode) {
      setQDCollateralTypesData(entity.CategoryCode);
    }
  };

  const updateAdressFields = (entity: CollateralModel_, addressDetails: AddressDetails_, pageField: PageFieldExtended_<QdApplicationHolder_, CollateralModel_>) => {
    const newEntity: CollateralModel_ = { ...entity, };
    const { ObjectProperty, } = pageField;
    if (addressDetails) {
      const {
        fullAddress, streetAddress, city, zipCode, state,
      } = addressDetails;

      newEntity[ObjectProperty] = fullAddress;
      newEntity.Street = streetAddress;
      newEntity.City = city;
      newEntity.Zip = zipCode;
      newEntity.State = state;
    }

    return newEntity;
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, CollateralModel_>[], data: CollateralModel_[]) => {
    const collateralModelModified = { ...CollateralModel, Collaterals: data, };
    props.onSubmit(invalidPageFields, holder, collateralModelModified, ExternalPages.Collateral, false);
  };

  const onCollateralSave = async (collateral: CollateralModel_) => QdServiceApi.saveQDCollateral(collateral);
  const onCollateralRemove = async (id: BigInt) => QdServiceApi.removeQDCollateral(id);
  const getSaveHolderAction = (collaterals: CollateralModel_[]): ISaveHolder_ => {
    const collateralModelModified = {
      ...CollateralModel,
      Collaterals: collaterals,
    };
    return {
      holderState: {
        Holder: { ...holder, Collateral: collateralModelModified, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };
  };

  const newCollateral = () => {
    const newData: CollateralModel_ = {
      Id: BigInt(0),
      GUID: '',
      CategoryCode: '',
      CategoryCodeStr: '',
      CategoryCodeSecondCode: '',
      CategoryCodeFourthCode: '',
      TypeCode: '',
      TypeCodeStr: '',
      TypeCodeThirdCode: '',
      TypeCodeFourthCode: '',
      Description: '',
      CurrentLoanAmount: null,
      MarketValue: null,
      Address: '',
      Street: '',
      City: '',
      State: '',
      Zip: '',
      IdOwner: null,
      OwnerStr: '',
      IdCompany: BigInt(0),
      IdQDApplication,
      IdUserLastModified: BigInt(0),
      OwnerOccupiedFlag: null,
      TreatAllNulls: false,
      NullFields: [],
    };
    return newData;
  };

  return (
    <>
      <HorizontalInfoCardGenerator
        formIdentifier={`form-${ExternalPages.Collateral}`}
        onChangeSelect={updateCollaterals}
        onSelectAddress={updateAdressFields}
        onEdit={onEdit}
        onSubmit={onSubmit}
        onSave={onCollateralSave}
        onRemove={onCollateralRemove}
        getSaveHolderAction={getSaveHolderAction}
        pageSettings={pageFieldSettings}
        holder={holder}
        subHolder={CollateralModel}
        addButtonLabel="Add Collateral"
        icon={IconsLight.faClipboardList}
        title="CategoryCodeStr"
        newObject={newCollateral()}
        list={[...CollateralModel.Collaterals]}
      />
    </>
  );
};

export default Collateral;
