import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partition } from 'lodash';
import {
  Section, Textarea, FormGroup, Radio
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { setLoader } from '../../../../../redux/actions/Loading';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerDeclarationsSettings from './CoBorrowerDeclarationsSettings';
import QuestionTemplate from '../../../../../components/Question/QuestionTemplate';
import { validateQuestionAndSetValidationMessage } from '../../../../../components/Question/QuestionValidate';
import {
  GuarantorPageSectionName_, GuarantorPages, ValueConstants, PartyType
} from '../../../../../utils/Enums';
import {
  booleanStringComparison, errorFoundMessage, getHoverHelpPageFieldValue, scrollToError, isFieldValid
} from '../../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationDeclarations as QDCoBorrowerApplicationDeclarations_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Question_ from '../../../../../data/models/Question';
import Section_ from '../../../../../data/models/Section';
import SubSection_ from '../../../../../data/models/Subsection';
import PageField_ from '../../../../../data/models/PageField';

type CoBorrowerDeclarationsProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationDeclarations_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationDeclarations_,
    sequence: string
  ) => void;
};

const CoBorrowerDeclarations = (props: CoBorrowerDeclarationsProps_) => {
  const {
    modelState, sectionsState, loading, holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const dispatch = useDispatch();

  const [declarations, setDeclarations] = useState({ ...holder?.Declarations, });
  const [invalidSetOfPageFields, setInvalidSetOfPageFields] = useState([]);
  const section = sectionsState?.VisibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.Declarations);
  const subSection = section?.SubSections?.find((ss: SubSection_) => ss.Code === ValueConstants.DeclarationsSubSection);

  const { CoBorrowerPageField: pageFieldHolder, } = modelState?.ModelHolder;

  const borrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;

  const RegOResponseMemoPageField = {
    ...new PageField_(),
    FieldName: 'RegOResponseMemo',
    Label: 'If yes, please explain: ',
  };

  const pageFields = {
    ...pageFieldHolder?.Declarations,
    RegOResponseMemo: RegOResponseMemoPageField,
  };

  const pageSettings = useMemo(() => {
    const isBusinessBorrower = borrower.PartyType === PartyType.Business;

    return new CoBorrowerDeclarationsSettings([pageFields], [subSection], isBusinessBorrower);
  }, [pageFields, subSection, borrower.PartyType]);

  const ESignConsentPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === 'ESignConsent');
  const RegOQuestionPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === 'RegOQuestion');
  const RegOResponseMemoPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === 'RegOResponseMemo');

  const onChangeQuestion = (value: string | number | boolean, propertyName: string, id: bigint) => {
    const { Questions = [], } = declarations;

    const newQuestions = Questions.map((question: Question_) => (question?.Id === id ? { ...question, [propertyName]: value, } : question));

    setDeclarations({
      ...declarations,
      Questions: newQuestions,
    });
  };

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationDeclarations_>
  ) => {
    const { value, } = e.target;
    const { ObjectProperty, ObjectPropertyType, } = pageField;

    const newDeclarations = {
      ...declarations,
    };

    newDeclarations[ObjectProperty] = ObjectPropertyType === 'boolean' ? booleanStringComparison(value) : value;

    setDeclarations(newDeclarations);
  };

  const validate = () => {
    let isDeclarationQuestionsValid = true;
    let isPageFieldsValid = true;

    const [invalidPageFields] = partition(pageSettings.PageFields, pf => !pf.IsValid(holder, declarations, pf) && pf.Component !== null);
    setInvalidSetOfPageFields(invalidPageFields);

    isPageFieldsValid = invalidPageFields?.length === 0;

    // Custom Questions
    if (declarations?.Questions?.length > 0) {
      const validatedDeclarationQuestions = declarations?.Questions?.map((question: Question_) => {
        const { IsValid, ValidationMessage: validationMessage, } = validateQuestionAndSetValidationMessage(question);

        return {
          ...question,
          IsValid,
          ValidationMessage: validationMessage,
        };
      });

      setDeclarations({ ...declarations, Questions: validatedDeclarationQuestions, });

      isDeclarationQuestionsValid = validatedDeclarationQuestions.every((question: Question_) => question.IsValid);
    }

    return {
      isValid: isDeclarationQuestionsValid && isPageFieldsValid,
      invalidPageFields,
    };
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { isValid, invalidPageFields, } = validate();

    if (!isValid) {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);

      return;
    }

    const newDeclarations = {
      ...declarations,
    };

    // Reset response memo
    if (!booleanStringComparison(newDeclarations?.RegOResponse) && newDeclarations?.RegOResponseMemo) {
      newDeclarations.RegOResponseMemo = null;
      setDeclarations(newDeclarations);
    }

    props.onSubmit(invalidPageFields, holder, newDeclarations, GuarantorPages.Declarations);
  };

  useEffect(() => {
    const getCustomQuestions = async () => {
      dispatch(setLoader(true));

      const { GUID, Id, } = borrower;

      const { Result: questions, } = await QdServiceApi.qdApplicationEvaluatedQuestions(null, GUID);

      const questionForGuarantor = questions
        ?.filter((question: Question_) => {
          return (
            question?.ApplicableForGuarantor
            && question.IdQDParty === Id
            && !question.IsHidden
            && question?.SectionName === GuarantorPageSectionName_.Declarations
          );
        })
        ?.map((question: Question_) => ({ ...question, NullFields: [], }));

      setDeclarations({ ...declarations, Questions: questionForGuarantor, });

      dispatch(setLoader(false));
    };

    getCustomQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.Declarations}`} onSubmit={onSubmit} noValidate>
          <Section
            dataUI="guarantor-declarations-section"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
          >
            {declarations?.Questions?.length > 0
              && declarations.Questions.map((question: Question_) => {
                return <QuestionTemplate key={`${question.Id}`} question={question} onChange={onChangeQuestion} />;
              })}

            {/* Only for personal co-borrower */}
            {holder?.Personal?.CoBorrower?.Id && (
              <>
                <FormGroup
                  className="mb-2"
                  checkboxOrRadio
                  dataUI={RegOQuestionPageFieldExtended?.dataUI}
                  label={RegOQuestionPageFieldExtended?.Label}
                  isRequired={RegOQuestionPageFieldExtended?.Required}
                  isBold={RegOQuestionPageFieldExtended?.IsBold}
                  isHidden={RegOQuestionPageFieldExtended?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(RegOQuestionPageFieldExtended)}
                  isValid={isFieldValid(RegOQuestionPageFieldExtended?.FieldName, invalidSetOfPageFields)}
                  validationMessage={RegOQuestionPageFieldExtended?.ValidationMessage}
                >
                  {RegOQuestionPageFieldExtended?.Component?.options?.map(option => {
                    const { dataUI, value, label, } = option;
                    const key = `${RegOQuestionPageFieldExtended?.FieldName}-${value}`;

                    return (
                      <Radio
                        key={key}
                        dataUI={dataUI}
                        className="d-inline-block"
                        name={RegOQuestionPageFieldExtended?.ObjectProperty}
                        htmlFor={key}
                        id={key}
                        value={value}
                        checked={declarations.RegOResponse === booleanStringComparison(value)}
                        onChange={e => onChange(e, RegOQuestionPageFieldExtended)}
                      >
                        {label}
                      </Radio>
                    );
                  })}
                </FormGroup>

                {booleanStringComparison(declarations.RegOResponse) && (
                  <FormGroup className="mb-2" label={RegOResponseMemoPageFieldExtended.Label} dataUI={RegOResponseMemoPageFieldExtended.dataUI}>
                    <Textarea
                      value={declarations.RegOResponseMemo}
                      rows={4}
                      onChange={e => onChange(e, RegOResponseMemoPageFieldExtended)}
                      dataUI={RegOResponseMemoPageFieldExtended?.Component?.dataUI}
                    />
                  </FormGroup>
                )}
              </>
            )}
            {/* END: Only for personal co-borrower */}

            <FormGroup
              className="mb-2"
              checkboxOrRadio
              dataUI={ESignConsentPageFieldExtended?.dataUI}
              label={ESignConsentPageFieldExtended?.Label}
              isRequired={ESignConsentPageFieldExtended?.Required}
              isBold={ESignConsentPageFieldExtended?.IsBold}
              isHidden={ESignConsentPageFieldExtended?.IsHidden}
              hoverHelp={getHoverHelpPageFieldValue(ESignConsentPageFieldExtended)}
              isValid={isFieldValid(ESignConsentPageFieldExtended?.FieldName, invalidSetOfPageFields)}
              validationMessage={errorFoundMessage(ESignConsentPageFieldExtended?.FieldName, invalidSetOfPageFields)}
            >
              {ESignConsentPageFieldExtended?.Component?.options?.map(option => {
                const { dataUI, value, label, } = option;
                const key = `${ESignConsentPageFieldExtended?.FieldName}-${value}`;

                return (
                  <Radio
                    key={key}
                    dataUI={dataUI}
                    className="d-inline-block"
                    name={ESignConsentPageFieldExtended?.ObjectProperty}
                    htmlFor={key}
                    id={key}
                    value={value}
                    checked={declarations.ESignConsent === booleanStringComparison(value)}
                    onChange={e => onChange(e, ESignConsentPageFieldExtended)}
                  >
                    {label}
                  </Radio>
                );
              })}
            </FormGroup>
          </Section>
        </form>
      )}
    </>
  );
};

export default CoBorrowerDeclarations;
