import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';

import _ from 'lodash';
import Section_ from '../../data/models/Section';
import QdServiceApi_ from '../../data/api/QDServiceApi';
import { setLoader } from './Loading';
import { filterSections } from '../../utils/Helper';

export type EvalSectionState = {
  VisibleSections: Section_[];
  CoBorrowerAddNowVisibleSections: Section_[];
  IsVisibleSectionsFetched: boolean;
  IsVisibleSectionsFetching: boolean;
  IsReviewed: boolean;
};

export interface IEvalSection extends Action_<'EvalSection'> {
  evalSectionState: EvalSectionState;
}

export type EvalSectionActions = IEvalSection;

export type InputEvalParameters = {
  IdQDApplication: bigint;
  SectionName?: string;
  QDPartyGUID?: string;
};

const getEvalSectionActionCreator: ActionCreator_<ThunkAction_<Promise<IEvalSection>, Section_[], InputEvalParameters, IEvalSection>> = (
  allSections: Section_[],
  params: InputEvalParameters
) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const evalFetchingAction: IEvalSection = {
      type: 'EvalSection',
      evalSectionState: {
        VisibleSections: [],
        CoBorrowerAddNowVisibleSections: [],
        IsVisibleSectionsFetching: true,
        IsReviewed: false,
        IsVisibleSectionsFetched: false,
      },
    };
    dispatch(evalFetchingAction);

    const { Result: evalResult, } = await QdServiceApi_.evaluate(params.IdQDApplication, params.SectionName, null, params.QDPartyGUID);

    const visibleSections = filterSections('IdQDSectionDefinition', allSections, evalResult.VisibleSections);
    const evalAction: IEvalSection = {
      type: 'EvalSection',
      evalSectionState: {
        VisibleSections: visibleSections,
        CoBorrowerAddNowVisibleSections: params.QDPartyGUID ? visibleSections : [],
        IsVisibleSectionsFetched: true,
        IsReviewed: !!visibleSections.find(vs => vs.Sequence === '10'),
        IsVisibleSectionsFetching: false,
      },
    };

    dispatch(setLoader(false));

    return dispatch(evalAction);
  };
};

export default getEvalSectionActionCreator;

export const updateVisibleSections = (visibleSections: Section_[], isReviewed: boolean, coBorrowerAddNowSections?: Section_[]): IEvalSection => {
  return {
    type: 'EvalSection',
    evalSectionState: {
      VisibleSections: visibleSections,
      CoBorrowerAddNowVisibleSections: coBorrowerAddNowSections,
      IsVisibleSectionsFetching: false,
      IsReviewed: isReviewed,
      IsVisibleSectionsFetched: true,
    },
  };
};
