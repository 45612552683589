import * as React from 'react';
import { createPortal } from 'react-dom';

import { Loader } from '@jkhy/vsg-design-system';

import './Loading.css';

export interface LoadingPanelProps {
  element?: string;
  dataUI?: string;
}

export default class LoadingPanel extends React.PureComponent<LoadingPanelProps> {
  render() {
    const { element, dataUI, } = this.props;
    const el = element || 'app';

    const loadingPanel = (
      <div className="loader-wrapper">
        <Loader dataUI={dataUI} />
      </div>
    );

    const gridContent = document && document.getElementById(el);
    return gridContent ? createPortal(loadingPanel, gridContent) : loadingPanel;
  }
}
