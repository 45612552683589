/* eslint-disable import/prefer-default-export */
import Borrower_ from '../data/models/Borrower';
import { CoBorrowerAddType, PartyType } from './Enums';

export const getAddNowGuarantors = (borrowers: Borrower_[]) => {
  return borrowers.filter((borrower: Borrower_) => borrower.CoBorrowerAddType === CoBorrowerAddType.AddNow);
};

export const isBusinessParty = (party: Borrower_): boolean => party?.PartyType === PartyType.Business;

export const isAddNowGuarantor = (g: Borrower_) => g?.CoBorrowerAddType === CoBorrowerAddType.AddNow;
