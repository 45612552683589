import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import QdApplicationHolder_ from '../../data/models/QDApplicationHolder';
import QDServiceApi from '../../data/api/QDServiceApi';
import { setLoader } from './Loading';
import { ExternalPages } from '../../utils/Enums';
import QDCoBorrowerApplicationHolder_ from '../../data/models/QDCoBorrowerApplicationHolder';
import { updateCoBorrowerHolder, IGotCoBorrowerHolder as IGotCoBorrowerHolder_ } from './CoBorrowerHolder';

export type HolderState = {
  IsHolderFetched: boolean;
  Holder: QdApplicationHolder_;
};

export interface IGotHolder extends Action_<'GotHolder'> {
  holderState: HolderState;
}

export interface ISaveHolder extends Action_<'SaveHolder'> {
  holderState: HolderState;
}

export type IgnoreChangeHolder = Action_<'IgnoreChange'>;

export type HolderActions = IGotHolder | ISaveHolder | IgnoreChangeHolder;

export type InputHolderParameters = {
  guid: string;
  partyGUID?: string;
  email: string;
  model: string;
};

// ToDo: check if is used and remove if not used
const getHolderActionCreator: ActionCreator_<ThunkAction_<
  Promise<IGotHolder | IGotCoBorrowerHolder_>,
  QdApplicationHolder_,
  InputHolderParameters,
  IGotHolder
>> = (params: InputHolderParameters) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const isCoBorrower = params.partyGUID;

    const { Result: holder, } = isCoBorrower
      ? await QDServiceApi.getQDApplicationHolderGuarantorUID(params.partyGUID, params.email, params.model)
      : await QDServiceApi.getQDApplicationHolder(params.guid, params.email, params.model);

    dispatch(setLoader(false));

    if (isCoBorrower) {
      const action = updateCoBorrowerHolder(holder as QDCoBorrowerApplicationHolder_);

      return dispatch(action);
    }

    const gotHolderAction: IGotHolder = {
      holderState: {
        Holder: holder as QdApplicationHolder_,
        IsHolderFetched: true,
      },
      type: 'GotHolder',
    };

    return dispatch(gotHolderAction);
  };
};

export default getHolderActionCreator;

export const updateExternalHolder = (result: QdApplicationHolder_): IGotHolder => ({
  type: 'GotHolder',
  holderState: {
    Holder: result,
    IsHolderFetched: true,
  },
});

export const getHolderWithoutResetingInformation: ActionCreator_<ThunkAction_<
  Promise<IGotHolder | IGotCoBorrowerHolder_>,
  QdApplicationHolder_,
  InputHolderParameters,
  IGotHolder
>> = (params: InputHolderParameters, currentHolder: QdApplicationHolder_ | QDCoBorrowerApplicationHolder_) => {
  return async (dispatch: Dispatch_) => {
    dispatch(setLoader(true));

    const isCoBorrower = params.partyGUID;

    const { Result: holder, } = isCoBorrower
      ? await QDServiceApi.getQDApplicationHolderGuarantorUID(params.partyGUID, params.email, params.model)
      : await QDServiceApi.getQDApplicationHolder(params.guid, params.email, params.model);

    dispatch(setLoader(false));

    if (isCoBorrower) {
      const currentCoBorrowerHolder = currentHolder as QDCoBorrowerApplicationHolder_;
      const coBorrowerHolder = holder as QDCoBorrowerApplicationHolder_;

      const modifiedHolder = { ...coBorrowerHolder, Information: currentCoBorrowerHolder.Information, };
      const action = updateCoBorrowerHolder(modifiedHolder);

      return dispatch(action);
    }

    const currentApplicationHolder = currentHolder as QdApplicationHolder_;
    const modifiedHolder = { ...holder, Information: currentApplicationHolder?.Information, } as QdApplicationHolder_;
    const gotHolderAction: IGotHolder = updateExternalHolder(modifiedHolder);

    return dispatch(gotHolderAction);
  };
};

export const buildSaveHolderAction = (holder: QdApplicationHolder_): ISaveHolder => {
  return {
    holderState: {
      Holder: holder,
      IsHolderFetched: true,
    },
    type: 'SaveHolder',
  };
};

export const updateHolderFromSaveResponse = (
  Sequence: number | string,
  holder: QdApplicationHolder_,
  responseResult
): ISaveHolder | IgnoreChangeHolder => {
  const resetNullFields = list => (list || []).map(l => {
    const item = l;
    item.NullFields.length = 0;
    return item;
  });

  let action: ISaveHolder | IgnoreChangeHolder;

  switch (`${Sequence}`) {
    case ExternalPages.PersonalBorrower:
      {
        const PersonalResponse = {
          ...responseResult.Personal,
          PartyMatchDone: true,
          NullFields: []
        }
        const borrowerP = { ...holder.BorrowerP, Personal: PersonalResponse };
        const ownerCheck = { ...holder.OwnerCheck, Personal: PersonalResponse };
        action = {
          holderState: {
            Holder: {
              ...holder,
              OwnerCheck: ownerCheck,
              BorrowerP: borrowerP,
            },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    /* NO need to update store. Check page logic.
    case ExternalPages.PersonalAddresses: {
    } break;
    */
    case ExternalPages.BusinessAddresses:
      {
        const businessAddressesPage = {
          ...holder.AddressesB,
          BusinessAddresses: resetNullFields(responseResult?.BusinessAddresses),
        };
        action = {
          holderState: {
            Holder: { ...holder, AddressesB: businessAddressesPage, },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };

        if (responseResult?.Business) {
          // Refresh Mellisa Naics check flag
          const business = { ...holder.BorrowerB.Business, SuccessfulNAICSCheckFlag: responseResult?.Business?.SuccessfulNAICSCheckFlag, };
          const borrower = { ...holder.BorrowerB, Business: business, };

          action = {
            holderState: {
              Holder: {
                ...holder,
                BorrowerB: borrower,
                AddressesB: businessAddressesPage,
              },
              IsHolderFetched: true,
            },
            type: 'SaveHolder',
          };
        }
      }
      break;
    // TDOD: Can test and remove
    case ExternalPages.BusinessReference:
      {
        const businessReferencePage = {
          ...holder.References,
          References: resetNullFields(responseResult?.References),
        };
        action = {
          holderState: {
            Holder: { ...holder, References: businessReferencePage, },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    case ExternalPages.CoBorrower:
      {
        const guarantorPage = {
          ...holder.Guarantor,
          Guarantors: resetNullFields(responseResult?.Guarantors),
        };
        action = {
          holderState: {
            Holder: { ...holder, Guarantor: guarantorPage, },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    // TDOD: Can test and remove
    case ExternalPages.BusinessObligations:
      {
        const debtsPage = {
          ...holder.Debts,
          Debts: resetNullFields(responseResult?.Debts),
        };
        action = {
          holderState: {
            Holder: { ...holder, Debts: debtsPage, },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    // TDOD: Can test and remove
    case ExternalPages.BusinessDeposits:
      {
        const depositsPage = {
          ...holder.Deposits,
          Deposits: resetNullFields(responseResult?.Deposits),
        };
        action = {
          holderState: {
            Holder: { ...holder, Deposits: depositsPage, },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    case ExternalPages.PersonalPartyMatch:
      {
        const personal = responseResult?.Personal;
        if (personal) {
          personal.NullFields.length = 0;
        }
        const ownerCheck = { ...holder.OwnerCheck, Personal: personal, };
        const personalBorrower = { ...holder.BorrowerP, Personal: personal, };
        const personalAddress = { ...holder.AddressesP, PersonalAddresses: resetNullFields(responseResult?.PersonalAddresses), };
        action = {
          holderState: {
            Holder: {
              ...holder,
              OwnerCheck: ownerCheck,
              BorrowerP: personalBorrower,
              AddressesP: personalAddress,
            },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
    case ExternalPages.BusinessPartyMatch:
      {
        const business = responseResult?.Business;
        if (business) {
          business.NullFields.length = 0;
        }
        const borrowerCheck = { ...holder.BorrowerCheck, Business: business, };
        const borrower = { ...holder.BorrowerB, Business: business, };
        const businessAddress = { ...holder.AddressesB, BusinessAddresses: resetNullFields(responseResult?.BusinessAddresses), };
        action = {
          holderState: {
            Holder: {
              ...holder,
              BorrowerCheck: borrowerCheck,
              BorrowerB: borrower,
              AddressesB: businessAddress,
            },
            IsHolderFetched: true,
          },
          type: 'SaveHolder',
        };
      }
      break;
      case ExternalPages.Employment:
        {
          const employment = responseResult?.Employment;
          if (employment) {
            employment.NullFields.length = 0;
          }
          const incomeAndExpensesPage = {
            ...holder.IncomeExpences,
            Employment: employment,
          };
          action = {
            holderState: {
              Holder: { ...holder, IncomeExpences: incomeAndExpensesPage, },
              IsHolderFetched: true,
            },
            type: 'SaveHolder',
          };
        }
        break;
    default:
      action = {
        type: 'IgnoreChange',
      };
      break;
  }

  return action;
};
