import React, { FC as FC_, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { currencyFormat, dateFormat, percentageFormat } from '../../../../utils/Helper';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import QdDebt_ from '../../../../data/models/QDDebt';

type BusinessObligationsProps = ReviewApplicationSectionProps_;

const BusinessObligations: FC_<BusinessObligationsProps> = ({
  page, sectionTitle, expandState, setExpandState,
}: BusinessObligationsProps) => {
  const { modelState, holder, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const debtsPageFields = modelState?.ModelHolder?.ApplicationPageField?.BusinessObligations?.General;
  const debts = holder?.Debts?.Debts;

  const rows: CustomRow_[] = useMemo(() => {
    return debts?.map((debt: QdDebt_) => {
      const debtId = debt.Id;

      return {
        id: `review-debts-${debtId}-collapse`,
        title: `${debt.DebtTypeEnumStr}: `,
        subTitle: debt.Creditor,
        pageFields: debtsPageFields,
        link: { page, text: 'Go to business obligations step to edit', },
        data: {
          Creditor: {
            dataUI: `debt-creditor-${debtId}-field`,
            value: debt.Creditor,
          },
          LoanType: {
            dataUI: `debt-type-${debtId}-field`,
            value: debt.DebtTypeEnumStr,
          },
          CurrentBalance: {
            dataUI: `debt-current-balance-${debtId}-field`,
            value: currencyFormat(debt.CurrentBalance),
          },
          MonthlyPayment: {
            dataUI: `debt-montly-payment-${debtId}-field`,
            value: currencyFormat(debt.MonthlyPayment),
          },
          InterestRate: {
            dataUI: `debt-interest-rate-${debtId}-field`,
            value: percentageFormat(debt.InterestRate, 3),
          },
          MaturityDate: {
            dataUI: `debt-maturity-date-${debtId}-field`,
            value: dateFormat(debt.MaturityDate),
          },
          Collateral: {
            dataUI: `debt-collateral-${debtId}-field`,
            value: debt.CollateralDescription,
          },
        },
      };
    });
  }, [page, debts, debtsPageFields]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessObligations);
