import axios, { AxiosPromise as AxiosPromise_ } from 'axios';
import BaseAPI from './BaseApi';
import QdApplicationSection_ from '../models/QDApplicationSection';
import SubSection_ from '../models/Subsection';
import PageField_, { QuickDecisionPageTypes as PageTypes_ } from '../models/PageField';
import QDDocumentsResult_ from '../models/QDDocumentsResult';
import QdApplicationModel_ from '../models/QDApplicationModel';

export default class ModelAPI extends BaseAPI {
  static getModel = (id: number): AxiosPromise_ => {
    return axios({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionRenewalApplication/InquireRenewalModel/${id}`,
      headers: BaseAPI.getRequestHeaders(),
      transformResponse: [BaseAPI.transformResponse],
    });
  };

  static getSections = (code: string) => BaseAPI.performAjax<QdApplicationSection_>({
    url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/QDApplicationSectionWithSubSectionsList?code=${code}`,
  });

  // This needs to stay because it breaks the silent login
  static getApplicationModel = (code: string): AxiosPromise_<QdApplicationModel_> => {
    return axios({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/QDApplicationModelInquire?code=${code}`,
      headers: BaseAPI.getRequestHeaders(),
      transformResponse: [BaseAPI.transformResponse],
    });
  };

  static getApplicationSections = (code: string) => {
    return BaseAPI.performAjax<QdApplicationSection_[]>({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/SectionList?code=${code}`,
    });
  };

  static getApplicationSubSections = (code: string) => {
    return BaseAPI.performAjax<SubSection_[]>({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/SubSectionList?code=${code}`,
    });
  };

  static getApplicationPageFields = (idPageFieldTemplate: bigint, pageType: PageTypes_) => {
    return BaseAPI.performAjax<PageField_[]>({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/PageFieldList?idPageFieldTemplate=${idPageFieldTemplate}&pageType=${pageType}`,
    });
  };

  static externalInquireDocumentData = (guid: string) => {
    return BaseAPI.performAjax<QDDocumentsResult_>({
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApplicationModel/GetDocumentUploadHtml?qdApplicationGuid=${guid}`,
    });
  };

  static getInstanceId = () => {
    return BaseAPI.performAjax<string>({
      method: 'GET',
      url: `${(window as any).CONFIG.Registrar.RegistrarURL}/api/instance/inquire?url=${(window as any).BASE_URL || (window as any).DOMAIN_URL}`,
    });
  };
}
