/* eslint no-undef: off */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch as ThunkDispatch_ } from 'redux-thunk';
import BigInt from 'bigint-polyfill';
import { withRouter } from 'react-router-dom';
import { ReactAux, Toast } from '@jkhy/vsg-design-system';
import { AnyAction as AnyAction_ } from 'redux';
import Base_ from '../Layout/BaseComponent';
import ModelApi from '../../data/api/ModelApi';
import { AppState as AppState_ } from '../../redux/AppState';
import {
  HolderState as HolderState_,
  IGotHolder as IGotHolder_,
  ISaveHolder as ISaveHolder_,
  IgnoreChangeHolder as IgnoreChangeHolder_,
  InputHolderParameters as InputHolderParameters_,
  updateExternalHolder,
  updateHolderFromSaveResponse,
  getHolderWithoutResetingInformation
} from '../../redux/actions/Holder';

import { evalProcessActionCreator } from '../../redux/actions/SaveProcess';
import {
  updateVisibleSections,
  IEvalSection as IEvalSection_,
  InputEvalParameters as InputEvalParameters_
} from '../../redux/actions/VisibleSections';
import { IdentityState as IdentityState_, setIdentityState as setIdentityStateAction } from '../../redux/actions/Identity';
import { setLoader, setProgress } from '../../redux/actions/Loading';
import { updateStopApplicationCondition as updateStopApplicationConditionAction } from '../../redux/actions/AppSettings';
import { SubmitButtonState as SubmitButtonState_, updateButton } from '../../redux/actions/SubmitButton';
import QdApplicationHolder_ from '../../data/models/QDApplicationHolder';
import Section_ from '../../data/models/Section';
import ExternalSection from './ExternalSection/ExternalSection';
import ExternalComplete from './ExternalComplete/ExternalComplete';
import QDCompletePars_ from '../../data/models/QDCompletePars';
import {
  setGuid, setModel, getQD, setQD, getGuid
} from '../../utils/LocalStorageManager';
import {
  ExternalPages,
  ApplicationStatus,
  ValueConstants,
  IdentityVerificationSystem,
  ExternalPageSectionName,
  CoBorrowerAddType,
  SameAddressType,
  IdentityCheckStatus,
  PartyType,
  GuarantorPages,
  GuarantorPageSectionName_
} from '../../utils/Enums';
import {
  ExternalState as ExternalState_,
  ExternalModelHolder as ExternalModelHolder_,
  ModelState as ModelState_,
  RoutingProps as RoutingProps_,
  BaseType as BaseType_,
  UpdateObject as UpdateObject_,
  InputSaveParameters as InputSaveParameters_
} from '../../utils/Types';
import QdServiceApi from '../../data/api/QDServiceApi';
// eslint-disable-next-line no-unused-vars
import CoBorrowerAddNowHolder from '../../data/models/CoBorrowerAddNowHolder';
import CoBorrowerPars_ from '../../data/models/CoBorrowerPars';
import getCoBorrowerActionCreator, {
  setCoBorrowerAddNowState,
  CoBorrowerAddNowState as CoBorrowerAddNowState_,
  ISetCoBorrowerAddNowState as ISetCoBorrowerAddNowState_
} from '../../redux/actions/CoBorrowerAddNow';
import Borrower_ from '../../data/models/Borrower';
import {
  setCoborrowerIdentityResult,
  CoBorrowerIdentityState as CoBorrowerIdentityState_,
  setCoBorrowerIdentityState as setCoBorrowerIdentityStateAction
} from '../../redux/actions/CoBorrowerIdentity';
import QDEvaluationIdentityResult_ from '../../data/models/QDEvaluationIdentityResult';
import QDAddress_ from '../../data/models/QDAddress';
import {
  checkIsReviewPageVisible, filterSections, sortVisibleSections, shouldUseHolderForEval
} from '../../utils/Helper';
import { QDApplicationTypeAnswer } from '../../data/models/Information';

interface IProps {
  holderState: HolderState_;
  model?: ModelState_<ExternalModelHolder_>;
  identityState?: IdentityState_;
  coBorrowerAddNowState?: CoBorrowerAddNowState_;
  setProgress: (progress: boolean) => void;
  updateExternalHolder: (app: QdApplicationHolder_) => IGotHolder_;
  updateHolderFromSave: (action: ISaveHolder_ | IgnoreChangeHolder_) => void;
  saveEvalSection?: (params: InputSaveParameters_) => Promise<number>;
  getVisibleSections?: (allSections: Section_[], params: InputEvalParameters_) => Promise<IEvalSection_>;
  updateSubmitButton?: (update: SubmitButtonState_) => void;
  updateCoBorrowerAddNowHolder?: (coBorroweAddNow: CoBorrowerAddNowHolder, isActive: boolean) => ISetCoBorrowerAddNowState_;
  updateIdentityResult?: (identityResponse: QDEvaluationIdentityResult_) => void;
  getHolder?: (params: InputHolderParameters_) => Promise<IGotHolder_>;
  getHolderWithoutOverridingInformation?: (params: InputHolderParameters_, currentHolder: QdApplicationHolder_) => Promise<IGotHolder_>;
  getCoBorrowerAddNowHolder: (pars: CoBorrowerPars_) => Promise<ISetCoBorrowerAddNowState_>;
  setIdentityState: (identityState: IdentityState_) => void;
  setCoBorrowerIdentityState: (identityState: CoBorrowerIdentityState_) => void;
  updateStopApplicationCondition: (isApplicationStop: boolean) => void;
  updateVisibleSections_: (sections: Section_[], isRevieweVisible: boolean, coBorrowerAddNowSections?: Section_[]) => void;
}

export type ExternalProps = IProps & BaseType_;

class External extends Base_<ExternalProps, ExternalState_> {
  constructor(props) {
    super(props);
    this.state = {
      dataLoaded: false,
      step: 1,
      showDocumentPage: false,
      showStatusPage: false,
    };
  }

  async componentDidMount() {
    const {
      match: {
        params: { guid, model, },
      },
    } = this.props;

    await this.storeSessionKeys(model, guid);
  }

  async componentDidUpdate() {
    const {
      holderState: { IsHolderFetched: isHolderFetched, Holder: holder, },
      modelState: { IsModelHolderFetched: isModelHolderFetched, },
      sectionsState: { VisibleSections: currentVisibleSections, },
    } = this.props;

    if (isModelHolderFetched && isHolderFetched && !this.state.dataLoaded) {
      const guidSessionKey = getGuid();
      if (!!holder?.GUIDQDApplication && (!guidSessionKey || guidSessionKey === 'undefined')) {
        setGuid(holder?.GUIDQDApplication); // Guid result of create
      }

      const lastPage = currentVisibleSections[currentVisibleSections.length - 1];
      this.navigate(lastPage.Sequence);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true, });
    }
  }

  storeSessionKeys = async (model: string, guid: string) => {
    if (getQD()) {
      const instanceResponse = await ModelApi.getInstanceId();
      setQD(instanceResponse.Result);
    }

    setModel(model);
    setGuid(guid);
  };

  navigate = (sequence: string) => {
    const {
      history,
      holderState: { Holder: holder, },
    } = this.props;

    history.push(this.getUrl(this.props.match.params, holder?.Expired, holder?.CompleteFlag, sequence, holder?.GUIDQDApplication));
  };

  getUrl = (routingParams: RoutingProps_, expired: boolean, complete: boolean, sequence: string, guidFromCreate: string): string => {
    const { model, guid, completePage, } = routingParams;

    if (expired) return `/expired/${model}/${guid || guidFromCreate}/`;

    if (complete) {
      const completePageCheck = ['documents', 'review', 'status'].includes(completePage) ? completePage : 'complete';
      return `/${completePageCheck}/${model}/${guid || guidFromCreate}/`;
    }
    return `/section/${model}/${sequence}/${guid || guidFromCreate}/`;
  };

  onSubmit = async (invalidPageFields, holder, updateSection, sequence, fromGuarantor = false) => {
    if (invalidPageFields && invalidPageFields.length > 0) {
      return;
    }

    const {
      updateHolderFromSave, saveEvalSection, setLoader: setLoader_, submitToReviewButtonState, updateStopApplicationCondition,
    } = this.props;
    setLoader_(true);

    const savePars = this.prepareSavePars(holder, updateSection, sequence, fromGuarantor);
    let nextPageSequence;
    if (submitToReviewButtonState.submitToReview) {
      // eslint-disable-next-line no-param-reassign
      nextPageSequence = parseInt(ExternalPages.Review, 10);
      updateVisibleSections(savePars.allSections, true);

      this.props.updateSubmitButton({ submitToReview: false, show: true, });
      const res = await QdServiceApi.saveSection(savePars.updateObject);

      if (res?.Result) {
        const holderAction = updateHolderFromSaveResponse(sequence, holder, res.Result);

        if (holderAction?.type === 'SaveHolder') {
          updateHolderFromSave(holderAction);
        }
      }
    } else {
      nextPageSequence = await saveEvalSection(savePars);
    }

    setLoader_(false);
    if (nextPageSequence !== ValueConstants.ShouldStopApplication) this.navigate(nextPageSequence.toString());
    else updateStopApplicationCondition(true);
  };

  isStatusTabVisible = (app: QdApplicationHolder_) => {
    return ApplicationStatus.WaitingOnCoApplicant === app.ApplicationStatus && app?.CompleteFlag && app?.Guarantor?.Guarantors.length > 0;
  };

  isDocumentPageVisible = (app: QdApplicationHolder_) => {
    return [ApplicationStatus.OnHold, ApplicationStatus.Accepted].includes(app.ApplicationStatus) && app?.CompleteFlag && app?.DocumentsTabVisibility;
  };

  checkSectionVisibilityChanged = async (): Promise<{ HasChanged: boolean; NextVisiblePageSequence?: string }> => {
    const {
      holderState,
      modelState,
      sectionsState,
      match: {
        params: { partyGUID, },
      },
      updateVisibleSections_,
      setLoader: setLoader_,
    } = this.props;

    const app = holderState?.Holder;
    const allSections = modelState?.ModelHolder.ApplicationSections;
    const currentVisibleSections = [...sectionsState.VisibleSections];

    setLoader_(true);

    //  ApplicationTypeAnswer
    const applicationTypeAnswer = (app as QdApplicationHolder_)?.Information?.ApplicationTypeAnswer ?? QDApplicationTypeAnswer.Business;
    const holderForEval = shouldUseHolderForEval(applicationTypeAnswer, false, partyGUID) ? app : null;
    //  END ApplicationTypeAnswer

    // Eval pages
    const { Result: evalResult, } = await QdServiceApi.evaluate(app.IdQDApplication, null, holderForEval, partyGUID);
    if (evalResult?.VisibleSections?.length > 0) {
      const latestVisibleSectionsFromServer = filterSections('IdQDSectionDefinition', allSections, evalResult?.VisibleSections);

      // Find if there is different page
      const sectionsVisibilityChanged = latestVisibleSectionsFromServer.filter(
        ({ SectionName: SectionNameFromServer, }) => !currentVisibleSections.some(({ SectionName: SectionNameFromState, }) => SectionNameFromServer === SectionNameFromState)
      );

      if (sectionsVisibilityChanged.length) {
        const sortedSections = sortVisibleSections(latestVisibleSectionsFromServer);
        const nextPage = sortedSections[sortedSections.length - 1];

        const { GUIDQDApplication, IdQDApplication, IdQDApplicationModel, } = app;
        // Eval Sub Sections for new page
        const { Result: evaluatedSubSections, } = await QdServiceApi.evalSectionSubSections({
          FromGuarantor: false,
          GUIDQDApplication,
          IdQDApplication,
          IdQDApplicationModel,
          SectionName: nextPage.SectionName,
          GUIDQDParty: partyGUID,
        } as Section_);

        if (nextPage) nextPage.SubSections = filterSections('Id', nextPage.SubSections, evaluatedSubSections);

        const isReviewVisible = checkIsReviewPageVisible(latestVisibleSectionsFromServer, ExternalPages.Review);
        updateVisibleSections_(sortedSections, isReviewVisible);

        return { HasChanged: true, NextVisiblePageSequence: nextPage.Sequence, };
      }
    }

    setLoader_(false);
    return { HasChanged: false, };
  };

  onFail = () => {
    const { setProgress: setProgressLoader, } = this.props;
    setProgressLoader(false);
  };

  onSubmitApplication = async () => {
    const {
      holderState,
      modelState,
      setProgress: setProgressProps,
      history,
      match: {
        params: { sequence, },
      },
      setLoader: setLoader_,
    } = this.props;

    const app = holderState?.Holder;
    const model = modelState?.ModelHolder?.Model;

    const sectionVisibilityResult = await this.checkSectionVisibilityChanged();
    if (sectionVisibilityResult?.HasChanged) {
      const infoMsgTimeout = 10000; // 10 sec.
      // eslint-disable-next-line max-len
      const warningMsg = 'New required information has been added, please review and resubmit your application.';
      Toast.warning(warningMsg, { autoClose: infoMsgTimeout, });
      setTimeout(() => {
        setLoader_(false);
        this.navigate(sectionVisibilityResult.NextVisiblePageSequence);
      }, infoMsgTimeout);
      return true;
    }

    setProgressProps(true);

    const result = await this.submit<QDCompletePars_>(
      { IdApplication: new BigInt(app.IdQDApplication), GUID: app.GUIDQDApplication, },
      (step: number, res: QDCompletePars_) => {
        this.setState({ step, });

        if (res?.CompleteFlag) {
          app.CompleteFlag = res?.CompleteFlag;
          app.IsCounterOffer = res?.Application?.IsCounterOffer;
          app.LoanOptions = res?.LoanOptions;
          app.ApplicationStatus = res?.Application?.ApplicationStatus;
          app.ApplicationStatusEnumStr = res?.Application?.ApplicationStatusEnumStr;
          app.DocumentsTabVisibility = res?.DocumentsTabVisibility;

          this.setState(() => {
            return {
              showStatusPage: this.isStatusTabVisible(app),
              showDocumentPage: this.isDocumentPageVisible(app),
            };
          });

          this.props.updateExternalHolder(app);

          history.push(this.getUrl(this.props.match.params, false, res?.CompleteFlag, sequence, app.GUIDQDApplication));
        }
      },
      this.onFail,
      this.state.step,
      model
    );

    setProgressProps(false);
    return result;
  };

  prepareSavePars = (holder: QdApplicationHolder_, updateSection: any, sequence: string, fromGuarantor: boolean = false): InputSaveParameters_ => {
    const { model, guid, } = this.props.match.params;
    const {
      modelState: { ModelHolder: modelHolder, },
      identityState,
    } = this.props;

    const updateObject: UpdateObject_ = {
      FromGuarantor: fromGuarantor,
      IdQDApplication: holder?.IdQDApplication,
      GUIDQDApplication: guid,
      IdQDModel: modelHolder?.Model?.Id,
      QDApplicationModelCode: model,
      SectionName: updateSection.SectionName,
      Sequence: sequence,
      identityState,
    };
    if (sequence === ExternalPages.PurposeProduct && holder?.PurposePickList) updateObject.PurposePickList = holder?.PurposePickList;

    Object.keys(holder).some(prop => {
      if ({}.hasOwnProperty.call(holder, prop)) {
        const section = holder[prop];
        if (section && typeof section === 'object') {
          if (section.SectionName === updateSection.SectionName) {
            updateObject[prop] = updateSection;
            return true;
          }
        }
      }
      return false;
    });

    if (sequence === ExternalPages.BusinessBorrowerOtherInformation) {
      updateObject.SectionName = ExternalPageSectionName.BusinessBorrowerOtherInformation;
    }

    const useIDology = modelHolder?.Model && modelHolder?.Model?.IdentityVerificationSystem === IdentityVerificationSystem.IDology;
    const identityCheckPars = {
      SectionName: useIDology ? ValueConstants.Idology : ValueConstants.PreciseID,
      GUIDQDParty: holder?.BorrowerP?.Personal?.GUID,
      IdQDParty: holder?.BorrowerP?.Personal?.Id,
      DisplayOption: modelHolder?.Model?.ValidationDisplayOption,
      IdBusinessRule: modelHolder?.Model?.IdBusinessRuleValidation,
      GUIDQDApplication: holder?.GUIDQDApplication,
      IdQDApplication: holder?.IdQDApplication,
      FromGuarantor: false,
    };

    return {
      updateObject,
      allSections: modelHolder.ApplicationSections,
      holder,
      identityCheckPars,
    };
  };

  updateHolder = (coBorrowerHolder: CoBorrowerAddNowHolder) => {
    const {
      holderState: { Holder: holder, },
    } = this.props;

    const { CoBorrower: coBorrower, Addresses: coBorrowerAddresses, Employment: coBorrowerEmployment, } = coBorrowerHolder;
    const {
      Guarantors: guarantors,
      GuarantorsAddNowAddresses: guarantorsAddresses,
      GuarantorsAddNowEmployments: guarantorsEmployments,
    } = holder.Guarantor;

    const isGuarantorExist = guarantors.some(g => g.GUID === coBorrower.GUID);

    // Guarantors Information
    const udpatedGuarantors = isGuarantorExist ? guarantors.map(g => (g.GUID === coBorrower.GUID ? coBorrower : g)) : [...guarantors, coBorrower];

    // Addresses
    const isCoBorrowerMatched = coBorrower.IdParty && coBorrower.PartyMatchDone && coBorrower.IdentityCheck === IdentityCheckStatus.Validated;
    const isPersonalParty = coBorrower.PartyType === PartyType.Personal;

    const personalAddNowMatch = isPersonalParty && isCoBorrowerMatched && coBorrower.CoBorrowerAddType === CoBorrowerAddType.AddNow;
    const partyMatchWithSameAddress = coBorrower.SameAddressType === SameAddressType.Yes && isCoBorrowerMatched && isPersonalParty;

    // If coBorrower has the same address type, his addresses are already filled in as Personal Borrower's,
    // but if the party is found by LV, we have to delete his current addresses because they will be filled from LV party.
    const udpatedGuarantorsAddresses = partyMatchWithSameAddress || personalAddNowMatch
      ? guarantorsAddresses.filter((address: QDAddress_) => address.IdQDParty !== coBorrower.Id)
      : [...guarantorsAddresses];

    coBorrowerAddresses.forEach((coBorrowerAddress: QDAddress_) => {
      const addressIndex = udpatedGuarantorsAddresses.findIndex(address => address.Id === coBorrowerAddress.Id);

      // If address already exist
      if (addressIndex > -1) {
        udpatedGuarantorsAddresses[addressIndex] = coBorrowerAddress;
      } else if (coBorrowerAddress.Id > 0) {
        udpatedGuarantorsAddresses.push(coBorrowerAddress);
      }
    });

    // Employments
    const udpatedGuarantorsEmployments = [...guarantorsEmployments];
    const employmentIndex = udpatedGuarantorsEmployments.findIndex(employment => employment.Id === coBorrowerEmployment.Id);

    // If employment already exist
    if (employmentIndex > -1) {
      udpatedGuarantorsEmployments[employmentIndex] = coBorrowerEmployment;
    } else if (coBorrowerEmployment.Id > 0) {
      udpatedGuarantorsEmployments.push(coBorrowerEmployment);
    }

    const guarantor = {
      ...holder.Guarantor,
      Guarantors: udpatedGuarantors,
      GuarantorsAddNowEmployments: udpatedGuarantorsEmployments,
      GuarantorsAddNowAddresses: udpatedGuarantorsAddresses,
    };

    const updatedHolder = { ...holder, Guarantor: guarantor, };

    this.props.updateExternalHolder(updatedHolder);
  };

  shouldEvalIdentity = (isIdentityFetched: boolean, page: string, coBorrower: Borrower_): boolean => {
    if (isIdentityFetched) return false;

    if (parseInt(page, 10) === parseInt(GuarantorPages.PersonalPartyMatch, 10)) {
      const { PartyMatchDone, IdParty, } = coBorrower;
      return PartyMatchDone && !!IdParty;
    }

    return parseFloat(page) === parseInt(GuarantorPages.PersonalAddress, 10);
  };

  onCoBorrowerSave = async (holder: CoBorrowerAddNowHolder, currSectionName: string) => {
    const {
      history,
      match: {
        params: { model, guid, page, },
      },
      updateCoBorrowerAddNowHolder,
      coBorrowerIdentityState: {
        IsIdentityFetched,
        IdentityVisited,
        IdentityHolder: { HasQuestions, },
      },
      updateIdentityResult,
      holderState: { Holder: appHolder, },
      modelState: { ModelHolder: modelHolder, },
      sectionsState: { VisibleSections: appVisibleSections, },
      updateVisibleSections_,
      updateStopApplicationCondition,
    } = this.props;

    let visibleSections = [];
    let identityResponse;
    const nextStep = { SectionName: '', Sequence: 0, };

    const { Result, } = await QdServiceApi.saveCoBorrower(holder);
    if (!Result) return; // Possible custom exception

    Result.CoBorrowerInfoPageSaved = holder.CoBorrowerInfoPageSaved;
    Result.CoBorrower.NullFields.length = 0;

    if (Result.Addresses.length > 0) {
      Result.Addresses = Result.Addresses.map(address => (address.NullFields?.length > 0 ? { ...address, NullFields: [], } : address));
    }

    if (Result.Employment) {
      Result.Employment.NullFields.length = 0;
    }

    updateCoBorrowerAddNowHolder(Result, true);
    this.updateHolder(Result);

    const { IdQDApplication, GUIDQDApplication, IdQDApplicationModel, } = appHolder;
    const {
      CoBorrower: { GUID: partyGUID, },
    } = holder;

    // Determine next page
    const { Result: evalResult, } = await QdServiceApi.evaluate(appHolder.IdQDApplication, currSectionName, null, partyGUID);

    // No visible sections
    if (!evalResult?.VisibleSections?.length) {
      this.navigate(ExternalPages.CoBorrower);
      return;
    }

    visibleSections = filterSections('IdQDSectionDefinition', modelHolder.ApplicationSections, evalResult?.VisibleSections);

    if (!evalResult?.ShouldStopApplication) {
      const nextPage = visibleSections[visibleSections.length - 1];
      const { Result: evaluatedSubSections, } = await QdServiceApi.evalSectionSubSections({
        FromGuarantor: true,
        GUIDQDApplication,
        IdQDApplication,
        IdQDApplicationModel,
        SectionName: nextPage.SectionName,
        GUIDQDParty: partyGUID,
      } as Section_);

      if (nextPage) {
        nextPage.SubSections = filterSections('Id', nextPage.SubSections, evaluatedSubSections);
        nextStep.Sequence = nextPage.Sequence;
        nextStep.SectionName = nextPage.SectionName;
      }
    } else {
      const currentSequence = visibleSections.find(s => s.SectionName === currSectionName)?.Sequence;
      if (currentSequence) {
        visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= parseInt(currentSequence, 10));
      }
      nextStep.Sequence = ValueConstants.ShouldStopApplication;
    }

    const evalIdentityCheck = this.shouldEvalIdentity(IsIdentityFetched, page, holder?.CoBorrower);
    if (evalIdentityCheck && !evalResult?.ShouldStopApplication) {
      const useIDology = modelHolder.Model && modelHolder.Model.IdentityVerificationSystem === 0;
      const identityCheckPars = {
        SectionName: useIDology ? ValueConstants.Idology : ValueConstants.PreciseID,
        GUIDQDParty: holder?.CoBorrower.GUID,
        IdQDParty: holder?.CoBorrower.Id,
        DisplayOption: modelHolder.Model.ValidationDisplayOptionGuarantor,
        IdBusinessRule: modelHolder.Model.IdBusinessRuleValidationGuarantor,
        GUIDQDApplication: appHolder.GUIDQDApplication,
        IdQDApplication: appHolder.IdQDApplication,
        FromGuarantor: false, // should be false, regarding authorization logic
      };
      const { Result: identityEvalResult, } = await QdServiceApi.identityCheckEval(identityCheckPars);
      identityResponse = identityEvalResult;
      updateIdentityResult(identityResponse);
    }

    const visibleSorted = sortVisibleSections(visibleSections);
    updateVisibleSections_(appVisibleSections, false, visibleSorted);

    if (nextStep.Sequence === ValueConstants.ShouldStopApplication) {
      updateStopApplicationCondition(true);
      return;
    }

    const nextVisibleSection = visibleSorted[visibleSorted.length - 1];
    nextStep.Sequence = parseFloat(nextVisibleSection?.Sequence);

    if (
      !IdentityVisited
      && (HasQuestions || identityResponse?.HasQuestions)
      && (parseInt(page, 10) === parseInt(GuarantorPages.PersonalPartyMatch, 10) || parseFloat(page) === parseInt(GuarantorPages.PersonalAddress, 10))
    ) {
      nextStep.SectionName = GuarantorPageSectionName_.Identity;
      nextStep.Sequence = parseFloat(GuarantorPages.IdentityCheck);
    }

    //  Last Section/Page reached
    if (nextStep.SectionName === currSectionName) {
      // Reset CoBorrower Visible sections
      updateVisibleSections_(appVisibleSections, false, []);
      this.navigate(ExternalPages.CoBorrower);
      return;
    }

    //   Navigate
    if (nextStep.Sequence) {
      const url = `/guarantor-add-now/${model}/${ExternalPages.CoBorrower}/${nextStep.Sequence}/${guid}/`;
      history.push(url);
    }
  };

  onCoBorrowerCancel = () => {
    const {
      updateCoBorrowerAddNowHolder,
      holderState: { Holder: appHolder, },
      updateVisibleSections_,
      sectionsState: { VisibleSections: appVisibleSections, },
    } = this.props;

    const resetState: CoBorrowerAddNowHolder = {
      GUIDQDApplication: appHolder?.GUIDQDApplication,
      CoBorrower: null,
      Employment: null,
      CoBorrowerInfoPageSaved: false,
      Addresses: [],
    };
    updateCoBorrowerAddNowHolder(resetState, false);

    // Reset CoBorrower Visible sections
    updateVisibleSections_(appVisibleSections, false, []);

    this.navigate(ExternalPages.CoBorrower);
  };

  content = () => {
    const { step, dataLoaded, } = this.state;
    const {
      sectionsState,
      showProgress,
      match: {
        params: { sequence, completePage, page, },
      },
      holderState: { Holder: holder, },
    } = this.props;

    const guarantors = dataLoaded ? holder?.Guarantor?.Guarantors : [];
    const emailInvitedGuarantors = (guarantors || []).filter(
      (guarantor: Borrower_) => guarantor.CoBorrowerAddType === CoBorrowerAddType.EmailInvitation
    );

    return (
      <ReactAux>
        {dataLoaded && !sequence && <ExternalComplete completePage={completePage} />}

        {dataLoaded && sequence && (
          <ExternalSection
            onSubmit={this.onSubmit}
            locationParams={this.props.match.params}
            onSubmitApplication={this.onSubmitApplication}
            sequence={sequence}
            sectionsState={sectionsState}
            step={step}
            agreedFlag={holder?.AgreedFlag}
            showProgress={showProgress}
            hasEmailInvitedGuarantors={emailInvitedGuarantors.length > 0}
            coBorrowerAddNowSequence={page}
            onCoBorrowerAddNowSave={this.onCoBorrowerSave}
            onCoBorroweerAddNowCancel={this.onCoBorrowerCancel}
          />
        )}
      </ReactAux>
    );
  };
}

const mapStateToProps = (state: AppState_): AppState_ => ({ ...state, });

const mapDispatchToProps = (dispatch: ThunkDispatch_<any, any, AnyAction_>) => {
  return {
    saveEvalSection: (params: InputSaveParameters_) => dispatch(evalProcessActionCreator(params)),
    setLoader: (loading: boolean) => dispatch(setLoader(loading)),
    setProgress: (progress: boolean) => dispatch(setProgress(progress)),
    updateExternalHolder: (holder: QdApplicationHolder_) => dispatch(updateExternalHolder(holder)),
    updateHolderFromSave: (action: ISaveHolder_ | IgnoreChangeHolder_) => dispatch(action),
    updateSubmitButton: (update: SubmitButtonState_) => dispatch(updateButton(update)),
    getCoBorrowerAddNowHolder: (pars: CoBorrowerPars_) => dispatch(getCoBorrowerActionCreator(pars)),
    updateCoBorrowerAddNowHolder: (coBorroweAddNow: CoBorrowerAddNowHolder, isActive: boolean) => dispatch(setCoBorrowerAddNowState(coBorroweAddNow, isActive)),
    updateIdentityResult: (identityHolder: QDEvaluationIdentityResult_) => dispatch(setCoborrowerIdentityResult(identityHolder)),
    getHolderWithoutOverridingInformation: (params: InputHolderParameters_, currentHolder: QdApplicationHolder_) => dispatch(getHolderWithoutResetingInformation(params, currentHolder)),
    setIdentityState: (identityState: IdentityState_) => dispatch(setIdentityStateAction(identityState)),
    setCoBorrowerIdentityState: (identityState: CoBorrowerIdentityState_) => dispatch(setCoBorrowerIdentityStateAction(identityState)),
    updateStopApplicationCondition: (isApplicationStop: boolean) => dispatch(updateStopApplicationConditionAction(isApplicationStop)),
    updateVisibleSections_: (sections: Section_[], isReviewVisible: boolean, coBorrowerAddNowSections?: Section_[]) => dispatch(updateVisibleSections(sections, isReviewVisible, coBorrowerAddNowSections)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(External));
