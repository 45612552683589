import React from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Section, TextEditorContentWrapper
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { htmlParser } from '../../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { QDPartyStatus, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';

type CoBorrowerCompletePage = {
  DataUI: string;
  Title: string;
  TextContent: string;
};
const CoBorrowerComplete = () => {
  const {
    modelState, loading, holder, isCoBorrowerSubmitted,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
      isCoBorrowerSubmitted: boolean;
    }
  >(state => ({
    modelState: state.modelState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    isCoBorrowerSubmitted: state.isCoBorrowerSubmitted,
  }));

  const { Personal, Business, } = holder;
  const coBorrower = Personal?.CoBorrower ?? Business?.CoBorrower;
  const {
    ModelHolder: { Model, CoBorrowerSections, },
  } = modelState;

  const getContent = (): CoBorrowerCompletePage => {
    // Expired
    if (holder.Expired) {
      return {
        Title: 'Application Expired',
        DataUI: 'expired-section',
        TextContent: Model?.ClosedApplicationHTML || '',
      };
    }

    switch (coBorrower.Status) {
      case QDPartyStatus.Declined: {
        // Withdraw
        return {
          Title: 'Removal Confirmed',
          TextContent: `This is confirmation that you have been removed from the application.
          We will notify the main applicant of your decision to be withdrawn from the application.`,
          DataUI: 'guarantor-declined-section',
        };
      }

      case QDPartyStatus.Stopped:
        return htmlParser(Model.GuarantorCancelHTML);

      case QDPartyStatus.FailedAuthentication:
      case QDPartyStatus.Submitted: {
        //  Already submitted;
        //  As result of refresh or resume co-borrower app
        if (!isCoBorrowerSubmitted) {
          return {
            Title: 'Results',
            TextContent: 'Thank you for returning to your application. Your portion of the application process is complete.',
            DataUI: 'guarantor-disabled-section',
          };
        }

        //  Just submitted;
        //  As result of Submit button click; isCoBorrowerSubmitted = true
        const thankYouSection = CoBorrowerSections.find(s => s.SectionName === GuarantorPageSectionName_.ThankYou);
        const [subSection] = thankYouSection?.SubSections ?? [];
        return {
          Title: subSection?.SubSectionName ?? '',
          DataUI: 'guarantor-complete-section',
          TextContent: htmlParser(Model.GuarantorThankYouHTML),
        };
      }
      default:
        return { Title: '', TextContent: '', DataUI: 'guarantor-complete-section', };
    }
  };

  const { Title, DataUI, TextContent, } = getContent();

  return (
    <>
      {!loading && (
        <Section title={Title} className="mb-2" dataUI={DataUI}>
          <Row>
            <Col md="12" lg="12">
              <p>
                <TextEditorContentWrapper>{TextContent}</TextEditorContentWrapper>
              </p>
            </Col>
          </Row>
        </Section>
      )}
    </>
  );
};

export default CoBorrowerComplete;
