import { Reducer as Reducer_ } from 'redux';
import { IGotDocuments as IGotDocuments_, DocumentsState as DocumentsState_ } from '../actions/Documents';
import QDDocumentsResult_ from '../../data/models/QDDocumentsResult';

const documentsReducer: Reducer_<DocumentsState_, IGotDocuments_> = (
  state = {
    DocumentHolder: new QDDocumentsResult_(),
    IsDocumentsFetching: false,
    IsDocumentsFetched: false,
  },
  action
) => {
  switch (action.type) {
    case 'GetDocuments': {
      return { ...action.DocumentsState, };
    }
    default:
      return state;
  }
};

export default documentsReducer;
