import React, { FC as FC_, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import Page from '../../../Page/Page';
import OtherInformationSettings from './OtherInformationSettings';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { GuarantorPages, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { scrollToError } from '../../../../../utils/Helper';

export interface CoBorrowerAddNowInformationProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

const GuarantorInformation: FC_<CoBorrowerAddNowInformationProps> = (props: CoBorrowerAddNowInformationProps) => {
  const { loading, modelState, addNowHolder, } = useSelector<
    AppState_,
    {
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      addNowHolder: CoBorrowerAddNowHolder_;
    }
  >(state => ({
    loading: state.loading,
    modelState: state.modelState,
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
  }));

  const [coBorrower, setCoBorrower] = useState(addNowHolder?.CoBorrower);
  const coBorrowerSections = modelState?.ModelHolder?.CoBorrowerSections;

  const section = coBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.BusinessOtherInformation);
  const [subSection] = section?.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const otherInformationPageFields = pageFieldHolder?.BusinessInformation?.OtherInformation;
  const pageSettings = useMemo(() => {
    return new OtherInformationSettings([otherInformationPageFields], [subSection]);
  }, [otherInformationPageFields, subSection]);

  const updateCoBorrowerAddNow = (pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>, value: string) => {
    coBorrower[pageField.ObjectProperty] = value;
    setCoBorrower({ ...coBorrower, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>[], e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (invalidPageFields.length === 0) {
      const coBorrowerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: coBorrower, };
      await props.onSave(coBorrowerAddNowHolder, GuarantorPageSectionName_.BusinessOtherInformation);
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateCoBorrowerAddNow}
          holder={addNowHolder}
          subHolder={coBorrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessOtherInformation}`}
        />
      )}
    </>
  );
};
export default GuarantorInformation;
