import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Section, FormSection, ScrollableContainer, Alert, Checkbox, Tooltip
} from '@jkhy/vsg-design-system';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import { GuarantorPageSectionName_, GuarantorPages } from '../../../../../utils/Enums';
import { getFullName, htmlParser } from '../../../../../utils/Helper';
import Messages from '../../../../../utils/Messages';
import { setLoader } from '../../../../../redux/actions/Loading';
import { setMainDisclosures } from '../../../../../redux/actions/Disclosures';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import QDCoBorrowerApplicationHolder_, {
  QDApplicationDisclosures as QDApplicationDisclosures_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import SubSection_ from '../../../../../data/models/Subsection';
import {
  checkIsDisclosuresHasEmailTemplate,
  checkHasPartyAgreedEarlier,
  DisclosuresDocuments as DisclosuresDocuments_,
  checkAreAllDocumentsOpened,
  checkHasDisclosures
} from '../../../../../utils/Disclosures';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import DisclosuresDocuments from '../../../../../components/DisclosuresDocuments/DisclosuresDocuments';

type CoBorrowerDisclosuresProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDApplicationDisclosures_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDApplicationDisclosures_,
    sequence: string
  ) => void;
};

const CoBorrowerDisclosures = (props: CoBorrowerDisclosuresProps_) => {
  const {
    sectionsState, loading, holder, modelState,
  } = useSelector<
    AppState_,
    {
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    modelState: state.modelState,
  }));

  const dispatch = useDispatch();
  const [disclosures, setDisclosures] = useState({ ...holder?.Disclosures, });
  const [concatDisclosures, setConcatDisclosures] = useState<string>('');
  const [showDisclosuresErrorMessage, setShowDisclosuresErrorMessage] = useState(false);
  const [disclosureParties, setDisclosureParties] = useState(disclosures?.QDPartyGuids);
  const [agreedDisclosuresFlag, setAgreedDisclosuresFlag] = useState(false);
  const [disclosuresDocuments, setDisclosuresDocuments] = useState<DisclosuresDocuments_>({});

  const section = sectionsState?.VisibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.Disclosures);
  const subSection = section?.SubSections?.find((currentSubSection: SubSection_) => currentSubSection.Code === GuarantorPageSectionName_.Disclosures);

  const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;

  const hasDisclosures = checkHasDisclosures(concatDisclosures, disclosuresDocuments);

  const hasPartyAgreedEarlier = useMemo(() => {
    const isAgreedEarlier = checkHasPartyAgreedEarlier(coBorrower.GUID, disclosureParties);

    if (agreedDisclosuresFlag !== isAgreedEarlier) {
      setAgreedDisclosuresFlag(isAgreedEarlier);
    }

    return isAgreedEarlier;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosureParties, coBorrower.GUID]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!agreedDisclosuresFlag && hasDisclosures) {
      setShowDisclosuresErrorMessage(true);
      return;
    }

    props.onSubmit([], holder, disclosures, GuarantorPages.Disclosures);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, } = e.currentTarget;

    if (checked && showDisclosuresErrorMessage) {
      setShowDisclosuresErrorMessage(false);
    }

    const newDisclosures = {
      ...disclosures,
      DisclosuresAgreeFlag: checked,
      DisclosuresSectionId: section?.Id,
      QDPartyGuids: checked ? [{ PartyGuid: coBorrower.GUID, AgreedEarlier: true, PartyEmail: coBorrower.Email, }] : [],
    };

    setDisclosures(newDisclosures);
    setAgreedDisclosuresFlag(checked);
  };

  useEffect(() => {
    const getDisclosures = async () => {
      dispatch(setLoader(true));

      const { GUID, GUIDQDApplication, } = coBorrower;

      const {
        Result: { ConcatenatedDisclosures, Parties, PdfDisclosures, },
      } = await QdServiceApi.loadQDApplicationDisclosures(GUIDQDApplication, section?.Id, GUID);

      setDisclosureParties(Parties);
      setConcatDisclosures(ConcatenatedDisclosures);

      const hasMainDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
      dispatch(setMainDisclosures({ hasDisclosures: hasMainDisclosures, isFetched: true, }));

      if (PdfDisclosures.length > 0) {
        const newDisclosuresDocuments = {};
        const hasAgreed = checkHasPartyAgreedEarlier(GUID, Parties);

        PdfDisclosures.forEach(disclosure => {
          const { GUID: disclosureGuid, Name, } = disclosure;

          newDisclosuresDocuments[disclosureGuid] = {
            isOpened: hasAgreed,
            name: Name,
            url: '',
          };
        });

        setDisclosuresDocuments(newDisclosuresDocuments);
      }

      dispatch(setLoader(false));
    };

    if (!concatDisclosures) {
      getDisclosures();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areAllDocumentsOpened = checkAreAllDocumentsOpened(disclosuresDocuments);
  const hasDisclosuresDocuments = disclosuresDocuments && Object.keys(disclosuresDocuments).length > 0;

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.Disclosures}`} onSubmit={onSubmit} noValidate>
          <Section
            dataUI="guarantor-disclosures-section"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
            className="mb-3"
          >
            <FormSection dataUI="guarantor-disclosures-content-section" className="mb-3">
              {concatDisclosures && (
                <ScrollableContainer dataUI="concatenated-guarantor-disclosures" className={`${hasDisclosuresDocuments ? 'mb-3' : ''}`}>
                  {htmlParser(concatDisclosures)}
                </ScrollableContainer>
              )}

              {hasDisclosuresDocuments && (
                <DisclosuresDocuments isGuarantor disclosuresDocuments={disclosuresDocuments} setDisclosuresDocuments={setDisclosuresDocuments} />
              )}
            </FormSection>

            {hasDisclosures && (
              <>
                <FormSection dataUI="guarantor-disclosures-section" className="mb-3">
                  <h3 className="mb-2">{getFullName(coBorrower)}</h3>

                  {/* eslint-disable-next-line quick-decision/react-data-ui */}
                  <Tooltip title={areAllDocumentsOpened ? '' : Messages.DISCLOSURES_TOOLTIP_TITLE}>
                    <div>
                      <Checkbox
                        dataUI="guarantor-disclosures-agreed-checkbox"
                        className="d-inline-block"
                        htmlFor="guarantor-agreement-with-disclosures"
                        id="guarantor-agreement-with-disclosures"
                        checked={agreedDisclosuresFlag}
                        disabled={hasPartyAgreedEarlier || !areAllDocumentsOpened}
                        onChange={onChange}
                      >
                        {Messages.DISCLOSURES_CHECKBOX_LABEL}
                      </Checkbox>
                    </div>
                  </Tooltip>
                </FormSection>

                {checkIsDisclosuresHasEmailTemplate(modelState, GuarantorPages.Disclosures) && (
                  <Alert
                    dataUI="guarantor-dislosures-alert-email-notification"
                    type="info"
                    text={Messages.CO_BORROWER_DISCLOSURES_AGREEMENT_NOTIFICATION}
                  />
                )}
              </>
            )}
          </Section>

          {showDisclosuresErrorMessage && <Alert dataUI="disclosure-alert-agreed-notification" type="danger" text={Messages.INVALID_DISCLOSURES} />}
        </form>
      )}
    </>
  );
};

export default CoBorrowerDisclosures;
