import React, {
  FC as FC_, useMemo, useEffect, useState, memo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../../ReviewApplicationGeneralSection';
import { ExternalPageSectionName, GuarantorPageSectionName_ } from '../../../../../utils/Enums';
import { getFullName, yesNoOrEmptyAnswer } from '../../../../../utils/Helper';
import QdApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../../ReviewApplication';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { filterQuestion, getBranchQuestionOptions } from '../../../../../utils/Declarations';
import { setLoader } from '../../../../../redux/actions/Loading';
import ListAPI from '../../../../../data/api/ListApi';
import Branch_ from '../../../../../data/models/Branch';
import ListItem_ from '../../../../../data/models/ListItem';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { transformCustomQuestions } from '../../QuestionPresentation/QuestionPresentation';

type DeclarationsProps = ReviewApplicationSectionProps_;

const InformationDeclarations: FC_<DeclarationsProps> = ({
  page, isCoBorrower, sectionTitle, expandState, setExpandState,
}: DeclarationsProps) => {
  const [branches, setBranches] = useState<Branch_[]>([]);
  const [counties, setCounties] = useState<ListItem_[]>([]);

  const {
    holder,
    coBorrowerHolder,
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      coBorrowerHolder: QDCoBorrowerApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    coBorrowerHolder: state.coBorrowerHolderState.CoBorrowerHolder,
    modelState: state.modelState,
  }));

  const dispatch = useDispatch();

  const pageFields = isCoBorrower ? CoBorrowerPageField?.Information?.General : ApplicationPageField?.Information?.General;
  const declarations = isCoBorrower ? coBorrowerHolder.Information : holder.Information;

  const coBorrower = isCoBorrower ? coBorrowerHolder.Personal.CoBorrower ?? coBorrowerHolder.Business.CoBorrower : null;
  const primaryBorrower = isCoBorrower ? coBorrower : holder.BorrowerP.Personal;

  useEffect(() => {
    const getBranches = async () => {
      const { Result: listBranchesResult, } = await ListAPI.getBranches();
      return listBranchesResult;
    };

    const getCounties = async () => {
      const pars = {
        listName: 'QDCounty',
        sortColumn: '',
        sortOrder: 'ASC',
      };

      const { Result: countyCodes, } = await ListAPI.getLtItemsSearch(pars);
      return countyCodes;
    };

    const loadData = async () => {
      dispatch(setLoader(true));

      if (branches.length === 0) {
        const listBranches = await getBranches();
        setBranches(listBranches);
      }

      if (counties.length === 0) {
        const newCountyCodes = await getCounties();
        setCounties(newCountyCodes);
      }

      dispatch(setLoader(false));
    };

    if (!isCoBorrower) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !primaryBorrower) {
      return [];
    }

    const { Questions, ESignConsent: EsignConsentInformationDeclaration, } = declarations || {};

    const informationSectionName = isCoBorrower ? GuarantorPageSectionName_.Information : ExternalPageSectionName.InformationMembership;

    const questions = Questions.filter(question => filterQuestion(question, informationSectionName, isCoBorrower, primaryBorrower.Id));
    const transformedCustomQuestions = transformCustomQuestions(questions);

    const primaryBorrowerName = getFullName(primaryBorrower);
    const declarationId = primaryBorrowerName;

    let externalInformationPageData = {};
    if (!isCoBorrower && branches.length > 0 && counties.length > 0) {
      const {
        BranchQuestionCode, OfficerQuestionCodeStr, ZipBusinessLocated, StateBusinessLocatedStr, CountyCode, ApplicationTypeAnswerEnumStr,
      } = holder.Information;

      const currentBranch = branches.find((branch: Branch_) => branch.Code === BranchQuestionCode);
      const branchQuestion = currentBranch ? getBranchQuestionOptions(currentBranch) : { label: '', };

      const currentCounty = counties.find((county: ListItem_) => county.Code === CountyCode);

      externalInformationPageData = {
        ApplicationTypeQuestion: {
          dataUI: `information-declaration-application-type-${declarationId}-field`,
          value: ApplicationTypeAnswerEnumStr,
        },
        BranchQuestion: {
          dataUI: `information-declaration-branch-question-${declarationId}-field`,
          value: branchQuestion.label,
        },
        OfficerQuestion: {
          dataUI: `information-declaration-officer-question-${declarationId}-field`,
          value: OfficerQuestionCodeStr,
        },
        ZipCode: {
          dataUI: `information-declaration-zip-business-question-${declarationId}-field`,
          value: ZipBusinessLocated,
        },
        LocationOfBusiness: {
          dataUI: `information-declaration-location-of-business-${declarationId}-field`,
          value: StateBusinessLocatedStr,
        },
        CountyCode: {
          dataUI: `information-declaration-county-question-${declarationId}-field`,
          value: currentCounty?.Description,
        },
      };
    }

    return [
      {
        id: `review-information-declarations-${declarationId}-collapse`,
        title: 'Declarations',
        pageFields,
        link: { page, text: 'Go to information step to edit', },
        data: {
          ...transformedCustomQuestions,
          ...externalInformationPageData,
          ESignConsent: {
            dataUI: `information-declaration-esign-consent-question-${declarationId}-field`,
            value: yesNoOrEmptyAnswer(EsignConsentInformationDeclaration),
          },
        },
      }
    ];
  }, [branches, counties, pageFields, primaryBorrower, declarations, isCoBorrower, page, holder.Information]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(InformationDeclarations);
