export default class IncomeExpenses {
  Id: bigint;

  GUID: string;

  IdQDParty: bigint;

  EmployerName: string;

  JobTitle: string;

  EmploymentTypeCode: string;

  EmploymentTypeCodeStr: string;

  EmploymentStartDate: string;

  GrossEmploymentIncomePeriod: string;

  GrossEmploymentIncomePeriodEnumStr: string;

  GrossMonthlyEmploymentIncome: number;

  GrossAnnuallyEmploymentIncome: number;

  GrossBonusPeriod: string;

  GrossBonusPeriodEnumStr: string;

  GrossMonthlyBonus: number;

  GrossAnnuallyBonus: number;

  OtherIncomePeriod: string;

  OtherIncomePeriodEnumStr: string;

  OtherMonthlyIncome: number;

  OtherAnnuallyIncome: number;

  OtherMonthlyPayments: number;

  MonthlyHousingExpense: number;

  ChildSupportOrAlimonyPayment?: number;

  TreatAllNulls: boolean;

  NullFields: string[];

  IdUserLastModified: bigint;

  IdCompany: bigint;

  IdQDApplication: bigint;

  OperatesAsDBA: boolean;

  IdPartyDBA: bigint;

  PersonalDBA: string;
}
