import React, {
  FC as FC_, useState, useMemo, useEffect
} from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../../redux/actions/CoBorrowerIdentity';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import QdApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';
import Borrower_ from '../../../../../data/models/Borrower';
import Page from '../../../Page/Page';
import IndividualSettings from './IndividualSettings';
import BusinessSettings from './BusinessSettings';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { GuarantorPages, GuarantorPageSectionName_, PartyType } from '../../../../../utils/Enums';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import {
  booleanStringComparison, scrollToError, isNullOrUndefined, checkIsPartyMatched
} from '../../../../../utils/Helper';
import { checkHasIDVerificationShowed } from '../../../../../utils/IdentityVerification';

export interface CoBorrowerAddNowInformationProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

const GuarantorInformation: FC_<CoBorrowerAddNowInformationProps> = (props: CoBorrowerAddNowInformationProps) => {
  const {
    loading, modelState, holder, addNowHolder, coBorrowerIdentityState,
  } = useSelector<
    AppState_,
    {
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
      addNowHolder: CoBorrowerAddNowHolder_;
      coBorrowerIdentityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    loading: state.loading,
    modelState: state.modelState,
    holder: state.holderState?.Holder,
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    coBorrowerIdentityState: state?.coBorrowerIdentityState,
  }));

  const addNowCoBorrower = addNowHolder?.CoBorrower;
  const [coBorrower, setCoBorrower] = useState(addNowCoBorrower);

  useEffect(() => {
    if (coBorrowerIdentityState?.IdentityVisited && !isNullOrUndefined(`${addNowCoBorrower?.IdentityCheck}`)) {
      setCoBorrower(addNowCoBorrower);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coBorrowerIdentityState?.IdentityVisited, addNowCoBorrower?.IdentityCheck]);

  const isPersonal = coBorrower.PartyType === PartyType.Personal;

  const sectionName = isPersonal ? GuarantorPageSectionName_.PersonalInformation : GuarantorPageSectionName_.BusinessInformation;
  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === sectionName);
  const [subSection] = section?.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const pageSettings = useMemo(() => {
    const hasIDVerificationShowed = checkHasIDVerificationShowed(coBorrower, coBorrowerIdentityState);
    const isPartyMatched = checkIsPartyMatched(coBorrower);

    const individualAdditionalSettings = {
      coBorrower,
      holder,
      hasIDVerificationShowed,
      isPartyMatched,
    };

    return isPersonal
      ? new IndividualSettings([pageFieldHolder?.PersonalInformation?.General], [subSection], individualAdditionalSettings)
      : new BusinessSettings([pageFieldHolder?.BusinessInformation?.General], [subSection], { coBorrower, holder, });
  }, [coBorrower, holder, isPersonal, pageFieldHolder, subSection, coBorrowerIdentityState]);

  const updateCoBorrowerAddNow = (pageField: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    coBorrower[ObjectProperty] = value;

    if (ObjectPropertyStr) coBorrower[ObjectPropertyStr] = text;

    // Reset MilitaryServiceStatusCodeStr
    if (ObjectProperty === 'IsMilitaryService' && !booleanStringComparison(value)) {
      coBorrower.MilitaryServiceStatusCode = '';
      coBorrower.MilitaryServiceStatusCodeStr = '';
    }

    setCoBorrower({ ...coBorrower, });
  };

  const onSubmit = (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, Borrower_>[], e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (invalidPageFields.length === 0) {
      const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: coBorrower, CoBorrowerInfoPageSaved: true, };
      props.onSave(coBorrwerAddNowHolder, sectionName);
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateCoBorrowerAddNow}
          holder={addNowHolder}
          subHolder={coBorrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${isPersonal ? GuarantorPages.PersonalInformation : GuarantorPages.BusinessInformation}`}
        />
      )}
    </>
  );
};
export default GuarantorInformation;
