import React, { FC as FC_, memo } from 'react';
import PersonalCurrentAddresses from './PersonalCurrentAddresses/PersonalCurrentAddresses';
import PersonalPreviousAddresses from './PersonalPreviousAddresses/PersonalPreviousAddresses';
import { AddressType } from '../../../../../utils/Enums';
import QDAddress_ from '../../../../../data/models/QDAddress';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../../ReviewApplication';
import ReviewApplicationGeneralSection from '../../ReviewApplicationGeneralSection';

type PersonalAddressesProps = {
  personalAddresses: QDAddress_[];
} & ReviewApplicationSectionProps_;

const PersonalAddresses: FC_<PersonalAddressesProps> = ({
  isCoBorrower,
  personalAddresses,
  page,
  sectionTitle,
  expandState,
  setExpandState,
}: PersonalAddressesProps) => {
  const currentAddresses = personalAddresses?.filter((address: QDAddress_) => address.AddressType === AddressType.Current);
  const previousAddresses = personalAddresses?.filter((address: QDAddress_) => address.AddressType === AddressType.Previous);

  if (currentAddresses.length === 0 && previousAddresses.length === 0) {
    return null;
  }

  return (
    <ReviewApplicationGeneralSection page={null} sectionTitle={sectionTitle} plainContainer>
      <>
        {currentAddresses?.length > 0 && (
          <PersonalCurrentAddresses
            isCoBorrower={isCoBorrower}
            page={page}
            currentAddresses={currentAddresses}
            expandState={expandState}
            setExpandState={setExpandState}
          />
        )}

        {previousAddresses?.length > 0 && (
          <PersonalPreviousAddresses
            isCoBorrower={isCoBorrower}
            page={page}
            previousAddresses={previousAddresses}
            expandState={expandState}
            setExpandState={setExpandState}
          />
        )}
      </>
    </ReviewApplicationGeneralSection>
  );
};

export default memo(PersonalAddresses);
