import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../../External/Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../../External/Page/PageHelpers/PageFieldExtended';
import { ValidationRegexConstants } from '../../../utils/Enums';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import QDPrequalification_ from '../../../data/models/Prequalification/QDPrequalification';
import { validateESignConsent } from '../../../utils/Validator';
import Messages from '../../../utils/Messages';
import DropDownItem_ from '../../../data/models/DropDownItem';

const MINIMUM_CREDIT_SCORE_RANGE = 0;
export const MAXIMUM_CREDIT_SCORE_RANGE = 850;
export const INVALID_CREDIT_SCORE_RANGE_MSG = `Value entered must be in the range of ${MINIMUM_CREDIT_SCORE_RANGE}-${MAXIMUM_CREDIT_SCORE_RANGE}`;

type PageData = {
  purposePickList: string;
  counties: DropDownItem_[];
  scoreCardRanges: DropDownItem_[];
};

export default class PrequalificationSettings extends PageSettings<QDPrequalification_, QDPrequalification_, PageData> {
  protected pageFieldsMapper(pageField: PageField_, additionalData: PageData): PageFieldExtended_<QDPrequalification_, QDPrequalification_> {
    if (!pageField) {
      return null;
    }

    const { purposePickList, counties, scoreCardRanges, } = additionalData;

    switch (pageField.FieldName) {
      case 'FirstName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'FirstName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 30,
          },
          dataUI: 'prequalification-first-name',
        });

      case 'LastName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'LastName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 30,
          },
          dataUI: 'prequalification-last-name',
        });

      case 'MobileNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-phone-field',
          ObjectProperty: 'PhoneNumber',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'phone',
            icon: IconsSolid.faPhone,
          },
        });

      case 'ZipCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-zip-code-field',
          ObjectProperty: 'ZipCode',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
        });

      case 'Purpose':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-purpose-field',
          ObjectProperty: 'PurposeCode',
          ObjectPropertyStr: 'PurposeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: purposePickList,
              ListType: 'LtItems',
            },
          },
        });

      case 'State':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-state-field',
          ObjectProperty: 'State',
          ObjectPropertyStr: 'StateStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'QDState',
              ListType: 'LtItems',
            },
          },
        });

      case 'County':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-county-field',
          ObjectProperty: 'County',
          ObjectPropertyStr: 'CountyStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              stateLess: true,
              initialData: counties,
            },
          },
        });

      case 'StatedCreditScoreRange':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-stated-credit-score-range-field',
          ObjectProperty: 'CreditScoreRatio',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: scoreCardRanges,
              stateLess: true,
            },
          },
        });

      case 'StatedCreditScore':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'CreditScoreValue',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            inputType: 'integerInput',
            maxLength: 3,
            hideArrows: true,
            maxValue: 850,
            minValue: 0,
          },
          IsValid: (app, prequalification, pf) => {
            const result = prequalification?.CreditScoreValue;

            if (pageField.IsHidden) return true;

            if (!pageField.Required && !result) return true;

            if (!result) {
              const pageF = pf;
              pageF.ValidationMessage = Messages.REQUIRED_FIELD;
              return false;
            }

            const isValid = result >= MINIMUM_CREDIT_SCORE_RANGE && result <= MAXIMUM_CREDIT_SCORE_RANGE;
            if (!isValid) {
              const pageF = pf;
              pageF.ValidationMessage = INVALID_CREDIT_SCORE_RANGE_MSG;
            }

            return isValid;
          },
          dataUI: 'prequalification-stated-credit-score-field',
        });

      case 'StatedGrossMonthlyIncome':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'GrossMonthlyIncome',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'prequalification-stated-gross-monthly-income-field',
        });

      case 'StatedMonthlyHousingExpense':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'MonthlyHousingExpense',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'prequalification-stated-monthly-housing-expense-field',
        });

      case 'StatedMonthlyDebtsExcludingHousing':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: 'TotalMonthlyDebts',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'prequalification-stated-monthly-debts-excluding-housing-field',
        });

      case 'EsignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'prequalification-esign-consent-field',
          ObjectProperty: 'ESignConsent',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'prequalification-esign-consent-radio-yes', value: 'true', label: 'Yes', },
              { dataUI: 'prequalification-esign-consent-radio-no', value: 'false', label: 'No', }
            ],
          },
          IsValid: (app, subHolder, pageF) => {
            const { IsValid, InvalidMessage, } = validateESignConsent(subHolder, pageF);

            const pf = pageF;
            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
