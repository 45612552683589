import React, {
  FC as FC_, useState, useMemo, useEffect
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState as AppState_ } from '../../../redux/AppState';
import { setLoader } from '../../../redux/actions/Loading';
import ListAPI from '../../../data/api/ListApi';
import PrequalificationAPI from '../../../data/api/QDPrequalificationApi';
import { PrequalificationPages, PrequalificationSectionName } from '../../../utils/prequalification/Enums';
import QDPrequalification_ from '../../../data/models/Prequalification/QDPrequalification';
import { PrequalificationModelState as PrequalificationModelState_ } from '../../../redux/actions/PrequalificationModel';
import PrequalificationSettings from './PrequalificationSettings';
import PageFieldExtended_ from '../../External/Page/PageHelpers/PageFieldExtended';
import Page from '../../External/Page/Page';
import DropDownItem_ from '../../../data/models/DropDownItem';
import { booleanStringComparison, deepComparison } from '../../../utils/Helper';

export interface PrequalificationProps {
  onSubmit(prequalification: QDPrequalification_): Promise<void>;
}

export const PREQUALIFICATION_FORM_ID = `form-${PrequalificationPages.Prequalification}`;
export const PREQUALIFICATION_SECTION_DATA_UI = 'prequalification-section';

const Prequalification: FC_<PrequalificationProps> = (props: PrequalificationProps) => {
  const { isLoading, prequalificationModelState, prequalificationHolder, } = useSelector<
    AppState_,
    {
      isLoading: boolean;
      prequalificationModelState: PrequalificationModelState_;
      prequalificationHolder: QDPrequalification_;
    }
  >(state => ({
    isLoading: state.loading,
    prequalificationModelState: state.prequalificationModelState,
    prequalificationHolder: state.prequalificationHolder,
  }));

  const dispatch = useDispatch();
  const [prequalification, setPrequalification] = useState(prequalificationHolder);
  const [allCounties, setAllCounties] = useState([]);
  const [scoreCardRanges, setScoreCardRanges] = useState([]);
  const {
    ApplicationPageFields,
    Sections,
    Model: { PurposePickList: purposePickList, },
  } = prequalificationModelState.ModelHolder;

  const prequalificationGeneralPageFields = ApplicationPageFields?.Prequalification?.General;
  const section = Sections.find(s => s.SectionName === PrequalificationSectionName.Prequalification);
  const [subSection] = section?.SubSections || [];

  useEffect(() => {
    const getCounties = async () => {
      const pars = {
        listName: 'QDCounty',
        sortColumn: '',
        sortOrder: 'ASC',
      };
      const { Result, } = await ListAPI.getLtItemsSearch(pars);

      const data: DropDownItem_[] = (Result || []).map(item => ({
        value: item.Code,
        label: item.Description,
        secondCode: item.SecondCode,
      }));
      setAllCounties(data);
    };

    const getScoreCardRanges = async () => {
      const { Result, } = await PrequalificationAPI.creditScoreOptionsInquire();
      const data: DropDownItem_[] = (Result || []).map(item => ({
        value: item.Value,
        label: item.Name,
      }));
      setScoreCardRanges(data);
    };

    (async () => {
      dispatch(setLoader(true));

      await getCounties();
      await getScoreCardRanges();

      dispatch(setLoader(false));
    })();
  }, [dispatch]);
  // END County drop down options logic

  const pageSettings = useMemo(() => {
    const counties = prequalification.State ? allCounties.filter(ltItem => ltItem.secondCode === prequalification.State) : [];

    const ss = { ...subSection, dataUI: PREQUALIFICATION_SECTION_DATA_UI, };
    return new PrequalificationSettings([prequalificationGeneralPageFields], [ss], { purposePickList, counties, scoreCardRanges, });
  }, [prequalification.State, allCounties, prequalificationGeneralPageFields, subSection, purposePickList, scoreCardRanges]);

  const onChange = (pageField: PageFieldExtended_<QDPrequalification_, QDPrequalification_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, ObjectPropertyType, } = pageField;
    const modified = !ObjectPropertyStr
      ? { ...prequalification, [ObjectProperty]: ObjectPropertyType === 'boolean' ? booleanStringComparison(value) : value, }
      : { ...prequalification, [ObjectProperty]: value, [ObjectPropertyStr]: text, };

    if (ObjectProperty === 'State' && !!modified.County) {
      modified.County = '';
      modified.CountyStr = '';
    }

    setPrequalification(modified);
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QDPrequalification_, QDPrequalification_>[]) => {
    if (invalidPageFields.length === 0) {
      props.onSubmit(prequalification);
    }
  };

  // Check stateless dropdown fields - County and StatedCreditScoreRange
  // Logic requires Counties to be filtered based on State.
  // This mehtod allows pagefields to be refreshed at Page generator logic.
  // For further information check arePageFieldsSame method.
  const pageFieldsComparison = (currentFields, latestFields) => {
    const stateLessFieldsWithOptions = latestFields.filter(field => field.Component?.options?.stateLess);

    const differentPageField = stateLessFieldsWithOptions.find(latestF => {
      const currentF = currentFields.find(cf => cf.FieldName === latestF.FieldName);
      if (!currentF) return false;

      const currentInitialDataNames = currentF?.Component?.options?.initialData?.map(d => d.label).sort();
      const latestInitialDataNames = latestF?.Component?.options?.initialData?.map(d => d.label).sort();
      const noDifference = deepComparison(currentInitialDataNames, latestInitialDataNames);
      return !noDifference;
    });

    return !differentPageField;
  };

  if (isLoading) {
    return null;
  }

  return (
    <Page
      formIdentifier={PREQUALIFICATION_FORM_ID}
      pageSettings={pageSettings}
      onSubmit={onSubmit}
      onChange={onChange}
      arePageFieldsSame={pageFieldsComparison}
      subHolder={prequalification}
      holder={prequalification}
    />
  );
};

export default Prequalification;
