import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PageField_ from '../../../data/models/PageField';
import QDAddress_ from '../../../data/models/QDAddress';
import { ValidationRegexConstants, REGEX } from '../../../utils/Enums';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import Messages from '../../../utils/Messages';
import QDApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { booleanStringComparison, isGoogleUnavailable } from '../../../utils/Helper';
import PersonalAddress_ from '../../../data/models/PersonalAddress';
import { validateAddressEndDate, validateDateRanges } from '../../../utils/Validator';

type PersonalAddressAdditionalSettings = {
  activeAddressIndex: string;
  startDateLabel: string;
  hasIDVerificationShowed: boolean;
  isCurrentAddress: boolean;
};
export default class PersonalAddressSettings_ extends PageSettings<QDApplicationHolder_, PersonalAddress_, PersonalAddressAdditionalSettings> {
  protected pageFieldsMapper(
    pageField: PageField_,
    {
      activeAddressIndex, startDateLabel, hasIDVerificationShowed, isCurrentAddress,
    }
  ): PageFieldExtended_<QDApplicationHolder_, PersonalAddress_> {
    if (!pageField) {
      return null;
    }

    const getAddress = (subHolder: PersonalAddress_): QDAddress_ => subHolder.PersonalAddresses[activeAddressIndex];

    const isMailingAddressHidden = (address: QDAddress_) => !booleanStringComparison(address?.DifferentMailingAddress);

    switch (pageField.FieldName) {
      case 'CurrentAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-current-address',
          ObjectProperty: 'GoogleAPIAddress',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: () => isGoogleUnavailable() || !isCurrentAddress,
          Component: {
            type: 'address',
            regexStr: REGEX.PO_BOX,
            errorMessage: Messages.INVALID_STREET_ADDRESS,
          },
          IsReadOnly: hasIDVerificationShowed,
        });
      case 'PreviousAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-previous-address',
          ObjectProperty: 'GoogleAPIAddress',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: () => isGoogleUnavailable() || isCurrentAddress,
          Component: {
            type: 'address',
            regexStr: REGEX.PO_BOX,
            errorMessage: Messages.INVALID_STREET_ADDRESS,
          },
        });
      case 'StreetAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-street-address',
          Label: 'Street Address', // Fields without DB representation should have Label
          ObjectProperty: 'Address',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          Component: {
            type: 'input',
          },
          IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
        });
      case 'City':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-city-address',
          Label: 'City',
          ObjectProperty: 'City',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          Component: {
            type: 'input',
          },
          IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
        });
      case 'State':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-state-address',
          Label: 'State', // Fields without DB representation should have Label
          ObjectProperty: 'State',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
          IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
        });
      case 'ZipCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-zip-address',
          Label: 'Zip', // Fields without DB representation should have Label
          ObjectProperty: 'Zip',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
          IsReadOnly: hasIDVerificationShowed && isCurrentAddress,
        });
      case 'AddressStartDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-address-start-date',
          ObjectProperty: 'AddressStartDate',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (holder: QDApplicationHolder_, subHolder: PersonalAddress_, pageF: PageFieldExtended_<QDApplicationHolder_, PersonalAddress_>) => {
            const { IsValid, InvalidMessage, } = validateDateRanges(holder, subHolder, pageF);
            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'AddressEndDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-address-end-date',
          ObjectProperty: 'AddressEndDate',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: () => isCurrentAddress,
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
            notClearableOnInvalid: true,
          },
          IsValid: (holder: QDApplicationHolder_, subHolder: PersonalAddress_, pageF: PageFieldExtended_<QDApplicationHolder_, PersonalAddress_>) => {
            const { IsValid: isValidDateRange, InvalidMessage: invalidDateRangeMessage, } = validateDateRanges(holder, subHolder, pageF);
            const pf = pageF;

            if (invalidDateRangeMessage) {
              pf.ValidationMessage = invalidDateRangeMessage;
              return isValidDateRange;
            }

            const address = getAddress(subHolder);
            const { IsValid, InvalidMessage, } = validateAddressEndDate(pageF, address, startDateLabel);

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'CurrentHousingStatus':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-individual-current-housing-status',
          ObjectProperty: 'CurrentHousingStatusCode',
          ObjectPropertyStr: 'CurrentHousingStatusCodeStr',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: () => !isCurrentAddress,
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'CurrentHousingStatus',
              ListType: 'LtItems',
            },
          },
        });
      case 'DifferentMailingAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-different-mailing-address',
          Label: 'Is your mailing address different from your current address?',
          ObjectProperty: 'DifferentMailingAddress',
          ObjectPropertyType: 'boolean',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: () => !isCurrentAddress,
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
        });
      case 'MailingAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-mailing-address',
          Label: 'Mailing Address', // Fields without DB representation should have Label
          ObjectProperty: 'MailingAddress',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: (holder, subHolder: PersonalAddress_) => {
            const address = getAddress(subHolder);
            return isMailingAddressHidden(address);
          },
          Component: {
            type: 'input',
          },
        });
      case 'MailingCity':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-individual-mailing-city',
          Label: 'City', // Fields without DB representation should have Label
          ObjectProperty: 'MailingCity',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: (holder, subHolder: PersonalAddress_) => {
            const address = getAddress(subHolder);
            return isMailingAddressHidden(address);
          },
          Component: {
            type: 'input',
          },
        });
      case 'MailingState':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-individual-mailing-state',
          Label: 'State', // Fields without DB representation should have Label
          ObjectProperty: 'MailingState',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: (holder, subHolder: PersonalAddress_) => {
            const address = getAddress(subHolder);
            return isMailingAddressHidden(address);
          },
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });
      case 'MailingZip':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'borrower-individual-mailing-zip',
          Label: 'Zip Code', // Fields without DB representation should have Label
          ObjectProperty: 'MailingZip',
          ObjectPropertyType: 'string',
          ObjectIndex: activeAddressIndex,
          ObjectType: 'PersonalAddresses',
          IsHiddenCalculated: (holder, subHolder: PersonalAddress_) => {
            const address = getAddress(subHolder);
            return isMailingAddressHidden(address);
          },
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
        });
      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
