import { Reducer as Reducer_ } from 'redux';
import { ModelState as ModelState_, ModelHolderActions as ModelHolderActions_, RenewalModelHolder as RenewalModelHolder_ } from '../../utils/Types';

const initialState: ModelState_<RenewalModelHolder_> = {
  ModelHolder: {
    Renewal: {
      Application: null,
      Invitation: null,
    },
    PageFields: [],
    Model: null,
    ApplicationPageField: null,
    ApplicationSections: [],
  },
  IsModelHolderFetched: false,
};

const renewalModelHolderReducer: Reducer_<ModelState_<RenewalModelHolder_>, ModelHolderActions_> = (state = initialState, action) => {
  switch (action.type) {
    case 'GotRenewalModelHolder': {
      return { ...action.renewalModelState, };
    }
    default:
      return state;
  }
};

export default renewalModelHolderReducer;
