/* eslint import/prefer-default-export: "off" */
/* eslint no-unused-vars: off */
import Question_ from '../../data/models/Question';
import { AnswerType } from '../../utils/Enums';
import { isDateAfter, isDateBefore, isNullOrUndefined } from '../../utils/Helper';
import Messages from '../../utils/Messages';

const maxDate = new Date('12/31/2099');
const minDate = new Date('1/1/1900');

const validateBooleanQuestion = questionBooleanParam => {
  return (
    (questionBooleanParam.AnswerBoolean !== null && questionBooleanParam.AnswerBoolean === false)
    || (questionBooleanParam.AnswerBoolean === true && questionBooleanParam.IfYesFlag === false)
    || (questionBooleanParam.AnswerBoolean === true
      && questionBooleanParam.IfYesFlag === true
      && questionBooleanParam.IfYesAnswer !== null
      && questionBooleanParam.IfYesAnswer !== '')
  );
};

const validateDateQuestion = questionDateParam => {
  const date = questionDateParam.AnswerDate;

  return date !== null && isDateAfter(date, minDate) && isDateBefore(date, maxDate);
};

const validateDecimalQuestion = question => {
  const { AnswerMinValue, AnswerDecimal, AnswerMaxValue, } = question;

  if (AnswerMaxValue !== null && AnswerMinValue !== null) {
    return AnswerMinValue <= AnswerDecimal && AnswerMaxValue >= AnswerDecimal;
  }
  if (AnswerMaxValue !== null && AnswerMinValue === null) {
    return AnswerMaxValue >= AnswerDecimal;
  }
  if (AnswerMaxValue === null && AnswerMinValue !== null) {
    return AnswerMinValue <= AnswerDecimal;
  }
  return true;
};

const validateIntegerQuestion = question => {
  const { AnswerMinValue, AnswerMaxValue, AnswerInteger, } = question;

  if (AnswerMaxValue !== null && AnswerMinValue !== null) {
    return AnswerMinValue <= AnswerInteger && AnswerMaxValue >= AnswerInteger;
  }
  if (AnswerMaxValue !== null && AnswerMinValue === null) {
    return AnswerMaxValue >= AnswerInteger;
  }
  if (AnswerMaxValue === null && AnswerMinValue !== null) {
    return AnswerMinValue <= AnswerInteger;
  }
  return true;
};

const validateYearQuestion = questionYearParam => {
  return (
    questionYearParam.AnswerYear !== null
    && questionYearParam.AnswerYear >= (questionYearParam.AnswerMinValue || minDate.getFullYear())
    && questionYearParam.AnswerYear <= (questionYearParam.AnswerMaxValue || maxDate.getFullYear())
  );
};

const validateMemoQuestion = question => !isNullOrUndefined(question.AnswerMemo) && question.AnswerMemo !== '';

const validateStringQuestion = question => !isNullOrUndefined(question.AnswerString) && question.AnswerString !== '';

export const setValidationMessages = (q: Question_) => {
  const qTemp = q;

  type integerOrDecimalType = 'AnswerDecimal' | 'AnswerInteger';

  const integerOrDecimalValidationMessages = (type: integerOrDecimalType) => {
    if (qTemp[`${type}`] === null) {
      return Messages.REQUIRED_FIELD;
    }
    if (qTemp.AnswerMinValue !== null && qTemp.AnswerMaxValue !== null) {
      return `The value must be between ${qTemp.AnswerMinValue} and ${qTemp.AnswerMaxValue}`;
    }
    if (qTemp.AnswerMinValue !== null && qTemp.AnswerMaxValue === null) {
      return `The value entered cannot be less than ${qTemp.AnswerMinValue}`;
    }
    if (qTemp.AnswerMinValue === null && qTemp.AnswerMaxValue !== null) {
      return `The value entered cannot exceed ${qTemp.AnswerMaxValue}`;
    }
    return Messages.REQUIRED_FIELD;
  };

  switch (qTemp.AnswerType) {
    case AnswerType.AnswerString:
    case AnswerType.AnswerBoolean:
      qTemp.ValidationMessage = Messages.REQUIRED_FIELD;
      return qTemp;
    case AnswerType.AnswerInteger:
      qTemp.ValidationMessage = integerOrDecimalValidationMessages('AnswerInteger');
      return qTemp;
    case AnswerType.AnswerDecimal:
      qTemp.ValidationMessage = integerOrDecimalValidationMessages('AnswerDecimal');
      return qTemp;
    case AnswerType.AnswerDate:
      qTemp.ValidationMessage = q.AnswerDate ? 'Please fill а valid date' : 'Please fill the date';
      return qTemp;
    case AnswerType.AnswerMemo:
      qTemp.ValidationMessage = 'Please fill the needed information';
      return qTemp;
    case AnswerType.AnswerYear:
      qTemp.ValidationMessage = `Please choose an year between
        ${qTemp.AnswerMinValue || 1900} and ${qTemp.AnswerMaxValue || 2099}`;
      return qTemp;

    default:
      qTemp.ValidationMessage = 'Invalid question answer!';
      return qTemp;
  }
};

export const validateQuestion = (question: Question_): boolean => {
  if (question?.IsHidden) return true;

  const {
    AnswerBoolean, AnswerDate, AnswerDecimal, AnswerInteger, AnswerMemo, AnswerString, AnswerYear,
  } = question;

  const allAnswers = [AnswerBoolean, AnswerDate, AnswerDecimal, AnswerInteger, AnswerMemo, AnswerString, AnswerYear];
  const areAllAnswersEmpty = allAnswers.every(questionValue => questionValue === null || questionValue === '');

  if (areAllAnswersEmpty) {
    // If the question has no value and is not mandatory
    return !question?.RequiredFlag;
  }

  switch (question.AnswerType) {
    case AnswerType.AnswerBoolean:
      return validateBooleanQuestion(question);
    case AnswerType.AnswerDate:
      return validateDateQuestion(question);
    case AnswerType.AnswerDecimal:
      return validateDecimalQuestion(question);
    case AnswerType.AnswerInteger:
      return validateIntegerQuestion(question);
    case AnswerType.AnswerMemo:
      return validateMemoQuestion(question);
    case AnswerType.AnswerString:
      return validateStringQuestion(question);
    case AnswerType.AnswerYear:
      return validateYearQuestion(question);
    default:
      return false;
  }
};

export const validateQuestionAndSetValidationMessage = (question: Question_): { IsValid: boolean; ValidationMessage: string } => {
  const qTemp = { ...question, };
  qTemp.ValidationMessage = '';
  qTemp.IsValid = validateQuestion(qTemp);

  if (!qTemp.IsValid) {
    const { ValidationMessage, } = setValidationMessages(qTemp);
    qTemp.ValidationMessage = ValidationMessage;
  }

  const { IsValid, ValidationMessage, } = qTemp;

  return {
    IsValid,
    ValidationMessage,
  };
};
