// eslint-disable-next-line max-classes-per-file
import { IconsSolid } from '@jkhy/vsg-design-system';
import { partition } from 'lodash';
import PageSettings from '../../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../../data/models/PageField';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import Employment_ from '../../../../../data/models/Employment';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationEmployment as QDCoBorrowerApplicationEmployment_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { GrossIncomePeriods } from '../../../../../utils/Enums';
import Message from '../../../../../utils/Messages';
import { formComponentsVisibility } from '../../../../../utils/Helper';

const pageFieldsMapper = <THolder, TSubHolder>(
  pageField: PageField_,
  getDefault: () => PageFieldExtended_<THolder, TSubHolder>
): PageFieldExtended_<THolder, TSubHolder> => {
  if (!pageField) {
    return null;
  }

  const IsGrossFieldsValid = (val, required: boolean) => {
    let value = val;
    if (typeof value === 'number' && value === 0) value = `${value}`;

    if (!!value === false) {
      if (required) {
        return { IsValid: false, ValidationMessage: Message.REQUIRED_FIELD, };
      }
      return { IsValid: true, ValidationMessage: '', };
    }

    return { IsValid: !!value, ValidationMessage: '', };
  };

  switch (pageField.FieldName) {
    case 'Employer':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'EmployerName',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 500,
        },
        dataUI: 'guarantor-income-expenses-employer-name-field',
      });
    case 'JobTitle':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'JobTitle',
        ObjectPropertyType: 'string',
        Component: {
          type: 'input',
          maxLength: 500,
        },
        dataUI: 'guarantor-income-expenses-job-title-field',
      });
    case 'EmploymentType':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'EmploymentTypeCode',
        ObjectPropertyStr: 'EmploymentTypeCodeStr',
        ObjectPropertyType: 'string',
        Component: {
          type: 'select',
          options: {
            initialData: [],
            ListName: 'EmploymentType',
            ListType: 'LtItems',
          },
        },
        dataUI: 'guarantor-income-expenses-employment-type',
      });
    case 'EmploymentStartDate':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'EmploymentStartDate',
        ObjectPropertyType: 'string',
        Component: {
          type: 'datepicker',
          rangeEnd: new Date(),
        },
        dataUI: 'guarantor-income-expenses-employment-start-date-field',
      });
    case 'GrossEmploymentIncome':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'income-expenses-gross-field',
        IsMultipleForm: true,
        FormComponentsVisibility: (holder, subHolder, pf) => {
          return formComponentsVisibility(
            pf.Components,
            `${subHolder?.GrossEmploymentIncomePeriodEnumStr}`,
            'GrossMonthlyEmploymentIncome',
            'GrossAnnuallyEmploymentIncome'
          );
        },
        Components: [
          {
            ObjectProperty: 'GrossEmploymentIncomePeriod',
            ObjectPropertyStr: 'GrossEmploymentIncomePeriodEnumStr',
            Label: 'Period',
            type: 'select',
            options: {
              stateLess: true,
              hideSelectOption: true,
              initialData: [
                { value: '0', label: GrossIncomePeriods.Monthly, },
                { value: '1', label: GrossIncomePeriods.Annually, }
              ],
            },
            ValidationMessage: '',
            IsComponentValid: () => true,
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-monthly-gross-field',
            FieldName: 'GrossMonthlyEmploymentIncome', // used as control Id
            ObjectProperty: 'GrossMonthlyEmploymentIncome',
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { GrossMonthlyEmploymentIncome, GrossEmploymentIncomePeriodEnumStr, } = subHolder;
              if (GrossEmploymentIncomePeriodEnumStr === GrossIncomePeriods.Annually) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossMonthlyEmploymentIncome, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-annually-gross-field',
            FieldName: 'GrossMonthlyEmploymentIncome', // used as control Id
            ObjectProperty: 'GrossAnnuallyEmploymentIncome', // used as control Id
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { GrossAnnuallyEmploymentIncome, GrossEmploymentIncomePeriodEnumStr, } = subHolder;
              if (GrossEmploymentIncomePeriodEnumStr === GrossIncomePeriods.Monthly) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossAnnuallyEmploymentIncome, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          }
        ],
        IsValid: (qdHolder, subHolder, pageF) => {
          if (pageF.IsHidden) return true;

          const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
          const result = invalidPageFieldComponents.length === 0;
          if (!result) {
            invalidPageFieldComponents.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = true;
              return currentComponent;
            });
          } else {
            pageF.Components.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = false;
              return currentComponent;
            });
          }

          return result;
        },
      });
    case 'GrossBonus':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'income-expenses-gross-bonus-field',
        IsMultipleForm: true,
        FormComponentsVisibility: (holder, subHolder, pf) => {
          return formComponentsVisibility(pf.Components, `${subHolder?.GrossBonusPeriodEnumStr}`, 'GrossMonthlyBonus', 'GrossAnnuallyBonus');
        },
        Components: [
          {
            ObjectProperty: 'GrossBonusPeriod',
            ObjectPropertyStr: 'GrossBonusPeriodEnumStr',
            Label: 'Period',
            type: 'select',
            options: {
              stateLess: true,
              hideSelectOption: true,
              initialData: [
                { value: '0', label: GrossIncomePeriods.Monthly, },
                { value: '1', label: GrossIncomePeriods.Annually, }
              ],
            },
            ValidationMessage: '',
            IsComponentValid: () => true,
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-gross-monthly-bonus-field',
            FieldName: 'GrossMonthlyBonus', // used as control Id
            ObjectProperty: 'GrossMonthlyBonus',
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { GrossMonthlyBonus, GrossBonusPeriodEnumStr, } = subHolder;
              if (GrossBonusPeriodEnumStr === GrossIncomePeriods.Annually) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossMonthlyBonus, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-gross-annually-bonus-field',
            FieldName: 'GrossAnnuallyBonus', // used as control Id
            ObjectProperty: 'GrossAnnuallyBonus',
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { GrossAnnuallyBonus, GrossBonusPeriodEnumStr, } = subHolder;
              if (GrossBonusPeriodEnumStr === GrossIncomePeriods.Monthly) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossAnnuallyBonus, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          }
        ],
        IsValid: (qdHolder, subHolder, pageF) => {
          if (pageF.IsHidden) return true;

          const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
          const result = invalidPageFieldComponents.length === 0;
          if (!result) {
            invalidPageFieldComponents.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = true;
              return currentComponent;
            });
          } else {
            pageF.Components.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = false;
              return currentComponent;
            });
          }

          return result;
        },
      });
    case 'OtherIncome':
      return PageFieldExtended_.createInstance({
        ...pageField,
        dataUI: 'income-expenses-alimony-field',
        IsMultipleForm: true,
        FormComponentsVisibility: (holder, subHolder, pf) => {
          return formComponentsVisibility(pf.Components, `${subHolder?.OtherIncomePeriodEnumStr}`, 'OtherMonthlyIncome', 'OtherAnnuallyIncome');
        },
        Components: [
          {
            ObjectProperty: 'OtherIncomePeriod',
            ObjectPropertyStr: 'OtherIncomePeriodEnumStr',
            Label: 'Period',
            type: 'select',
            options: {
              stateLess: true,
              hideSelectOption: true,
              initialData: [
                { value: '0', label: GrossIncomePeriods.Monthly, },
                { value: '1', label: GrossIncomePeriods.Annually, }
              ],
            },
            ValidationMessage: '',
            IsComponentValid: () => true,
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-monhtly-alimony-field',
            FieldName: 'OtherMonthlyIncome', // used as control Id
            ObjectProperty: 'OtherMonthlyIncome',
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { GrossMonthlyBonus, OtherIncomePeriodEnumStr, } = subHolder;
              if (OtherIncomePeriodEnumStr === GrossIncomePeriods.Annually) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossMonthlyBonus, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          },
          {
            Label: 'Amount',
            dataUI: 'income-expenses-annually-alimony-field',
            FieldName: 'OtherAnnuallyIncome', // used as control Id
            ObjectProperty: 'OtherAnnuallyIncome',
            ObjectPropertyType: 'number',
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
            ValidationMessage: '',
            IsComponentValid: (appHolder, subHolder, pageF, component) => {
              const { OtherAnnuallyIncome, OtherIncomePeriodEnumStr, } = subHolder;
              if (OtherIncomePeriodEnumStr === GrossIncomePeriods.Monthly) return true;

              const { IsValid, ValidationMessage, } = IsGrossFieldsValid(OtherAnnuallyIncome, pageF.Required);
              const comp = component;
              comp.ValidationMessage = ValidationMessage;

              return IsValid;
            },
          }
        ],
        IsValid: (qdHolder, subHolder, pageF) => {
          if (pageF.IsHidden) return true;

          const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
          const result = invalidPageFieldComponents.length === 0;
          if (!result) {
            invalidPageFieldComponents.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = true;
              return currentComponent;
            });
          } else {
            pageF.Components.map(c => {
              const currentComponent = c;
              currentComponent.IsInvalidResult = false;
              return currentComponent;
            });
          }

          return result;
        },
      });
    case 'MonthlyHousingExpenses':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'MonthlyHousingExpense',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
        dataUI: 'guarantor-income-expenses-monthly-housing-field',
      });
    case 'OtherMonthlyPayments':
      return PageFieldExtended_.createInstance({
        ...pageField,
        ObjectProperty: 'OtherMonthlyPayments',
        ObjectPropertyType: 'number',
        Component: {
          type: 'maskedinput',
          inputType: 'number',
          icon: IconsSolid.faDollarSign,
          maxLength: 11,
          maxValue: 999999999,
        },
        dataUI: 'guarantor-income-expenses-monthly-alimony-payments-field',
      });
    default:
      return getDefault();
  }
};
export default class IncomeExpensesSettings extends PageSettings<CoBorrowerAddNowHolder_, Employment_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<CoBorrowerAddNowHolder_, Employment_> {
    return pageFieldsMapper(pageField, () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField));
  }
}

export class IncomeExpensesSettings_ extends PageSettings<QDCoBorrowerApplicationHolder_, Employment_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QDCoBorrowerApplicationHolder_, Employment_> {
    return pageFieldsMapper(pageField, () => this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField));
  }
}
