import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../../Page/PageHelpers/PageSettings';
import PageField_ from '../../../../data/models/PageField';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import BorrowerBHolder_ from '../../../../data/models/BorrowerHolder';
import InputMasksConstants from '../../../../utils/InputMasksConstants';

export default class OtherInformationSettings extends PageSettings<QdApplicationHolder_, BorrowerBHolder_, boolean> {
  pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, BorrowerBHolder_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'AGI':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'AGI',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'business-borrower-AGI-field',
        });
      case 'AnnualSales':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'AnnualSales',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'business-borrower-annual-sales-field',
        });
      case 'AverageDeposits':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'AverageDeposits',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'business-borrower-average-deposits-field',
        });
      case 'CheckingAccountNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'CheckingAccountNumber',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: appHolder => {
            const {
              BorrowerB: {
                Business: { IsExistingCustomerFlag, },
              },
            } = appHolder;
            return `${IsExistingCustomerFlag}` === 'false' || IsExistingCustomerFlag === null;
          },
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.CHECKING_ACCOUNT_NUMBER,
            guide: false,
            className: 'text-left',
          },
          dataUI: 'business-borrower-checking-account-number-field',
        });

      case 'ReCheckingAccountNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          Label: 'Re-enter Checking Account #',
          ObjectProperty: 'ReCheckingAccountNum',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: appHolder => {
            const {
              BorrowerB: {
                Business: { IsExistingCustomerFlag, },
              },
            } = appHolder;
            return `${IsExistingCustomerFlag}` === 'false' || IsExistingCustomerFlag === null;
          },
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.CHECKING_ACCOUNT_NUMBER,
            guide: false,
            className: 'text-left',
          },
          IsValid: (qdHolder, subHolder, pageF) => {
            let result = true;
            if (pageF.IsHiddenCalculated(qdHolder, subHolder, pageField)) return result;

            const value = subHolder[pageF.ObjectProperty];
            const compareValue = subHolder.Business?.CheckingAccountNumber;

            if (compareValue) {
              result = !value ? false : value.trim() === compareValue.trim();
            }
            const pf = pageF;
            pf.ValidationMessage = result ? '' : 'Account numbers must match.';

            return result;
          },
          dataUI: 'business-borrower-re-checking-account-number-field',
        });
      case 'NumberOfEmployees':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'NumberOfEmployees',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 9,
            inputType: 'number',
            minValue: 0,
            maxValue: 9999999,
          },
          dataUI: 'business-borrower-number-of-employees-field',
        });
      case 'ExistingAccount':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'IsExistingCustomerFlag',
          ObjectPropertyType: 'string',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
          dataUI: 'business-borrower-existing-customer-field',
        });

      case 'MostRecentYearEndDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Business',
          ObjectProperty: 'MostRecentYearEndDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
          },
          dataUI: 'business-borrower-most-recent-year-end-date-field',
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
