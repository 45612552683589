import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Section } from '@jkhy/vsg-design-system';
import IdologyPage from '../../../IdentityVerification/Idology/Idology';
import PreciseIDPage from '../../../IdentityVerification/PreciseID/PreciseID';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import QDApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationPersonal as QDCoBorrowerApplicationPersonal_,
  QDCoBorrowerApplicationPersonalAddresses as QDCoBorrowerApplicationPersonalAddresses_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import {
  IdentityVerificationSystem, ExternalPages, CoBorrowerAddType, GuarantorPages, ValueConstants
} from '../../../../../utils/Enums';
import {
  ExternalModelHolder as ExternalModelHolder_,
  IdentityVerificationCompleteStatus as IdentityVerificationCompleteStatus_,
  ModelState as ModelState_
} from '../../../../../utils/Types';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_, setCoBorrowerIdentityState } from '../../../../../redux/actions/CoBorrowerIdentity';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import QDPartyDetails_ from '../../../../../data/models/QDPartyDetails';
import { setCoBorrowerAddNowState } from '../../../../../redux/actions/CoBorrowerAddNow';
import { updateCoBorrowerHolder } from '../../../../../redux/actions/CoBorrowerHolder';
import { isVerified } from '../../../../../utils/IdentityVerification';

export default () => {
  const {
    holder, coBorrowerHolder, addNowHolder, coBorrowerIdentityState, modelState, sectionsState,
  } = useSelector<
    AppState_,
    {
      holder: QDApplicationHolder_;
      coBorrowerHolder: QDCoBorrowerApplicationHolder_;
      addNowHolder: CoBorrowerAddNowHolder_;
      coBorrowerIdentityState: CoBorrowerIdentityState_;
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    coBorrowerHolder: state.coBorrowerHolderState.CoBorrowerHolder,
    // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    coBorrowerIdentityState: state.coBorrowerIdentityState,
    modelState: state.modelState,
    sectionsState: state?.sectionsState,
  }));

  const history = useHistory();

  // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
  const coBorrower = coBorrowerHolder?.Personal?.CoBorrower ?? addNowHolder?.CoBorrower;
  const { GUID: qdPartyGuid, /* PartyMatchConfirmedByUser, PartyMatchDone, IdParty, */ } = coBorrower;
  const isAddNow = coBorrower.CoBorrowerAddType === CoBorrowerAddType.AddNow;

  const { IdentityVisited, } = coBorrowerIdentityState;
  const {
    ModelHolder: { Model: model, IdentitySection: identitySection, },
  } = modelState;

  const [identitySubSection] = identitySection.SubSections;

  useEffect(() => {
    const { VisibleSections, CoBorrowerAddNowVisibleSections, } = sectionsState;

    const visibleSorted = isAddNow ? CoBorrowerAddNowVisibleSections : VisibleSections;
    const nextVisibleSection = visibleSorted[visibleSorted.length - 1];
    const nextPage = nextVisibleSection?.Sequence as GuarantorPages;

    let returnUrl = `/guarantor/${model.Code}/${nextPage}/${qdPartyGuid}/`;
    if (isAddNow) {
      returnUrl = nextPage === GuarantorPages.PersonalAddress // check Income & Expenses is Hidden
        ? `/section/${model.Code}/${ExternalPages.CoBorrower}/${holder.GUIDQDApplication}/`
        : `/guarantor-add-now/${model.Code}/${ExternalPages.CoBorrower}/${nextPage}/${holder.GUIDQDApplication}/`;
    }

    if (IdentityVisited) {
      history.push(returnUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdentityVisited]);

  const useIdology = model?.IdentityVerificationSystem === IdentityVerificationSystem.IDology;

  const dispatch = useDispatch();
  const updateIdentityVisited = () => {
    dispatch(
      setCoBorrowerIdentityState({
        ...coBorrowerIdentityState,
        IsIdentityFetched: true,
        IdentityVisited: true,
      })
    );
  };

  const updateHolder = ({ QDParty, Addresses, }: QDPartyDetails_) => {
    if (isAddNow) {
      const newCoBorrowerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: QDParty, Addresses, };
      dispatch(setCoBorrowerAddNowState(newCoBorrowerAddNowHolder, true));
      return;
    }

    const personalBorrower: QDCoBorrowerApplicationPersonal_ = { ...coBorrowerHolder.Personal, CoBorrower: QDParty, };
    const personalAddresses: QDCoBorrowerApplicationPersonalAddresses_ = { ...coBorrowerHolder.AddressesP, PersonalAddresses: Addresses, };

    const newCoBorrowerEmailInvitedHolder: QDCoBorrowerApplicationHolder_ = {
      ...coBorrowerHolder,
      Personal: personalBorrower,
      AddressesP: personalAddresses,
    };

    dispatch(updateCoBorrowerHolder(newCoBorrowerEmailInvitedHolder));
  };

  const completeIdentityVerificationProcess = (
    identityCheckType: typeof ValueConstants.PreciseID | typeof ValueConstants.Idology,
    verificationStatus?: IdentityVerificationCompleteStatus_,
    partyDetails?: QDPartyDetails_
  ) => {
    updateIdentityVisited();

    const isPartyVerified = isVerified(identityCheckType, verificationStatus);

    if (isPartyVerified && partyDetails) {
      updateHolder(partyDetails);
    }
  };

  return (
    <Section
      key="identity-verification"
      dataUI="identity-verification-section"
      title={identitySubSection?.SubSectionName}
      headerText={identitySubSection?.SubSectionHeaderText || identitySection?.SectionHeaderHTML}
      footerText=""
    >
      {useIdology ? (
        <IdologyPage
          borrower={coBorrower}
          formId={`form-${GuarantorPages.IdentityCheck}`}
          completeIdentityVerificationProcess={completeIdentityVerificationProcess}
        />
      ) : (
        <PreciseIDPage
          borrower={coBorrower}
          formId={`form-${GuarantorPages.IdentityCheck}`}
          completeIdentityVerificationProcess={completeIdentityVerificationProcess}
        />
      )}
    </Section>
  );
};
