import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Section, LinkWithIcon, IconsSolid } from '@jkhy/vsg-design-system';
import { QDApplicationTypeAnswer } from '../../../../../data/models/Information';
import PurposeProduct from '../../../ReviewApplication/PurposeProduct/PurposeProduct';
import AutoDetails from '../../../ReviewApplication/AutoDetails/AutoDetails';
import PersonalBorrower from '../../../ReviewApplication/PersonalBorrower/PersonalBorrower';
import BusinessBorrower from '../../../ReviewApplication/BusinessBorrower/BusinessBorrower';
import Employment from '../../../ReviewApplication/Employment/Employment';
import PersonalAddresses from '../../../ReviewApplication/Addresses/PersonalAddresses/PersonalAddresses';
import Disclosures from '../../../ReviewApplication/Disclosures/Disclosures';
import Declarations from '../../../ReviewApplication/Declarations/Declarations';
import Information from '../../../ReviewApplication/Information/Information';
import BusinessOtherInformation from '../../../ReviewApplication/BusinessOtherInformation/BusinessOtherInformation';
import BusinessAddresses from '../../../ReviewApplication/Addresses/BusinessAddresses/BusinessAddresses';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { HolderState as HolderState_, updateExternalHolder } from '../../../../../redux/actions/Holder';
import { setLoader } from '../../../../../redux/actions/Loading';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import {
  ApplicableFor,
  ClientContext,
  DisplayOptions,
  ExternalPages,
  ExternalPageSectionName,
  GuarantorPages,
  GuarantorPageSectionName_
} from '../../../../../utils/Enums';
import QDCoBorrowerApplicationHolder_ from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import { ExpandState as ExpandState_ } from '../../../ReviewApplication/ReviewApplication';
import Guarantors from './Guarantors/Guarantors';
import { areSectionsExpanded, toggleAllSections } from '../../../../../utils/ReviewApplication';
import QDApplicationSection_ from '../../../../../data/models/QDApplicationSection';

type CoBorrowerReviewApplicationProps_ = {
  onSubmit: () => void;
};

const CoBorrowerReviewApplication = (props: CoBorrowerReviewApplicationProps_) => {
  const dispatch = useDispatch();
  const {
    modelState,
    sections,
    holder,
    appHolderState: { Holder: appHolder, IsHolderFetched: isAppHolderFetched, },
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sections: Section_[];
      holder: QDCoBorrowerApplicationHolder_;
      appHolderState: HolderState_;
    }
  >(state => ({
    modelState: state.modelState,
    sections: state.sectionsState.VisibleSections,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    appHolderState: state.holderState,
  }));

  const { ApplicationSections: applicationSections, } = modelState?.ModelHolder;

  const [expandState, setExpandState] = useState<ExpandState_>(null);

  const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;

  const allSectionsExpanded = useMemo(() => {
    return areSectionsExpanded(expandState);
  }, [expandState]);

  const getSearchSections = useMemo(() => {
    return (type: ClientContext) => {
      if (type === ClientContext.Guarantor) {
        return sections;
      }

      // ToDo Once the server has functionality for visible sections for primary borrower application, we need to change to use them
      let result = applicationSections?.filter((s: Section_) => s.DisplayOption === DisplayOptions.Always);
      if (holder?.Information?.ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString()) {
        result = result?.filter((s: QDApplicationSection_) => s?.ApplicableFor !== ApplicableFor.PreSubmitCommercial);
      }
      return result;
    };
  }, [applicationSections, holder, sections]);

  useEffect(() => {
    // Inquire primary borrower application holder
    const getApplicationHolder = async () => {
      dispatch(setLoader(true));

      const { Result: externalHolder, } = await QdServiceApi.getQDApplicationHolderForGuarantoReview(coBorrower?.GUID);

      dispatch(updateExternalHolder(externalHolder));

      dispatch(setLoader(false));
    };

    if (!isAppHolderFetched) {
      getApplicationHolder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const guarantorSections = sections
      .filter((s: Section_) => Number(s.Sequence) <= Number(GuarantorPages.Disclosures) && s.SectionName !== GuarantorPageSectionName_.Welcome)
      .map(({ Sequence, }: Section_) => Sequence as keyof typeof GuarantorPages)
      .reduce((acc, sequence) => ({ ...acc, [sequence]: {}, }), {} as ExpandState_);

    const externalApplicationSections = [
      ExternalPages.PurposeProduct,
      ExternalPages.AutoDetails,
      ExternalPages.PersonalBorrower,
      ExternalPages.BusinessBorrower,
      ExternalPages.CoBorrower
    ].reduce((acc, sequence) => ({ ...acc, [sequence]: {}, }), {} as ExpandState_);

    setExpandState({ ...externalApplicationSections, ...guarantorSections, });
  }, [sections]);

  const getSectionTabName = (type: ClientContext, sectionName: GuarantorPageSectionName_ | ExternalPageSectionName, defaultTabName: string) => {
    const searchSections = getSearchSections(type);

    const foundSection = searchSections?.find((s: Section_) => s.SectionName === sectionName);

    return foundSection?.TabName || defaultTabName;
  };

  const checkIsSectionVisible = (type: ClientContext, sectionName: GuarantorPageSectionName_ | ExternalPageSectionName) => {
    const searchSections = getSearchSections(type);

    return !!searchSections?.find((s: Section_) => s.SectionName === sectionName);
  };

  const toggleSections = (expand: boolean) => {
    const modifiedExpandedState: ExpandState_ = toggleAllSections(expand, expandState);
    setExpandState(modifiedExpandedState);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    props.onSubmit();
  };

  return (
    <>
      {expandState && isAppHolderFetched && (
        <form id={`form-${GuarantorPages.Review}`} onSubmit={onSubmit}>
          <Section title="Review Application" dataUI="review-application-section">
            <LinkWithIcon icon={allSectionsExpanded ? IconsSolid.faCompressAlt : IconsSolid.faExpandAlt}>
              <a
                href="/"
                onClick={e => {
                  e.preventDefault();
                  toggleSections(!allSectionsExpanded);
                }}
              >
                {allSectionsExpanded ? 'Collapse all sections' : 'Expand all sections'}
              </a>
            </LinkWithIcon>

            {/* Purpose and Product */}
            {checkIsSectionVisible(ClientContext.Owner, ExternalPageSectionName.PurposeProduct) && (
              <PurposeProduct
                isCoBorrower={false}
                hasFullAccess={false}
                page={ExternalPages.PurposeProduct}
                sectionTitle={getSectionTabName(ClientContext.Owner, ExternalPageSectionName.PurposeProduct, 'Purpose / Product')}
                expandState={expandState[ExternalPages.PurposeProduct]}
                setExpandState={setExpandState}
              />
            )}

            {/* Auto Details - ToDo - The section should be hidden if no information is filled in */}
            {checkIsSectionVisible(ClientContext.Owner, ExternalPageSectionName.AutoDetails) && (
              <AutoDetails
                isCoBorrower={false}
                hasFullAccess={false}
                page={ExternalPages.AutoDetails}
                sectionTitle={getSectionTabName(ClientContext.Owner, ExternalPageSectionName.AutoDetails, 'Auto Details')}
                expandState={expandState[ExternalPages.AutoDetails]}
                setExpandState={setExpandState}
              />
            )}

            {/* Owner Information */}
            {checkIsSectionVisible(ClientContext.Owner, ExternalPageSectionName.YourInformation) && (
              <PersonalBorrower
                isCoBorrower={false}
                hasFullAccess={false}
                page={ExternalPages.PersonalBorrower}
                personalBorrower={appHolder?.BorrowerP?.Personal}
                sectionTitle={getSectionTabName(ClientContext.Owner, null, 'Primary Applicant')}
                expandState={expandState[ExternalPages.PersonalBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {/* Business Borrower */}
            {checkIsSectionVisible(ClientContext.Owner, ExternalPageSectionName.BusinessInformation) && (
              <BusinessBorrower
                isCoBorrower={false}
                hasFullAccess={false}
                page={ExternalPages.BusinessBorrower}
                businessBorrower={appHolder?.BorrowerB?.Business}
                sectionTitle={getSectionTabName(ClientContext.Owner, ExternalPageSectionName.BusinessInformation, 'Business Borrower Information')}
                expandState={expandState[ExternalPages.BusinessBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {/* Guarantors */}
            {checkIsSectionVisible(ClientContext.Owner, ExternalPageSectionName.Guarantor) && (
              <Guarantors
                isCoBorrower
                page={ExternalPages.CoBorrower}
                sectionTitle={getSectionTabName(ClientContext.Owner, ExternalPageSectionName.Guarantor, 'Guarantor')}
                expandState={expandState[ExternalPages.CoBorrower]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Information */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.Information) && (
              <Information
                isCoBorrower
                page={GuarantorPages.Information}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.Information, 'Information')}
                expandState={expandState[GuarantorPages.Information]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Personal Information */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.PersonalInformation) && (
              <PersonalBorrower
                isCoBorrower
                hasFullAccess
                personalBorrower={holder.Personal.CoBorrower}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.PersonalInformation, 'Your Information')}
                expandState={expandState[GuarantorPages.PersonalInformation]}
                setExpandState={setExpandState}
                page={GuarantorPages.PersonalInformation}
              />
            )}

            {/* Co-Borrower Personal Address */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.PersonalAddress) && (
              <PersonalAddresses
                isCoBorrower
                personalAddresses={holder.AddressesP.PersonalAddresses}
                page={GuarantorPages.PersonalAddress}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.PersonalAddress, 'Personal Address')}
                expandState={expandState[GuarantorPages.PersonalAddress]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Employment - ToDo - The section should be hidden if no information is filled in */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.Employment) && (
              <Employment
                isCoBorrower
                employment={holder.IncomeExpenses.Employment}
                page={GuarantorPages['Income&Expenses']}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.Employment, 'Employment')}
                expandState={expandState[GuarantorPages['Income&Expenses']]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Business Information */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.BusinessInformation) && (
              <BusinessBorrower
                isCoBorrower
                hasFullAccess
                businessBorrower={holder.Business.CoBorrower}
                page={GuarantorPages.BusinessInformation}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.BusinessInformation, 'Business Information')}
                expandState={expandState[GuarantorPages.BusinessInformation]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Business Other Information - ToDo - The section should be hidden if no information is filled in */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.BusinessOtherInformation) && (
              <BusinessOtherInformation
                isCoBorrower
                businessBorrower={holder.Business.CoBorrower}
                page={GuarantorPages.BusinessOtherInformation}
                sectionTitle={getSectionTabName(
                  ClientContext.Guarantor,
                  GuarantorPageSectionName_.BusinessOtherInformation,
                  'Business Other Information'
                )}
                expandState={expandState[GuarantorPages.BusinessOtherInformation]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Business Address */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.BusinessAddress) && (
              <BusinessAddresses
                isCoBorrower
                businessAddresses={holder.AddressesB.BusinessAddresses}
                page={GuarantorPages.BusinessAddresses}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.BusinessAddress, 'Business Address')}
                expandState={expandState[GuarantorPages.BusinessAddresses]}
                setExpandState={setExpandState}
              />
            )}

            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.Declarations) && (
              <Declarations
                isCoBorrower
                page={GuarantorPages.Declarations}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.Declarations, 'Declarations')}
                expandState={expandState[GuarantorPages.Declarations]}
                setExpandState={setExpandState}
              />
            )}

            {/* Co-Borrower Disclosures */}
            {checkIsSectionVisible(ClientContext.Guarantor, GuarantorPageSectionName_.Disclosures) && (
              <Disclosures
                isCoBorrower
                page={GuarantorPages.Disclosures}
                sectionTitle={getSectionTabName(ClientContext.Guarantor, GuarantorPageSectionName_.Disclosures, 'Disclosures')}
                expandState={expandState[GuarantorPages.Disclosures]}
                setExpandState={setExpandState}
              />
            )}
          </Section>
        </form>
      )}
    </>
  );
};

export default CoBorrowerReviewApplication;
