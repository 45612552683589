import { IdentityVerificationCompleteStatus as IdentityVerificationCompleteStatus_ } from './Types';
/* eslint-disable import/prefer-default-export */
import { IdentityState as IdentityState_ } from '../redux/actions/Identity';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../redux/actions/CoBorrowerIdentity';
import {
  IdentityCheckStatus, IdologyVerificationStatuses, PreciseIDDecisions, ValueConstants
} from './Enums';
import Borrower_ from '../data/models/Borrower';

export const checkHasIDVerificationShowed = (borrower: Borrower_, identityState: IdentityState_ | CoBorrowerIdentityState_) => {
  const { IdentityCheck, PartyMatchDone, } = borrower;

  // We save identity state only in FE, so after refresh we lose this state
  // precisely because of this, below we check in the state of the party.
  if (identityState.IdentityVisited) {
    return true;
  }

  // Тhis condition ensures that the user has gone through ID Verification process
  return PartyMatchDone && (IdentityCheck === IdentityCheckStatus.Validated || IdentityCheck === IdentityCheckStatus.NotValidated);
};

export const shouldReInquireHolder = (borrower: Borrower_, verificationStatus: IdentityVerificationCompleteStatus_): boolean => {
  if (!borrower || !verificationStatus) {
    return false;
  }

  const { PartyMatchDone, IdParty, } = borrower;

  return (
    PartyMatchDone && !!IdParty && (verificationStatus === IdologyVerificationStatuses.PASS || verificationStatus === PreciseIDDecisions.Validated)
  );
};

export const isVerified = (
  identityCheckType: typeof ValueConstants.PreciseID | typeof ValueConstants.Idology,
  verificationStatus: IdologyVerificationStatuses | PreciseIDDecisions
) => {
  return identityCheckType === ValueConstants.Idology
    ? verificationStatus === IdologyVerificationStatuses.PASS
    : verificationStatus === PreciseIDDecisions.Validated;
};
