/* eslint-disable max-len */
import { IconsSolid } from '@jkhy/vsg-design-system';
import { partition } from 'lodash';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import IncomeExpenses_ from '../../../data/models/IncomeExpenses';
import Message from '../../../utils/Messages';
import { GrossIncomePeriods, IdentityCheckStatus } from '../../../utils/Enums';
import { booleanStringComparison, formComponentsVisibility, isNullOrUndefined } from '../../../utils/Helper';
import { EmploymentAdditionalSettings as EmploymentAdditionalSettings_ } from '../../../utils/Types';
import { QDApplicationTypeAnswer } from '../../../data/models/Information';

export default class IncomeExpensesSettings extends PageSettings<QdApplicationHolder_, IncomeExpenses_, EmploymentAdditionalSettings_> {
  protected pageFieldsMapper(pageField: PageField_, pagePars: EmploymentAdditionalSettings_): PageFieldExtended_<QdApplicationHolder_, IncomeExpenses_> {
    if (!pageField) {
      return null;
    }

    const IsGrossFieldsValid = (val, required: boolean) => {
      let value = val;
      if (typeof value === 'number' && value === 0) value = `${value}`;

      if (!!value === false) {
        if (required) {
          return { IsValid: false, ValidationMessage: Message.REQUIRED_FIELD, };
        }
        return { IsValid: true, ValidationMessage: '', };
      }

      return { IsValid: !!value, ValidationMessage: '', };
    };

    const isApplicationTypeIndividual = (holder) => (holder as QdApplicationHolder_)?.Information?.ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString();
    const hiddenDBAFields = holder => {
      const {
        BorrowerP: { Personal: { IdParty, PartyMatchConfirmedByUser, IdentityCheck, }, },
      } = holder as QdApplicationHolder_;

      return !isApplicationTypeIndividual(holder)
             || !IdParty
             || !PartyMatchConfirmedByUser
             || IdentityCheck !== IdentityCheckStatus.Validated;
    };

    switch (pageField.FieldName) {
      case 'Employer':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'EmployerName',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 500,
          },
          dataUI: 'income-expenses-employer-name-field',
        });
      case 'JobTitle':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'JobTitle',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 500,
          },
          dataUI: 'income-expenses-job-title-field',
        });
      case 'EmploymentType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'EmploymentTypeCode',
          ObjectPropertyStr: 'EmploymentTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'EmploymentType',
              ListType: 'LtItems',
            },
          },
          dataUI: 'income-expenses-employment-type',
        });
      case 'EmploymentStartDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'EmploymentStartDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
            rangeEnd: new Date(),
          },
          dataUI: 'income-expenses-employment-start-date-field',
        });
      case 'GrossEmploymentIncome':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'income-expenses-gross-field',
          IsMultipleForm: true,
          FormComponentsVisibility: (holder, subHolder, pf) => {
            return formComponentsVisibility(
              pf.Components,
              `${subHolder?.Employment?.GrossEmploymentIncomePeriodEnumStr}`,
              'GrossMonthlyEmploymentIncome',
              'GrossAnnuallyEmploymentIncome'
            );
          },
          Components: [
            {
              ObjectType: 'Employment',
              ObjectProperty: 'GrossEmploymentIncomePeriod',
              ObjectPropertyStr: 'GrossEmploymentIncomePeriodEnumStr',
              Label: 'Period',
              type: 'select',
              options: {
                stateLess: true,
                hideSelectOption: true,
                initialData: [
                  { value: '0', label: GrossIncomePeriods.Monthly, },
                  { value: '1', label: GrossIncomePeriods.Annually, }
                ],
              },
              ValidationMessage: '',
              IsComponentValid: () => true,
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-monthly-gross-field',
              FieldName: 'GrossMonthlyEmploymentIncome', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'GrossMonthlyEmploymentIncome',
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { GrossMonthlyEmploymentIncome, GrossEmploymentIncomePeriodEnumStr, } = subHolder?.Employment;
                if (GrossEmploymentIncomePeriodEnumStr === GrossIncomePeriods.Annually) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossMonthlyEmploymentIncome, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-annually-gross-field',
              FieldName: 'GrossMonthlyEmploymentIncome', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'GrossAnnuallyEmploymentIncome', // used as control Id
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { GrossAnnuallyEmploymentIncome, GrossEmploymentIncomePeriodEnumStr, } = subHolder?.Employment;
                if (GrossEmploymentIncomePeriodEnumStr === GrossIncomePeriods.Monthly) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossAnnuallyEmploymentIncome, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            }
          ],
          IsValid: (qdHolder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
            const result = invalidPageFieldComponents.length === 0;
            if (!result) {
              invalidPageFieldComponents.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = true;
                return currentComponent;
              });
            } else {
              pageF.Components.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = false;
                return currentComponent;
              });
            }

            return result;
          },
        });
      case 'GrossBonus':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'income-expenses-gross-bonus-field',
          IsMultipleForm: true,
          FormComponentsVisibility: (holder, subHolder, pf) => {
            return formComponentsVisibility(
              pf.Components,
              `${subHolder?.Employment?.GrossBonusPeriodEnumStr}`,
              'GrossMonthlyBonus',
              'GrossAnnuallyBonus'
            );
          },
          Components: [
            {
              ObjectType: 'Employment',
              ObjectProperty: 'GrossBonusPeriod',
              ObjectPropertyStr: 'GrossBonusPeriodEnumStr',
              Label: 'Period',
              type: 'select',
              options: {
                stateLess: true,
                hideSelectOption: true,
                initialData: [
                  { value: '0', label: GrossIncomePeriods.Monthly, },
                  { value: '1', label: GrossIncomePeriods.Annually, }
                ],
              },
              ValidationMessage: '',
              IsComponentValid: () => true,
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-gross-monthly-bonus-field',
              FieldName: 'GrossMonthlyBonus', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'GrossMonthlyBonus',
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { GrossMonthlyBonus, GrossBonusPeriodEnumStr, } = subHolder?.Employment;
                if (GrossBonusPeriodEnumStr === GrossIncomePeriods.Annually) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossMonthlyBonus, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-gross-annually-bonus-field',
              FieldName: 'GrossAnnuallyBonus', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'GrossAnnuallyBonus',
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { GrossAnnuallyBonus, GrossBonusPeriodEnumStr, } = subHolder?.Employment;
                if (GrossBonusPeriodEnumStr === GrossIncomePeriods.Monthly) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(GrossAnnuallyBonus, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            }
          ],
          IsValid: (qdHolder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
            const result = invalidPageFieldComponents.length === 0;
            if (!result) {
              invalidPageFieldComponents.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = true;
                return currentComponent;
              });
            } else {
              pageF.Components.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = false;
                return currentComponent;
              });
            }

            return result;
          },
        });
      case 'OtherIncome':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'income-expenses-alimony-field',
          IsMultipleForm: true,
          FormComponentsVisibility: (holder, subHolder, pf) => {
            return formComponentsVisibility(
              pf.Components,
              `${subHolder?.Employment?.OtherIncomePeriodEnumStr}`,
              'OtherMonthlyIncome',
              'OtherAnnuallyIncome'
            );
          },
          Components: [
            {
              ObjectType: 'Employment',
              ObjectProperty: 'OtherIncomePeriod',
              ObjectPropertyStr: 'OtherIncomePeriodEnumStr',
              Label: 'Period',
              type: 'select',
              options: {
                stateLess: true,
                hideSelectOption: true,
                initialData: [
                  { value: '0', label: GrossIncomePeriods.Monthly, },
                  { value: '1', label: GrossIncomePeriods.Annually, }
                ],
              },
              ValidationMessage: '',
              IsComponentValid: () => true,
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-monhtly-alimony-field',
              FieldName: 'OtherMonthlyIncome', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'OtherMonthlyIncome',
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { OtherMonthlyIncome, OtherIncomePeriodEnumStr, } = subHolder?.Employment;
                if (OtherIncomePeriodEnumStr === GrossIncomePeriods.Annually) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(OtherMonthlyIncome, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            },
            {
              Label: 'Amount',
              dataUI: 'income-expenses-annually-alimony-field',
              FieldName: 'OtherAnnuallyIncome', // used as control Id
              ObjectType: 'Employment',
              ObjectProperty: 'OtherAnnuallyIncome',
              ObjectPropertyType: 'number',
              type: 'maskedinput',
              inputType: 'number',
              icon: IconsSolid.faDollarSign,
              maxLength: 11,
              maxValue: 999999999,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                const { OtherAnnuallyIncome, OtherIncomePeriodEnumStr, } = subHolder?.Employment;
                if (OtherIncomePeriodEnumStr === GrossIncomePeriods.Monthly) return true;

                const { IsValid, ValidationMessage, } = IsGrossFieldsValid(OtherAnnuallyIncome, pageF.Required);
                const comp = component;
                comp.ValidationMessage = ValidationMessage;

                return IsValid;
              },
            }
          ],
          IsValid: (qdHolder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
            const result = invalidPageFieldComponents.length === 0;
            if (!result) {
              invalidPageFieldComponents.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = true;
                return currentComponent;
              });
            } else {
              pageF.Components.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = false;
                return currentComponent;
              });
            }

            return result;
          },
        });
      case 'MonthlyHousingExpenses':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'MonthlyHousingExpense',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'income-expenses-monthly-housing-field',
        });
      case 'OtherMonthlyPayments':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'OtherMonthlyPayments',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          dataUI: 'income-expenses-monthly-alimony-payments-field',
        });
      case 'OperatesAsDBA':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'OperatesAsDBA',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [{ label: 'Yes', value: 'true', }, { label: 'No', value: 'false', }],
          },
          Label: 'Are you operating as a DBA?',
          dataUI: 'income-expenses-operates-as-dba-field',
          // eslint-disable-next-line no-unused-vars
          IsHiddenCalculated: (appHolder_, employment_) => !isApplicationTypeIndividual(appHolder_),
        });
      case 'ApplicableDBA':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'IdPartyDBA',
          // Fill PersonalDBA text once selection is made
          ObjectPropertyStr: 'PersonalDBA',
          ObjectPropertyType: 'bigint',
          Component: {
            type: 'radio',
            preventRadioInline: true,
            options: pagePars?.partyDBAs.map(item => {
              return { label: (item?.PersonalDBA || item?.BusinessDBA || 'I have a different DBA'), value: `${item?.Id}`, };
            }),
          },
          Label: 'Which DBA is applicable for this request?',
          dataUI: 'income-expenses-applicable-dba-fields',
          IsHiddenCalculated: (appHolder_, employment_) => {
            const { Employment: { OperatesAsDBA, }, } = employment_;
            const falsyApplicableDBA = !OperatesAsDBA || !booleanStringComparison(OperatesAsDBA); // No
            return hiddenDBAFields(appHolder_) || pagePars?.partyDBAs?.length <= 1 || falsyApplicableDBA;
          },
        });
      case 'PersonalDBA':
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectType: 'Employment',
          ObjectProperty: 'PersonalDBA',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 100,
          },
          Label: 'DBA Name',
          dataUI: 'income-expenses-custom-dba-field',
          IsHiddenCalculated: (appHolder_, employment_) => {
            const { Employment: { OperatesAsDBA, IdPartyDBA, }, } = employment_;
            const falsyApplicableDBA = !OperatesAsDBA || !booleanStringComparison(OperatesAsDBA); // No
            const selectedDBA = (isNullOrUndefined(IdPartyDBA) || `${IdPartyDBA}` !== '0') && pagePars?.partyDBAs?.length > 1;
            return !isApplicationTypeIndividual(appHolder_) || falsyApplicableDBA || selectedDBA;
          },
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
