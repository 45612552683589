/* eslint-disable no-undef */
import React, { FC as FC_ } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { IconsLight } from '@jkhy/vsg-design-system';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../utils/Types';
import HorizontalInfoCardGenerator from '../HorizontalInfoCardGenerator/HorizontalInfoCardGenerator';
import DepositSettings from './DepositSettings';
import { ExternalPages, ExternalPageSectionName } from '../../../utils/Enums';
import QdDeposit_ from '../../../data/models/QDDeposit';
import Deposits_ from '../../../data/models/BusinessDeposits';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';

export interface DepositProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, Deposits_[]>[],
    holder: QdApplicationHolder_,
    section: Deposits_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}

const BusinessDeposits: FC_<DepositProps> = (props: DepositProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const { IdQDApplication, Deposits: DepositModel, } = holder;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.BusinessDeposits);
  const pageSettings = new DepositSettings([pageFieldHolder?.BusinessDeposits.General], [section?.SubSections[0]]);

  const newDeposit = () => {
    const newData: QdDeposit_ = {
      CurrentBalance: null,
      GUID: '',
      IdCompany: BigInt(0),
      IdUserLastModified: BigInt(0),
      NullFields: [],
      TreatAllNulls: false,
      Id: BigInt(0),
      IdQDApplication,
      BankName: '',
      DepositType: null,
      DepositTypeEnumStr: '',
    };
    return newData;
  };

  const onSubmit = async (invalidPageFields, data) => {
    const depositModelModified = { ...DepositModel, Deposits: data, };
    await props.onSubmit(invalidPageFields, holder, depositModelModified, ExternalPages.BusinessDeposits, false);
  };

  const onDepositSave = async deposit => QdServiceApi.saveQDDeposit(deposit);
  const onDepositRemove = async (id: BigInt) => QdServiceApi.removeQDDeposit(id);
  const getSaveHolderAction = (deposits): ISaveHolder_ => {
    const depositModelModified = {
      ...DepositModel,
      Deposits: deposits,
    };
    return {
      holderState: {
        Holder: { ...holder, Deposits: depositModelModified, },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };
  };

  return (
    <>
      {!loading && (
        <HorizontalInfoCardGenerator
          formIdentifier={`form-${ExternalPages.BusinessDeposits}`}
          onSubmit={onSubmit}
          onSave={onDepositSave}
          onRemove={onDepositRemove}
          getSaveHolderAction={getSaveHolderAction}
          pageSettings={pageSettings}
          holder={holder}
          subHolder={DepositModel}
          title="BankName"
          icon={IconsLight.faMoneyCheckEditAlt}
          newObject={newDeposit()}
          list={DepositModel.Deposits}
        />
      )}
    </>
  );
};

export default BusinessDeposits;
