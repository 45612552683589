import React, { FC as FC_, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import {
  ExternalPages, ExternalPageSectionName, GuarantorPages, GuarantorPageSectionName_
} from '../../../../utils/Enums';
import {
  booleanStringComparison, currencyFormat, dateFormat, getSubSectionPageHeader, yesNoOrEmptyAnswer
} from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import Section_ from '../../../../data/models/Section';
import Borrower_ from '../../../../data/models/Borrower';

type BusinessOtherInformationProps = {
  businessBorrower: Borrower_;
} & ReviewApplicationSectionProps_;

const BusinessOtherInformation: FC_<BusinessOtherInformationProps> = ({
  isCoBorrower,
  page,
  businessBorrower,
  sectionTitle,
  expandState,
  setExpandState,
}: BusinessOtherInformationProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField: applicationPageFieldHolder, CoBorrowerPageField: coBorrowerPageFieldHolder, },
    },
    sections,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sections: Section_[];
    }
  >(state => ({
    modelState: state.modelState,
    sections: state.sectionsState.VisibleSections,
  }));

  const applicationPageFields = applicationPageFieldHolder?.BusinessInformation?.OtherInformation;
  const guarantorPageFields = coBorrowerPageFieldHolder?.BusinessInformation?.OtherInformation;
  const pageFields = isCoBorrower ? guarantorPageFields : applicationPageFields;

  // These page fields doesn't exist for co-borrowers
  const existingAccountPageField = applicationPageFields?.ExistingAccount;
  const checkingAccountNumberPageField = applicationPageFields?.CheckingAccountNumber;

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !businessBorrower) return [];

    const {
      Id: businessBorrowerId,
      NumberOfEmployees,
      MostRecentYearEndDate,
      AnnualSales,
      AGI,
      IsExistingCustomerFlag,
      CheckingAccountNumber,
      AverageDeposits,
    } = businessBorrower || {};

    const sectionName = isCoBorrower && page === GuarantorPages.BusinessOtherInformation
      ? GuarantorPageSectionName_.BusinessOtherInformation
      : ExternalPageSectionName.BusinessBorrowerOtherInformation;

    const title = getSubSectionPageHeader(sections, sectionName, 'Additional Information');
    // eslint-disable-next-line max-len
    const linkText = isCoBorrower && page === ExternalPages.CoBorrower ? 'Go to Co-borrower step to edit' : 'Go to business other information step to edit';

    return [
      {
        id: `review-business-borrower-${businessBorrowerId}-other-information-collapse`,
        title,
        pageFields,
        link: { page, text: linkText, },
        data: {
          NumberOfEmployees: {
            dataUI: `business-borrower-${businessBorrowerId}-number-of-employees-field`,
            value: `${NumberOfEmployees ?? ''}`,
          },
          MostRecentYearEndDate: {
            dataUI: `business-borrower-${businessBorrowerId}-most-recent-year-end-date-field`,
            value: dateFormat(MostRecentYearEndDate),
          },
          AnnualSales: {
            dataUI: `business-borrower-${businessBorrowerId}-annual-sales-field`,
            value: currencyFormat(AnnualSales),
          },
          AGI: {
            dataUI: `business-borrower-${businessBorrowerId}-AGI-field`,
            value: currencyFormat(AGI),
          },
          ExistingAccount: {
            dataUI: `business-borrower-${businessBorrowerId}-existing-customer-field`,
            value: yesNoOrEmptyAnswer(IsExistingCustomerFlag),
            pageField: existingAccountPageField,
            isVisible: !existingAccountPageField?.IsHidden && !isCoBorrower,
          },
          CheckingAccountNumber: {
            dataUI: `business-borrower-${businessBorrowerId}-checking-account-number-field`,
            value: CheckingAccountNumber,
            pageField: checkingAccountNumberPageField,
            isVisible: !checkingAccountNumberPageField?.IsHidden && booleanStringComparison(IsExistingCustomerFlag) && !isCoBorrower,
          },
          AverageDeposits: {
            dataUI: `business-borrower-${businessBorrowerId}-average-deposits-field`,
            value: `${AverageDeposits ?? ''}`,
          },
        },
      }
    ];
  }, [pageFields, businessBorrower, isCoBorrower, page, sections, existingAccountPageField, checkingAccountNumberPageField]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessOtherInformation);
