/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThunkDispatch as ThunkDispatch_ } from 'redux-thunk';
import { AnyAction as AnyAction_ } from 'redux';

import {
  ActionsWrapper, Button, IconsSolid, Section, Modal
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../redux/AppState';

import BaseComponent from '../../Layout/BaseComponent';
import PageMissing from '../../PageMissing/PageMissing';

import ActionLog_ from '../../../data/models/QDActionLog';
import QdServiceApi from '../../../data/api/QDServiceApi';
import ResponseBase_ from '../../../data/models/ResponseBase';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import getQdPartyActionCreator, { IGotQDParty as IGotQDParty_, updateGuarantor } from '../../../redux/actions/QDParty';

import { setLoader as setLoaderAction } from '../../../redux/actions/Loading';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import {
  getLocalStorageItem, localStorageKeys, setGuarantorGuid, setModel
} from '../../../utils/LocalStorageManager';
import Borrower_ from '../../../data/models/Borrower';
import {
  IGotHolder as IGotHolder_,
  InputHolderParameters as InputHolderParameters_,
  updateExternalHolder,
  getHolderWithoutResetingInformation as getHolderWithoutResetingInformationAction
} from '../../../redux/actions/Holder';
import { IGotGuarantorEmployment as IGotGuarantorEmployment_, updateGuarantorEmploymentAction } from '../../../redux/actions/GuarantorEmployment';
import {
  BaseType as BaseType_,
  InputSaveParameters as InputSaveParameters_,
  RoutingProps as RoutingProps_,
  UpdateObject as UpdateObject_
} from '../../../utils/Types';
import {
  GuarantorPages,
  PartyType,
  QDPartyStatus,
  ValueConstants,
  IdentityVerificationSystem,
  GuarantorPageSectionName_,
  ContextParams
} from '../../../utils/Enums';
import { IEvalSection as IEvalSection_, updateVisibleSections as updateVisibleSectionsAction } from '../../../redux/actions/VisibleSections';
import Section_ from '../../../data/models/Section';
import Employment_ from '../../../data/models/Employment';
//  import NotImplementedPage from '../../NotImplement/NotImplement';
import CoBorrowerWelcome from '../CoBorrower/Invite/Welcome/CoBorrowerWelcome_';
import CoBorrowerInformation_ from '../CoBorrower/Invite/Information/CoBorrowerInformation_';
import { evalCoBorrowerProcessActionCreator } from '../../../redux/actions/SaveProcess';
import CoBorrowerPersonalInformation_ from '../CoBorrower/Invite/PersonalInformation/CoBorrowerPersonalInformation_';
import CoBorrowerPersonalPartyMatch from '../CoBorrower/AddNow/PartyMatch/IndividualMatch';
import IncomeExpenses_ from '../CoBorrower/Invite/IncomeExpenses/IncomeExpenses_';
import PersonalAddress from '../CoBorrower/Invite/Address/PersonalAddress';
import CoBorrowerBusinessAddress from '../CoBorrower/Invite/Address/BusinessAddress';
import CoBorrowerBusinessInformation from '../CoBorrower/Invite/BusinessInformation/CoBorrowerBusinessInformation';
import CoBorrowerBusinessOtherInformation from '../CoBorrower/Invite/BusinessOtherInformation/CoBorrowerBusinessOtherInformation';
import CoBorrowerBusinessPartyMatch from '../CoBorrower/AddNow/PartyMatch/BusinessMatch';
import CoBorrowerIdentityCheck from '../CoBorrower/AddNow/IdentityCheck/IdentityCheck';
import CoBorrowerDisclosures from '../CoBorrower/Invite/Disclosures/CoBorrowerDisclosures';
import CoBorowerTerms from '../CoBorrower/Invite/Terms/Terms';
import CoBorowerDeclarations from '../CoBorrower/Invite/Declarations/CoBorrowerDeclarations';
import CoBorowerReviewApplication from '../CoBorrower/Invite/ReviewApplication/CoBorrowerReviewApplication';
import CoBorowerComplete from '../CoBorrower/Invite/Complete/CoBorrowerComplete';

import QDCoBorrowerApplicationHolder_ from '../../../data/models/QDCoBorrowerApplicationHolder';
import {
  SubmitButtonState as SubmitButtonState_,
  UpdateAction as UpdateAction_,
  updateButton as updateButtonAction
} from '../../../redux/actions/SubmitButton';
import {
  IGotCoBorrowerHolder as IGotCoBorrowerHolder_,
  updateCoBorrowerHolder,
  updateCoBorrowerHolderFromSaveResponse,
  ICoBorrowerSaveHolder as ICoBorrowerSaveHolder_,
  IgnoreChangeHolder as IgnoreChangeCoBorrowerHolder_
} from '../../../redux/actions/CoBorrowerHolder';
import { setCoborrowerSubmitted } from '../../../redux/actions/CoBorrowerSubmitted';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_, setCoBorrowerIdentityState } from '../../../redux/actions/CoBorrowerIdentity';
import { updateStopApplicationCondition as updateStopApplicationConditionAction } from '../../../redux/actions/AppSettings';

type GuarantorBaseProps = {
  getQdParty: (guid: string) => void;
  setLoader: (loading: boolean) => void;
  updateExternalHolder: (holder: QdApplicationHolder_) => IGotHolder_;
  updateGuarantor: (party: Borrower_) => IGotQDParty_;
  updateGuarantorEmployment: (guarantorEmployment: Employment_) => IGotGuarantorEmployment_;
  updateCoBorrowerHolder_: (holder: QDCoBorrowerApplicationHolder_) => IGotCoBorrowerHolder_;

  saveEvalSection?: (params: InputSaveParameters_) => Promise<number>;
  updateSubmitButton: (params: SubmitButtonState_) => UpdateAction_;
  updateVisibleSections: (visibleSections: Section_[], isReviewed: boolean) => IEvalSection_;
  updateCoBorrowerHolderFromSave: (params: ICoBorrowerSaveHolder_ | IgnoreChangeCoBorrowerHolder_) => void;

  setCoborrowerSubmitted_: (submitted: boolean) => void;
  setIdentityState: (coBorrowerIdentityState: CoBorrowerIdentityState_) => void;
  getHolderWithoutResetingInformation?: (
    params: InputHolderParameters_,
    currentHolder: QDCoBorrowerApplicationHolder_
  ) => Promise<IGotHolder_ | IGotCoBorrowerHolder_>;
  updateStopApplicationCondition: (isApplicationStop: boolean) => void;
} & BaseType_;

type GuarantorBaseState = {
  dataLoaded: boolean;
  guarantorSubmitted: boolean;
  isWithdrawnConfirmationOpened: boolean;
};

export const isGuarantorIncomeExpensesVisible = (partyType: PartyType): boolean => partyType === PartyType.Personal;

class GuarantorBase extends BaseComponent<GuarantorBaseProps, GuarantorBaseState> {
  constructor(props: GuarantorBaseProps) {
    super(props);
    this.state = {
      dataLoaded: false,
      guarantorSubmitted: false,
      isWithdrawnConfirmationOpened: false,
    };

    this.cancelCoBorrowerApplication = this.cancelCoBorrowerApplication.bind(this);
    this.submit = this.submit.bind(this);
    this.logApplication = this.logApplication.bind(this);
    this.onSubmit_ = this.onSubmit_.bind(this);
  }

  cancelCoBorrowerApplication = async () => {
    const { setLoader, updateCoBorrowerHolder_, } = this.props;

    setLoader(true);

    const {
      coBorrowerHolderState: { CoBorrowerHolder: holder, },
    } = this.props;

    const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;

    const res = await QdServiceApi.declineCoBorrowerApplication(coBorrower?.GUID);

    if (res.Success) {
      const newCoBorrower = {
        ...coBorrower,
        Status: QDPartyStatus.Declined,
      };

      const newCoBorrowerData = coBorrower?.PartyType === PartyType.Personal
        ? { Personal: { ...holder.Personal, CoBorrower: newCoBorrower, }, }
        : { Business: { ...holder.Business, CoBorrower: newCoBorrower, }, };

      updateCoBorrowerHolder_({ ...holder, ...newCoBorrowerData, });

      this.setState(prevState => ({ ...prevState, isWithdrawnConfirmationOpened: false, }));
      this.navigate(GuarantorPages.Complete);
    }

    setLoader(false);
  };

  saveHolder = party => {
    const {
      holderState: { Holder: holderModified, },
    } = this.props;

    if (holderModified?.Guarantor?.Guarantors?.length) {
      // eslint-disable-next-line max-len
      const mappedGuarantors = holderModified?.Guarantor?.Guarantors.map(guarantor => (guarantor?.GUID?.toUpperCase() === party?.GUID?.toUpperCase() ? party : guarantor));
      holderModified.Guarantor.Guarantors = mappedGuarantors;

      this.props.updateExternalHolder(holderModified);
    }
  };

  onSubmit = async () => {
    const {
      setLoader,
      setCoborrowerSubmitted_,
      updateCoBorrowerHolder_,
      coBorrowerHolderState: { CoBorrowerHolder: holder, },
      match: {
        params: { partyGUID, },
      },
    } = this.props;
    setLoader(true);
    const { Result, } = await QdServiceApi.submitGuarantor(partyGUID);
    if (Result?.QDPartyStatus === QDPartyStatus.Submitted) {
      setCoborrowerSubmitted_(true);

      const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;
      const modifiedCoBorrower = { ...coBorrower, Status: QDPartyStatus.Submitted, };
      const modifiedCoBorrowerHolder = modifiedCoBorrower?.PartyType === PartyType.Personal
        ? { Personal: { ...holder?.Personal, CoBorrower: modifiedCoBorrower, }, }
        : { Business: { ...holder.Business, CoBorrower: modifiedCoBorrower, }, };
      updateCoBorrowerHolder_({ ...holder, ...modifiedCoBorrowerHolder, });

      this.navigate(GuarantorPages.Complete);
    }
    setLoader(false);
  };

  async componentDidMount() {
    const {
      match: {
        params: { partyGUID, model, },
      },
    } = this.props;

    await this.storeSessionKeys(model, partyGUID);
  }

  storeSessionKeys = async (model: string, partyGuid: string) => {
    setModel(model);
    setGuarantorGuid(partyGuid);
  };

  async componentDidUpdate() {
    const {
      //  match: { params: { partyGUID, }, },
      modelState: { IsModelHolderFetched, /*  ModelHolder: { Sections, }, */ },
      coBorrowerHolderState: {
        IsCoBorrowerHolderFetched,
        CoBorrowerHolder: { Personal, Business, Expired, },
      },
      sectionsState: { VisibleSections, },
      isCoBorrowerSubmitted,
      //  getQdParty,
    } = this.props;

    /* const shouldLoadParty = IsModelHolderFetched && !IsQDPartyFetching && !IsQDPartyFetched && !this.state.dataLoaded;

     if (shouldLoadParty && partyGUID) {
      await getQdParty(partyGUID);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true, });
    } */

    // const { dataLoaded, guarantorSubmitted, } = this.state;
    // if (IsQDPartyFetched && dataLoaded) {
    //   const navigateToExpired = QDParty?.Status === QDPartyStatus.Submitted && !guarantorSubmitted;
    //   if (navigateToExpired) this.navigateTo(GuarantorPages.Disabled);
    // }

    if (IsCoBorrowerHolderFetched && IsModelHolderFetched && !this.state.dataLoaded) {
      const lastPage = VisibleSections[VisibleSections.length - 1];

      const coBorrower = Personal?.CoBorrower ?? Business?.CoBorrower;
      const isComplete = isCoBorrowerSubmitted
        || [QDPartyStatus.Stopped, QDPartyStatus.FailedAuthentication, QDPartyStatus.Submitted, QDPartyStatus.Declined].includes(coBorrower?.Status);

      const page = isComplete || Expired ? GuarantorPages.Complete : lastPage.Sequence;
      this.navigate(page);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dataLoaded: true, });
    }
  }

  saveParty = async (qdParty: Borrower_, status: QDPartyStatus) => {
    const party = qdParty;
    party.Status = status;

    const result = await QdServiceApi.saveQdParty(party);
    if (result.Success) this.props.updateGuarantor(party);

    return result;
  };

  saveEmployment = async (guarantorEmployment: Employment_) => {
    const result = await QdServiceApi.saveQdEmployment(guarantorEmployment);

    if (result.Success) this.props.updateGuarantorEmployment(guarantorEmployment);

    return result;
  };

  logApplication = (pars: ActionLog_): Promise<ResponseBase_<boolean>> => QdServiceApi.storeApplicationLog(pars);

  onSubmit_ = async (invalidPageFields: PageFieldExtended_<any, any>[], holder, updateSection, sequence) => {
    if (invalidPageFields && invalidPageFields.length > 0) {
      return;
    }
    const { saveEvalSection, } = this.props;

    const { model, guid, partyGUID, } = this.props.match.params;

    const {
      modelState: { ModelHolder: modelHolder, },
      coBorrowerIdentityState,
      submitToReviewButtonState,
      updateSubmitButton,
      //  updateVisibleSections,
      updateCoBorrowerHolderFromSave,
      setLoader,
      updateStopApplicationCondition,
    } = this.props;

    const updateObject: UpdateObject_ = {
      FromGuarantor: true,
      IdQDApplication: holder?.IdQDApplication,
      GUIDQDApplication: guid,
      IdQDModel: modelHolder?.Model?.Id,
      QDApplicationModelCode: model,
      SectionName: updateSection.SectionName,
      Sequence: sequence,
      identityState: coBorrowerIdentityState,
      PartyGUID: partyGUID,
    };

    Object.keys(holder).some(prop => {
      if ({}.hasOwnProperty.call(holder, prop)) {
        const section = holder[prop];
        if (section && typeof section === 'object') {
          if (section.SectionName === updateSection.SectionName) {
            updateObject[prop] = updateSection;
            return true;
          }
        }
      }
      return false;
    });

    if (sequence === GuarantorPages.BusinessOtherInformation) {
      updateObject.SectionName = GuarantorPageSectionName_.BusinessOtherInformation;
    }

    const useIDology = modelHolder?.Model && modelHolder?.Model?.IdentityVerificationSystem === IdentityVerificationSystem.IDology;
    const identityCheckPars = {
      SectionName: useIDology ? ValueConstants.Idology : ValueConstants.PreciseID,
      GUIDQDParty: holder?.Personal?.CoBorrower?.GUID,
      IdQDParty: holder?.Personal?.CoBorrower?.Id,
      DisplayOption: modelHolder?.Model?.ValidationDisplayOptionGuarantor,
      IdBusinessRule: modelHolder?.Model?.IdBusinessRuleValidationGuarantor,
      GUIDQDApplication: holder?.GUIDQDApplication,
      IdQDApplication: holder?.IdQDApplication,
      FromGuarantor: true,
    };

    const pars: InputSaveParameters_ = {
      updateObject,
      allSections: modelHolder.ApplicationSections,
      holder,
      identityCheckPars,
    };

    let nextPageSequence;
    if (submitToReviewButtonState.submitToReview) {
      setLoader(true);

      nextPageSequence = parseInt(GuarantorPages.Review, 10);

      updateSubmitButton({ submitToReview: false, show: true, });

      const guid_ = holder?.Personal?.CoBorrower?.GUID ?? holder?.Business?.CoBorrower?.GUID;

      const res = await QdServiceApi.saveCoBorrowerSection({ ...updateObject, GUIDQDParty: guid_, });
      if (res?.Result) {
        const holderAction = updateCoBorrowerHolderFromSaveResponse(sequence, holder, res.Result);

        if (holderAction?.type === 'SaveCoBorrowerHolder') {
          updateCoBorrowerHolderFromSave(holderAction);
        }
      }

      setLoader(false);
    } else {
      nextPageSequence = await saveEvalSection(pars);
    }

    if (nextPageSequence !== ValueConstants.ShouldStopApplication) this.navigate(nextPageSequence.toString());
    else updateStopApplicationCondition(true);
  };

  navigate = (nextSequence: string) => {
    const {
      history,
      location: { search, },
      holderState: { Holder: holder, },
    } = this.props;

    history.push(this.getUrl(this.props.match.params, holder, nextSequence, search));
  };

  getUrl = (routingParams: RoutingProps_, holder: QdApplicationHolder_, nextSequence: string, locationSearch: string = ''): string => {
    const { model, partyGUID, } = routingParams;

    // if (holder?.Expired) return `/expired/${model}/${guid || holder?.GUIDQDApplication}/`; //TODO
    //
    // if (holder?.CompleteFlag) {
    //   const completePageCheck = (['documents', 'review', 'status'].includes(completePage)) ? completePage : 'complete';
    //   return `/${completePageCheck}/${model}/${guid || holder?.GUIDQDApplication}/`;
    // }
    // return `/section/${model}/${sequence}/${guid || holder?.GUIDQDApplication}`;
    return `/guarantor/${model}/${nextSequence}/${partyGUID || ''}/${locationSearch}`;
  };

  renderGuarantorPage = (page: GuarantorPages) => {
    switch (page) {
      case GuarantorPages.Welcome:
        return <CoBorrowerWelcome onSubmit={this.onSubmit_} logApplication={this.logApplication} />;
      case GuarantorPages.Information:
        return <CoBorrowerInformation_ onSubmit={this.onSubmit_} />;
      case GuarantorPages.PersonalPartyMatch:
        return <CoBorrowerPersonalPartyMatch onSubmit={this.onSubmit_} />;
      case GuarantorPages.PersonalInformation:
        return <CoBorrowerPersonalInformation_ onSubmit={this.onSubmit_} />;
      case GuarantorPages['Income&Expenses']:
        return <IncomeExpenses_ onSubmit={this.onSubmit_} />;
      case GuarantorPages.IdentityCheck:
        return <CoBorrowerIdentityCheck />;
      case GuarantorPages.BusinessPartyMatch:
        return <CoBorrowerBusinessPartyMatch onSubmit={this.onSubmit_} />;
      case GuarantorPages.BusinessInformation:
        return <CoBorrowerBusinessInformation onSubmit={this.onSubmit_} />;
      case GuarantorPages.BusinessOtherInformation:
        return <CoBorrowerBusinessOtherInformation onSubmit={this.onSubmit_} />;
      case GuarantorPages.Declarations:
        return <CoBorowerDeclarations onSubmit={this.onSubmit_} />;
      case GuarantorPages.Disclosures:
        return <CoBorrowerDisclosures onSubmit={this.onSubmit_} />;
      case GuarantorPages.Terms:
        return <CoBorowerTerms onSubmit={this.onSubmit_} />;
      case GuarantorPages.Review:
        return <CoBorowerReviewApplication onSubmit={this.onSubmit} />;
      case GuarantorPages.Complete:
        return <CoBorowerComplete />;
      case GuarantorPages.PersonalAddress:
        return <PersonalAddress onSubmit={this.onSubmit_} />;
      case GuarantorPages.BusinessAddresses:
        return <CoBorrowerBusinessAddress onSubmit={this.onSubmit_} />;
      default:
        return <PageMissing />;
    }
  };

  checkIsNotInterestedButtonVisible = (coBorrSequence: string, coBorrowerStatus: QDPartyStatus) => {
    const context = getLocalStorageItem(localStorageKeys.CONTEXT);
    return context !== ContextParams.CurrentRequests && coBorrSequence === GuarantorPages.Welcome && coBorrowerStatus === QDPartyStatus.Pending;
  };

  content = () => {
    const {
      match: {
        params: { coBorrSequence, },
      },
      coBorrowerHolderState: { IsCoBorrowerHolderFetched, CoBorrowerHolder, },
      submitToReviewButtonState,
      sectionsState,
      updateSubmitButton,
    } = this.props;

    const coBorrower = CoBorrowerHolder?.Personal?.CoBorrower ?? CoBorrowerHolder?.Business?.CoBorrower;
    const showNavSteps = ![GuarantorPages.Authentication].includes(coBorrSequence); // GuarantorPages.Disabled GuarantorPages.Welcome,
    const showActions = ![GuarantorPages.Complete].includes(coBorrSequence);
    const ready = this.state.dataLoaded;

    const isNotInterestedButtonVisible = this.checkIsNotInterestedButtonVisible(coBorrSequence, coBorrower?.Status);

    return (
      <>
        {/* {ready && coBorrSequence === GuarantorPages.PersonalInformation && (
          <h4 className="mb-3">
            <TextEditorContentWrapper>
              {htmlParser(qdModel.GuarantorSectionHeaderHTML)}
            </TextEditorContentWrapper>
          </h4>
        )}  */}

        {ready && this.renderGuarantorPage(coBorrSequence)}

        {showNavSteps && showActions && (
          <Section dataUI="guarantor-base-section" className="mt-3">
            {/* {ready && coBorrSequence === GuarantorPages.PersonalInformation && (
              <p className="body2 mb-2">
                <TextEditorContentWrapper>
                  {htmlParser(qdModel.GuarantorSectionFooterHTML)}
                </TextEditorContentWrapper>
              </p>
            )}  */}

            {submitToReviewButtonState.show && (
              <ActionsWrapper dataUI="guarantor-base-action-wrapper" className="mb-1">
                {isNotInterestedButtonVisible && (
                  <Button
                    dataUI="guarantor-base-not-interested-button"
                    className="action-spacer"
                    type="button"
                    btnType="secondary"
                    onClick={() => this.setState(prevState => ({ ...prevState, isWithdrawnConfirmationOpened: true, }))}
                  >
                    Not Me/Not Interested
                  </Button>
                )}

                <Button
                  dataUI="guarantor-base-continue-submit-button"
                  form={`form-${coBorrSequence}`}
                  type="submit"
                  disabled={coBorrSequence === GuarantorPages.Terms && IsCoBorrowerHolderFetched && !CoBorrowerHolder?.AgreedFlag}
                  iconRight={IconsSolid.faChevronRight}
                >
                  {coBorrSequence !== GuarantorPages.Review ? 'Continue' : 'Submit Application and Continue'}
                </Button>
              </ActionsWrapper>
            )}

            {sectionsState.IsReviewed && submitToReviewButtonState.show && coBorrSequence !== GuarantorPages.Review && (
              <ActionsWrapper dataUI="guarantor-base-section-actions-wrapper" className="mb-1">
                <Button
                  type="submit"
                  dataUI="guarantor-base-section-save-button"
                  className="ml-3"
                  form={`form-${coBorrSequence}`}
                  iconLeft={IconsSolid.faSave}
                  onClick={() => updateSubmitButton({ show: true, submitToReview: true, })}
                >
                  Save/Return to Review Application
                </Button>
              </ActionsWrapper>
            )}

            {coBorrSequence === GuarantorPages.Welcome && coBorrower?.Status === QDPartyStatus.Pending && (
              <Modal
                dataUI="withdraw-from-application-confirmation-modal"
                title="Withdraw from Application"
                isVisible={this.state.isWithdrawnConfirmationOpened}
                isClosable
                closeBtnName="No"
                onClose={() => this.setState(prevState => ({ ...prevState, isWithdrawnConfirmationOpened: false, }))}
                actionBtnName="Yes"
                onSubmit={() => this.cancelCoBorrowerApplication()}
                size="M"
              >
                <p>Are you sure you want to withdraw from this application?</p>
              </Modal>
            )}
          </Section>
        )}
      </>
    );
  };
}

const mapStateToProps = (state: AppState_): AppState_ => ({ ...state, });

const mapDispatchToProps = (dispatch: ThunkDispatch_<any, any, AnyAction_>) => {
  return {
    getQdParty: (guid: string) => dispatch(getQdPartyActionCreator(guid)),
    setLoader: (loading: boolean) => dispatch(setLoaderAction(loading)),
    updateGuarantor: (party: Borrower_) => dispatch(updateGuarantor(party)),
    updateGuarantorEmployment: (guarantorEmployment: Employment_) => dispatch(updateGuarantorEmploymentAction(guarantorEmployment)),
    updateExternalHolder: (holder: QdApplicationHolder_) => dispatch(updateExternalHolder(holder)),
    updateCoBorrowerHolder_: (holder: QDCoBorrowerApplicationHolder_) => dispatch(updateCoBorrowerHolder(holder)),
    saveEvalSection: (params: InputSaveParameters_) => dispatch(evalCoBorrowerProcessActionCreator(params)),
    updateSubmitButton: (params: SubmitButtonState_) => dispatch(updateButtonAction(params)),
    updateVisibleSections: (visibleSections: Section_[], isReviewed: boolean) => dispatch(updateVisibleSectionsAction(visibleSections, isReviewed)),
    updateCoBorrowerHolderFromSave: (action: ICoBorrowerSaveHolder_ | IgnoreChangeCoBorrowerHolder_) => dispatch(action),
    setCoborrowerSubmitted_: (submitted: boolean) => dispatch(setCoborrowerSubmitted(submitted)),
    setIdentityState: (coBorrowerIdentityState: CoBorrowerIdentityState_) => dispatch(setCoBorrowerIdentityState(coBorrowerIdentityState)),
    // eslint-disable-next-line max-len
    getHolderWithoutResetingInformation: (params: InputHolderParameters_, currentHolder: QDCoBorrowerApplicationHolder_) => dispatch(getHolderWithoutResetingInformationAction(params, currentHolder)),
    updateStopApplicationCondition: (isApplicationStop: boolean) => dispatch(updateStopApplicationConditionAction(isApplicationStop)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GuarantorBase));
