/* eslint no-console: "off" */
import BaseAPI from './BaseApi';

export default class SystemApi extends BaseAPI {
  static logError = error => BaseAPI.performAjax({
    method: 'POST',
    data: JSON.stringify(error),
    url: `${BaseAPI.BASE_URL}api/System/LogError`,
  }).catch(err => {
    console.error(err);
  });

  static logInfo = message => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/System/LogInformation`,
    data: JSON.stringify(message),
  }).catch(err => {
    console.error(err);
  });

  static attachErrorListener = () => {
    window.addEventListener('error', event => {
      const {
        message, lineno, colno, error, filename,
      } = event;
      SystemApi.logError(`message: ${message}, lineno: ${lineno}, colno: ${colno}, error: ${error}, filename: ${filename}`);
    });
  };
}
