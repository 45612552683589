import { Action as Action_ } from 'redux';

export type DisclosuresState = {
  informationDisclosures: {
    hasDisclosures: boolean;
    isFetched: boolean;
  };
  mainDisclosures: {
    hasDisclosures: boolean;
    isFetched: boolean;
  };
};

export const setInformationDisclosures = (payload: { hasDisclosures: boolean; isFetched: boolean }): InformationDisclosuresAction => {
  return {
    payload,
    type: 'InformationDisclosures',
  };
};

export const setMainDisclosures = (payload: { hasDisclosures: boolean; isFetched: boolean }): MainDisclosuresAction => {
  return {
    payload,
    type: 'MainDisclosures',
  };
};

interface InformationDisclosuresAction extends Action_<'InformationDisclosures'> {
  payload: {
    hasDisclosures: boolean;
    isFetched: boolean;
  };
}

interface MainDisclosuresAction extends Action_<'MainDisclosures'> {
  payload: {
    hasDisclosures: boolean;
    isFetched: boolean;
  };
}

export type DisclosuresActions = InformationDisclosuresAction | MainDisclosuresAction;
