import React, { FC as FC_, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { getFullName, maskText, percentageFormat } from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import Borrower_ from '../../../../data/models/Borrower';
import { ExternalPages } from '../../../../utils/Enums';

type BusinessBorrowerProps = {
  businessBorrower: Borrower_;
  hasFullAccess: boolean;
} & ReviewApplicationSectionProps_;

const BusinessBorrower: FC_<BusinessBorrowerProps> = ({
  isCoBorrower,
  hasFullAccess,
  businessBorrower,
  page,
  sectionTitle,
  expandState,
  setExpandState,
}: BusinessBorrowerProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    modelState: state.modelState,
  }));

  const pageFields = isCoBorrower ? CoBorrowerPageField?.BusinessInformation?.General : ApplicationPageField?.BusinessInformation?.General;

  const relationshipPageField = ApplicationPageField?.Guarantor?.General?.Relationship;
  const percentOwnershipPageField = CoBorrowerPageField?.BusinessInformation?.General?.PercentOwnership;

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !businessBorrower) return [];

    const {
      Id: businessBorrowerId,
      FullName,
      BorrowerTypeStr,
      DBA,
      TaxEntityTypeCodeStr,
      TIN,
      CompanyTraits,
      StateFormedCode,
      OrganizationTypeCodeStr,
      PreferredPhone,
      WebSite,
      Email,
      PercentOwnership,
    } = businessBorrower || {};

    const fullName = getFullName(businessBorrower);

    const isCoBorrowerAddNow = isCoBorrower && page === ExternalPages.CoBorrower;
    const title = isCoBorrowerAddNow ? fullName : 'Name:';
    const subTitle = isCoBorrowerAddNow ? '' : fullName;

    const linkText = isCoBorrowerAddNow ? 'Go to Co-borrower step to edit' : 'Go to business information step to edit';

    return [
      {
        id: `review-business-borrower-${businessBorrowerId}-information-collapse`,
        title,
        subTitle,
        pageFields,
        ...(hasFullAccess ? { link: { page, text: linkText, }, } : {}),
        data: {
          BorrowerType: {
            dataUI: `business-borrower-${businessBorrowerId}-borrower-type-field`,
            pageField: relationshipPageField,
            value: BorrowerTypeStr,
            isVisible: isCoBorrowerAddNow && !relationshipPageField.IsHidden,
          },
          BusinessName: {
            dataUI: `business-borrower-${businessBorrowerId}-name-field`,
            value: FullName,
          },
          DBA: {
            dataUI: `business-borrower-${businessBorrowerId}-dba-field`,
            value: DBA,
          },
          TaxEntityType: {
            dataUI: `business-borrower-${businessBorrowerId}-tax-entity-type-field`,
            value: TaxEntityTypeCodeStr,
          },
          TaxID: {
            dataUI: `business-borrower-${businessBorrowerId}-tax-ID-field`,
            value: hasFullAccess ? TIN : maskText(TIN, '*', TIN.length - 4),
          },
          CompanyTraits: {
            dataUI: `business-borrower-${businessBorrowerId}-company-traits-field`,
            value: CompanyTraits,
          },
          StateFormed: {
            dataUI: `business-borrower-${businessBorrowerId}-state-formed-field`,
            value: StateFormedCode,
          },
          OrganizationType: {
            dataUI: `business-borrower-${businessBorrowerId}-organization-type-field`,
            value: OrganizationTypeCodeStr,
          },
          PhoneNumber: {
            dataUI: `business-borrower-${businessBorrowerId}-telephone-field`,
            value: PreferredPhone,
          },
          WebSite: {
            dataUI: `business-borrower-${businessBorrowerId}-web-site-field`,
            value: WebSite,
          },
          BusinessEmailAddress: {
            dataUI: `business-borrower-${businessBorrowerId}-email-address-field`,
            value: Email,
          },
          PercentOwnership: {
            dataUI: `business-borrower-${businessBorrowerId}-percent-ownership-field`,
            pageField: percentOwnershipPageField,
            value: percentageFormat(PercentOwnership, 3),
            isVisible: isCoBorrowerAddNow && !percentOwnershipPageField?.IsHidden,
          },
        },
      }
    ];
  }, [pageFields, businessBorrower, isCoBorrower, hasFullAccess, page, relationshipPageField, percentOwnershipPageField]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessBorrower);
