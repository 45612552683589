import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import Page from '../../Page/Page';
import PersonalBorrowerSettings from './PersonalBorrowerSettings';
import PageFieldExtended_ from '../../Page/PageHelpers/PageFieldExtended';
import BorrowerPHolder_ from '../../../../data/models/BorrowerPHolder';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_, PersonalBorrowerAdditionalSettings } from '../../../../utils/Types';
import { ExternalPages, ExternalPageSectionName } from '../../../../utils/Enums';
import { booleanStringComparison, checkIsPartyMatched, deepCopy } from '../../../../utils/Helper';
import { checkHasIDVerificationShowed } from '../../../../utils/IdentityVerification';
import { IdentityState as IdentityState_ } from '../../../../redux/actions/Identity';

export interface PersonalBorrowerProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_>[],
    holder: QdApplicationHolder_,
    borrower: BorrowerPHolder_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const PersonalBorrower: FC_<PersonalBorrowerProps> = (props: PersonalBorrowerProps) => {
  const {
    holder, loading, modelState, identityState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      identityState: IdentityState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
    identityState: state.identityState,
  }));

  const [borrower, setBorrower] = useState(deepCopy(holder?.BorrowerP));
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s?.SectionName === ExternalPageSectionName.YourInformation);
  const [subSection] = section?.SubSections;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const yourInformationGeneralPageFields = pageFieldHolder?.YourInformation?.General;

  useEffect(() => {
    setBorrower(deepCopy(holder?.BorrowerP));
  }, [holder?.BorrowerP]);

  const { OwnerCheck: ownerCheck, } = holder;

  const pageSettings = useMemo(() => {
    const { Personal, } = ownerCheck;
    const hasIDVerificationShowed = checkHasIDVerificationShowed(Personal, identityState);
    const isPartyMatched = checkIsPartyMatched(Personal);

    const additionalSettings: PersonalBorrowerAdditionalSettings = {
      hasIDVerificationShowed,
      isPartyMatched,
      borrower: ownerCheck.Personal,
    };

    return new PersonalBorrowerSettings([yourInformationGeneralPageFields], [subSection], additionalSettings);
  }, [ownerCheck, yourInformationGeneralPageFields, subSection, identityState]);

  const updatePersonalBorrower = (pageField: PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_>, value: string, e, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    borrower.Personal[ObjectProperty] = value;
    if (ObjectPropertyStr) borrower.Personal[ObjectPropertyStr] = text;

    // Reset MilitaryServiceStatusCodeStr
    if (ObjectProperty === 'IsMilitaryService' && !booleanStringComparison(value)) {
      borrower.Personal.MilitaryServiceStatusCode = '';
      borrower.Personal.MilitaryServiceStatusCodeStr = '';
    }

    setBorrower({ ...borrower, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, BorrowerPHolder_>[]) => {
   await props.onSubmit(invalidPageFields, holder, borrower, ExternalPages.PersonalBorrower, false);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updatePersonalBorrower}
          holder={holder}
          subHolder={borrower}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.PersonalBorrower}`}
        />
      )}
    </>
  );
};
export default PersonalBorrower;
