import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators as bindActionCreators_ } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  ReactAux,
  Section,
  FormSection,
  ActionsWrapper,
  Button,
  Checkbox,
  ProgressBar,
  ScrollableContainer,
  Alert,
  FormGroup,
  Radio,
  Textarea
} from '@jkhy/vsg-design-system';
import _ from 'lodash';
import Base from '../../Layout/BaseComponent';
import { setLoader, setProgress } from '../../../redux/actions/Loading';
import {
  errorMsg,
  htmlParser,
  getHoverHelpPageFieldValue,
  scrollToError,
  booleanStringComparison,
  isFieldValid,
  errorFoundMessage
} from '../../../utils/Helper';
import { AppState as AppState_ } from '../../../redux/AppState';
import { QdApplication as QdApplication_ } from '../../../data/models/QDApplication';
import { updateRenewalHolder } from '../../../redux/actions/Renewal';
import MarketingQuestion from '../../../components/MarketingQuestion/MarketingQuestion';
import QDPartyDisclosure_ from '../../../data/models/QDPartyDisclosure';
import QDCompletePars_ from '../../../data/models/QDCompletePars';
import { RenewalModelHolder as RenewalModelHolder_, BaseType as BaseType_ } from '../../../utils/Types';
import { RenewalPages, DisplayOptions } from '../../../utils/Enums';
import Messages from '../../../utils/Messages';
import QdApplicationSection_ from '../../../data/models/QDApplicationSection';
import QdServiceApi from '../../../data/api/QDServiceApi';

type QDApplicationBaseKeys = keyof QdApplication_;

type DeclarationsProps = {
  section: QdApplicationSection_;
  goToRenewalPage: (renewalSequence: typeof RenewalPages[keyof typeof RenewalPages]) => void;
  validate: () => any;
  getInvalidFieldsForDeclarationTab: () => any[];
  updateRenewalHolder;
} & BaseType_;

type DeclarationsState = {
  app: QdApplication_;
  step: number;
  showProgress: boolean;

  agreeToTerms: boolean;
  showNotAgreedWithTerms: boolean;

  invalidFields: any[];
  notAgreedWithTheConsent: boolean;
  allInvalidFieldsExistOnLastTab: boolean;

  disclosuresHTML: string;
  agreedDisclosuresFlag: boolean;
  partyDisclosures: QDPartyDisclosure_[];
  showDisclosureWarningMessage: boolean;
};

let agreeToApplicationGlobal = false;

class Declarations extends Base<DeclarationsProps, DeclarationsState> {
  constructor(props) {
    super(props);

    this.state = {
      app: props?.renewal?.ModelHolder?.Renewal?.Application,
      showProgress: false,
      agreeToTerms: agreeToApplicationGlobal,
      showNotAgreedWithTerms: false,
      step: 0,
      invalidFields: [],
      notAgreedWithTheConsent: false,
      allInvalidFieldsExistOnLastTab: true,
      disclosuresHTML: '',
      agreedDisclosuresFlag: false,
      showDisclosureWarningMessage: false,
      partyDisclosures: [],
    };
  }

  async componentDidMount() {
    const {
      section,
      renewal: {
        ModelHolder: {
          Renewal: { Application: app, },
        },
      },
    } = this.props;

    const {
      Result: { ConcatenatedDisclosures, Parties, },
    } = await QdServiceApi.loadQDApplicationDisclosures(app?.GUID, section.Id);
    if (ConcatenatedDisclosures) {
      const agreedDislosuresFlag = Parties?.length > 0 ? this.hasAgreed(app?.Business?.GUID, Parties) : false;
      this.setState({
        disclosuresHTML: ConcatenatedDisclosures,
        partyDisclosures: Parties,
        agreedDisclosuresFlag: agreedDislosuresFlag,
      });
    }
  }

  onChange = (e, prop: QDApplicationBaseKeys) => {
    const { app, } = this.state;
    const value = e.value ? e.value : e.target.value;
    app[prop.toString()] = value === 'true' || value === 'false' ? booleanStringComparison(value) : value;

    this.setState({ app, });
  };

  onMarketingRadioChange = (e, prop: QDApplicationBaseKeys) => {
    const { app, } = this.state;
    const value = e.value ? e.value : e.target.value;
    app[prop.toString()] = value;

    this.setState({ app, });
  };

  updateStore = () => {
    const { app, } = this.state;
    const newRenewal: RenewalModelHolder_ = {
      Renewal: {
        Invitation: this.props.renewal?.ModelHolder?.Renewal?.Invitation,
        Application: { ...app, },
      },
      ...this.props.renewal.ModelHolder,
    };
    this.props.updateRenewalHolder(newRenewal);
  };

  isFormValid(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const {
      app, agreedDisclosuresFlag, agreeToTerms, disclosuresHTML,
    } = this.state;

    let isDisclosuresValid = true;
    if (disclosuresHTML) isDisclosuresValid = booleanStringComparison(agreedDisclosuresFlag);

    this.setState({
      showNotAgreedWithTerms: !agreeToTerms,
      showDisclosureWarningMessage: !isDisclosuresValid,
      notAgreedWithTheConsent: app.ESignConsent === false,
    });

    const [invalidFields, isValidForm] = this.props.validate();
    if (!!invalidFields || !isValidForm) {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
    const localInvalidFields = this.props.getInvalidFieldsForDeclarationTab();

    this.setState(prevState => {
      return {
        ...prevState,
        invalidFields,
        allInvalidFieldsExistOnLastTab: invalidFields.length === localInvalidFields.length,
      };
    });

    return isDisclosuresValid && agreeToTerms && isValidForm;
  }

  onFail = () => {
    const { setProgress: setProgressLoader, } = this.props;
    setProgressLoader(false);
  };

  onContinue = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    this.setState({ step: 1, });
    const {
      renewal: {
        ModelHolder: { Model: model, },
      },
      setProgress: setProgress_,
    } = this.props;
    const { goToRenewalPage, } = this.props;
    const { app, } = this.state;
    setProgress_(true);

    const data = await this.save(app);
    const {
      Success: succ,
      Exceptions: [exp],
    } = data;

    if (succ) {
      this.setState(prevState => ({ step: prevState.step + 1, }));
      await this.submit<QDCompletePars_>({ IdApplication: app.Id, GUID: app.GUID, }, this.updateProgress, this.onFail, this.state.step, model);

      setProgress_(false);
      goToRenewalPage(RenewalPages.Submitted);
    } else {
      this.onFail();
      errorMsg(exp);
    }
  };

  updateProgress = (step: number) => {
    this.setState({ step, });
  };

  onAgreedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.currentTarget.checked;
    agreeToApplicationGlobal = result;
    this.setState({ agreeToTerms: result, });
  };

  hasAgreed = (borrowerGuid, parties = []) => !!(this.state?.partyDisclosures || parties).find(d => d.AgreedEarlier && d.PartyGuid === borrowerGuid);

  onAgreedDisclosureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, } = e.currentTarget;
    const { app, partyDisclosures, } = this.state;
    const { GUID, Id, } = app?.Business;

    if (!partyDisclosures?.find(pd => pd.PartyGuid === GUID && !pd.AgreedEarlier)) {
      app.Disclosures = app.Disclosures.map(d => {
        return { ...d, AgreedFlag: true, IdQDParty: Id, };
      });
    }

    this.setState({ agreedDisclosuresFlag: checked, app, });
    this.updateStore();
  };

  content = () => {
    const {
      app,
      invalidFields,
      allInvalidFieldsExistOnLastTab,
      notAgreedWithTheConsent,
      agreedDisclosuresFlag,
      disclosuresHTML,
      agreeToTerms,
      showDisclosureWarningMessage,
      showNotAgreedWithTerms,
    } = this.state;

    const {
      section,
      renewal: {
        ModelHolder: { Model: model, ApplicationPageField: pageFieldHolder, },
      },
    } = this.props;

    const wrapUpHTML = htmlParser(model.WrapUpHTML);
    const [declarationSubSection, disclosureSubSection] = section.SubSections;

    return (
      <ReactAux>
        <form>
          <Section
            title={section.PageHeader}
            headerText={section.SectionHeaderHTML}
            footerText={section.SectionFooterHTML}
            dataUI={`${section.SectionName}-section`}
          >
            {declarationSubSection?.DisplayOption === DisplayOptions.Always && (
              <FormSection className="mb-3" dataUI="declaration-section">
                <h3 className="mb-2">{declarationSubSection.SubSectionName}</h3>
                <FormGroup
                  className="mb-2"
                  isBold={pageFieldHolder?.Declarations?.RegOQuestion?.IsBold}
                  isHidden={pageFieldHolder?.Declarations?.RegOQuestion?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(pageFieldHolder?.Declarations?.RegOQuestion)}
                  label={pageFieldHolder?.Declarations?.RegOQuestion?.Label}
                  isRequired={pageFieldHolder?.Declarations?.RegOQuestion?.Required}
                  dataUI="declarations-rego-question-formgroup"
                  isValid={isFieldValid('RegOQuestion', invalidFields)}
                  validationMessage={errorFoundMessage('RegOQuestion', invalidFields)}
                  checkboxOrRadio
                >
                  <Radio
                    htmlFor="answerYes"
                    id="answerYes"
                    name="RegOQuestion"
                    className="d-inline-block"
                    value="true"
                    checked={app.RegOResponse === true}
                    onChange={e => {
                      this.onChange(e, 'RegOResponse');
                      this.updateStore();
                    }}
                    dataUI="declarations-rego-question-radio-yes"
                  >
                    Yes
                  </Radio>
                  <Radio
                    htmlFor="answerNo"
                    id="answerNo"
                    name="RegOQuestion"
                    className="d-inline-block"
                    value="false"
                    checked={app.RegOResponse === false}
                    onChange={e => {
                      this.onChange(e, 'RegOResponse');
                      this.updateStore();
                    }}
                    dataUI="declarations-rego-question-radio-no"
                  >
                    No
                  </Radio>
                </FormGroup>

                {app.RegOResponse === true && (
                  <FormGroup
                    className="mb-2"
                    label="If yes, please explain: "
                    htmlFor="regOResponseMemo"
                    dataUI="declarations-rego-question-if-yes-answer-form-group"
                  >
                    <Textarea
                      id="regOResponseMemo"
                      value={app.RegOResponseMemo}
                      rows={4}
                      onChange={e => this.onChange(e, 'RegOResponseMemo')}
                      dataUI="declarations-rego-question-if-yes-answer"
                      onBlur={this.updateStore}
                      maxLength={980}
                    />
                  </FormGroup>
                )}
                <FormGroup
                  className="mb-2"
                  isBold={pageFieldHolder?.Declarations?.ESignConsent?.IsBold}
                  isHidden={pageFieldHolder?.Declarations?.ESignConsent?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(pageFieldHolder?.Declarations?.ESignConsent)}
                  label={pageFieldHolder?.Declarations?.ESignConsent?.Label || 'Do you consent to receiving communication electronically via e-mail?'}
                  isRequired={pageFieldHolder?.Declarations?.ESignConsent?.Required}
                  dataUI="declarations-esign-consent-form-group"
                  isValid={isFieldValid('ESignConsent', invalidFields) && !notAgreedWithTheConsent}
                  validationMessage={notAgreedWithTheConsent ? Messages.E_SIGN_CONSENT_REQUIRED : errorFoundMessage('ESignConsent', invalidFields)}
                  checkboxOrRadio
                >
                  <Radio
                    htmlFor="esign-consent-yes"
                    id="esign-consent-yes"
                    name="ESignConsent"
                    className="d-inline-block"
                    value="true"
                    checked={app.ESignConsent === true}
                    onChange={e => {
                      this.onChange(e, 'ESignConsent');
                      this.updateStore();
                    }}
                    dataUI="declarations-esign-consent-radio-yes"
                  >
                    Yes
                  </Radio>
                  <Radio
                    htmlFor="esign-consent-no"
                    id="esign-consent-no"
                    name="ESignConsent"
                    className="d-inline-block"
                    value="false"
                    checked={app.ESignConsent === false}
                    onChange={e => {
                      this.onChange(e, 'ESignConsent');
                      this.updateStore();
                    }}
                    dataUI="declarations-esign-consent-radio-no"
                  >
                    No
                  </Radio>
                </FormGroup>

                <MarketingQuestion
                  value={app.MarketingQuestionCode}
                  marketingQuestionText={model.MarketingQuestionText}
                  isValid={isFieldValid('MarketingQuestion', invalidFields)}
                  pageField={pageFieldHolder?.Declarations?.MarketingQuestion}
                  inputValue={app.MarketingQuestionMemo}
                  onInputChange={e => {
                    this.onChange(e, 'MarketingQuestionMemo');
                  }}
                  onChangeRadio={e => {
                    this.onMarketingRadioChange(e, 'MarketingQuestionCode');
                    this.updateStore();
                  }}
                />
              </FormSection>
            )}

            {disclosureSubSection?.DisplayOption === DisplayOptions.Always && disclosuresHTML && (
              <ReactAux>
                <FormSection dataUI={`${disclosureSubSection.Code}-section`} className="mb-3">
                  <h3 className="mb-2">{disclosureSubSection.SubSectionName}</h3>
                  <ScrollableContainer className="mb-1" dataUI="contatenated-disclosures">
                    {htmlParser(disclosuresHTML)}
                  </ScrollableContainer>
                  <Checkbox
                    checked={agreedDisclosuresFlag}
                    disabled={this.hasAgreed(app?.Business?.GUID)}
                    onChange={this.onAgreedDisclosureChange}
                    dataUI="disclosure-agreed-checkbox"
                    htmlFor="agreement-with-disclosures"
                    id="agreement-with-disclosures"
                    className="d-inline-block"
                  >
                    {Messages.DISCLOSURES_CHECKBOX_LABEL}
                  </Checkbox>
                </FormSection>
                <Alert
                  dataUI="information-dislosure-alert-email-notification"
                  type="info"
                  text={Messages.AGREEMENT_NOTIFICATION_DISCLOSURES}
                  className="mb-3"
                />
              </ReactAux>
            )}

            <ReactAux>
              <FormSection dataUI="terms-and-conditions-section" className="mb-3">
                <h3 className="mb-2">Terms and Conditions</h3>
                <ScrollableContainer dataUI="terms-and-conditions-text-content" className="mb-2">
                  {wrapUpHTML}
                </ScrollableContainer>
                {wrapUpHTML && (
                  <Checkbox
                    checked={agreeToTerms || false}
                    onChange={this.onAgreedChange}
                    dataUI="terms-and-conditions-checkbox"
                    id="terms-and-conditions-checkbox"
                    className="d-inline-block"
                  >
                    {Messages.WRAP_UP_CHECKBOX_LABEL}
                  </Checkbox>
                )}
              </FormSection>
            </ReactAux>
          </Section>

          {showNotAgreedWithTerms && (
            <Alert dataUI="wrap-up-alert-agreed-terms-and-conditions" type="danger" text={Messages.INVALID_WRAP_UP_TERMS} className="mt-1" />
          )}

          {showDisclosureWarningMessage && (
            <Alert dataUI="disclosure-alert-agreed-notification" type="danger" text={Messages.INVALID_DISCLOSURES} className="mt-1" />
          )}

          {!allInvalidFieldsExistOnLastTab && (
            <Alert dataUI="invalid-fields-alert" type="danger" text="There are required or incorrect fields on previous tabs" className="mt-1" />
          )}

          <ActionsWrapper dataUI="declarations-wrap-up-submit-button-wrapper" className="mt-2">
            <Button
              dataUI="declarations-wrap-up-submit-button"
              disabled={disclosuresHTML && !agreedDisclosuresFlag}
              onClick={e => (this.isFormValid(e) ? this.onContinue(e) : '')}
            >
              Submit
            </Button>
          </ActionsWrapper>

          <ProgressBar dataUI="declarations-progress-bar" isVisible={this.props.showProgress} steps={8} currentStep={this.state.step} />
        </form>
      </ReactAux>
    );
  };
}

export default withRouter(
  connect(
    (state: AppState_) => ({ ...state, }),
    dispatch => bindActionCreators_({ setLoader, setProgress, updateRenewalHolder, }, dispatch)
  )(Declarations)
);
