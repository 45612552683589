import React, { FC as FC_, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import {
  booleanStringComparison, dateFormat, getFullName, percentageFormat, yesNoOrEmptyAnswer
} from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import Borrower_ from '../../../../data/models/Borrower';
import { GuarantorPages } from '../../../../utils/Enums';
import PageField_ from '../../../../data/models/PageField';

type PersonalBorrowerProps = {
  isCoBorrower?: boolean;
  hasFullAccess: boolean;
  personalBorrower: Borrower_;
} & ReviewApplicationSectionProps_;

const PersonalBorrower: FC_<PersonalBorrowerProps> = ({
  isCoBorrower,
  hasFullAccess,
  personalBorrower,
  sectionTitle,
  expandState,
  setExpandState,
  page,
}: PersonalBorrowerProps) => {
  const {
    modelState: {
      ModelHolder: { ApplicationPageField, CoBorrowerPageField, },
    },
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    modelState: state.modelState,
  }));

  const coBorrowerPersonalInformationPageFields = CoBorrowerPageField?.PersonalInformation?.General;
  const borrowerPersonalInformationPageFields = ApplicationPageField?.YourInformation?.General;
  const pageFields = isCoBorrower ? coBorrowerPersonalInformationPageFields : borrowerPersonalInformationPageFields;

  const relationshipPageField = coBorrowerPersonalInformationPageFields?.Relationship ?? ({ IsHidden: true, } as PageField_);

  const rows: CustomRow_[] = useMemo(() => {
    if (!pageFields || !personalBorrower) return [];

    const {
      Id: personalBorrowerId,
      FirstName,
      MiddleName,
      LastName,
      TIN,
      PreferredPhone,
      HomePhone,
      Email,
      DOB,
      USCitizenFlag,
      IDTypeCodeStr,
      IDNumber,
      IDIssueDate,
      IDExpirationDate,
      IDIssuer,
      PercentOwnership,
      IsMilitaryService,
      MilitaryServiceStatusCodeStr,
      BorrowerTypeStr,
    } = personalBorrower || {};

    const fullName = getFullName(personalBorrower);
    // eslint-disable-next-line max-len
    const linkText = isCoBorrower && page !== GuarantorPages.PersonalInformation ? 'Go to Co-borrower step to edit' : 'Go to your personal information step to edit';

    return [
      {
        id: `personal-borrower-${personalBorrowerId}-borrower-type-field`,
        title: 'Name:',
        subTitle: fullName,
        pageFields,
        ...(hasFullAccess ? { link: { page, text: linkText, }, } : {}),
        data: {
          Relationship: {
            dataUI: `personal-borrower-${personalBorrowerId}-borrower-type-field`,
            value: BorrowerTypeStr,
            pageField: relationshipPageField,
            isVisible: !relationshipPageField.IsHidden && isCoBorrower,
            hasAccess: hasFullAccess,
          },
          FirstName: {
            dataUI: `personal-borrower-${personalBorrowerId}-first-name-field`,
            value: FirstName,
          },
          MiddleName: {
            dataUI: `personal-borrower-${personalBorrowerId}-middle-name-field`,
            value: MiddleName,
          },
          LastName: {
            dataUI: `personal-borrower-${personalBorrowerId}-last-name-field`,
            value: LastName,
          },
          SSN: {
            dataUI: `personal-borrower-${personalBorrowerId}-ssn-field`,
            value: TIN,
            hasAccess: hasFullAccess,
          },
          MobilePhone: {
            dataUI: `personal-borrower-${personalBorrowerId}-telephone-field`,
            value: PreferredPhone,
            hasAccess: hasFullAccess,
          },
          HomePhone: {
            dataUI: `personal-borrower-${personalBorrowerId}-home-phone-field`,
            value: HomePhone,
            hasAccess: hasFullAccess,
          },
          EmailAddress: {
            dataUI: `personal-borrower-${personalBorrowerId}-email-address-field`,
            value: Email,
          },
          DateOfBirth: {
            dataUI: `personal-borrower-${personalBorrowerId}-date-of-birth-field`,
            value: dateFormat(DOB),
            hasAccess: hasFullAccess,
          },
          USCitizen: {
            dataUI: `personal-borrower-${personalBorrowerId}-citizen-class-field`,
            value: yesNoOrEmptyAnswer(USCitizenFlag),
            hasAccess: hasFullAccess,
          },
          IDType: {
            dataUI: `personal-borrower-${personalBorrowerId}-ID-type-field`,
            value: IDTypeCodeStr,
            hasAccess: hasFullAccess,
          },
          IDNumber: {
            dataUI: `personal-borrower-${personalBorrowerId}-ID-number-field`,
            value: IDNumber,
            hasAccess: hasFullAccess,
          },
          IDIssueDate: {
            dataUI: `personal-borrower-${personalBorrowerId}-issue-date-field`,
            value: dateFormat(IDIssueDate),
            hasAccess: hasFullAccess,
          },
          IDExpirationDate: {
            dataUI: `personal-borrower-${personalBorrowerId}-expiration-date-field`,
            value: dateFormat(IDExpirationDate),
            hasAccess: hasFullAccess,
          },
          IDIssuer: {
            dataUI: `personal-borrower-${personalBorrowerId}-issuer-field`,
            value: IDIssuer,
            hasAccess: hasFullAccess,
          },
          PercentOwnership: {
            dataUI: `personal-borrower-${personalBorrowerId}-percent-ownership-field`,
            value: percentageFormat(PercentOwnership, 3),
          },
          ServeInMilitary: {
            dataUI: `personal-borrower-${personalBorrowerId}-serve-in-military-field`,
            value: yesNoOrEmptyAnswer(IsMilitaryService),
            hasAccess: hasFullAccess,
          },
          MilitaryServiceStatus: {
            dataUI: `personal-borrower-${personalBorrowerId}-military-service-status-field`,
            value: MilitaryServiceStatusCodeStr,
            isVisible: !pageFields.MilitaryServiceStatus.IsHidden && booleanStringComparison(IsMilitaryService),
            hasAccess: hasFullAccess,
          },
        },
      }
    ];
  }, [pageFields, relationshipPageField, personalBorrower, isCoBorrower, hasFullAccess, page]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

PersonalBorrower.defaultProps = {
  isCoBorrower: false,
};

export default memo(PersonalBorrower);
