import React, { FC as FC_ } from 'react';
import { LinkWithIcon, IconsSolid } from '@jkhy/vsg-design-system';
import { useDispatch } from 'react-redux';
import DocumentAPI from '../../data/api/DocumentApi';
import { downloadFileFromDocumentBytes } from '../../utils/Helper';
import { setLoader } from '../../redux/actions/Loading';
import { DisclosuresDocuments as DisclosuresDocuments_ } from '../../utils/Disclosures';

type DisclosuresDocumentsProps = {
  disclosuresDocuments: DisclosuresDocuments_;
  setDisclosuresDocuments: React.Dispatch<React.SetStateAction<DisclosuresDocuments_>>;
  isGuarantor?: boolean;
};

const DisclosuresDocuments: FC_<DisclosuresDocumentsProps> = ({
  disclosuresDocuments,
  setDisclosuresDocuments,
  isGuarantor,
}: DisclosuresDocumentsProps) => {
  const dispatch = useDispatch();

  const onClickPdfDisclosure = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, disclosureId: string) => {
    e.preventDefault();

    const documentBytes = disclosuresDocuments[disclosureId]?.documentBytes;
    const fileName = disclosuresDocuments[disclosureId]?.name;
    if (documentBytes && fileName) {
      downloadFileFromDocumentBytes(documentBytes, fileName);
      return;
    }

    dispatch(setLoader(true));

    const { Success, Result, } = await DocumentAPI.downloadDisclosure(disclosureId, !!isGuarantor);
    if (Success) {
      const { DocumentBytes: downloadedDocumentBytes, Name: downloadedDocumentName, } = Result;
      downloadFileFromDocumentBytes(downloadedDocumentBytes, downloadedDocumentName);

      setDisclosuresDocuments(prevState => ({
        ...prevState,
        [disclosureId]: {
          ...prevState[disclosureId],
          documentBytes: downloadedDocumentBytes,
          isOpened: true,
        },
      }));
    }

    dispatch(setLoader(false));
  };

  return (
    <div>
      <h4 className="mb-2"> Select the links to open and review the disclosures.</h4>

      {Object.entries(disclosuresDocuments).map(([guid, documentData], index: number) => {
        const { isOpened, name, } = documentData;

        const isLastDocument = index === Object.keys(disclosuresDocuments).length - 1;

        return (
          <LinkWithIcon
            key={guid}
            icon={isOpened ? IconsSolid.faFileCheck : IconsSolid.faFileAlt}
            iconColor={isOpened ? 'success' : 'primary'}
            className={`w-100 ${isLastDocument ? '' : 'mb-1'}`}
          >
            <a href="#!" onClick={e => onClickPdfDisclosure(e, guid)}>
              {name}
            </a>
          </LinkWithIcon>
        );
      })}
    </div>
  );
};

DisclosuresDocuments.defaultProps = {
  isGuarantor: false,
};

export default DisclosuresDocuments;
