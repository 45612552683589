/* eslint no-unused-vars: "off" */
import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings_ from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { validateGuarantorEmail } from '../../../utils/Validator';
import { PartyType, CoBorrowerAddType } from '../../../utils/Enums';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import Borrower_ from '../../../data/models/Borrower';

export default class CoBorrowerSettings<T, T1> extends PageSettings_<T, T1, any> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<T, T1> {
    if (!pageField) {
      return null;
    }

    // TODO: Remove below ugly logic on page rework
    //  There are Business page field (Email Invited or Add Now)
    //  and Personal page field (Add Now) with same field name - FirstName, LastName, EmailAddress
    //  Validation logic should not consider one of them.
    const preventFieldValidation = (appHolder, coBorrower, pf) => {
      const { PartyType: partyType, CoBorrowerAddType: coBorrowerAddType, } = coBorrower;

      if (partyType === PartyType.Personal) {
        return (
          (coBorrowerAddType === CoBorrowerAddType.AddNow && pf.GroupName === 'Guarantor/General')
          || (coBorrowerAddType === CoBorrowerAddType.EmailInvitation && pf.GroupName === 'Personal Information/General')
        );
      }
      return partyType === PartyType.Business && pf.GroupName === 'Personal Information/General';
    };

    switch (pageField.FieldName) {
      case 'PartyType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-party-type-field',
          ObjectProperty: 'PartyType',
          ObjectPropertyType: 'number',
        });
      case 'GBusinessName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-business-name-field',
          ObjectProperty: 'FullName',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: (appHolder, coBorrower) => {
            const { PartyType: partyType, CoBorrowerAddType: coBorrowerAddType, } = coBorrower;
            return coBorrowerAddType === CoBorrowerAddType.AddNow || parseInt(coBorrower.PartyType, 10) === PartyType.Personal; // Not Personal
          },
        });
      case 'BusinessName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-business-name-field',
          ObjectProperty: 'FullName',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: (appHolder, coBorrower) => {
            const { PartyType: partyType, CoBorrowerAddType: coBorrowerAddType, } = coBorrower;
            return coBorrowerAddType === CoBorrowerAddType.EmailInvitation || parseInt(partyType, 10) === PartyType.Personal; // Not Personal
          },
        });
      case 'GDBA':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-DBA-field',
          ObjectProperty: 'DBA',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: (appHolder, coBorrower) => {
            return parseInt(coBorrower.PartyType, 10) === PartyType.Personal; // Not Personal
          },
        });
      case 'FirstName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-first-name-field',
          ObjectProperty: 'FirstName',
          ObjectPropertyType: 'string',
          // IsHiddenCalculated method is used to prevent/disable validation logic
          IsHiddenCalculated: preventFieldValidation,
        });
      case 'LastName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-last-name-field',
          ObjectProperty: 'LastName',
          ObjectPropertyType: 'string',
          // IsHiddenCalculated method is used to prevent/disable validation logic
          IsHiddenCalculated: preventFieldValidation,
        });
      case 'Relationship':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-relationship-field',
          ObjectProperty: 'BorrowerType',
          ObjectPropertyStr: 'BorrowerTypeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              hideSelectOption: true,
              initialData: [],
              ListName: 'QDRelationship',
              ListType: 'LtItems',
            },
          },
        });
      case 'PercentOwnershipGuarantor':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-percent-ownership-guarantor-field',
          ObjectProperty: 'PercentOwnership',
          ObjectPropertyType: 'number',
        });

      case 'EmailAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-email-address-field',
          ObjectProperty: 'Email',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            icon: IconsSolid.faEnvelope,
            inputType: 'email',
          },
          // IsHiddenCalculated method is used to prevent/disable validation logic
          IsHiddenCalculated: preventFieldValidation,
          IsValid: (appHolder: QdApplicationHolder_, coBorrower: Borrower_, pf: PageFieldExtended_<QdApplicationHolder_, Borrower_>) => {
            const pageFieldHelper = pf;
            const { IsValid, InvalidMessage, } = validateGuarantorEmail(appHolder, coBorrower, pf);

            pageFieldHelper.ValidationMessage = InvalidMessage;
            return IsValid;
          },
        });

      // TO DO: Fields are not visible at FE, need to remove them from DB
      case 'SSN':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-ssn-field',
          ObjectProperty: 'SSN',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'DateOfBirth':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-dob-field',
          ObjectProperty: 'DateOfBirth',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'TaxID':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-tax-id-field',
          ObjectProperty: 'TaxID',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'StreetAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-street-address-field',
          ObjectProperty: 'StreetAddress',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'City':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-city-field',
          ObjectProperty: 'City',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'State':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-state-field',
          ObjectProperty: 'State',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'ZIPCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-zip-code-field',
          ObjectProperty: 'ZIPCode',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'PhoneNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-phone-number-field',
          ObjectProperty: 'PhoneNumber',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'WebSite':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-website-field',
          ObjectProperty: 'WebSite',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'MailingAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-mailing-address-field',
          ObjectProperty: 'MailingAddress',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'MailingCity':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-mailing-city-field',
          ObjectProperty: 'MailingCity',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'MailingState':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-mailing-state-field',
          ObjectProperty: 'MailingState',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'MailingZip':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-mailing-zip-field',
          ObjectProperty: 'MailingZip',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'OrganizationType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-organization-type-field',
          ObjectProperty: 'OrganizationType',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'TaxEntityType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-tax-entity-type-field',
          ObjectProperty: 'TaxEntityType',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'CompanyTraits':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-company-traits-field',
          ObjectProperty: 'CompanyTraits',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'NumberOfEmployees':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-number-of-employees-field',
          ObjectProperty: 'NumberOfEmployees',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'DateFormed':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-date-formed-field',
          ObjectProperty: 'DateFormed',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'StateFormed':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-state-formed-field',
          ObjectProperty: 'StateFormed',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'AGI':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-agi-field',
          ObjectProperty: 'AGI',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'MostRecentYearEndDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-most-recent-year-end-date-field',
          ObjectProperty: 'MostRecentYearEndDate',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'AnnualSales':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-annual-sales-field',
          ObjectProperty: 'AnnualSales',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

      case 'AverageDeposits':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'co-borrower-average-deposits-field',
          ObjectProperty: 'AverageDeposits',
          ObjectPropertyType: 'string',
          IsValid: (appHolder, coBorrower, pf) => {
            return true;
          },
        });

        // END TO DO: Fields are not visible at FE, need to remove them from DB

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
