import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionsWrapper, Button, HorizontalInfoCard, IconsSolid, IconsLight, Modal, Section
} from '@jkhy/vsg-design-system';
import Page from '../../../Page/Page';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import {
  AddressType, AddressPersonalPageSteps, GuarantorPages, GuarantorPageSectionName_
} from '../../../../../utils/Enums';
import QDAddress from '../../../../../data/models/QDAddress';
import { UpdateAction as UpdateAction_ } from '../../../../../redux/actions/SubmitButton';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import {
  AddressDetails as AddressDetails_,
  ExternalModelHolder as ExternalModelHolder_,
  ModelState as ModelState_
} from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationPersonalAddresses as QDCoBorrowerApplicationPersonalAddresses_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { PersonalAddressSettings_ } from '../../AddNow/Address/PersonalAdressSettings';
import { calculateDifferenceText, getGoogleApiAddressAsString } from '../../../../../utils/Address';
import { updateCoBorrowerHolder } from '../../../../../redux/actions/CoBorrowerHolder';
import { checkHasIDVerificationShowed } from '../../../../../utils/IdentityVerification';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../../redux/actions/CoBorrowerIdentity';

export interface PersonalAddressProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationPersonalAddresses_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationPersonalAddresses_,
    sequence: string
  ) => void;
}

const CoBorrowerPersonalAddress: FC_<PersonalAddressProps> = (props: PersonalAddressProps) => {
  const {
    holder, modelState, loading, identityState,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QDCoBorrowerApplicationHolder_;
      loading: boolean;
      identityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.coBorrowerHolderState?.CoBorrowerHolder,
    loading: state.loading,
    identityState: state.coBorrowerIdentityState,
  }));

  const personalAddresses = holder.AddressesP.PersonalAddresses;
  const hasIDVerificationShowed = useMemo(() => {
    return checkHasIDVerificationShowed(holder.Personal.CoBorrower, identityState);
  }, [holder.Personal.CoBorrower, identityState]);

  const {
    initialAddresses, initialAddressInProgress, initialActiveIndex, initialStep,
  } = useMemo(() => {
    const modifiedPersonalAddresses = personalAddresses?.map((address: QDAddress) => {
      return { ...address, GoogleAPIAddress: getGoogleApiAddressAsString(address), };
    });

    const currentAddress = modifiedPersonalAddresses?.find(a => a.AddressType === AddressType.Current);
    const hasCurrentAddressSavedByUser = currentAddress?.IsSavedByUser;
    // eslint-disable-next-line max-len
    const initAddressInProgress = currentAddress && !hasCurrentAddressSavedByUser ? { ...currentAddress, } : { ...new QDAddress(), AddressType: AddressType.Current, };

    const initIndex = hasCurrentAddressSavedByUser ? -1 : 0;
    const initStep = hasCurrentAddressSavedByUser ? AddressPersonalPageSteps.HorizontalCardOverview : AddressPersonalPageSteps.CurrentAddress;

    return {
      initialAddresses: modifiedPersonalAddresses,
      initialAddressInProgress: initAddressInProgress,
      initialActiveIndex: initIndex,
      initialStep: initStep,
    };
  }, [personalAddresses]);

  const [addresses, setAddresses] = useState(initialAddresses);
  const [addressesInProgress, setAddressInProgress] = useState(initialAddressInProgress);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [activeAddressIndex, setActiveAddressIndex] = useState(initialActiveIndex);
  const [step, setStep] = useState(initialStep);

  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.PersonalAddress);
  const [subSection] = section.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const {
    PersonalAddress: { CurrentAddress, PreviousAddress, },
  } = pageFieldHolder;

  const pageFieldsInUse = useMemo(() => {
    if (addressesInProgress.AddressType === AddressType.Current) {
      const { SortOrder: currentAddressSortOrder, ...currentAddress } = CurrentAddress.CurrentAddress;
      const { SortOrder: mailingAddressSortOrder, ...mailingAddress } = CurrentAddress.MailingAddress;

      return {
        ...CurrentAddress,
        // Fields not part of Page Fields settings in Admin, i.e Database
        DifferentMailingAddress: { ...mailingAddress, FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressSortOrder - 1, },
        MailingZipCode: { ...mailingAddress, FieldName: 'MailingZip', SortOrder: mailingAddressSortOrder + 1, },
        MailingCity: { ...mailingAddress, FieldName: 'MailingCity', SortOrder: mailingAddressSortOrder + 2, },
        MailingState: { ...mailingAddress, FieldName: 'MailingState', SortOrder: mailingAddressSortOrder + 3, },
        StreetAddress: { ...currentAddress, FieldName: 'StreetAddress', SortOrder: currentAddressSortOrder + 1, },
        City: { ...currentAddress, FieldName: 'City', SortOrder: currentAddressSortOrder + 2, },
        State: { ...currentAddress, FieldName: 'State', SortOrder: currentAddressSortOrder + 3, },
        ZipCode: { ...currentAddress, FieldName: 'Zip', SortOrder: currentAddressSortOrder + 4, },
      };
    }

    const { SortOrder: previousAddressSortOrder, ...previousAddress } = PreviousAddress.PreviousAddress;

    return {
      ...PreviousAddress,
      // Fields not part of Page Fields settings in Admin, i.e Database
      StreetAddress: { ...previousAddress, FieldName: 'StreetAddress', SortOrder: previousAddressSortOrder + 1, },
      City: { ...previousAddress, FieldName: 'City', SortOrder: previousAddressSortOrder + 2, },
      State: { ...previousAddress, FieldName: 'State', SortOrder: previousAddressSortOrder + 3, },
      ZipCode: { ...previousAddress, FieldName: 'Zip', SortOrder: previousAddressSortOrder + 4, },
    };
  }, [addressesInProgress, CurrentAddress, PreviousAddress]);

  const pageSettings = useMemo(() => {
    const startDateLabel = pageFieldsInUse?.AddressStartDate?.Label;
    const additionalSettings = {
      startDateLabel,
      hasIDVerificationShowed,
      isCurrentAddress: addressesInProgress.AddressType === AddressType.Current,
    };

    return new PersonalAddressSettings_([pageFieldsInUse], section.SubSections, additionalSettings);
  }, [pageFieldsInUse, hasIDVerificationShowed, addressesInProgress.AddressType, section.SubSections]);

  const dispatch = useDispatch();
  const showSubmitButtonFlag = (show: boolean) => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: false, show, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };
  const updateCoBorrowerHolderStore = (modifiedAddresses: QDAddress[]) => {
    const modifiedAddressP = { ...holder.AddressesP, PersonalAddresses: modifiedAddresses, };
    dispatch(updateCoBorrowerHolder({ ...holder, AddressesP: modifiedAddressP, }));
  };

  useEffect(() => {
    const showSubmit = step === AddressPersonalPageSteps.HorizontalCardOverview;
    showSubmitButtonFlag(showSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    return () => {
      // aka ComponentWillUnmount
      showSubmitButtonFlag(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (pageField: PageFieldExtended_<any, QDAddress>, value: string, e: React.ChangeEvent, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    addressesInProgress[ObjectProperty] = value;
    if (ObjectPropertyStr) {
      addressesInProgress[ObjectPropertyStr] = text;
    }

    setAddressInProgress({ ...addressesInProgress, });
  };

  const onAddressSelect = (pageField: PageFieldExtended_<any, QDAddress>, addressDetails: AddressDetails_) => {
    const { ObjectProperty, } = pageField;

    if (addressDetails) {
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;

      const changedAddressData = {
        ...addressesInProgress,
        [ObjectProperty]: fullAddress,
        Address: streetAddress,
        City: city,
        State: state,
        Zip: zipCode,
      };

      setAddressInProgress({ ...changedAddressData, });
    }
  };

  const onAdd = () => {
    const previousAddress = new QDAddress();
    previousAddress.AddressType = AddressType.Previous;
    setAddressInProgress(previousAddress);
    setStep(AddressPersonalPageSteps.PreviousAddress);
  };

  const onEdit = index => {
    const address = addresses[index];
    setActiveAddressIndex(index);

    if (address?.Address) {
      address.GoogleAPIAddress = getGoogleApiAddressAsString(address);
    }

    setAddressInProgress(address);
    const editStep = address.AddressType === AddressType.Current ? AddressPersonalPageSteps.CurrentAddress : AddressPersonalPageSteps.PreviousAddress;
    setStep(editStep);
  };

  const onDelete = async () => {
    const id = addresses[removeIndex]?.Id;
    if (id) {
      await QdServiceApi.removeQDAddress(id);
    }
    const editedData = _.reject(addresses, (x, i) => {
      return i === removeIndex;
    });
    setAddresses([...editedData]);
    updateCoBorrowerHolderStore([...editedData]);
    setRemoveIndex(-1);
  };

  const onCancel = () => {
    setStep(AddressPersonalPageSteps.HorizontalCardOverview);
    setActiveAddressIndex(-1);
    setAddressInProgress(new QDAddress());
  };

  const onSave = async (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDAddress>[]) => {
    if (invalidPageFields.length === 0) {
      const isNew = !addressesInProgress.Id;
      const {
        Personal: {
          CoBorrower: { Id, IdQDApplication, },
        },
      } = holder;
      if (!addressesInProgress.IdQDParty) {
        addressesInProgress.IdQDParty = Id;
      }
      if (!addressesInProgress.IdQDApplication) {
        addressesInProgress.IdQDApplication = IdQDApplication;
      }

      // In case client has NOT used Google proposed addresses
      // Use value entered by user in field
      if (addressesInProgress.GoogleAPIAddress && !addressesInProgress.Address) {
        addressesInProgress.Address = addressesInProgress.GoogleAPIAddress;
      }
      addressesInProgress.IsSavedByUser = true;

      const { Result: saved, } = await QdServiceApi.saveQDAddress(addressesInProgress);
      if (isNew) {
        addressesInProgress.Id = saved.Id;
        addressesInProgress.GUID = saved.GUID;
        setAddresses([...addresses, addressesInProgress]);
        updateCoBorrowerHolderStore([...addresses, addressesInProgress]);
      } else if (activeAddressIndex > -1) {
        addresses[activeAddressIndex] = addressesInProgress;
        setAddresses([...addresses]);
        updateCoBorrowerHolderStore([...addresses]);
      }
      setActiveAddressIndex(-1);
      setAddressInProgress(new QDAddress());
      setStep(AddressPersonalPageSteps.HorizontalCardOverview);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const personalAddress = { ...holder.AddressesP, PersonalAddresses: addresses, };
    await props.onSubmit([], holder, personalAddress, GuarantorPages.PersonalAddress);
  };

  return (
    <>
      {!loading && step === AddressPersonalPageSteps.HorizontalCardOverview && (
        <form id={`form-${GuarantorPages.PersonalAddress}`} onSubmit={onSubmit}>
          <Section
            dataUI="guarantor-personal-address-page"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
          >
            {addresses.map((a, index) => {
              const key = index;
              const isCurrentAddress = a?.AddressType === AddressType.Current;

              return (
                <HorizontalInfoCard
                  key={key}
                  title={a.Address}
                  description={calculateDifferenceText(a.AddressStartDate, a.AddressEndDate)}
                  className="mb-2"
                  icon={isCurrentAddress ? IconsLight.faHome : IconsLight.faBuilding}
                  dataUI={`address-horizontal-info-card-${index}`}
                  onEdit={() => {
                    onEdit(index);
                  }}
                  onDelete={
                    isCurrentAddress
                      ? null
                      : () => {
                        setRemoveIndex(index);
                      }
                  }
                />
              );
            })}
          </Section>
          <Button dataUI="add-address-button" iconLeft={IconsSolid.faPlus} onClick={onAdd}>
            Add Address
          </Button>
        </form>
      )}

      {!loading && step !== AddressPersonalPageSteps.HorizontalCardOverview && (
        <>
          <Page
            pageSettings={pageSettings}
            onChange={onChange}
            onAddressSelect={onAddressSelect}
            holder={holder}
            subHolder={addressesInProgress}
            onSubmit={onSave}
            formIdentifier="form-co-borrower-add-now-address"
          />
          <ActionsWrapper dataUI="address-actions-wrapper">
            {(addressesInProgress.Id || addressesInProgress.AddressType !== AddressType.Current) && (
              <Button
                dataUI="cancel-address-button"
                btnType="secondary"
                className="action-spacer"
                iconLeft={IconsSolid.faTimesCircle}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}

            <Button dataUI="save-address-button" iconLeft={IconsSolid.faSave} form="form-co-borrower-add-now-address" type="submit">
              Save
            </Button>
          </ActionsWrapper>
        </>
      )}

      <Modal
        dataUI="remove-confiramtion-modal"
        title="Confirm to delete"
        isVisible={removeIndex > -1}
        isClosable
        closeBtnName="Cancel"
        closeBtnIcon={IconsSolid.faTimesCircle}
        onClose={() => setRemoveIndex(-1)}
        actionBtnName="YES, DELETE"
        actionBtnIcon={IconsSolid.faTrash}
        onSubmit={onDelete}
        size="S"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </>
  );
};

export default CoBorrowerPersonalAddress;
