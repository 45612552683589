import { Reducer as Reducer_ } from 'redux';
import { CoBorrowerSubmitAction as CoBorrowerSubmitAction_ } from '../actions/CoBorrowerSubmitted';

const coBorrowerSubmitReducer: Reducer_<boolean, CoBorrowerSubmitAction_> = (state = false, action) => {
  switch (action.type) {
    case 'Submitted': {
      return action.isCoBorrowerSubmitted;
    }
    default:
      return state;
  }
};

export default coBorrowerSubmitReducer;
