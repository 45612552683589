import { deepCopy } from './Helper';
import { ExternalPages, ExternalPageSectionName } from './Enums';
import { ExpandState as ExpandState_ } from '../pages/External/ReviewApplication/ReviewApplication';
import Section_ from '../data/models/Section';

export const transformExpandId = (externalPageSectionName: typeof ExternalPages[keyof typeof ExternalPages], id?: string | bigint) => {
  return id ? `${externalPageSectionName}-${id}` : externalPageSectionName;
};

export const isSectionVisibleBySequence = (sections: Section_[], sequence: string) => {
  return sections.some(section => parseInt(section.Sequence, 0) === parseInt(sequence, 10));
};

export const getSectionTabName = (
  sections: Section_[],
  sectionName: typeof ExternalPageSectionName[keyof typeof ExternalPageSectionName],
  defaultTabName: string
) => {
  const foundSection = sections.find(s => s.SectionName === sectionName);
  return foundSection?.TabName || defaultTabName;
};

export const areSectionsExpanded = (expandState: ExpandState_) => {
  if (!expandState) return false;

  return Object.values(expandState).every(reviewSection => {
    const areSubSectionsExpanded = Object.values(reviewSection).every(Boolean);
    return areSubSectionsExpanded;
  });
};

export const toggleAllSections = (expand: boolean, expandState: ExpandState_) => {
  const newExpandedState: ExpandState_ = deepCopy(expandState);

  const modifiedExpandedState: ExpandState_ = Object.entries(newExpandedState)
    .map(([sequence, sectionExpandState]) => {
      const allSubSectionsKeys = Object.keys(sectionExpandState);
      return allSubSectionsKeys.reduce(
        (acc, subSectionKey) => ({ ...acc, [sequence]: { ...acc[sequence], [subSectionKey]: expand, }, }),
        {} as ExpandState_
      );
    })
    .reduce((acc, sectionState) => ({ ...acc, ...sectionState, }), {} as ExpandState_);

  return modifiedExpandedState;
};
