import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import CollateralModel_ from '../../../data/models/Collateral';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { REGEX, ValidationRegexConstants } from '../../../utils/Enums';
import Messages from '../../../utils/Messages';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import Borrower_ from '../../../data/models/Borrower';
import { getFullName, isGoogleUnavailable } from '../../../utils/Helper';
import type { AdditionalData_ } from './Collateral';

export default class CollateralSettings extends PageSettings<QdApplicationHolder_, CollateralModel_, AdditionalData_> {
  // eslint-disable-next-line class-methods-use-this
  protected pageFieldsMapper(pageField: PageField_, additionalData: AdditionalData_): PageFieldExtended_<QdApplicationHolder_, CollateralModel_> {
    const { owners, CollateralCatPickList, } = additionalData;
    const checkCollateralLocaltionHidden = (holder_: QdApplicationHolder_, collateral: CollateralModel_) => {
      return collateral?.CategoryCodeSecondCode !== 'P';
    };

    const ownerOptions = owners.map((borrower: Borrower_) => {
      return {
        label: getFullName(borrower),
        value: borrower.Id,
      };
    });

    switch (pageField.FieldName) {
      case 'Category':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-category-field',
          ObjectProperty: 'CategoryCode',
          ObjectPropertyStr: 'CategoryCodeStr',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: CollateralCatPickList,
              ListType: 'LtItems',
            },
          },
        });

      case 'Type':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-type-field',
          ObjectProperty: 'TypeCode',
          ObjectPropertyStr: 'TypeCodeStr',
          Component: {
            type: 'select',
            options: {
              stateLess: true,
            },
          },
        });

      case 'CAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-address-autocomplete-field',
          ObjectProperty: 'Address',
          ObjectPropertyType: 'string',
          Component: {
            type: 'address',
            regexStr: REGEX.PO_BOX,
            errorMessage: Messages.INVALID_STREET_ADDRESS,
          },
          IsHiddenCalculated: (holder_, subSection_) => {
            return isGoogleUnavailable() ? true : checkCollateralLocaltionHidden(holder_, subSection_);
          },
        });

      case 'CStreetAddress':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-street-address-field',
          Label: 'Street Address',
          ObjectProperty: 'Street',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
          },
          IsHiddenCalculated: checkCollateralLocaltionHidden,
        });

      case 'CCity':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-city-address-field',
          Label: 'City',
          ObjectProperty: 'City',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
          },
          IsHiddenCalculated: checkCollateralLocaltionHidden,
        });

      case 'CState':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-state-address-field',
          Label: 'State',
          ObjectProperty: 'State',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
          IsHiddenCalculated: checkCollateralLocaltionHidden,
        });

      case 'CZipCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-zip-address-field',
          Label: 'Zip',
          ObjectProperty: 'Zip',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
          IsHiddenCalculated: checkCollateralLocaltionHidden,
        });

      case 'OwnerOccupiedQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-owner-occupied-question-field',
          ObjectProperty: 'OwnerOccupiedFlag',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { value: 'true', label: 'Yes', },
              { value: 'false', label: 'No', }
            ],
          },
          IsHiddenCalculated: checkCollateralLocaltionHidden,
        });

      case 'Description':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-description-field',
          ObjectProperty: 'Description',
          ObjectPropertyType: 'string',
          Component: {
            type: 'textarea',
            maxLength: 300,
            rows: 5,
          },
        });

      case 'CurrentValue':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-current-value-field',
          ObjectProperty: 'MarketValue',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
        });

      case 'CurrentLoan':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-current-loan-field',
          ObjectProperty: 'CurrentLoanAmount',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
        });
      case 'Owner':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'collateral-owner-field',
          ObjectProperty: 'IdOwner',
          ObjectPropertyStr: 'OwnerStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              stateLess: true,
              initialData: ownerOptions,
            },
          },
        });

      default:
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
    }
  }
}
