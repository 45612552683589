/* eslint array-callback-return: off */
import React, { FC as FC_, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  Section, FormGroup, Input, MaskedInput, Radio, Datepicker
} from '@jkhy/vsg-design-system';

import { AppState as AppState_ } from '../../../../../redux/AppState';
import YourInformation_ from '../../../../../data/models/YourInformation';
import {
  scrollToError, dateFormat, getHoverHelpPageFieldValue, booleanStringComparison, isNullOrUndefined
} from '../../../../../utils/Helper';
import { setLoader } from '../../../../../redux/actions/Loading';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import {
  SameAddressType, CoBorrowerAddType, GuarantorPages, PartyType, GuarantorPageSectionName_
} from '../../../../../utils/Enums';
import QdServiceApi_ from '../../../../../data/api/QDServiceApi';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import { ICoBorrowerSaveHolder as ICoBorrowerSaveHolder_ } from '../../../../../redux/actions/CoBorrowerHolder';
import Message from '../../../../../utils/Messages';
import QDPartyMatchPars_ from '../../../../../data/models/QDPartyMatchPars';
import QDPartyMatchResult_ from '../../../../../data/models/QDPartyMatchResult';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import Borrower_ from '../../../../../data/models/Borrower';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationPersonalMatchCheck as CoBorrowerPersonalMatchCheck_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import { validatePartyMatchTIN } from '../../../../../utils/Validator';
import QdApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';

const YOUR_INFORMATION_STATES = {
  HAS_DONE_BUSINESS: 0,
  GATHER_MATCH_INFORMATION: 1,
  CONFIRM_ADDRESS: 2,
  GREAT_PROCEED: 3,
};

const PAGE_FIELDS_NAMES = {
  FIRST_NAME: 'FirstName',
  LAST_NAME: 'LastName',
  DATE_OF_BIRTH: 'DateOfBirth',
  TIN: 'TIN',
  ADDRESS: 'Address',
  ADDRESS_CONFIRMED: 'AddressConfirmed',
  HAS_DONE_BUSINESS: 'HasDoneBusinessPreviously',
};

export interface IndividualMatchProps {
  // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
  onSave?: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onSubmit?: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, CoBorrowerPersonalMatchCheck_>[],
    holder: QDCoBorrowerApplicationHolder_,
    check: CoBorrowerPersonalMatchCheck_,
    sequence: string
  ) => void;
  onCancel?: () => void;
}

const IndividualMatch: FC_<IndividualMatchProps> = (props: IndividualMatchProps) => {
  const {
    loading,
    // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
    addNowHolder,
    holder,
    appHolder,
    modelState,
  } = useSelector<
    AppState_,
    {
      loading: boolean;
      addNowHolder: CoBorrowerAddNowHolder_;
      holder: QDCoBorrowerApplicationHolder_;
      appHolder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    loading: state.loading,
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    appHolder: state.holderState?.Holder,
    modelState: state.modelState,
  }));

  const {
    ApplicationPageField: {
      PersonalPartyMatch: {
        General: { PartyMatchQuestion, },
      },
    },
  } = modelState?.ModelHolder;
  const [yourInformationState, setYourInformationState] = useState(YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS);
  const [yourInformation, setYourInformation] = useState(new YourInformation_());
  const [invalidSetOfFields, setInvalidSetOfFields] = useState([]);
  const [invalidTINMessage, setInvalidTINMessage] = useState('');

  const qdParty = addNowHolder?.CoBorrower ?? holder?.Personal?.CoBorrower;
  const isAddNow = qdParty.CoBorrowerAddType === CoBorrowerAddType.AddNow;
  const [coBorrower, setCoBorrower] = useState(qdParty);

  useEffect(() => {
    if (isAddNow && qdParty) {
      const { FirstName, LastName, } = qdParty;
      setYourInformation({ ...yourInformation, FirstName, LastName, });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onHasDoneBusinesChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    const {
      target: { value, },
    } = e;
    const hasDoneBussiness = booleanStringComparison(value);
    setYourInformation({ ...yourInformation, [property]: hasDoneBussiness, });

    if (hasDoneBussiness) setYourInformationState(YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION);
    else setYourInformationState(YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    const {
      target: { value, },
    } = e;
    setYourInformation({ ...yourInformation, [property]: value, });
  };

  const onDateChange = (e: any) => {
    setYourInformation({ ...yourInformation, DateOfBirth: dateFormat(e.target?.value), });
  };

  const onAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, },
    } = e;
    setYourInformation({ ...yourInformation, AddressConfirmed: booleanStringComparison(value), });
  };

  const isAdult = value => moment().diff(value, 'year') >= 18;
  const validate = () => {
    const invalidSetOfF = [];
    const {
      FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, TIN, ADDRESS_CONFIRMED, HAS_DONE_BUSINESS,
    } = PAGE_FIELDS_NAMES;

    if (yourInformationState === YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS) {
      const { Required, } = PartyMatchQuestion;
      const isValid = !Required || (Required && !isNullOrUndefined(yourInformation.HasDoneBusinessPreviously));
      if (!isValid) {
        invalidSetOfF.push(HAS_DONE_BUSINESS);
        setInvalidSetOfFields(invalidSetOfF);
      }
      return isValid;
    }

    if (!yourInformation.FirstName) invalidSetOfF.push(FIRST_NAME);
    if (!yourInformation.LastName) invalidSetOfF.push(LAST_NAME);
    if (!yourInformation.DateOfBirth || !isAdult(yourInformation.DateOfBirth)) invalidSetOfF.push(DATE_OF_BIRTH);

    const { IsValid: isTINValid, InvalidMessage: tinMessage, } = validatePartyMatchTIN(yourInformation.TIN, PartyType.Personal, appHolder, isAddNow);

    if (!isTINValid) invalidSetOfF.push(TIN);
    setInvalidTINMessage(tinMessage);

    if (yourInformationState === YOUR_INFORMATION_STATES.CONFIRM_ADDRESS && yourInformation.AddressConfirmed == null) {
      invalidSetOfF.push(ADDRESS_CONFIRMED);
    }

    setInvalidSetOfFields(invalidSetOfF);
    return invalidSetOfF.length === 0;
  };
  const getDateInvalidMessage = () => (!yourInformation.DateOfBirth ? Message.REQUIRED_FIELD : Message.INVALID_AGE);

  const dispatch = useDispatch();
  const updateHolder = (borrowerCheck: Borrower_, personaMatchCheck: CoBorrowerPersonalMatchCheck_) => {
    const coBorrowerPersonal = { ...holder.Personal, CoBorrower: borrowerCheck, };
    const saveHolderAction: ICoBorrowerSaveHolder_ = {
      coBorrowerHolderState: {
        CoBorrowerHolder: { ...holder, PersonalMatchCheck: personaMatchCheck, Personal: coBorrowerPersonal, },
        IsCoBorrowerHolderFetched: true,
      },
      type: 'SaveCoBorrowerHolder',
    };
    dispatch(saveHolderAction);
  };

  const onSubmit = async () => {
    if (isAddNow) {
      // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
      const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: coBorrower, };
      await props.onSave(coBorrwerAddNowHolder, GuarantorPageSectionName_.PersonalPartyMatch);
    } else {
      const borrowerCheck = {
        ...holder?.Personal?.CoBorrower,
        PartyMatchDone: coBorrower.PartyMatchDone,
        PartyMatchConfirmedByUser: coBorrower.PartyMatchConfirmedByUser,
        IdParty: coBorrower.IdParty,
        FirstName: coBorrower.FirstName,
        LastName: coBorrower.LastName,
        DOB: coBorrower.DOB,
        TIN: coBorrower.TIN,
      };

      const personaMatchCheck = { ...holder.PersonalMatchCheck, CoBorrower: borrowerCheck, };
      updateHolder(borrowerCheck, personaMatchCheck);
      await props.onSubmit([], holder, personaMatchCheck, GuarantorPages.PersonalPartyMatch);
    }
  };

  const getAddress = (pars: QDPartyMatchResult_) => {
    const {
      Street, State, Zip, City,
    } = pars;
    let address = [Street, City, State].filter(value => value).join(', ');
    if (Zip) address = ` ${address} ${Zip}`;
    return address;
  };

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    switch (yourInformationState) {
      case YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS:
        if (validate()) {
          coBorrower.PartyMatchDone = true;
          setCoBorrower(coBorrower);

          setLoader(true);
          await onSubmit();
          setLoader(false);
        } else {
          setTimeout(() => {
            scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
          }, 300);
        }
        break;
      case YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION:
        setLoader(true);
        if (validate()) {
          const pars: QDPartyMatchPars_ = {
            PartyType: coBorrower.PartyType,
            GUIDQDApplication: coBorrower.GUIDQDApplication,
            TIN: yourInformation.TIN,
            BirthDate: yourInformation.DateOfBirth,
            FirstName: yourInformation.FirstName?.trim(),
            LastName: yourInformation.LastName?.trim(),
          };

          const { Result, } = await QdServiceApi_.qdPartyMatch(pars);

          coBorrower.PartyMatchDone = true;
          coBorrower.FirstName = yourInformation.FirstName;
          coBorrower.LastName = yourInformation.LastName;
          coBorrower.DOB = yourInformation.DateOfBirth;
          coBorrower.TIN = yourInformation.TIN;

          setCoBorrower(coBorrower);

          const address = getAddress(Result);
          const sameAddressAsBorrower = coBorrower.SameAddressType === SameAddressType.Yes;
          if (Result?.IdParty && (sameAddressAsBorrower || address)) {
            yourInformation.IdPartyMatch = Result?.IdParty;

            if (sameAddressAsBorrower) {
              yourInformation.AddressConfirmed = true;
              coBorrower.PartyMatchConfirmedByUser = true;
              coBorrower.IdParty = yourInformation.IdPartyMatch;
              setCoBorrower(coBorrower);
            } else if (address) {
              yourInformation.Address = address;
              setYourInformation(yourInformation);
            }

            const nextState = sameAddressAsBorrower ? YOUR_INFORMATION_STATES.GREAT_PROCEED : YOUR_INFORMATION_STATES.CONFIRM_ADDRESS;
            setYourInformationState(nextState);
          } else {
            setLoader(true);
            await onSubmit();
            setLoader(false);
          }
        } else {
          setTimeout(() => {
            scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
          }, 300);
        }
        setLoader(false);
        break;
      case YOUR_INFORMATION_STATES.CONFIRM_ADDRESS:
        if (validate()) {
          if (yourInformation.AddressConfirmed) {
            coBorrower.IdParty = yourInformation.IdPartyMatch;
            coBorrower.PartyMatchConfirmedByUser = true;
            setCoBorrower(coBorrower);
            setYourInformationState(YOUR_INFORMATION_STATES.GREAT_PROCEED);
          } else {
            setLoader(true);
            await onSubmit();
            setLoader(false);
          }
        }
        break;
      case YOUR_INFORMATION_STATES.GREAT_PROCEED:
        setLoader(true);
        await onSubmit();
        setLoader(false);
        break;
      default:
        break;
    }
  };

  const IsValid = field => !invalidSetOfFields.find(invalidField => invalidField === field);
  const {
    FIRST_NAME, LAST_NAME, DATE_OF_BIRTH, TIN, ADDRESS_CONFIRMED, HAS_DONE_BUSINESS,
  } = PAGE_FIELDS_NAMES;
  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.PersonalPartyMatch}`} onSubmit={onSave}>
          {(yourInformationState === YOUR_INFORMATION_STATES.GATHER_MATCH_INFORMATION
            || yourInformationState === YOUR_INFORMATION_STATES.HAS_DONE_BUSINESS) && (
            <Section
              title="Your Information"
              headerText="Let us see if we can locate your information. Please enter the following information."
              dataUI="co-borrower-add-now-party-check"
            >
              <FormGroup
                className="mb-2"
                label={PartyMatchQuestion.Label}
                checkboxOrRadio
                isBold={PartyMatchQuestion.IsBold}
                isRequired={PartyMatchQuestion.Required}
                hoverHelp={getHoverHelpPageFieldValue(PartyMatchQuestion)}
                isValid={IsValid(HAS_DONE_BUSINESS)}
                validationMessage={Message.REQUIRED_FIELD}
                dataUI="has-done-business-before"
                htmlFor={PartyMatchQuestion.FieldName}
              >
                <Radio
                  htmlFor={`${PartyMatchQuestion.FieldName}-Yes`}
                  id={`${PartyMatchQuestion.FieldName}-Yes`}
                  value="true"
                  name={PartyMatchQuestion.FieldName}
                  onChange={e => onHasDoneBusinesChange(e, HAS_DONE_BUSINESS)}
                  dataUI="has-done-business-before-yes"
                  className="d-inline-block"
                >
                  Yes
                </Radio>
                <Radio
                  htmlFor={`${PartyMatchQuestion.FieldName}-No`}
                  id={`${PartyMatchQuestion.FieldName}-No`}
                  value="false"
                  name={PartyMatchQuestion.FieldName}
                  onChange={e => onHasDoneBusinesChange(e, HAS_DONE_BUSINESS)}
                  dataUI="has-done-business-before-no"
                  className="d-inline-block"
                >
                  No
                </Radio>
              </FormGroup>

              {yourInformation.HasDoneBusinessPreviously && (
                <>
                  <FormGroup
                    className="mb-2"
                    htmlFor="firstName"
                    isRequired
                    label="First Name"
                    dataUI="co-borrower-first-name"
                    isValid={IsValid(FIRST_NAME)}
                    validationMessage={Message.REQUIRED_FIELD}
                  >
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      dataUI="txtFirstName"
                      onChange={e => onChange(e, FIRST_NAME)}
                      value={yourInformation.FirstName}
                    />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="secondName"
                    isRequired
                    label="Last Name"
                    dataUI="co-borrower-last-name"
                    isValid={IsValid(LAST_NAME)}
                    validationMessage={Message.REQUIRED_FIELD}
                  >
                    <Input
                      type="text"
                      name="secondName"
                      id="secondName"
                      dataUI="txtLastName"
                      onChange={e => onChange(e, LAST_NAME)}
                      value={yourInformation.LastName}
                    />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="dpDef"
                    isRequired
                    label="Date of Birth"
                    dataUI="co-borrower-date-of-birth"
                    isValid={IsValid(DATE_OF_BIRTH)}
                    validationMessage={getDateInvalidMessage()}
                  >
                    <Datepicker id="dpDef" dataUI="fgDateOfBirth" onChange={e => onDateChange(e)} format="MM/DD/YYYY" />
                  </FormGroup>
                  <FormGroup
                    className="mb-2"
                    htmlFor="tin"
                    isRequired
                    label="Social Security Number"
                    dataUI="co-borrower-ssn-last-digits"
                    isValid={IsValid(TIN)}
                    validationMessage={invalidTINMessage}
                  >
                    <MaskedInput
                      id="tin"
                      className="text-left"
                      dataUI="mtxtLastDigits"
                      pattern={null}
                      type="password"
                      mask={InputMasksConstants.SSN_TAX_ID}
                      onChange={e => onChange(e, TIN)}
                    />
                  </FormGroup>
                </>
              )}
            </Section>
          )}

          {yourInformationState === YOUR_INFORMATION_STATES.CONFIRM_ADDRESS && (
            <Section title="Address" dataUI="co-borrower-add-now-address">
              <FormGroup
                className="mb-2"
                htmlFor="address"
                isRequired
                checkboxOrRadio
                label="Is this your current address?"
                dataUI="co-borrower-confirm-address"
                isValid={IsValid(ADDRESS_CONFIRMED)}
                validationMessage={Message.REQUIRED_FIELD}
              >
                <Radio
                  dataUI="co-borrower-confirm-address-yes"
                  htmlFor="addressRadio"
                  id="addressRadio"
                  className="d-inline-block"
                  name="address"
                  value="true"
                  onChange={onAddressChange}
                >
                  {yourInformation.Address}
                </Radio>
                <Radio
                  dataUI="co-borrower-confirm-address-no"
                  htmlFor="addressRadio2"
                  id="addressRadio2"
                  className="d-inline-block"
                  name="address"
                  value="false"
                  onChange={onAddressChange}
                >
                  I have a different address.
                </Radio>
              </FormGroup>
            </Section>
          )}

          {yourInformationState === YOUR_INFORMATION_STATES.GREAT_PROCEED && (
            <Section title="Your Information" dataUI="co-borrower-add-now-proceed-section">
              <p>Good News!</p>
              <br />
              <p>
                We recognize you as an existing client. Shortly you will be presented with questions to verify your identity so you can continue the
                application.
              </p>
            </Section>
          )}
        </form>
      )}
    </>
  );
};

export default IndividualMatch;
