/* eslint-disable no-unused-vars */

export enum QuickDecisionPageTypes {
  QuickDecision = 12,
  QuickDecisionRenewals = 18,
  QuickDecisionGuarantor = 20,
  QDPrequalification = 22,
}

export default class PageField {
  IdPageFieldDefinition: bigint;

  IdPageFieldTemplate: bigint;

  IdUDFDefinition: bigint;

  Required: boolean;

  IsHidden: boolean = false;

  IsEditable: boolean;

  EditableWhenLocked: boolean;

  IsBold: boolean;

  IdCompany: bigint;

  GroupName: string;

  SortOrder: number;

  IsReservedFlag: boolean;

  FieldName: string;

  PageType: number;

  PageTypeEnumStr: string;

  TemplateName: string;

  Name: string;

  Label: string;

  CustomLabel: string;

  HoverHelp: string;

  HoverHelpEnabledFlag: boolean;

  ObjectProperty: string;

  ObjectPropertyStr?: string;

  ObjectType: string;

  EnableHiddenSelection: boolean;

  Id: bigint;

  TreatAllNulls: boolean;

  NullFields: string[];

  IdUserLastModified: bigint;
}
