/* eslint-disable no-unused-vars */
import QDPartyDisclosure_ from './QDPartyDisclosure';
import Question_ from './Question';

export enum QDApplicationTypeAnswer {
  Business = 0,
  Individual = 1
}

export default class Information {
  SectionName: string;

  Questions: Question_[];

  Disclosures: string;

  ESignConsent: boolean;

  ESignConsentDate: string;

  OfficerQuestionCode: string;

  OfficerQuestionCodeStr: string;

  BranchQuestionCode: string;

  BranchQuestionCodeStr: string;

  ZipBusinessLocated: string;

  StateBusinessLocated: string;

  StateBusinessLocatedStr: string;

  CountyCode: string;

  CountyCodeStr: string;

  InformationDisclosuresSectionId: bigint;

  QDPartyGuids: QDPartyDisclosure_[];

  Id: number;

  TreatAllNulls: boolean;

  NullFields: string[];

  IdUserLastModified: number;

  ApplicationTypeAnswer: QDApplicationTypeAnswer;

  ApplicationTypeAnswerEnumStr: string;
}
