import React, {
  FC as FC_, useEffect, useState, useMemo
} from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  ActionsWrapper, Button, HorizontalInfoCard, IconsSolid, IconsLight, Modal, Section
} from '@jkhy/vsg-design-system';
import Page from '../../../Page/Page';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import {
  AddressType, AddressPersonalPageSteps, GuarantorPageSectionName_, GuarantorPages
} from '../../../../../utils/Enums';
import { calculateDifferenceText, getGoogleApiAddressAsString } from '../../../../../utils/Address';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import QDAddress from '../../../../../data/models/QDAddress';
import IndividualAddressSettings from './PersonalAdressSettings';
import { UpdateAction as UpdateAction_ } from '../../../../../redux/actions/SubmitButton';
import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import {
  AddressDetails as AddressDetails_,
  ExternalModelHolder as ExternalModelHolder_,
  ModelState as ModelState_
} from '../../../../../utils/Types';
import { setCoBorrowerAddNowState } from '../../../../../redux/actions/CoBorrowerAddNow';
import { checkHasIDVerificationShowed } from '../../../../../utils/IdentityVerification';
import { CoBorrowerIdentityState as CoBorrowerIdentityState_ } from '../../../../../redux/actions/CoBorrowerIdentity';

export interface CoBorrowerAddNowPersonalAddressProps {
  onSave: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel: () => void;
}

const CoBorrowerAddNowPersonalAddress: FC_<CoBorrowerAddNowPersonalAddressProps> = (props: CoBorrowerAddNowPersonalAddressProps) => {
  const {
    addNowHolder, modelState, loading, identityState,
  } = useSelector<
    AppState_,
    {
      addNowHolder: CoBorrowerAddNowHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
      identityState: CoBorrowerIdentityState_;
    }
  >(state => ({
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    modelState: state.modelState,
    loading: state.loading,
    identityState: state.coBorrowerIdentityState,
  }));

  const personalAddresses = addNowHolder?.Addresses;

  const hasIDVerificationShowed = useMemo(() => {
    return checkHasIDVerificationShowed(addNowHolder.CoBorrower, identityState);
  }, [addNowHolder.CoBorrower, identityState]);

  const {
    initialAddresses, initialAddressInProgress, initialActiveIndex, initialStep,
  } = useMemo(() => {
    const modifiedPersonalAddresses = personalAddresses?.map((address: QDAddress) => {
      return { ...address, GoogleAPIAddress: getGoogleApiAddressAsString(address), };
    });

    const currentAddress = modifiedPersonalAddresses?.find(a => a.AddressType === AddressType.Current);
    const hasCurrentAddressSavedByUser = currentAddress?.IsSavedByUser;
    // eslint-disable-next-line max-len
    const initAddressInProgress = currentAddress && !hasCurrentAddressSavedByUser ? { ...currentAddress, } : { ...new QDAddress(), AddressType: AddressType.Current, };

    const initIndex = hasCurrentAddressSavedByUser ? -1 : 0;
    const initStep = hasCurrentAddressSavedByUser ? AddressPersonalPageSteps.HorizontalCardOverview : AddressPersonalPageSteps.CurrentAddress;

    return {
      initialAddresses: modifiedPersonalAddresses,
      initialAddressInProgress: initAddressInProgress,
      initialActiveIndex: initIndex,
      initialStep: initStep,
    };
  }, [personalAddresses]);

  const [addresses, setAddresses] = useState(initialAddresses);
  const [addressesInProgress, setAddressInProgress] = useState(initialAddressInProgress);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [activeAddressIndex, setActiveAddressIndex] = useState(initialActiveIndex);
  const [step, setStep] = useState(initialStep);

  const section = modelState?.ModelHolder?.CoBorrowerSections?.find(s => (s.SectionName || '') === GuarantorPageSectionName_.PersonalAddress);
  const [subSection] = section.SubSections;

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const {
    PersonalAddress: { CurrentAddress, PreviousAddress, },
  } = pageFieldHolder;

  const pageFieldsInUse = useMemo(() => {
    // return addressesInProgress?.AddressType !== AddressType.Current ? PreviousAddress : CurrentAddress;
    if (addressesInProgress?.AddressType === AddressType.Current) {
      const { SortOrder: currentAddressSortOrder, ...currentAddress } = CurrentAddress.CurrentAddress;
      const { SortOrder: mailingAddressSortOrder, ...mailingAddress } = CurrentAddress.MailingAddress;

      return {
        ...CurrentAddress,
        // Fields not part of Page Fields settings in Admin, i.e Database
        DifferentMailingAddress: { ...mailingAddress, FieldName: 'DifferentMailingAddress', SortOrder: mailingAddressSortOrder - 1, },
        MailingZipCode: { ...mailingAddress, FieldName: 'MailingZip', SortOrder: mailingAddressSortOrder + 1, },
        MailingCity: { ...mailingAddress, FieldName: 'MailingCity', SortOrder: mailingAddressSortOrder + 2, },
        MailingState: { ...mailingAddress, FieldName: 'MailingState', SortOrder: mailingAddressSortOrder + 3, },
        StreetAddress: { ...currentAddress, FieldName: 'StreetAddress', SortOrder: currentAddressSortOrder + 1, },
        City: { ...currentAddress, FieldName: 'City', SortOrder: currentAddressSortOrder + 2, },
        State: { ...currentAddress, FieldName: 'State', SortOrder: currentAddressSortOrder + 3, },
        ZipCode: { ...currentAddress, FieldName: 'Zip', SortOrder: currentAddressSortOrder + 4, },
      };
    }

    const { SortOrder: previousAddressSortOrder, ...previousAddress } = PreviousAddress.PreviousAddress;

    return {
      ...PreviousAddress,
      // Fields not part of Page Fields settings in Admin, i.e Database
      StreetAddress: { ...previousAddress, FieldName: 'StreetAddress', SortOrder: previousAddressSortOrder + 1, },
      City: { ...previousAddress, FieldName: 'City', SortOrder: previousAddressSortOrder + 2, },
      State: { ...previousAddress, FieldName: 'State', SortOrder: previousAddressSortOrder + 3, },
      ZipCode: { ...previousAddress, FieldName: 'Zip', SortOrder: previousAddressSortOrder + 4, },
    };
  }, [addressesInProgress, PreviousAddress, CurrentAddress]);

  const pageSettings = useMemo(() => {
    const startDateLabel = pageFieldsInUse?.AddressStartDate?.Label;
    const additionalSettings = {
      startDateLabel,
      hasIDVerificationShowed,
      isCurrentAddress: addressesInProgress.AddressType === AddressType.Current,
    };

    return new IndividualAddressSettings([pageFieldsInUse], [subSection], additionalSettings);
  }, [addressesInProgress.AddressType, hasIDVerificationShowed, pageFieldsInUse, subSection]);

  const dispatch = useDispatch();
  const showSubmitButtonFlag = (show: boolean) => {
    const updateSubmitButtonAction: UpdateAction_ = {
      state: { submitToReview: false, show, },
      type: 'UpdateSubmitButton',
    };

    dispatch(updateSubmitButtonAction);
  };
  const updateAddNowState = (modifiedAddresses: QDAddress[]) => {
    const modifiedHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, Addresses: modifiedAddresses, };
    dispatch(setCoBorrowerAddNowState(modifiedHolder, true));
  };

  useEffect(() => {
    const showSubmit = step === AddressPersonalPageSteps.HorizontalCardOverview;
    showSubmitButtonFlag(showSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    return () => {
      showSubmitButtonFlag(true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (pageField: PageFieldExtended_<any, QDAddress>, value: string, e: React.ChangeEvent, text: string) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;
    addressesInProgress[ObjectProperty] = value;
    if (ObjectPropertyStr) {
      addressesInProgress[ObjectPropertyStr] = text;
    }

    setAddressInProgress({ ...addressesInProgress, });
  };

  const onAddressSelect = (pageField: PageFieldExtended_<any, QDAddress>, addressDetails: AddressDetails_) => {
    const { ObjectProperty, } = pageField;
    if (addressDetails) {
      const {
        fullAddress, streetAddress, city, state, zipCode,
      } = addressDetails;

      addressesInProgress[ObjectProperty] = fullAddress;
      addressesInProgress.Address = streetAddress;
      addressesInProgress.City = city;
      addressesInProgress.State = state;
      addressesInProgress.Zip = zipCode;
    }
    setAddressInProgress({ ...addressesInProgress, });
  };

  const onAdd = () => {
    const previousAddress = new QDAddress();
    previousAddress.AddressType = AddressType.Previous;
    setAddressInProgress(previousAddress);
    setStep(AddressPersonalPageSteps.PreviousAddress);
  };

  const onEdit = index => {
    const address = addresses[index];
    setActiveAddressIndex(index);
    setAddressInProgress(address);
    const editStep = address.AddressType === AddressType.Current ? AddressPersonalPageSteps.CurrentAddress : AddressPersonalPageSteps.PreviousAddress;
    setStep(editStep);
  };

  const onDelete = async () => {
    const id = addresses[removeIndex]?.Id;
    if (id) {
      await QdServiceApi.removeQDAddress(id);
    }
    const editedData = _.reject(addresses, (x, i) => {
      return i === removeIndex;
    });
    setAddresses([...editedData]);
    updateAddNowState([...editedData]);
    setRemoveIndex(-1);
  };

  const onCancel = () => {
    setStep(AddressPersonalPageSteps.HorizontalCardOverview);
    setActiveAddressIndex(-1);
    setAddressInProgress(new QDAddress());
  };

  const onSave = async (invalidPageFields: PageFieldExtended_<CoBorrowerAddNowHolder_, QDAddress>[]) => {
    if (invalidPageFields.length === 0) {
      const isNew = !addressesInProgress.Id;
      const {
        CoBorrower: { Id, IdQDApplication, },
      } = addNowHolder;
      if (!addressesInProgress.IdQDParty) {
        addressesInProgress.IdQDParty = Id;
      }
      if (!addressesInProgress.IdQDApplication) {
        addressesInProgress.IdQDApplication = IdQDApplication;
      }
      addressesInProgress.IsSavedByUser = true;

      const { Result: saved, } = await QdServiceApi.saveQDAddress(addressesInProgress);
      if (isNew) {
        addressesInProgress.Id = saved.Id;
        addressesInProgress.GUID = saved.GUID;
        setAddresses([...addresses, addressesInProgress]);
        updateAddNowState([...addresses, addressesInProgress]);
      } else if (activeAddressIndex > -1) {
        addresses[activeAddressIndex] = addressesInProgress;
        setAddresses([...addresses]);
        updateAddNowState([...addresses]);
      }
      setActiveAddressIndex(-1);
      setAddressInProgress(new QDAddress());
      setStep(AddressPersonalPageSteps.HorizontalCardOverview);
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const coBorrwerAddNowHolder: CoBorrowerAddNowHolder_ = {
      ...addNowHolder,
      Addresses: addresses,
    };
    await props.onSave(coBorrwerAddNowHolder, GuarantorPageSectionName_.PersonalAddress);
  };

  return (
    <>
      {!loading && step === AddressPersonalPageSteps.HorizontalCardOverview && (
        <form id={`form-${GuarantorPages.PersonalAddress}`} onSubmit={onSubmit}>
          <Section
            dataUI="guarantor-personal-address-page"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
          >
            {addresses.map((a, index) => {
              const key = `${a.Address}-${a.AddressType}-${a.Id}`;
              const isCurrentAddress = a?.AddressType === AddressType.Current;

              return (
                <HorizontalInfoCard
                  key={key}
                  title={a.Address}
                  description={calculateDifferenceText(a.AddressStartDate, a.AddressEndDate)}
                  className="mb-2"
                  icon={isCurrentAddress ? IconsLight.faHome : IconsLight.faBuilding}
                  dataUI={`address-horizontal-info-card-${index}`}
                  onEdit={() => {
                    onEdit(index);
                  }}
                  onDelete={
                    isCurrentAddress
                      ? null
                      : () => {
                        setRemoveIndex(index);
                      }
                  }
                />
              );
            })}
            <Button dataUI="add-address-button" iconLeft={IconsSolid.faPlus} onClick={onAdd}>
              Add Address
            </Button>
          </Section>
        </form>
      )}

      {!loading && step !== AddressPersonalPageSteps.HorizontalCardOverview && (
        <>
          <Page
            pageSettings={pageSettings}
            onChange={onChange}
            onAddressSelect={onAddressSelect}
            holder={addNowHolder}
            subHolder={addressesInProgress}
            onSubmit={onSave}
            formIdentifier="form-co-borrower-add-now-address"
          />
          <ActionsWrapper dataUI="address-actions-wrapper" className="mt-3">
            {/* Тhe address may have an id, but it may not be saved, because in a party match process, the address comes from LV party.
              In this case, it has an id, but has never been saved in QD */}
            {((addressesInProgress?.Id && addressesInProgress?.IsSavedByUser) || addressesInProgress?.AddressType !== AddressType.Current) && (
              <Button
                dataUI="cancel-address-button"
                btnType="secondary"
                className="action-spacer"
                iconLeft={IconsSolid.faTimesCircle}
                onClick={onCancel}
              >
                Cancel
              </Button>
            )}

            <Button dataUI="save-address-button" iconLeft={IconsSolid.faSave} form="form-co-borrower-add-now-address" type="submit">
              Save
            </Button>
          </ActionsWrapper>
        </>
      )}

      <Modal
        dataUI="remove-confiramtion-modal"
        title="Confirm to delete"
        isVisible={removeIndex > -1}
        isClosable
        closeBtnName="Cancel"
        closeBtnIcon={IconsSolid.faTimesCircle}
        onClose={() => setRemoveIndex(-1)}
        actionBtnName="YES, DELETE"
        actionBtnIcon={IconsSolid.faTrash}
        onSubmit={onDelete}
        size="S"
      >
        <p>Are you sure you want to delete?</p>
      </Modal>
    </>
  );
};

export default CoBorrowerAddNowPersonalAddress;
