import React, {
  FC as FC_, Fragment, useEffect, useCallback, useMemo, memo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import ReviewApplicationGeneralSection from '../ReviewApplicationGeneralSection';
import BusinessOtherInformation from '../BusinessOtherInformation/BusinessOtherInformation';
import PersonalBorrower from '../PersonalBorrower/PersonalBorrower';
import BusinessBorrower from '../BusinessBorrower/BusinessBorrower';
import { getFullName } from '../../../../utils/Helper';
import { ExternalPages, ExternalPageSectionName } from '../../../../utils/Enums';
import { getSectionTabName } from '../../../../utils/ReviewApplication';
import Employment from '../Employment/Employment';
import BusinessAddresses from '../Addresses/BusinessAddresses/BusinessAddresses';
import GeneralGuarantorsInformation from './GeneralGuarantorsInformation/GeneralGuarantorsInformation';
import PersonalAddresses from '../Addresses/PersonalAddresses/PersonalAddresses';
import { isAddNowGuarantor, isBusinessParty } from '../../../../utils/Borrower';
import QDAddress_ from '../../../../data/models/QDAddress';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import Borrower_ from '../../../../data/models/Borrower';
import Employment_ from '../../../../data/models/Employment';
import { fetchCoBorrowerPageFields } from '../../CoBorrower/CoBorrower';
import { setLoader } from '../../../../redux/actions/Loading';
import Section_ from '../../../../data/models/Section';

type GuarantorsProps = ReviewApplicationSectionProps_;

type GuarantorTabNames = {
  otherInfoTabName: string;
  businessBorrowerTabName: string;
  personalBorrowerTabName: string;
  personalAddressesTabName: string;
  businessAddressesTabName: string;
  employmentTabName: string;
  generalGuarantorsInformationTabName: string;
};

const Guarantors: FC_<GuarantorsProps> = ({ expandState, setExpandState, }: GuarantorsProps) => {
  const { holder, modelState, sections, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      sections: Section_[];
    }
  >(state => ({
    holder: state.holderState.Holder,
    modelState: state.modelState,
    sections: state.sectionsState.VisibleSections,
  }));

  const guarantors = holder?.Guarantor?.Guarantors;

  const dispatch = useDispatch();

  const sectionTitle = useMemo(() => {
    return getSectionTabName(sections, ExternalPageSectionName.Guarantor, 'Co-Applicant');
  }, [sections]);

  const getGuarantorTabNames = useCallback(
    (isAddNow: boolean, isBusinessGuarantor: boolean): GuarantorTabNames => {
      const result = {
        otherInfoTabName: '',
        businessBorrowerTabName: '',
        personalBorrowerTabName: '',
        personalAddressesTabName: '',
        businessAddressesTabName: '',
        employmentTabName: '',
        generalGuarantorsInformationTabName: '',
      };

      if (isAddNow && isBusinessGuarantor) {
        result.businessBorrowerTabName = getSectionTabName(sections, ExternalPageSectionName.BusinessInformation, 'Business Information');
        result.otherInfoTabName = getSectionTabName(sections, ExternalPageSectionName.BusinessBorrowerOtherInformation, 'Business Other Information');
        result.businessAddressesTabName = getSectionTabName(sections, ExternalPageSectionName.BusinessAddresses, 'Business Address');
      } else if (isAddNow && !isBusinessGuarantor) {
        result.personalBorrowerTabName = getSectionTabName(sections, ExternalPageSectionName.YourInformation, 'Personal Information');
        result.employmentTabName = getSectionTabName(sections, ExternalPageSectionName.Employment, 'Employment');
        result.personalAddressesTabName = getSectionTabName(sections, ExternalPageSectionName.PersonalAddresses, 'Personal Address');
      } else {
        result.generalGuarantorsInformationTabName = getSectionTabName(sections, null, 'Email Invitation');
      }

      return Object.entries(result).reduce((acc, [key, value]) => {
        acc[key] = value ? `${sectionTitle} ${value}` : '';

        return acc;
      }, {} as GuarantorTabNames);
    },
    [sectionTitle, sections]
  );

  useEffect(() => {
    const loadData = async () => {
      dispatch(setLoader(true));

      await fetchCoBorrowerPageFields(modelState, dispatch);

      dispatch(setLoader(false));
    };

    if (!modelState.ModelHolder.CoBorrowerPageField && guarantors.length > 0) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const page = ExternalPages.CoBorrower;

  if (guarantors.length === 0) {
    return null;
  }

  return (
    <ReviewApplicationGeneralSection page={null} sectionTitle={sectionTitle} plainContainer>
      <>
        {guarantors.map((guarantor: Borrower_) => {
          const guarantorId = guarantor.Id;

          const isBusinessGuarantor = isBusinessParty(guarantor);
          const isAddNow = isAddNowGuarantor(guarantor);

          const fullName = getFullName(guarantor);

          const guarantorAddNowAddresses = isAddNow
            ? holder?.Guarantor?.GuarantorsAddNowAddresses?.filter((address: QDAddress_) => address.IdQDParty === guarantorId)
            : null;

          const guarantorAddNowEmployment = isAddNow
            ? holder?.Guarantor?.GuarantorsAddNowEmployments?.find((e: Employment_) => e.IdQDParty === guarantorId)
            : null;

          const {
            otherInfoTabName,
            businessBorrowerTabName,
            personalBorrowerTabName,
            personalAddressesTabName,
            businessAddressesTabName,
            employmentTabName,
            generalGuarantorsInformationTabName,
          } = getGuarantorTabNames(isAddNow, isBusinessGuarantor);

          return (
            <Fragment key={`${guarantorId} `}>
              {isBusinessGuarantor && isAddNow && (
                <>
                  <BusinessBorrower
                    isCoBorrower
                    hasFullAccess
                    page={page}
                    businessBorrower={guarantor}
                    sectionTitle={businessBorrowerTabName}
                    expandState={expandState}
                    setExpandState={setExpandState}
                  />

                  <BusinessOtherInformation
                    isCoBorrower
                    page={page}
                    businessBorrower={guarantor}
                    sectionTitle={otherInfoTabName}
                    expandState={expandState}
                    setExpandState={setExpandState}
                  />
                </>
              )}

              {!isBusinessGuarantor && isAddNow && (
                <>
                  <PersonalBorrower
                    isCoBorrower
                    hasFullAccess
                    page={page}
                    personalBorrower={guarantor}
                    sectionTitle={personalBorrowerTabName}
                    expandState={expandState}
                    setExpandState={setExpandState}
                  />
                </>
              )}

              {isAddNow && !isBusinessGuarantor && (
                <PersonalAddresses
                  isCoBorrower
                  page={page}
                  personalAddresses={guarantorAddNowAddresses}
                  sectionTitle={personalAddressesTabName}
                  expandState={expandState}
                  setExpandState={setExpandState}
                />
              )}

              {isAddNow && isBusinessGuarantor && guarantorAddNowAddresses && (
                <BusinessAddresses
                  isCoBorrower
                  page={page}
                  businessAddresses={guarantorAddNowAddresses}
                  sectionTitle={businessAddressesTabName}
                  expandState={expandState}
                  setExpandState={setExpandState}
                />
              )}

              {isAddNow && guarantorAddNowEmployment && (
                <Employment
                  isCoBorrower
                  page={page}
                  guarantorFullName={fullName}
                  employment={guarantorAddNowEmployment}
                  sectionTitle={employmentTabName}
                  expandState={expandState}
                  setExpandState={setExpandState}
                />
              )}

              {!isAddNow && (
                <GeneralGuarantorsInformation
                  hasFullAccess
                  isCoBorrower
                  page={page}
                  guarantor={guarantor}
                  sectionTitle={generalGuarantorsInformationTabName}
                  expandState={expandState}
                  setExpandState={setExpandState}
                />
              )}
            </Fragment>
          );
        })}
      </>
    </ReviewApplicationGeneralSection>
  );
};

export default memo(Guarantors);
