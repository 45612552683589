import React, {
  FC as FC_, memo, useMemo, useEffect, useCallback
} from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon, IconsRegular } from '@jkhy/vsg-design-system';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import QdServiceApi from '../../../../data/api/QDServiceApi';
import { isSectionVisibleBySequence } from '../../../../utils/ReviewApplication';
import {
  CoBorrowerAddType,
  DisplayOptions,
  ExternalPages,
  ExternalPageSectionName,
  GuarantorPages,
  GuarantorPageSectionName_,
  ValueConstants
} from '../../../../utils/Enums';
import { getFullName, isNullOrUndefined } from '../../../../utils/Helper';
import { DisclosuresState as DisclosuresState_, setInformationDisclosures, setMainDisclosures } from '../../../../redux/actions/Disclosures';
import { setLoader } from '../../../../redux/actions/Loading';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { AppState as AppState_ } from '../../../../redux/AppState';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import Borrower_ from '../../../../data/models/Borrower';
import Section_ from '../../../../data/models/Section';
import QDCoBorrowerApplicationHolder_ from '../../../../data/models/QDCoBorrowerApplicationHolder';

type DisclosuresProps = ReviewApplicationSectionProps_;

const Disclosures: FC_<DisclosuresProps> = ({
  isCoBorrower, page, sectionTitle, expandState, setExpandState,
}: DisclosuresProps) => {
  const {
    holder, coBorrowerHolder, sections, disclosuresState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      coBorrowerHolder: QDCoBorrowerApplicationHolder_;
      sections: Section_[];
      disclosuresState: DisclosuresState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    coBorrowerHolder: state.coBorrowerHolderState.CoBorrowerHolder,
    sections: state.sectionsState.VisibleSections,
    disclosuresState: state.disclosuresState,
  }));

  const { informationDisclosures, mainDisclosures, } = disclosuresState || {};

  const { isFetched: isInformationDisclosuresFetched, } = informationDisclosures || {};
  const { isFetched: isMainDisclosuresFetched, } = mainDisclosures || {};

  const coBorrower = isCoBorrower ? coBorrowerHolder.Personal.CoBorrower ?? coBorrowerHolder.Business.CoBorrower : null;
  const coBorrowerGuid = coBorrower?.GUID;
  const primaryBorrower = isCoBorrower ? coBorrower : holder.BorrowerP.Personal;
  const applicationGuid = primaryBorrower.GUIDQDApplication;

  const informationSectionName = isCoBorrower ? GuarantorPageSectionName_.Information : ExternalPageSectionName.InformationMembership;
  const disclosuresSectionName = isCoBorrower ? GuarantorPageSectionName_.Disclosures : ExternalPageSectionName.Disclosures;
  const isMainDisclosurePage = isCoBorrower ? page === GuarantorPages.Disclosures : page === ExternalPages.Disclosures;

  const dispatch = useDispatch();

  const getInformationDisclosures = useCallback(async () => {
    const section = sections.find(s => s.SectionName === informationSectionName);
    const { Result, Success, } = await QdServiceApi.loadQDApplicationDisclosures(applicationGuid, section.Id, coBorrowerGuid);

    if (!Success) return false;

    const { ConcatenatedDisclosures, PdfDisclosures, } = Result;
    const hasInformationDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
    return hasInformationDisclosures;
  }, [applicationGuid, coBorrowerGuid, informationSectionName, sections]);

  const getMainDisclosures = useCallback(async () => {
    const section = sections?.find(s => s.SectionName === disclosuresSectionName);
    const { Result, Success, } = await QdServiceApi.loadQDApplicationDisclosures(applicationGuid, section.Id, coBorrowerGuid);

    if (!Success) return false;

    const { ConcatenatedDisclosures, PdfDisclosures, } = Result;
    const hasMainDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
    return hasMainDisclosures;
  }, [applicationGuid, coBorrowerGuid, disclosuresSectionName, sections]);

  useEffect(() => {
    const loadData = async () => {
      dispatch(setLoader(true));

      // Information
      const infoSection = sections.find(s => s.SectionName === informationSectionName);
      const subSectionCode = isCoBorrower
        ? ValueConstants.CoBorrowerInformationDisclosuresSubSection
        : ValueConstants.InformationDisclosureSubSection;
      const infoDisclosuresSubSection = infoSection?.SubSections.find(ss => ss.Code === subSectionCode);
      const isInformationDisclosureSectionVisible = infoDisclosuresSubSection?.DisplayOption === DisplayOptions.Always;

      // Disclosures
      const isDisclosuresPageVisible = isSectionVisibleBySequence(sections, page);

      let hasInformationDisclosures = null;
      let hasMainDisclosures = null;

      if (!isInformationDisclosuresFetched && isInformationDisclosureSectionVisible) {
        hasInformationDisclosures = await getInformationDisclosures();
      }

      if (!isMainDisclosuresFetched && isDisclosuresPageVisible) {
        hasMainDisclosures = await getMainDisclosures();
      }

      // Re-render page after saving information and main disclosures into store
      // By default every dispatch re-render the page, so batch function help us to re-render page when all actions were dispatched.
      batch(() => {
        if (!isNullOrUndefined(hasInformationDisclosures)) {
          dispatch(setInformationDisclosures({ hasDisclosures: hasInformationDisclosures, isFetched: true, }));
        }

        if (!isNullOrUndefined(hasMainDisclosures)) {
          dispatch(setMainDisclosures({ hasDisclosures: hasMainDisclosures, isFetched: true, }));
        }
      });

      dispatch(setLoader(false));
    };

    if (!isInformationDisclosuresFetched || !isMainDisclosuresFetched) {
      loadData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDisclosureCustomComponent = (party: Borrower_, isLastParty: boolean) => {
    return (
      <p key={`${party.Id}`} className={isLastParty ? 'mb-2' : ''}>
        <span>{`${getFullName(party)} - `}</span>
        <FontAwesomeIcon icon={IconsRegular.faThumbsUp} />
      </p>
    );
  };

  const disclosuresData = useMemo(() => {
    if (!informationDisclosures && !mainDisclosures) {
      return {};
    }

    const { hasDisclosures: hasInformationDisclosures, } = informationDisclosures;
    const { hasDisclosures: hasMainDisclosures, } = mainDisclosures;

    const hasDisclosures = isMainDisclosurePage ? hasMainDisclosures : hasInformationDisclosures;

    const currentParties = hasDisclosures ? [primaryBorrower] : [];

    if (!isCoBorrower && isMainDisclosurePage && hasDisclosures) {
      if (holder?.BorrowerB?.Business?.FullName) {
        currentParties.push(holder.BorrowerB.Business);
      }

      const addNowGuarantors = holder?.Guarantor?.Guarantors?.filter((g: Borrower_) => g?.CoBorrowerAddType === CoBorrowerAddType.AddNow);
      currentParties.push(...addNowGuarantors);
    }

    const data = {};

    currentParties.forEach((party: Borrower_, index: number) => {
      const { Id, FullName, GUID, } = party;

      const customComponent = getDisclosureCustomComponent(party, index === currentParties.length - 1);
      data[`${Id}`] = {
        dataUI: `${FullName}-${GUID}`,
        customComponent,
      };
    });

    return data;
  }, [primaryBorrower, holder, informationDisclosures, isCoBorrower, isMainDisclosurePage, mainDisclosures]);

  const rows: CustomRow_[] = useMemo(() => {
    return [
      {
        id: isMainDisclosurePage ? 'review-disclosures-section' : 'review-information-disclosures-section',
        title: 'Disclosures',
        pageFields: {},
        link: { page, text: isMainDisclosurePage ? 'Go to disclosures step to edit' : 'Go to information step to edit', },
        data: { ...disclosuresData, },
      }
    ];
  }, [isMainDisclosurePage, page, disclosuresData]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(Disclosures);
