import axios from 'axios';
import _ from 'lodash';
import BigInt from 'bigint-polyfill';
import { Toast } from '@jkhy/vsg-design-system';
import BigIntJSON from 'bigint-json-native';
import userManager from '../../utils/UserManager';
import ResponseBase_ from '../models/ResponseBase';
import Messages from '../../utils/Messages';
import { getToken, getModel, removeUser } from '../../utils/LocalStorageManager';

export default class BaseAPI {
  static BASE_URL = window.BASE_URL;

  static getBasicHeaders() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      InstanceId: window.INSTANCE_ID,
    };
  }

  static getRequestHeaders() {
    const token = getToken();

    return {
      ...BaseAPI.getBasicHeaders(),
      Authorization: `Bearer ${token}`,
    };
  }

  static performAjax<T>(pars): Promise<ResponseBase_<T>> {
    return axios({
      ...pars,
      headers: BaseAPI.getRequestHeaders(),
      transformRequest: BaseAPI.transformRequest,
      transformResponse: [BaseAPI.transformResponse],
    })
      .then(({ data, }) => {
        if (pars?.cacheLt) {
          sessionStorage.setItem(`LtItems-${pars.data.LtName}`, JSON.stringify(data?.Result));
        }
        if (pars?.cacheLtDefault) {
          sessionStorage.setItem(`LtItemsDefault-${pars.data.LtNames.join('-')}`, JSON.stringify(data.Result));
        }
        if (pars?.cacheEnumName) {
          sessionStorage.setItem(`EnumItems-${pars.cacheEnumName}`, JSON.stringify(data.Result));
        }
        if (pars?.cacheName) {
          sessionStorage.setItem(`${pars.cacheName}`, JSON.stringify(data.Result));
        }
        return data as ResponseBase_<T>;
      })
      .catch(async ex => {
        switch (ex?.response?.status) {
          case 400: // BadRequest (400); Custom Exception handling
            Toast.warning(((_.first(ex.response.data.Exceptions) as any) || {}).message);
            break;
          case 401:
            removeUser();
            await BaseAPI.signinRedirect();
            break;
          case 403:
            /* Unauthorized access is handled on holder inquire
          Toast.warning((_.first(ex.response.data.Exceptions) as any || {}).message);
          setTimeout(() => {
            removeUser();
            BaseAPI.signinRedirect();
          }, 5000); */
            break;
          case 500: {
            const exceptions = ex?.response?.data?.Exceptions;
            if (exceptions?.length > 0) {
              const [exception] = exceptions;
              Toast.danger(Messages.GENERAL_ERROR, exception);
            }

            break;
          }
          default:
            break;
        }

        return ex?.response?.data;
      });
  }

  static transformResponse = (data: string): any => {
    if (!data || data.length === 0) {
      return data;
    }

    const reviver = (key, value) => {
      if (typeof value === 'object' && value && value.c && value.e && value.s && value.toFixed) {
        return new BigInt(value.toFixed()); // Polyfill a?
      }
      return value;
    };

    return BigIntJSON.parse(data, reviver);
  };

  static transformRequest = (data: any): string => {
    const replacer = (key, value) => {
      if (typeof value === 'bigint') {
        return value.toString();
      }

      if (typeof value === 'string') {
        return _.escape(value);
      }
      return value;
    };
    return BigIntJSON.stringify(data, replacer);
  };

  static getParams = () => {
    const model = getModel() || '';
    return { InstanceId: window.INSTANCE_ID, QDModel: model, };

    // This functionality is commented, because we added new query parameter "context",
    // which is used to track whether application was started by current request application in CP

    // (window.location.search !== null && window.location.search !== ''
    //   ? {
    //     InstanceId: window.INSTANCE_ID,
    //     QDModel: model,
    //     InvitationEmail: window.location.search.split('=')[1],
    //   }
    //   : { InstanceId: window.INSTANCE_ID, QDModel: model, });
  };

  static signinRedirect = () => {
    const pars = BaseAPI.getParams();
    return userManager.signinRedirect({
      extraQueryParams: { prompt: 'login', ...pars, },
    });
  };
}
