import React from 'react';
import {
  Row, Col, Container, Button, FontAwesomeIcon, IconsSolid
} from '@jkhy/vsg-design-system';
import './PageMissing.css';

type PageMissingProps = {
  statusCode?: string;
  errMsg?: string;
};

const PageMissing = (props: PageMissingProps) => {
  const { statusCode, errMsg, } = props;
  // eslint-disable-next-line prefer-const
  let faBanIcon = 'faBan';

  return (
    <Container dataUI="page-missing-container-element">
      <Row>
        <Col lg={{ size: 9, offset: 3, }}>
          <div className="page-missing-container">
            <div className="page-missing-circle mb-1">
              <FontAwesomeIcon icon={IconsSolid[faBanIcon]} />
            </div>
            <h1 className="mb-2">{`${statusCode || '404'} Error`}</h1>
            <p className="mb-2">{errMsg || 'The page you are looking for was not found.'}</p>
            <Button dataUI="page-missing-back-to-portal-button" onClick={() => window.location.replace(`${window.CUSTOMER_PORTAL_URL}/renewals`)}>
              Back to Portal
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

PageMissing.defaultProps = {
  statusCode: '',
  errMsg: '',
};

export default PageMissing;
