import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings_ from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { ValidationRegexConstants, ReferenceType } from '../../../utils/Enums';
import InputMasksConstants from '../../../utils/InputMasksConstants';
import Reference_ from '../../../data/models/Reference';

export default class ReferenceSettings extends PageSettings_<QdApplicationHolder_, Reference_, any> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, Reference_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'ReferenceType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-reference-type-field',
          ObjectProperty: 'ReferenceType',
          ObjectPropertyStr: 'ReferenceTypeEnumStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'radio',
            options: [
              { value: ReferenceType.Accountant, label: 'Accountant', },
              { value: ReferenceType.Attorney, label: 'Attorney', },
              { value: ReferenceType.InsuranceAgent, label: 'Insurance Agent', }
            ],
          },
          IsValid: (holder, app, pf) => {
            if (pf.Required) {
              return pf.Required && app.ReferenceType !== null;
            }
            return true;
          },
        });
      case 'RefName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-name-field',
          ObjectProperty: 'Name',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 100,
          },
        });
      case 'Email':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-email-field',
          ObjectProperty: 'Email',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            icon: IconsSolid.faEnvelope,
            inputType: 'email',
            maxLength: 100,
          },
        });
      case 'City':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-city-field',
          ObjectProperty: 'City',
          ObjectPropertyType: 'string',
          Component: {
            type: 'input',
            maxLength: 50,
          },
        });
      case 'State':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-state-field',
          ObjectProperty: 'State',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              ListName: 'States',
              ListType: 'LtItems',
            },
          },
        });
      case 'ZIPCode':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-zip-code-field',
          ObjectProperty: 'Zip',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.ZIP_CODE,
            regexStr: ValidationRegexConstants.ZipCode,
            errorMessage: `${pageField?.Label || 'ZIP Code'} is in incorrect format`,
            inputType: 'zipCode',
            icon: IconsSolid.faMapPin,
          },
        });
      case 'PhoneNumber':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'business-reference-phone-number-field',
          ObjectProperty: 'Phone',
          ObjectPropertyType: 'string',
          Component: {
            type: 'maskedinput',
            inputMask: InputMasksConstants.PHONE,
            regexStr: ValidationRegexConstants.Phone,
            errorMessage: `${pageField?.Label || 'Telephone #'} is incorrect`,
            inputType: 'phone',
            icon: IconsSolid.faPhone,
          },
        });
      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
