import { Action as Action_ } from 'redux';

export interface SubmitButtonState {
  submitToReview: boolean;
  show: boolean;
  disabled?: boolean;
}

export const updateButton = (state: SubmitButtonState): UpdateAction => {
  return {
    state,
    type: 'UpdateSubmitButton',
  };
};

export interface UpdateAction extends Action_<'UpdateSubmitButton'> {
  state: SubmitButtonState;
}
