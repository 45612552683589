import { IconsSolid } from '@jkhy/vsg-design-system';
import { partition } from 'lodash';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageField_ from '../../../data/models/PageField';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import PurposeProduct_ from '../../../data/models/PurposeProduct';
import Message from '../../../utils/Messages';
import { currencyFormat } from '../../../utils/Helper';
import { ValueConstants } from '../../../utils/Enums';
import InputMasksConstants from '../../../utils/InputMasksConstants';

type PurposeProductAdditionalData = {
  purposePickList: string;
  currentYear: number;
  maximumLoanAmount: number;
  minimumLoanAmount: number;
};

export const PURPOSE_OF_LOAN_FIELD_SELECTOR = 'purpose-product-purpose-field';
export const PRODUCT_TYPE_FIELD_SELECTOR = 'purpose-product-product-type-field';
export const TERM_LENGTH_FIELD_SELECTOR = 'purpose-product-term-length-field';
export const AMOUNT_REQUESTED_FIELD_SELECTOR = 'purpose-product-amount-requested-field';
export const SPECIFIC_REASON_FIELD_SELECTOR = 'purpose-product-specific-reason-field';
export const DATE_BUSINESS_STARTED_FIELD_SELECTOR = 'purpose-product-time-in-business-field';

export default class PurposeProductSettings extends PageSettings<QdApplicationHolder_, PurposeProduct_, PurposeProductAdditionalData> {
  protected pageFieldsMapper(
    pageField: PageField_,
    additionalData: PurposeProductAdditionalData
  ): PageFieldExtended_<QdApplicationHolder_, PurposeProduct_> {
    if (!pageField) {
      return null;
    }

    const isTermLengthHidden = (appHolder: QdApplicationHolder_, subHolder: any) => {
      return subHolder?.ProductTypeSecondCode !== 'TL';
    };

    const {
      purposePickList, currentYear, maximumLoanAmount, minimumLoanAmount,
    } = additionalData;

    switch (pageField.FieldName) {
      case 'StatementOfIntent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'purpose-product-statement-of-intent-field',
          ObjectIndex: '0',
          ObjectType: 'Facilities',
          ObjectProperty: 'StatementOfIntentCode',
          ObjectPropertyStr: 'StatementOfIntentCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'StatementOfIntent',
              ListType: 'LtItems',
            },
          },
        });
      case 'Purpose':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: PURPOSE_OF_LOAN_FIELD_SELECTOR,
          ObjectIndex: '0',
          ObjectType: 'Facilities',
          ObjectProperty: 'PurposeCode',
          ObjectPropertyStr: 'PurposeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: purposePickList,
              ListType: 'LtItems',
            },
          },
        });
      case 'ProductType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'purpose-product-product-type-field',
          ObjectProperty: 'ProductTypeCode',
          ObjectPropertyStr: 'ProductTypeCodeStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'QDProduct',
              ListType: 'LtItems',
            },
          },
        });
      case 'AmountRequested':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: AMOUNT_REQUESTED_FIELD_SELECTOR,
          ObjectIndex: '0',
          ObjectType: 'Facilities',
          ObjectProperty: 'AmountRequested',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
          IsValid: (qdHolder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            let value = subHolder[pageF.ObjectProperty];
            if (pageF.ObjectType) {
              value = pageF.ObjectIndex
                ? subHolder[pageF.ObjectType][pageF.ObjectIndex][pageF.ObjectProperty]
                : subHolder[pageF.ObjectType][pageF.ObjectProperty];
            }

            const pf = pageF;
            if (!!value === false) {
              if (pf.Required) {
                pf.ValidationMessage = Message.REQUIRED_FIELD;
                return false;
              }
              return true;
            }

            value = value.toString().replace(/,/g, '');
            if (value < (minimumLoanAmount ?? 0)) {
              if (minimumLoanAmount) {
                pf.ValidationMessage = `The loan cannot be less than ${currencyFormat(minimumLoanAmount)}`;
              } else {
                pf.ValidationMessage = 'The loan cannot be less than $0';
              }

              return false;
            }

            if (value > (maximumLoanAmount ?? ValueConstants.MaxMoney)) {
              if (maximumLoanAmount) {
                pf.ValidationMessage = `The loan cannot be greater than ${currencyFormat(maximumLoanAmount)}`;
              } else {
                pf.ValidationMessage = `The loan cannot be greater than ${currencyFormat(ValueConstants.MaxMoney)}`;
              }

              return false;
            }

            return !!value;
          },
        });
      case 'RequestedTermLength':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: TERM_LENGTH_FIELD_SELECTOR,
          ObjectProperty: 'TermLengthCode',
          ObjectPropertyStr: 'TermLengthCodeStr',
          ObjectPropertyType: 'string',
          IsHiddenCalculated: isTermLengthHidden,
          Component: {
            type: 'select',
            options: {
              initialData: [],
              ListName: 'QDTermLength',
              ListType: 'LtItems',
            },
          },
        });
      case 'SpecificReason':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'purpose-product-specific-reason-field',
          ObjectIndex: '0',
          ObjectType: 'Facilities',
          ObjectProperty: 'SpecificReason',
          ObjectPropertyType: 'string',
          Component: {
            type: 'textarea',
            maxLength: 1950,
            rows: 4,
          },
        });
      case 'TimeInBusiness':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: DATE_BUSINESS_STARTED_FIELD_SELECTOR,
          IsMultipleForm: true,
          Components: [
            {
              ObjectProperty: 'DateFormedMonth',
              Label: 'Month',
              type: 'select',
              options: {
                stateLess: true,
                initialData: [
                  { value: 1, label: 'January', },
                  { value: 2, label: 'February', },
                  { value: 3, label: 'March', },
                  { value: 4, label: 'April', },
                  { value: 5, label: 'May', },
                  { value: 6, label: 'June', },
                  { value: 7, label: 'July', },
                  { value: 8, label: 'August', },
                  { value: 9, label: 'September', },
                  { value: 10, label: 'October', },
                  { value: 11, label: 'November', },
                  { value: 12, label: 'December', }
                ],
              },
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                if (!pageF.Required || pageF.IsHidden) return true;

                const result = subHolder.DateFormedMonth;
                const comp = component;
                comp.ValidationMessage = !result ? Message.REQUIRED_FIELD : '';

                return !!result;
              },
            },
            {
              ObjectProperty: 'DateFormedYear',
              Label: 'Year',
              type: 'maskedinput',
              inputType: 'number',
              minValue: ValueConstants.MinBusinessFormedYear,
              maxValue: currentYear,
              placeholder: 'YYYY',
              inputMask: InputMasksConstants.YEAR,
              ValidationMessage: '',
              IsComponentValid: (appHolder, subHolder, pageF, component) => {
                if (pageField.IsHidden) return true;

                let value = subHolder.DateFormedYear;
                if (value) {
                  value = (value.toString() || '').replace(/\D+/g, '');
                }

                const comp = component;
                comp.ValidationMessage = '';

                if (!!value === false) {
                  if (pageF.Required) {
                    comp.ValidationMessage = Message.REQUIRED_FIELD;
                    return false;
                  }
                  return true;
                }

                if (value < ValueConstants.MinBusinessFormedYear) {
                  comp.ValidationMessage = `The year cannot be less than ${ValueConstants.MinBusinessFormedYear}`;
                  return false;
                }
                if (value > appHolder.CurrentYear) {
                  comp.ValidationMessage = `The year cannot be greater than the current one (${appHolder.CurrentYear})`;
                  return false;
                }

                return !!value;
              },
            }
          ],
          Label: pageField.Label,
          Column: 1,
          Colspan: 3,
          Row: 5,
          IsValid: (qdHolder, subHolder, pageF) => {
            if (pageF.IsHidden) return true;

            const [invalidPageFieldComponents] = partition(pageF.Components, c => !c.IsComponentValid(qdHolder, subHolder, pageF, c));
            const result = invalidPageFieldComponents.length === 0;
            if (!result) {
              invalidPageFieldComponents.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = true;
                return currentComponent;
              });
            } else {
              pageF.Components.map(c => {
                const currentComponent = c;
                currentComponent.IsInvalidResult = false;
                return currentComponent;
              });
            }

            return result;
          },
          ObjectPropertyType: 'string',
        });
      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
