import { useState } from 'react';
import { isNullOrUndefined } from '../utils/Helper';

type FieldData<T> = {
  value: T;
  isValid: boolean;
};

export type CustomFormData<T> = {
  [fieldName: string]: FieldData<T>;
};

type CustomValidateHandler<T> = (field: FieldData<T>) => boolean;

const defaultValidationFunction = <T>(field: FieldData<T>) => {
  return !isNullOrUndefined(field.value) && (typeof field.value === 'string' ? field.value !== '' : true);
};

export const useFormData = <T>(validateHandler: CustomValidateHandler<T> = defaultValidationFunction) => {
  const [formData, setFormData] = useState<CustomFormData<T>>(null);

  const onFormValuesChange = (fieldName: string, newFieldValue: T) => {
    setFormData(prevState => ({ ...prevState, [fieldName]: { ...prevState[fieldName], value: newFieldValue, }, }));
  };

  const validate = () => {
    let isValid = true;
    const newFormData = { ...formData, };

    Object.entries(formData).forEach(([fieldName, fieldData]) => {
      const isCurrentQuestionValid = validateHandler(fieldData);
      newFormData[fieldName].isValid = isCurrentQuestionValid;
      isValid = isValid && isCurrentQuestionValid;
    });

    setFormData(newFormData);

    return isValid;
  };

  return {
    formData,
    setFormData,
    onFormValuesChange,
    validate,
  };
};
