import React, { FC as FC_, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { currencyFormat, isGoogleUnavailable, yesNoOrEmptyAnswer } from '../../../../utils/Helper';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { AppState as AppState_ } from '../../../../redux/AppState';
import Collateral_ from '../../../../data/models/Collateral';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';

type CollateralsProps = ReviewApplicationSectionProps_;

const Collaterals: FC_<CollateralsProps> = ({
  page, sectionTitle, expandState, setExpandState,
}: CollateralsProps) => {
  const { modelState, holder, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const collateralsPageFields = modelState?.ModelHolder?.ApplicationPageField?.Collateral;
  const collaterals = holder?.Collateral?.Collaterals;

  const isCollateralLocationVisible = (collateral: Collateral_) => collateral?.CategoryCodeSecondCode?.toUpperCase() === 'P';

  const rows: CustomRow_[] = useMemo(() => {
    return collaterals?.map((collateral: Collateral_) => {
      const collateralId = collateral.Id;

      const isLocationVisible = isCollateralLocationVisible(collateral);
      const isAddressVisible = !collateralsPageFields?.CAddress?.IsHidden && isLocationVisible;

      return {
        id: `review-collateral-${collateralId}-collapse`,
        title: collateral.TypeCodeStr,
        pageFields: collateralsPageFields,
        link: { page, text: 'Go to collateral step to edit', },
        data: {
          Category: {
            dataUI: `collateral-category-${collateralId}-field`,
            value: collateral.CategoryCodeStr,
          },
          Type: {
            dataUI: `collateral-type-${collateralId}-field`,
            value: collateral.TypeCodeStr,
          },
          CAddress: {
            dataUI: `collateral-address-autocomplete-${collateralId}-field`,
            value: collateral.Address,
            isVisible: isAddressVisible && !isGoogleUnavailable(),
          },
          CStreetAddress: {
            dataUI: `collateral-address-street-${collateralId}-field`,
            customLabel: 'Street Address',
            value: collateral.Street,
            isVisible: isAddressVisible,
          },
          CCity: {
            dataUI: `collateral-address-city-${collateralId}-field`,
            customLabel: 'City',
            value: collateral.City,
            isVisible: isAddressVisible,
          },
          CState: {
            dataUI: `collateral-address-state-${collateralId}-field`,
            customLabel: 'State',
            value: collateral.State,
            isVisible: isAddressVisible,
          },
          CZipCode: {
            dataUI: `collateral-address-zip-${collateralId}-field`,
            customLabel: 'Zip',
            value: collateral.Zip,
            isVisible: isAddressVisible,
          },
          OwnerOccupiedQuestion: {
            dataUI: `collateral-owner-occupied-question-${collateralId}-field`,
            value: yesNoOrEmptyAnswer(collateral.OwnerOccupiedFlag),
            isVisible: !collateralsPageFields?.OwnerOccupiedQuestion?.IsHidden && isLocationVisible,
          },
          Description: {
            dataUI: `collateral-description-${collateralId}-field`,
            value: collateral.Description,
          },
          CurrentValue: {
            dataUI: `collateral-current-value-${collateralId}-field`,
            value: currencyFormat(collateral.MarketValue),
          },
          CurrentLoan: {
            dataUI: `collateral-current-loan-${collateralId}-field`,
            value: currencyFormat(collateral.CurrentLoanAmount),
          },
          Owner: {
            dataUI: `collateral-owner-${collateralId}-field`,
            value: collateral.OwnerStr,
          },
        },
      };
    });
  }, [collaterals, collateralsPageFields, page]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(Collaterals);
