/* eslint-disable no-undef */
import React, {
  FC as FC_, useState, useMemo, useEffect
} from 'react';
import { useSelector } from 'react-redux';
import { AppState as AppState_ } from '../../../redux/AppState';
import Page from '../Page/Page';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import IncomeExpensesSettings from './IncomeExpensesSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import IncomeExpenses_ from '../../../data/models/IncomeExpenses';

import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ExternalPages, ExternalPageSectionName, IdentityCheckStatus } from '../../../utils/Enums';
import PageField from '../../../data/models/PageField';
import PartyDBA_ from '../../../data/models/PartyDBA';
import { QDApplicationTypeAnswer } from '../../../data/models/Information';
import { booleanStringComparison, deepCopy, isNullOrUndefined } from '../../../utils/Helper';
import ListAPI from '../../../data/api/ListApi';

export interface IncomeExpensesProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QdApplicationHolder_, IncomeExpenses_>[],
    holder: QdApplicationHolder_,
    section: IncomeExpenses_,
    sequence: string,
    fromGuarantor: boolean
  ) => void;
}

const IncomeExpenses: FC_<IncomeExpensesProps> = (props: IncomeExpensesProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));

  const [incomeExpenses, setIncomeExpenses] = useState<IncomeExpenses_>(deepCopy(holder?.IncomeExpences));
  const differentDBAId = BigInt(0);
  const [partyDBAs, setPartyDBAs] = useState<PartyDBA_[]>([]);
  const [partyDBAsLoading, setPartyDBAsLoading] = useState(true);
  const section = modelState?.ModelHolder?.ApplicationSections?.find(s => s.SectionName === ExternalPageSectionName.Employment);
  const [subSection] = section?.SubSections;
  const pageFieldHolder = modelState?.ModelHolder?.ApplicationPageField;
  const incomeExpensesPF = pageFieldHolder?.IncomeandExpenses;
  const incomeAndExpensesPageFields = useMemo(() => {
    const { SortOrder: sortOrder, } = incomeExpensesPF?.OtherMonthlyPayments;

    return {
      ...incomeExpensesPF,
      OperatesAsDBA: {
        ...new PageField(), FieldName: 'OperatesAsDBA', Required: true, SortOrder: (sortOrder + 1),
      },
      ApplicableDBA: {
        ...new PageField(), FieldName: 'ApplicableDBA', Required: true, SortOrder: (sortOrder + 2),
      },
      PersonalDBA: {
        ...new PageField(), FieldName: 'PersonalDBA', Required: true, SortOrder: (sortOrder + 3),
      },
    };
  }, [incomeExpensesPF]);

  useEffect(() => {
    const {
      Information: { ApplicationTypeAnswer, },
      BorrowerP: { Personal: { IdParty, IdentityCheck, PartyMatchConfirmedByUser, }, },
    } = holder;

    const fetchPartyDBAs = async () => {
      const { Result, Success, } = await ListAPI.getPartyDBAs(IdParty);
      if (Success) {
        // Include 'different DBA' option; Place it at end/bottom
        const sorted = [...Result, { Id: differentDBAId, } as PartyDBA_].sort((a, b) => {
          if (a.Id < b.Id) return 1;
          return (a.Id > b.Id) ? -1 : 0;
        });
        setPartyDBAs(sorted);
      }
      setPartyDBAsLoading(false);
    };

    const shouldInquireDBAs = partyDBAs.length === 0
      && ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString()
      && IdParty // LV party match successful
      && PartyMatchConfirmedByUser
      && IdentityCheck === IdentityCheckStatus.Validated; // Identity Verification successful

    if (shouldInquireDBAs) {
      fetchPartyDBAs();
    } else {
      setPartyDBAsLoading(false);
    }
  }, [differentDBAId, holder, partyDBAs.length]);

  useEffect(() => {
    const { Employment: { IdPartyDBA, PersonalDBA, }, } = incomeExpenses;
    const { Information: { ApplicationTypeAnswer, }, } = holder;

    if (ApplicationTypeAnswer?.toString() !== QDApplicationTypeAnswer.Individual.toString()) {
      incomeExpenses.Employment.OperatesAsDBA = null;
      incomeExpenses.Employment.IdPartyDBA = null;
      incomeExpenses.Employment.PersonalDBA = null;
    } else {
      const hasDifferentDBA = isNullOrUndefined(IdPartyDBA) && PersonalDBA;
      if (hasDifferentDBA) incomeExpenses.Employment.IdPartyDBA = differentDBAId;
    }

    setIncomeExpenses(incomeExpenses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageSettings = useMemo(() => {
    return new IncomeExpensesSettings([incomeAndExpensesPageFields], [subSection], { partyDBAs, });
  }, [incomeAndExpensesPageFields, subSection, partyDBAs]);


  const updateIncomeExpenses = (pageField: PageFieldExtended_<QdApplicationHolder_, IncomeExpenses_>, value: string, e, text: string) => {
    const { ObjectType, ObjectProperty, ObjectPropertyStr, } = pageField;

    if (ObjectType) {
      incomeExpenses[ObjectType][ObjectProperty] = value;
      if (ObjectPropertyStr) incomeExpenses[ObjectType][ObjectPropertyStr] = text;
    } else {
      incomeExpenses[ObjectProperty] = value;
      if (ObjectPropertyStr) incomeExpenses[ObjectPropertyStr] = text;
    }

    if (ObjectProperty === 'IdPartyDBA' && ObjectPropertyStr) {
      // In case 'different' selected, reset party DBA string representation
      if (`${value}` === `${differentDBAId}`) {
        incomeExpenses[ObjectType][ObjectPropertyStr] = '';
      } else {
        const dba = partyDBAs.find(item => `${item.Id}` === `${value}`);
        incomeExpenses[ObjectType][ObjectPropertyStr] = dba?.PersonalDBA ?? dba?.BusinessDBA;
      }
    }

    setIncomeExpenses({ ...incomeExpenses, });
  };

  const onSubmit = async (invalidPageFields: PageFieldExtended_<QdApplicationHolder_, IncomeExpenses_>[]) => {
    if (invalidPageFields && invalidPageFields.length > 0) {
      return;
    }

    if (!incomeExpenses?.Employment.IdQDParty) {
      const {
        BorrowerP: {
          Personal: { Id, },
        },
      } = holder;
      incomeExpenses.Employment.IdQDParty = Id;
    }

    // Reset DBA values
    const { Employment: { OperatesAsDBA, IdPartyDBA, PersonalDBA, }, } = incomeExpenses;
    if (booleanStringComparison(OperatesAsDBA)) {
      if (`${IdPartyDBA}` === `${differentDBAId}`) incomeExpenses.Employment.IdPartyDBA = null;
    } else if (IdPartyDBA || PersonalDBA) {
      incomeExpenses.Employment.IdPartyDBA = null;
      incomeExpenses.Employment.PersonalDBA = null;
    }
    await props.onSubmit(invalidPageFields, holder, incomeExpenses, ExternalPages.Employment, false);
  };

  return (
    <>
      {!loading && !partyDBAsLoading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateIncomeExpenses}
          holder={holder}
          subHolder={incomeExpenses}
          onSubmit={onSubmit}
          formIdentifier={`form-${ExternalPages.Employment}`}
        />
      )}
    </>
  );
};

export default IncomeExpenses;
