import { Action as Action_ } from 'redux';

export interface AppSettingsState {
  isApplicationStop: boolean;
}

export const updateStopApplicationCondition = (isApplicationStop: boolean): UpdateStopApplicationAction => {
  return {
    isApplicationStop,
    type: 'UpdateStopApplication',
  };
};

interface UpdateStopApplicationAction extends Action_<'UpdateStopApplication'> {
  isApplicationStop: boolean;
}

export type AppSettingsActions = UpdateStopApplicationAction;
