import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Page from '../../../Page/Page';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { CoBorrowerBusinessOtherInformationSettings } from '../../AddNow/Information/OtherInformationSettings';
import { GuarantorPageSectionName_, GuarantorPages } from '../../../../../utils/Enums';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationBusiness as QDCoBorrowerApplicationBusiness_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import Borrower_ from '../../../../../data/models/Borrower';

type CoBorrowerBusinessOtherInformationProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationBusiness_,
    sequence: string
  ) => void;
};

const CoBorrowerBusinessOtherInformation = (props: CoBorrowerBusinessOtherInformationProps_) => {
  const {
    modelState, sectionsState, loading, holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const [businessCoBorrowerOtherInformation, setBusinessCoBorrowerOtherInformation] = useState({ ...holder?.Business?.CoBorrower, });

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const section = sectionsState?.VisibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.BusinessOtherInformation);

  const updateBusinessOtherInformation = (
    pageField: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>,
    value: string,
    e,
    text: string
  ) => {
    const { ObjectProperty, ObjectPropertyStr, } = pageField;

    const changedBusinessCoBorrowerOtherInformationData = { ...businessCoBorrowerOtherInformation, };

    changedBusinessCoBorrowerOtherInformationData[ObjectProperty] = value;
    if (ObjectPropertyStr) changedBusinessCoBorrowerOtherInformationData[ObjectPropertyStr] = text;

    setBusinessCoBorrowerOtherInformation(changedBusinessCoBorrowerOtherInformationData);
  };

  const pageSettings = useMemo(() => {
    return new CoBorrowerBusinessOtherInformationSettings([pageFieldHolder?.BusinessInformation?.OtherInformation], section?.SubSections);
  }, [pageFieldHolder, section]);

  const onSubmit = (invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, Borrower_>[]) => {
    const businessInformation = {
      ...holder.Business,
      CoBorrower: businessCoBorrowerOtherInformation,
    };

    props.onSubmit(invalidPageFields, holder, businessInformation, GuarantorPages.BusinessOtherInformation);
  };

  return (
    <>
      {!loading && (
        <Page
          pageSettings={pageSettings}
          onChange={updateBusinessOtherInformation}
          holder={holder}
          subHolder={businessCoBorrowerOtherInformation}
          onSubmit={onSubmit}
          formIdentifier={`form-${GuarantorPages.BusinessOtherInformation}`}
        />
      )}
    </>
  );
};

export default CoBorrowerBusinessOtherInformation;
