import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PageField_ from '../../../data/models/PageField';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import QdDebt_ from '../../../data/models/QDDebt';

export default class DebtsSettings extends PageSettings<QdApplicationHolder_, QdDebt_, boolean> {
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, QdDebt_> {
    switch (pageField.FieldName) {
      case 'Creditor':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-creditor-field',
          ObjectProperty: 'Creditor',
          Component: {
            type: 'input',
          },
        });
      case 'LoanType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-type-field',
          ObjectProperty: 'DebtType',
          ObjectPropertyStr: 'DebtTypeEnumStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'radio',
            options: [
              { value: 0, label: 'Credit Card', },
              { value: 1, label: 'Mortgage', },
              { value: 2, label: 'Revolving', },
              { value: 3, label: 'Term Loan', }
            ],
          },
        });
      case 'CurrentBalance':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-current-balance-field',
          ObjectProperty: 'CurrentBalance',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
        });
      case 'MonthlyPayment':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-montly-payment-field',
          ObjectProperty: 'MonthlyPayment',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
        });
      case 'InterestRate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-interest-rate-field',
          ObjectProperty: 'InterestRate',
          ObjectPropertyType: 'number',
          Component: {
            type: 'maskedinput',
            inputType: 'percent',
            icon: IconsSolid.faPercent,
          },
        });
      case 'MaturityDate':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-maturity-date-field',
          ObjectProperty: 'MaturityDate',
          ObjectPropertyType: 'string',
          Component: {
            type: 'datepicker',
          },
        });
      case 'Collateral':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'debt-collateral-field',
          ObjectProperty: 'CollateralDescription',
          ObjectPropertyType: 'string',
          Component: {
            type: 'textarea',
            maxLength: 1950,
            rows: 2,
          },
        });

      default:
        return this.PageFields.find(x => x.FieldName === pageField.FieldName) || super.pageFieldsMapper(pageField);
    }
  }
}
