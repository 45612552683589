import { IconsSolid } from '@jkhy/vsg-design-system';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PageField_ from '../../../data/models/PageField';

import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import QdDeposit_ from '../../../data/models/QDDeposit';

export default class DepositSettings extends PageSettings<QdApplicationHolder_, QdDeposit_, boolean> {
  // eslint-disable-next-line class-methods-use-this
  protected pageFieldsMapper(pageField: PageField_): PageFieldExtended_<QdApplicationHolder_, QdDeposit_> {
    switch (pageField.FieldName) {
      case 'DepositType':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'deposit-deposit-type-field',
          ObjectProperty: 'DepositType',
          ObjectPropertyStr: 'DepositTypeEnumStr',
          ObjectPropertyType: 'string',
          Component: {
            type: 'radio',
            options: [
              { value: 0, label: 'Checking', },
              { value: 2, label: 'Money Market', },
              { value: 3, label: 'Other', },
              { value: 1, label: 'Savings', }
            ],
          },
        });
      case 'CurrentBalance':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'deposit-current-balance-type-field',
          ObjectProperty: 'CurrentBalance',
          Component: {
            type: 'maskedinput',
            inputType: 'number',
            icon: IconsSolid.faDollarSign,
            maxLength: 11,
            maxValue: 999999999,
          },
        });
      case 'BankName':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'deposit-bank-name-field',
          ObjectProperty: 'BankName',
          Component: {
            type: 'input',
          },
          IsValid: (holder, app, pf) => {
            if (pf.Required) {
              return pf.Required && !!app.BankName;
            }
            return true;
          },
        });

      default:
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
    }
  }
}
