import { Action as Action_, ActionCreator as ActionCreator_, Dispatch as Dispatch_ } from 'redux';
import { ThunkAction as ThunkAction_ } from 'redux-thunk';
import ModelAPI from '../../data/api/ModelApi';
import QDServiceApi from '../../data/api/QDServiceApi';
import {
  setPageFieldObj, filterSections, checkIsReviewPageVisible, shouldUseHolderForEval
} from '../../utils/Helper';
import { ExternalPageSectionName, ApplicableFor, ExternalPages } from '../../utils/Enums';
import GeneratorHost, { IGeneratorParameters as GeneratorParameters_ } from '../../utils/GeneratorHost';
import { LoadingAction as LoadingAction_, setLoader } from './Loading';
import { IGotHolder as IGotHolder_, InputHolderParameters as InputHolderParameters_ } from './Holder';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_, RenewalModelHolder as RenewalModelHolder_ } from '../../utils/Types';
import { updateVisibleSections } from './VisibleSections';
import PageField_, { QuickDecisionPageTypes } from '../../data/models/PageField';
import { IGotCoBorrowerHolder as IGotCoBorrowerHolder_ } from './CoBorrowerHolder';
import QDCoBorrowerApplicationHolder_ from '../../data/models/QDCoBorrowerApplicationHolder';
import QdApplicationHolder_ from '../../data/models/QDApplicationHolder';
import { QDApplicationTypeAnswer } from '../../data/models/Information';

export interface IGotModelHolder extends Action_<'GotModelHolder'> {
  modelState: ModelState_<ExternalModelHolder_>;
}

export interface IGotRenewalModelHolder extends Action_<'GotRenewalModelHolder'> {
  renewalModelState: ModelState_<RenewalModelHolder_>;
}

// eslint-disable-next-line func-names
export const modelHolderGenerator = function* (pars: InputHolderParameters_, dispatch: Dispatch_) {
  const resultExternalModel = yield ModelAPI.getApplicationModel(pars.model);
  const sectionsResult = yield ModelAPI.getApplicationSections(resultExternalModel.Code);
  const subSectionsResult = yield ModelAPI.getApplicationSubSections(resultExternalModel.Code);
  let res: ExternalModelHolder_ = {
    Model: resultExternalModel,
    ApplicationSections: [],
    CoBorrowerSections: [],
    SubSections: [],
    PageFields: [],
    ApplicationPageField: null,
    CoBorrowerPageField: null,
    IdentitySection: null,
  };
  const gotModelAction: IGotModelHolder = {
    modelState: {
      ModelHolder: res,
      IsModelHolderFetched: false, // don't set it to True, authorization will happen on Holder inquire
    },
    type: 'GotModelHolder',
  };
  dispatch(gotModelAction);

  const holderResult = pars.partyGUID
    ? yield QDServiceApi.getQDApplicationHolderGuarantorUID(pars.partyGUID, pars.email, pars.model)
    : yield QDServiceApi.getQDApplicationHolder(pars.guid, pars.email, pars.model);

  const isCoBorrower = !!pars.partyGUID;

  const mainAppPageFields = yield ModelAPI.getApplicationPageFields(resultExternalModel.IdPageFieldTemplate, QuickDecisionPageTypes.QuickDecision);

  const coBorrowerAppPageFields = isCoBorrower
    ? yield ModelAPI.getApplicationPageFields(resultExternalModel.IdGuarantorPageFieldTemplate, QuickDecisionPageTypes.QuickDecisionGuarantor)
    : [];

  const reUsedPageFieldGroupsFromMainApp: string[] = [ExternalPageSectionName.Declarations];

  const pageFieldsResult = isCoBorrower
    ? [
      ...coBorrowerAppPageFields,
      ...mainAppPageFields.filter((pageField: PageField_) => reUsedPageFieldGroupsFromMainApp.includes(pageField.GroupName))
    ]
    : mainAppPageFields;

  const sections = sectionsResult.map(section => {
    return {
      ...section,
      SubSections: subSectionsResult.filter(subSection => subSection.IdQDApplicationSection === section.Id),
    };
  });

  //  ApplicationTypeAnswer
  const applicationTypeAnswer = (holderResult as QdApplicationHolder_)?.Information?.ApplicationTypeAnswer ?? QDApplicationTypeAnswer.Business;
  const holderForEval = shouldUseHolderForEval(applicationTypeAnswer, isCoBorrower, pars.partyGUID) ? holderResult : null;
  //  END ApplicationTypeAnswer

  //  Eval first page
  const evalResult = yield QDServiceApi.evaluate(holderResult.IdQDApplication, holderResult.LastVisitedSection, holderForEval, pars.partyGUID);

  if (!evalResult?.ShouldStopApplication) {
    const visibleSections = filterSections('IdQDSectionDefinition', sections, evalResult?.VisibleSections);

    const isReviewed = checkIsReviewPageVisible(visibleSections, ExternalPages.Review);
    dispatch(updateVisibleSections(visibleSections, isReviewed));
  }
  //  END Eval first page
  const identitySection = sections.find(s => s.SectionName === ExternalPageSectionName.IdentityCheck);

  const reUsedSectionsFromMainApp = [
    ExternalPageSectionName.Declarations,
    ExternalPageSectionName.Disclosures,
    ExternalPageSectionName.TermsAndCondition,
    ExternalPageSectionName.Review
  ];
  // Authentication on Holder has passed on server udpate ModelHolder state
  res = {
    ...res,
    ApplicationSections: sections,
    CoBorrowerSections: sections.filter(
      // eslint-disable-next-line max-len
      s => [ApplicableFor.CoAppPreSubmit, ApplicableFor.CoAppPostSubmit, ApplicableFor.CoAppPreSubmitCom].includes(s.ApplicableFor) || reUsedSectionsFromMainApp.includes(s.SectionName)
    ),
    SubSections: subSectionsResult || [],
    PageFields: pageFieldsResult || [],
    ApplicationPageField: setPageFieldObj(mainAppPageFields),
    CoBorrowerPageField: isCoBorrower ? setPageFieldObj(pageFieldsResult) : null,
    IdentitySection: identitySection,
  };

  if (!isCoBorrower) {
    const gotHolderAction: IGotHolder_ = {
      holderState: {
        Holder: holderResult as QdApplicationHolder_,
        IsHolderFetched: true,
      },
      type: 'GotHolder',
    };

    dispatch(gotHolderAction);
  } else {
    const gotCoBorrowerHolderAction: IGotCoBorrowerHolder_ = {
      coBorrowerHolderState: {
        CoBorrowerHolder: holderResult as QDCoBorrowerApplicationHolder_,
        IsCoBorrowerHolderFetched: true,
      },
      type: 'GotCoBorrowerHolder',
    };

    dispatch(gotCoBorrowerHolderAction);
  }

  const gotModelHolderAction: IGotModelHolder = {
    modelState: {
      ModelHolder: res,
      IsModelHolderFetched: true,
    },
    type: 'GotModelHolder',
  };

  dispatch(gotModelHolderAction);
};

export const getModelHolderActionCreator: ActionCreator_<ThunkAction_<Promise<LoadingAction_>, boolean, InputHolderParameters_, LoadingAction_>> = (
  pars: InputHolderParameters_,
  Unauthorized: () => void,
  onFail: () => void
) => {
  return async (dispatch: Dispatch_) => {
    const params: GeneratorParameters_<any> = {
      dispatch,
      onFail: errors => {
        const [err, _] = errors;
        switch (err?.Code) {
          case 403:
            Unauthorized();
            break;
          case 401:
            onFail();
            break; // perform silent login
          default:
        }
      },
    };
    dispatch(setLoader(true));
    await new GeneratorHost(params).run(modelHolderGenerator.bind(null, pars, dispatch));
    return dispatch(setLoader(false));
  };
};

export default {};
