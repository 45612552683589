/* eslint no-useless-escape: "off" */
import React, { FC as FC_ } from 'react';
import { useSelector, connect } from 'react-redux';
import { ThunkDispatch as ThunkDispatch_ } from 'redux-thunk';
import { AnyAction as AnyAction_ } from 'redux';
import { withRouter } from 'react-router-dom';
import { DataPreviewText, Section } from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../redux/AppState';
import getDocumentsActionCreator, { DocumentsState as DocumentsState_, setDocuments } from '../../../redux/actions/Documents';
import { htmlParser } from '../../../utils/Helper';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import { ApplicationStatus as ApplicationStatus_ } from '../../../utils/Enums';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import QDDocumentsResult_ from '../../../data/models/QDDocumentsResult';
import DocTrack_ from '../../../data/models/DocTrack';

export interface DocumentsProps {
  getDocuments: (guid: string) => void;
  setDefaultDocuments: (documentsHolder: QDDocumentsResult_) => void;
}

const Document: FC_<DocumentsProps> = (props: DocumentsProps) => {
  const {
    holder, modelState, loading, documentState,
  } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      modelState: ModelState_<ExternalModelHolder_>;
      loading: boolean;
      documentState: DocumentsState_;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    modelState: state.modelState,
    loading: state.loading,
    documentState: state.documentState,
  }));
  const { getDocuments, setDefaultDocuments, } = props;

  const {
    ModelHolder: {
      Model: { DocumentsSectionHeaderHTML, DocumentsSectionFooterHTML, DocumentsCustomLabel, },
    },
  } = modelState;
  const { ApplicationStatus, } = holder;
  const {
    IsDocumentsFetched,
    IsDocumentsFetching,
    DocumentHolder: { Documents, },
  } = documentState;

  if ([ApplicationStatus_.OnHold, ApplicationStatus_.Accepted].includes(ApplicationStatus)) {
    if (!IsDocumentsFetched && !IsDocumentsFetching) getDocuments(holder.GUIDQDApplication);
  } else {
    const defaultHolder: QDDocumentsResult_ = {
      Documents: [],
      DocumentsUploadPostHTML: '',
      DocumentsUploadPreHTML: '',
    };
    setDefaultDocuments(defaultHolder);
  }

  const renderDocuments = () => Documents.map((document: DocTrack_) => {
    const {
      Id, DocTypeName, PrimaryLinkDesc, PortalDocumentName,
    } = document;
    const key = `document-${Id}`;

    return (
      <DataPreviewText
        key={key}
        dataUI={key}
        className="mb-2"
        bold="label"
        label={PrimaryLinkDesc}
        text={PortalDocumentName || DocTypeName || ''}
      />
    );
  });

  return (
    <Section
      dataUI="external-document-section"
      title={DocumentsCustomLabel || 'Required Documents'}
      headerText={htmlParser(DocumentsSectionHeaderHTML)}
      footerText={htmlParser(DocumentsSectionFooterHTML)}
    >
      <>{!loading && IsDocumentsFetched && Documents.length > 0 && renderDocuments()}</>
    </Section>
  );
};

export default withRouter(
  connect(
    (state: AppState_) => ({ ...state, }),
    (dispatch: ThunkDispatch_<any, any, AnyAction_>) => {
      return {
        getDocuments: (guid: string) => dispatch(getDocumentsActionCreator(guid)),
        setDefaultDocuments: (documentsHolder: QDDocumentsResult_) => dispatch(setDocuments(documentsHolder)),
      };
    }
  )(Document)
);
