import React, { FC as FC_, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, Checkbox, ScrollableContainer, Alert
} from '@jkhy/vsg-design-system';
import _ from 'lodash';
import QDCoBorrowerApplicationHolder_, {
  QDApplicationRequiredDocs as QDApplicationRequiredDocs_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { htmlParser } from '../../../../../utils/Helper';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../../../utils/Types';
import { ExternalPageSectionName, ValueConstants, GuarantorPages } from '../../../../../utils/Enums';
import Messages from '../../../../../utils/Messages';
import { ICoBorrowerSaveHolder as ICoBorrowerSaveHolder_ } from '../../../../../redux/actions/CoBorrowerHolder';

export interface TermsProps {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDApplicationRequiredDocs_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: {},
    sequence: string
  ) => void;
}

const Terms: FC_<TermsProps> = (props: TermsProps) => {
  const {
    holder,
    loading,
    modelState,
    sectionsState: { VisibleSections, },
  } = useSelector<
    AppState_,
    {
      holder: QDCoBorrowerApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
    }
  >(state => ({
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    loading: state.loading,
    modelState: state.modelState,
    sectionsState: state.sectionsState,
  }));
  const dispatch = useDispatch();
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const agreedFlag = holder?.AgreedFlag;
  const { Model: model, } = modelState?.ModelHolder;

  // Terms and condition section is re-used in Guarantor from Applicant Admin setup
  const section = VisibleSections?.find(s => s.SectionName === ExternalPageSectionName.TermsAndCondition);
  const subSection = section?.SubSections?.find(ss => ss.Code === ValueConstants.TersmAndConditionSubSection);

  const onAgreedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const result = e.currentTarget.checked;
    const action: ICoBorrowerSaveHolder_ = {
      coBorrowerHolderState: {
        CoBorrowerHolder: { ...holder, AgreedFlag: result, },
        IsCoBorrowerHolderFetched: true,
      },
      type: 'SaveCoBorrowerHolder',
    };

    dispatch(action);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!agreedFlag) {
      setShowWarningMessage(true);
      return;
    }

    if (showWarningMessage) setShowWarningMessage(false);

    props.onSubmit([], holder, holder.RequiredDocs, GuarantorPages.Terms);
  };

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.Terms}`} onSubmit={onSubmit}>
          <Section
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
            dataUI="guarantor-terms-and-conditions-section"
          >
            <ScrollableContainer dataUI="terms-and-conditions-text-content" className="mb-2">
              {htmlParser(model.WrapUpHTML)}
            </ScrollableContainer>

            <Checkbox
              checked={agreedFlag || false}
              onChange={onAgreedChange}
              dataUI="terms-and-conditions-checkbox"
              id="terms-and-conditions-checkbox"
              className="d-inline-block"
            >
              Check here to indicate that you have read and agree to the above terms.
            </Checkbox>

            {showWarningMessage && <Alert dataUI="wrap-up-alert-agreed-terms-and-conditions" type="danger" text={Messages.INVALID_WRAP_UP_TERMS} />}
          </Section>
        </form>
      )}
    </>
  );
};

export default Terms;
