import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { partition } from 'lodash';
import {
  FormGroup, Alert, Checkbox, FormSection, ReactAux, ScrollableContainer, Section, Radio, Tooltip
} from '@jkhy/vsg-design-system';
import QdServiceApi from '../../../../../data/api/QDServiceApi';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import CoBorrowerInformationSettings from './CoBorrowerInformationSettings_';
import QuestionTemplate from '../../../../../components/Question/QuestionTemplate';
import { validateQuestionAndSetValidationMessage } from '../../../../../components/Question/QuestionValidate';
import { GuarantorPageSectionName_, GuarantorPages, ValueConstants } from '../../../../../utils/Enums';
import {
  booleanStringComparison,
  errorFoundMessage,
  getHoverHelpPageFieldValue,
  htmlParser,
  isFieldValid,
  scrollToError
} from '../../../../../utils/Helper';
import Messages from '../../../../../utils/Messages';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import { setLoader as setLoaderAction } from '../../../../../redux/actions/Loading';
import { EvalSectionState as EvalSectionState_ } from '../../../../../redux/actions/VisibleSections';
import { setInformationDisclosures } from '../../../../../redux/actions/Disclosures';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../../utils/Types';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationInformation as QDCoBorrowerApplicationInformation_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import Section_ from '../../../../../data/models/Section';
import SubSection_ from '../../../../../data/models/Subsection';
import Question_ from '../../../../../data/models/Question';
import {
  checkAreAllDocumentsOpened,
  checkHasDisclosures,
  checkHasPartyAgreedEarlier,
  checkIsDisclosuresHasEmailTemplate,
  DisclosuresDocuments as DisclosuresDocuments_
} from '../../../../../utils/Disclosures';
import DisclosuresDocuments from '../../../../../components/DisclosuresDocuments/DisclosuresDocuments';

type CoBorrowerInformationProps_ = {
  onSubmit: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, QDCoBorrowerApplicationInformation_>[],
    holder: QDCoBorrowerApplicationHolder_,
    section: QDCoBorrowerApplicationInformation_,
    sequence: string
  ) => void;
};

const CoBorrowerInformation_ = (props: CoBorrowerInformationProps_) => {
  const {
    modelState, sectionsState, loading, holder,
  } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      sectionsState: EvalSectionState_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    sectionsState: state.sectionsState,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
  }));

  const dispatch = useDispatch();

  const [information, setInformation] = useState({ ...holder?.Information, Disclosures: '', Questions: [], });
  const [invalidSetOfPageFields, setInvalidSetOfPageFields] = useState([]);
  const [showDisclosuresErrorMessage, setShowDisclosuresErrorMessage] = useState(false);
  const [disclosureParties, setDisclosureParties] = useState(information?.QDPartyGuids);
  const [agreedDisclosuresFlag, setAgreedDisclosuresFlag] = useState(false);
  const [disclosuresDocuments, setDisclosuresDocuments] = useState<DisclosuresDocuments_>({});

  const pageFieldHolder = modelState?.ModelHolder?.CoBorrowerPageField;
  const section = sectionsState?.VisibleSections?.find((s: Section_) => (s.SectionName || '') === GuarantorPageSectionName_.Information);
  const declarationSubSection = section?.SubSections?.find((s: SubSection_) => s.Code === ValueConstants.CoBorrowerInformationDeclarationsSubSection);
  const disclosureSubSection = section?.SubSections?.find((s: SubSection_) => s.Code === ValueConstants.CoBorrowerInformationDisclosuresSubSection);
  const coBorrower = holder?.Personal?.CoBorrower ?? holder?.Business?.CoBorrower;
  const hasDisclosures = checkHasDisclosures(information?.Disclosures, disclosuresDocuments);
  const isDisclosureSubSectionVisible = disclosureSubSection && hasDisclosures;

  const pageSettings = useMemo(() => {
    return new CoBorrowerInformationSettings([pageFieldHolder?.Information?.General], [declarationSubSection]);
  }, [pageFieldHolder, declarationSubSection]);

  const ESignConsentPageFieldExtended = pageSettings?.PageFields?.find(pageField => pageField.FieldName === 'ESignConsent');

  const validate = () => {
    let isDeclarationQuestionsValid = true;
    let isPageFieldsValid = true;
    let isDisclosuresValid = true;
    let currentInvalidPageFields = [];

    // Declarations
    if (declarationSubSection) {
      // Currently our page fields are visible only if declarations sub section is visible.
      const [invalidPageFields] = partition(pageSettings.PageFields, pf => !pf.IsValid(holder, information, pf) && pf.Component !== null);
      currentInvalidPageFields = [...invalidPageFields];
      setInvalidSetOfPageFields(invalidPageFields);

      isPageFieldsValid = invalidPageFields?.length === 0;

      // Custom Questions
      if (information?.Questions?.length > 0) {
        const validatedDeclarationQuestions = information?.Questions?.map((question: Question_) => {
          const { IsValid, ValidationMessage: validationMessage, } = validateQuestionAndSetValidationMessage(question);

          return {
            ...question,
            IsValid,
            ValidationMessage: validationMessage,
          };
        });

        setInformation({ ...information, Questions: validatedDeclarationQuestions, });

        isDeclarationQuestionsValid = validatedDeclarationQuestions.every((question: Question_) => question?.IsValid);
      }
    }

    // Disclosures
    if (isDisclosureSubSectionVisible) {
      isDisclosuresValid = agreedDisclosuresFlag === true;

      if (!isDisclosuresValid) {
        setShowDisclosuresErrorMessage(true);
      }
    }

    return {
      isValid: isDeclarationQuestionsValid && isPageFieldsValid && isDisclosuresValid,
      invalidPageFields: currentInvalidPageFields,
    };
  };

  const onChangeQuestion = (value: string | number | boolean, propertyName: string, id: bigint) => {
    const { Questions = [], } = information;

    const newQuestions = Questions.map((question: Question_) => (question?.Id === id ? { ...question, [propertyName]: value, } : question));

    setInformation({
      ...information,
      Questions: newQuestions,
    });
  };

  const onChangeESignConsent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, } = e.target;

    const newInformationData = {
      ...information,
      ESignConsent: booleanStringComparison(value),
    };

    setInformation(newInformationData);
  };

  const onAgreedDisclosureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, } = e.currentTarget;

    const newInformationData = {
      ...information,
      InformationDisclosuresAgreeFlag: checked,
      InformationDisclosuresSectionId: section?.Id,
      QDPartyGuids: checked ? [{ PartyGuid: coBorrower.GUID, AgreedEarlier: true, PartyEmail: coBorrower.Email, }] : [],
    };

    setInformation(newInformationData);
    setAgreedDisclosuresFlag(checked);
    if (checked) {
      setShowDisclosuresErrorMessage(false);
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { isValid, invalidPageFields, } = validate();

    if (!isValid) {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);

      return;
    }

    props.onSubmit(invalidPageFields, holder, information, GuarantorPages.Information);
  };

  const hasPartyAgreedEarlier = useMemo(() => {
    const hasAgreedEarlier = checkHasPartyAgreedEarlier(coBorrower.GUID, disclosureParties);

    if (agreedDisclosuresFlag !== hasAgreedEarlier) {
      setAgreedDisclosuresFlag(hasAgreedEarlier);
    }

    return hasAgreedEarlier;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disclosureParties, coBorrower.GUID]);

  useEffect(() => {
    const getDeclarationsAndDisclosures = async () => {
      dispatch(setLoaderAction(true));

      const { GUID, GUIDQDApplication, Id, } = coBorrower;

      const { Result: informationDeclarationQuestions, } = await QdServiceApi.qdApplicationEvaluatedQuestions(null, GUID);
      const {
        Result: { ConcatenatedDisclosures, Parties, PdfDisclosures, },
      } = await QdServiceApi.loadQDApplicationDisclosures(GUIDQDApplication, section?.Id, GUID);

      const questionForGuarantor = informationDeclarationQuestions
        .filter((q: Question_) => {
          return q?.ApplicableForGuarantor && q.IdQDParty === Id && !q.IsHidden && q?.SectionName === section?.SectionName;
        })
        .map((q: Question_) => ({ ...q, NullFields: [], }));

      setDisclosureParties(Parties);
      setInformation({ ...information, Disclosures: ConcatenatedDisclosures, Questions: questionForGuarantor, });

      const hasInformationDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
      dispatch(setInformationDisclosures({ hasDisclosures: hasInformationDisclosures, isFetched: true, }));

      if (PdfDisclosures.length > 0) {
        const newDisclosuresDocuments: DisclosuresDocuments_ = {};
        const hasAgreed = checkHasPartyAgreedEarlier(GUID, Parties);

        PdfDisclosures.forEach(disclosure => {
          const { GUID: disclosureGuid, Name, } = disclosure;

          newDisclosuresDocuments[disclosureGuid] = {
            isOpened: hasAgreed,
            name: Name,
            documentBytes: '',
          };
        });

        setDisclosuresDocuments(newDisclosuresDocuments);
      }

      dispatch(setLoaderAction(false));
    };

    getDeclarationsAndDisclosures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const areAllDocumentsOpened = checkAreAllDocumentsOpened(disclosuresDocuments);
  const hasDisclosuresDocuments = disclosuresDocuments && Object.keys(disclosuresDocuments).length > 0;

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.Information}`} onSubmit={onSubmit} noValidate>
          <Section
            dataUI="guarantor-information-section"
            title={section?.TabName}
            headerText={declarationSubSection?.SubSectionHeaderText}
            footerText={disclosureSubSection?.SubSectionFooterText}
            className="mb-3"
          >
            {declarationSubSection && (
              <FormSection className="mb-3" dataUI="guarantor-information-declaration-section">
                <h3 className="mb-2">{declarationSubSection?.SubSectionName}</h3>

                {(information?.Questions || []).map((question: Question_) => (
                  <QuestionTemplate key={`${question.Id}`} question={question} onChange={onChangeQuestion} />
                ))}

                <FormGroup
                  className="mb-2"
                  checkboxOrRadio
                  dataUI={ESignConsentPageFieldExtended?.dataUI}
                  label={ESignConsentPageFieldExtended?.Label}
                  isRequired={ESignConsentPageFieldExtended?.Required}
                  isBold={ESignConsentPageFieldExtended?.IsBold}
                  isHidden={ESignConsentPageFieldExtended?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(ESignConsentPageFieldExtended)}
                  isValid={isFieldValid(ESignConsentPageFieldExtended?.FieldName, invalidSetOfPageFields)}
                  validationMessage={errorFoundMessage(ESignConsentPageFieldExtended?.FieldName, invalidSetOfPageFields)}
                >
                  {ESignConsentPageFieldExtended?.Component?.options?.map(option => {
                    const { dataUI, value, label, } = option;
                    const key = `${ESignConsentPageFieldExtended?.FieldName}-${value}`;

                    return (
                      <Radio
                        key={key}
                        dataUI={dataUI}
                        className="d-inline-block"
                        name={ESignConsentPageFieldExtended?.ObjectProperty}
                        htmlFor={key}
                        id={key}
                        value={value}
                        checked={information.ESignConsent === booleanStringComparison(value)}
                        onChange={onChangeESignConsent}
                      >
                        {label}
                      </Radio>
                    );
                  })}
                </FormGroup>
              </FormSection>
            )}

            {isDisclosureSubSectionVisible && (
              <ReactAux>
                <FormSection dataUI="guarantor-information-disclosures-section" className="mb-3">
                  <h3 className="mb-2">{disclosureSubSection?.SubSectionName}</h3>

                  {information?.Disclosures && (
                    <ScrollableContainer dataUI="contatenated-guarantor-disclosures" className={`${hasDisclosuresDocuments ? 'mb-3' : ''}`}>
                      {htmlParser(information?.Disclosures)}
                    </ScrollableContainer>
                  )}

                  {hasDisclosuresDocuments && (
                    <DisclosuresDocuments isGuarantor disclosuresDocuments={disclosuresDocuments} setDisclosuresDocuments={setDisclosuresDocuments} />
                  )}

                  {/* eslint-disable-next-line quick-decision/react-data-ui */}
                  <Tooltip title={areAllDocumentsOpened ? '' : Messages.DISCLOSURES_TOOLTIP_TITLE}>
                    <div>
                      <Checkbox
                        dataUI="guarantor-information-disclosures-agreed-checkbox"
                        className="d-inline-block mt-2"
                        htmlFor="guarantor-information-agreement-with-disclosures"
                        id="guarantor-information-agreement-with-disclosures"
                        checked={agreedDisclosuresFlag}
                        disabled={hasPartyAgreedEarlier || !areAllDocumentsOpened}
                        onChange={onAgreedDisclosureChange}
                      >
                        {Messages.CO_BORROWER_DISCLOSURES_CHECKBOX_LABEL}
                      </Checkbox>
                    </div>
                  </Tooltip>
                </FormSection>

                {checkIsDisclosuresHasEmailTemplate(modelState, GuarantorPages.Information) && (
                  <Alert
                    dataUI="guarantor-information-dislosures-alert-email-notification"
                    type="info"
                    text={Messages.CO_BORROWER_DISCLOSURES_AGREEMENT_NOTIFICATION}
                  />
                )}
              </ReactAux>
            )}
          </Section>

          {showDisclosuresErrorMessage && (
            <Alert dataUI="information-disclosure-alert-agreed-notification" type="danger" text={Messages.INVALID_DISCLOSURES} />
          )}
        </form>
      )}
    </>
  );
};

export default CoBorrowerInformation_;
