/* eslint no-unused-vars: "off" */
/* eslint no-console: "off" */
import { Dispatch as Dispatch_ } from 'redux';
import { AxiosResponse as AxiosResponse_ } from 'axios';
import ResponseBase_, { Error as Error_ } from '../data/models/ResponseBase';
import { IGotException as IGotException_ } from '../redux/actions/Exception';

export interface IGeneratorParameters<T> {
  step?: number;
  dispatch: Dispatch_;
  onEachSuccess?: (step: number, res: T) => void;
  onFail?: (error: Error_[]) => void;
}

export default class GeneratorHost<T> {
  params: IGeneratorParameters<T>;

  constructor(params: IGeneratorParameters<T>) {
    this.params = params;
    this.params.onFail = params.onFail || this.onFail;
    this.params.onEachSuccess = params.onEachSuccess || this.onEachSuccess;
    this.params.step = params.step || 1;
  }

  onEachSuccess = (step: number, res: T) => {
    // eslint-disable-next-line
  };

  onFail = (error: Error_[]) => {
    const { dispatch, } = this.params;
    dispatch({ type: 'Fetching', loading: false, });
    const exceptionAction: IGotException_ = {
      exceptions: error,
      type: 'GotException',
    };
    dispatch(exceptionAction);
    // Toast.danger(error?.Message);
  };

  run = generator => {
    type ResultGeneratorType = AxiosResponse_ | ResponseBase_<T>;
    return new Promise(resolve => {
      let iterator;
      const process = ({ done, value: promise, }) => {
        if (!done) {
          if (promise && typeof promise.then === 'function') {
            promise
              .then(
                (result: ResultGeneratorType) => {
                  const axiosResult = result as AxiosResponse_;
                  const data = axiosResult.data ? (axiosResult.data as ResponseBase_<T>) : (result as ResponseBase_<T>);
                  const { Success: success, Result: res, Exceptions: exp, } = data;

                  if (success) {
                    this.params.step = this.params.step + 1;
                    this.params.onEachSuccess(this.params.step, res);
                    process(iterator.next(res));
                  } else {
                    this.params.onFail(exp);
                  }
                },
                ex => {
                  const exception: Error_ = {
                    Code: ex?.response?.status,
                    message: ex?.response?.data?.Exceptions?.message,
                  };
                  this.params.onFail([exception]);
                }
              )
              .catch(error => {
                console.error(error);
              });
          }
        } else {
          resolve();
        }
      };

      iterator = generator();

      process(iterator.next());
    });
  };
}
