import React, { FC as FC_, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { ExternalPages } from '../../../../utils/Enums';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { currencyFormat } from '../../../../utils/Helper';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';
import QdDeposit_ from '../../../../data/models/QDDeposit';

type BusinessDepositsProps = ReviewApplicationSectionProps_;

const BusinessDeposits: FC_<BusinessDepositsProps> = ({
  page, sectionTitle, expandState, setExpandState,
}: BusinessDepositsProps) => {
  const { modelState, holder, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const depositsPageFields = modelState?.ModelHolder?.ApplicationPageField?.BusinessDeposits?.General;
  const deposits = holder?.Deposits?.Deposits;

  const rows: CustomRow_[] = useMemo(() => {
    return deposits?.map((deposit: QdDeposit_) => {
      const depositId = deposit.Id;

      return {
        id: `review-deposits-${depositId}-collapse`,
        title: `${deposit.DepositTypeEnumStr}:`,
        subTitle: deposit.BankName,
        pageFields: depositsPageFields,
        link: { page: ExternalPages.BusinessDeposits, text: 'Go to business deposits step to edit', },
        data: {
          DepositType: {
            dataUI: `deposit-deposit-type-${depositId}-field`,
            value: deposit.DepositTypeEnumStr,
          },
          CurrentBalance: {
            dataUI: `deposit-current-balance-type-${depositId}-field`,
            value: currencyFormat(deposit.CurrentBalance),
          },
          BankName: {
            dataUI: `deposit-bank-name-${depositId}-field`,
            value: deposit.BankName,
          },
        },
      };
    });
  }, [deposits, depositsPageFields]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessDeposits);
