import { Reducer as Reducer_ } from 'redux';
import { IGotQDParty as IGotQDParty_, QDPartyState as QDPartyState_ } from '../actions/QDParty';
import Party_ from '../../data/models/Borrower';

const qdPartyReducer: Reducer_<QDPartyState_, IGotQDParty_> = (
  state = {
    IsQDPartyFetching: false,
    IsQDPartyFetched: false,
    QDParty: new Party_(),
  },
  action
) => {
  switch (action.type) {
    case 'GetQDParty': {
      return { ...action.QDPartyState, };
    }
    default:
      return state;
  }
};

export default qdPartyReducer;
