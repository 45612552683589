import React, { FC as FC_, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Section, FormGroup, Input, MaskedInput, AddressAutocomplete, IconsSolid
} from '@jkhy/vsg-design-system';

import CoBorrowerAddNowHolder_ from '../../../../../data/models/CoBorrowerAddNowHolder';
import { AppState as AppState_ } from '../../../../../redux/AppState';
import PickList from '../../../../../components/PickList/PickList';
import { scrollToError, validateAddressNoPoBox, isGoogleUnavailable } from '../../../../../utils/Helper';
import { buildBusinessPartyMatchAddress } from '../../../../../utils/Address';
import { setLoader } from '../../../../../redux/actions/Loading';
import regexCheck from '../../../../../utils/Validator';
import { AddressDetails as AddressDetails_ } from '../../../../../utils/Types';
import {
  PartyType, ValidationRegexConstants, CoBorrowerAddType, GuarantorPages, GuarantorPageSectionName_
} from '../../../../../utils/Enums';
import QdServiceApi_ from '../../../../../data/api/QDServiceApi';

import Message from '../../../../../utils/Messages';
import InputMasksConstants from '../../../../../utils/InputMasksConstants';
import QDPartyMatchPars_ from '../../../../../data/models/QDPartyMatchPars';
import PageFieldExtended_ from '../../../Page/PageHelpers/PageFieldExtended';
import QDCoBorrowerApplicationHolder_, {
  QDCoBorrowerApplicationBusinessMatchCheck as CoBorrowerBusinessMatchCheck_
} from '../../../../../data/models/QDCoBorrowerApplicationHolder';
import QdApplicationHolder_ from '../../../../../data/models/QDApplicationHolder';

const QD_PARTY_MATCH_PROPERTY_NAMES = {
  BUSINESS_NAME: 'FullName',
  BUSINESS_TIN: 'TIN',
  ADDRESS: 'Address',
  STREET: 'Street',
  ZIP: 'Zip',
  CITY: 'City',
  STATE: 'State',
};

export interface BusinessMatchProps {
  // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
  onSave?: (holder: CoBorrowerAddNowHolder_, currentSectionName: string) => void;
  onCancel?: () => void;
  onSubmit?: (
    invalidPageFields: PageFieldExtended_<QDCoBorrowerApplicationHolder_, CoBorrowerBusinessMatchCheck_>[],
    holder: QDCoBorrowerApplicationHolder_,
    check: CoBorrowerBusinessMatchCheck_,
    sequence: string
  ) => void;
}

const BusinessMatch: FC_<BusinessMatchProps> = (props: BusinessMatchProps) => {
  const {
    // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
    addNowHolder,
    loading,
    holder,
    appHolder,
  } = useSelector<
    AppState_,
    {
      addNowHolder: CoBorrowerAddNowHolder_;
      loading: boolean;
      holder: QDCoBorrowerApplicationHolder_;
      appHolder: QdApplicationHolder_;
    }
  >(state => ({
    addNowHolder: state.coBorrowerAddNowState?.CoBorrowerAddNowHolder,
    loading: state.loading,
    holder: state.coBorrowerHolderState.CoBorrowerHolder,
    appHolder: state.holderState?.Holder,
  }));

  const {
    BUSINESS_NAME, BUSINESS_TIN, ADDRESS, STREET, CITY, STATE, ZIP,
  } = QD_PARTY_MATCH_PROPERTY_NAMES;

  const [businessMatchPars, setBusinessMatchPars] = useState(new QDPartyMatchPars_());

  const [invalidSetOfFields, setInvalidSetOfFields] = useState([]);
  const qdParty = addNowHolder?.CoBorrower ?? holder?.Business?.CoBorrower;
  const isAddNow = qdParty.CoBorrowerAddType === CoBorrowerAddType.AddNow;
  const [coBorrower, setCoBorrower] = useState(qdParty);
  const noGoogleAPI = isGoogleUnavailable();

  useEffect(() => {
    if (isAddNow && qdParty) {
      const { FullName, TIN, } = qdParty;
      setBusinessMatchPars({ ...businessMatchPars, FullName, TIN, });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateState = (value: string, property: string) => {
    if (property === ADDRESS) {
      const updatedPartyMatchPars = value
        ? { ...businessMatchPars, [property]: value, }
        : {
          ...businessMatchPars,
          Address: '',
          Street: '',
          City: '',
          State: '',
          Zip: '',
        };
      setBusinessMatchPars(updatedPartyMatchPars);
      return;
    }
    setBusinessMatchPars({ ...businessMatchPars, [property]: value?.trim(), });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, property: string) => {
    e.preventDefault();

    const {
      target: { value, },
    } = e;
    updateState(value, property);
  };

  const onStateChange = (item: any, property: string) => {
    updateState(item?.value, property);
  };

  const onAddressSelect = (addressDetails: AddressDetails_) => {
    const {
      fullAddress, streetAddress, city, state, zipCode,
    } = addressDetails;

    setBusinessMatchPars({
      ...businessMatchPars,
      Address: fullAddress?.trim(),
      Street: streetAddress?.trim(),
      City: city?.trim(),
      State: state?.trim(),
      Zip: zipCode?.trim(),
    });
  };

  const dispatch = useDispatch();
  const hasDifferentTIN = (): boolean => {
    let result = true;
    if (isAddNow && appHolder) {
      const participants = [...appHolder?.Guarantor?.Guarantors];
      if (appHolder?.BorrowerB?.Business) {
        participants.push(appHolder.BorrowerB.Business);
      }
      if (appHolder?.BorrowerP?.Personal) {
        participants.push(appHolder.BorrowerP.Personal);
      }
      if (participants.find(g => g.TIN === businessMatchPars.TIN)) result = false;
    }

    return result;
  };

  const getTinInvalidMessage = (): string => {
    if (!invalidSetOfFields.length) return '';
    if (!businessMatchPars.TIN) return Message.REQUIRED_FIELD;

    return hasDifferentTIN() ? Message.INVALID_TIN_FORMAT : Message.BUSINESS_TIN_SAME;
  };
  const getZipInvalidMessage = () => (!businessMatchPars.Zip ? Message.REQUIRED_FIELD : Message.INVALID_ZIP_FORMAT);
  const validateZipFormat = (ZipCode: string) => {
    const regexExpNoPoBox = new RegExp(ValidationRegexConstants.ZipCode);
    return regexExpNoPoBox.test(ZipCode);
  };

  const validate = () => {
    const invalidSetOfF = [];

    if (!businessMatchPars.FullName) invalidSetOfF.push(BUSINESS_NAME);

    if (!businessMatchPars.TIN) invalidSetOfF.push(BUSINESS_TIN);
    else {
      const { IsValid, } = regexCheck({
        regexStr: ValidationRegexConstants.TIN_SSN,
        value: businessMatchPars.TIN,
        errorMessage: Message.INVALID_TIN_FORMAT,
      });

      if (!IsValid) invalidSetOfF.push(BUSINESS_TIN);

      if (IsValid && !hasDifferentTIN()) invalidSetOfF.push(BUSINESS_TIN);
    }

    if (noGoogleAPI) {
      // Google API is NOT available
      if (!businessMatchPars.Street || !validateAddressNoPoBox(businessMatchPars.Street)) invalidSetOfF.push(STREET);
      if (!businessMatchPars.City) invalidSetOfF.push(CITY);
      if (!businessMatchPars.State) invalidSetOfF.push(STATE);
      if (!businessMatchPars.Zip || !validateZipFormat(businessMatchPars.Zip)) invalidSetOfF.push(ZIP);
    } else if (
      (!businessMatchPars.Address // Google API is avaialble
        && !businessMatchPars.Street
        && !businessMatchPars.City
        && !businessMatchPars.State
        && !businessMatchPars.Zip)
      || !validateAddressNoPoBox(businessMatchPars.Address)
    ) {
      invalidSetOfF.push(ADDRESS);
    }

    setInvalidSetOfFields(invalidSetOfF);

    return invalidSetOfF.length === 0;
  };

  const onSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate()) {
      dispatch(setLoader(true));
      businessMatchPars.GUIDQDApplication = coBorrower?.GUIDQDApplication;
      businessMatchPars.PartyType = PartyType.Business;
      const { Result, } = await QdServiceApi_.qdPartyMatch(businessMatchPars);

      coBorrower.PartyMatchDone = true;
      if (Result?.IdParty) coBorrower.IdParty = Result?.IdParty;
      else {
        coBorrower.FullName = businessMatchPars.FullName;
        coBorrower.TIN = businessMatchPars.TIN;
      }
      setCoBorrower(coBorrower);

      const newAddress = buildBusinessPartyMatchAddress(businessMatchPars, coBorrower);
      const newBusinessAddresses = [newAddress];

      if (isAddNow) {
        // TODO: remove once AddNow and Email Invited code logic are merged in single CoBorowwer flow
        const coBorrowerAddNowHolder: CoBorrowerAddNowHolder_ = { ...addNowHolder, CoBorrower: coBorrower, Addresses: newBusinessAddresses, };
        await props.onSave(coBorrowerAddNowHolder, GuarantorPageSectionName_.BusinessPartyMatch);
      } else {
        const businessMatchCheck = { ...holder?.BusinessMatchCheck, CoBorrower: coBorrower, BusinessAddresses: newBusinessAddresses, };
        await props.onSubmit([], holder, businessMatchCheck, GuarantorPages.BusinessPartyMatch);
      }

      dispatch(setLoader(false));
    } else {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
    }
  };

  const IsValid = field => !invalidSetOfFields.find(invalidField => invalidField === field);

  return (
    <>
      {!loading && (
        <form id={`form-${GuarantorPages.BusinessPartyMatch}`} onSubmit={onSave}>
          <Section
            title="Business"
            headerText={`To save you time in filling out the business information,
                          please enter the following information
                          so we can try to locate your business details.`}
            dataUI="co-borrower-add-now-party-check"
          >
            <FormGroup
              className="mb-2"
              htmlFor="businessName"
              label="Business Name"
              dataUI="co-borrower-business-name"
              isRequired
              isValid={IsValid(BUSINESS_NAME)}
              validationMessage={Message.REQUIRED_FIELD}
            >
              <Input
                type="text"
                name="businessName"
                id="businessName"
                dataUI="co-borrower-business-name-field"
                value={businessMatchPars.FullName}
                onChange={e => onChange(e, BUSINESS_NAME)}
              />
            </FormGroup>
            <FormGroup
              className="mb-2"
              htmlFor="businessTIN"
              isRequired
              label="Business TIN"
              dataUI="co-borrower-tin"
              isValid={IsValid(BUSINESS_TIN)}
              validationMessage={getTinInvalidMessage()}
            >
              <MaskedInput
                id="businessTIN"
                dataUI="co-borrower-tin-field"
                name="businessTIN"
                mask={InputMasksConstants.SSN_TAX_ID}
                pattern={null}
                value={businessMatchPars.TIN}
                onChange={e => onChange(e, BUSINESS_TIN)}
                className="text-left"
              />
            </FormGroup>
            <FormGroup
              isHidden={noGoogleAPI}
              className="mb-2"
              htmlFor="businessAddress"
              label="Business Address"
              dataUI="co-borrower-address"
              isRequired
              isValid={IsValid(ADDRESS) && validateAddressNoPoBox(businessMatchPars.Address)}
              validationMessage={validateAddressNoPoBox(businessMatchPars.Address) ? Message.REQUIRED_FIELD : Message.INVALID_STREET_ADDRESS}
            >
              <AddressAutocomplete
                type="text"
                name="businessAddress"
                id="businessAddress"
                dataUI="co-borrower-address-field"
                value={businessMatchPars?.Address}
                onChange={(addressDetails: any) => updateState(addressDetails, ADDRESS)}
                onAddressSelect={(addressDetails: any) => {
                  onAddressSelect(addressDetails);
                }}
              />
            </FormGroup>
            {noGoogleAPI && (
              <>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessStreet"
                  isRequired
                  label="Street Address"
                  dataUI="fgBusinessStreet"
                  isValid={IsValid(STREET)}
                  validationMessage={validateAddressNoPoBox(businessMatchPars.Street) ? Message.REQUIRED_FIELD : Message.INVALID_STREET_ADDRESS}
                >
                  <Input id="businessStreet" name="businessStreet" dataUI="txtBusinessStreet" onChange={e => onChange(e, STREET)} type="text" />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessCity"
                  isRequired
                  label="City"
                  dataUI="fgBusinessCity"
                  isValid={IsValid(CITY)}
                  validationMessage={Message.REQUIRED_FIELD}
                >
                  <Input id="businessCity" name="businessCity" dataUI="txtBusinessCity" onChange={e => onChange(e, CITY)} type="text" />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessState"
                  isRequired
                  label="State"
                  dataUI="fgBusinessState"
                  isValid={IsValid(STATE)}
                  validationMessage={Message.REQUIRED_FIELD}
                >
                  <PickList
                    dsSelectWrapper
                    disabled={false}
                    value={businessMatchPars.State}
                    id="businessState"
                    stateLess={false}
                    initialData={[]}
                    onChange={e => onStateChange(e, STATE)}
                    ListName="States"
                    ListType="LtItems"
                    dataUI="businessState"
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  htmlFor="businessZipCode"
                  isRequired
                  label="Zip"
                  dataUI="fgBusinessZipCode"
                  isValid={IsValid(ZIP)}
                  validationMessage={getZipInvalidMessage()}
                >
                  <MaskedInput
                    id="businessZipCode"
                    dataUI="txtBusinessZipCode"
                    name="businessZipCode"
                    type="zipCode"
                    mask={InputMasksConstants.ZIP_CODE}
                    pattern={null}
                    onChange={e => onChange(e, ZIP)}
                    icon={IconsSolid.faMapPin}
                  />
                </FormGroup>
              </>
            )}
          </Section>
        </form>
      )}
    </>
  );
};

export default BusinessMatch;
