import PageField_ from '../../../../data/models/PageField';
import PageFieldExtended_ from './PageFieldExtended';
import SubSection_ from '../../../../data/models/Subsection';

export default class PageSettings<THolder, THolderSubset, TAdditionalProperties> {
  PageFields: PageFieldExtended_<THolder, THolderSubset>[] = [];

  PageSection: SubSection_[] = null;

  constructor(pageFields: any[], sections: SubSection_[], additionalProperties?: TAdditionalProperties) {
    let pageFieldsArr = [];
    pageFields.forEach(p => {
      const arr = Object.keys(p || []).map(key => p[key]);
      pageFieldsArr = pageFieldsArr.concat(arr);
    });

    this.setupPageFields(pageFieldsArr, additionalProperties);
    this.PageSection = sections;
  }

  setupPageFields(pageFields: PageField_[], additionalProperties?: TAdditionalProperties) {
    if (pageFields.length <= 0) {
      return;
    }

    this.PageFields = pageFields.map(p => this.pageFieldsMapper(p, additionalProperties));
  }

  // eslint-disable-next-line no-unused-vars
  protected pageFieldsMapper(pageField: PageField_, additionalProperties?: TAdditionalProperties): PageFieldExtended_<THolder, THolderSubset> {
    return (
      this.PageFields.find(x => x.FieldName === pageField.FieldName)
      || PageFieldExtended_.createInstance({
        Column: -1,
        Row: -1,
        Component: null,
        ...pageField,
        ObjectPropertyType: null,
      })
    );
  }
}
