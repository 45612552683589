import { Alert, ReactAux, ToastContainer } from '@jkhy/vsg-design-system';

import React from 'react';
import userManager from '../../utils/UserManager';
import BaseAPI from '../../data/api/BaseApi';
import submitProcessGenerator from '../../utils/SubmitProcess';
import LoadingPanel from '../../components/Loading/Loading';
import GeneratorHost, { IGeneratorParameters as IGeneratorParameters_ } from '../../utils/GeneratorHost';
import QdApplicationModel_ from '../../data/models/QDApplicationModel';
import Messages from '../../utils/Messages';
import { QdApplication as QdApplication_ } from '../../data/models/QDApplication';
import { ApplicationIdentifiers as ApplicationIdentifiers_, BaseType as BaseType_ } from '../../utils/Types';
import { ContextParams, QueryParams, RenewalPages as RenewalPages_ } from '../../utils/Enums';
import { getQueryParameter } from '../../utils/Helper';
import { setContext } from '../../utils/LocalStorageManager';

export const LINKS = {
  TAB: '/renewal/',
  LOGOUT: '/logout',
  PAGE_MISSING: '/404',
  IE_NOT_SUPPORTED: '/ie-not-supported',
};
interface IContent {
  content: () => JSX.Element;
}

export default class BaseComponent<TProps extends BaseType_, TState> extends React.Component<TProps, TState> implements IContent {
  componentDidMount() {
    const {
      location: { search, },
    } = this.props;

    const context = getQueryParameter(QueryParams.Context, search);

    if (context === ContextParams.CurrentRequests) {
      setContext(context);
    }
  }

  getParams = () => {
    const {
      match: {
        params: { model, },
      },
    } = this.props;

    return { InstanceId: window.INSTANCE_ID, QDModel: model || '', };

    // This functionality is commented, because we added new query parameter "context",
    // which is used to track whether application was started by current request application in CP
    // return (window.location.search !== null && window.location.search !== ''
    //   ? {
    //     InstanceId: window.INSTANCE_ID, QDModel: (model || ''), InvitationEmail: window.location.search.split('=')[1], prompt: 'login',
    //   }
    //   : { InstanceId: window.INSTANCE_ID, QDModel: (model || ''), });
  };

  signinRedirect = () => {
    const pars = this.getParams();
    return userManager.signinRedirect({ extraQueryParams: pars, });
  };

  componentDidCatch = (error: Error) => {
    Toast.danger(error?.message || Messages.GENERAL_ERROR, {
      autoClose: false,
    });
  };

  submit = async <T, >(app: ApplicationIdentifiers_, onEachSuccess: any, onFail: () => void, initStep: number, model: QdApplicationModel_) => {
    const { dispatch, }: any = this.props;
    const generatorParams: IGeneratorParameters_<T> = {
      dispatch,
      onEachSuccess,
      onFail,
      step: initStep,
    };

    await new GeneratorHost(generatorParams).run(submitProcessGenerator.bind(null, model, app));
  };

  save = (app: QdApplication_) => BaseAPI.performAjax<any>({
    url: `${window.BASE_URL}api/QuickDecisionRenewalApplication/QDApplicationUpdate`,
    method: 'POST',
    data: app,
  });

  goToRenewalPage = (renewalSequence: RenewalPages_, guid: string = '') => {
    const { history, match, } = this.props;
    let url = `${LINKS.TAB + match.params.model}/${match.params.idInvitation}/${renewalSequence}/`;
    if (guid) url = `${url}${guid}/`;
    return history.push(url);
  };

  goTo404 = () => {
    const { history, } = this.props;
    return history.push(LINKS.PAGE_MISSING);
  };

  goToLogout = () => {
    const { history, } = this.props;
    return history.push(LINKS.LOGOUT);
  };

  goToIENotSupported = () => {
    const { history, } = this.props;
    return history.push(LINKS.IE_NOT_SUPPORTED);
  };

  goToCustomLocation = (location: any) => {
    const { history, } = this.props;
    return history.push(location);
  };

  content = () => <div />;

  render() {
    const { loading, exception, children, } = this.props;
    return (
      <ReactAux>
        <ToastContainer />
        {children || this.content()}
        {loading && <LoadingPanel dataUI="base-component-loader" />}
        {exception.length > 0
          ? exception.map(e => (
            <Alert key={`${e?.Code}-${e?.message}`} dataUI="base-component-exception-alert" type="danger" className="mt-1 mb-2" text={e?.message} />
          ))
          : null}
      </ReactAux>
    );
  }
}
