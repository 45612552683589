import React, { FC as FC_ } from 'react';
import { useSelector } from 'react-redux';
import {
  Section, Row, Col, TextEditorContentWrapper
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../../redux/AppState';
import { htmlParser } from '../../../utils/Helper';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';

const Expired: FC_<any> = () => {
  const { loading, modelState, } = useSelector<
    AppState_,
    {
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    loading: state.loading,
    modelState: state.modelState,
  }));
  const model = modelState?.ModelHolder?.Model;
  const expiredHTML = htmlParser(model?.ClosedApplicationHTML);

  return (
    <>
      {!loading && (
        <form className="ml-3" id="form-expired">
          <Section title="Closed Application" className="mb-3" dataUI="expired-section">
            <Row>
              <Col md="12" lg="12">
                <p>
                  <TextEditorContentWrapper>{expiredHTML || ''}</TextEditorContentWrapper>
                </p>
              </Col>
            </Row>
          </Section>
        </form>
      )}
    </>
  );
};

export default Expired;
