import { combineReducers } from 'redux';
import { reducer } from 'redux-oidc';
import modelReducer from './Model';
import renewalReducer from './Renewal';
import holderReducer from './Holder';
import loadingReducer, { progressReducer } from './Loading';
import exceptionReducer from './Exception';
import sectionsReducer from './VisibleSections';
import qdPartyReducer from './QDParty';
import documentsReducer from './Documents';
import reviewButtonReducer from './SubmitButtonReducer';
import identityReducer from './Identity';
import guarantorEmploymentReducer from './GuarantorEmployment';
import coBorrowerAddNowReducer from './CoBorrowerAddNow';
import coBorrowerIdentityReducer from './CoBorrowerIdentity';
import coBorrowerHolderReducer from './CoBorrowerHolder';
import coBorrowerSubmitReducer from './CoBorrowerSubmit';
import disclosuresReducer from './Disclosures';
import unauthorizedReducer from './Unauthorized';
import appSettingsReducer from './AppSettings';
import prequalificationModelStateReducer from './PrequalificationModel';
import prequalificationHolderReducer from './PrequalificationHolder';

export default combineReducers({
  modelState: modelReducer,
  exception: exceptionReducer,
  oidc: reducer,
  renewal: renewalReducer,
  loading: loadingReducer,
  showProgress: progressReducer,
  holderState: holderReducer,
  sectionsState: sectionsReducer,
  qdPartyState: qdPartyReducer,
  guarantorEmploymentState: guarantorEmploymentReducer,
  renewalModelState: renewalReducer,
  documentState: documentsReducer,
  submitToReviewButtonState: reviewButtonReducer,
  identityState: identityReducer,
  coBorrowerIdentityState: coBorrowerIdentityReducer,
  coBorrowerAddNowState: coBorrowerAddNowReducer,
  coBorrowerHolderState: coBorrowerHolderReducer,
  isCoBorrowerSubmitted: coBorrowerSubmitReducer,
  disclosuresState: disclosuresReducer,
  unauthorized: unauthorizedReducer,
  appSettings: appSettingsReducer,
  prequalificationModelState: prequalificationModelStateReducer,
  prequalificationHolder: prequalificationHolderReducer,
});
