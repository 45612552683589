import BaseAPI from './BaseApi';
import ListItem_ from '../models/ListItem';
import NameValueItem_ from '../models/NameValueItem';
import ResponseBase_ from '../models/ResponseBase';
import Branch_ from '../models/Branch';
import PartyDBA_ from '../models/PartyDBA';

export type LtPars = {
  listName: string;
  sortOrder?: string;
  sortColumn?: string;
};

export type EnumPars = {
  enumQualifiedName: string;
  ignoreList?: string;
};

export default class ListAPI extends BaseAPI {
  static getLtItemsDefault = (IdBank, LtNames) => {
    const dataFromCache = sessionStorage.getItem(`LtItemsDefault-${LtNames.join('-')}`);

    if (dataFromCache) {
      const response = new ResponseBase_<ListItem_[]>();
      const cached = JSON.parse(dataFromCache);
      response.Result = cached as ListItem_[];
      response.Success = true;
      return new Promise(resolve => resolve(response));
    }

    return BaseAPI.performAjax<ListItem_[]>({
      method: 'POST',
      cacheLtDefault: true,
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApi/LtItemsGetDefault`,
      data: { IdBank, LtNames, },
    });
  };

  static getLtItemsSearch = ({ sortColumn, listName, sortOrder, }: LtPars): Promise<ResponseBase_<ListItem_[]>> => {
    const dataFromCache = sessionStorage.getItem(`LtItems-${listName}`);

    if (dataFromCache) {
      const response = new ResponseBase_<ListItem_[]>();
      const cached = JSON.parse(dataFromCache);
      response.Result = cached as ListItem_[];
      response.Success = true;
      return new Promise(resolve => resolve(response));
    }

    return BaseAPI.performAjax<ListItem_[]>({
      method: 'POST',
      cacheLt: true,
      data: { LtName: listName, SortOrder: sortOrder || 'ASC', SortColumn: sortColumn, },
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApi/LtItemsSearch`,
    });
  };

  static getEnumValues = (_pars: EnumPars): Promise<ResponseBase_<NameValueItem_[]>> => {
    const dataFromCache = sessionStorage.getItem(`EnumItems-${_pars.enumQualifiedName}`);
    if (dataFromCache) {
      const response = new ResponseBase_<NameValueItem_[]>();
      const cached = JSON.parse(dataFromCache);
      response.Result = cached as NameValueItem_[];
      response.Success = true;
      return new Promise(resolve => resolve(response));
    }

    return BaseAPI.performAjax<NameValueItem_[]>({
      method: 'GET',
      cacheEnumName: _pars.enumQualifiedName,
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApi/EnumValues?enumQualifiedName=${_pars.enumQualifiedName}&ignoreList=${_pars.ignoreList}`,
    });
  };

  static getBranches = (): Promise<ResponseBase_<Branch_[]>> => {
    const cacheName = 'Branches';
    const dataFromCache = sessionStorage.getItem(cacheName);
    if (dataFromCache) {
      const response = new ResponseBase_<Branch_[]>();
      const cached = JSON.parse(dataFromCache);
      response.Result = cached as Branch_[];
      response.Success = true;
      return new Promise(resolve => resolve(response));
    }

    return BaseAPI.performAjax<Branch_[]>({
      method: 'POST',
      cacheName: 'Branches',
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApi/BranchList`,
      data: { CompanyId: 1, IsAdmin: false, },
    });
  };

  static getPartyDBAs = (idParty: BigInt): Promise<ResponseBase_<PartyDBA_[]>> => {
    return BaseAPI.performAjax<PartyDBA_[]>({
      method: 'GET',
      url: `${BaseAPI.BASE_URL}api/QuickDecisionApi/PartyDBAList?IdParty=${idParty}`,
    });
  };
}
