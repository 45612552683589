import { validateESignConsent } from '../../../utils/Validator';
import PageSettings from '../Page/PageHelpers/PageSettings';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import PageField_ from '../../../data/models/PageField';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import { PrimaryQuestions as PrimaryQuestions_ } from './Declaration';

export default class DeclarationSettings<T, T1> extends PageSettings<QdApplicationHolder_, PrimaryQuestions_, bigint> {
  // eslint-disable-next-line class-methods-use-this
  protected pageFieldsMapper(pageField: PageField_, primaryBorrowerPartyId: bigint): PageFieldExtended_<QdApplicationHolder_, PrimaryQuestions_> {
    if (!pageField) {
      return null;
    }

    switch (pageField.FieldName) {
      case 'ESignConsent':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-esign-consent-form-group',
          ObjectProperty: pageField.FieldName,
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'declaration-esign-consent-radio-yes', value: 'true', label: 'Yes', },
              { dataUI: 'declaration-esign-consent-radio-no', value: 'false', label: 'No', }
            ],
          },
          IsValid: (holder, subHolder, pageF) => {
            const { IsValid, InvalidMessage, } = validateESignConsent(subHolder, pageF);

            const pf = pageF;

            if (InvalidMessage) {
              pf.ValidationMessage = InvalidMessage;
            }

            return IsValid;
          },
        });
      case 'RegOQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-rego-question-form-group',
          ObjectProperty: 'RegOResponse',
          ObjectPropertyType: 'boolean',
          Component: {
            type: 'radio',
            options: [
              { dataUI: 'declaration-rego-question-radio-Yes', value: 'true', label: 'Yes', },
              { dataUI: 'declaration-rego-question-radio-No', value: 'false', label: 'No', }
            ],
          },
        });
      case 'RegOResponseMemo':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-rego-question-if-yes-answer-form-group',
          ObjectProperty: pageField.FieldName,
          Component: {
            type: 'textarea',
            dataUI: 'declaration-rego-question-if-yes-answer',
          },
        });
      case 'OfficerQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-officer-question-field',
          ObjectProperty: pageField.FieldName,
          IsValid: (holder, app, pf) => {
            if (pf.Required) {
              return pf.Required && app.OfficerQuestionCode !== null;
            }
            return true;
          },
        });
      case 'MarketingQuestion':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-marketing-question-field',
          ObjectProperty: 'MarketingQuestionCode',
          ObjectPropertyStr: 'MarketingQuestionCodeStr',
          IsHiddenCalculated: (appHolder, subHolder) => {
            // Does NOT apply for coborrowers added now. Related to page render logic
            return subHolder?.IdQDParty !== primaryBorrowerPartyId;
          },
        });
      case 'MarketingQuestionMemo':
        return PageFieldExtended_.createInstance({
          ...pageField,
          dataUI: 'declaration-marketing-question-memo-field',
          ObjectProperty: 'MarketingQuestionMemo',
          Component: {
            type: 'textarea',
            dataUI: 'declaration-marketing-question-memo-textarea',
          },
        });

      default:
        return PageFieldExtended_.createInstance({
          ...pageField,
          ObjectProperty: pageField.FieldName,
        });
    }
  }
}
