import { Reducer as Reducer_ } from 'redux';
import { LoadingAction as LoadingAction_, ProgressingAction as ProgressingAction_ } from '../actions/Loading';

const loadingReducer: Reducer_<boolean, LoadingAction_> = (state = false, action) => {
  switch (action.type) {
    case 'Fetching': {
      return action.loading;
    }
    default:
      return state;
  }
};

export default loadingReducer;

export const progressReducer: Reducer_<boolean, ProgressingAction_> = (state = false, action) => {
  switch (action.type) {
    case 'Progressing': {
      return action.progress;
    }
    default:
      return state;
  }
};
