import { ExternalPageSectionName, GuarantorPageSectionName_ } from './Enums';
import Borrower_ from '../data/models/Borrower';
import Branch_ from '../data/models/Branch';
import { getFullName, joinValidElementsInString } from './Helper';
import Question_ from '../data/models/Question';

export const getPartyNameForDeclarations = (isPrimaryBorrower: boolean, party: Borrower_, businessParty?: Borrower_) => {
  if (isPrimaryBorrower) {
    const personalBorrowerName = getFullName(party);
    const businessBorrowerName = getFullName(businessParty);

    return joinValidElementsInString([personalBorrowerName, businessBorrowerName], ' / ');
  }

  return getFullName(party);
};

export const getBranchQuestionOptions = (branch: Branch_) => {
  const {
    Name, Code, Address1, Address2, City, State, Zip,
  } = branch;

  let address = [Address1, Address2, City, State].filter(value => value).join(', ');

  if (Zip) {
    address = `${address} ${Zip}`;
  }

  const label = address ? `${Name} - ${address}` : Name;

  return {
    label,
    value: Code,
  };
};

export const filterQuestion = (
  question: Question_,
  sectionName:
    | typeof ExternalPageSectionName[keyof typeof ExternalPageSectionName]
    | typeof GuarantorPageSectionName_[keyof typeof GuarantorPageSectionName_],
  isGuarantor: boolean,
  partyId?: bigint
) => {
  const commonConditions = question.IdQDParty === partyId && !question.IsHidden && question.SectionName === sectionName;

  return isGuarantor ? question.ApplicableForGuarantor && commonConditions : question.ApplicableForBorrower && commonConditions;
};
