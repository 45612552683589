import React, { FC as FC_, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import ReviewApplicationGeneralSection, { CustomRow as CustomRow_ } from '../ReviewApplicationGeneralSection';
import { ExternalModelHolder as ExternalModelHolder_, ModelState as ModelState_ } from '../../../../utils/Types';
import { cityStateZip, cityStateZipLabel, cityStateZipVisibility } from '../../../../utils/Address';
import { AppState as AppState_ } from '../../../../redux/AppState';
import Reference_ from '../../../../data/models/Reference';
import QdApplicationHolder_ from '../../../../data/models/QDApplicationHolder';
import { ReviewApplicationSectionProps as ReviewApplicationSectionProps_ } from '../ReviewApplication';

type BusinessReferencesProps = ReviewApplicationSectionProps_;

const BusinessReferences: FC_<BusinessReferencesProps> = ({
  page, sectionTitle, expandState, setExpandState,
}: BusinessReferencesProps) => {
  const { modelState, holder, } = useSelector<
    AppState_,
    {
      modelState: ModelState_<ExternalModelHolder_>;
      holder: QdApplicationHolder_;
    }
  >(state => ({
    modelState: state.modelState,
    holder: state.holderState?.Holder,
  }));

  const referencesPageFields = modelState?.ModelHolder?.ApplicationPageField?.BusinessReferences?.General;
  const references = holder?.References?.References;

  const rows: CustomRow_[] = useMemo(() => {
    return references?.map((reference: Reference_) => {
      const { City, State, ZIPCode, } = referencesPageFields || {};
      const referenceId = reference.Id;

      const addressLabel = cityStateZipLabel;
      const address = cityStateZip(reference.City, reference.State, reference.Zip, City, State, ZIPCode);
      const isAddressVisible = cityStateZipVisibility(City, State, ZIPCode);

      return {
        id: `review-business-references-${referenceId}-collapse`,
        title: `${reference.ReferenceTypeEnumStr}: `,
        subTitle: reference.Name,
        pageFields: referencesPageFields,
        link: { page, text: 'Go to business references step to edit', },
        data: {
          RefName: {
            dataUI: `business-reference-name-${referenceId}-field`,
            value: reference.Name,
          },
          Email: {
            dataUI: `business-reference-email-${referenceId}-field`,
            value: reference.Email,
          },
          Address: {
            dataUI: `business-reference-city-state-zip-${referenceId}-field`,
            value: address,
            customLabel: addressLabel,
            isVisible: isAddressVisible,
          },
          PhoneNumber: {
            dataUI: `business-reference-phone-number-${referenceId}-field`,
            value: reference.Phone,
          },
        },
      };
    });
  }, [page, references, referencesPageFields]);

  return (
    <ReviewApplicationGeneralSection page={page} sectionTitle={sectionTitle} rows={rows} expandState={expandState} setExpandState={setExpandState} />
  );
};

export default memo(BusinessReferences);
