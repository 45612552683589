import BaseAPI from './BaseApi';
import QdApplicationHolder_ from '../models/QDApplicationHolder';
import Borrower_ from '../models/Borrower';
import QdApplicationBase_ from '../models/QDApplicationBase';
import Question_ from '../models/Question';
import Disclosure_ from '../models/Disclosure';
import Section_ from '../models/Section';
import IdentityCheckEvalParam_ from '../models/IdentityCheckEvalParam';
import ActionLog_ from '../models/QDActionLog';
import QdEvalResult_ from '../models/QDEvalResult';
import QDLoanOptionSelectPars_ from '../models/QDLoanOptionSelectPars';
import QDEvaluationIdentityResult_ from '../models/QDEvaluationIdentityResult';
import QDPartyMatchPars_ from '../models/QDPartyMatchPars';
import QDPartyMatchResult_ from '../models/QDPartyMatchResult';
import QDCOBorrowerHolder_ from '../models/CoBorrowerAddNowHolder';
import QDAddress_ from '../models/QDAddress';
import CoBorrowerPars_ from '../models/CoBorrowerPars';
import Employment_ from '../models/Employment';
import Reference_ from '../models/Reference';
import Collateral_ from '../models/Collateral';
import QDDebt_ from '../models/QDDebt';
import QDDeposit_ from '../models/QDDeposit';
import QDDisclosureResponse_ from '../models/QDDisclosureResponse';
import QDCoBorrowerApplicationHolder_ from '../models/QDCoBorrowerApplicationHolder';
import { QDPartyStatus as QDPartyStatus_ } from '../../utils/Enums';

export default class QdServiceApi extends BaseAPI {
  static inquire = (GUIDQDApplication, Email, QDApplicationModelCode) => BaseAPI.performAjax<QdApplicationHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/Inquire`,
    data: { GUIDQDApplication, Email, QDApplicationModelCode, },
  });

  static inquireQdParty = guidQdParty => BaseAPI.performAjax<Borrower_>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDPartyInquire?guidQdParty=${guidQdParty}`,
  });

  static inquireQdEmployment = (guidQdParty: string) => BaseAPI.performAjax<Employment_>({
    method: 'GET',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDEmploymentInquire?guidQdParty=${guidQdParty}`,
  });

  static createQdApplication = setup => BaseAPI.performAjax<QdApplicationBase_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDApplicationCreate`,
    data: setup,
  });

  static getQDApplicationHolder = (GUIDQDApplication, Email, QDApplicationModelCode) => BaseAPI.performAjax<QdApplicationHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/GetQDApplicationHolder`,
    data: { GUIDQDApplication, Email, QDApplicationModelCode, },
  });

  // eslint-disable-next-line max-len
  static getQDApplicationHolderGuarantorUID = (QDPartyUID: string, Email, QDApplicationModelCode) => BaseAPI.performAjax<QDCoBorrowerApplicationHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/InquireCoBorrowerHolder`,
    data: { QDPartyUID, Email, QDApplicationModelCode, },
  });

  static getQDApplicationHolderForGuarantoReview = (QDPartyUID: string) => BaseAPI.performAjax<QdApplicationHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDApplicationInquireHolderByGuarantorUID`,
    data: { QDPartyUID, },
  });

  static saveSection = holder => BaseAPI.performAjax<QdApplicationBase_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveSection`,
    data: holder,
  });

  static saveCoBorrowerSection = holder => BaseAPI.performAjax<QdApplicationBase_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveCoBorrowerSection`,
    data: holder,
  });

  static setQDPartyEmail = (qdPartyObj: Borrower_) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SetQDPartyEmail`,
    data: qdPartyObj,
  });

  static sendQDPartyEmail = (qdPartyObj: Borrower_) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SendQDPartyEmail`,
    data: qdPartyObj,
  });

  static saveQdParty = (qdPartyObj: Borrower_) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDParty`,
    data: qdPartyObj,
  });

  static saveQdEmployment = (qdEmploymentObj: Employment_) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDEmployment`,
    data: qdEmploymentObj,
  });

  static saveQDPartyQuestions = (qdPartyQuestions: Borrower_) => BaseAPI.performAjax<Question_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDPartyQuestions`,
    data: qdPartyQuestions,
  });

  // eslint-disable-next-line max-len
  static loadQDApplicationDisclosures = (qdAppGuid: string, idQDAppSection: BigInt, partyGUID: string = '') => BaseAPI.performAjax<QDDisclosureResponse_>({
    method: 'GET',
    // eslint-disable-next-line max-len
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDApplicationDisclosures?qdApplicationGuid=${qdAppGuid}&idQDApplicationSection=${idQDAppSection}&qdGuarantorGuid=${partyGUID}`,
  });

  static loanOptionSelect = (pars: QDLoanOptionSelectPars_) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionComplete/LoanOptionSelect`,
    data: pars,
  });

  static loanOptionWithdraw = (idQdApplication: BigInt) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionComplete/LoanOptionWithdraw`,
    data: idQdApplication,
  });

  // qdApplicationGuid parameter is used for Borrower question; guarantorGuid parameter is used for CoBorrower questions
  static qdApplicationEvaluatedQuestions = (
    qdApplicationGuid: string,
    guarantorGuid?: string,
    primaryPersonalGuid?: string,
    primaryBusinessGuid?: string
  ) => BaseAPI.performAjax<Question_[]>({
    method: 'GET',
    // eslint-disable-next-line max-len
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/GetQDApplicationEvaluatedQuestions?qdApplicationGuid=${
      guarantorGuid ? '' : qdApplicationGuid
    }&guarantorGuid=${guarantorGuid ?? ''}&primaryPersonalGuid=${primaryPersonalGuid ?? ''}&primaryBusinessGuid=${primaryBusinessGuid ?? ''}`,
  });

  static saveQDPartyDisclosures = (disclosures: Borrower_) => BaseAPI.performAjax<Disclosure_[]>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDPartyDisclosuresSubmit`,
    data: disclosures,
  });

  static evaluate = (idApplication, currentSection, holder, qdPartyGUID) => BaseAPI.performAjax<QdEvalResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/Eval`,
    data: {
      IdApplication: idApplication || 0,
      CurrentSection: currentSection,
      Holder: (idApplication || 0) <= 0 ? holder : null,
      QdPartyGUID: qdPartyGUID || '',
      ApplicationTypeAnswer: (holder as QdApplicationHolder_)?.Information?.ApplicationTypeAnswer,
    },
  });

  static identityCheckEval = (section: IdentityCheckEvalParam_) => BaseAPI.performAjax<QDEvaluationIdentityResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/EvalIdentitySection`,
    data: section,
  });

  static evalIdentityCheckCondition = (idQDApplication: BigInt, guidQDApplication: string, idQDApplicationModel: BigInt, guidQdParty?: string) => {
    const pars = {
      IdQDApplication: idQDApplication,
      Holder: {
        IdQDApplicationModel: idQDApplicationModel,
        GUIDQDApplication: guidQDApplication,
      },
      QDPartyGUID: guidQdParty ?? '',
    };
    return BaseAPI.performAjax<boolean>({
      method: 'POST',
      url: `${BaseAPI.BASE_URL}api/QuickDecisionService/EvalIdentityCheckCondition`,
      data: pars,
    });
  };

  static evalSectionSubSections = (section: Section_) => BaseAPI.performAjax<bigint[]>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/EvalSectionSubSections`,
    data: section,
  });

  static qdPartyMatch = (partyMatchInfo: QDPartyMatchPars_) => BaseAPI.performAjax<QDPartyMatchResult_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDPartyMatch`,
    data: partyMatchInfo,
  });

  static inquireCoBorrower = (pars: CoBorrowerPars_) => BaseAPI.performAjax<QDCOBorrowerHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/InquireCoBorrower`,
    data: pars,
  });

  static saveCoBorrower = (holder: QDCOBorrowerHolder_) => BaseAPI.performAjax<QDCOBorrowerHolder_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveCoBorrower`,
    data: holder,
  });

  static submitGuarantor = (qdPartyGuid: string) => BaseAPI.performAjax<any>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/GuarantorSubmit`,
    data: qdPartyGuid,
  });

  static saveQDAddress = (address: QDAddress_) => BaseAPI.performAjax<QDAddress_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDAddress`,
    data: address,
  });

  static removeQDAddress = (idAddress: BigInt) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveQDAddress`,
    data: idAddress,
  });

  static saveQDBusinessReference = (businessReference: Reference_) => BaseAPI.performAjax<Reference_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDBusinessReference`,
    data: businessReference,
  });

  static removeQDBusinessReference = (idBusinessReference: BigInt) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveQDBusinessReference`,
    data: idBusinessReference,
  });

  static saveQDCollateral = (collateral: Collateral_) => BaseAPI.performAjax<Collateral_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDCollateral`,
    data: collateral,
  });

  static removeQDCollateral = (idCollateral: BigInt) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveQDCollateral`,
    data: idCollateral,
  });

  static saveQDDebt = (debt: QDDebt_) => BaseAPI.performAjax<QDDebt_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDDebt`,
    data: debt,
  });

  static removeQDDebt = (idDebt: BigInt) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveQDDebt`,
    data: idDebt,
  });

  static saveQDDeposit = (debt: QDDeposit_) => BaseAPI.performAjax<QDDeposit_>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/SaveQDDeposit`,
    data: debt,
  });

  static removeQDDeposit = (idDeposit: BigInt) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveQDDeposit`,
    data: idDeposit,
  });

  static removeCoBorrower = (IdQDCoBorrower: BigInt, IdQDApplication: BigInt, GUIDQDCoBorrower: string) => BaseAPI.performAjax({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/RemoveCoBorrower`,
    data: { IdQDCoBorrower, IdQDApplication, GUIDQDCoBorrower, },
  });

  static updateQDPartyStatus = (qdPartyGUID: string, newStatus: QDPartyStatus_) => BaseAPI.performAjax<void>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDPartyUpdateStatus`,
    data: { Guid: qdPartyGUID, QDPartyStatus: newStatus, },
  });

  static declineCoBorrowerApplication = (qdPartyGUID: string) => BaseAPI.performAjax<void>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/DeclineQDCoBorrowerApplication`,
    data: { Guid: qdPartyGUID, },
  });

  static storeApplicationLog = (qdActionLog: ActionLog_) => BaseAPI.performAjax<boolean>({
    method: 'POST',
    url: `${BaseAPI.BASE_URL}api/QuickDecisionService/QDActionLogCreate`,
    data: qdActionLog,
  });
}
