import { Reducer as Reducer_ } from 'redux';
import { IdentityState as IdentityState_, IdentityActions as IdentityActions_ } from '../actions/Identity';
import QDEvaluationIdentityResult_ from '../../data/models/QDEvaluationIdentityResult';

const identityReducer: Reducer_<IdentityState_, IdentityActions_> = (
  state = {
    IdentityHolder: new QDEvaluationIdentityResult_(),
    IsIdentityFetched: false,
    IdentityVisited: false,
  },
  action
) => {
  switch (action.type) {
    case 'GetIdentity': {
      return { ...action.identityState, };
    }
    case 'SetIdentity': {
      return { ...action.identityState, };
    }
    default:
      return state;
  }
};

export default identityReducer;
