import BaseAPI from './BaseApi';
import { getToken } from '../../utils/LocalStorageManager';
import DownloadedDocumentResult_ from '../models/DownloadedDocumentResult';

export default class DocumentAPI extends BaseAPI {
  static downloadDisclosure = (guid: string, isGuarantor: boolean) => {
    const token = getToken();
    return BaseAPI.performAjax<DownloadedDocumentResult_>({
      url: `${BaseAPI.BASE_URL}api/DisclosureDownload/DownloadDocument?disclosureGuid=${guid}&token=${token}&isGuarantor=${isGuarantor}`,
    });
  };
}
