import { Reducer as Reducer_ } from 'redux';
import { EvalSectionState as EvalSectionState_, EvalSectionActions as EvalSectionActions_ } from '../actions/VisibleSections';

const evalSectionReducer: Reducer_<EvalSectionState_, EvalSectionActions_> = (
  state = {
    IsVisibleSectionsFetched: false,
    IsReviewed: false,
    IsVisibleSectionsFetching: false,
    VisibleSections: [],
    CoBorrowerAddNowVisibleSections: [],
  },
  action
) => {
  switch (action.type) {
    case 'EvalSection': {
      return { ...action.evalSectionState, };
    }
    default:
      return state;
  }
};

export default evalSectionReducer;
