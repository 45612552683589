/* eslint-disable no-undef */
import React, { FormEvent as FormEvent_, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Section } from '@jkhy/vsg-design-system';
import DOMPurify from 'dompurify';
import LoanOptions from '../../../../components/LoanOptions/LoanOptions';
import { setLoader } from '../../../../redux/actions/Loading';
import { getGuid } from '../../../../utils/LocalStorageManager';
import { ExternalPages, URLValidationConstants } from '../../../../utils/Enums';
import { PrequalificationPages, PrequalificationSectionName } from '../../../../utils/prequalification/Enums';
import { AppState as AppState_ } from '../../../../redux/AppState';
import QdApplicationSection_ from '../../../../data/models/QDApplicationSection';
import QdPrequalificationApi from '../../../../data/api/QDPrequalificationApi';
import { PrequalificationModelState as PrequalificationModelState_ } from '../../../../redux/actions/PrequalificationModel';
import LoanOption_ from '../../../../data/models/QDLoanOption';
import { informationMsg } from '../../../../utils/Helper';
import Messages from '../../../../utils/Messages';

export const PREQUALIFICATION_QUALIFIES_SECTION_DATA_UI = 'prequalification-qualifies-section';

const PrequalificationResultsQualifies = () => {
  const [loanOptions, setLoanOptions] = useState<LoanOption_[]>([]);

  const {
    isLoading,
    prequalificationModelState: { ModelHolder, },
  } = useSelector<
    AppState_,
    {
      isLoading: boolean;
      prequalificationModelState: PrequalificationModelState_;
    }
  >(state => ({
    isLoading: state.loading,
    prequalificationModelState: state.prequalificationModelState,
  }));

  const dispatch = useDispatch();

  const model = ModelHolder.Model;
  const section = ModelHolder.Sections.find(({ SectionName, }: QdApplicationSection_) => SectionName === PrequalificationSectionName.Qualifies);
  const [subSection] = section?.SubSections || [];

  useEffect(() => {
    const getLoanOptions = async () => {
      dispatch(setLoader(true));

      const guid = getGuid();
      const { Result, Success, } = await QdPrequalificationApi.inquireLoanOptions(guid);
      if (Success) {
        setLoanOptions(Result);
      }

      dispatch(setLoader(false));
    };

    getLoanOptions();
  }, [dispatch]);

  const handleSubmit = async (e: FormEvent_<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(setLoader(true));

    const guid = getGuid();
    const { Result: GUIDQDApplication, Success, } = await QdPrequalificationApi.continueWithApplication(guid);

    if (Success) {
      // Change application type
      const { APP_NAME, } = window;
      const url = `${APP_NAME}/section/${model.Code}/${ExternalPages.Welcome}/${GUIDQDApplication}/`;
      const sanitizedURL = DOMPurify.sanitize(url);
      if (URLValidationConstants.PrequalificationResultQualifies.test(sanitizedURL)) {
        window.location.replace(url);
      } else {
        informationMsg({ Title: Messages.INVALID_URL }, null);
      }
    }

    dispatch(setLoader(false));
  };

  return (
    <>
      {!isLoading && (
        <form id={`form-${PrequalificationPages.Qualifies}`} onSubmit={handleSubmit}>
          <Section
            dataUI={PREQUALIFICATION_QUALIFIES_SECTION_DATA_UI}
            className="mb-3"
            title={subSection?.SubSectionName}
            headerText={subSection?.SubSectionHeaderText}
            footerText={subSection?.SubSectionFooterText}
          >
            {loanOptions.length > 0 && <LoanOptions loanOptions={loanOptions} />}

            {model.IdEmailTemplatePrequalificationResult && (
              <Alert
                dataUI={`${PREQUALIFICATION_QUALIFIES_SECTION_DATA_UI}-alert-email-notification`}
                className="mb-3"
                type="info"
                text="A copy of these offers will be emailed to you."
              />
            )}
          </Section>
        </form>
      )}
    </>
  );
};

export default PrequalificationResultsQualifies;
