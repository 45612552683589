/* eslint no-console: "off" */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import userManager from '../../utils/UserManager';
import CallbackComponent from './Callback';
import RedirectPage from './Redirect';
import { AppState as AppState_ } from '../../redux/AppState';
import { attachBrandingIcon, attachCustomCss, changeDocumentTitle } from '../../utils/Helper';
import {
  setUser,
  getIdInvitation,
  getInvitationModel,
  getGuid,
  getModel,
  getGuarantorGuid,
  getApplicationType
} from '../../utils/LocalStorageManager';
import { ApplicationTypes, ExternalPages, GuarantorPages } from '../../utils/Enums';
import { getPrequalificationUrl } from '../../utils/prequalification/Navigation';
import { PrequalificationPages } from '../../utils/prequalification/Enums';

class TokenCallback extends React.Component<any, any, any> {
  constructor(props) {
    super(props);
    this.successCallback.bind(this);
    this.errorCallback.bind(this);
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    const browserTabTitle = this.props.modelState?.ModelHolder?.Model?.BrawserTabTitle;
    changeDocumentTitle(browserTabTitle, 'Token Callback');
  }

  successCallback = authInfo => {
    setUser({ ...authInfo, });
    const idInvitation = getIdInvitation();
    const { history, } = this.props;

    const modelSessionKey = getModel();
    const guidSessionKey = getGuid();
    const guarantorGuid = getGuarantorGuid();

    const applicationType = getApplicationType();
    let url = '';

    if (applicationType === ApplicationTypes.Prequalification) {
      const sequence = guidSessionKey ? PrequalificationPages.Prequalification : null;
      url = getPrequalificationUrl(modelSessionKey, sequence, guidSessionKey);
    } else if (applicationType === ApplicationTypes.External) {
      url = `section/${modelSessionKey}/${ExternalPages.PurposeProduct}/`; // Create

      if (!!guarantorGuid && guarantorGuid !== 'undefined') url = `/guarantor/${modelSessionKey}/${GuarantorPages.Welcome}/${guarantorGuid}/`;
      if (!!guidSessionKey && guidSessionKey !== 'undefined') url = `/${ExternalPages.PurposeProduct}/${modelSessionKey}/${guidSessionKey}/`;
    } else if (applicationType === ApplicationTypes.Renewal) {
      const renewalModel = getInvitationModel();
      url = `/renewal/${renewalModel}/${idInvitation}`;
    }

    history.push(url);

    attachCustomCss(modelSessionKey);
    attachBrandingIcon(modelSessionKey);
  };

  errorCallback = error => {
    console.error(error);
  };

  render() {
    return (
      <CallbackComponent successCallback={this.successCallback} errorCallback={this.errorCallback} userManager={userManager}>
        <RedirectPage />
      </CallbackComponent>
    );
  }
}

export default connect((state: AppState_) => ({ ...state, }))(withRouter(TokenCallback) as any);
