import { Action as Action_ } from 'redux';

export const setCoborrowerSubmitted = (isCoBorrowerSubmitted: boolean): CoBorrowerSubmitAction => {
  return {
    isCoBorrowerSubmitted,
    type: 'Submitted',
  };
};

export interface CoBorrowerSubmitAction extends Action_<'Submitted'> {
  isCoBorrowerSubmitted: boolean;
}
