import { Reducer as Reducer_ } from 'redux';
import {
  CoBorrowerIdentityState as CoBorrowerIdentityState_,
  CoBorrowerIdentityActions as CoBorrowerIdentityActions_
} from '../actions/CoBorrowerIdentity';
import QDEvaluationIdentityResult_ from '../../data/models/QDEvaluationIdentityResult';

const coBorrowerIdentityReducer: Reducer_<CoBorrowerIdentityState_, CoBorrowerIdentityActions_> = (
  state = {
    IdentityHolder: new QDEvaluationIdentityResult_(),
    IsIdentityFetched: false,
    IdentityVisited: false,
  },
  action
) => {
  switch (action.type) {
    case 'GetCoBorrowerIdentity': {
      return { ...action.coBorrowerIdentityState, };
    }
    case 'SetCoBorrowerIdentity': {
      return { ...action.coBorrowerIdentityState, };
    }
    case 'ResetCoBorrowerIdentity': {
      return { ...action.coBorrowerIdentityState, };
    }
    default:
      return state;
  }
};

export default coBorrowerIdentityReducer;
