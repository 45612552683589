import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { UserState as UserState_ } from 'redux-oidc';
import { Select } from '@jkhy/vsg-design-system';
import PageField_ from '../../data/models/PageField';
import ListApi, { LtPars as LtPars_, EnumPars as EnumPars_ } from '../../data/api/ListApi';

import { AppState as AppState_ } from '../../redux/AppState';
import { ValueConstants } from '../../utils/Enums';

export interface PickListProps {
  id: string;
  oidc: UserState_;
  initialData: { value: string; label: string }[];
  value: string;
  pageField?: PageField_;
  ListName: string;
  disabled: boolean;
  stateLess: boolean;
  ListType: 'Custom' | 'LtItems' | 'Enums';
  onChange(e: React.ChangeEvent, pageField: PageField_): void;
  className?: string;
  dataUI?: string;
  hideSelectOption?: boolean;
  dsSelectWrapper?: any;
  // Descrip is a description without Code value (Code is optional)
  useDescrip?: boolean;
}

export interface Filter {
  field: string;
  operator: string;
  value: string;
}

export interface PickListState {
  selectOptions: { value: string; label: string }[];
  isLoading: boolean;
}

class PickList extends React.Component<PickListProps, PickListState, any> {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: props.initialData,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.loadListData();
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = () => {
      return {};
    };
  }

  filterData: any = (items: any, filter: Filter) => {
    return filter.operator === 'eq'
      ? items.filter(item => item[filter.field] === filter.value)
      : items.filter(item => item[filter.field] !== filter.value);
  };

  checkValueType = (items, value) => {
    if (items.length === 0) return value;

    const firstOptionType = typeof items[0].value;
    return firstOptionType === 'string' && typeof value !== 'string' ? `${value}` : value;
  };

  loadPickList() {
    const { ListName, initialData, useDescrip, } = this.props;
    const pars: LtPars_ = {
      listName: ListName,
      sortColumn: '',
      sortOrder: 'ASC',
    };

    let filter: Filter;
    if (pars.listName === 'ReferralSource') {
      filter = { field: 'ThirdCode', operator: 'eq', value: 'QD', };
    }
    if (pars.listName === 'Officer') {
      filter = { field: 'SecondCode', operator: 'eq', value: 'OL', };
    }
    if (pars.listName === 'QDRelationship') {
      filter = { field: 'SecondCode', operator: 'neq', value: '0', }; // 0 represent Borrower
    }
    ListApi.getLtItemsSearch(pars).then(response => {
      const data = filter ? this.filterData(response.Result, filter) : response.Result;

      const ltItems = data.map(item => ({
        value: item.Code,
        label: useDescrip ? item.Descrip : item.Description,
      }));

      this.setState({
        isLoading: false,
        selectOptions: initialData.concat(ltItems),
      });
    });
  }

  loadEnums() {
    const { ListName, initialData, } = this.props;

    let filter: Filter;
    const pars: EnumPars_ = {
      enumQualifiedName: '',
      ignoreList: '',
    };

    if (ListName === 'QDApplicationSection') {
      pars.enumQualifiedName = 'PublicModels.QDApplicationSection, PublicModels';
    }
    if (ListName === 'FilePartyType') {
      pars.enumQualifiedName = 'Intellibanc.IbClue.Files.FilePartyType, Intellibanc.IbClue';
    }
    if (ListName === 'PartyType') {
      pars.enumQualifiedName = 'PublicModels.PartyType, PublicModels';
    }
    if (ListName === 'PhoneType') {
      pars.enumQualifiedName = 'PublicModels.PhoneType, PublicModels';
    }
    if (ListName === 'QDReferenceType') {
      pars.enumQualifiedName = 'PublicModels.QDReferenceType, PublicModels';
    }
    if (ListName === 'QDDepositType') {
      pars.enumQualifiedName = 'PublicModels.QDDepositType, PublicModels';
    }
    if (ListName === 'QDAnswerType') {
      pars.enumQualifiedName = 'PublicModels.QDAnswerType, PublicModels';
    }
    if (ListName === 'QDRequiredDocStatus') {
      pars.enumQualifiedName = 'PublicModels.QDRequiredDocStatus, PublicModels';
    }
    ListApi.getEnumValues(pars).then(response => {
      let data = response.Result;
      if (filter) {
        data = this.filterData(data, filter);
      }
      let enumItems = data.map(item => ({
        value: item.Value,
        label: item.Name,
      }));
      enumItems = _.sortBy(enumItems, item => item.label);
      enumItems = initialData.concat(enumItems);

      this.setState({
        isLoading: false,
        selectOptions: enumItems,
      });
    });
  }

  loadCustomList() {
    const { initialData, } = this.props;
    // use ListName from props to inquire custom data, using ajax method in api
    this.setState({ selectOptions: initialData, isLoading: false, });
  }

  loadListData() {
    const { ListType, stateLess, } = this.props;
    if (!stateLess) this.setState({ isLoading: true, });

    if (ListType === 'LtItems') {
      this.loadPickList();
    }
    if (ListType === 'Enums') {
      this.loadEnums();
    }
    if (ListType === 'Custom') {
      this.loadCustomList();
    }
  }

  render = () => {
    const {
      id, value, onChange, pageField, disabled, stateLess, initialData, className, hideSelectOption,
    } = this.props;
    const { selectOptions, isLoading, } = this.state;
    const selectOptionsTemp = stateLess ? initialData : selectOptions;
    const val = this.checkValueType(selectOptionsTemp, value);

    return (
      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        id={id}
        dataUI={this.props?.dataUI ?? pageField?.FieldName}
        isLoading={isLoading}
        options={selectOptionsTemp}
        disabled={disabled}
        value={val}
        placeholder={hideSelectOption ? null : ValueConstants.DropDownDefaultValue.toString()}
        onChange={e => onChange.call(this, e, pageField)}
        className={className}
      />
    );
  };
}

export default connect((state: AppState_) => ({ oidc: state.oidc, }))(PickList);
