/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  FC as FC_, useState, useEffect, useMemo
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  FormGroup,
  MaskedInput,
  Radio,
  Section,
  Select,
  IconsSolid,
  ScrollableContainer,
  Checkbox,
  FormSection,
  ReactAux,
  Alert,
  Tooltip
} from '@jkhy/vsg-design-system';
import { partition } from 'lodash';
import { ISaveHolder as ISaveHolder_ } from '../../../redux/actions/Holder';
import PageFieldExtended_ from '../Page/PageHelpers/PageFieldExtended';
import { AppState as AppState_ } from '../../../redux/AppState';
import { setInformationDisclosures } from '../../../redux/actions/Disclosures';
import { ModelState as ModelState_, ExternalModelHolder as ExternalModelHolder_ } from '../../../utils/Types';
import {
  ApplicationTypes,
  CustomerType,
  DisplayOptions, ExternalPages, ExternalPageSectionName, ValueConstants
} from '../../../utils/Enums';
import QdApplicationHolder_ from '../../../data/models/QDApplicationHolder';
import InformationModel_, { QDApplicationTypeAnswer } from '../../../data/models/Information';
import QuestionTemplate from '../../../components/Question/QuestionTemplate';
import PickList from '../../../components/PickList/PickList';
import {
  getHoverHelpPageFieldValue, scrollToError, htmlParser, errorFoundMessage
} from '../../../utils/Helper';
import { validateQuestionAndSetValidationMessage } from '../../../components/Question/QuestionValidate';
import InformationSettings from './InformationSettings';
import QdServiceApi from '../../../data/api/QDServiceApi';
import { getGuid } from '../../../utils/LocalStorageManager';
import ListAPI from '../../../data/api/ListApi';
import Messages from '../../../utils/Messages';
import Branch_ from '../../../data/models/Branch';
import QDPartyDisclosure_ from '../../../data/models/QDPartyDisclosure';
import Question_ from '../../../data/models/Question';
import { setLoader as setLoaderAction } from '../../../redux/actions/Loading';
import {
  checkAreAllDocumentsOpened,
  checkHasDisclosures,
  checkHasPartyAgreedEarlier,
  checkIsDisclosuresHasEmailTemplate,
  DisclosuresDocuments as DisclosuresDocuments_
} from '../../../utils/Disclosures';
import DisclosuresDocuments from '../../../components/DisclosuresDocuments/DisclosuresDocuments';
import { getBranchQuestionOptions } from '../../../utils/Declarations';
import QdApplicationSection from '../../../data/models/QDApplicationSection';

export interface InformationProps {
  onSubmit: (
    invalidPageFiels: PageFieldExtended_<QdApplicationHolder_, InformationModel_>[],
    holder: QdApplicationHolder_,
    section: InformationModel_,
    sequence: string,
    fromGuarnator: boolean
  ) => void;
}
type QDCountyCachedResultType = {
  value: string,
  label: string,
  secondCode: string,
};
let QDCountyCachedResult: QDCountyCachedResultType[];

const Information: FC_<InformationProps> = (props: InformationProps) => {
  const { holder, loading, modelState, } = useSelector<
    AppState_,
    {
      holder: QdApplicationHolder_;
      loading: boolean;
      modelState: ModelState_<ExternalModelHolder_>;
    }
  >(state => ({
    holder: state.holderState?.Holder,
    loading: state.loading,
    modelState: state.modelState,
  }));
  const [information, setInformation] = useState(holder.Information);
  const [invalidSetOfPageFields, setInvalidSetOfPageFields] = useState<PageFieldExtended_<QdApplicationHolder_, InformationModel_>[]>([]);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const [countyCodesLoading, setCountyCodesLoading] = useState(true);
  const [listBranches, setListBranches] = useState<Branch_[]>([]);
  const [listCounties, setListCounties] = useState([]);
  const [agreedDisclosuresFlag, setAgreedDisclosuresFlag] = useState(false);
  const [disclosureParties, setDisclosureParties] = useState(information?.QDPartyGuids);
  const [disclosuresDocuments, setDisclosuresDocuments] = useState<DisclosuresDocuments_>({});
  const [showDisclosuresErrorMessage, setShowDisclosuresErrorMessage] = useState(false);

  const { ApplicationSections: sections, ApplicationPageField: pageFieldHolder, Model: model, } = modelState?.ModelHolder;
  const section = sections.find(s => s.SectionName === ExternalPageSectionName.InformationMembership);
  const [declarationSubSection] = section.SubSections.filter(el => el.Code === ValueConstants.InformationDeclarationsSubSection);
  const [disclosureSubSection] = section.SubSections.filter(el => el.Code === ValueConstants.InformationDisclosureSubSection);
  const { Personal: borrower, } = holder?.BorrowerP;

  const hasDisclosures = checkHasDisclosures(information?.Disclosures, disclosuresDocuments);
  const hideApplicationTypeQuestion = model.QDModelTypeEnumStr !== ApplicationTypes.External || model.CustomerType !== CustomerType.Commercial;

  const dispatch = useDispatch();

  const updateHolder = (modifiedInformation?: InformationModel_) => {
    const {
      ESignConsent, StateBusinessLocated, StateBusinessLocatedStr, ZipBusinessLocated,
    } = modifiedInformation ?? information;

    const declarationHolder = { ...holder?.Declarations, ESignConsent, };
    const business = {
      ...holder?.BorrowerB?.Business,
      StateBusinessLocated,
      StateBusinessLocatedStr,
      ZipBusinessLocated,
    };
    const borrowerB = { ...holder?.BorrowerB, Business: business, };
    const saveHolderAction: ISaveHolder_ = {
      holderState: {
        Holder: {
          ...holder,
          Information: modifiedInformation ?? information,
          Declarations: declarationHolder,
          BorrowerB: borrowerB,
        },
        IsHolderFetched: true,
      },
      type: 'SaveHolder',
    };

    dispatch(saveHolderAction);
  };

  const onChangeQuestion = (value: string | number | boolean, prop: string, id?: bigint) => {
    const { Questions, } = information;

    const mappedQuestions = (Questions || []).map(q_ => {
      const q = q_;
      if (q.Id === id) {
        q[prop] = value;
      }

      return q;
    });

    setInformation({ ...information, Questions: mappedQuestions, });
  };

  const setCountyData = locationOfBusinessCode => {
    const filterResult = QDCountyCachedResult.filter(ltItem => ltItem.secondCode === locationOfBusinessCode);
    if (filterResult.length !== 0) {
      setListCounties(filterResult);
    } else {
      setListCounties([]);
    }
  };

  const onChange = (e: any, updatedObject: string) => {
    const { value, label, } = e;
    const propertyName = updatedObject.toString();
    if (e.target?.value === 'true' || e.target?.value === 'false') {
      information[propertyName] = e.target.value === 'true';
    } else {
      information[propertyName] = e.target?.value ? e.target?.value : value;
      const questionArray = ['OfficerQuestionCode', 'BranchQuestionCode', 'StateBusinessLocated', 'CountyCode'];
      if (questionArray.includes(propertyName)) {
        information[`${propertyName}Str`] = label === ValueConstants.DropDownDefaultValue ? '' : label;
      }
    }

    // Convert control value type to numeric value of enum item
    // Fill string representation field
    if (propertyName === 'ApplicationTypeAnswer') {
      const valAsStr = e.target?.value ? e.target?.value : value;
      information[propertyName] = parseInt(valAsStr, 10);
      let text = label;
      if (!label) {
        text = information[propertyName] === QDApplicationTypeAnswer.Business ? 'Business' : 'Individual';
      }
      information[`${propertyName}EnumStr`] = text;
    }

    if (propertyName === 'StateBusinessLocated') {
      setCountyData(value);
      information.CountyCode = '';
      information.CountyCodeStr = '';
    }
    setInformation({ ...information, });
  };

  const agreedToDisclosure = useMemo(() => {
    const result = checkHasPartyAgreedEarlier(borrower.GUID, disclosureParties);
    if (result !== agreedDisclosuresFlag) {
      setAgreedDisclosuresFlag(result);
    }

    return result;
  }, [disclosureParties, borrower.GUID]);

  useEffect(() => {
    const getDisclosuresAndQuestions = async () => {
      dispatch(setLoaderAction(true));

      const guidKey = getGuid();
      const {
        BorrowerP: {
          Personal: { GUID: personalGuid, },
        },
      } = holder;

      const {
        Result: { ConcatenatedDisclosures, Parties, PdfDisclosures, },
      } = await QdServiceApi.loadQDApplicationDisclosures(guidKey, section.Id);
      const { Result: questionsListResult, } = await QdServiceApi.qdApplicationEvaluatedQuestions(guidKey, '', personalGuid);
      const questionsListBorrower = questionsListResult
        .filter((q: Question_) => q.ApplicableForBorrower && !q.IsHidden && q.SectionName === section.SectionName)
        .map((q: Question_) => ({ ...q, NullFields: [], }));

      setDisclosureParties(Parties);
      setInformation({ ...information, Disclosures: ConcatenatedDisclosures, Questions: questionsListBorrower, });

      const hasInformationDisclosures = !!ConcatenatedDisclosures || PdfDisclosures.length > 0;
      dispatch(setInformationDisclosures({ hasDisclosures: hasInformationDisclosures, isFetched: true, }));

      if (PdfDisclosures.length > 0) {
        const newDisclosuresDocuments: DisclosuresDocuments_ = {};
        const hasAgreed = checkHasPartyAgreedEarlier(personalGuid, Parties);

        PdfDisclosures.forEach(disclosure => {
          const { GUID: disclosureGuid, Name, } = disclosure;

          newDisclosuresDocuments[disclosureGuid] = {
            isOpened: hasAgreed,
            name: Name,
            documentBytes: '',
          };
        });

        setDisclosuresDocuments(newDisclosuresDocuments);
      }

      dispatch(setLoaderAction(false));
    };

    getDisclosuresAndQuestions();
  }, []);

  useEffect(() => {
    const getListBranches = async () => {
      const { Result: listBranchesResult, } = await ListAPI.getBranches();
      setListBranches(listBranchesResult);
      setBranchesLoading(false);
    };
    getListBranches();
  }, [listBranches.length]);

  useEffect(() => {
    const pars = {
      listName: 'QDCounty',
      sortColumn: '',
      sortOrder: 'ASC',
    };
    ListAPI.getLtItemsSearch(pars).then(response => {
      const data = response.Result;
      QDCountyCachedResult = data.map(item => ({
        value: item.Code,
        label: item.Description,
        secondCode: item.SecondCode,
      }));
      if (information.StateBusinessLocated) {
        setCountyData(information.StateBusinessLocated);
      }
      setCountyCodesLoading(false);
    });
  }, []);

  const informationSectionHolder = pageFieldHolder?.Information?.General;
  const disclosuresHTML = htmlParser(information?.Disclosures);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // pagefields validation
    const informationSettings = new InformationSettings<QdApplicationHolder_, InformationModel_>([pageFieldHolder?.Information?.General], null, null);

    let modifiedInformation = { ...information, };

    let isDeclarationQuestionsValid = true;
    let isDisclosuresValid = true;
    let isEsignValid = true;

    // Declarations
    if (declarationSubSection?.DisplayOption === DisplayOptions.Always) {
      // The validation of the page field questions should only be active when the declarations are displayed
      const [invalidSetOfPF] = partition(informationSettings.PageFields, pf => !pf.IsValid(holder, information, pf));
      setInvalidSetOfPageFields(invalidSetOfPF);

      let isModifiedQuestionsValid = true;

      // Questions from declarations in information wizard
      if (information?.Questions?.length > 0) {
        // set the message for questions
        const modifiedQuestions = (information.Questions || []).map(q => {
          const { IsValid, ValidationMessage: validationMessage, } = validateQuestionAndSetValidationMessage(q);

          const qTemp = { ...q, IsValid, ValidationMessage: validationMessage, };

          return qTemp;
        });

        modifiedInformation = { ...information, Questions: modifiedQuestions, };
        setInformation(modifiedInformation);

        isModifiedQuestionsValid = modifiedQuestions.every(question => question.IsValid);
      }

      isDeclarationQuestionsValid = isModifiedQuestionsValid && invalidSetOfPF.length === 0;

      // eSign consent message
      const { ESignConsent: ESignConsentPageField, } = informationSectionHolder;

      if (!ESignConsentPageField?.IsHidden) {
        // information?.ESignConsent value can be null, false or true
        if (information?.ESignConsent === false || (ESignConsentPageField?.Required && !information?.ESignConsent)) {
          isEsignValid = false;
        }
      }
    }

    // Disclosures
    if (disclosureSubSection?.DisplayOption === DisplayOptions.Always && hasDisclosures) {
      isDisclosuresValid = agreedDisclosuresFlag === true;
      if (!isDisclosuresValid) {
        setShowDisclosuresErrorMessage(true);
      }
    }

    // questions, ESingConsent, disclosures and pageFields
    if (!isDeclarationQuestionsValid || !isEsignValid || !isDisclosuresValid) {
      setTimeout(() => {
        scrollToError(document.getElementsByClassName('validation-msg')[0] as HTMLElement);
      }, 300);
      return;
    }

    updateHolder(modifiedInformation);
    props.onSubmit(invalidSetOfPageFields, holder, modifiedInformation, ExternalPages.InformationMembership, false);
  };

  const onAgreedDisclosureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, } = e.currentTarget;
    const { GUID, Email, } = borrower;

    const partyDisclosure: QDPartyDisclosure_ = { PartyGuid: GUID, AgreedEarlier: true, PartyEmail: Email, };
    const modifiedInformation = {
      ...information,
      InformationDisclosuresSectionId: section.Id,
      QDPartyGuids: [partyDisclosure],
    };
    setInformation(modifiedInformation);
    setAgreedDisclosuresFlag(checked);
    if (checked) {
      setShowDisclosuresErrorMessage(false);
    }
  };

  const validationCheck = (pageFieldName: string) => {
    const [isInvalid] = invalidSetOfPageFields.filter(el => el.FieldName === pageFieldName);
    return !isInvalid;
  };

  const areAllDocumentsOpened = checkAreAllDocumentsOpened(disclosuresDocuments);
  const hasDisclosuresDocuments = disclosuresDocuments && Object.keys(disclosuresDocuments).length > 0;

  return (
    <>
      {!loading && (
        <form id={`form-${ExternalPages.InformationMembership}`} onSubmit={onSubmit} noValidate>
          <Section
            title={section.PageHeader}
            dataUI={`${section.SectionName}-section`}
            headerText={section.SectionHeaderHTML}
            footerText={section.SectionFooterHTML}
            className="mb-3"
          >
            {declarationSubSection?.DisplayOption === DisplayOptions.Always && (
              <FormSection className="mb-3" dataUI="information-declaration-section">
                <h3 className="mb-2">{declarationSubSection.SubSectionName}</h3>

                {(information?.Questions || []).map((question: Question_) => (
                  <QuestionTemplate key={`${question.Id}`} question={question} onChange={onChangeQuestion} />
                ))}

                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.ApplicationTypeQuestion?.IsBold}
                  isHidden={informationSectionHolder?.ApplicationTypeQuestion?.IsHidden || hideApplicationTypeQuestion}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.ApplicationTypeQuestion)}
                  label={informationSectionHolder?.ApplicationTypeQuestion?.Label}
                  // Required option could be ignored.
                  // Based on BE logic field has default selection of Business, so it has always value
                  isRequired={informationSectionHolder?.ApplicationTypeQuestion?.Required}
                  dataUI="declaration-application-type-form-group"
                  checkboxOrRadio
                >
                  <Radio
                    name="ApplicationTypeQuestion"
                    className="d-inline-block"
                    value={QDApplicationTypeAnswer.Business}
                    checked={information.ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Business.toString()}
                    onChange={e => {
                      onChange(e, 'ApplicationTypeAnswer');
                    }}
                    dataUI="declaration-application-type-radio-business"
                    htmlFor={`${informationSectionHolder?.ApplicationTypeQuestion?.FieldName}-Business`}
                    id={`${informationSectionHolder?.ApplicationTypeQuestion?.FieldName}-Business`}
                  >
                    Business
                  </Radio>
                  <Radio
                    name="ApplicationTypeQuestion"
                    className="d-inline-block"
                    value={QDApplicationTypeAnswer.Individual}
                    checked={information.ApplicationTypeAnswer?.toString() === QDApplicationTypeAnswer.Individual.toString()}
                    onChange={e => {
                      onChange(e, 'ApplicationTypeAnswer');
                    }}
                    dataUI="declaration-application-type-radio-individual"
                    htmlFor={`${informationSectionHolder?.ApplicationTypeQuestion?.FieldName}-Individual`}
                    id={`${informationSectionHolder?.ApplicationTypeQuestion?.FieldName}-Individual`}
                  >
                    Individual
                  </Radio>
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.BranchQuestion?.IsBold}
                  isHidden={informationSectionHolder?.BranchQuestion?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.BranchQuestion)}
                  label={informationSectionHolder?.BranchQuestion?.Label}
                  isRequired={informationSectionHolder?.BranchQuestion?.Required}
                  dataUI="information-branch-question-form-group"
                  htmlFor={informationSectionHolder?.BranchQuestion?.FieldName}
                  isValid={validationCheck(informationSectionHolder?.BranchQuestion?.FieldName)}
                  validationMessage={errorFoundMessage('BranchQuestion', invalidSetOfPageFields)}
                >
                  <Select
                    isLoading={branchesLoading}
                    placeholder={ValueConstants.DropDownDefaultValue.toString()}
                    options={listBranches.map((branch: Branch_) => getBranchQuestionOptions(branch))}
                    value={information.BranchQuestionCode}
                    onChange={e => {
                      onChange(e, 'BranchQuestionCode');
                    }}
                    dataUI="information-branch-question-state-code-select"
                    id={informationSectionHolder?.BranchQuestion?.FieldName}
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.OfficerQuestion?.IsBold}
                  isHidden={informationSectionHolder?.OfficerQuestion?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.OfficerQuestion)}
                  label={informationSectionHolder?.OfficerQuestion?.Label}
                  isRequired={informationSectionHolder?.OfficerQuestion?.Required}
                  dataUI="information-officer-question-form-group"
                  htmlFor={informationSectionHolder?.OfficerQuestion?.FieldName}
                  isValid={validationCheck(informationSectionHolder?.OfficerQuestion?.FieldName)}
                  validationMessage={errorFoundMessage('OfficerQuestion', invalidSetOfPageFields)}
                >
                  <PickList
                    dsSelectWrapper
                    id={informationSectionHolder?.OfficerQuestion?.FieldName}
                    stateLess={false}
                    initialData={[]}
                    value={information.OfficerQuestionCode}
                    disabled={informationSectionHolder?.OfficerQuestion?.Disabled}
                    onChange={e => {
                      onChange(e, 'OfficerQuestionCode');
                    }}
                    ListName="Officer"
                    ListType="LtItems"
                    pageField={informationSectionHolder?.OfficerQuestion}
                    dataUI="information-officer-question-pick-list"
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.ZipCode?.IsBold}
                  isHidden={informationSectionHolder?.ZipCode?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.ZipCode)}
                  label={informationSectionHolder?.ZipCode?.Label}
                  isRequired={informationSectionHolder?.ZipCode?.Required}
                  validationMessage="Incorrect ZIP code format"
                  dataUI="information-officer-question-form-group"
                  htmlFor={informationSectionHolder?.ZipCode?.FieldName}
                  isValid={validationCheck(informationSectionHolder?.ZipCode?.FieldName)}
                >
                  <MaskedInput
                    type="zipCode"
                    mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                    icon={IconsSolid.faMapPin}
                    value={information.ZipBusinessLocated}
                    onChange={e => {
                      onChange(e, 'ZipBusinessLocated');
                    }}
                    dataUI="information-zip-Code-question-input"
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.LocationOfBusiness?.IsBold}
                  isHidden={informationSectionHolder?.LocationOfBusiness?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.LocationOfBusiness)}
                  label={informationSectionHolder?.LocationOfBusiness?.Label}
                  isRequired={informationSectionHolder?.LocationOfBusiness?.Required}
                  dataUI="information-state-question-form-group"
                  htmlFor={informationSectionHolder?.LocationOfBusiness?.FieldName}
                  isValid={validationCheck(informationSectionHolder?.LocationOfBusiness?.FieldName)}
                  validationMessage={errorFoundMessage('LocationOfBusiness', invalidSetOfPageFields)}
                >
                  <PickList
                    dsSelectWrapper
                    id={informationSectionHolder?.LocationOfBusiness?.FieldName}
                    stateLess={false}
                    initialData={[]}
                    value={information.StateBusinessLocated}
                    disabled={informationSectionHolder?.LocationOfBusiness?.Disabled}
                    onChange={e => {
                      onChange(e, 'StateBusinessLocated');
                    }}
                    ListName="QDState"
                    ListType="LtItems"
                    pageField={informationSectionHolder?.LocationOfBusiness}
                    dataUI="information-officer-question-pick-list"
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.CountyCode?.IsBold}
                  isHidden={informationSectionHolder?.CountyCode?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.CountyCode)}
                  label={informationSectionHolder?.CountyCode?.Label}
                  isRequired={informationSectionHolder?.CountyCode?.Required}
                  dataUI="information-county-question-form-group"
                  htmlFor={informationSectionHolder?.CountyCode?.FieldName}
                  isValid={validationCheck(informationSectionHolder?.CountyCode?.FieldName)}
                  validationMessage={errorFoundMessage('CountyCode', invalidSetOfPageFields)}
                >
                  <Select
                    isLoading={countyCodesLoading}
                    placeholder={ValueConstants.DropDownDefaultValue.toString()}
                    options={listCounties.map(c => {
                      return { label: c.label, value: c.value, };
                    })}
                    value={information.CountyCode}
                    onChange={e => {
                      onChange(e, 'CountyCode');
                    }}
                    dataUI="information-branch-question-state-code-select"
                    id={informationSectionHolder?.CountyCode?.FieldName}
                  />
                </FormGroup>
                <FormGroup
                  className="mb-2"
                  isBold={informationSectionHolder?.ESignConsent?.IsBold}
                  isHidden={informationSectionHolder?.ESignConsent?.IsHidden}
                  hoverHelp={getHoverHelpPageFieldValue(informationSectionHolder?.ESignConsent)}
                  label={informationSectionHolder?.ESignConsent?.Label}
                  isRequired={informationSectionHolder?.ESignConsent?.Required}
                  dataUI="declaration-esign-consent-form-group"
                  checkboxOrRadio
                  isValid={validationCheck(informationSectionHolder?.ESignConsent?.FieldName) && information.ESignConsent !== false}
                  validationMessage={
                    information.ESignConsent === false ? Messages.E_SIGN_CONSENT_REQUIRED : errorFoundMessage('ESignConsent', invalidSetOfPageFields)
                  }
                >
                  <Radio
                    name="ESignConsent"
                    className="d-inline-block"
                    value="true"
                    checked={information.ESignConsent === true}
                    onChange={e => {
                      onChange(e, 'ESignConsent');
                    }}
                    dataUI="declaration-esign-consent-radio-yes"
                    htmlFor={`${informationSectionHolder?.ESignConsent?.FieldName}-1`}
                    id={`${informationSectionHolder?.ESignConsent?.FieldName}-1`}
                  >
                    Yes
                  </Radio>
                  <Radio
                    name="ESignConsent"
                    className="d-inline-block"
                    value="false"
                    checked={information.ESignConsent === false}
                    onChange={e => {
                      onChange(e, 'ESignConsent');
                    }}
                    dataUI="declaration-esign-consent-radio-no"
                    htmlFor={`${informationSectionHolder?.ESignConsent?.FieldName}-2`}
                    id={`${informationSectionHolder?.ESignConsent?.FieldName}-2`}
                  >
                    No
                  </Radio>
                </FormGroup>
              </FormSection>
            )}

            {disclosureSubSection?.DisplayOption === DisplayOptions.Always && hasDisclosures && (
              <ReactAux>
                <FormSection dataUI="information-disclosures-section" className="mb-3">
                  <h3 className="mb-2">{disclosureSubSection.SubSectionName}</h3>

                  {disclosuresHTML && (
                    <ScrollableContainer dataUI="contatenated-disclosures" className={`${hasDisclosuresDocuments ? 'mb-3' : ''}`}>
                      {disclosuresHTML}
                    </ScrollableContainer>
                  )}

                  {hasDisclosuresDocuments && (
                    <DisclosuresDocuments disclosuresDocuments={disclosuresDocuments} setDisclosuresDocuments={setDisclosuresDocuments} />
                  )}

                  {/* eslint-disable-next-line quick-decision/react-data-ui */}
                  <Tooltip title={areAllDocumentsOpened ? '' : Messages.DISCLOSURES_TOOLTIP_TITLE}>
                    <div>
                      <Checkbox
                        checked={agreedDisclosuresFlag}
                        onChange={onAgreedDisclosureChange}
                        dataUI="information-disclosure-agreed-checkbox"
                        htmlFor="information-agreement-with-disclosures"
                        id="information-agreement-with-disclosures"
                        className="d-inline-block mt-2"
                        disabled={agreedToDisclosure || !areAllDocumentsOpened}
                      >
                        {Messages.DISCLOSURES_CHECKBOX_LABEL}
                      </Checkbox>
                    </div>
                  </Tooltip>
                </FormSection>

                {disclosuresHTML && checkIsDisclosuresHasEmailTemplate(modelState, ExternalPages.InformationMembership) && (
                  <Alert dataUI="information-dislosure-alert-email-notification" type="info" text={Messages.AGREEMENT_NOTIFICATION_DISCLOSURES} />
                )}
              </ReactAux>
            )}
          </Section>

          {showDisclosuresErrorMessage && (
            <Alert dataUI="information-disclosure-alert-agreed-notification" type="danger" text={Messages.INVALID_DISCLOSURES} />
          )}
        </form>
      )}
    </>
  );
};

export default Information;
