import { Reducer as Reducer_ } from 'redux';
import {
  PrequalificationModelState as PrequalificationModelState_,
  SetPrequalificationModelStateAction as SetPrequalificationModelStateAction_,
  SET_PREQUALIFICATION_MODEL_STATE
} from '../actions/PrequalificationModel';

const prequalificationModelStateReducer: Reducer_<PrequalificationModelState_, SetPrequalificationModelStateAction_> = (
  state = {
    ModelHolder: {
      Model: null,
      Sections: [],
      SubSections: [],
      PageFields: [],
      ApplicationPageFields: null,
    },
    IsModelHolderFetched: false,
  },
  action
) => {
  const { type, payload, } = action;

  switch (type) {
    case SET_PREQUALIFICATION_MODEL_STATE: {
      return { ...payload, };
    }
    default:
      return state;
  }
};

export default prequalificationModelStateReducer;
