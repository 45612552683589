import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch as ThunkDispatch_ } from 'redux-thunk';
import { withRouter } from 'react-router-dom';
import { AnyAction as AnyAction_ } from 'redux';
import {
  Button,
  Footer,
  Header,
  IconsSolid,
  MainContainer,
  Modal,
  NavSteps,
  ReactAux,
  TextEditorContentWrapper,
  TimeOutMessage,
  ActionsWrapper
} from '@jkhy/vsg-design-system';
import { AppState as AppState_ } from '../../redux/AppState';
import {
  htmlParser,
  logApplication,
  prepareActionLogCompleteSectionData,
  prepareActionLogSectionData,
  scrollToTopOfThePage,
  maskLoanNumber,
  getModelHolderBasedOnApplicationType,
  sortVisibleSections
} from '../../utils/Helper';
import QdApplicationHolder_ from '../../data/models/QDApplicationHolder';
import {
  ApplicationStatus,
  ExternalPages,
  ExternalPageSectionName,
  PartyType,
  GuarantorPages,
  QDPartyStatus,
  GuarantorPageSectionName_,
  WorkerActions,
  RenewalPages,
  ApplicationTypes,
  CoBorrowerAddType
} from '../../utils/Enums';
import { setLoader } from '../../redux/actions/Loading';
import { updateStopApplicationCondition as updateStopApplicationConditionAction } from '../../redux/actions/AppSettings';
import { BaseType as BaseType_ } from '../../utils/Types';
import QdApplicationSection_ from '../../data/models/QDApplicationSection';
import { getCompleteSteps, NavigationStep as NavigationStep_, redirectToCP } from '../../utils/Navigation';
import Section_ from '../../data/models/Section';
import { PrequalificationPages } from '../../utils/prequalification/Enums';
import { getPrequalificationUrl } from '../../utils/prequalification/Navigation';
import { getApplicationType } from '../../utils/LocalStorageManager';

const seconds = 60;
const MINUTE_IN_MILLISECONDS = 1000 * 60;

let timeout:number;
let timeoutValue:number;
// Keep client activity in sync between CP and QD; Localestorage related logic.
// Prevent QD to autologout user, while he is using CP
const PUBLIC_APPLICATION_ACTIVITY_KEY = 'pa-activity-timestamp';
export interface LayoutTemplateProps extends BaseType_ {
  children: React.ReactNode;
  updateStopApplicationCondition: (isApplicationStop: boolean) => void;
}

type LayoutTemplateState = {
  visibleWindow: boolean;
  showInactiveUserModal: boolean;
  logoConfirmVisible: boolean;
  homeConfirmVisible: boolean;
  secondsLeft: number;
  // identityCheckReached: boolean
};

// This ugly piece of code is due CORS restriction on Worker creation
// Additional related info
// https://github.com/vitejs/vite/issues/12662
// https://github.com/vitejs/vite/issues/13680
// https://github.com/webpack-contrib/worker-loader/issues/154
// https://web.dev/articles/workers-basics#toc-enviornment-loadingscripts
const js = `import ${JSON.stringify(new URL('../../worker/counter.worker.ts', import.meta.url))}`;
const blob = new Blob([js], { type: 'application/javascript', });
const objURL = URL.createObjectURL(blob);
const worker = new Worker(objURL, { type: 'module', });

class LayoutTemplate extends React.Component<LayoutTemplateProps, LayoutTemplateState> {
  constructor(props:LayoutTemplateProps) {
    super(props);

    this.state = {
      visibleWindow: false,
      showInactiveUserModal: false,
      logoConfirmVisible: false,
      homeConfirmVisible: false,
      secondsLeft: seconds,
      // identityCheckReached: false,
    };

    worker.addEventListener('message', event => {
      const {
        data: { action, value, },
      } = event;

      if (action === WorkerActions.CounterEnd) {
        this.showInactiveModal(true);
        // start TimeOutMessage countdown
        worker.postMessage({ action: WorkerActions.TimeoutComponentCountdown, value: seconds, });
      }
      if (action === WorkerActions.CounterResetWithLogicEnd) {
        if (!this.localeStorageActiveTimeStamp()) {
          this.showInactiveModal(true);
          worker.postMessage({ action: WorkerActions.TimeoutComponentCountdown, value: seconds, });
        } else this.resetTimer(null, true);
      }
      if (action === WorkerActions.TimeoutComponentIntervalValue) {
        this.setState({ secondsLeft: value, });
        if (value <= 0) {
          this.showInactiveModal(false);
          worker.postMessage({ action: WorkerActions.TimeoutComponentCountdownIntervalClear, });
          this.logoutOnInactivity();
        }
      }
    });
  }

  async componentDidMount() {
    this.inactiveUserTime();

    const {
      holderState,
      modelState,
      coBorrowerHolderState,
      match: {
        params: { sequence: borrowerSequence, coBorrSequence: coBorrowerSequence, completePage, },
      },
    } = this.props;

    let actionLogData;

    // Borrower Welcome page
    if (borrowerSequence === ExternalPages.Welcome) {
      actionLogData = prepareActionLogSectionData(borrowerSequence, modelState, holderState, false);
      // Email Invited Co-Borrower Welcome and Thank You page
    } else if (coBorrowerSequence === GuarantorPages.Welcome || coBorrowerSequence === GuarantorPages.Complete) {
      actionLogData = prepareActionLogSectionData(coBorrowerSequence, modelState, coBorrowerHolderState, true);
    }

    // Complete pages
    if (completePage) {
      actionLogData = prepareActionLogCompleteSectionData(completePage, modelState, holderState);
    }

    if (actionLogData?.Details) {
      await logApplication(actionLogData);
    }
  }

  async componentDidUpdate(prevProps: LayoutTemplateProps) {
    const {
      holderState,
      coBorrowerHolderState,
      modelState,
      match: {
        params: {
          sequence: borrowerSequence, coBorrSequence: coBorrowerSequence, page: coBorrowerAddNowPage, completePage,
        },
      },
      location,
    } = this.props;

    const {
      match: {
        params: {
          sequence: prevBorrowerSequence,
          coBorrSequence: prevCoBorrowerSequence,
          page: prevCoBorrowerAddNowPage,
          completePage: prevCompletePage,
        },
      },
      location: previousLocation,
    } = prevProps;

    if (location?.pathname !== previousLocation?.pathname) {
      scrollToTopOfThePage();
    }

    let actionLogData;

    // Borrower pages
    if (prevBorrowerSequence !== borrowerSequence) {
      actionLogData = prepareActionLogSectionData(borrowerSequence, modelState, holderState, false);
      // Email Invited Co-Borrower pages
    } else if (prevCoBorrowerSequence !== coBorrowerSequence) {
      actionLogData = prepareActionLogSectionData(coBorrowerSequence, modelState, coBorrowerHolderState, true);
    }

    // Complete pages
    if (prevCompletePage !== completePage) {
      actionLogData = prepareActionLogCompleteSectionData(completePage, modelState, holderState);
    }

    // Add Now Co-Borrower pages
    if (prevCoBorrowerAddNowPage !== coBorrowerAddNowPage) {
      actionLogData = prepareActionLogSectionData(borrowerSequence, modelState, holderState, false, coBorrowerAddNowPage);
    }

    if (actionLogData?.Details) {
      await logApplication(actionLogData);
    }
  }

  toggleWindow = () => {
    this.setState(prevState => ({
      visibleWindow: !prevState.visibleWindow,
    }));
  };

  showInactiveModal = value => {
    this.setState({ showInactiveUserModal: value, });
  };

  logout = () => {
    const { history, } = this.props;
    history.push('/logout/');
  };

  logoutOnInactivity = () => {
    const { history, } = this.props;

    if (!this.localeStorageActiveTimeStamp()) history.push('/logout/1/');
    else {
      this.showInactiveModal(false);
      this.inactiveUserTime();
    }
  };

  localeStorageActiveTimeStamp = () => {
    const {
      App: { InactiveUserMinutes, },
    } = window.CONFIG;
    const timeoutPeriod = parseInt(InactiveUserMinutes, 10) * MINUTE_IN_MILLISECONDS;

    const currentTimeStamp = new Date().getTime();
    const loggedActivityTimestamp = parseInt(localStorage.getItem(PUBLIC_APPLICATION_ACTIVITY_KEY), 10);
    const diff = currentTimeStamp - loggedActivityTimestamp;

    return timeoutPeriod > diff;
  };

  inactiveUserTime = () => {
    const {
      App: { InactiveUserMinutes, },
    } = window.CONFIG;

    //  Disable autologout, case InactiveUserMinutes = 0 or not set
    if (!InactiveUserMinutes || InactiveUserMinutes === '0') return;

    timeoutValue = parseInt(InactiveUserMinutes, 10) * MINUTE_IN_MILLISECONDS;

    worker.postMessage({ action: WorkerActions.CounterStart, value: timeoutValue, });

    document.onkeypress = e => this.resetTimer(e, false);
    document.onmousemove = e => this.resetTimer(e, false);
    document.onmousedown = e => this.resetTimer(e, false);
    document.ontouchstart = e => this.resetTimer(e, false);
    document.ontouchmove = e => this.resetTimer(e, false);
    document.onkeydown = e => this.resetTimer(e, false);
  };

  resetTimer = (e: KeyboardEvent | MouseEvent | TouchEvent, dontUpdatePublicAppKey: boolean) => {
    const { showInactiveUserModal, } = this.state;

    worker.postMessage({ action: WorkerActions.CounterReset, value: timeout, });

    if (!dontUpdatePublicAppKey) {
      const activityTimestamp = `${new Date().getTime()}`;
      localStorage.setItem(PUBLIC_APPLICATION_ACTIVITY_KEY, activityTimestamp);
    }

    if (!showInactiveUserModal) {
      worker.postMessage({ action: WorkerActions.CounterResetWithLogicStart, value: timeoutValue, });
    }
  };

  isStatusTabVisible = (app: QdApplicationHolder_) => {
    return ApplicationStatus.WaitingOnCoApplicant === app.ApplicationStatus && app?.CompleteFlag && app?.Guarantor?.Guarantors.length > 0;
  };

  isDocumentPageVisible = (app: QdApplicationHolder_) => {
    return [ApplicationStatus.OnHold, ApplicationStatus.Accepted].includes(app.ApplicationStatus) && app?.CompleteFlag && app?.DocumentsTabVisibility;
  };

  renderExternalSteps = () => {
    const {
      sectionsState: { VisibleSections, CoBorrowerAddNowVisibleSections, },
      holderState: { Holder: holder, IsHolderFetched: isHolderFetched, },
      modelState: { ModelHolder: modelHolder, },
      match: {
        params: {
          guid, model, completePage, sequence, page,
        },
      },
      identityState: { IdentityVisited: identityVisited, IsIdentityFetched: isIdentityFetched, },
      coBorrowerAddNowState: { CoBorrowerAddNowHolder, },
      coBorrowerIdentityState: {
        IsIdentityFetched: coIsIdentityFetched,
        IdentityVisited: coIdentityVisited,
        /*  IdentityHolder: {
          HasQuestions: coHasQuestions,
        },  */
      },
    } = this.props;

    if (!isHolderFetched || holder?.Expired) return [];

    if (holder?.CompleteFlag && !holder.Expired) {
      const isDocumentsPageVisible = this.isDocumentPageVisible(holder);
      const isStatusPageVisible = this.isStatusTabVisible(holder);

      const params = {
        completePage,
        model,
        guid,
        modelHolder,
        sections: VisibleSections,
        isDocumentsPageVisible,
        isStatusPageVisible,
      };

      return getCompleteSteps(params);
    }

    const {
      BorrowerP: { Personal: personal, },
      BorrowerB: { Business: business, },
    } = holder;
    const sections = this.identityVerificationStep(VisibleSections, personal, business);

    const result = [...sections].map(s => {
      let path = `/section/${model}/${s.Sequence}/${guid}/`;
      if (personal.PartyMatchDone && isIdentityFetched && !identityVisited && `${s.Sequence}` === ExternalPages.PersonalPartyMatch) {
        path = `/section/${model}/${ExternalPages.IdentityCheck}/${guid}/`;
      }

      const activeIdenityCheck = sequence === ExternalPages.IdentityCheck && `${s.Sequence}` === ExternalPages.PersonalPartyMatch;
      return {
        text: (s.SectionName || '') === ExternalPageSectionName.Review ? 'Review' : s.TabName,
        path,
        isActive: sequence === `${s.Sequence}` || activeIdenityCheck,
        subSteps: [],
      };
    });

    // CoBorrower Add Now steps
    if (sequence === ExternalPages.CoBorrower && page && CoBorrowerAddNowVisibleSections?.length > 0) {
      const { CoBorrower: coBorrower, } = CoBorrowerAddNowHolder;
      const res = this.coBorrowerIdentityVerificationStep(CoBorrowerAddNowVisibleSections, coBorrower);
      const coBorrowerAddNowSteps = res.map((s: QdApplicationSection_) => {
        let path = `/guarantor-add-now/${model}/${sequence}/${s.Sequence}/${guid}/`;

        if (
          coBorrower.PartyMatchDone
          && coBorrower.PartyType === PartyType.Personal
          && coIsIdentityFetched
          && !coIdentityVisited
          && `${s.Sequence}` === GuarantorPages.PersonalPartyMatch
        ) {
          path = `/guarantor-add-now/${model}/${sequence}/${GuarantorPages.IdentityCheck}/${guid}/`;
        }

        const activeIdenityCheck = page === GuarantorPages.IdentityCheck && `${s.Sequence}` === GuarantorPages.PersonalPartyMatch;
        return {
          text: (s.SectionName || '') === ExternalPageSectionName.Review ? 'Review' : s.TabName,
          path,
          isActive: page === `${s.Sequence}` || activeIdenityCheck,
          subSteps: [],
        };
      });

      // Include Add Now steps as sub menu items
      const coBorrowerPage = result.find(s => s.isActive);
      if (coBorrowerPage) {
        coBorrowerPage.isActive = false;
        coBorrowerPage.subSteps = coBorrowerAddNowSteps;
      }
    }

    return result;
  };

  identityVerificationStep = (sections, personal, business) => {
    let visibleSections = [...sections];
    const {
      modelState: { ModelHolder: modelHolder, },
      match: {
        params: { sequence, },
      },
      identityState: { IdentityVisited: identityVisited, IsIdentityFetched: isIdentityFetched, IdentityHolder: identityHolder, },
    } = this.props;

    const lastVisibleSection = visibleSections[visibleSections.length - 1];
    const lastVisibleSequence = parseInt(lastVisibleSection.Sequence, 10);
    const personalPartyMatch = parseInt(ExternalPages.PersonalPartyMatch, 10);
    switch (sequence) {
      case ExternalPages.PersonalPartyMatch:
        if (isIdentityFetched) {
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
        }
        break;
      case ExternalPages.IdentityCheck:
        if (lastVisibleSequence > parseInt(ExternalPages.PersonalAddresses, 10)) {
          visibleSections = visibleSections.slice(0, -1);
          const identityPage = { ...modelHolder.IdentitySection, Sequence: ExternalPages.IdentityCheck, };
          visibleSections.push(identityPage);
        } else if (lastVisibleSequence > personalPartyMatch) {
          if (!visibleSections.includes(ExternalPages.PersonalPartyMatch)) {
            const partyMatchSection = modelHolder.ApplicationSections.find(s => s.SectionName === ExternalPageSectionName.PersonalPartyMatch);
            if (partyMatchSection) {
              visibleSections.push(partyMatchSection);
              visibleSections = sortVisibleSections(visibleSections);
            }
          }
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
        }
        break;
      default: {
        if (personal.PartyMatchDone && (!isIdentityFetched || identityVisited)) {
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) !== personalPartyMatch);
        }

        if (isIdentityFetched && !identityVisited && identityHolder.HasQuestions) {
          if (lastVisibleSequence > parseInt(ExternalPages.PersonalAddresses, 10)) {
            visibleSections = visibleSections.slice(0, -1);
            const identityPage = { ...modelHolder.IdentitySection, Sequence: ExternalPages.IdentityCheck, };
            visibleSections.push(identityPage);
          } else if (lastVisibleSequence > personalPartyMatch) {
            if (!visibleSections.includes(ExternalPages.PersonalPartyMatch)) {
              const partyMatchSection = modelHolder.ApplicationSections.find(s => s.SectionName === ExternalPageSectionName.PersonalPartyMatch);
              if (partyMatchSection) {
                visibleSections.push(partyMatchSection);
                visibleSections = sortVisibleSections(visibleSections);
              }
            }
            visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
          }
        }

        if (business.PartyMatchDone) {
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) !== parseInt(ExternalPages.BusinessPartyMatch, 10));
        }
      }
    }
    return visibleSections;
  };

  coBorrowerIdentityVerificationStep = (sections, coBorrower) => {
    let visibleSections = [...sections];
    const {
      modelState: { ModelHolder: modelHolder, },
      match: {
        params: { coBorrSequence, page, },
      },
      coBorrowerIdentityState: { IdentityVisited, IsIdentityFetched, IdentityHolder, },
    } = this.props;
    const isAddNow = coBorrower.CoBorrowerAddType === CoBorrowerAddType.AddNow;

    const lastVisibleSection = visibleSections[visibleSections.length - 1];
    const lastVisibleSequence = parseInt(lastVisibleSection.Sequence, 10);
    const personalPartyMatch = parseInt(GuarantorPages.PersonalPartyMatch, 10);
    const currentCoBorrowerPage = isAddNow ? page : coBorrSequence; /* Email Invited flow */
    switch (currentCoBorrowerPage) {
      case GuarantorPages.PersonalPartyMatch:
        if (IsIdentityFetched) {
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
        }
        break;
      case GuarantorPages.IdentityCheck:
        if (isAddNow && lastVisibleSequence === parseInt(GuarantorPages.PersonalAddress, 10)) {
          // Income&Expenses hidden
          const identityPage = { ...modelHolder.IdentitySection, Sequence: GuarantorPages.IdentityCheck, };
          visibleSections.push(identityPage);
        } else if (lastVisibleSequence > parseInt(GuarantorPages.PersonalAddress, 10)) {
          visibleSections = visibleSections.slice(0, -1);
          const identityPage = { ...modelHolder.IdentitySection, Sequence: GuarantorPages.IdentityCheck, };
          visibleSections.push(identityPage);
        } else if (lastVisibleSequence > personalPartyMatch) {
          if (!visibleSections.map(s => s.Sequence).includes(GuarantorPages.PersonalPartyMatch)) {
            const partyMatchSection = modelHolder.ApplicationSections.find(s => s.SectionName === GuarantorPageSectionName_.PersonalPartyMatch);
            if (partyMatchSection) {
              visibleSections.push(partyMatchSection);
              visibleSections = sortVisibleSections(visibleSections);
            }
          }
          visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
        }
        break;
      default: {
        if (coBorrower.PartyMatchDone) {
          const removePersonalPartyMatch = !IsIdentityFetched || IdentityVisited || (isAddNow && !IdentityHolder?.HasQuestions);
          if (coBorrower.PartyType === PartyType.Personal && removePersonalPartyMatch) {
            visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) !== personalPartyMatch);
          }
          if (coBorrower.PartyType === PartyType.Business) {
            visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) !== parseInt(GuarantorPages.BusinessPartyMatch, 10));
          }
        }

        if (IsIdentityFetched && !IdentityVisited && IdentityHolder.HasQuestions) {
          if (isAddNow && lastVisibleSequence === parseInt(GuarantorPages.PersonalAddress, 10)) {
            // Income&Expenses hidden
            const identityPage = { ...modelHolder.IdentitySection, Sequence: GuarantorPages.IdentityCheck, };
            visibleSections.push(identityPage);
          } else if (lastVisibleSequence > parseInt(GuarantorPages.PersonalAddress, 10)) {
            visibleSections = visibleSections.slice(0, -1);
            const identityPage = { ...modelHolder.IdentitySection, Sequence: GuarantorPages.IdentityCheck, };
            visibleSections.push(identityPage);
          } else if (lastVisibleSequence > personalPartyMatch) {
            if (!visibleSections.map(s => s.Sequence).includes(GuarantorPages.PersonalPartyMatch)) {
              const partyMatchSection = modelHolder.ApplicationSections.find(s => s.SectionName === GuarantorPageSectionName_.PersonalPartyMatch);
              if (partyMatchSection) {
                visibleSections.push(partyMatchSection);
                visibleSections = sortVisibleSections(visibleSections);
              }
            }
            visibleSections = visibleSections.filter(s => parseInt(s.Sequence, 10) <= personalPartyMatch);
          }
        }
      }
    }
    return visibleSections;
  };

  renderGuarantorSteps = (): NavigationStep_[] => {
    const {
      coBorrowerHolderState: { CoBorrowerHolder, IsCoBorrowerHolderFetched, },
      isCoBorrowerSubmitted,
      sectionsState: { VisibleSections, },
      modelState: {
        ModelHolder: { CoBorrowerSections, },
      },
      match: {
        params: { model, coBorrSequence, partyGUID, },
      },
      coBorrowerIdentityState: { IdentityVisited, IsIdentityFetched, },
    } = this.props;

    if (!IsCoBorrowerHolderFetched) return [];

    const coBorrower = CoBorrowerHolder?.Personal?.CoBorrower ?? CoBorrowerHolder?.Business?.CoBorrower;
    if ([QDPartyStatus.Stopped, QDPartyStatus.FailedAuthentication, QDPartyStatus.Submitted, QDPartyStatus.Declined].includes(coBorrower.Status)) {
      let menuTitle = CoBorrowerHolder?.Expired ? 'Expired' : 'Results';
      if (isCoBorrowerSubmitted) {
        const thankYouSection = CoBorrowerSections.find(s => s.SectionName === GuarantorPageSectionName_.ThankYou);
        menuTitle = thankYouSection.TabName;
      }

      if (coBorrower.Status === QDPartyStatus.Declined) menuTitle = 'Withdrawn';

      return [
        {
          text: menuTitle,
          path: `/guarantor/${model}/${GuarantorPages.Complete}/${partyGUID}/`,
          isActive: true,
          subSteps: [],
        }
      ];
    }

    const sections = this.coBorrowerIdentityVerificationStep(VisibleSections, coBorrower);

    const result = sections.map((s: QdApplicationSection_) => {
      let path = `/guarantor/${model}/${s.Sequence}/${partyGUID}/`;
      if (
        coBorrower.PartyMatchDone
        && coBorrower.PartyType === PartyType.Personal
        && IsIdentityFetched
        && !IdentityVisited
        && `${s.Sequence}` === GuarantorPages.PersonalPartyMatch
      ) {
        path = `/guarantor/${model}/${GuarantorPages.IdentityCheck}/${partyGUID}/`;
      }

      const activeIdenityCheck = coBorrSequence === GuarantorPages.IdentityCheck && `${s.Sequence}` === GuarantorPages.PersonalPartyMatch;
      return {
        text: (s.SectionName || '') === ExternalPageSectionName.Review ? 'Review' : s.TabName,
        path,
        isActive: coBorrSequence === `${s.Sequence}` || activeIdenityCheck,
        subSteps: [],
      };
    });

    return result;
  };

  openLogoPopup = () => this.setState({ logoConfirmVisible: true, });

  closeLogoPopup = () => this.setState({ logoConfirmVisible: false, });

  openHomeLinkPopup = () => this.setState({ homeConfirmVisible: true, });

  closeHomeLinkPopup = () => this.setState({ homeConfirmVisible: false, });

  getHomePopupContent = (onSuccess: any, onCancel: any) => (
    <>
      <h3 className="mb-2">Go to Client Portal</h3>
      <p className="mb-3">Are you sure you want to go to the home page?</p>
      <ActionsWrapper dataUI="leave-confirmation-actions-wrapper">
        <Button btnType="secondary" className="action-spacer" iconLeft={IconsSolid.faTimes} onClick={onCancel} dataUI="leave-confirmation-button-no">
          No
        </Button>

        <Button btnType="primary" iconLeft={IconsSolid.faCheck} onClick={onSuccess} dataUI="leave-confirmation-button-yes">
          Yes
        </Button>
      </ActionsWrapper>
    </>
  );

  renderRenewalSteps = (): NavigationStep_[] => {
    const {
      renewal,
      match: { params: routingParams, },
    } = this.props;
    const { idInvitation, model, renewalSequence, } = routingParams;

    // eslint-disable-next-line react/destructuring-assignment
    const app = renewal?.ModelHolder?.Renewal?.Application;
    const { CompleteFlag, Expired, Facilities: facilities = [], } = app;

    if (CompleteFlag || Expired) {
      return [
        {
          text: 'Submitted',
          path: `/renewal/${model}/${idInvitation}/${RenewalPages.Submitted}`,
          isActive: renewalSequence === RenewalPages.Submitted,
          subSteps: [],
        }
      ];
    }

    const facilityMenuItems = facilities.map((f, idx) => {
      const firstFacilityIsActive = renewalSequence === RenewalPages.RenewalIdentity && idx === 0;
      return {
        text: `Loan Number: ${maskLoanNumber(f.LoanNumber)}`,
        path: `/renewal/${model}/${idInvitation}/${RenewalPages.Renewal}/${f.GUID}`,
        isActive: f.GUID === routingParams?.guid || firstFacilityIsActive,
        subSteps: [],
      };
    });

    return [
      ...facilityMenuItems,
      {
        text: 'Information',
        path: `/renewal/${model}/${idInvitation}/${RenewalPages.Information}`,
        isActive: renewalSequence === RenewalPages.Information,
        subSteps: [],
      },
      {
        text: 'Declarations',
        path: `/renewal/${model}/${idInvitation}/${RenewalPages.RenewalDeclarations}`,
        isActive: renewalSequence === RenewalPages.RenewalDeclarations,
        subSteps: [],
      }
    ];
  };

  renderPrequalificationSteps = (
    prequalificationSections: QdApplicationSection_[],
    modelCode: string,
    currentSequence: typeof PrequalificationPages[keyof typeof PrequalificationPages]
  ): NavigationStep_[] => {
    const currentSection = prequalificationSections.find(({ Sequence, }) => parseInt(Sequence, 10) === parseInt(currentSequence, 10));

    const links = [];

    if (currentSection) {
      const { TabName, } = currentSection;
      const path = getPrequalificationUrl(modelCode, currentSequence);

      links.push({
        text: TabName,
        path,
        isActive: true,
        subSteps: [],
      });
    }

    return links;
  };

  renderSteps = () => {
    const {
      prequalificationModelState: {
        ModelHolder: { Sections: prequalificationSections, },
      },
      match: {
        params: { partyGUID, prequalificationSequence, model, },
      },
    } = this.props;

    const applicationType = getApplicationType();
    if (applicationType === ApplicationTypes.Renewal) return this.renderRenewalSteps();

    const isGuarantorContext = !!partyGUID;
    if (isGuarantorContext && applicationType === ApplicationTypes.External) return this.renderGuarantorSteps();

    if (applicationType === ApplicationTypes.Prequalification) {
      return this.renderPrequalificationSteps(prequalificationSections, model, prequalificationSequence);
    }

    return this.renderExternalSteps();
  };

  getStopApplicationText = () => {
    const {
      prequalificationModelState: {
        ModelHolder: { Sections: prequalSections, },
      },
      sectionsState: { VisibleSections: visibleSections, CoBorrowerAddNowVisibleSections, },
      match: {
        params: {
          partyGUID, sequence, coBorrSequence, prequalificationSequence, page: addNowPage,
        },
      },
    } = this.props;

    const currentSequence = partyGUID ? coBorrSequence : sequence;
    if (prequalificationSequence) {
      return prequalSections?.find((section: Section_) => section.Sequence === prequalificationSequence)?.StopApplicationWording || '';
    }
    if (addNowPage) {
      return CoBorrowerAddNowVisibleSections?.find((section: Section_) => section.Sequence === addNowPage)?.StopApplicationWording || '';
    }

    return visibleSections?.find((section: Section_) => section.Sequence === currentSequence)?.StopApplicationWording || '';
  };

  render() {
    const {
      modelState,
      prequalificationModelState,
      renewalModelState,
      children,
      history,
      appSettings: { isApplicationStop, },
      updateStopApplicationCondition,
    } = this.props;

    const {
      visibleWindow, showInactiveUserModal, homeConfirmVisible, logoConfirmVisible, secondsLeft,
    } = this.state;

    const applicationType = getApplicationType();
    const modelHolder = getModelHolderBasedOnApplicationType(modelState, renewalModelState, prequalificationModelState, applicationType);

    const { Model: model, } = modelHolder;
    const hideSignOut = history?.location?.pathname?.indexOf('logout-callback') !== -1;

    const {
      IsLeftSideImageSelected, BrandName, FooterTopText, FooterBottomText, FooterLeftImageUrl, FooterMiddleImageUrl, FooterRightImageUrl,
    } = model?.DesignSystemTemplate || {};

    const links = [];
    links.push({
      text: 'Home',
      path: '/client-portal',
      icon: IconsSolid.faHome,
      isHomeLink: true,
    });
    if (model?.FaqHTML) {
      links.push({
        text: 'FAQs',
        path: '#',
        onClick: this.toggleWindow,
        icon: IconsSolid.faComments,
        isHomeLink: false,
      });
    }
    if (!hideSignOut) {
      links.push({
        text: 'Sign Out',
        path: '/logout',
        icon: IconsSolid.faSignOutAlt,
        isHomeLink: false,
      });
    }

    return (
      <ReactAux>
        <MainContainer
          dataUI="layout-template-container"
          header={(
            <Header
              logoText={IsLeftSideImageSelected ? null : BrandName}
              links={links}
              homeLinkConfirmation={{
                content: this.getHomePopupContent(redirectToCP, this.closeHomeLinkPopup),
                isVisible: homeConfirmVisible,
                onOpen: this.openHomeLinkPopup,
                onClose: this.closeHomeLinkPopup,
              }}
              logoLinkConfirmation={{
                content: this.getHomePopupContent(redirectToCP, this.closeLogoPopup),
                isVisible: logoConfirmVisible,
                onOpen: this.openLogoPopup,
                onClose: this.closeLogoPopup,
              }}
            />
          )}
          leftSideElement={<NavSteps hideStepsNumber dataUI="layout-template-navigation" steps={this.renderSteps()} />}
          footer={(
            <Footer
              imgLeft={{ src: FooterLeftImageUrl, alt: '', }}
              imgCenter={{ src: FooterMiddleImageUrl, alt: '', }}
              imgRight={{ src: FooterRightImageUrl, alt: '', }}
              topText={FooterTopText}
              bottomText={FooterBottomText}
              version={window.FE_VERSION}
            />
          )}
        >
          {children || ''}
        </MainContainer>

        {model?.FaqHTML && (
          <Modal dataUI="layout-template-faq-modal" title="FAQ" closeBtnName="Close" isVisible={visibleWindow} onClose={this.toggleWindow}>
            <TextEditorContentWrapper>{htmlParser(model?.FaqHTML)}</TextEditorContentWrapper>
          </Modal>
        )}

        {isApplicationStop && (
          <Modal
            title=""
            closeBtnName="Close"
            isVisible={isApplicationStop}
            onClose={() => updateStopApplicationCondition(false)}
            dataUI="external-stop-application-html-modal"
          >
            <TextEditorContentWrapper>{this.getStopApplicationText()}</TextEditorContentWrapper>
          </Modal>
        )}

        <TimeOutMessage
          dataUI="autologout-modal"
          isVisible={showInactiveUserModal}
          title="Long inactivity detected"
          subText="We will sign you out to keep your account safe"
          seconds={seconds}
          secondsLeft={secondsLeft}
          onClose={() => this.showInactiveModal(false)}
          buttons={[
            <Button key="still-here-button" dataUI="still-here-button" className="mb-2" onClick={() => this.showInactiveModal(false)}>
              I am here
            </Button>,
            <Button key="sign-out-button" dataUI="sign-out-button" btnType="secondary" onClick={this.logout}>
              Sign out now
            </Button>
          ]}
        />
      </ReactAux>
    );
  }
}

const mapStateToProps = (state: AppState_): AppState_ => ({ ...state, });

const mapDispatchToProps = (dispatch: ThunkDispatch_<any, any, AnyAction_>) => {
  return {
    setLoader: (loading: boolean) => dispatch(setLoader(loading)),
    updateStopApplicationCondition: (isApplicationStop: boolean) => dispatch(updateStopApplicationConditionAction(isApplicationStop)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LayoutTemplate));
