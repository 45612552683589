import moment from 'moment';
import PageField_ from '../data/models/PageField';
import QDAddress_ from '../data/models/QDAddress';
import { AddressType, DateFormats } from './Enums';
import { joinValidElementsInString } from './Helper';
import QDPartyMatchPars_ from '../data/models/QDPartyMatchPars';
import Borrower_ from '../data/models/Borrower';

export const cityStateZipVisibility = (pageFieldCity: PageField_, pageFieldState: PageField_, pageFieldZipCode: PageField_) => {
  return !(pageFieldCity?.IsHidden && pageFieldState?.IsHidden && pageFieldZipCode?.IsHidden);
};

export const addressCityStateZipVisibility = (
  pageFieldAddress: PageField_,
  pageFieldCity: PageField_,
  pageFieldState: PageField_,
  pageFieldZipCode: PageField_
) => {
  return !pageFieldAddress?.IsHidden && cityStateZipVisibility(pageFieldCity, pageFieldState, pageFieldZipCode);
};

export const cityStateZipLabel = 'City/State/Zip Code';
export const addressCityStateZipLabel = 'Address/City/State/Zip Code';

export const cityStateZip = (
  city: string,
  state: string,
  zip: string,
  pageFieldCity: PageField_,
  pageFieldState: PageField_,
  pageFieldZipCode: PageField_
) => {
  let str = '';

  if (!pageFieldCity?.IsHidden && city) {
    str = city;
  }

  if (!pageFieldState?.IsHidden && state) {
    str = `${str}, ${state}`;
  }

  if (!pageFieldZipCode?.IsHidden && zip) {
    str = `${str}, ${zip}`;
  }

  return str;
};

export const addressCityStateZip = (
  address: string,
  city: string,
  state: string,
  zip: string,
  pageFieldAddress: PageField_,
  pageFieldCity: PageField_,
  pageFieldState: PageField_,
  pageFieldZipCode: PageField_
) => {
  let str = cityStateZip(city, state, zip, pageFieldCity, pageFieldState, pageFieldZipCode);

  if (!pageFieldAddress?.IsHidden && address) {
    str = `${address}, ${str}`;
  }

  return str;
};

export const getGoogleApiAddressAsString = (address: QDAddress_ | { City: string; State: string; Zip: string; Address: string }) => {
  const {
    Address, City, State, Zip,
  } = address;

  let googleApiAddress = joinValidElementsInString([Address, City, State], ', ');
  if (Zip) googleApiAddress = `${googleApiAddress} ${address.Zip}`;
  return googleApiAddress;
};

export const calculateDifferenceText = (addressStartDate: Date | string, addressEndDate: Date | string) => {
  if (!addressStartDate) return null;

  const endDate = addressEndDate || moment().format(DateFormats.MonthDayYear);

  const endDateMoment = moment(endDate);
  const diffYear = endDateMoment.diff(addressStartDate, 'year').toString();
  const diffMonth = endDateMoment.diff(addressStartDate, 'month') % 12;

  return `Length of time: ${diffYear} Years ${diffMonth} Months`;
};

export const buildBusinessPartyMatchAddress = (businessMatchParams: QDPartyMatchPars_, borrower: Borrower_) => {
  const { Id, IdQDApplication, } = borrower;

  return {
    ...new QDAddress_(),
    GoogleAPIAddress: businessMatchParams.Address,
    Address: businessMatchParams.Street,
    City: businessMatchParams.City,
    State: businessMatchParams.State,
    Zip: businessMatchParams.Zip,
    AddressType: AddressType.Current,
    IdQDParty: Id,
    IdQDApplication,
  };
};

export const getCurrentAddress = (addresses: QDAddress_[]) => addresses?.find((address: QDAddress_) => address.AddressType === AddressType.Current);
